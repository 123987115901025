import { nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import { QueuedResponseException } from '@/exceptions'

/**
 * @typedef { import("vue").Ref } Ref
 */

/**
 * @param {{}} [params]
 * @param {string} [params.lockTarget] - селектор для показа спиннера элемента
 * @param {boolean} [params.lockPage] - показ глобального спиннера страницы
 * @returns {{setLoading:  () => void, unsetLoading: () => void, wrapRequest: ((function(Function, ({currLockTarget?: string, keepLoadingOnQueue?: boolean} | {currLockTarget?: string})=): Promise<{result: null, error: null, queue: null}>) | any), loading: Ref<boolean>}}
 */
export function useRequest({ lockTarget, lockPage = false } = {}) {
  const store = useStore()
  const loading = ref(false)
  const target = ref()
  const doLockPage = ref()

  function setLoading() {
    loading.value = true
    if (doLockPage.value) {
      store.commit('lockPageWS')
      return
    }
    if (target.value) {
      store.commit('toggleLockEl', { lock: true, target: target.value })
    }
  }
  function unsetLoading() {
    loading.value = false
    if (doLockPage.value) {
      store.commit('unlockPage')
      return
    }
    if (target.value) {
      store.commit('toggleLockEl', { lock: false, target: target.value })
    }
  }

  /**
   * Оборачивает запрос с добавлением спинера и зачения loading
   * @param {function} request
   * @param {{currLockTarget?: string, keepLoadingOnQueue?: boolean, currLockPage?: boolean}} params
   * @param {string} [params.currLockTarget] - селектор только для этого запроса
   * @returns {Promise<{result: null, error: null, queue: null}>}
   */
  async function wrapRequest(
    request,
    { currLockTarget, currLockPage = false, keepLoadingOnQueue = true } = {},
  ) {
    const response = { result: null, error: null, queue: null }
    target.value = lockTarget || currLockTarget
    doLockPage.value = lockPage || currLockPage
    setLoading()

    try {
      response.result = await request()
      return response
    } catch (err) {
      if (err instanceof QueuedResponseException) {
        response.queue = {
          taskId: err.message,
        }
      } else {
        console.error(err)
        response.error = err
      }
      return response
    } finally {
      if (!response.queue || (response.queue && !keepLoadingOnQueue)) {
        await nextTick()
        unsetLoading()
      }
    }
  }

  return {
    wrapRequest,
    loading,
    setLoading,
    unsetLoading,
  }
}
