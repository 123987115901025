// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CompanyApiMethodsCls {
    async companyList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка компаний
      // filters: id, name, is_active, is_system, is_agency, is_hotelier, is_demo, is_service,
      //          is_transfer, is_privileged, inn, phone, entity_name
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('companyList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async companyCreate(wh, data, extra_data = null) {
      // data: Company
      // Создание компании
      console.log('companyCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyCreateexCreate(wh, data, extra_data = null) {
      // data: CompanyEntityCreate
      // Создание Компании и юрлица
      console.log('companyCreateexCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.createex.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyRetrieve(wh, id, extra_data = null) {
      // Получение компании
      console.log('companyRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('account__company', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'account__company', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyUpdate(wh, id, data, extra_data = null) {
      // data: Company
      // Обновление компании
      console.log('companyUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyDelete(wh, id) {
      // Удаление компании
      console.log('companyDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.account.company.delete(id)})
      ctx.$services.storage.del('account__company', id, wh)
      return null
    }
    async companyActivatePartial(wh, id, data, extra_data = null) {
      // data: CompanyActivate
      // Изменение поля is_active для компании (Company)
      console.log('companyActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyIdentitiesList(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Все доступные Identities компании, за исключением CUSTOMER
      // filters: created_at, updated_at, company, user, person, role, division, handle,
      //          exact_position_name, is_active, is_deleted, is_managed, is_supervisor, supervisor,
      //          agent, notifications_contact, pk, fio, phone, user_email
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('companyIdentitiesList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.identities.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async companyPhonesList(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Все внутренние телефоны компании
      // filters: number, info
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('companyPhonesList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.phones.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.companyApi = new CompanyApiMethodsCls()
}

