<template>
  <div v-if="props.hotel?.rooms_description">
    <Markdown :source="props.hotel.rooms_description" />
  </div>
  <div>
    <h5 v-if="categoriesList?.data?.length" class="mb-3">Номера</h5>
    <ul v-if="categoriesList?.data?.length" class="ps-4">
      <li
        v-for="category in categoriesList.data"
        :key="category.id"
        class="mb-2"
      >
        {{ category.name }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import Markdown from 'vue3-markdown-it'
import { inject, onMounted, onUnmounted, ref, watch } from 'vue'

const WH = 'b6dd4b26-b89b-4e96-b68f-ca7c34152c71'
const props = defineProps({
  hotel: {
    type: Object,
    required: true,
  },
})
const { categoryApi, storage } = inject('services')

const categoriesList = ref([])
const fetchCategories = async (id) => {
  storage.clr(WH)
  try {
    if (id || props.hotel?.id) {
      categoriesList.value = await categoryApi.hotelListCategoriesList(
        WH,
        id || props.hotel.id,
        { limit: 100, filters: { is_active: true } },
      )
    }
  } catch (error) {
    console.error(error)
  }
}

watch(
  () => props.hotel,
  (val) => {
    fetchCategories(val.id)
  },
)

onMounted(fetchCategories)
onUnmounted(() => {
  storage.clr(WH)
})
</script>
