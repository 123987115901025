<template>
  <template v-if="props.tooltip">
    <template v-if="disabled">
      <CTooltip
        :placement="props.placement"
        :content="disabledTooltip || `${props.tooltip} (недоступно)`"
      >
        <template #toggler="{ on }">
          <CButton
            :variant="props.variant"
            color="secondary"
            :size="props.size"
            :style="props.style"
            v-on="on"
            style="cursor: default"
            :class="buttonClass"
            :active="active"
          >
            <AppIcon
              :icon="props.icon"
              :size="props.iconSize"
              :class="iconClass"
              class="no-mouse-events"
            />
          </CButton>
        </template>
      </CTooltip>
    </template>
    <template v-else>
      <CTooltip :placement="props.placement" :content="props.tooltip">
        <template #toggler="{ on }">
          <CButton
            :variant="props.variant"
            :color="props.color"
            @click="onAction(props.obj)"
            :size="props.size"
            :style="props.style"
            :class="buttonClass"
            v-on="on"
            :disabled="disabled"
            :active="active"
            class="me-1"
          >
            <AppIcon
              :icon="props.icon"
              :size="props.iconSize"
              :class="iconClass"
              class="no-mouse-events"
            />
          </CButton>
        </template>
      </CTooltip>
    </template>
  </template>
  <template v-else>
    <template v-if="disabled">
      <CButton
        :variant="props.variant"
        color="secondary"
        :size="props.size"
        :style="props.style"
        :class="buttonClass"
        v-on="on"
        :active="active"
        style="cursor: default"
      >
        <AppIcon
          :icon="props.icon"
          :size="props.iconSize"
          :class="iconClass"
          class="no-mouse-events"
        />
      </CButton>
    </template>
    <template v-else>
      <CButton
        :variant="props.variant"
        :color="props.color"
        @click="onAction(props.obj)"
        :size="props.size"
        :style="props.style"
        :class="buttonClass"
        v-on="on"
        :disabled="disabled"
        :active="active"
        class="me-1"
      >
        <AppIcon
          :icon="props.icon"
          :size="props.iconSize"
          :class="iconClass"
          class="no-mouse-events"
        />
      </CButton>
    </template>
  </template>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  variant: {
    type: [String],
    default: 'ghost',
  },
  size: {
    type: [String],
    default: 'sm',
  },
  iconSize: {
    type: [String],
    default: null,
  },
  iconClass: {
    type: [String],
    default: null,
  },
  buttonClass: {
    type: [String],
    default: null,
  },
  color: {
    type: [String],
    default: 'dark',
  },
  action: {
    type: [Function],
    default: null,
  },
  obj: {
    type: [Object, Number, String],
    default: null,
  },
  icon: {
    type: [String],
    default: 'icon',
  },
  tooltip: {
    type: [String],
    default: null,
  },
  disabledTooltip: {
    type: [String],
    default: null,
  },
  placement: {
    type: [String],
    default: 'top',
  },
  disabled: {
    type: [Boolean],
    default: false,
  },
  style: {
    type: Object,
    required: false,
    default: null,
  },
  active: {
    type: Boolean,
    default: false,
  },
})

const onAction = (payload) => {
  if (props.action) props.action(payload)
}
</script>
