import TelecallFullinfoModal from '@/components/_telecall/TelecallFullinfoModal.vue'
import TableRowAdaptive from '@/components/custom/TableRowAdaptive.vue'
import TelecallTableRow from '@/components/_telecall/TelecallTableRow.vue'
import { ref, inject, onUnmounted } from 'vue'
import C from '@/config/back_const'
import { useStore } from 'vuex'

export const useTelecalls = (WH, request, extra = null) => {
  // TO DO STORAGE_BUG _2990
  // const WH = Symbol()

  const store = useStore()

  const { userApi, storage, identityApi, dateService, personApi } =
    inject('services')

  const rowFullinfo = ref(null)

  const load = ref(false)
  const relIdentities = ref({})
  const relIdentityUsers = ref({})
  const relInitiatorsIdentities = ref({})
  const relInitiatorsUsers = ref({})
  const relPersons = ref({})
  let lastRequestValue = null

  //действия с полученнгыми данными от запроса
  const afterAction = extra?.afterAction

  const getTelecall = async (requestValue) => {
    try {
      load.value = true
      store.commit('lockPageWS')
      if (requestValue && !(requestValue instanceof Event)) {
        lastRequestValue = requestValue
      }
      const telecallData =
        requestValue && !(requestValue instanceof Event)
          ? await request(requestValue)
          : await request(lastRequestValue)

      await initRels(telecallData)

      //в formedData лежат значения свойств, преобразованные для вывода в таблице
      telecallData.data = await Promise.all(
        telecallData.data.map(async (telecall) => {
          const formedData = {
            created_at: telecall?.created_at
              ? dateService.formatDateTime(telecall.created_at)
              : '',
            started_at: telecall?.started_at
              ? dateService.formatDateTime(telecall.started_at)
              : '',
            stopped_at: telecall?.stopped_at
              ? dateService.formatDateTime(telecall.stopped_at)
              : '',
            ring_duration: dateService.transformSecondsIntoTime(
              telecall?.ring_duration,
            ),
            call_duration: dateService.transformSecondsIntoTime(
              telecall?.call_duration,
            ),
            tele_call_type: telecall?.tele_call_type
              ? C.TELECALL_TYPE_TITLES[telecall.tele_call_type]
              : '',
            event_type: telecall?.event_type
              ? C.TELECALL_EVENT_TITLES[telecall.event_type]
              : '',
            event_type_icon: formedEventTypeIcon(telecall),
            object_id: formedObjInfo(telecall),
            identity: formedIdentityWuthUser(telecall),
            raw_data: telecall?.raw_data
              ? JSON.stringify(telecall.raw_data)
              : '',
            initiator: formedInitiator(telecall),
            person: formedPerson(telecall),
            status: formedStatus(telecall),
          }

          return {
            ...telecall,
            formedData,
          }
        }),
      )

      if (afterAction) {
        afterAction(telecallData)
      }

      return {
        data: telecallData,
      }
    } catch (error) {
      console.error(error)
    } finally {
      store.commit('unlockPage')
      load.value = false
    }
  }

  const initRels = async (telecallData) => {
    relIdentities.value = await storage.getRelations(
      telecallData.data,
      'identity',
      'account__identity',
      identityApi.identityRetrieve,
      WH,
    )
    relIdentityUsers.value = await storage.getRelations(
      relIdentities.value,
      'user',
      'account__user',
      userApi.userRetrieve,
      WH,
    )
    relPersons.value = await storage.getRelations(
      relIdentities.value,
      'person',
      'core__person',
      personApi.personRetrieve,
      WH,
    )
    relInitiatorsIdentities.value = await storage.getRelations(
      telecallData.data,
      'initiator',
      'account__identity',
      identityApi.identityRetrieve,
      WH,
    )
    relInitiatorsUsers.value = await storage.getRelations(
      relInitiatorsIdentities.value,
      'user',
      'account__user',
      userApi.userRetrieve,
      WH,
    )
  }

  const formedObjInfo = (telecall) => {
    return {
      id: telecall?.object_id || '',
      object_type: telecall?.object_type || '',
    }
  }

  const formedInitiator = (telecall) => {
    const initiatorIdentity =
      relInitiatorsIdentities.value?.[telecall?.initiator]
    const initiatorUser = relInitiatorsUsers.value?.[initiatorIdentity.user]
    return initiatorUser
  }

  const formedPerson = (telecall) => {
    const identity = relIdentities.value?.[telecall.identity]
    return relPersons.value?.[identity?.person]
  }

  const formedIdentityWuthUser = (telecall) => {
    const identity = relIdentities.value?.[telecall.identity]
    const identityUser = relIdentityUsers.value?.[identity?.user]
    const personUser = formedPerson(telecall)

    if (identity?.user || identity?.person) {
      return {
        ...identity,
        identityUser:
          identity?.role === C.ROLES_CUSTOMER && personUser
            ? personUser
            : identityUser,
        local_phone: telecall?.local_phone || '',
      }
    }

    if (identity?.id) {
      return {
        ...identity,
        local_phone: telecall?.local_phone || '',
      }
    }

    return {
      notIdentity: true,
    }
  }

  function formedStatus(telecall) {
    const tele_call_type = telecall?.tele_call_type
    const event_type = telecall?.event_type

    if (tele_call_type && event_type) {
      const obj = {
        text: '',
        tele_call_type,
      }
      let time = ''

      if (event_type === C.TELECALL_EVENT_END) {
        time = dateService.transformSecondsIntoTime(telecall?.call_duration)
        obj.text = time ? `Беседовали ${time}` : ''
        obj.record_url = telecall?.record_url || ''
      } else {
        if (
          //входящий
          (tele_call_type === C.TELECALL_TYPE_BACK ||
            tele_call_type === C.TELECALL_TYPE_IN) &&
          event_type === C.TELECALL_EVENT_MISSED
        ) {
          time = dateService.transformSecondsIntoTime(telecall?.ring_duration)
          obj.text = time ? `Никто не ответил в течении ${time}` : ''
        } else if (
          //исходящий
          tele_call_type === C.TELECALL_TYPE_OUT &&
          event_type === C.TELECALL_EVENT_CALLER_MISSED
        ) {
          time = dateService.transformSecondsIntoTime(telecall?.ring_duration)
          obj.text = time ? `Дозванивались ${time}` : ''
        }
      }

      return {
        ...obj,
        tele_call_type,
      }
    }

    return null
  }

  function formedEventTypeIcon(telecall) {
    const event_type = telecall?.event_type
    const tele_call_type = telecall?.tele_call_type

    if (event_type && tele_call_type) {
      const callTypeName = C.TELECALL_TYPE_TITLES?.[tele_call_type]
      return {
        icon: tele_call_type === C.TELECALL_TYPE_OUT ? 'out_call' : 'in_call',
        color: event_type === C.TELECALL_EVENT_END ? 'success' : 'danger',
        tooltip: callTypeName || '',
      }
    }

    return null
  }

  onUnmounted(() => {
    storage.clr(WH)
  })

  return {
    getTelecall,
    rowFullinfo,
    TelecallFullinfoModal,
    TableRowAdaptive,
    TelecallTableRow,
    load,
  }
}
