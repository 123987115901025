<template>
  <div class="autocomplete-field w-100" v-click-outside="onClickOutside">
    <div class="autocomplete-field__input position-relative">
      <CStaticText v-if="props.readOnly" :text="searchString" />
      <CFormInput
        v-else
        v-model.trim="searchString"
        @click="onInputChange"
        @input="onInputChange"
        @focus="onFocus"
        @blur="onBlur"
        :invalid="invalid"
        :size="props.size"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        class="form-control autocomplete-field__input"
        autocomplete="off"
        type="text"
      />
      <!--      @keydown="onInputChange"-->
      <div
        class="position-absolute top-0 end-0 flex justify-content-center align-items-center h-100"
      >
        <div class="mx-1">
          <CSpinner
            v-if="loading"
            component="span"
            color="secondary"
            size="sm"
            aria-hidden="true"
          />
        </div>
        <CButton
          v-if="searchString && !props.readOnly && !props.disabled"
          class="autocomplete-field__reset ms-1"
          color="secondary"
          variant="outline"
          @click="reset"
          :size="props.size"
        >
          <CIcon :icon="cilX" :size="props.size" class="text-danger" />
        </CButton>
      </div>
    </div>
    <CCollapse
      class="position-absolute z-index-15 autocomplete-field__drop_down"
      :visible="_dropDown"
      v-if="_dropDown"
      :size="props.size"
    >
      <div
        role="button"
        v-for="(item, i) in objectsList"
        :key="item.data_type + item.id"
        @click="() => onSelect(item)"
        class="px-3 py-2 content-item autocomplete_field_item small"
      >
        {{ item.generic_title }}
      </div>
    </CCollapse>
  </div>
</template>

<script setup>
import {
  defineProps,
  ref,
  onMounted,
  inject,
  defineEmits,
  computed,
  watch,
} from 'vue'
import { cilX } from '@coreui/icons'
import CStaticText from '@/components/_common/CStaticText'
import FC from '@/config/constants'
import { asyncDebouncedQueue } from '@/helpers/asyncDebouncedQueue'

const props = defineProps({
  minLen: {
    type: Number,
    default: 3,
  },
  searchArea: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: [Object, Number, String],
    default: null,
  },
  placeholder: {
    type: String,
    default: '',
  },
  rulesValidation: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: null,
    required: false,
  },
  invalid: { type: Boolean, required: false, default: false },
  //нужно для форм создания, что бы в маунтеде не провоцировать валидатор эмитом значения
  ignoreInit: {
    type: Boolean,
    default: false,
  },
  // Возвращает полученный при инициализации результат автокомплита с generic_title
  // это нужно в случаях если надо открыть форму редактирования с установленным значением и сразу использовать generic_title из modelValue, но там не будет generic_title если false
  emitObject: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits([
  'update:modelValue',
  'focus',
  'blur',
  'dropdown-open',
  'dropdown-close',
  'search-result',
])

const { genericApi } = inject('services')

const objectsList = ref([])
const searchString = ref(null)
const dropDown = ref(false)
const safeString = ref(null)
const selectionDone = ref(false)
const currSelection = ref(null)
const prevSearchString = ref(null)
const loading = ref(false)

const onInputAsyncDebouncedQueue = asyncDebouncedQueue(
  _onInputChange,
  FC.DEBOUNCE_TIMEOUT,
)
const onInputChange = async (ev) => onInputAsyncDebouncedQueue(ev)

const _dropDown = computed(() => {
  return dropDown.value && objectsList.value.length > 0
})

const getGenericTitle = async () => {
  const obj = {
    id: props.modelValue?.id,
    data_type: props.modelValue?.data_type,
    generic_title: props.modelValue?.generic_title,
  }
  if (obj.data_type && obj.id) {
    if (obj.generic_title) return
    try {
      const res = await genericApi.genericTitleRetrieve(
        null,
        obj.data_type,
        obj.id,
      )
      obj.generic_title = res.generic_title
      searchString.value = String(obj.generic_title)
      if (props.emitObject) emitObject(obj)
    } catch (err) {
      console.error(err)
    }
  } else {
    reset()
  }
}

const prepSearchText = (str) => {
  const len = str?.length || 0
  const maxLen = FC.AUTOCOMPLETE_SEARCH_MAX_LEN
  const tooLong = len > maxLen
  return tooLong ? str.slice(0, maxLen) : str
}

const loadAutocompleteResults = async (
  contentTypes,
  searchText,
  limit = 50,
) => {
  objectsList.value = await genericApi.autocompletePut(null, {
    content_types: contentTypes,
    search_string: prepSearchText(searchText),
    limit: limit,
  })
  onSearchResult({ result: objectsList.value, searchText })
}

async function _onInputChange(e) {
  try {
    loading.value = true

    const value = e?.target?.value

    if (value === '') {
      reset()
      return
    }

    if (e.keyCode === 27) {
      if (safeString.value !== null) {
        searchString.value = safeString.value
        safeString.value = null
      }
      objectsList.value = []
      dropDown.value = false
      return
    }

    if (
      value === null ||
      value === undefined ||
      !value.length ||
      value.length < 1
    ) {
      objectsList.value = []
      dropDown.value = false
      return
    }

    if (e.type === 'click' && dropDown.value) {
      dropDown.value = true
    }

    if (safeString.value === null) {
      safeString.value = searchString.value
    }

    dropDown.value = true
    selectionDone.value = false

    await loadAutocompleteResults(props.searchArea, value, 50)
  } catch (err) {
    console.error(err)
  } finally {
    loading.value = false
  }
}

const emitObject = (value) => {
  emit('update:modelValue', value)
}
const onFocus = () => {
  emit('focus')
}
const onBlur = () => {
  emit('blur')
}
const onSelect = async (item) => {
  searchString.value = item?.name || item?.generic_title || ''
  safeString.value = null
  selectionDone.value = true
  prevSearchString.value = searchString.value
  currSelection.value = item
  dropDown.value = false
  emitObject(item)
}
const onDropdownClose = () => {
  const changedTextButNoSelection =
    searchString.value !== prevSearchString.value && !selectionDone.value
  if (changedTextButNoSelection) {
    const model = currSelection.value ? { ...currSelection.value } : null
    emitObject(model)
  }
}
const onSearchResult = ({ result, searchText }) => {
  const payload =
    searchText && result.length === 0 ? { result, searchText } : undefined
  emit('search-result', payload)
}
const onClickOutside = () => {
  if (safeString.value) {
    searchString.value = safeString.value
    safeString.value = null
  }
  objectsList.value = []
  dropDown.value = false
}
const reset = () => {
  searchString.value = null
  currSelection.value = null
  safeString.value = null
  emitObject(null)
}

watch(
  () => props.modelValue,
  async () => {
    await getGenericTitle()
  },
)

watch(dropDown, (val) => {
  if (val) emit('dropdown-open', { searchString })
  if (!val) {
    emit('dropdown-close', {
      searchString: searchString.value,
      selectionDone: selectionDone.value,
      prevSearchString: prevSearchString.value,
    })
    onDropdownClose()
  }
})

onMounted(async () => {
  if (props.ignoreInit) {
    // await getGenericTitle(false)
    return
  }
  if (
    typeof props.modelValue === 'string' ||
    props.modelValue instanceof String
  ) {
    searchString.value = props.modelValue + ''
    emit('update:modelValue', searchString.value)
  } else if (props.modelValue instanceof Object) {
    await getGenericTitle()
  } else {
    emit('update:modelValue', null)
  }
})
</script>

<style lang="scss" scoped>
.autocomplete-field {
  position: relative;

  &__reset {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: var(--cui-white);
    &:hover {
      background-color: var(--cui-gray);
    }
  }
}
</style>
