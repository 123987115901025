<template>
  <div>
    <CModal ref="modal" :visible="isVisible" size="md" backdrop="static" alignment="center" fullscreen="md" @close="onClosed" @show="onOpened">
      <CModalHeader dismiss>
        <CModalTitle> Багрепорт</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div>
          <CFormCheck
            id="issue.i_am_reporter"
            v-model="issue.i_am_reporter"
            :label="issue.i_am_reporter ? 'ошибка обнаружена мной' : 'ошибка обнаружена другим пользователем'"
          />
          <CFormLabel>кто обнаружил ошибку</CFormLabel>
          <CFormInput v-model="issue.reporter" />
        </div>
        <div>
          <CFormLabel>когда произошло событие</CFormLabel>
          <CFormInput v-model="issue.issue_time" />
        </div>
        <div>
          <CFormLabel>environment (на каком сервере)</CFormLabel>
          <CFormSelect id="issue.environment" v-model="issue.environment" size="sm">
            <option value="" selected="true" disabled="disabled"></option>
            <option :value="o" v-for="o in environments" :key="o">{{o}}</option>
          </CFormSelect>
        </div>

        <div>
          <CFormLabel>описание события</CFormLabel>
          <CFormTextarea v-model="issue.description" />
        </div>

        {{ issue }}

      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onCancel">Закрыть</CButton>
        <CButton color="primary" @click="onOk" :disabled="!canSearch">Найти</CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>


<script setup>
import {onMounted, ref, defineEmits, computed, reactive} from "vue";
import {useRouter} from 'vue-router'

const issue = reactive({
  i_am_reporter: true,
  reporter: '',
  issue_time: new Date(),
  environment: null,
  description: null,
})

const isVisible = ref(false)

const router = useRouter()

const emit = defineEmits(['closed', 'update:modelValue'])

const searchString = ref(null)

const environments = [
  'production',
  'staging',
  'dev1',
  'dev2',
  'dev3',
]

onMounted(() =>{
  isVisible.value = true;
  console.log('onMounted')
})

const onClosed = () => {
  console.log('onClosed')
  isVisible.value=false
  emit('closed')
}

const onCancel = () => {
  console.log('onCancel')
  isVisible.value = false
  emit('closed')
}

const onOk = async () => {
  console.log('onOk')

  if (searchString.value) {
    await router.push(`/agent/tour_search_results?search=${searchString.value}`)
    isVisible.value = false
    emit('closed')
  }
}

const onOpened = () => {
  console.log('onOpened')
}

const canSearch = computed(() => {
  return !!searchString.value
})

</script>
