<template>
  <div class="minimizable" ref="modalContainer">
    <CModal
      ref="modal"
      :visible="visible"
      scrollable
      size="xl"
      :fullscreen="true"
      backdrop="static"
      alignment="center"
      @close="onClose"
    >
      <ModalMinimize v-if="modalContainer" :modal-container="modalContainer" />

      <CModalHeader dismiss>
        <CModalTitle :id="`id-title-${WH}`"></CModalTitle>
      </CModalHeader>
      <CModalBody :id="`id-${WH}`" class="position-relative popup-min-h">
        <div class="flex h-100 justify-content-center align-items-center">
          <ImagesView
            :object-data="objectData"
            :active="active"
            :main-image-sizes="mainImageSizes"
            :thumbs="thumbs"
            :images="images"
            :full-screen="fullScreen"
            :upscale-image="upscaleImage"
            @get-data="onGetData"
            @data-ready="onDataReady"
          />
        </div>
      </CModalBody>
      <CModalFooter :id="`id-footer-${WH}`">
        <div
          class="flex flex-wrap gap-3 justify-content-end"
          :id="`id-buttons-${WH}`"
        >
          <div>
            <CButton color="secondary" @click="onClose">Закрыть</CButton>
          </div>
        </div>
      </CModalFooter>
      <div :id="`id-dev-${WH}`"></div>
    </CModal>
  </div>
</template>
<script setup>
import { inject, onMounted, onUnmounted, ref } from 'vue'
import ModalMinimize from '@/components/_common/ModalMinimize.vue'
import ImagesView from '@/components/_custom/images-view/ImagesView.vue'
import { useStore } from 'vuex'

defineProps({
  /**
   * Объект по которому получаем изображения
   * { data_type: string, id: string|number }
   */
  objectData: {
    type: Object,
    required: true,
  },
  /**
   * После маунтинга изображения загрузятся когда active станет true
   * Далее изображения будут перезагружаться если active: true и objectData.id поменялся
   */
  active: {
    type: Boolean,
    default: false,
  },
  // ограничения размеров основного изображения. Размеры нужны для того чтобы фото разных размеров отображались в одинаковом размере.
  mainImageSizes: {
    type: Object,
    required: false,
    default: () => ({
      maxWidth: '1025px',
      height: '670px',
      mediaMaxWidth: {
        w1020: {
          height: '580px',
        },
        w860: {
          height: '480px',
        },
        w580: {
          height: '320px',
        },
      },
    }),
  },
  // размер миниатюр
  thumbs: {
    type: Object,
    required: false,
    default: () => ({
      size: '80px',
    }),
  },
  // полноэкранный режим
  fullScreen: {
    type: Boolean,
    required: false,
    default: false,
  },
  // можно передать изображения, тогда не будет запроса
  images: {
    type: Array,
    default: null,
  },
  // увеличивать фото если оно меньше контейнера, обычно ок для фото отелей, если контейнер не сильно большой
  upscaleImage: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['close'])
const WH = '63e7e9aa-f1b1-4c0d-8bfd-90edc35f7675'
const { storage } = inject('services')
const store = useStore()

const modalContainer = ref(null)
const visible = ref(false)

const onClose = () => {
  visible.value = false
  emit('close')
}
const onGetData = () => {
  store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
}
const onDataReady = () => {
  store.commit('toggleLockEl', { lock: false, target: null })
}

onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
