// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class TourApiMethodsCls {
    async agentContractRetrieve(wh, id, extra_data = null) {
      // Получение док-та договора вознаграждений
      console.log('agentContractRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__agentcontract', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__agentcontract', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentContract.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agentContractUpdate(wh, id, data, extra_data = null) {
      // data: AgentContract
      // Обновление док-та договора вознаграждений
      console.log('agentContractUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentContract.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agentContractPartial(wh, id, data, extra_data = null) {
      // data: AgentContract
      console.log('agentContractPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentContract.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agentSalaryList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка док-тов начисления вознаграждений
      // filters: company, created_at, created_at_range, agent
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('agentSalaryList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalary.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async agentSalaryRetrieve(wh, id, extra_data = null) {
      // Получение док-та начисления вознаграждений
      console.log('agentSalaryRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__agentsalary', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__agentsalary', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalary.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async clientBookingCreate(wh, data, extra_data = null) {
      // data: ClientBooking
      // Создание брони из виджета
      console.log('clientBookingCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.clientBooking.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tourList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка туров
      // filters: id, status, created_at, date_arrive, date_departure, close_till, agency_entity,
      //          agents, xstatus, buyer_contract, amount_fr, amount_to, hotel, location_name,
      //          person, customer, deferred_amount__gte, payment_to_object, location, lead
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('tourList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async tourCreate(wh, data, extra_data = null) {
      // data: TourCreate
      // Создание тура (Tour)
      console.log('tourCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tourRetrieve(wh, id, extra_data = null) {
      // Получение конкретного тура по id
      console.log('tourRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__tour', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__tour', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tourAddAgentPartial(wh, id, data, extra_data = null) {
      // data: TourSetAgent
      // Передача тура другому агенту
      console.log('tourAddAgentPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.addAgent.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tourAddPersonPartial(wh, id, data, extra_data = null) {
      // data: TourAddPerson
      // Добавление персоны (Person)
      console.log('tourAddPersonPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.addPerson.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tourSetAgencyEntityPartial(wh, id, data, extra_data = null) {
      // data: TourSetAgencyEntity
      // Изменение юрлица агенства в туре (legal.Entity)
      console.log('tourSetAgencyEntityPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.setAgencyEntity.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tourSetBankAccountPartial(wh, id, data, extra_data = null) {
      // data: TourSetBankAccount
      // Изменение счета в туре (legal.BankAccount)
      console.log('tourSetBankAccountPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.setBankAccount.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tourSetPercentagePartial(wh, id, data, extra_data = null) {
      // Установка % вознаграждения агентам
      console.log('tourSetPercentagePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.setPercentage.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tourSetSystemEntityPartial(wh, id, data, extra_data = null) {
      // data: TourSetSystemEntity
      // Изменение юридического лица системы в туре (legal.Entity)
      console.log('tourSetSystemEntityPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.setSystemEntity.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.tourApi = new TourApiMethodsCls()
}

