// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class WebsiteApiMethodsCls {
    async programDeepRetrieve(wh, id, {limit, offset, page} = {}, extra_data = null) {
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('programDeepRetrieve')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.program.deep.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.websiteApi = new WebsiteApiMethodsCls()
}

