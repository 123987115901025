import FC from '@/config/constants'
import store from '@/store'

export default async (ctx) => {
  class NotificationService {

    notify(type, text, {header, autohide, delay, dismissible} = {}) {
      console.log(type, text, header, autohide, delay, dismissible)
      store.commit('pushToast', {type, text, header, autohide, delay, dismissible, created_at: new Date()})
    }

    error(text, {header, autohide=false, delay, dismissible} = {}) {
      if (!header) header = 'Ошибка'
      this.notify(FC.NOTIFY.ERROR, text, {header, autohide, delay, dismissible})
    }

    success(text, {header, autohide, delay, dismissible} = {}) {
      if (!header) header = 'Успешно'
      this.notify(FC.NOTIFY.SUCCESS, text, {header, autohide, delay, dismissible})
    }

    warning(text, {header, autohide, delay, dismissible} = {}) {
      if (!header) header = 'Предупреждение'
      this.notify(FC.NOTIFY.WARN, text, {header, autohide, delay, dismissible})
    }

    info(text, {header, autohide, delay, dismissible} = {}) {
      if (!header) header = 'Информация'
      this.notify(FC.NOTIFY.INFO, text, {header, autohide, delay, dismissible})
    }

    primary(text, {header, autohide, delay, dismissible} = {}) {
      if (!header) header = 'Информация'
      this.notify(FC.NOTIFY.PRIMARY, text, {header, autohide, delay, dismissible})
    }

    secondary(text, {header, autohide, delay, dismissible} = {}) {
      if (!header) header = 'Информация'
      this.notify(FC.NOTIFY.SECONDARY, text, {header, autohide, delay, dismissible})
    }

  }

  ctx.$services.notification = new NotificationService()
}
