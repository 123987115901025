// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class IdentitytaskApiMethodsCls {
    async identityTaskList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка задач Identity
      // filters: name__icontains, task_id, status, status__in
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('identityTaskList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.identityTask.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async identityTaskRetrieve(wh, id, extra_data = null) {
      // Получение задачи Identity
      console.log('identityTaskRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__identitytask', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__identitytask', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.identityTask.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityTaskClosePartial(wh, id, data, extra_data = null) {
      // data: IdentityTask
      // Закрыть задачу (параметры не нужны)
      console.log('identityTaskClosePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.identityTask.close.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.identityTaskApi = new IdentitytaskApiMethodsCls()
}

