import CommonTabContent from '@/components/_hotel/PopupHotelInfo/tabs-content/CommonTab.vue'
import RoomsTabContent from '@/components/_hotel/PopupHotelInfo/tabs-content/RoomsTab.vue'
import FoodTabContent from '@/components/_hotel/PopupHotelInfo/tabs-content/FoodTab.vue'
import LeisureTabContent from '@/components/_hotel/PopupHotelInfo/tabs-content/LeisureTab.vue'
import TreatmentTabContent from '@/components/_hotel/PopupHotelInfo/tabs-content/TreatmentTab.vue'
import ProgramsTabContent from '@/components/_hotel/PopupHotelInfo/tabs-content/ProgramsTab.vue'
import PricesTabContent from '@/components/_hotel/PopupHotelInfo/tabs-content/PricesTab.vue'
import ImagesTab from '@/components/_hotel/PopupHotelInfo/tabs-content/ImagesTab.vue'

export default [
  {
    name: 'common',
    icon: 'info',
    title: 'Общие данные',
    content: CommonTabContent,
  },
  {
    name: 'rooms',
    icon: 'home',
    title: 'Проживание',
    content: RoomsTabContent,
  },
  {
    name: 'food',
    icon: 'food',
    title: 'Питание',
    content: FoodTabContent,
  },
  {
    name: 'leisure',
    icon: 'casino',
    title: 'Досуг',
    content: LeisureTabContent,
  },
  {
    name: 'treatment',
    icon: 'medicine',
    title: 'Лечение',
    content: TreatmentTabContent,
  },
  {
    name: 'programs',
    icon: 'daySelect',
    title: 'Программы',
    content: ProgramsTabContent,
  },
  {
    name: 'prices',
    icon: 'bed',
    title: 'Номера и цены',
    content: PricesTabContent,
  },
  {
    name: 'images',
    icon: 'icon',
    title: 'Фото',
    content: ImagesTab,
  },
]
