// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class DocumentTemplateApiMethodsCls {
    async documentTemplateList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение списка шаблонов документов
      // filters: company, type, name, is_default, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('documentTemplateList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async documentTemplateCreate(wh, data, extra_data = null) {
      // data: DocumentTemplate
      // Создание шаблона документа
      console.log('documentTemplateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async documentTemplateRetrieve(wh, id, extra_data = null) {
      // Получение конкретного шаблона документа
      console.log('documentTemplateRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__documenttemplate', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__documenttemplate', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async documentTemplateUpdate(wh, id, data, extra_data = null) {
      // data: DocumentTemplate
      // Изменение шаблона документа
      console.log('documentTemplateUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async documentTemplateDelete(wh, id) {
      // Удаление шаблона документа
      console.log('documentTemplateDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.delete(id)})
      ctx.$services.storage.del('legal__documenttemplate', id, wh)
      return null
    }
    async documentTemplateTypeList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка типов шаблонов документов
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('documentTemplateTypeList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplateType.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async documentTemplateTypeDefaultList(wh, type, extra_data = null) {
      // Получение шаблона по умолчанию для определённого типа шаблонов документов
      console.log('documentTemplateTypeDefaultList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplateType.default.list(type)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.document_templateApi = new DocumentTemplateApiMethodsCls()
}

