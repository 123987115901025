<template>
  <ContainerTableWithMoreBtn
    v-if="telecallItems.length"
    class="custom-adaptive-table"
    :request="getTelecall"
    :have-more="telecallHaveMore"
  >
    <CTable>
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell scope="col">Тип</CTableHeaderCell>

          <CTableHeaderCell scope="col">Id</CTableHeaderCell>

          <CTableHeaderCell scope="col">Когда</CTableHeaderCell>

          <CTableHeaderCell scope="col">Сотрудник</CTableHeaderCell>

          <CTableHeaderCell v-if="havePhoneColumn" scope="col">
            Номер телефона
          </CTableHeaderCell>

          <CTableHeaderCell scope="col">Местный</CTableHeaderCell>

          <CTableHeaderCell scope="col">Статус</CTableHeaderCell>

          <CTableHeaderCell scope="col">Запись</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        <CTableRow
          v-for="item in telecallItems"
          :key="item.id"
          @click="telecallHandlerTableRow(item)"
        >
          <CTableDataCell>
            <TableRowAdaptive>
              <template #mobile-column-title> Тип </template>
              <template #content>
                <TelecallTableRow
                  :info="item"
                  label="event_type_icon"
                  :value="item.formedData.event_type_icon"
                />
              </template>
            </TableRowAdaptive>
          </CTableDataCell>

          <CTableDataCell>
            <TableRowAdaptive>
              <template #mobile-column-title> ID </template>
              <template #content>#{{ item.id }} </template>
            </TableRowAdaptive>
          </CTableDataCell>

          <CTableDataCell>
            <TableRowAdaptive>
              <template #mobile-column-title> Когда </template>
              <template #content>
                <div v-html="item.formedData.created_at" />
              </template>
            </TableRowAdaptive>
          </CTableDataCell>

          <CTableDataCell>
            <TableRowAdaptive>
              <template #mobile-column-title> Сотрудник </template>
              <template #content>
                <TelecallTableRow
                  :info="item"
                  label="identity"
                  :value="item.identity"
                />
              </template>
            </TableRowAdaptive>
          </CTableDataCell>

          <CTableDataCell v-if="havePhoneColumn">
            <TableRowAdaptive>
              <template #mobile-column-title> Номер телефона </template>
              <template #content>{{ item.phone }} </template>
            </TableRowAdaptive>
          </CTableDataCell>

          <CTableDataCell>
            <TableRowAdaptive>
              <template #mobile-column-title> Местный </template>
              <template #content>{{ item.local_phone }} </template>
            </TableRowAdaptive>
          </CTableDataCell>

          <CTableDataCell>
            <TableRowAdaptive>
              <template #mobile-column-title> Статус </template>
              <template #content>
                <TelecallTableRow
                  :info="item"
                  label="status"
                  :value="item.formedData.status"
                />
              </template>
            </TableRowAdaptive>
          </CTableDataCell>

          <CTableDataCell>
            <TableRowAdaptive>
              <template #mobile-column-title>Запись</template>
              <template #content>
                <TelecallTableRow
                  :info="item"
                  label="record_url"
                  :value="item.record_url"
                />
              </template>
            </TableRowAdaptive>
          </CTableDataCell>
        </CTableRow>
      </CTableBody>
    </CTable>
  </ContainerTableWithMoreBtn>
  <div v-else>Нет звонков</div>

  <TelecallFullinfoModal
    :info="telecallRowFullinfo"
    @closed="telecallRowFullinfoClear"
  />
</template>

<script setup>
import { inject, computed } from 'vue'
import ContainerTableWithMoreBtn from '@/components/table/ContainerTableWithMoreBtn.vue'
import TelecallFullinfoModal from '@/components/_telecall/TelecallFullinfoModal.vue'
import TableRowAdaptive from '@/components/custom/TableRowAdaptive.vue'
import TelecallTableRow from '@/components/_telecall/TelecallTableRow.vue'

const props = defineProps({
  typeEntity: {
    type: String,
    default: '',
  },
})

const getTelecall = inject('getTelecall')
const telecallHandlerTableRow = inject('telecallHandlerTableRow')
const telecallHaveMore = inject('telecallHaveMore')
const telecallItems = inject('telecallItems', [])
const telecallRowFullinfo = inject('telecallRowFullinfo', [])
const telecallRowFullinfoClear = inject('telecallRowFullinfoClear')

const havePhoneColumn = computed(() => {
  return props.typeEntity === 'telephone__phone'
})
</script>
