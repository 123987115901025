import { debounce } from 'lodash'
import FC from '@/config/constants'

export default ctx => {
  class TableService  {
    /**
     * @param {function} requestFn
     * @param {number} [ms]
     * @returns {function({}): Promise<{ data: {count: number, data: Array} }>}
     */
    requestFnDebounceWrap = (requestFn, ms = FC.DEBOUNCE_TIMEOUT) => {
      const debounced = debounce((req, resolve, reject) => {
        requestFn(req)
          .then(data => {
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      }, ms)

      return (req) => {
        return new Promise((resolve, reject) => {
          debounced(req, resolve, reject)
        })
      }
    }
  }

  ctx.$services.tableService = new TableService()
}
