import { reactive } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import C from '@/config/back_const'
import { getWSMessage } from '@/helpers'

function formFailedTaskMessage({ name, status, task_id, result } = {}) {
  const taskName = `${name || `Task ID: ${task_id}`}`
  // берём первые 10 ошибок для вывода
  const errorsStr =
    result?.errors
      ?.filter((err) => err)
      ?.slice(0, 10)
      ?.join('\n') || ''

  if (status === C.IDENTITY_TASK_STATUS_FAILURE) {
    return `${taskName} \n${C.IDENTITY_TASK_STATUS_TITLES[status]} ${
      errorsStr ? `\nОшибки: \n${errorsStr}` : ''
    }`
  }

  if (errorsStr) {
    return `${taskName} \nВыполнена с ошибками: \n${errorsStr}`
  }

  return `${taskName} \nВыполнена неуспешно`
}

// docs EXPECTANT_SERVICE.md
export default (ctx) => {
  class ExpectantService {
    tasks = new Set()
    windowHandlers = {}
    completedTasks = {}
    failedTasks = {}
    subscribers = {}
    subscribersFailure = {}
    processingTask = null
    state = reactive({
      tasks: [],
      completedTasks: {},
      failedTasks: {},
    })

    // обновляет состояние
    _update() {
      this.state.tasks = Array.from(this.tasks)
      this.state.completedTasks = this.completedTasks
      this.state.failedTasks = this.failedTasks
    }

    // когда задача переходит в completed или failed удаляет задачу из this.tasks и удаляет её подписчиков
    _deleteTask(id) {
      this.tasks.delete(id)
      delete this.subscribers[id]
      delete this.subscribersFailure[id]
      ctx.$services.persistentService.set('queueTasks', Array.from(this.tasks))
    }

    _failDone(data) {
      this._deleteTask(data.id)
      delete this.windowHandlers[data.id]
      this.failedTasks[data.id] = data
      this.processingTask = null
      this._update()
      const errMessage = formFailedTaskMessage(data)
      ctx.$services.notification.error(`${errMessage}`)
    }

    // все колбеки подписчиков выполнены
    _failAllSubsDone(data) {
      if (this.subscribersFailure[data.id].every((s) => s.done)) {
        this._failDone(data)
      }
    }

    _taskFail(data) {
      if (this.subscribersFailure[data.id]) {
        for (const sub of this.subscribersFailure[data.id]) {
          if (sub.cb.constructor.name === 'AsyncFunction') {
            sub.cb(data).then(() => {
              sub.done = true
              this._failAllSubsDone(data)
            })
          } else {
            setTimeout(() => {
              sub.cb(data)
              sub.done = true
              this._failAllSubsDone(data)
            })
          }
        }
      } else {
        this._failDone(data)
      }
    }

    _successDone(data) {
      this._deleteTask(data.id)
      this.processingTask = null
      this._update()
      if (data.status === C.IDENTITY_TASK_STATUS_CLOSED) {
        ctx.$services.notification.info(`Завершено: ${data.name}`)
      } else {
        this.completedTasks[data.id] = data
        ctx.$services.notification.success(`${data.name}`)
      }
    }

    // все колбеки подписчиков выполнены
    _successAllSubsDone(data) {
      if (this.subscribers[data.id].every((s) => s.done)) {
        this._successDone(data)
      }
    }

    _taskSuccess(data) {
      if (data.status !== C.IDENTITY_TASK_STATUS_CLOSED) {
        ctx.$services.storage.put_response(
          data.result,
          this.getWH(data.id),
          false,
        )
      }

      // вызываются коллбеки подписчиков
      // done отмечает, что коллбек выполнен
      if (this.subscribers[data.id]) {
        for (const sub of this.subscribers[data.id]) {
          // для async функций
          if (sub.cb.constructor.name === 'AsyncFunction') {
            sub.cb(data).then(() => {
              sub.done = true
              this._successAllSubsDone(data)
            })
          } else {
            // setTimeout чтобы не блокировать приложение синхронными коллбеками
            setTimeout(() => {
              sub.cb(data)
              sub.done = true
              this._successAllSubsDone(data)
            })
          }
        }
      } else {
        this._successDone(data)
      }
    }

    // init вызывается в методе connect WebsocketService
    // будет также вызван при переподключениях WS
    init(ws) {
      ctx.$services.persistentService.get('queueTasks').then((tasks) => {
        if (tasks && tasks.length) {
          // todo отфильтровать уже выполненные
          // нужен способ получения task_id на беке тех задач, которые не завершены
          tasks.forEach((id) => {
            this.addTask(id)
          })
          ctx.$services.persistentService.set(
            'queueTasks',
            Array.from(this.tasks),
          )
        }
      })

      ws.addEventListener('message', (ev) => {
        const message = getWSMessage(ev)
        if (!message) return

        const type = message.type
        const data = message.data
        const result = data.result || {}

        if (
          type !== 'notifier' ||
          !data ||
          data.data_type !== 'core__identitytask'
        )
          return

        // небольшая задержка для того чтобы успел отработать catch на QueuedResponseException при запросе import/{id}/process/
        // сообщение по веб сокету может прийти раньше чем выполнится catch
        // в catch могут устанавливаться подписки на выполнение задачи.
        setTimeout(() => {
          this.processingTask = data.id

          if (
            data.status === C.IDENTITY_TASK_STATUS_FAILURE ||
            result.success === 'false' ||
            result.success === false
          ) {
            this._taskFail(data)
          } else if (
            data.status === C.IDENTITY_TASK_STATUS_SUCCESS ||
            data.status === C.IDENTITY_TASK_STATUS_CLOSED
          ) {
            this._taskSuccess(data)
          }
        }, 250)
      })
    }

    // добавление новой задачи
    addTask(id) {
      const wh = uuidv4()
      this.tasks.add(id)
      this.windowHandlers[id] = wh
      ctx.$services.persistentService.set('queueTasks', Array.from(this.tasks))
      this._update()
    }

    // завершение задачи
    closeTask(id) {
      const wh = this.getWH(id)
      ctx.$services.identityTaskApi
        .identityTaskClosePartial(wh, id)
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          delete this.windowHandlers[id]
          delete this.subscribers[id]
          delete this.subscribersFailure[id]
          delete this.completedTasks[id]
          delete this.failedTasks[id]
          ctx.$services.storage.clr(wh)
          this._update()
        })
    }

    // получить состояние задач. Возвращает реактивный объект.
    getState() {
      return this.state
    }

    // получить WH задачи по id
    getWH(id) {
      return this.windowHandlers[id]
    }

    // подписать коллбек на выполнение задачи
    subscribe(id, cb) {
      if (this.processingTask === id) return false
      this.subscribers[id] = this.subscribers[id] || []
      this.subscribers[id].push({ cb, done: false })
      return true
    }

    // подписать коллбек на ошибку задачи
    subscribeFailure(id, cb) {
      if (this.processingTask === id) return false
      this.subscribersFailure[id] = this.subscribersFailure[id] || []
      this.subscribersFailure[id].push({ cb, done: false })
      return true
    }
  }

  ctx.$services.expectant = new ExpectantService()
}
