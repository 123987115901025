<template>
  <CSpinner v-if="load" component="span" size="sm" aria-hidden="true" />
  <TableTelecallForEntity v-else />
</template>

<script setup>
import { computed, provide, ref, readonly, inject, onMounted } from 'vue'
import { useTelecallsForSimpleTable } from '@/composables/telecalls/telecalls-simple-table'
import TableTelecallForEntity from '@/components/custom/TableTelecallForEntity.vue'

const props = defineProps({
  id: {
    type: [Number],
    required: true,
  },
  typeEntity: {
    type: [String],
    required: true,
  },
  wh: {
    type: [String],
    required: true,
  },
  //сколько брать новых записей
  offset: {
    type: [Number],
    default: 20,
  },
})

const { telephonyApi } = inject('services')

const telecallItems = ref([])
const telecallItemsCount = ref(0)
const telecallOffset = ref(0)
const telecallHaveMore = computed(() => {
  return telecallItems.value.length < telecallItemsCount.value
})

provide('telecallItems', readonly(telecallItems))
provide('telecallItemsCount', readonly(telecallItemsCount))
provide('telecallOffset', readonly(telecallOffset))
provide('telecallHaveMore', readonly(telecallHaveMore))

const {
  rowFullinfo: telecallRowFullinfo,
  handlerTableRow: telecallHandlerTableRow,
  getTelecall,
  load,
} = useTelecallsForSimpleTable(
  props.wh,
  (extId) =>
    telephonyApi.telecallsForObjectList(props.wh, props.typeEntity, extId, {
      limit: props.offset,
      offset: telecallOffset.value,
    }),
  {
    afterAction: (data) => {
      telecallItems.value = [...telecallItems.value, ...data.data]
      telecallItemsCount.value = data.count
      telecallOffset.value += props.offset
    },
  },
)

provide('telecallRowFullinfo', readonly(telecallRowFullinfo))
provide('telecallRowFullinfoClear', () => (telecallRowFullinfo.value = null))
provide('telecallHandlerTableRow', readonly(telecallHandlerTableRow))
provide('getTelecall', getTelecall)

onMounted(async () => {
  await getTelecall(props.id)
})
</script>
