// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class MediaApiMethodsCls {
    async f2oRetrieve(wh, id, extra_data = null) {
      // Получение файла по id
      console.log('f2oRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('media__filetoobject', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'media__filetoobject', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.f2o.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async filePut(wh, data, extra_data = null) {
      // data: FileGet
      // Получение файлов
      console.log('filePut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.file.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async fileImportCreate(wh, data, extra_data = null) {
      // data: {title}
      // Загрузка файла для импорта
      console.log('fileImportCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.file.import.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async fileUploadCreate(wh, data, extra_data = null) {
      // data: {object_type, object_id, title}
      // Загрузка файла
      console.log('fileUploadCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.file.upload.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async fileListForObjectList(wh, data_type, id, extra_data = null) {
      // Получение всех файлов объекта
      console.log('fileListForObjectList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.file.listForObject.list(data_type, id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async fileDelete(wh, id) {
      // Удаление файла
      console.log('fileDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.media.file.delete(id)})
      ctx.$services.storage.del('media__file', id, wh)
      return null
    }
    async i2oRetrieve(wh, id, extra_data = null) {
      // Получение изображения по id
      console.log('i2oRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('media__imagetoobject', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'media__imagetoobject', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.i2o.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async imagePut(wh, data, extra_data = null) {
      // data: ImageGet
      // Получение изображений
      console.log('imagePut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.image.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async imageUploadCreate(wh, data, extra_data = null) {
      // data: {object_type, object_id, fill_color, width, height, sort_order, title, fixed_title}
      // Загрузка изображения
      console.log('imageUploadCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.image.upload.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async imageListForObjectList(wh, data_type, id, extra_data = null) {
      // Получение всех изображений объекта
      console.log('imageListForObjectList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.image.listForObject.list(data_type, id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async imageDelete(wh, id) {
      // Удаление изображения
      console.log('imageDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.media.image.delete(id)})
      ctx.$services.storage.del('media__image', id, wh)
      return null
    }
    async importFileList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка всех импортов файлов
      // filters: created_at, type, state, operator_identity, period_after, period_before
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('importFileList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.importFile.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async importFilePruneDelete(wh) {
      // Удаление всех незавершенных импортов файлов
      console.log('importFilePruneDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.media.importFile.prune.delete()})
      ctx.$services.storage.del('None', id, wh)
      return null
    }
    async importFileRetrieve(wh, id, extra_data = null) {
      // Получение импорта файла
      console.log('importFileRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('media__importfile', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'media__importfile', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.media.importFile.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async importFileDelete(wh, id) {
      // Удаление импорта
      console.log('importFileDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.media.importFile.delete(id)})
      ctx.$services.storage.del('media__importfile', id, wh)
      return null
    }
  }

  ctx.$services.mediaApi = new MediaApiMethodsCls()
}

