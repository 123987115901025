<template>
  <template v-if="Array.isArray(phones) && withInfo">
    <span v-for="(phoneObj, i) in phones" :key="phoneObj.number">
      <CTooltip :content="phoneObj.info">
        <template #toggler="{ on }">
          <a
            v-on="phoneObj.info ? on : ''"
            href=""
            @click.prevent="
              bus.emit('webphone-set-dial', {
                dialNumber: phoneObj.number,
              })
            "
            class="form-label-dotted"
            >{{ phoneObj.number }}</a
          >
        </template>
      </CTooltip>
      <template v-if="i + 1 < phones.length">,&nbsp; </template>
    </span>
  </template>
  <template v-else-if="Array.isArray(phones)">
    <span v-for="(phone, i) in phones" :key="phone">
      <a
        href=""
        @click.prevent="
          bus.emit('webphone-set-dial', {
            dialNumber: phone,
          })
        "
        class="form-label-dotted"
        >{{ phone }}</a
      >
      <template v-if="i + 1 < phones.length">,&nbsp; </template>
    </span>
  </template>
  <template v-else>{{ phones }}</template>
</template>

<script setup>
import { inject } from 'vue'

defineProps({
  phones: {
    type: Array,
    required: false,
    default: () => [],
  },
  withInfo: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const bus = inject('bus')
</script>
