// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class FinanceApiMethodsCls {
    async entityPaymentsRetrieve(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка платежей для юр. лица системы
      // filters: created_at, updated_at, owner_entity, operator_identity, state, object_type,
      //          doc_number, doc_date_after, doc_date_before, tour, import_payments,
      //          payment_doc_number, payment_doc_date_after, payment_doc_date_before, amount, type,
      //          cash, owner_bank_account, contractor_bank_account, info, split_amount, booking_id,
      //          pk, contractor_entity, split
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('entityPaymentsRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__payment', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__payment', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.entity.payments.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.financeApi = new FinanceApiMethodsCls()
}

