<template>
  <div class="minimizable" ref="modalContainer">
    <CModal
      ref="modal"
      :visible="isVisible"
      size="xl"
      backdrop="static"
      alignment="top"
      fullscreen="lg"
      scrollable
      @close="onClosed"
    >
      <ModalMinimize v-if="modalContainer" :modal-container="modalContainer" />
      <CModalHeader
        dismiss
        class="flex align-items-start justify-content-between"
      >
        <div class="flex flex-wrap col-10 align-items-center">
          <CModalTitle class="flex-shrink-0 me-4">
            <h4 class="pt-1">{{ hotel?.title }}</h4>
          </CModalTitle>
          <div class="col-10 col-sm-6 col-md-4 col-lg-3">
            <Autocomplete
              v-if="hotel?.title"
              v-model="hotelAC"
              placeholder="Выберите другой отель"
              :search-area="[
                {
                  content_type: 'refbook__hotel',
                },
              ]"
              size="sm"
            />
          </div>
        </div>
      </CModalHeader>
      <CModalBody :id="`id-${WH}`" class="position-relative">
        <NavAndTabs
          :hotel="hotel"
          @get-data="showSpinner"
          @data-ready="hideSpinner"
        />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onCancel">Закрыть</CButton>
      </CModalFooter>
      <ThePodval
        title="Окно информации об отеле"
        :tasks="[
          'https://tracker.yandex.ru/BACK-1361',
          'https://tracker.yandex.ru/BACK-1169',
          'https://tracker.yandex.ru/BACK-1136',
          'https://tracker.yandex.ru/BACK-3985',
          'https://tracker.yandex.ru/BACK-3984',
          'https://tracker.yandex.ru/BACK-3912',
          'https://tracker.yandex.ru/BACK-4336',
        ]"
        :wiki="[]"
        :uuid="WH"
      />
    </CModal>
  </div>
</template>

<script setup>
import {
  defineEmits,
  defineProps,
  inject,
  onMounted,
  onUnmounted,
  ref,
  watch,
  nextTick,
} from 'vue'
import { useStore } from 'vuex'
import NavAndTabs from '@/components/_hotel/PopupHotelInfo/NavAndTabs.vue'
import Autocomplete from '@/components/custom/Autocomplete.vue'
import ModalMinimize from '@/components/_common/ModalMinimize.vue'
import ThePodval from '@/components/_shared/ThePodval.vue'

const WH = '5eeb1d46-ed22-406e-9169-a02c747f9081'
const props = defineProps({
  hotelId: {
    type: [String, Number],
    required: true,
  },
})
const emit = defineEmits(['closed'])

const { hotelApi, hotelServiceApi, storage } = inject('services')
const store = useStore()

const isVisible = ref(false)
const modalContainer = ref(null)

const hotel = ref({})
const hotelAC = ref(null)

const onClosed = () => {
  isVisible.value = false
  emit('closed')
}
const onCancel = () => {
  isVisible.value = false
  emit('closed')
}

const showSpinner = () => {
  store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
}
const hideSpinner = () => {
  store.commit('toggleLockEl', { lock: false, target: null })
}

const getService = async (id) => {
  try {
    return await hotelServiceApi.hotelServiceRetrieve(WH, id)
  } catch (error) {
    console.error(error)
  }
}
const processHotelData = async () => {
  if (hotel.value?.services?.length) {
    hotel.value.servicesList = await Promise.all(
      hotel.value.services.map((id) => getService(id)),
    )
  }
}
const fetchHotel = async (id) => {
  try {
    await nextTick(showSpinner)
    hotel.value = await hotelApi.hotelRetrieve(WH, id || props.hotelId)
    await processHotelData()
  } catch (error) {
    console.error(error)
  } finally {
    hideSpinner()
  }
}

watch(hotelAC, (val) => {
  if (val) fetchHotel(val.id)
})

onMounted(() => {
  isVisible.value = true
  fetchHotel()
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
