export const formatRubles = (number = 0) => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(number)
}

export const centToRubles = (cent = 0, round) => {
  return round ? Math.ceil(cent / 100) : cent / 100
}

export const formatCents = (cent = 0) => {
  return formatRubles(centToRubles(cent))
}

export const formatCentsRounded = (cent = 0) => {
  return formatRubles(centToRubles(cent, true))
}

export const strLimit = (value, size) => {
  if (!value) return ''
  value = value.toString()

  if (value.length <= size) {
    return value
  }
  return value.substring(0, size) + '...'
}

/*
склонение по множественому числу
const num = 8
`${num} ${inflectNoun(num, ['день', 'дня', 'дней'])}`
*/
export const inflectNoun = (num, words) => {
  if (num >= 0 && num !== null) {
    const prepNum = num < 20 ? num : Number(String(num).slice(-1))
    if (prepNum === 1) {
      return words[0]
    }
    if (prepNum > 0 && prepNum <= 4) {
      return words[1]
    }
    return words[2]
  }
  return ''
}

export const inflectNounYear = (val) => {
  const text = inflectNoun(val, ['год', 'года', 'лет'])
  return val ? `, ${val} ${text}` : ''
}

//для случаев множественного числа типа "от 1 до 2 лет, от 0 до 1 года"
export const inflectNounYears = (val) => {
  const text = inflectNoun(val, ['года', 'лет', 'лет'])
  return val ? `, ${val} ${text}` : ''
}

export const capitaliseFirst = (str) => {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1)
}

export const carText = (car) => {
  const { model = '', number = '', seats = '', luggage_seats = '' } = car || {}
  const carTextSep = model && number ? ', ' : ''
  const carSeatsText = seats ? `, мест: пасс. ${seats}` : ''
  const carLSeatsText = luggage_seats ? `, багаж. ${luggage_seats}` : ''
  return `${model}${carTextSep}${number}${carSeatsText}${carLSeatsText}`
}

export const formatFileSize = (bytes) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let size = bytes
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024
    unitIndex++
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`
}

export const formatPersonInfo = (personObj) => {
  const person = personObj || {}
  const { fio = '', gender = '', age = '' } = person
  const shortGender = gender ? `, ${gender[0]}` : ''
  const ageText = age
    ? `, ${age} ${inflectNoun(age, ['год', 'года', 'лет'])}`
    : ''
  return `${fio}${shortGender}${ageText}`
}

export default {
  formatRubles: formatRubles,
  centToRubles: centToRubles,
  formatCents: formatCents,
  formatCentsRounded: formatCentsRounded,
  strLimit: strLimit,
  inflectNoun,
  capitaliseFirst,
}
