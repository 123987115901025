// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class SupportApiMethodsCls {
    async screenList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка экранов
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('screenList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.support.screen.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async screenCreate(wh, data, extra_data = null) {
      // data: Screen
      console.log('screenCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.support.screen.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async screenRetrieve(wh, id, extra_data = null) {
      console.log('screenRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('support__screen', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'support__screen', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.support.screen.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async screenUpdate(wh, id, data, extra_data = null) {
      // data: Screen
      console.log('screenUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.support.screen.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async screenDelete(wh, id) {
      console.log('screenDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.support.screen.delete(id)})
      ctx.$services.storage.del('support__screen', id, wh)
      return null
    }
    async screenPartial(wh, id, data, extra_data = null) {
      // data: Screen
      console.log('screenPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.support.screen.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.supportApi = new SupportApiMethodsCls()
}

