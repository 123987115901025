<template>
  <CFormLabel v-if="label">{{ label }}</CFormLabel>
  <CFormInput
    :placeholder="placeholder ? placeholder : ''"
    v-model="phoneNumber"
    @blur="v$.phoneNumber.$touch()"
    @input="onInput"
    @keyup="onKeyup"
    :invalid="invalidPhone"
    v-maska="[
      '+# (###) ###-##-##',
      '+## (###) ###-##-##',
      '+### (###) ###-##-##',
    ]"
    :disabled="props.readonly"
  />
  <CFormFeedback v-if="v$.phoneNumber.$error" :tooltip="errorTooltip" invalid>
    {{ v$.phoneNumber.$silentErrors[0].$message }}
  </CFormFeedback>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { phone } from '@/helpers/vValidators'
const emit = defineEmits([
  'update:modelValue',
  'input',
  'keyup',
  'have-input-phone-error',
  'clear-val',
])

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: [String, Boolean],
    default: () => false,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  label: {
    type: [String, Boolean],
    default: () => false,
  },
  single: {
    type: Boolean,
    default: () => false,
  },
  errorTooltip: {
    type: Boolean,
    default: () => false,
  },
  readonly: {
    type: Boolean,
    default: () => false,
  },
  clearVal: {
    type: Boolean,
    default: false,
  },
})

const phoneNumber = ref(String(props.modelValue))

watch(phoneNumber, (phoneNumberValue) => {
  if (phoneNumberValue?.[0] == 8) {
    phoneNumberValue = '+7' + phoneNumberValue.slice(1)
  } else if (phoneNumberValue?.[0] == 9) {
    phoneNumberValue = '+79' + phoneNumberValue.slice(1)
  }
  if (props.single) {
    v$.value.$validate()
    if (!v$.value.$invalid) {
      emit('update:modelValue', phoneNumberValue)
      emit('have-input-phone-error', false)
    } else {
      emit('have-input-phone-error', true)
      emit('update:modelValue', '')
    }
  } else {
    emit('update:modelValue', phoneNumberValue)
    emit('have-input-phone-error', false)
  }
  phoneNumber.value = phoneNumberValue
})

//пришлось сделать тк локальный номер постоянно эмитится по внешний(в вотче выше), но в строке должен оставаться локальный
//что бы при вводе не сбивалось значение и юзер мог дописать номер в правильном формате
watch(
  () => props.clearVal,
  (val) => {
    if (val) {
      phoneNumber.value = ''
      emit('clear-val', true)
    }
  },
)

const onInput = (ev) => {
  emit('input', ev)
}

const onKeyup = () => {
  emit('keyup')
}

const rules = computed(() => ({
  phoneNumber: {
    phone,
  },
}))
const v$ = useVuelidate(
  rules,
  { phoneNumber },
  { $stopPropagation: props.single },
)
const invalidPhone = computed(() => {
  return v$.value.phoneNumber?.$error || props.invalid
})
</script>
