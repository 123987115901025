<template>
  <div
    class="phones-edit-row position-relative d-flex flex-column flex-md-row mb-4 mb-md-3"
  >
    <div class="inputs w-100 d-flex flex-column">
      <div class="col d-flex flex-column flex-md-row">
        <div class="col-md-3 position-relative mb-2 mb-md-0 me-md-2 min-w-4">
          <PhoneInput
            v-if="phoneLocalObject.new"
            placeholder="Введите номер"
            @input="
              (ev) => {
                onInputPhone(ev)
              }
            "
            @have-input-phone-error="onHaveInputPhoneError"
            v-model="phoneLocalObject.number"
            error-tooltip
            single
          />
          <CFormInput
            v-else
            v-model="phoneLocalObject.number"
            :disabled="!phoneLocalObject.new"
          />
          <div
            v-if="errors.length && !phoneIsUnique"
            class="invalid-tooltip error-unique"
            :class="{ show: true }"
          >
            Телефон уже существует
          </div>
        </div>
        <div class="col position-relative mb-2 mb-md-0 me-md-2">
          <CFormInput
            v-model="phoneLocalObject.info"
            placeholder="Комментарий"
          />
        </div>
      </div>
      <div
        v-if="errors?.number"
        class="col block-errors mt-md-2 mb-md-0 me-md-2"
      >
        <ServerFormErrors :errors="errors?.number"></ServerFormErrors>
      </div>
    </div>
    <CTooltip
      v-if="(isNew && canSave) || (!isNew && canUpdate)"
      :content="isNew ? 'Сохранить номер' : 'Обновить номер'"
      placement="top"
    >
      <template #toggler="{ on }">
        <CButton
          color="success"
          variant="outline"
          class="mb-2 mb-md-0 me-md-2"
          v-on="on"
          @click="phoneSave"
        >
          <CIcon v-if="!saving" :icon="cilCheckAlt" size="sm" />
          <CSpinner v-else color="dark" size="sm" />
        </CButton>
      </template>
    </CTooltip>
    <CTooltip :content="isNew ? 'Отменить' : 'Удалить номер'" placement="top">
      <template #toggler="{ on }">
        <CButton
          :color="isNew ? 'secondary' : 'danger'"
          variant="outline"
          v-on="on"
          @click="phoneDelete"
          :disabled="deleting"
        >
          <template v-if="!isNew">
            <CIcon v-if="!deleting" :icon="cilTrash" size="sm" />
            <CSpinner v-else color="dark" size="sm" />
          </template>
          <template v-else>
            <CIcon :icon="cilX" size="sm" />
          </template>
        </CButton>
      </template>
    </CTooltip>
  </div>
</template>

<script>
import { cilTrash, cilCheckAlt, cilX } from '@coreui/icons'
import {computed, inject, onMounted, onUnmounted, reactive, ref} from 'vue'
import cloneDeep from 'lodash.clonedeep'
import PhoneInput from "@/components/form/PhoneInput"
import ServerFormErrors from "@/components/form/ServerFormErrors"

export default {
  name: 'PhonesEditRow',
  components: {
    ServerFormErrors,
    PhoneInput
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue', 'complete-action'],

  setup(props, {emit}) {

    const {
        phoneApi
    } = inject('services')

    const {
      WH,
      objectType,
      objectId,
      phonesList
    } = inject('requestParams')

    const bus = inject('bus')

    const inputPhoneCheck = reactive({
      hasError: false,
      hasInput: false
    })

    const phoneLocalObject = ref(props.modelValue)
    const phoneCloneObject = ref(cloneDeep(props.modelValue))
    const phoneIsUnique = ref(true)
    const rawInput = ref('')
    const havePhoneIpnutError = ref(false)

    const saving = ref(false)
    const deleting = ref(false)

    const errors = ref([])

    const isNew = computed(() => {
      return !!phoneLocalObject.value.new
    })

    const onHaveInputPhoneError = (value) => {
      havePhoneIpnutError.value = value
      inputPhoneCheck.hasError = value
    }

    const isUniquePhone = (newPhone) => {
      let isUnique = true
      errors.value = []
      if(!havePhoneIpnutError.value){
        for(let phone of phonesList.value.data.slice(0,-1)) {
          isUnique = !phone.number.includes(newPhone.number)
          if (!isUnique){
            errors.value.push('Телефон уже существует')
            return isUnique
          } else {
            errors.value = []
          }
        }

        return isUnique
      }
    }

    const canSave = computed(() => {
      if(phoneLocalObject.value.new &&
        phoneLocalObject.value.number !== '' &&
        phoneLocalObject.value.number.length >= 7) {
        resetUniquesPhone()
        return true
      }
      return false
    })

    const resetUniquesPhone = () => {
      errors.value = []
      phoneIsUnique.value = true
    }

    const canUpdate = computed(() => {
      if(!phoneLocalObject.value.new && phoneLocalObject.value.info !== phoneCloneObject.value.info) {
        return true
      }
      return false
    })

    const phoneCreate = async () => {
      if(isUniquePhone(phoneLocalObject.value)){
        try {
          saving.value = true
          if (!objectId) {
            // подготовка локаных значений (когда объект для которого устанавливаются номера ещё не создан)
            delete phoneLocalObject.value.new
            // PhoneInput возаращает номер без + а для локального значения нужен с + (rawInput). Чтобы не отличаться от тех, что приходят с сервера.
            // TO DO теперь инпут телефона возвращает с +, надо будет отрефакторить - убрать rawInput совсем
            phoneLocalObject.value.number = rawInput.value
            rawInput.value = ''
          }
          const response = objectId ? await phoneApi.phoneForObjectCreate(
            WH,
            objectType,
            objectId,
            {
              number: phoneLocalObject.value?.number,
              info: phoneLocalObject.value?.info
            }) : phoneLocalObject.value
          phoneCloneObject.value = cloneDeep(response)
          emit('update:modelValue', response)
        }
        catch(error) {
          console.error(error, error?.response);
          if (error?.response?.data) {
            errors.value = error.response.data
            console.log(errors.value)
          }
        }
        finally {
          saving.value = false
        }

      } else {
        phoneIsUnique.value = false
      }
      inputPhoneCheck.hasError = false
      inputPhoneCheck.hasInput = false
      emit('complete-action')
    }

    const phoneUpdate = async () => {
      try {
        saving.value = true
        const id = props.modelValue.id
        if (!id) {
          console.error('Невозможно обновить: нет id у объекта телефона')
          return
        }

        const response = objectId ? await phoneApi.phoneUpdate(WH, id,
          {
              info: phoneLocalObject.value.info
          }) : phoneLocalObject.value
        const result =  {...props.modelValue, ...response}
        phoneCloneObject.value = result
        emit('update:modelValue', result)
      }
      catch(error) {
        console.log(error);
      }
      finally {
        saving.value = false
      }
      emit('complete-action')
    }

    const phoneSave = async () => {
      isNew.value ? await phoneCreate() : await phoneUpdate()
    }

    const phoneDelete = async () => {
      try {
        deleting.value = true

        const indexInList = phonesList.value.data.findIndex(phone => phone.id === phoneLocalObject.value.id)
        if (indexInList > -1) phonesList.value.data.splice(indexInList, 1)

        if(!phoneLocalObject.value.new && objectId) {
          const id = props.modelValue.id
          if (!id) {
            console.error('Невозможно удалить: нет id у объекта телефона')
            return
          }
          await phoneApi.phoneDelete(WH, id)
        }
      }
      catch(error) {
        console.error(error);
      }
      finally {
        deleting.value = false
      }
      emit('complete-action')
    }

    const onInputPhone = (ev) => {
      if (ev?.target) {
        rawInput.value = ev.target.value
      }
      inputPhoneCheck.hasInput = !!ev?.target?.value
    }

    const savePhoneInputBusHandler = () => {
      if (inputPhoneCheck.hasInput && !inputPhoneCheck.hasError) {
        phoneSave()
      }
    }

    onMounted(() => {
      bus.on('save-phone-input', savePhoneInputBusHandler)
    })

    onUnmounted(() => {
      bus.off('save-phone-input', savePhoneInputBusHandler)
    })

    return {
      cilTrash, cilX, cilCheckAlt,
      phoneIsUnique,
      phoneLocalObject,
      phoneCloneObject,
      saving,
      deleting,
      isNew,
      canSave,
      canUpdate,
      phoneCreate,
      phoneUpdate,
      phoneSave,
      phoneDelete,
      onInputPhone,
      errors,
      onHaveInputPhoneError,
      inputPhoneCheck
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  height: fit-content;
}

.error-unique {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000015;
  background-color: rgba(229, 83, 83, 0.9);
  border-radius: 0.375rem;

  &.show {
    display: block;
  }
}
</style>
