<template>
  <ImagesView
    :object-data="objectData"
    :active="active"
    @get-data="emit('get-data')"
    @data-ready="emit('data-ready')"
  />
</template>

<script setup>
import { computed } from 'vue'
import ImagesView from '@/components/_custom/images-view/ImagesView.vue'

const props = defineProps({
  hotel: {
    type: Object,
    required: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['get-data', 'data-ready'])

const objectData = computed(() => ({
  data_type: props.hotel?.data_type,
  id: props.hotel?.id,
}))
</script>
