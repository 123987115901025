export const entityViewPermissions = {
  VIEW: 'view',
  CHANGE: 'change',
  CONTACTS: 'contacts',
}

// TODO рефактор удалить и использовать везде usePermissions

export const entityViewMode = {
  VIEW: 'VIEW',
  CHANGE: 'CHANGE',
  CREATE: 'CREATE',
  DENIED: 'DENIED',
}
