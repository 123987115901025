import { v4 as uuidv4 } from 'uuid'

const id =  uuidv4()
let unloading = false

function getWinTrackerLS () {
  return JSON.parse(window.localStorage.getItem('win-tracker')) || { windows: {}, lastActive: [] }
}
function setWinTrackerLS (data) {
  window.localStorage.setItem('win-tracker', JSON.stringify(data))
}
function addLastActive (winTracker) {
  if (winTracker.lastActive[0] === id) {
    return winTracker.lastActive
  }
  winTracker.lastActive = winTracker.lastActive.filter(item => item !== id)
  winTracker.lastActive.unshift(id)
  return winTracker.lastActive
}
function rmLastActive (winTracker) {
  return winTracker.lastActive.filter(item => item !== id)
}
function visibilityHandle () {
  if (unloading) {
    return
  }
  const winTracker = getWinTrackerLS()
  winTracker.windows[id] = winTracker.windows[id] || {}
  if (document.visibilityState === 'visible') {
    winTracker.windows[id].visible = true
    winTracker.lastActive = addLastActive(winTracker)
  } else {
    winTracker.windows[id].visible = false
  }
  setWinTrackerLS(winTracker)
}
function setFocusState (focusState) {
  if (unloading) {
    return
  }
  const winTracker = getWinTrackerLS()
  if (focusState) {
    winTracker.lastActive = addLastActive(winTracker)
  }
  winTracker.windows[id] = winTracker.windows[id] || {}
  winTracker.windows[id].focused = focusState
  setWinTrackerLS(winTracker)
}
function focusHandle () {
  setFocusState(true)
}
function blurHandle () {
  setFocusState(false)
}
function beforeUnloadHandle () {
  unloading = true
  const winTracker = getWinTrackerLS()
  winTracker.lastActive = rmLastActive(winTracker)
  delete winTracker.windows[id]
  setWinTrackerLS(winTracker)
}

export function setWinTracker () {
  document.addEventListener('visibilitychange', visibilityHandle)
  window.addEventListener('focus', focusHandle)
  window.addEventListener('blur', blurHandle)
  window.addEventListener('beforeunload', beforeUnloadHandle)
  visibilityHandle()
}

export function withActiveWin (cb, {titleMarker = ''} = {}) {
  const winTracker = getWinTrackerLS()
  const winEntries = Object.entries(winTracker.windows)
  let activeWinId

  // visible and focused
  for (const [key, val] of winEntries) {
    if (val.visible && val.focused) {
      activeWinId = key
      break
    }
  }

  // visible only and lastActive or if no visible - lastActive
  if (!activeWinId) {
    const visibleEntry = winEntries.find(([key, val]) => val.visible && (winTracker.lastActive[0] === key))
    activeWinId = visibleEntry ? visibleEntry[0] : winTracker.lastActive[0]
  }

  if (activeWinId === id && cb) {
    cb()
    if (titleMarker && !document.title.includes(titleMarker)) {
      document.title = `${titleMarker} ${document.title} ${titleMarker}`
    }
    return true
  } else {
    if (titleMarker && document.title.includes(titleMarker)) {
      const markerRx = new RegExp(titleMarker, 'g')
      document.title = document.title.replace(markerRx, '')
    }
    return false
  }
}
