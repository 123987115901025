// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CommissionerReportApiMethodsCls {
    async comissionerReportRowRetrieve(wh, id, extra_data = null) {
      // Получение строки отчета комиссионера
      console.log('comissionerReportRowRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__commissionerreportrow', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__commissionerreportrow', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.comissionerReportRow.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получить список отчетов комиссионера
      // filters: date_fr, date_to, hotelier_contract, state, ids, location, principal, period,
      //          reward_type
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('commissionerReportList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async commissionerReportCreate(wh, data, extra_data = null) {
      // data: CommissionerReportCreate
      // Создать отчеты комиссионера
      console.log('commissionerReportCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportRetrieve(wh, id, extra_data = null) {
      // Получить конкретный отчет комиссионера
      console.log('commissionerReportRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__commissionerreport', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__commissionerreport', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportUpdate(wh, id, data, extra_data = null) {
      // data: CommissionerReportUpdate
      // Изменить строки отчета комиссионера
      console.log('commissionerReportUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportDelete(wh, id) {
      // Удалить отчет комиссионера
      console.log('commissionerReportDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.delete(id)})
      ctx.$services.storage.del('legal__commissionerreport', id, wh)
      return null
    }
    async commissionerReportPartial(wh, id, data, extra_data = null) {
      // data: CommissionerReport
      console.log('commissionerReportPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportAnnulPartial(wh, id, data, extra_data = null) {
      // data: CommissionerReportAnnul
      // Анулировать отчет комиссионера
      console.log('commissionerReportAnnulPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.annul.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportDownloadReportRetrieve(wh, id, extra_data = null) {
      // Получить url файла отчета комиссионера (поле в ответе file_url)
      console.log('commissionerReportDownloadReportRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.downloadReport.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportHoldPartial(wh, id, data, extra_data = null) {
      // data: CommissionerReportHold
      // Зафиксировать принятие отчета комиссионера
      console.log('commissionerReportHoldPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.hold.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportPrintRetrieve(wh, id, extra_data = null) {
      // Получить "отчет комиссионера" в виде html
      console.log('commissionerReportPrintRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.print.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportRejectPartial(wh, id, data, extra_data = null) {
      // data: CommissionerReportReject
      // Зафиксировать отклонение отчета комиссионера
      console.log('commissionerReportRejectPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.reject.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportRestorePartial(wh, id, data, extra_data = null) {
      // data: CommissionerReport
      // Зафиксировать восстановление отчета комиссионера
      console.log('commissionerReportRestorePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.restore.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportResumePartial(wh, id, data, extra_data = null) {
      // data: CommissionerReport
      // Зафиксировать возобновление отчета комиссионера
      console.log('commissionerReportResumePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.resume.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionerReportSendPartial(wh, id, data, extra_data = null) {
      // data: CommissionerReportSend
      // Выслать документ "отчет комиссионера"
      console.log('commissionerReportSendPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commissionerReport.send.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.commissioner_reportApi = new CommissionerReportApiMethodsCls()
}

