<template>
    <CModal
      ref="modal"
      :visible="visible"
      size="lg"
      :fullscreen="false"
      backdrop="static"
      alignment="center"
      @close="onClose"
    >
      <CModalHeader dismiss>
        <CModalTitle>Выбор агента</CModalTitle>
      </CModalHeader>
      <CModalBody class="overflow-visible">
        <div class="flex gap-4 align-items-end">
          <div class="col-8 col-xl-6">
            <CFormLabel for="select.agent">Выберите агента для передачи лида</CFormLabel>
            <Autocomplete
              id="select.agent"
              v-model="agentAC"
              :search-area="[{
              content_type: 'account__identity',
              filters:[{ name: 'role', value: C.ROLES_AGENT }]
            }]"
            />
          </div>
          <div>
            <CLoadingButton color="success" :loading="loading" @click="handOver" >Передать</CLoadingButton>
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <div class="flex gap-3">
          <CButton color="secondary" @click="onClose">Отмена</CButton>
        </div>
      </CModalFooter>
    </CModal>
</template>
<script setup>
import {inject, onMounted, onUnmounted, ref} from 'vue'
import C from "@/config/back_const"
import Autocomplete from "@/components/custom/Autocomplete.vue"

const emit = defineEmits(['close', 'complete'])
const WH = 'd063c0a1-e2cf-41a2-8e9f-3a5928d2e541'
const { storage } = inject('services')

const visible = ref(false)
const loading = ref(false)
const agentAC = ref(null)
const onClose = () => {
  visible.value = false
  emit('close')
}
const handOver = async () => {
  loading.value = true
  emit('complete', agentAC.value.id)
}

onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
