// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class HotelserviceApiMethodsCls {
    async hotelServiceList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Список услуг отеля
      // filters: title, category
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('hotelServiceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotelService.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, false, extra_data), count:response.data.count}
    }
    async hotelServiceRetrieve(wh, id, extra_data = null) {
      // Получение услуги отеля по id
      console.log('hotelServiceRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__hotelservice', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__hotelservice', id:id}, wh, false)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotelService.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async hotelServiceCategoryList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Список категорий услуг отеля
      // filters: created_at, updated_at, title, description, sort_order
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('hotelServiceCategoryList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotelServiceCategory.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, false, extra_data), count:response.data.count}
    }
    async hotelServiceCategoryRetrieve(wh, id, extra_data = null) {
      // Получение категории услуг отеля по id
      console.log('hotelServiceCategoryRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__hotelservicecategory', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__hotelservicecategory', id:id}, wh, false)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotelServiceCategory.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async roomServiceList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Список услуг номера
      // filters: title, category
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('roomServiceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.roomService.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, false, extra_data), count:response.data.count}
    }
    async roomServiceRetrieve(wh, id, extra_data = null) {
      // Получение услуги номера по id
      console.log('roomServiceRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__roomservice', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__roomservice', id:id}, wh, false)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.roomService.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
  }

  ctx.$services.hotelServiceApi = new HotelserviceApiMethodsCls()
}

