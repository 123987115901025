// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class PaymentApiMethodsCls {
    async paymentCreateCreate(wh, data, extra_data = null) {
      // data: Payment
      // Создание платежа
      console.log('paymentCreateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.create.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentImportCreate(wh, data, extra_data = null) {
      // data: ImportPaymentsCreate
      // Создание импорта платежей
      console.log('paymentImportCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.import.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentImportAcceptPartial(wh, id, data, extra_data = null) {
      // data: ImportPaymentsAccept
      // Принятие платежей
      console.log('paymentImportAcceptPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.import.accept.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentImportProcessPartial(wh, id, data, extra_data = null) {
      // data: ForceForeground
      // Импорт платежей
      console.log('paymentImportProcessPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.import.process.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentSplitCreate(wh, data, extra_data = null) {
      // data: PaymentSplit
      // Создание разнесения платежа
      console.log('paymentSplitCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.split.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentSplitRetrieve(wh, id, extra_data = null) {
      // Получение конкретного разнесения платежа
      console.log('paymentSplitRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__paymentsplit', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__paymentsplit', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.split.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentSplitAnnulPartial(wh, id, data, extra_data = null) {
      // data: PaymentSplit
      // Аннулировние разнесения платежа
      console.log('paymentSplitAnnulPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.split.annul.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentSplitDeleteDelete(wh, id) {
      console.log('paymentSplitDeleteDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.payment.split.delete.delete(id)})
      ctx.$services.storage.del('None', id, wh)
      return null
    }
    async paymentSplitUpdatePartial(wh, id, data, extra_data = null) {
      // data: PaymentSplit
      console.log('paymentSplitUpdatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.split.update.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentRetrieve(wh, id, extra_data = null) {
      // Получение конкретного платежа
      console.log('paymentRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__payment', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__payment', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentAcceptPartial(wh, id, data, extra_data = null) {
      // data: Payment
      // Принять платеж
      console.log('paymentAcceptPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.accept.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentAnnulPartial(wh, id, data, extra_data = null) {
      // data: Payment
      // Аннулировать платеж
      console.log('paymentAnnulPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.annul.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentAutoSplitPartial(wh, id, data, extra_data = null) {
      // data: Payment
      // Разнести платеж по идентификатору брони
      console.log('paymentAutoSplitPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.autoSplit.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentCancelPartial(wh, id, data, extra_data = null) {
      // data: Payment
      // Отменить платеж
      console.log('paymentCancelPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.cancel.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentHoldPartial(wh, id, data, extra_data = null) {
      // data: Payment
      // Провести платеж
      console.log('paymentHoldPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.hold.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentRestorePartial(wh, id, data, extra_data = null) {
      // data: Payment
      // Восстановить платеж
      console.log('paymentRestorePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.restore.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentListSplitList(wh, id, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка разнесений для конкретного платежа
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('paymentListSplitList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.payment.listSplit.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async paymentUpdateUpdate(wh, id, data, extra_data = null) {
      // data: PaymentUpdate
      // Обновление платежа(только для статусов NEW/DRAFT)
      console.log('paymentUpdateUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.payment.update.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.paymentApi = new PaymentApiMethodsCls()
}

