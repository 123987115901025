// исключения добавляются сюда
const errorIgnoreList = [
  // постоянный захардкоженый коллбэк
  {
    url: 'api/core/lead/take/?',
    status: 404,
    method: 'POST',
    cbList: [
      {
        cb: (ctx) => {
          ctx.$services.notification.warning(
            'Не удалось взять лид в работу!<br>Новые лиды уже закончились.',
          )
        },
        id: 'core_lead_take',
      },
    ],
  },
]

export const findException = (
  { url, status, method },
  { getIndex = false } = {},
) => {
  const idx = errorIgnoreList.findIndex((item) => {
    const sameUrl = url.includes(item.url)
    const sameStatus = item.status === status
    const sameMethod = item.method.toLowerCase() === method.toLowerCase()
    return sameUrl && sameStatus && sameMethod
  })
  return getIndex ? idx : errorIgnoreList[idx]
}

export const deleteExceptionCB = ({ url, status, method, id }) => {
  const exception = findException({ url, status, method })
  exception.cbList = exception.cbList.filter((cb) => cb.id !== id)
}

export const deleteExceptionIgnore = ({ url, status, method }) => {
  const idx = findException({ url, status, method }, { getIndex: true })
  errorIgnoreList.splice(idx, 1)
}

export const addExceptionIgnore = ({ url, status, method, cbList }) => {
  const exception = findException({ url, status, method })
  if (exception) {
    const toAdd = cbList.filter(
      ({ id }) => !exception.cbList.some((cbItem) => cbItem.id === id),
    )
    exception.cbList = exception.cbList.concat(toAdd)
  } else {
    errorIgnoreList.push({ url, status, method, cbList })
  }
}

/**
 * TODO Docs
 * Проверяет наличие ответа с ошибкой в листе игнорирования и выполняет колбэк если исключение найдено и колбэк установлен
 * @param {{}} ctx
 * @param {response: {config: {url: string}, status: number, method: string}} response
 * @returns {boolean}
 */
export const errorIgnoreListCheck = (ctx, response) => {
  const url = response?.config?.url
  const status = response?.status
  const method = response.config.method
  if (!url || !status || !method) return false

  const exception = findException({ url, status, method })

  if (!exception || !exception.cbList?.length) return false

  for (const { cb } of exception.cbList) {
    setTimeout(() => cb(ctx, response))
  }

  return true
}
