<template>
  <div
    class="aside-right border border-start-1 border-opacity-10 h-100"
    :id="`id-${WH}`"
  >
    <CSidebar
      color-scheme="light"
      placement="end"
      :visible="asideVisible"
      @visible-change="onVisibleChange"
      class="sidebar-xxl h-100"
    >
      <CSidebarHeader class="bg-transparent p-0">
        <CNav variant="underline">
          <CNavItem>
            <div @click.stop.prevent="onCloseChat">
              <CNavLink
                href="#"
                class="nav-group-toggle"
                :active="activeKey === 1"
              >
                <CIcon icon="cil-List" alt="CoreUI Icons Speech" />
              </CNavLink>
            </div>
          </CNavItem>
          <CNavItem>
            <div
              @click.stop.prevent="
                () => {
                  if (chatId) updateActiveKey(2)
                }
              "
            >
              <CNavLink
                href="#"
                class="nav-group-toggle"
                :active="activeKey === 2"
                :disabled="!chatId"
              >
                <CIcon icon="cil-speech" alt="CoreUI Icons List" />
              </CNavLink>
            </div>
          </CNavItem>
          <CNavItem>
            <div
              @click.stop.prevent="
                () => {
                  if (chatId) updateActiveKey(3)
                }
              "
            >
              <CNavLink
                href="#"
                class="nav-group-toggle"
                :active="activeKey === 3"
                :disabled="!chatId"
              >
                <CIcon icon="cil-settings" alt="CoreUI Icons Settings" />
              </CNavLink>
            </div>
          </CNavItem>
          <CNavItem class="ms-auto me-2 d-flex align-items-center">
            <CCloseButton @click="$store.commit('toggleAside')" />
          </CNavItem>
        </CNav>
      </CSidebarHeader>
      <CTabContent class="h-100">
        <CTabPane class="p-3 h-100" :visible="activeKey == 1">
          <div class="h-100">
            <AsideChatGroup
              :chat-id="lastChatId"
              @open-chat="onOpenChat"
              @chat-group="onChatGroup"
            />
          </div>
        </CTabPane>
        <CTabPane :visible="activeKey == 2">
          <div>
            <AsideChatItem
              :chat-id="chatId"
              :chat-data="currChat"
              @close="onCloseChat"
            />
          </div>
        </CTabPane>
        <CTabPane class="p-3" :visible="activeKey == 3">
          <template v-if="currChat">
            <div>
              Чат #{{ currChat.id }} создан
              {{ new Date(currChat.created_at).toLocaleDateString() }}
            </div>
            <div v-if="currChat.lead">
              Лид
              <router-link :to="`/lead/${currChat.lead}/`">{{
                currChat.lead
              }}</router-link>
            </div>
            <div v-if="currChat.tour">
              Тур
              <router-link :to="`/tour/${currChat.tour}/`">{{
                currChat.tour
              }}</router-link>
            </div>
          </template>
        </CTabPane>
      </CTabContent>
    </CSidebar>
  </div>
</template>

<script setup>
import {
  computed,
  inject,
  nextTick,
  onBeforeMount,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { useStore } from 'vuex'
import AsideChatGroup from '@/components/AsideChatGroup.vue'
import AsideChatItem from '@/components/AsideChatItem.vue'
import { useRoute } from 'vue-router'
import { useAuthData } from '@/composables/authData'

const WH = `e77d1d7c-6509-4c87-9d23-d2131595a478`
const { messageApi, dateService, storage, leadApi } = inject('services')
const bus = inject('bus')

const { myIdentity } = useAuthData()
const asideVisible = computed(() => store.state.asideVisible)
const store = useStore()
const route = useRoute()
const activeKey = ref(1)
const chatId = ref(null)

const currObjectType = ref(null)
const currObjectId = ref(null)
const currObjectChat = ref(null)
const chatGroup = ref([])
const currChat = computed(() => {
  if (chatId.value) {
    const idx = chatGroup.value.findIndex((chat) => chat.id === chatId.value)
    return idx !== -1 ? chatGroup.value[idx] : currObjectChat.value
  } else {
    return null
  }
})
const lastChatId = computed(() => chatId.value || lastChatId.value)

const objectTypesPath = ['lead', 'tour']
const objectTypesData = {
  lead: {
    dataType: 'core__lead',
    retrieve: leadApi.leadRetrieve,
  },
}

const getRelObj = async () => {
  const id = currObjectId.value
  const { dataType, retrieve } = objectTypesData[currObjectType.value] || {}
  if (!id || !dataType || !retrieve) return null
  const result = await storage.getRelations(
    [{ id }],
    'id',
    dataType,
    retrieve,
    WH,
  )
  return result[id]
}
const onVisibleChange = (visible) => {
  // синхронизировать авто-скрытие панели со стейтом закрытия
  if (visible === false) store.commit('toggleAside', false)
}
const updateActiveKey = (key) => {
  activeKey.value = key
}
const onOpenChat = (chatObj) => {
  currObjectChat.value = chatObj // необх. если вызвано из события
  chatId.value = chatObj?.id
  if (chatObj.lastMessage) {
    chatObj.lastMessage.read_at = dateService.toIso(new Date())
  }
  updateActiveKey(2)
}
const onCloseChat = () => {
  chatId.value = currObjectChat.value?.id
  updateActiveKey(1)
}
const onChatGroup = (data) => {
  chatGroup.value = data
}
const asideAutoCloseControl = (route) => {
  const autoClosePath = ['/leads/']
  if (autoClosePath.includes(route.path)) {
    store.commit('toggleAside', false)
  }
}
const findAndOpenChat = async () => {
  if (!currObjectId.value) return
  const relObj = await getRelObj()
  let chat = chatGroup.value.find(
    (ch) => ch[currObjectType.value] == currObjectId.value,
  )
  if (!chat) {
    const res = await messageApi.chatList(WH, {
      filters: { [currObjectType.value]: currObjectId.value },
    })
    if (res?.data?.length) {
      chat = res.data[0]
    }
  }
  if (!chat) {
    onCloseChat()
    return
  }
  nextTick(() => {
    setTimeout(() => {
      currObjectChat.value = chat
      onOpenChat(currObjectChat.value)
      // нашли и перешлив чат, но открывать панель чатов только для своего лида
      if (
        currObjectType.value === 'lead' &&
        relObj?.agent !== myIdentity.value?.id
      )
        return
      store.commit('toggleAside', true)
    }, 500)
  })
}
const chatAutoOpenControl = async (route) => {
  currObjectType.value = objectTypesPath.find((type) => {
    const rx = new RegExp(`/${type}/.+`)
    return rx.test(route.path)
  })
  if (currObjectType.value) {
    currObjectId.value = route.params?.id ? Number(route.params.id) : null
    await findAndOpenChat()
  } else {
    onCloseChat()
    currObjectType.value = null
    currObjectChat.value = null
    currObjectId.value = null
  }
}
const busOpenChatHandle = (chat) => {
  onOpenChat(chat)
  store.commit('toggleAside')
}

watch(
  () => route,
  (val) => {
    asideAutoCloseControl(val)
    chatAutoOpenControl(val)
  },
  { deep: true },
)
watch(
  () => store.state.sidebarExpanded,
  (val) => {
    if (val) {
      // сворачивать панель чатов при открытии сайдбара чтобы не было конфликта
      store.commit('toggleAside', false)
    }
  },
)
/*
// сейчас решено не сохранять в LS состояние панели чатов
watch(() => store.state.asideVisible, (val) => {
  const asideState = val ? 'opened' : 'closed'
  persistentService.set('asideState', asideState)
})
*/

onBeforeMount(async () => {
  store.commit('toggleAside', false)
  chatAutoOpenControl(route)
  bus.on('aside-open-chat', busOpenChatHandle)

  /*
  // сейчас решено не сохранять в LS состояние панели чатов
  persistentService.get('asideState').then((asideState) => {
    if (asideState) {
      store.commit('toggleAside', asideState === 'opened')
    }
  })
*/
})

onUnmounted(() => {
  bus.off('aside-open-chat', busOpenChatHandle)
})
</script>
