<template>
  <div class="minimizable" ref="modalContainer">
    <CModal
      ref="modal"
      :visible="isVisible"
      size="lg"
      backdrop="static"
      alignment="center"
      fullscreen="sm"
      @close="onClose"
    >
      <ModalMinimize v-if="modalContainer" :modal-container="modalContainer" />
      <CModalHeader dismiss>
        <CModalTitle>{{ fullname }}</CModalTitle>
      </CModalHeader>
      <CModalBody class="popup-min-h-sm" :id="`id-${WH}`">
        <CAlert color="danger" v-if="err">{{ err }}</CAlert>

        <div v-if="person">
          <div class="mb-3">
            <div class="row">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel for="personName"> Имя </CFormLabel>
                <CFormInput
                  id="personName"
                  :value="person.name"
                  type="text"
                  size="sm"
                  disabled
                  class="static-text-disabled"
                />
              </div>

              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel for="personSurname"> Фамилия </CFormLabel>
                <CFormInput
                  :value="person.surname"
                  id="personSurname"
                  type="text"
                  size="sm"
                  disabled
                  class="static-text-disabled"
                />
              </div>

              <div class="col-12 col-md-4">
                <CFormLabel for="personPatronymic"> Отчество </CFormLabel>
                <CFormInput
                  :value="person.patronymic"
                  id="personPatronymic"
                  type="text"
                  size="sm"
                  disabled
                  class="static-text-disabled"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel for="personBirthday"> Дата рождения </CFormLabel>
                <Datepicker
                  v-model="birthday"
                  locale="ru-RU"
                  format="dd.MM.yyyy"
                  model-type="dd.MM.yyyy"
                  :enable-time-picker="false"
                  size="sm"
                  :range="false"
                  :multi-calendars="false"
                  :week-start="1"
                  disabled
                  class="static-text-disabled"
                />
              </div>

              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel for="personAge"> Возраст </CFormLabel>
                <CFormInput
                  id="personAge"
                  :value="person.age"
                  size="sm"
                  disabled
                  class="static-text-disabled"
                />
              </div>

              <div class="col-12 col-md-4">
                <CFormLabel for="personAge"> Пол </CFormLabel>
                <CFormSelect
                  id="personGender"
                  :value="person.gender"
                  size="sm"
                  disabled
                  class="static-text-disabled"
                >
                  <option value="" class="static-text-disabled">
                    Выберите значение
                  </option>
                  <option
                    v-for="gender in Object.entries(C.GENDER_TITLES)"
                    :key="gender[0]"
                    :value="gender[0]"
                    class="static-text-disabled"
                  >
                    {{ gender[1] }}
                  </option>
                </CFormSelect>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel for="country"> Страна </CFormLabel>
                <CFormInput
                  id="country"
                  class="static-text-disabled"
                  :value="country"
                  size="sm"
                  disabled
                />
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel> Город местонахождения </CFormLabel>
                <CFormInput
                  id="city"
                  class="static-text-disabled"
                  :value="city"
                  size="sm"
                  disabled
                />
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel> Социальная группа </CFormLabel>
                <CMultiSelect
                  :options="personSocialGroups"
                  size="sm"
                  disabled
                  class="static-text-disabled"
                />
              </div>
            </div>
          </div>
          <div v-if="haveContacts" class="mb-4">
            <div class="row">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <CFormLabel> Телефоны </CFormLabel>
                <template v-if="personPhones.length">
                  <div
                    class="position-relative d-flex flex-column mb-4 mb-md-3"
                    v-for="(phone, index) in personPhones"
                    :key="index"
                  >
                    <div>
                      <CFormInput
                        :value="phone"
                        size="sm"
                        class="static-text-disabled"
                        disabled
                      />
                    </div>
                  </div>
                </template>
                <div v-else>Нет телефонов</div>
              </div>
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <CFormLabel> Контакты </CFormLabel>
                <template v-if="personContacts.length">
                  <div
                    class="d-flex justify-content-between mb-3"
                    v-for="contact in personContacts"
                    :key="contact.id"
                  >
                    <div class="w-100 position-relative me-2">
                      <CFormInput
                        :value="contact.type"
                        placeholder="Тип"
                        size="sm"
                        disabled
                        class="static-text-disabled"
                      />
                    </div>
                    <div class="w-100 position-relative me-2">
                      <CFormInput
                        :value="contact.number"
                        placeholder="Контакт"
                        size="sm"
                        disabled
                        class="static-text-disabled"
                      />
                    </div>
                  </div>
                </template>
                <div v-else>Нет контактов</div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <CAccordion>
              <CAccordionItem>
                <CAccordionHeader>Паспортные данные</CAccordionHeader>
                <CAccordionBody class="p-0">
                  <CRow>
                    <CCol xs="12" md="5">
                      <div class="mb-3">
                        <CFormLabel> Тип документа </CFormLabel>
                        <CFormInput
                          :value="
                            person.document_type
                              ? C.PERSON_PASSPORT_TYPE_TITLES[
                                  person.document_type
                                ]
                              : 'Не выбрано'
                          "
                          size="sm"
                          disabled
                          class="static-text-disabled"
                        />
                      </div>
                      <div class="mb-3">
                        <CFormLabel> Номер документа </CFormLabel>
                        <CFormInput
                          :value="person.passport_number"
                          size="sm"
                          disabled
                          class="static-text-disabled"
                        />
                      </div>
                      <div class="mb-3">
                        <CFormLabel> Дата выдачи документа </CFormLabel>
                        <Datepicker
                          v-model="passportDate"
                          locale="ru-RU"
                          format="dd.MM.yyyy"
                          model-type="dd.MM.yyyy"
                          :enable-time-picker="false"
                          size="sm"
                          :range="false"
                          :multi-calendars="false"
                          :week-start="1"
                          disabled
                          class="static-text-disabled"
                        />
                        <div class="mb-3">
                          <CFormLabel for="whoIssuedDoc">
                            Кем выдан документ
                          </CFormLabel>
                          <CFormInput
                            v-model.trim="person.passport_issued_by"
                            id="whoIssuedDoc"
                            type="text"
                            size="sm"
                            disabled
                          />
                        </div>
                      </div>
                    </CCol>
                    <CCol xs="12" md="7">
                      <CFormLabel for="personRegistrationAddress">
                        Адрес регистрации
                      </CFormLabel>
                      <CFormTextarea
                        id="personRegistrationAddress"
                        type="text"
                        rows="7"
                        v-model.trim="registrationAddress"
                        disabled
                        class="static-text-disabled"
                      />
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </div>
          <div class="mb-3">
            <ObjectComments
              v-if="commentsObj"
              :obj="commentsObj"
              el-id="person-comments-wrap"
              title="Комментарии"
              :show-button="true"
              @update-data="(data) => emit('comments-obj-update', data)"
            />
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onClose">Закрыть</CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script setup>
import {
  inject,
  onMounted,
  ref,
  onUnmounted,
  onBeforeMount,
  computed,
  nextTick,
} from 'vue'
import C from '@/config/back_const'
import { useStore } from 'vuex'
import { convertContactStrToObj, getFullName } from '@/helpers'
import ObjectComments from '@/components/custom/ObjectComments'
import ModalMinimize from '@/components/_common/ModalMinimize.vue'
import { capitaliseFirst } from '@/helpers/textFormatter'

const props = defineProps({
  personId: {
    type: [Number, String],
    required: true,
  },
  haveContacts: {
    type: [Boolean],
    default: true,
  },
  personExternal: {
    type: [Object],
    default: () => null,
  },
  commentsObj: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
  err: {
    type: [Object, String],
    default: null,
  },
})
const emit = defineEmits(['close', 'cancel', 'update', 'comments-obj-update'])

const WH = '598788f1-b838-4f9d-858c-84806f3d028d'
const { personApi, storage, dateService, genericApi, refbookApi, identityApi } =
  inject('services')
const store = useStore()

const modalContainer = ref(null)
const isVisible = ref(false)
const isLoading = ref(false)
const person = ref(null)
const country = ref(null)
const city = ref(null)
const allSocialGroups = ref([])

const fullname = computed(() => {
  return getFullName(person.value)
})
const personSocialGroups = computed(() => {
  return allSocialGroups.value.reduce((acc, item) => {
    if (person.value.social_groups.includes(item.id)) {
      acc.push({ value: item.id, text: item.title, selected: true })
    }
    return acc
  }, [])
})
const registrationAddress = computed(() => {
  if (person.value?.registration_address) {
    return person.value.registration_address
  }
  return ''
})
const birthday = computed(() => {
  if (person.value?.birthday) {
    return dateService.formatDate(person.value.birthday)
  }
  return ''
})
const passportDate = computed(() => {
  if (person.value?.passport_date) {
    return dateService.formatDate(person.value.passport_date)
  }
  return ''
})
const personPhones = computed(() => {
  return person.value?.phones?.length ? person.value.phones : []
})
const personContacts = computed(() => {
  if (person.value?.contacts?.length) {
    return person.value.contacts.map((contact) => {
      const _c =
        typeof contact === 'string' ? convertContactStrToObj(contact) : contact
      return {
        ..._c,
        type: _c.type?.length > 0 ? capitaliseFirst(_c.type) : '',
      }
    })
  }
  return []
})
const onClose = () => {
  isVisible.value = false
  emit('close')
}

onBeforeMount(async () => {
  isLoading.value = ref(true)
  try {
    if (props.personExternal) {
      person.value = props.personExternal
    } else {
      person.value = await personApi.personRetrieve(WH, props.personId)
    }

    if (!person.value?.id) {
      throw new Error(`Персона с id "${props.personId}" не найдена`)
    }
  } catch (err) {
    console.error(err)
    store.commit('toggleLockEl', { lock: false, target: null })
  }

  try {
    const awaitData = []
    awaitData.push(refbookApi.socialGroupList(WH))
    if (person.value.country) {
      awaitData.push(
        genericApi.genericTitleRetrieve(
          WH,
          'refbook__location',
          person.value.country,
        ),
      )
    } else {
      awaitData.push(Promise.resolve({}))
    }
    if (person.value.city_of_resident) {
      awaitData.push(
        genericApi.genericTitleRetrieve(
          WH,
          'refbook__location',
          person.value.city_of_resident,
        ),
      )
    } else {
      awaitData.push(Promise.resolve({}))
    }
    if (person.value.owner) {
      awaitData.push(identityApi.identityRetrieve(WH, person.value.owner))
    } else {
      awaitData.push(Promise.resolve(null))
    }
    const [_allSocialGroups, _country, _city] = await Promise.all(awaitData)

    allSocialGroups.value = _allSocialGroups?.data || []
    country.value = _country.generic_title || null
    city.value = _city.generic_title || null
  } catch (err) {
    console.error(err)
  } finally {
    store.commit('toggleLockEl', { lock: false, target: null })
  }
})
onMounted(() => {
  isVisible.value = true
  nextTick(() => {
    if (isLoading.value) {
      store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
    }
  })
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
