// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class TagApiMethodsCls {
    async tagCreate(wh, data, extra_data = null) {
      // data: TagCreate
      // Создание/удаление тэгов
      console.log('tagCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tag.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async tagForObjectList(wh, object_id, object_type, {limit, offset, page} = {}, extra_data = null) {
      // Получение тэгов
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('tagForObjectList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tag.forObject.list(object_id, object_type, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, false, extra_data), count:response.data.count}
    }
  }

  ctx.$services.tagApi = new TagApiMethodsCls()
}

