export default (ctx) => {
  class PersonService {
    getEmptyPerson({ owner, data }) {
      return {
        name: data?.name ? data.name : null,
        surname: data?.surname ? data.surname : null,
        patronymic: data?.patronymic ? data.patronymic : null,
        birthday: null,
        age: null,
        gender: null,
        document_type: '',
        registration_address: null,
        passport_number: null,
        passport_date: null,
        email: data?.email ? data.email : null,
        social_group: [],
        country: null,
        city_of_resident: null,
        owner: owner,
      }
    }
  }

  ctx.$services.personService = new PersonService()
}
