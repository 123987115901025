// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class RoomApiMethodsCls {
    async roomList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Room
      // filters: sort_order, created_at, updated_at, category, number, name,
      //          prot_allow_external_booking, is_active
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('roomList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async roomCreate(wh, data, extra_data = null) {
      // data: RoomPost
      // Создание Room
      console.log('roomCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async roomRetrieve(wh, id, extra_data = null) {
      // Получение Room
      console.log('roomRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__room', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__room', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async roomUpdate(wh, id, data, extra_data = null) {
      // data: RoomPost
      // Обновление модели Room
      console.log('roomUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async roomFlagPost(wh, id, data, extra_data = null) {
      // data: RoomFlag
      // Создание RoomFlag
      console.log('roomFlagPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.flag.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.roomApi = new RoomApiMethodsCls()
}

