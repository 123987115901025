<template>
  <div class="phones-edit">
    <template v-if="phonesIsLoaded">
      <div v-if="phonesList?.data?.length">
        <template v-for="(phone, index) in phonesList.data" :key="phone.id">
          <div :class="{ 'flex gap-2': !hidePhoneButton }">
            <PhonesEditRow
              v-model="phonesList.data[index]"
              @complete-action="onCompleteAction"
            />
            <PhoneButton
              v-if="!hidePhoneButton"
              :phone="phone.number"
              size="md"
            />
          </div>
        </template>
        <FieldModelErrors :errors="validationErrors" />
      </div>
      <div
        v-else
        class="border rounded-3 d-flex flex-row align-items-center justify-content-center mb-3"
      >
        <div
          class="label d-flex flex-row align-items-center text-secondary"
          style="padding: 0.38rem"
        >
          <span>Телефоны</span>
        </div>
      </div>
      <div v-if="addBtnVisible" class="d-flex justify-content-end">
        <CButton @click="addPhone" size="sm" color="primary">
          <CIcon icon="cilPlus" size="sm" />
          Добавить номер
        </CButton>
      </div>
    </template>
    <div
      v-else
      class="border rounded-3 d-flex flex-row align-items-center justify-content-center p-4"
    >
      <div class="label d-flex flex-row align-items-center">
        <CSpinner color="secondary" class="me-2" />
        <span>Загрузка</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from 'uuid'
import { onMounted, provide, inject, ref, watch } from 'vue'
import PhonesEditRow from './PhonesEditRow.vue'
import PhoneButton from '@/components/_custom/phone-button/PhoneButton.vue'
import FieldModelErrors from '@/components/form/FieldModelErrors.vue'

const WH = 'PhonesEdit'
const props = defineProps({
  objectType: {
    type: String,
    required: true,
  },
  objectId: {
    type: [String, Number],
    required: false,
    default: () => undefined,
  },
  // начальные значения.
  // Например для формы создания новой персоны, когда персоны ещё нет, но нужно добавить в форму номера. Затем после создания персоны добавить эти телефоны к ней.
  initialPhones: {
    type: Array,
    required: false,
    default: () => [],
  },
  hidePhoneButton: {
    type: Boolean,
    default: false,
  },
  validationErrors: {
    type: Array,
    default: null,
  },
})
const emit = defineEmits(['complete-action', 'input-error', 'input'])

const { phoneApi } = inject('services')
const phonesList = ref({ data: [] })
const phonesIsLoaded = ref(false)
const addBtnVisible = ref(true)

provide('requestParams', {
  WH,
  objectType: props.objectType,
  objectId: props.objectId,
  phonesList,
})

const newPhone = (number) => {
  return {
    id: uuidv4(),
    number: number || '',
    info: '',
    new: true,
  }
}

const addPhone = (numberOrEvent) => {
  // при использовании начальных значений передаём сюда номер, но если он не передан то это будет событие - игнорируем его.
  const phone =
    typeof numberOrEvent === 'string' || numberOrEvent?.id
      ? numberOrEvent
      : undefined
  addBtnVisible.value = false
  phonesList.value.data.push(phone?.id ? phone : newPhone(phone))
}

const onCompleteAction = () => {
  emit('complete-action', phonesList.value.data)
  addBtnVisible.value = true
}

watch(
  () => props.initialPhones,
  (val) => {
    if (!val || props.objectId) return
    // добавляем начальные значения так же как если бы они пришли с сервера
    phonesList.value = { data: [] }
    val.forEach((p) => {
      addPhone(p)
    })
    phonesList.value.data.forEach((p) => {
      p.new = false
    })
    onCompleteAction()
  },
  { immediate: true },
)

onMounted(async () => {
  try {
    if (props.objectId) {
      phonesList.value = await phoneApi.phoneForObjectList(
        WH,
        props.objectType,
        props.objectId,
      )
    }
  } catch (error) {
    console.log(error)
  } finally {
    phonesIsLoaded.value = true
  }
})
</script>
