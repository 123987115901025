import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Главная',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'home',
        component: () => import('@/pages/a08-common/FrontPage'),
      },
      {
        path: '/workplace',
        name: 'Рабочие страницы',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/components/popup-price-model-demo',
            name: 'PopupPriceModelDemo',
            component: () =>
              import('@/pages/components/PopupPriceModelDemo.vue'),
          },
          {
            path: '/components/popup-price-model-demo/price/edit/:id',
            name: 'PopupPriceModelDemo Edit',
            props: true,
            component: () =>
              import('@/pages/components/PopupPriceModelDemo.vue'),
          },
        ],
      },
      {
        path: '/components/components-problems',
        name: 'Проблемы коммпонентов',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/components/components-problems/select',
            name: 'CFormSelect',
            component: () => import('@/pages/components/CFormSelectProblem'),
          },
        ],
      },
      {
        path: '/components',
        name: 'Кастомные компоненты',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/components/app-icon',
            name: 'Иконки приложения',
            component: () => import('@/pages/components/AppIconDemo'),
          },
          {
            path: '/components/upload-files',
            name: 'Загрузка файлов',
            component: () =>
              import('@/components/_custom/upload-files/UploadFilesDemo.vue'),
          },
          {
            path: '/components/view-files',
            name: 'Просмотр загруженных файлов',
            component: () =>
              import('@/components/_custom/view-files/ViewFilesDemo.vue'),
          },
          {
            path: '/components/tel-input',
            name: 'Компонент ввода телефона',
            component: () => import('@/pages/components/TelInputDemo'),
          },
          {
            path: '/components/phone-input',
            name: 'Поле ввода телефона',
            component: () => import('@/pages/components/PhoneInputDemo'),
          },
          {
            path: '/components/autocomplete',
            name: 'Поле autocomplete',
            component: () =>
              import('@/pages/components/Autocomplete/AutocompleteDemo.vue'),
          },
          {
            path: '/components/server-errors',
            name: 'Серверные ошибки',
            component: () => import('@/pages/components/ServerErrors.vue'),
          },
          {
            path: '/components/currency-input',
            name: 'Ввод суммы',
            component: () =>
              import(
                '@/components/_custom/currency-input/CurrencyInputDemo.vue'
              ),
          },
          {
            path: '/components/text-input-lazy',
            name: 'Ленивый инпут',
            component: () =>
              import(
                '@/components/_custom/text-input-lazy/TextInputLazyDemo.vue'
              ),
          },
          {
            path: '/components/percent-input',
            name: 'ВВод процентов',
            component: () =>
              import('@/components/_custom/percent-input/PercentInputDemo.vue'),
          },
          {
            path: '/components/contacts-edit',
            name: 'Редактирование контактов',
            component: () =>
              import('@/components/_custom/contacts-edit/ContactsEditDemo.vue'),
          },
          {
            path: '/components/comments-popup',
            name: 'Модал комментариев',
            component: () => import('@/pages/components/CommentsPopupDemo.vue'),
          },
          {
            path: '/components/phones-edit',
            name: 'Редактирование телефонов',
            title: 'Редактирование телефонов',
            component: () => import('@/pages/components/PhonesEditDemo.vue'),
          },
          {
            path: '/components/phones-list',
            name: 'Список телефонов объекта',
            title: 'Редактирование телефонов',
            component: () => import('@/pages/components/PhonesListDemo.vue'),
          },
          {
            path: '/components/contacts-list',
            name: 'Список контактов объекта',
            component: () =>
              import('@/components/_custom/contacts-list/ContactsListDemo.vue'),
          },
          {
            path: '/components/comments-list',
            name: 'Список комментариев объекта',
            component: () => import('@/pages/components/CommentsListDemo.vue'),
          },
          {
            path: '/components/collapse-data',
            name: 'Сворачиваемый блок данных',
            component: () => import('@/pages/components/CollapseDataDemo'),
          },
          {
            path: '/components/edit_list_contracts',
            name: 'Редактирование списка контрактов',
            component: () => import('@/pages/components/EditListContractsDemo'),
          },
          {
            path: '/components/list-moving-objects',
            name: 'Компонент для перемещения объектов между списками',
            component: () => import('@/pages/components/ListMovingObjectsDemo'),
          },
          {
            path: '/components/collapse-data',
            name: 'Сворачиваемый блок данных',
            component: () => import('@/pages/components/CollapseDataDemo'),
          },
        ],
      },
      {
        path: '/guides',
        name: 'Руководства',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            name: {
              title: 'Требования к коду',
              name: 'guides__requrements',
            },
            path: '/guides/requrements',
            component: () => import('@/pages/guides/Requirements'),
          },
          {
            name: 'Кастомные стили',
            path: '/guides/css',
            component: () => import('@/pages/guides/CustomStyles'),
          },
          {
            path: '/guides/form-validation',
            name: 'Валидация форм',
            component: () => import('@/pages/guides/FormValidation'),
          },
          {
            path: '/guides/validation',
            name: 'Валидация',
            component: () => import('@/pages/guides/Validations'),
          },
          {
            path: '/guides/common',
            name: 'Верстка',
            component: () => import('@/pages/guides/Common'),
          },
          {
            path: '/guides/form',
            name: 'Демо формы',
            component: () => import('@/pages/guides/FormDemo'),
          },
          {
            path: '/guides/page',
            name: 'Демо страницы',
            component: () => import('@/pages/guides/PageDemo'),
          },
          {
            path: '/guides/multiform',
            name: 'Демо мульти-формы',
            component: () => import('@/pages/guides/Multiform'),
          },
          {
            path: '/guides/table',
            name: 'Демо таблицы',
            component: () => import('@/pages/guides/TableDemo'),
          },
          {
            path: '/guides/popup',
            name: 'Демо попап диалога',
            component: () => import('@/pages/guides/PopupDemo'),
          },
        ],
      },
      {
        name: 'Туры',
        path: '/tours/',
        component: () => import('@/pages/_shared/Tours'),
      },
      {
        name: 'Нет доступа',
        path: '/no_access/',
        component: () => import('@/views/pages/Page403.vue'),
      },
      {
        name: {
          title: 'Лиды',
          name: 'leads',
        },
        path: '/leads/',
        component: () => import('@/pages/_shared/Leads'),
      },
      {
        path: '/lead/:id/',
        name: 'Лид',
        props: true,
        component: () => import('@/pages/_shared/Lead'),
      },
      {
        path: '/bookings/',
        name: 'Брони',
        component: () => import('@/pages/_shared/Bookings.vue'),
      },
      {
        path: '/agent/supervisor/',
        name: {
          title: 'Cупервизор',
          name: 'agent_supervisor',
        },
        meta: {
          access: ['supervisor'],
        },
        component: () => import('@/pages/a01-agent/Supervisor.vue'),
      },
      {
        name: 'Квоты',
        path: '/quotas/',
        meta: {
          access: ['agent', 'admin'],
        },
        component: () => import('@/pages/a02-agency/Quotas.vue'),
      },
      {
        name: 'Заявки на трансферы',
        path: '/transfer/bookings/',
        meta: {
          access: [
            ['admin', 'is_transfer'],
            ['transferManager', 'is_transfer'],
            ['accountant', 'is_transfer'],
          ],
        },
        component: () => import('@/pages/a12-transfer/TransferBookings.vue'),
      },
      {
        name: 'Услуги трансфера',
        path: '/transfer-service/',
        meta: {
          access: [
            ['admin', 'is_transfer'],
            ['transferManager', 'is_transfer'],
          ],
        },
        component: () => import('@/pages/_shared/TransferServiceList'),
      },
      {
        name: 'Автомобили',
        path: '/cars/',
        meta: {
          access: [
            ['admin', 'is_transfer'],
            ['transferManager', 'is_transfer'],
          ],
        },
        component: () => import('@/pages/_shared/Cars'),
      },
      {
        name: 'Назначение водителя',
        path: '/assign-driver/',
        meta: {
          access: [
            ['admin', 'is_transfer'],
            ['transferManager', 'is_transfer'],
          ],
        },
        component: () => import('@/pages/_shared/AssignDriverList.vue'),
      },
      {
        name: 'Заказчики',
        path: '/customer/',
        meta: {
          access: ['agent', 'admin'],
        },
        component: () => import('@/pages/a02-agency/Customers.vue'),
      },
      {
        name: 'Заказчик',
        path: '/customer/:id/',
        props: true,
        meta: {
          access: ['agent', 'admin'],
        },
        component: () => import('@/pages/a02-agency/Customer.vue'),
      },
      {
        name: 'Задачи',
        path: '/task/',
        meta: {
          access: ['agent', 'admin'],
        },
        component: () => import('@/pages/a02-agency/Tasks.vue'),
      },
      {
        name: 'Задача',
        path: '/task/:id/',
        props: true,
        meta: {
          access: ['agent', 'admin'],
        },
        component: () => import('@/pages/a02-agency/Task.vue'),
      },
      {
        name: 'Запросы',
        path: '/query/',
        meta: {
          access: ['manager'],
        },
        component: () => import('@/pages/a07-manager/Queries.vue'),
      },
      {
        path: '/accountant/payment_orders_list',
        name: {
          title: 'Платежные поручения системе',
          name: 'accountant__payment_orders_list',
        },
        component: () =>
          import('@/pages/a04-agency-acc/PaymentsOrdersList.vue'),
      },
      {
        path: '/accountant/qrcodes_list',
        name: 'accountant__qrcodes_list',
        component: () => import('@/pages/a04-agency-acc/QrcodesList.vue'),
      },
      {
        path: '/accountant/incoming_invoice_list',
        name: {
          title: 'Список входящих счетов от системы',
          name: 'accountant__incoming_invoice_list',
        },
        component: () =>
          import('@/pages/a04-agency-acc/SystemInvoiceListForAgency'),
      },
      {
        path: '/sys_accountant/payment_orders_list',
        name: {
          title: 'Платежные поручения',
          name: 'sys_accountant__payment_orders_list',
        },
        component: () =>
          import('@/pages/a06-accountant/PaymentsOrdersList.vue'),
      },
      {
        path: '/sys_accountant/commissioner_report_list',
        name: {
          title: 'Отчеты комиссионера',
          name: 'sys_accountant__commissioner_report_list',
        },
        component: () => import('@/pages/a06-accountant/PrincipalReports'),
      },
      {
        path: '/imports_history',
        name: {
          title: 'История загрузок',
          name: 'imports_history',
        },
        component: () => import('@/pages/_shared/ImportList'),
      },
      {
        path: '/payment_offset_list',
        name: {
          title: 'Список зачетов платежей',
          name: 'payment_offset_list',
        },
        component: () => import('@/pages/a06-accountant/PaymentOffsetList'),
      },
      {
        path: '/admin/employees_list',
        name: 'Сотрудники компании',
        component: () => import('@/pages/a09-admin/EmployeesList'),
      },
      {
        path: '/admin/local_phones_list',
        name: {
          title: 'Местные телефонные номера',
          name: 'admin__local_phones_list',
        },
        component: () => import('@/pages/a09-admin/LocalPhonesList'),
      },
      {
        path: '/admin/online_users_list',
        name: 'Сотрудники онлайн',
        component: () => import('@/pages/a09-admin/OnlineUsersList'),
      },
      {
        name: 'Черный список',
        path: '/blacklist/',
        meta: {
          access: ['admin'],
        },
        component: () => import('@/pages/a09-admin/IPList.vue'),
      },
      {
        name: 'Отчеты',
        path: '/reports/',
        component: () => import('@/pages/a08-common/ReportsList.vue'),
      },
      {
        name: 'Уведомления',
        path: '/reminders/',
        component: () => import('@/pages/_shared/Reminders.vue'),
      },
      {
        path: '/profile/',
        name: {
          title: 'Свой профайл',
          name: 'personal_profile',
        },
        component: () => import('@/pages/_shared/Profile.vue'),
      },
      {
        path: '/profile/:id/',
        name: {
          title: 'Профайл',
          name: 'profile',
        },
        props: true,
        component: () => import('@/pages/_shared/Profile.vue'),
      },
      {
        path: '/documentation/',
        name: 'Документация',
        children: [
          {
            path: ':id/',
            name: 'Статья',
            props: true,
            component: () => import('@/views/pages/Documentation.vue'),
          },
        ],
        component: () => import('@/views/pages/Documentation.vue'),
      },
      {
        path: '/demo/',
        name: 'Демо',
        component: () => import('@/pages/_demo/Demo.vue'),
      },

      // Все кучей
      {
        path: '/sys_accountant/agency_invoice_list',
        name: {
          title: 'Список счетов агентствам',
          name: 'sys_accountant__agency_invoice_list',
        },
        component: () => import('@/pages/a06-accountant/AgencyInvoiceList'),
      },
      {
        path: '/sys_accountant/supplier_invoice_list',
        name: {
          title: 'Список счетов от поставщиков',
          name: 'sys_accountant__supplier_invoice_list',
        },
        component: () => import('@/pages/a06-accountant/SupplierInvoiceList'),
      },
      {
        path: '/components/comissioner-report-accept',
        name: 'Модальное окно принятие отчета комиссионера',
        component: () =>
          import('@/pages/components/PopupComissionerReportAcceptDemo.vue'),
      },
      {
        path: '/hotels/',
        name: 'Список отелей сотрудника системы',
        component: () => import('@/pages/a10-hms/HotelsList'),
      },
      {
        path: '/hotels/:hotelId',
        name: 'Редактирование отеля',
        component: () => import('@/pages/a10-hms/HotelEdit'),
      },
      {
        path: '/hms/hotel/:hotelId/price/list',
        name: 'Список цен отеля',
        props: true,
        component: () => import('@/pages/a10-hms/HotelPricesList'),
      },
      {
        path: '/hms/hotel/:hotelId/price/edit/:priceId',
        name: 'Список цен отеля: редактирование',
        props: true,
        component: () => import('@/pages/a10-hms/HotelPricesList'),
      },
      {
        path: '/components/popup-organization',
        name: 'Создание или редактирование организации',
        component: () =>
          import('@/pages/components/OrganizationCreateOrEditPopupDemo'),
      },
      {
        path: '/components/popup-hotel-info',
        name: 'Модальное окно информации об отеле',
        component: () => import('@/pages/components/PopupHotelInfoDemo.vue'),
      },
      {
        path: '/components/popup-hotel-places-info',
        name: 'Модальное окно информации о наличии мест отеля',
        component: () =>
          import('@/pages/components/PopupHotelPlacesInfoDemo.vue'),
      },
      {
        path: '/components/popup-hotel-prices-info',
        name: 'Модальное окно информации ценах отеля',
        component: () =>
          import('@/pages/components/PopupHotelPricesInfoDemo.vue'),
      },
      {
        path: '/webmaster/bookings_forms_edit',
        name: 'Бронирование редактирование формы',
        component: () => import('@/pages/a03-webmaster/BookingFormsEdit'),
      },
      {
        path: '/webmaster/bookings_forms_integration',
        name: 'Интеграция с формами бронирования',
        component: () =>
          import('@/pages/a03-webmaster/BookingFormsIntegration'),
      },
      {
        path: '/webmaster/bookings_forms_list',
        name: 'Список форм бронирования',
        component: () => import('@/pages/a03-webmaster/BookingFormsEdit'),
      },
      {
        path: '/agent/agent_fee',
        name: 'агентское вознаграждение',
        component: () => import('@/pages/a01-agent/AgentFee'),
      },
      {
        path: '/agent/agent_profile',
        name: 'Агент профиль',
        component: () => import('@/pages/a01-agent/AgentProfile'),
      },
      {
        path: '/agent/check_in_out',
        name: 'Список тур агентов',
        component: () => import('@/pages/a01-agent/CheckInOut'),
      },
      {
        path: '/agent/hotel_find_helper',
        name: 'Найти помощника',
        component: () => import('@/pages/a01-agent/HotelFindHelper'),
      },
      {
        path: '/telecall',
        name: 'Звонки',
        component: () => import('@/pages/a09-admin/TelecallList'),
        meta: {
          access: ['agent', 'admin'],
        },
      },
      {
        path: '/telecall',
        name: 'Звонки',
        component: () => import('@/pages/a09-admin/TelecallList'),
        meta: {
          access: ['agent', 'admin'],
        },
      },
      {
        path: '/agent/script_play',
        name: 'Запустить скрипт',
        component: () => import('@/pages/a01-agent/ScriptPlay'),
      },
      {
        path: '/tour/:id/',
        name: 'Тур',
        props: true,
        component: () => import('@/pages/_shared/Tour.vue'),
      },
      {
        path: '/agent/tour_search_demo',
        name: 'Найти тур или лид',
        component: () => import('@/pages/a01-agent/TourSearchDemo'),
      },
      {
        path: '/agent/tour_search_results',
        name: 'Результаты поиска туров и лидов',
        component: () => import('@/pages/a01-agent/TourSearchResults'),
      },
      {
        path: '/agency/agency_tour_operate',
        name: 'агентство тур оператор',
        component: () => import('@/pages/a02-agency/AgencyTourOperate'),
      },
      {
        path: '/agency/operations_log',
        name: 'операционные логи',
        component: () => import('@/pages/a02-agency/OperationsLog'),
      },
      {
        path: '/agency/system_contracts',
        name: 'Системные контракты',
        component: () => import('@/pages/a02-agency/SystemContracts'),
      },
      {
        path: '/agency/tele_call_list',
        name: 'список телезвонков',
        component: () => import('@/pages/a02-agency/TeleCallsList'),
      },
      {
        path: '/accountant/agency_entries_list',
        name: 'Агентство список записей',
        component: () => import('@/pages/a04-agency-acc/AgencyEntriesList'),
      },
      {
        path: '/accountant/agency_entry_create',
        name: 'Агентство запись создать',
        component: () => import('@/pages/a04-agency-acc/AgencyEntryCreate'),
      },
      {
        path: '/accountant/export',
        name: 'экспорт',
        component: () => import('@/pages/a04-agency-acc/AgencyExport'),
      },
      {
        path: '/accountant/agency_payments_create',
        name: 'создать платеж агентства',
        component: () => import('@/pages/a04-agency-acc/AgencyPaymentCreate'),
      },
      {
        path: '/accountant/agency_payments_list',
        name: 'список платежей агентства',
        component: () => import('@/pages/a04-agency-acc/AgencyPaymentsList'),
      },
      {
        path: '/accountant/agency_payments_split',
        name: 'разбить выплаты агентства',
        component: () => import('@/pages/a04-agency-acc/AgencyPaymentSplit'),
      },
      {
        path: '/accountant/agency_reports',
        name: 'отчеты агентства',
        component: () => import('@/pages/a04-agency-acc/AgencyReports'),
      },
      {
        path: '/accountant/agency_system_documents',
        name: 'агентство системные документы',
        component: () => import('@/pages/a04-agency-acc/AgencySystemDocuments'),
      },
      {
        path: '/accountant/agency_system_settlements',
        name: 'агентская система расчетов',
        component: () =>
          import('@/pages/a04-agency-acc/AgencySystemSettlements'),
      },
      {
        path: '/accountant/agent_fee_calculation_create',
        name: 'создать расчет агентского вознаграждения',
        component: () =>
          import('@/pages/a04-agency-acc/AgentFeeCalculationCreate'),
      },
      {
        path: '/salary_accrual/',
        name: 'начисления вознаграждений',
        meta: {
          access: ['accountant'],
        },
        component: () =>
          import('@/pages/a04-agency-acc/AgentSalaryAccrualsList.vue'),
      },
      {
        path: '/accountant/agent_fee_list',
        name: 'список  агентских вознаграждения',
        component: () => import('@/pages/a04-agency-acc/AgentFeeList'),
      },
      {
        path: '/system/company/',
        name: {
          title: 'Контрагенты',
          name: 'company',
        },
        component: () => import('@/pages/a05-system/CompaniesList'),
      },
      {
        path: '/system/company/:id',
        name: 'Редактирование компании',
        props: true,
        component: () => import('@/pages/a05-system/CompanyView'),
      },
      {
        path: '/entities',
        name: 'Юридические лица',
        component: () => import('@/pages/a05-system/LegalEntities'),
      },
      {
        path: '/system/user_list',
        name: {
          title: 'Пользователи',
          name: 'system__user_list',
        },
        meta: {
          access: [['system', 'admin'], 'owner'],
        },
        component: () => import('@/pages/a05-system/UsersList'),
      },
      {
        path: '/sys_accountant/agency_create_entry',
        name: 'агентство создать юридическое лицо',
        component: () => import('@/pages/a06-accountant/AgencyCreateEntry'),
      },
      {
        path: '/sys_accountant/agency_entries_list',
        name: 'список записей агентства',
        component: () => import('@/pages/a06-accountant/AgencyEntriesList'),
      },
      {
        path: '/sys_accountant/system_agency_contract_edit',
        name: 'редактирование контракта с системным агентством',
        component: () =>
          import('@/pages/a06-accountant/SystemAgencyContractEdit'),
      },
      {
        path: '/sys_accountant/system_agency_contract_list',
        name: 'системные агентские контракты',
        component: () =>
          import('@/pages/a06-accountant/SystemAgencyContractsList'),
      },
      {
        path: '/sys_accountant/system_agency_plans_edit',
        name: 'системное агентство планы редактировать',
        component: () => import('@/pages/a06-accountant/SystemAgencyPlansEdit'),
      },
      {
        path: '/sys_accountant/system_agency_plans_list',
        name: 'список планов системного агентства',
        component: () => import('@/pages/a06-accountant/SystemAgencyPlansList'),
      },
      {
        path: '/sys_accountant/system_documents',
        name: 'системные документы',
        component: () => import('@/pages/a06-accountant/SystemDocuments'),
      },
      {
        path: '/sys_accountant/system_export',
        name: 'системный экспорт',
        component: () => import('@/pages/a06-accountant/SystemExport'),
      },
      {
        path: '/booking/:id',
        name: 'Бронь',
        meta: {
          access: ['accountant', 'manager', 'admin'],
        },
        component: () => import('@/pages/_shared/Booking.vue'),
      },
      {
        path: '/sys_accountant/system_hotelier_contract_edit',
        name: 'системный договор отельера редактировать',
        component: () =>
          import('@/pages/a06-accountant/SystemHotelierContractEdit'),
      },
      {
        path: '/sys_accountant/system_hotelier_contract_list',
        name: 'системный договор отельера список',
        component: () =>
          import('@/pages/a06-accountant/SystemHotelierContractsList'),
      },
      {
        path: '/sys_accountant/system_payment_create',
        name: 'система оплаты создать',
        component: () => import('@/components/_payment/SystemPaymentCreate'),
      },
      {
        path: '/sys_accountant/system_payments_import',
        name: 'система оплаты импорт',
        component: () => import('@/components/_payment/SystemPaymentsImport'),
      },
      {
        path: '/sys_accountant/system_payments_list',
        name: 'система оплаты список',
        meta: {
          access: [
            ['system', 'accountant'],
            ['system', 'admin'],
          ],
        },
        component: () => import('@/components/_payment/SystemPaymentsList'),
      },
      {
        path: '/sys_accountant/system_payment_split',
        name: 'система платежей разбить',
        component: () => import('@/components/_payment/SystemPaymentSplit'),
      },
      {
        path: '/sys_accountant/system_reports',
        name: 'система отчетов',
        component: () => import('@/pages/a06-accountant/SystemReports'),
      },
      {
        path: '/manager/bookings_list',
        name: 'Список броней',
        component: () => import('@/pages/a07-manager/ManagerBookingsList'),
      },
      {
        path: '/manager/booking_operate',
        name: 'Операции с бронями',
        component: () => import('@/pages/a07-manager/ManagerBookingOperate'),
      },
      {
        path: '/manager/tour_operate',
        name: 'Операции с турами',
        component: () => import('@/pages/a07-manager/ManagerTourOperate'),
      },
      {
        path: '/manager/quotes_list',
        name: 'Список квот',
        component: () => import('@/pages/a07-manager/QuotesList'),
      },
      // Демо
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography'),
      },

      {
        path: '/hotel',
        name: 'Hotel',
        component: () => import('@/views/Hotel'),
      },

      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select'),
          },
          {
            path: '/forms/multi-select',
            name: 'Multi Select',
            component: () => import('@/views/forms/MultiSelect'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets'),
      },
      {
        path: '/smart-table',
        name: 'Smart Table',
        component: () => import('@/views/smart-table/SmartTable'),
      },
      {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('@/views/plugins/Calendar'),
      },
      {
        path: 'apps',
        name: 'Apps',
        redirect: '/apps/invoicing/invoice',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'invoicing',
            redirect: '/apps/invoicing/invoice',
            name: 'Invoicing',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: 'invoice',
                name: 'Invoice',
                component: () => import('@/views/apps/invoicing/Invoice'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        path: 'restore',
        name: 'RestorePassword',
        component: () => import('@/views/pages/RestorePassword'),
      },
    ],
  },
  {
    path: '/apps/email',
    redirect: '/apps/email/inbox',
    name: 'Email',
    component: () => import('@/views/apps/email/EmailApp'),
    children: [
      {
        path: 'compose',
        name: 'Compose',
        component: () => import('@/views/apps/email/Compose'),
      },
      {
        path: 'inbox',
        name: 'Inbox',
        component: () => import('@/views/apps/email/Inbox'),
      },
      {
        path: 'message',
        name: 'Message',
        component: () => import('@/views/apps/email/Message'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      // скролл на элементы внутри страницы
      document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' })
    } else {
      // scroll to top
      return { top: 0 }
    }
  },
})

export default router
