<template>
  <CDropdown variant="nav-item">
    <CTooltip placement="bottom" content="Список задач">
      <template #toggler="{ on }">
        <div v-on="on">
          <CDropdownToggle @click.prevent :caret="false" placement="bottom-end" :class="{'disabled': !allTasksCount}">
            <AppIcon icon="list" size="lg" class="my-1" />
            <CBadge
              v-if="allTasksCount"
              class="position-absolute top-0 end-0"
              :color="failedCount ? 'danger' : 'success'"
              shape="rounded-pill"
            >{{ allTasksCount }}</CBadge>
          </CDropdownToggle>
        </div>
      </template>
    </CTooltip>

    <CDropdownMenu class="p-0">
      <CDropdownHeader class="bg-light">
        <span class="flex gap-3">
          <span>Завершено: <span class="text-success">{{ completedCount }}</span></span>
          <span>Не выполнено: <span class="text-danger">{{ failedCount }}</span></span>
        </span>
      </CDropdownHeader>

      <CDropdownItem
        v-for="task in maxCompleted"
        :key="task.id"
        class="d-block py-2"
        href="javascript:void(0)"
        @click="showCompleted(task)"
      >
        <div class="message flex-column">
          <div class="position-relative">
            <CBadge class="badge-dot top-50" color="success" position="top-start" shape="rounded-circle">
              <span class="visually-hidden">success</span>
            </CBadge>
            <div class="w-100 flex align-items-center justify-content-between">
              <span class="d-block ps-3 me-2">{{ task.name}} (ID: {{ task.id || task.result?.data?.id}})</span>
              <div @click.stop.prevent>
                <ActionButton
                  icon="reject" tooltip="Закрыть задачу" color="warning" size="sm" icon-size="lg"
                  :action="()=>{expectant.closeTask(task.id)}"
                />
              </div>
            </div>
          </div>
        </div>
      </CDropdownItem>

      <CDropdownItem
        v-for="task in maxFailed"
        :key="task.id"
        class="d-block py-2"
        href="javascript:void(0)"
        @click="showFailed(task)"
      >
        <div class="message flex-column">
          <div class="position-relative">
            <CBadge class="badge-dot top-50" color="danger" position="top-start" shape="rounded-circle">
              <span class="visually-hidden">fail</span>
            </CBadge>
            <div class="w-100 flex align-items-center justify-content-between">
              <span class="d-block ps-3 me-2">{{ task.name }} (Task ID: {{ task.id}})</span>
              <div @click.stop.prevent>
                <ActionButton
                  icon="reject" tooltip="Завершить задачу" color="warning" size="sm" icon-size="lg"
                  :action="(ev)=>{expectant.closeTask(task.id)}"
                />
              </div>
            </div>
          </div>
          <span
            v-for="(err, index) in task.result.errors"
            :key="index"
            class="d-block small ps-4"
          >- {{ err }}</span>
        </div>
      </CDropdownItem>

      <CDropdownItem class="text-center border-top">
        <button
          class="border-0 w-100 h-100 bg-transparent text-primary"
          @click="$store.commit('togglePopup', 'taskViewer')"
        >Все задачи</button>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>

  <PopupTaskFailViewer
          v-if="currTask"
          :task="currTask"
          :show="showFailViewer"
          @close="showFailViewer=false"
        />

  <PopupPaymentImportFileView
          v-if="currTask"
          :id="currTask?.result?.data?.id"
          :task-id="currTask?.id"
          :show="showPaymentImport"
          @close="showPaymentImport=false"
        />
</template>
<script setup>
import {inject, ref, watch } from 'vue'
import ActionButton from '@/components/_common/ActionButton.vue'
import PopupTaskFailViewer from '@/components/popups/PopupTaskFailViewer.vue'
import PopupPaymentImportFileView from '@/components/_payment/PopupPaymentImportFileView.vue'

const { expectant } = inject('services')
const expectState = expectant.getState()
const maxCount = ref(10)
const completedCount = ref(0)
const failedCount = ref(0)
const allTasksCount = ref(0)
const maxCompleted = ref([])
const maxFailed = ref([])

const currTask = ref()
const showFailViewer = ref(false)
const showPaymentImport = ref(false)

const showCompleted = (task) => {
  currTask.value = task
  const { data } = task.result || task

  switch (data?.data_type) {
    case 'media__importfile':
      showPaymentImport.value = true
  }
}
const showFailed = (task) => {
  currTask.value = task
  showFailViewer.value = true
}

watch(expectState, (val) => {
  const completedValues = Object.values(val.completedTasks)
  const failedValues = Object.values(val.failedTasks)

  completedCount.value = completedValues.length
  failedCount.value = failedValues.length
  maxCompleted.value = completedValues.slice(0, maxCount.value)
  maxFailed.value = maxCompleted.value.length === maxCount.value ?
    0 :
    failedValues.slice(0, maxCount.value - maxCompleted.value.length)
  allTasksCount.value = completedCount.value + failedCount.value
}, {deep: true})
</script>
