<template>
  <div ref="contactsRow" class="d-flex justify-content-between mb-3">
    <div class="d-flex position-relative w-100 me-2">
      <div class="w-100 position-relative me-2">
        <CFormSelect
          v-model="contactLocalObject.type"
          :invalid="v$.contactLocalObject.type.$error"
          @change="v$.contactLocalObject.type.$reset()"
        >
          <option value="">Выберите тип</option>
          <option v-for="{ label, value } in types" :key="value" :value="value">
            {{ label }}
          </option>
        </CFormSelect>
        <CFormFeedback
          v-if="v$.contactLocalObject.type.$invalid"
          tooltip
          invalid
        >
          {{ v$.contactLocalObject.type.$silentErrors[0].$message }}
        </CFormFeedback>
      </div>
      <div class="w-100 position-relative me-2">
        <PhoneInput
          v-if="contactLocalObject.type === 'phone'"
          v-model="contactLocalObject.number"
          placeholder="телефон"
        />
        <CFormInput
          v-else
          v-model="contactLocalObject.number"
          :invalid="v$.contactLocalObject.number.$error"
          @input="
            () => {
              v$.contactLocalObject.number.$reset()
              contactIsUnique = true
              inputContactCheck.hasInput = true
            }
          "
          placeholder="введите контакт"
        />
        <CFormFeedback
          v-if="v$.contactLocalObject.number.$invalid"
          tooltip
          invalid
        >
          {{ v$.contactLocalObject.number.$silentErrors[0].$message }}
        </CFormFeedback>
        <div
          v-if="errors.length && !contactIsUnique"
          class="invalid-tooltip show error-unique"
        >
          Контакт уже существует
        </div>
      </div>
      <div class="w-100 position-relative">
        <CFormInput
          v-model="contactLocalObject.info"
          :invalid="v$.contactLocalObject.info.$error"
          @input="v$.contactLocalObject.info.$reset()"
          placeholder="Информация"
        />
        <CFormFeedback
          v-if="v$.contactLocalObject.info.$invalid"
          tooltip
          invalid
        >
          {{ v$.contactLocalObject.info.$silentErrors[0].$message }}
        </CFormFeedback>
      </div>
    </div>
    <div class="d-flex cer-btn-wrap">
      <CTooltip v-if="canSave" :content="saveTitle" placement="top">
        <template #toggler="{ on }">
          <CButton
            color="success"
            variant="outline"
            v-on="on"
            @click="saveContact"
            class="me-2"
            :disabled="isSaving"
          >
            <CIcon v-if="!isSaving" :icon="cilCheckAlt" size="sm" />
            <CSpinner v-else color="dark" size="sm" />
          </CButton>
        </template>
      </CTooltip>
      <CTooltip
        :content="isNew ? 'Отменить' : 'Удалить контакт'"
        placement="top"
      >
        <template #toggler="{ on }">
          <CButton
            :color="isNew ? 'secondary' : 'danger'"
            variant="outline"
            v-on="on"
            @click="contactDelete"
            :disabled="isDeleting"
          >
            <template v-if="!isNew">
              <CIcon v-if="!isDeleting" :icon="cilTrash" size="sm" />
              <CSpinner v-else color="dark" size="sm" />
            </template>
            <template v-else>
              <CIcon :icon="cilX" size="sm" />
            </template>
          </CButton>
        </template>
      </CTooltip>
    </div>
  </div>
</template>

<script setup>
import { cilTrash, cilCheckAlt, cilX } from '@coreui/icons'
import C from '@/config/back_const'
import {
  inject,
  toRef,
  defineProps,
  defineEmits,
  computed,
  ref,
  onMounted,
  onUnmounted,
  reactive,
} from 'vue'
import cloneDeep from 'lodash.clonedeep'
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@/helpers/vValidators'
import PhoneInput from '@/components/form/PhoneInput.vue'
const WH = 'ContactsEditRow'

const emit = defineEmits(['update:modelValue', 'complete-action'])
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  objectType: {
    type: String,
    default: '',
  },
  objectId: {
    type: [String, Number],
    default: 0,
  },
})

const { contactApi } = inject('services')

const bus = inject('bus')

const inputContactCheck = reactive({
  hasInput: false,
})

const contactsRow = ref(null)

const isSaving = ref(false)
const isDeleting = ref(false)

const contactIsUnique = ref(true)
const contactsList = inject('contactsList')

const contactLocalObject = toRef(props, 'modelValue')
const contactCloneObject = ref(cloneDeep(props.modelValue))

const types = ref(
  Object.entries(C.CONTACT_TYPE_TITLES).map(([type, title]) => ({
    label: title,
    value: type,
  })),
)

const errors = ref([])
const externalResults = ref({
  contactLocalObject: {},
})

const isNew = computed(() => {
  return !!contactLocalObject.value.new
})

const saveTitle = computed(() => {
  return isNew.value ? 'Сохранить' : 'Обновить'
})

const validationRules = computed(() => {
  return {
    contactLocalObject: {
      type: {
        required,
      },
      number: {
        required,
        maxLength: maxLength(C.EMAIL_MAX_LENGTH),
      },
      info: {
        maxLength: maxLength(C.INFO_LENGTH),
      },
    },
  }
})

const v$ = useVuelidate(
  validationRules,
  { contactLocalObject },
  { $externalResults: externalResults },
)

const isUniqueContact = () => {
  let isUnique = true
  errors.value = []

  for (let contact of contactsList.value.slice(0, -1)) {
    isUnique = !contact.number.includes(contactLocalObject.value.number)
    if (!isUnique) {
      errors.value.push('Контакт уже существует')
      return isUnique
    } else {
      errors.value = []
    }
  }

  return isUnique
}

const resetUniquesContacts = () => {
  errors.value = []
  contactIsUnique.value = true
}

const contactCreate = async () => {
  try {
    isSaving.value = true
    if (isUniqueContact()) {
      if (!props.objectId) {
        // подготовка локаных значений (когда объект для которого устанавливаются контакты ещё не создан)
        delete contactLocalObject.value.new
      }
      const response = props.objectId
        ? await contactApi.contactCreate(
          WH,
          contactLocalObject.value.object_id,
          contactLocalObject.value.object_type,
          {
            type: contactLocalObject.value?.type,
            number: contactLocalObject.value?.number,
            info: contactLocalObject.value?.info,
            company: contactLocalObject.value?.company
          },
          )
        : contactLocalObject.value
      contactCloneObject.value = cloneDeep(response)
      emit('update:modelValue', response)
    } else {
      contactIsUnique.value = false
    }
  } catch (error) {
    console.error(error)
    if (error.response.status === 400) {
      errors.value = error.response.data
      externalResults.value.contactLocalObject = errors.value
    }
  } finally {
    isSaving.value = false
    emit('complete-action')
  }
}

const contactUpdate = async () => {
  try {
    isSaving.value = true
    if (isUniqueContact()) {
      const response = props.objectId
        ? await contactApi.contactPartial(
            WH,
            contactLocalObject.value.id,
            contactLocalObject.value,
          )
        : contactLocalObject.value
      contactCloneObject.value = cloneDeep(response)
      emit('update:modelValue', response)
    } else {
      contactIsUnique.value = false
    }
  } catch (error) {
    console.error(error)
    if (error.response.status === 400) {
      errors.value = error.response.data
      externalResults.value.contactLocalObject = errors.value
    }
  } finally {
    isSaving.value = false
    emit('complete-action')
  }
}

const submitHandler = async (handler) => {
  v$.value.$touch()
  if (!v$.value.$invalid) {
    await handler()
    inputContactCheck.hasInput = false
  }
  emit('complete-action')
}

const saveContact = () => {
  submitHandler(isNew.value ? contactCreate : contactUpdate)
}

const contactDelete = async () => {
  isDeleting.value = true
  try {
    if (!isNew.value && props.objectId) {
      await contactApi.contactDelete(WH, contactLocalObject.value.id)
    }
  } catch (error) {
    console.error(error)
    if (error.response && error.response.status === 400) {
      errors.value = error.response.data
      externalResults.value.contactLocalObject = errors.value
    }
  } finally {
    const indexInList = contactsList.value.findIndex(
      (phone) => phone.id === contactLocalObject.value.id,
    )
    if (indexInList > -1) contactsList.value.splice(indexInList, 1)
    isDeleting.value = false
  }
  emit('complete-action')
}

const canSave = computed(() => {
  if (
    contactLocalObject.value.type !== contactCloneObject.value.type ||
    contactLocalObject.value.number !== contactCloneObject.value.number ||
    contactLocalObject.value.info !== contactCloneObject.value.info
  ) {
    resetUniquesContacts()
    return true
  }

  if (contactLocalObject.value.new) {
    return true
  }

  return false
})

const saveContactInputBusHandler = () => {
  if (inputContactCheck.hasInput) {
    saveContact()
  }
}

onMounted(() => {
  if (isNew.value) {
    contactsRow.value.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
      alignToTop: false,
    })
  }
  bus.on('save-contact-input', saveContactInputBusHandler)
})

onUnmounted(() => {
  bus.off('save-contact-input', saveContactInputBusHandler)
})
</script>

<style lang="scss" scoped>
.cer-btn-wrap {
  max-height: 38px;
}
.error-unique {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000015;
  background-color: rgba(229, 83, 83, 0.9);
  border-radius: 0.375rem;

  &.show {
    display: block;
  }
}
</style>
