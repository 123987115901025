// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class PhoneApiMethodsCls {
    async phoneNumberRetrieve(wh, phone_number, extra_data = null) {
      // Получение информации о телефоне по номеру
      console.log('phoneNumberRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phoneNumber.retrieve(phone_number)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async phoneNumberUpdate(wh, phone_number, data, extra_data = null) {
      // data: PhoneUpdate
      // Обновление поля info (по номеру)
      console.log('phoneNumberUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phoneNumber.update(phone_number), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async phoneNumberDelete(wh, phone_number) {
      // Удаление всех связей у телефона (по номеру)
      console.log('phoneNumberDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.telephony.phoneNumber.delete(phone_number)})
      ctx.$services.storage.del('telephony__phone', id, wh)
      return null
    }
    async phoneForObjectList(wh, data_type, object_id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка телефонов для объекта
      // filters: number, info
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('phoneForObjectList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phoneForObject.list(data_type, object_id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async phoneForObjectCreate(wh, data_type, object_id, data, extra_data = null) {
      // data: PhoneCreate
      // Создание телефона
      console.log('phoneForObjectCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phoneForObject.create(data_type, object_id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async phoneNumberForObjectDelete(wh, data_type, object_id, phone_number) {
      // Удаление телефона у объекта по номеру
      console.log('phoneNumberForObjectDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.telephony.phoneNumberForObject.delete(data_type, object_id, phone_number)})
      ctx.$services.storage.del('None', id, wh)
      return null
    }
    async phoneForObjectDelete(wh, data_type, id, object_id) {
      // Удаление телефона у объекта по id
      console.log('phoneForObjectDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.telephony.phoneForObject.delete(data_type, id, object_id)})
      ctx.$services.storage.del('telephony__phone', id, wh)
      return null
    }
    async phoneRetrieve(wh, id, extra_data = null) {
      // Получение информации о телефоне по id
      console.log('phoneRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('telephony__phone', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'telephony__phone', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phone.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async phoneUpdate(wh, id, data, extra_data = null) {
      // data: PhoneUpdate
      // Обновление поля info info (по id)
      console.log('phoneUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phone.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async phoneDelete(wh, id) {
      // Удаление всех связей у телефона (по id)
      console.log('phoneDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.telephony.phone.delete(id)})
      ctx.$services.storage.del('telephony__phone', id, wh)
      return null
    }
  }

  ctx.$services.phoneApi = new PhoneApiMethodsCls()
}

