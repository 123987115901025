<template>
  <div>
    <CModal
      ref="modal"
      :visible="isVisible"
      size="xl"
      backdrop="static"
      alignment="center"
      fullscreen="md"
      scrollable
      @close="onClosed"
      @show="onOpened"
    >
      <CModalHeader dismiss>
        <CModalTitle> Комментарии для {{ objectTitle }}</CModalTitle>
      </CModalHeader>
      <CModalBody class="popup-comments__body py-0 px-0">
        <div
          class="popup-comments__list align-items-center p-3"
          ref="commentListElement"
        >
          <div
            v-if="!loaded"
            class="d-flex align-items-center justify-content-center h-100 py-5"
          >
            <CSpinner color="primary" />
          </div>
          <template v-if="loaded">
            <template v-if="comments?.data.length">
              <div
                v-if="scrolledTop"
                class="d-flex justify-content-center align-items-center p-3"
              >
                <CSpinner />
              </div>
              <div
                v-for="(comment, index) in comments.data"
                :key="comment.id"
                class="pb-3"
                :class="{
                  'border-bottom': index < comments.data.length - 1,
                  'mb-3': index < comments.data.length - 1,
                }"
                :ref="
                  (el) => {
                    if (el && index < comments.data.length - 1)
                      lastCommentElement = el
                  }
                "
              >
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <b class="me-3">{{
                      getUserFullName(comment.owner_identity)
                    }}</b>
                    <small
                      class="text-secondary"
                      v-html="dateService.formatDateTime(comment.created_at)"
                    ></small>
                  </div>
                  <template v-if="comment.is_active">
                    <CLoadingButton
                      :color="!comment.is_active ? 'success' : 'danger'"
                      variant="outline"
                      size="sm"
                      :loading="comment.id === updatedCommentId"
                      @click="toggleActiveComment(comment)"
                    >
                      {{ !comment.is_active ? 'Восстановить' : 'Отменить' }}
                    </CLoadingButton>
                  </template>
                </div>
                <small
                  style="white-space: pre-line"
                  :class="{
                    'text-decoration-line-through text-secondary':
                      !comment.is_active,
                  }"
                >
                  <span v-html="preprocessObjectsInText(comment.text)" />
                </small>
              </div>
            </template>
            <div
              v-if="!comments.data.length"
              class="d-flex align-items-center justify-content-center text-secondary h-100 py-5"
            >
              Тут пока пусто
            </div>
          </template>
        </div>
        <CForm
          @submit.prevent="submitHandler"
          class="popup-comments__add p-3 bg-white border-top"
        >
          <div class="mb-2">
            <CFormTextarea
              v-model="v$.newComment.text.$model"
              :invalid="v$.newComment.text.$error"
              style="font-size: 12px; min-height: 80px"
            />
            <CFormFeedback invalid>
              Поле обязательно к заполнению
            </CFormFeedback>
          </div>
          <div
            class="d-flex flex-column flex-md-row align-items-center justify-content-between"
          >
            <CFormSelect
              v-model="newComment.type"
              class="w-100 w-md-50 mb-2 mb-md-0 me-md-2"
            >
              <option
                v-for="[type, title] in Object.entries(C.COMMENT_TYPE_TITLES)"
                :key="type"
                :value="type"
              >
                {{ title }}
              </option>
            </CFormSelect>
            <CLoadingButton
              type="submit"
              color="success"
              :loading="submitLoading"
              class="w-100"
            >
              Оставить комментарий
            </CLoadingButton>
          </div>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onCancel">Закрыть</CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script>
import { inject, onMounted, reactive, ref, watchPostEffect } from 'vue'
import C from '@/config/back_const.js'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { preprocessObjectsInText } from '@/helpers/preprocessObjectsInText'

const WH = 'PopupComments'

export default {
  name: 'PopupComments',
  props: {
    dataType: {
      type: String,
      required: true,
    },
    dataId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['closed'],
  validations() {
    return {
      newComment: {
        text: {
          required,
        },
      },
    }
  },
  setup(props, { emit }) {
    const v$ = useVuelidate()

    const {
      commentApi,
      identityApi,
      userApi,
      userService,
      genericApi,
      dateService,
      commentService,
    } = inject('services')

    const isVisible = ref(false)
    const loaded = ref(false)
    const scrolled = ref(false)
    const scrolledTop = ref(null)
    const submitLoading = ref(false)
    const comments = ref([])
    const commentsCount = ref(0)
    const updatedCommentId = ref(null)
    const newComment = ref(
      commentService.getEmptyComment(props.dataType, props.dataId),
    )
    const commentListElement = ref(null)
    const lastCommentElement = ref(null)

    const identitiesIds = ref(null)
    const identitiesArr = ref(null)
    const identities = ref({})
    const identitiesUsersArr = ref(null)
    const identitiesUsers = reactive({})
    const objectTitle = ref(null)

    const pageInfo = reactive({
      limit: 10,
      offset: 0,
    })

    onMounted(async () => {
      isVisible.value = true
      objectTitle.value = await genericApi.genericTitleRetrieve(
        WH,
        props.dataType,
        props.dataId,
      )
      objectTitle.value = objectTitle.value.generic_title

      await getCommentsPage({
        mounting: true,
      })

      commentListElement.value.addEventListener('scroll', async () => {
        if (
          commentListElement.value.scrollTop === 0 &&
          pageInfo.offset < commentsCount.value
        ) {
          let newScrollHeight
          const prevScrollHeight = commentListElement.value.scrollHeight
          scrolledTop.value = true
          await getCommentsPage()
          newScrollHeight = commentListElement.value.scrollHeight
          const scrollPosition = newScrollHeight - prevScrollHeight
          commentListElement.value.scrollTo(0, scrollPosition)
        }
      })

      console.log('onMounted')
    })

    watchPostEffect(() => {
      if (!scrolled.value) {
        scrollToLastComment(lastCommentElement.value)
      }
    })

    const onClosed = () => {
      console.log('onClosed')
      isVisible.value = false
      emit('closed')
    }

    const onCancel = () => {
      console.log('onCancel')
      isVisible.value = false
      emit('closed')
    }

    const onOk = () => {
      console.log('onOk')
      isVisible.value = false
      emit('closed')
    }

    const onOpened = () => {
      console.log('onOpened')
    }

    function scrollToLastComment(element) {
      if (!element) return
      element.scrollIntoView()
      scrolled.value = true
    }

    const getUserFullName = (identityId) => {
      return userService.getPrintName(identitiesUsers[identityId], 'Система')
    }

    const toggleActiveComment = async (comment) => {
      updatedCommentId.value = comment.id
      try {
        const responseComment = await commentApi.commentActivatePartial(
          WH,
          comment.id,
          {
            is_active: !comment.is_active,
          },
        )
        comment = responseComment
      } catch (error) {
        console.error(error)
      } finally {
        updatedCommentId.value = null
      }
    }

    async function makeIdentiesUsers(commentsArr) {
      identitiesIds.value = Array.from(
        new Set(
          commentsArr.map((comment) => {
            return comment.owner_identity
          }),
        ),
      )

      identitiesArr.value = await Promise.all(
        identitiesIds.value.map(
          async (id) => await identityApi.identityRetrieve(WH, id),
        ),
      )
      identitiesArr.value.forEach((identity) => {
        identities.value[identity.id] = identity
      })

      identitiesUsersArr.value = await Promise.all(
        Object.entries(identities.value).map(
          async ([identityId, identity]) => ({
            identityId,
            user: identity.user
              ? await userApi.userRetrieve(WH, identity.user)
              : null,
          }),
        ),
      )

      identitiesUsersArr.value.forEach(({ identityId, user }) => {
        identitiesUsers[identityId] = user
      })
    }

    async function getCommentsPage({ mounting = false } = {}) {
      try {
        const response = await commentApi.commentListForObjectList(
          WH,
          props.dataId,
          props.dataType,
          {
            sort: ['-created_at'],
            offset: pageInfo.offset,
            limit: pageInfo.limit,
          },
        )

        response.data.reverse()

        if (mounting) {
          comments.value = response
        } else {
          comments.value.data = [...response.data, ...comments.value.data]
        }

        pageInfo.offset = pageInfo.offset + pageInfo.limit

        commentsCount.value = response.count

        if (!mounting) {
          scrolledTop.value = false
        }

        await makeIdentiesUsers(comments.value.data)
      } catch (error) {
        console.log(error)
      } finally {
        if (mounting) {
          loaded.value = true
        }
      }
    }

    const submitHandler = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        submitLoading.value = true
        try {
          const comment = await commentApi.commentCreate(WH, newComment.value)
          comments.value.data.push(comment)
          await makeIdentiesUsers(comments.value.data)
          newComment.value = commentService.getEmptyComment(
            props.dataType,
            props.dataId,
          )
        } catch (error) {
          console.log(error)
        } finally {
          v$.value.$reset()
          submitLoading.value = false
          scrolled.value = false
        }
      }
    }

    return {
      isVisible,
      loaded,
      scrolled,
      submitLoading,
      onClosed,
      onOpened,
      onCancel,
      onOk,
      comments,
      lastCommentElement,
      getUserFullName,
      toggleActiveComment,
      updatedCommentId,
      submitHandler,
      C,
      newComment,
      v$,
      identitiesUsers,
      dateService,
      commentService,
      objectTitle,
      commentsCount,
      commentListElement,
      scrolledTop,
      pageInfo,
      preprocessObjectsInText,
    }
  },
}
</script>

<style lang="scss" scoped>
.popup-comments {
  &__body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__list {
    overflow-y: auto;
    height: 100%;
  }
}
</style>
