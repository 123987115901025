<template>
  <router-view />
  <Toast />
  <WebPhoneContainer />
</template>
<script>
import Toast from '@/components/_common/Toast'
import {watch} from 'vue'
import { useStore } from 'vuex'
import 'v-calendar/dist/style.css'
import WebPhoneContainer from "@/components/WebPhoneContainer.vue"
export default {
  components: {WebPhoneContainer, Toast },
  setup() {

    const store = useStore()

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
