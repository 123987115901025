// для небольших хелперов которым не нужен отдельный файл

import { showPrintWindow } from '@/helpers/showPrintWindow'
import { downloadDocBlob } from '@/helpers/downloadFile'
import CC from '@/config/constants'
import { inflectNoun } from '@/helpers/textFormatter.js'

// добавить значение в глобальный объект dbg если в url есть ?debug
export const setURLDebug = (key, val) => {
  try {
    if (
      new URL(window.location.href.replace('#', '')).searchParams.has('debug')
    ) {
      window.dbg = window.dbg || {}
      window.dbg[key] = val
    }
  } catch (err) {
    //
  }
}

/**
 * Преобразование объекта из back_const в список для CFormSelect
 * @param {{}} opts
 * @returns {Array}
 */
export const formedOptsForFieldSelect = (opts) => {
  if (opts && Object.keys(opts)?.length) {
    return Object.entries(opts).map(([label, value]) => ({ label, value }))
  }
  return []
}

/**
 * Преобразование объекта из back_const в список для CFormSelect
 * @param {{}} opts
 * @returns {Array}
 */
export const formedOptsForFieldSelect2 = (opts) => {
  if (opts && Object.keys(opts)?.length) {
    return Object.entries(opts).map(([value, label]) => ({ label, value }))
  }
  return []
}

/**
 * Преобразование объекта из back_const в список для CFormSelect
 * @param {{}} arr
 * @param {{}} params
 * @param {string} [params.labelField]
 * @param {string} [params.valueField]
 * @param {function} [params.format]
 * @returns {Array}
 */
export const createArrOptions = (
  arr,
  { labelField = 'id', valueField = 'id', format } = {},
) => {
  const list = arr || []
  return list.map((item) => {
    if (format) return format(item)
    const label = item[labelField]
    const value = item[valueField]
    return { label, value }
  })
}

/**
 * Получение полного имени из имеющихся полей
 * @param {{}} obj
 * @param {string} [obj.name]
 * @param {string} [obj.surname]
 * @param {string} [obj.patronymic]
 * @param {{}} [params]
 * @param {string} [params.defaultNameText] текст по умолчанию если нет имени
 * @returns {string}
 */
export const getFullName = (obj, params = {}) => {
  const { defaultNameText = '' } = params
  if (!obj) {
    return ''
  }
  const name = `${obj?.surname || ''} ${obj?.name || ''} ${
    obj?.patronymic || ''
  }`.trim()

  return name || defaultNameText
}

export const getAgeText = (obj) => {
  if (obj?.age == null) {
    return ''
  }
  const age = Number(obj.age)
  return `${age} ${inflectNoun(age, ['год', 'года', 'лет'])}`
}
/**
 * Получение массива с телефонами из объекта типа user или person
 * @param {{}} obj
 * @param {string} [obj.phone]
 * @param {{number: string}[]} [obj.phones]
 * @param {{}} params
 * @param {boolean} [params.asString] возвращать ввиде строки или массива
 * @param {boolean} [params.withInfo] Только для asString = false. Возвращать ввиде массива объектов {number: string, info: string}
 * * @returns {string[]|{number: string, info: string}[]}
 */
export const getPhonesArr = (obj, params = {}) => {
  const { asString, withInfo } = params
  if (!obj) return []
  if (obj.phone) {
    return [obj.phone]
  }
  if (obj.phones?.length) {
    return obj.phones.map(({ number, info }) => {
      return !asString && withInfo ? { number, info } : number
    })
  }
  return []
}
/**
 * Получение массива с уникальными телефонами из нескольких объектов типа user или person
 * @param {{phone: string, phones: {number: string}[]}[]} objs
 * @param {{}} params
 * @param {boolean} [params.asString] возвращать ввиде строки или массива
 * @param {boolean} [params.withInfo] Только для asString = false. Возвращать ввиде массива объектов {number: string, info: string}
 * @returns {string[]|{number: string, info: string}[]}
 */
export const getCombinedPhonesArr = (objs, params = {}) => {
  const phonesArr = objs.reduce((acc, obj) => {
    return acc.concat(getPhonesArr(obj, params))
  }, [])
  return Array.from(new Set(phonesArr))
}
/**
 * Получение строки с телефонами из объекта типа user или person
 * @param {{}} obj
 * @param {string} [obj.phone]
 * @param {{number: string}[]} [obj.phones]
 * @returns {string}
 */
export const getPhones = (obj) => {
  return getPhonesArr(obj).join(', ')
}
/**
 * Получение строки с уникальными телефонами из нескольких объектов типа user или person
 * @param {{phone?: string, phones: {number: string}[]}[]} objs
 * @param {{}} [params]
 * @param {boolean} [params.asString] возвращать ввиде строки или массива
 * @param {boolean} [params.withInfo] Только для asString = false. Возвращать ввиде массива объектов {number: string, info: string}
 * @returns {string|string[]|{number: string, info: string}[]}
 */
export const getCombinedPhones = (objs, params = {}) => {
  const { asString = true } = params
  const phonesArr = getCombinedPhonesArr(objs, params)
  return asString ? phonesArr.join(', ') : phonesArr?.length ? phonesArr : null
}
/**
 * Получение email из объекта типа user или person
 * @param {{}} obj
 * @param {string} [obj.email]
 * @param {{type: string, number: string}} [obj.contacts]
 * @returns {string}
 */
export const getEmail = (obj) => {
  if (!obj) return ''
  if (obj.email) {
    return obj.email
  }
  if (obj.contacts?.length) {
    return obj.contacts.find((c) => c.type === 'email')?.number || ''
  }
}
/**
 * Получение массива с контактов из объекта типа user или person
 * @param {{}} obj
 * @param {string} [obj.email]
 * @param {{type: string, number: string, info: string}[]} [obj.contacts]
 * @returns {{type: string, number: string, info: string}[]}
 */
export const getContactsArr = (obj) => {
  let contacts = []
  if (!obj) return contacts
  if (obj.email) contacts.push({ type: 'email', number: obj.email, info: '' })
  if (obj.contacts?.length) {
    const objContacts = obj.email
      ? obj.contacts.filter((c) => c.type !== 'email')
      : obj.contacts
    contacts = contacts.concat(objContacts)
  }
  return contacts
}
/**
 * Получение массива с уникальными контактами из нескольких объектов типа user или person
 * @param {{email: string, contacts: {type: string, number: string, info: string}[]}[]} objs
 * @returns {{type: string, number: string, info: string}[]}
 */
export const getCombinedContactsArr = (objs) => {
  const combContactsArr = objs.reduce((acc, obj) => {
    getContactsArr(obj).forEach((c) => {
      if (
        acc.find(
          ({ type, number }) => type === c.type && number === c.number,
        ) === undefined
      ) {
        acc.push(c)
      }
    })
    return acc
  }, [])
  return combContactsArr
}
/**
 * Получение строку с уникальными контактами из нескольких объектов типа user или person
 * @param {{email?: string, contacts: {type: string, number: string, info: string}[]}[]} objs
 * @param {{}} [params]
 * @param {boolean} [params.asString] возвращать ввиде строки или массива
 * @returns {string[]|{number: string, info: string}[]}
 */
export const getCombinedContacts = (objs, params = {}) => {
  const { asString } = params
  const combContactsArr = getCombinedContactsArr(objs).map(
    ({ type, number, info }) => {
      return { number: `${type}: ${number}`, info }
    },
  )
  return asString
    ? combContactsArr.map(({ number }) => number).join(', ')
    : combContactsArr
}
/**
 * Форматирование даты в календаре Datepicker с учётом выбора одной даты
 * @param {[]} dateRange
 * @param {{}} dateService
 * @returns {string}
 */
export const formatDateRangeDefault = (dateRange, dateService) => {
  const start = dateService.formatDate(dateRange[0])
  const end = dateService.formatDate(dateRange[1])
  return start === end ? start : `${start} - ${end}`
}
/**
 * Добавляет дни
 * @param {string|Date} date
 * @param {number} days
 * @returns {Date}
 */
export const addDays = (date, days) => {
  const newDate = typeof date === 'string' ? new Date(date) : date
  newDate.setDate(date.getDate() + days)
  return newDate
}

export const getWSMessage = (ev) => {
  const data = ev.data || ev.detail // ev.detail для тестирования с CustomEvents
  if (!data || data === 'pong') return null
  try {
    return JSON.parse(data)
  } catch (err) {
    console.error(err)
    return null
  }
}

export const normalizeDownloadFilename = (filename = 'download') => {
  return filename.replace(/[.:]/, '_')
}

/**
 *
 * @param {string} [action]
 * @param {{content: string, filename: string}[]} documents
 * @param {string} [fmt] - формал для скачивания, для всех файлов
 * @param  [notification]
 * @returns {Promise<void>}
 */
export const docAction = async ({
  action = 'print',
  documents,
  fmt = 'html',
  notification,
}) => {
  try {
    if (!documents?.length) {
      const message = 'Документ пустой!'
      if (notification) {
        await notification.warning(message)
      } else {
        console.error(message)
      }
      return
    }
    if (action === 'print') await showPrintWindow(documents)
    if (action === 'download') {
      const promises = documents.map(({ content, filename }) => {
        return downloadDocBlob(content, { fmt, filename })
      })
      await Promise.all(promises)
    }
  } catch (err) {
    console.error(err)
  }
}

/**
 *  хелпер для обрезки строки до N-х символов после точки.
 * @param {string} str
 * @param {number} [index] - должен быть >= 0
 * @returns {string}
 */
export const truncate = (str, index = 2) => {
  let dotIndex = str.indexOf('.')
  if (dotIndex === -1) return str

  if (index === 0) return str.slice(0, dotIndex)

  let endIndex = dotIndex + index + 1
  return str.slice(0, endIndex)
}

export const numberToStrWithSign = (num) => {
  let sign = num < 0 || Object.is(num, -0) ? '-' : ''
  num = Math.abs(num)
  return sign + String(num)
}

export const isDateOverdue = (_date) => {
  const date = typeof _date === 'string' ? new Date(_date) : date
  return date.getTime() + CC.h24Ms - CC.minuteMs < new Date().getTime()
}

export const convertContactStrToObj = (contactStr = '') => {
  if (typeof contactStr !== 'string') return { type: '', number: '' }
  const [type = '', number = ''] = contactStr.split(':')
  return { type, number }
}

export const extractBodyHTML = (str) => {
  const rx = /<body>[\s\n]*(.+[\w\W]*)<\/body>/
  const match = str.match(rx)
  return match?.[1] || ''
}

export const asyncDebounce = (fn, delay) => {
  let timeoutId
  return (...args) => {
    return new Promise((resolve, reject) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(async () => {
        try {
          const res = await fn(...args)
          resolve(res)
        } catch (err) {
          reject(err)
        }
      }, delay)
    })
  }
}

export const roundTo2 = (val) => Math.round(val * 100) / 100
export const roundRawAmount = (val, { method = 'round' } = {}) =>
  Math[method](val / 100) * 100

export function setInitialBooker(bookingData, bookers) {
  const data = bookingData || {}
  const defaultBooker = bookers?.[0]
  if (data.booker) {
    const existInList = bookers.some((b) => b === data.booker)
    if (!existInList) {
      console.error('Букер брони не найден в списке букеров')
      return defaultBooker
    }
    return data.booker
  }
  return defaultBooker
}

export const isNone = (v) => v == null || v === ''

export const normalizeApiErrors = (response) => {
  const errData = response?.reason?.response?.data || []
  if (Array.isArray(errData)) return errData
  return Object.values(errData).reduce((acc, entryVal) => {
    if (Array.isArray(entryVal)) {
      return acc.concat(entryVal)
    }
    return acc
  }, [])
}

export const getFileHashList = (fileList) => {
  if (!fileList?.length) return
  return fileList.map(({ file: hash }) => hash)
}

/**
 * Bool или null в число
 * @param {boolean|null} val
 * @returns {null|0|1|2}
 */
export const boolOrNullToNumber = (val) => {
  if (val === undefined) return null
  return !val ? (val === null ? 2 : 0) : 1
}

export const logDebugInfoMessage = (
  message,
  { nocheck = false, bgcolor = 'dodgerblue' } = {},
) => {
  if (window.dbg || nocheck) {
    console.info(
      `%c${message}`,
      `background-color: ${bgcolor}; color: white; padding: 4px;`,
    )
  }
}
