<template>
    <CModal
      ref="modal"
      :visible="visible"
      size="lg"
      :fullscreen="false"
      backdrop="static"
      alignment="center"
      @close="onClose"
    >
      <CModalHeader dismiss>
        <CModalTitle>Несохраненные изменения</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>У вас есть несохраненные изменения, выберите дальнейшее действие</p>
        <p class="mb-1">Изменения:</p>
        <div class="pb-4">
          <i v-for="(item, i) in unsaved" :key="item.key" class="pe-2">
            <strong>{{ item.title }}{{ i !== unsaved.length - 1 ? ',' : '' }}</strong>
          </i>
        </div>
      </CModalBody>
      <CModalFooter>
        <div class="flex gap-3">
          <CButton color="secondary" size="sm" @click="onClose">Отмена</CButton>
          <CButton color="danger" size="sm" @click="onContinue">Продолжить без сохранения</CButton>
          <CButton color="success" size="sm" @click="onSave">Сохранить и продолжить</CButton>
        </div>
      </CModalFooter>
    </CModal>
</template>
<script setup>
import {inject, onMounted, onUnmounted, ref} from 'vue'

defineProps({
  unsaved: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(['close', 'continue', 'save'])
const WH = '061855fc-0b41-4266-a62e-25f227a28937'
const { storage } = inject('services')

const visible = ref(false)

const onClose = () => {
  visible.value = false
  emit('close')
}
const onContinue = () => {
  emit('continue')
  onClose()
}
const onSave = () => {
  emit('save')
  onClose()
}

onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
