<template>
    <CModal
      ref="modal"
      :visible="visible"
      size="sm"
      :fullscreen="false"
      backdrop="static"
      alignment="center"
      @close="onClose"
    >
      <CModalHeader dismiss>
        <CModalTitle>Новый лид с чатом!</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div>
          <CButton color="primary" size="lg" @click="emit('take')">Взять в работу</CButton>
        </div>
      </CModalBody>
    </CModal>
</template>
<script setup>
import {onMounted, ref, watch} from 'vue'

const props = defineProps({
  leadStatus: {
    type: Object,
    required: false,
    default: () => null
  }
})
const emit = defineEmits(['close', 'take'])

const visible = ref(false)

const onClose = () => {
  visible.value = false
  emit('close')
}

watch(() => props.leadStatus, (val) => {
   if (val.new_leads_with_chat === 0) {
     onClose()
   }
}, {deep: true})

onMounted(() => {
  visible.value = true
})
</script>
