<template>
  <div
    class="collapse-preview-content"
    :style="cssVars"
  >
    <div
      class="collapse-preview-content__body pb-3"
      :class="{
        collapsed: state.collapsed
      }"
    >
      <slot />
    </div>
    <div
      class="collapse-preview-content__footer"
      :class="{
        collapsed: state.collapsed
      }"
      :style="gradientCss"
    >
      <CButton
        :color="state.collapsed ? 'secondary' : 'info'"
        variant="outline"
        size="sm"
        @click="toggleCollapse"
        class="collapse-preview-content__toggler"
      >
        <CIcon
          :icon="cilOptions"
          size="sm"
          class="me-1"
        />
        <span>{{ togglerText }}</span>
      </CButton>
    </div>
  </div>
</template>

<script setup>
import { cilOptions } from '@coreui/icons'
import {computed, reactive, defineProps} from 'vue'

const props = defineProps({
  remHeight: {
    type: [Number, String],
    default: () => 9
  },
  hasGradient: {
    type: Boolean,
    default: () => true
  }
})

const state = reactive({
  collapsed: false,
})
const togglerText = computed(() => {
  return state.collapsed ? 'Свернуть' : `Развернуть`
})

const cssVars = computed(() => `--collapse-max-height: ${props.remHeight}rem; --gradient-height: ${Number(props.remHeight) / 1.375}rem;`)
const gradientCss = computed(() => {
  const gradientColor = `255,255,255`
  return props.hasGradient
    ? `--gradient-color: ${gradientColor}; --gradient: linear-gradient(180deg, rgba(${gradientColor}, 0) 0%, rgba(${gradientColor}, .74) 30%, rgba(${gradientColor}, 1) 100%);`
    : `--gradient-color: none; --gradient: none`
})

const toggleCollapse = () => state.collapsed = !state.collapsed

</script>

<style lang="scss" scoped>

  .collapse-preview-content {
    position: relative;

    &__body {
      position: relative;
      z-index: 0;
      height: var(--collapse-max-height);
      white-space: pre-wrap;
      overflow: hidden;

      &.collapsed {
        height: auto;
        background: none;
      }
    }

    &__footer {
      position: relative;
      margin-top: 10px;
      padding-top: .75rem;
      border-top: 1px solid var(--cui-light);

      &:after {
        position: absolute;
        bottom: calc(100% + 11px);
        display: block;
        content: '';
        width: 100%;
        height: var(--gradient-height);
        background: var(--gradient-color);
        background: var(--gradient);
      }

      &.collapsed {
        border-color: var(--cui-border-color);

        &:after {
          display: none;
        }
      }
    }

    &__toggler {

    }
  }

</style>

