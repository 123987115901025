/* Можно использовать */

import CC from "@/config/constants";
import {ref} from "vue";

const buildInfo = require('/build-info.json')

const servers = ref([
  {label: 'Production (https://api.rnsb.su/)', value: 'https://api.rnsb.su', port: ['80', '443']},
  {label: 'Staging (https://staging-api.rnsb.su:943/)', value: 'https://staging-api.rnsb.su:943', port: ['90', '943']},
  {label: 'Dev (https://dev-api.rnsb.su:843/)', value: 'https://dev-api.rnsb.su:843', port: ['88', '843']},
  {label: 'local (localhost:8000)', value: 'http://localhost:8000'},
  {label: 'local (127.0.0.1:8000)', value: 'http://127.0.0.1:8000'},
  {label: 'local (localhost:8001)', value: 'http://localhost:8001'}
])

export default ctx => {
  class VersionService  {
    clientBuild(){
      return buildInfo.commit
    }
    clientDate(){
      return buildInfo.date
    }
    clientEnvironment() {
      return process.env.NODE_ENV
    }
    isDev() {
      return process.env.NODE_ENV === 'development'
    }
    isProd() {
      return process.env.NODE_ENV === 'production'
    }

    async serverUrl()  {
      return await ctx.$services.persistentService.getGlobal(CC.LOCAL_STORAGE_SERVER_KEY)
    }

    async serverEnvironment() {
      const url = await this.serverUrl()
      console.log('serverEnvironment', url)
      if (url === servers.value[0].value){
        console.log('production')
        return 'production'
      }
      if (url === servers.value[1].value){
        console.log('staging')
        return 'staging'
      }
      console.log('development')
      return 'development'
    }

    async isDevServer() {
      return await this.serverEnvironment() === 'development'
    }

    async isProdServer() {
      return await this.serverEnvironment() === 'production'
    }

  }
  ctx.$services.version = new VersionService()
}
