<template>
  <div>
    <CAccordion v-if="programsList?.data?.length">
      <CAccordionItem
        v-for="program in programsList.data"
        :key="program.id"
        :item-key="program.id"
      >
        <CAccordionHeader>
          {{ program.title }}
        </CAccordionHeader>
        <CAccordionBody>
          <Markdown v-if="program?.description" :source="program.description" />
          <p v-else class="text-secondary mb-0">Описание не добавленно</p>
          <div>
            <CButton
              color="primary"
              @click="currentProgramId = program.id"
              size="sm"
              >Показать цены</CButton
            >
          </div>
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
    <p v-if="!programsList?.data?.length" class="text-secondary mb-0">
      Программы не добавленны
    </p>

    <PopupHotelPricesInfo
      v-if="currentProgramId"
      :data="pricesParams"
      select-mode="prog"
      @close="currentProgramId = null"
    />
  </div>
</template>

<script setup>
import Markdown from 'vue3-markdown-it'
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import PopupHotelPricesInfo from '@/components/_shared/hotel-prices/PopupHotelPricesInfo.vue'

const props = defineProps({
  hotel: {
    type: Object,
    required: true,
  },
})
const WH = '96fa6079-271c-424f-a1d8-b570e3921287'
const { programApi, storage } = inject('services')

const programsList = ref([])
const currentProgramId = ref(null)

const pricesParams = computed(() => {
  return {
    hotel: props.hotel.id,
    program: currentProgramId.value,
  }
})

const fetchPrograms = async (id) => {
  storage.clr(WH)
  try {
    if (id || props.hotel?.id) {
      programsList.value = await programApi.hotelProgramsRetrieve(
        WH,
        id || props.hotel.id,
        { limit: 100, filters: { is_active: true } },
      )
    }
  } catch (error) {
    console.error(error)
  }
}

watch(
  () => props.hotel,
  (val) => {
    fetchPrograms(val.id)
  },
)

onMounted(fetchPrograms)
onUnmounted(() => {
  storage.clr(WH)
})
</script>
