import { createStore } from 'vuex'
import company from '@/store/modules/company'
import aplications from '@/store/modules/aplications'

export default createStore({
  state: {
    asideVisible: false,
    storageViewerVisible: false,
    toasts: [],
    sidebarExpanded: true,
    sidebarVisible: true,
    theme: 'default',
    commentsTypes: [],
    lockPage: false,
    lockPageWS: false,
    lockEl: false,
    lockElCount: {},
    lockElTarget: null,
    popupsVisible: {},
    webPhoneVisible: false,
    webPhoneStarted: false
  },
  getters: {
    toasts: (state) => {
      return state.toasts
    },
    commentsTypes: (state) => {
      return state.commentsTypes
    },
    popupVisible: state => (name) => state.popupsVisible[name],
    lockPage: (state) => state.lockPage,
    lockPageWS: (state) => state.lockPageWS,
    webPhoneVisible: (state) => state.webPhoneVisible,
    webPhoneStarted: (state) => state.webPhoneStarted
  },
  mutations: {
    clearAllToasts(state) {
      state.toasts = []
    },
    pushToast(state, payload) {
      state.toasts.push(payload)
    },
    toggleAside(state, payload) {
      const asideVisibility = payload === undefined ? !state.asideVisible: payload
      state.asideVisible = asideVisibility
    },
    toggleStorageViewer(state) {
      state.storageViewerVisible = !state.storageViewerVisible
    },
    toggleSidebar(state, payload) {
      const sbState = payload === undefined ? !state.sidebarExpanded: payload
      state.sidebarExpanded = sbState
    },
    toggleSidebarVisibility(state, payload) {
      const sbVisibility = payload === undefined ? !state.sidebarVisible: payload
      state.sidebarVisible = sbVisibility
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    togglePreloader(state) {
      state.lockPageWS = !state.lockPageWS
    },
    lockPageWS(state) {
      state.lockPageWS = true
      state.lockPage = false
    },
    lockPage(state) {
      state.lockPage = true
      state.lockPageWS = false
    },
    unlockPage(state) {
      state.lockPage = false
      state.lockPageWS = false
    },
    toggleLockEl(state, payload) {
      if (payload.lock === false) {
        // если хотим проверить что в выполняются ещё запросы которые лочат этот target
        // отправляем target и lock:false - при этом спиннер не уберётся после первого запроса
        if (payload.target && state.lockElCount[payload.target] != null && state.lockElCount[payload.target] > 1) {
          state.lockElCount[payload.target] -= 1
          return
        }
        if (state.lockElTarget) delete state.lockElCount[state.lockElTarget]
        state.lockEl = false
        state.lockElTarget = null
      } else {
        if (state.lockElTarget && state.lockElTarget !== payload.target) {
          console.log(`lockElTarget не установлен на ${payload.target}, так как ужезанят на ${state.lockElTarget} `)
          return
        }
        const count = state.lockElCount[payload.target] || 0
        state.lockElCount[payload.target] = count + 1
        state.lockEl = true
        state.lockElTarget = payload.target
      }
    },
    togglePopup(state, name) {
      state.popupsVisible[name] = !state.popupsVisible[name]
    },
    toggleWebPhoneVisible(state, payload) {
      const webPhoneState = payload === undefined ? !state.webPhoneVisible: payload
      state.webPhoneVisible = webPhoneState
    },
    toggleWebPhoneStarted(state, payload) {
      const started = payload === undefined ? !state.webPhoneStarted: payload
      state.webPhoneStarted = started
    }
  },
  modules: {
    aplications,
    company
  },
})
