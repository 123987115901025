// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class FmsApiMethodsCls {
    async fmsList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Fms (фмс карточка)
      // filters: person, document_type, document_number, document_date, document_expire_date,
      //          purpose_of_entry, profession, arrival_date, stay_up_to, migration_card,
      //          legal_representatives_info, previous_address
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('fmsList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.fms.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async fmsCreate(wh, data, extra_data = null) {
      // data: Fms
      // Создание Fms (фмс карточка)
      console.log('fmsCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.fms.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async fmsRetrieve(wh, id, extra_data = null) {
      // Получение Fms (фмс карточка)
      console.log('fmsRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__fms', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__fms', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.fms.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async fmsUpdate(wh, id, data, extra_data = null) {
      // data: Fms
      // Обновление Fms (фмс карточка)
      console.log('fmsUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.fms.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async fmsDelete(wh, id) {
      // Удаление Fms (фмс карточка)
      console.log('fmsDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.fms.delete(id)})
      ctx.$services.storage.del('core__fms', id, wh)
      return null
    }
  }

  ctx.$services.fmsApi = new FmsApiMethodsCls()
}

