<template>
  <teleport v-if="modalHeaderEl" :to="modalHeaderEl">
    <div class="btn-minimize-cont">
      <button class="btn btn-minimize" @click="toggleMinimize">
        <AppIcon
          v-if="minimized"
          icon="maximize"
          size="xl"
          color="black-50"
          class="d-block"
        />
        <AppIcon
          v-else
          icon="minimize"
          size="xl"
          color="black-50"
          class="d-block"
        />
      </button>
    </div>
  </teleport>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import AppIcon from '@/plugins/app-icons/AppIcon.vue'

const props = defineProps({
  modalContainer: {
    type: Object,
    required: true,
  },
})

const modalHeaderEl = ref(null)
const minimized = ref(false)
const modalContainerEl = computed(() => props.modalContainer)
const modalBackdropEl = computed(getModalBackdropEl)
const modalContentEl = computed(getModalContentEl)

function setTopOffsetCSSCalcVal() {
  const { top } = modalContentEl.value.getBoundingClientRect()
  modalContainerEl.value.style.setProperty(
    '--modal-top-offset-calc',
    `calc(100% - ${top}px - 50px)`,
  )
}
function addMinimizeButton() {
  modalHeaderEl.value = modalContainerEl.value.querySelector('.modal-header')
}
function getModalBackdropEl() {
  return modalContainerEl.value?.querySelector('.modal-backdrop')
}
function getModalContentEl() {
  return modalContainerEl.value?.querySelector('.modal-content')
}
function toggleMinimize() {
  minimized.value = !minimized.value
}

watch(minimized, (val) => {
  const action = val ? 'add' : 'remove'
  const actionRev = val ? 'remove' : 'add'
  setTopOffsetCSSCalcVal()
  setTimeout(() => {
    modalContainerEl.value.classList[action]('minimized')
    modalBackdropEl.value.classList[actionRev]('show')
  })
})

onMounted(() => {
  addMinimizeButton()
})
</script>

<style lang="scss">
.minimizable {
  .modal {
    transition: top 350ms ease-in-out;
  }
  .modal-header {
    display: flex;
  }
  .btn-close {
    order: 2;
    margin: 0 !important;
  }
  .btn-minimize-cont {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  .btn-minimize {
    box-shadow: unset !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    &:hover {
      span {
        color: rgba(44, 56, 74, 0.9) !important;
      }
    }
  }
  &.minimized {
    & .modal {
      overflow: hidden !important;
      top: var(--modal-top-offset-calc);
    }
    .modal-header {
      transition: background-color 100ms ease 350ms;
      background-color: rgba(51, 153, 255, 0.7);
    }
  }
}
</style>
