// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CommissionApiMethodsCls {
    async commissionList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получить список активных планов комиссий
      // filters: name, is_active, created_at, updated_at
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('commissionList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async commissionCreate(wh, data, extra_data = null) {
      // data: AgencyCommissionPlan
      // Создать план комиссий
      console.log('commissionCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionAllList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получить список всех планов комиссий
      // filters: name, is_active, created_at, updated_at
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('commissionAllList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.all.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async commissionRetrieve(wh, id, extra_data = null) {
      // Получить конкретный план комиссий
      console.log('commissionRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__agencycommissionplan', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__agencycommissionplan', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionUpdate(wh, id, data, extra_data = null) {
      // data: AgencyCommissionPlan
      // Изменить план комиссий
      console.log('commissionUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionDelete(wh, id) {
      console.log('commissionDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.commission.delete(id)})
      ctx.$services.storage.del('legal__agencycommissionplan', id, wh)
      return null
    }
    async commissionPartial(wh, id, data, extra_data = null) {
      // data: AgencyCommissionPlan
      console.log('commissionPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commissionActivatePartial(wh, id, data, extra_data = null) {
      // data: AgencyCommissionPlanActivate
      // Изменение поля is_active для AgencyCommissionPlan
      console.log('commissionActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.commissionApi = new CommissionApiMethodsCls()
}

