<template>
  <div
    class="flex flex-column align-items-center"
    :class="{
      'mw-100 mh-100 justify-content-center h-100': mainImageSizes.fullSize,
    }"
    id="image-view-image-tab"
  >
    <div v-if="isFirstImageLoaded && !images.length">Нет фото</div>
    <div v-if="!isFirstImageLoaded">Загрузка...</div>
    <div
      :class="{
        'col-12': mainImageSizes.fullSize,
        'col-12 col-md-10 col-lg-9': !mainImageSizes.fullSize,
        'mw-100 mh-100': mainImageSizes.fullSize,
      }"
    >
      <div
        id="image-view-image-tab-image"
        class="mx-auto position-relative flex align-items-center"
        :class="{
          'mw-100 mh-100 justify-content-center': mainImageSizes.fullSize,
        }"
      >
        <CImage
          v-if="current.image"
          fluid
          rounded
          thumbnail
          class="p-1 p-sm-3"
          :class="{
            'w-100 h-100': upscaleImage,
            'mw-100 mh-100': mainImageSizes.fullSize,
          }"
          style="object-fit: cover"
          :src="current.image.url"
        />
        <template v-if="showArrows">
          <div
            class="position-absolute start-0 top-50 ms-4 bg-black bg-opacity-25 no-margin-btn rounded"
            :style="arrowButtonsCssVars"
          >
            <ActionButton
              v-if="!arrowButtonsDisable.prev && isFirstImageLoaded"
              icon="leftArrow"
              color="light"
              variant="outline"
              size="sm"
              icon-size="xxl"
              :style="arrowButtonsCssVars"
              :action="prev"
            />
          </div>
          <div
            class="position-absolute end-0 top-50 me-4 bg-black bg-opacity-25 no-margin-btn rounded"
          >
            <ActionButton
              v-if="!arrowButtonsDisable.next && isFirstImageLoaded"
              icon="rightArrow"
              color="light"
              variant="outline"
              size="sm"
              icon-size="xxl"
              :style="arrowButtonsCssVars"
              :action="next"
            />
          </div>
        </template>
      </div>
      <div
        v-if="showThumbs"
        id="image-view-image-tab-thumb"
        class="my-2 flex gap-2 overflow-scroll"
      >
        <div
          v-for="(image, idx) in images"
          :key="image.id"
          :data-index="idx"
          @click="onClickThumb(image, idx)"
        >
          <CImage
            rounded
            :src="image.url"
            class="mb-1 pe-none"
            style="object-fit: cover"
            :class="{
              'border border-2 border-info': current.image?.id === image.id,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue'
import { setDragScroll } from '@/helpers/setDragScroll'
import ActionButton from '@/components/_common/ActionButton.vue'

const props = defineProps({
  /**
   * Объект по которому получаем изображения
   * { data_type: string, id: string|number }
   */
  objectData: {
    type: Object,
    default: () => {},
  },
  /**
   * После маунтинга изображения загрузятся когда active станет true
   * Далее изображения будут перезагружаться если active: true и objectData.id поменялся
   */
  active: {
    type: Boolean,
    default: false,
  },
  // ограничения размеров основного изображения. Размеры нужны для того чтобы фото разных размеров отображались в одинаковом размере.
  mainImageSizes: {
    type: Object,
    required: false,
    default: () => ({
      fullSize: false,
      maxWidth: '1025px',
      height: '670px',
      mediaMaxWidth: {
        w1020: {
          height: '580px',
        },
        w860: {
          height: '480px',
        },
        w580: {
          height: '320px',
        },
      },
    }),
  },
  // размер миниатюр
  thumbs: {
    type: Object,
    required: false,
    default: () => ({
      size: '80px',
    }),
  },
  // полноэкранный режим
  fullScreen: {
    type: Boolean,
    required: false,
    default: false,
  },
  // можно передать изображения, тогда не будет запроса
  images: {
    type: Array,
    default: null,
  },
  // увеличивать фото если оно меньше контейнера, обычно ок для фото отелей, если контейнер не сильно большой
  upscaleImage: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['get-data', 'data-ready'])

const WH = 'c9024070-1379-460f-b706-6b8bf8693430'
const { storage, mediaApi } = inject('services')

let removeDragScroll = null
const arrowButtonsCssVars = {
  '--cui-btn-padding-y': '1rem',
}
const images = ref([])
const imagesLoadedFor = ref(null)
const current = reactive({
  image: null,
  index: 0,
})
const arrowButtonsDisable = reactive({
  next: false,
  prev: true,
})
const isFirstImageLoaded = ref(false)
const objectId = computed(() => props.objectData?.id)
const objectDataType = computed(() => props.objectData?.data_type)
const isActive = computed(() => props.active)
const showThumbs = computed(() => images.value.length > 1)
const showArrows = computed(() => showThumbs.value)

const loadFirstImage = (url) => {
  const img = new Image()
  img.onload = () => (isFirstImageLoaded.value = true)
  img.src = url
}

const getImages = async () => {
  if (!props.images) {
    images.value = await mediaApi.imageListForObjectList(
      WH,
      objectDataType.value,
      objectId.value,
    )
  } else {
    images.value = props.images
  }
  if (images.value?.length) {
    current.image = images.value[0]
    loadFirstImage(current.image?.url)
  } else {
    isFirstImageLoaded.value = true
  }
  imagesLoadedFor.value = objectId.value
}

const resetImageLoadings = () => {
  current.image = null
  current.index = 0
  isFirstImageLoaded.value = false
  images.value.length = 0
}

const onClickThumb = (image, index) => {
  current.image = image
  current.index = index
}

const getImageElByIndex = (index) => {
  const selector = `#image-view-image-tab-thumb div[data-index="${index}"]`
  return document.querySelector(selector)
}

const setCurrentByIndex = (index) => {
  if (index >= images.value.length || index < 0) return

  current.image = images.value[index]
  current.index = index

  const el = getImageElByIndex(index)
  if (!el) {
    console.error('Элемент изображения не найден')
    return
  }

  el.scrollIntoView({ behavior: 'smooth' })
}

const next = () => setCurrentByIndex(current.index + 1)
const prev = () => setCurrentByIndex(current.index - 1)

const init = async () => {
  try {
    emit('get-data')
    resetImageLoadings()
    await getImages()
  } catch (err) {
    console.error(err)
  } finally {
    await nextTick(() => {
      emit('data-ready')
    })
  }
}

watch(
  [objectId, isActive],
  async ([idVal, activeVal]) => {
    if (activeVal && idVal !== imagesLoadedFor.value) await init()
  },
  { immediate: true },
)
watch(
  () => current.index,
  (val) => {
    arrowButtonsDisable.next = val === images.value.length - 1
    arrowButtonsDisable.prev = val === 0
  },
  { immediate: true },
)

onMounted(() => {
  if (showThumbs.value) {
    removeDragScroll = setDragScroll('#image-view-image-tab-thumb')
  }
})
onUnmounted(() => {
  if (removeDragScroll) removeDragScroll()
  storage.clr(WH)
})
</script>

<style lang="scss">
.btn-outline-light {
  --cui-btn-hover-bg: rgba(0, 0, 21, 0.3);
  --cui-btn-hover-color: #ebedef;
  --cui-btn-hover-border-color: #ebedef;
}
#image-view-image-tab-thumb img {
  width: v-bind('props.thumbs.size');
  height: v-bind('props.thumbs.size');
  min-width: v-bind('props.thumbs.size');
  min-height: v-bind('props.thumbs.size');
}
#image-view-image-tab-image {
  height: v-bind('props.mainImageSizes.height');
  max-height: v-bind('props.mainImageSizes.maxHeight');
  max-width: v-bind('props.mainImageSizes.maxWidth');
  & .btn {
    top: 20px;
    right: 20px;
    &:hover {
      & span {
        color: rgba(var(--cui-info-rgb), 1) !important;
      }
    }
  }
}
@media (max-width: 1020px) {
  #image-view-image-tab-image {
    height: v-bind('props.mainImageSizes.mediaMaxWidth.w1020.height');
  }
}
@media (max-width: 860px) {
  #image-view-image-tab-image {
    height: v-bind('props.mainImageSizes.mediaMaxWidth.w860.height');
  }
}
@media (max-width: 580px) {
  #image-view-image-tab-image {
    height: v-bind('props.mainImageSizes.mediaMaxWidth.w580.height');
  }
}
</style>
