<template>
  <template v-if="searchString">
    <h2 class="h6 text-secondary">Поисковый запрос: {{ searchString }}</h2>
    <br />
    <v-server-table ref="tableRef" :columns="columns" :options="options">
      <template #id="{ row }">
        <router-link
          :to="`${dataTypeMap[row.data_type].route}/${row.id}/`"
          class="d-block"
          @click="emit('close')"
          >{{ dataTypeMap[row.data_type].name }} #{{ row.id }}</router-link
        >
        <small class="nobr">{{
          C[dataTypeMap[row.data_type].statusTitles][row.status]
        }}</small>
      </template>

      <template #dates="{ row }">
        <div class="z-index-5">
          <span class="d-block"
            >C:<span
              class="ps-2"
              v-html="dateService.formatDateTime(row.created_at)"
          /></span>
          <span class="d-block" v-if="row.taken_at"
            >Р:<span
              class="ps-2"
              v-html="dateService.formatDateTime(row.taken_at)"
          /></span>
          <span class="d-block" v-if="row.closed_at"
            >З:<span
              class="ps-2"
              v-html="dateService.formatDateTime(row.closed_at)"
          /></span>
        </div>
      </template>

      <template #buyer="{ row }">
        <span class="d-block">{{ getLinkedBuyerName(row) }}</span>
      </template>

      <template #agent="{ row }">
        <span class="d-block">{{ getAgentName(row) }}</span>
      </template>

      <template #checkIn="{ row }">
        <div class="z-index-5">
          <template v-if="row.data_type === 'core__lead'">
            <span v-if="row.check_in_fr" class="d-block"
              >c {{ dateService.formatDate(row.check_in_fr) }}</span
            >
            <span v-if="row.check_in_to" class="d-block"
              >по {{ dateService.formatDate(row.check_in_to) }}</span
            >
          </template>
          <template v-else-if="row.date_fr">
            <span class="d-block">{{
              dateService.formatDate(row.date_fr)
            }}</span>
          </template>
        </div>
      </template>

      <template #hotel="{ row }">
        <template v-if="row.data_type === 'core__lead'">
          <b class="d-block">{{ relLocations[row.location]?.name }}</b>
          <span>{{ relHotels[row.hotel]?.title }}</span>
        </template>
        <template v-else>
          <div
            v-for="(item, index) in getHotelAndCityFromTour(row)"
            :key="index"
          >
            <b class="d-block">{{ item?.city?.name }}</b>
            <span>{{ item?.hotel?.title }}</span>
          </div>
        </template>
      </template>
    </v-server-table>
  </template>
  <template v-else>
    <h2 class="h6 text-secondary">Поисковый запрос отсутствует</h2>
    <hr />
    <CButton color="primary" @click="tryAgain"> Попробовать снова </CButton>
  </template>
</template>

<script setup>
import { defineProps, computed, inject, ref, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import tableOptions from '@/config/table-config'
import C from '@/config/back_const'
import tableSettings from '@/config/table-config'

const WH = 'eedecc0a-a70e-4b94-868f-a1c7690e4953'

const props = defineProps({
  searchString: {
    type: String,
    default: () => '',
  },
  modalWH: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(['get-data', 'data-ready', 'close'])

const {
  leadApi,
  tourApi,
  buyerApi,
  personApi,
  organizationApi,
  identityApi,
  userApi,
  bookingApi,
  categoryApi,
  hotelApi,
  locationApi,
  storage,
  genericApi,
  tableService,
  dateService,
} = inject('services')

const store = useStore()
const tableRef = ref(null)
const searchString = computed(() => props.searchString)
const relLeads = ref({})
const relTours = ref({})
const relUsers = ref({})
const relBuyers = ref({})
const relPersons = ref({})
const relIdentities = ref({})
const relOrganizations = ref({})
const relLocations = ref({})
const relBookings = ref({})
const relCategories = ref({})
const relHotels = ref({})
const searchResults = ref([])
const dataTypeMap = {
  core__lead: {
    name: 'Лид',
    route: '/lead',
    statusTitles: 'LEAD_STATUS_TITLES',
  },
  core__tour: {
    name: 'Тур',
    route: '/tour',
    statusTitles: 'TOUR_STATUS_TITLES',
  },
}

const combineValues = (leadsAndTours, leadProp, tourProp) => {
  // например у лидов agent: id, у туров agents: [id, id].
  const items = new Set()
  leadsAndTours.forEach((item) => {
    if (item[leadProp]) items.add(item[leadProp])
    if (item[tourProp] && item[tourProp].length) {
      item[tourProp].forEach((entry) => items.add(entry))
    }
  })
  return Array.from(items).map((item) => ({ id: item }))
}
const preprocessResponse = async (response) => {
  relLeads.value = await storage.getRelations(
    response.leads.map((l) => ({ id: l })),
    'id',
    'core__lead',
    leadApi.leadRetrieve,
    WH,
  )
  relTours.value = await storage.getRelations(
    response.tours.map((t) => ({ id: t })),
    'id',
    'core__tour',
    tourApi.tourRetrieve,
    WH,
  )

  const leadsValues = Object.values(relLeads.value)
  const toursValues = Object.values(relTours.value)
  const leadsAndTours = [...leadsValues, ...toursValues]
  const agents = combineValues(leadsAndTours, 'agent', 'agents')
  const bookings = toursValues.reduce((acc, item) => {
    if (item.bookings && item.bookings.length) {
      return acc.concat(item.bookings.map((id) => ({ id })))
    } else {
      return acc
    }
  }, [])

  relBuyers.value = await storage.getRelations(
    leadsAndTours,
    'buyer',
    'core__buyer',
    buyerApi.buyerRetrieve,
    WH,
  )
  relPersons.value = await storage.getRelations(
    Object.values(relBuyers.value).filter((e) => e.person && !e.organization),
    'person',
    'core__person',
    personApi.personRetrieve,
    WH,
  )
  relOrganizations.value = await storage.getRelations(
    Object.values(relBuyers.value).filter((e) => e.organization),
    'organization',
    'core__organization',
    organizationApi.organizationRetrieve,
    WH,
  )
  relIdentities.value = await storage.getRelations(
    agents,
    'id',
    'account__identity',
    identityApi.identityRetrieve,
    WH,
  )
  relUsers.value = await storage.getRelations(
    Object.values(relIdentities.value),
    'user',
    'account__user',
    userApi.userRetrieve,
    WH,
  )
  relBookings.value = await storage.getRelations(
    bookings,
    'id',
    'core__booking',
    bookingApi.bookingRetrieve,
    WH,
  )
  relCategories.value = await storage.getRelations(
    Object.values(relBookings.value),
    'category',
    'refbook__category',
    categoryApi.categoryRetrieve,
    WH,
  )

  const categValues = Object.values(relCategories.value)

  relHotels.value = await storage.getRelations(
    leadsValues.concat(categValues),
    'hotel',
    'refbook__hotel',
    hotelApi.hotelRetrieve,
    WH,
  )

  const locationsByHotel = Object.values(relHotels.value).map((item) => ({
    location: item.parent,
  }))

  relLocations.value = await storage.getRelations(
    leadsValues.concat(locationsByHotel),
    'location',
    'refbook__location',
    locationApi.locationRetrieve,
    WH,
  )

  return {
    data: leadsAndTours,
    count: leadsAndTours.length,
  }
}
const requestFunction = async (/*req*/) => {
  try {
    storage.clr(WH)
    emit('get-data')
    const filters = {
      search_string:
        searchString.value /* encodeURIComponent(searchString.value) // fetchService их закодирует повторно */,
    }
    const response = await genericApi.searchList(WH, { filters })
    searchResults.value = await preprocessResponse(response)
    return {
      data: searchResults.value,
    }
  } catch (error) {
    console.error(error)
  } finally {
    emit('data-ready')
  }
}
const columns = ['id', 'dates', 'buyer', 'agent', 'checkIn', 'hotel']
const headings = {
  dates: 'Создано/Обработано',
  checkIn: 'Заезд',
  hotel: 'Объект/Город',
}
const options = {
  ...tableOptions,
  requestFunction: tableService.requestFnDebounceWrap(requestFunction),
  columnsClasses: {
    dates: 'z-index-10 w-10 text-nowrap ',
  },
  headings: {
    ...tableSettings.headings,
    ...headings,
  },
  filterable: false,
  alwaysShowPerPageSelect: false,
  perPage: 100,
  perPageValues: [100],
  pagination: null,
  sortable: [],
}

const normalizePersonName = (person) => {
  if (!person) return ''
  return `${person.surname || ''} ${person.name || ''} ${
    person.patronymic || ''
  }`.trim()
}
const getLinkedBuyerName = (item) => {
  const linkedBuyerName = relBuyers.value[item.buyer]?.organization
    ? relOrganizations.value[relBuyers.value[item.buyer].organization]
        ?.full_title
    : normalizePersonName(
        relBuyers.value[item.buyer]?.person
          ? relPersons.value[relBuyers.value[item.buyer].person]
          : '',
      )
  return linkedBuyerName || normalizePersonName(item)
}
const getAgentName = (row) => {
  if (!row.agent && !row.agents?.length) return ''
  const agent = row.agent || row.agents[0]
  const user = relIdentities.value[agent]?.user
  return user ? normalizePersonName(relUsers.value[user]) : ''
}
const tryAgain = () => {
  store.commit('togglePopup', 'searchLT')
}
const getHotelAndCityFromTour = (tour) => {
  if (!tour.bookings || tour.bookings.length === 0) {
    return { city: null, hotel: null }
  }
  return tour.bookings.map((id) => {
    const book = relBookings.value[id]
    if (!book || !book.category || !relCategories.value[book.category]) {
      return { city: null, hotel: null }
    }
    const hotel =
      relHotels.value[relCategories.value[book.category].hotel] || null
    const city = hotel ? relLocations.value[hotel.parent] : null
    return { city, hotel }
  })
}

watch(searchString, (val) => {
  if (!val || !tableRef.value) return
  tableRef.value.refresh()
})

onUnmounted(() => {
  storage.clr(WH)
})
</script>
