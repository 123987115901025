<template>
  <CFooter class="app-footer flex-grow-1 z-index-10 flex-wrap">
    <div class="me-3">
      <a href="https://cp.nsb.su/" target="_blank">НСБ©</a>
      <span class="ms-1">{{ years }}</span>
    </div>
    <div>
      <router-link to="/documentation/">Документация</router-link>
    </div>
    <div class="flex flex-grow-1"></div>
    <span class="me-3 flex flex-wrap gap-1">
      <span>Клиент</span>
      <span>{{ host }}</span>
      <span v-html="clientDate" />
    </span>
    <span class="flex flex-wrap gap-1 align-items-center">
      <span>Сервер</span>
      <small class="fw-medium text-primary">{{ serverName }}</small>
      <span class="position-relative mx-2">
        <CBadge
          class="badge-dot top-50"
          :color="connectionLost ? 'danger' : 'success'"
          :title="
            connectionLost ? 'Соединение потеряно' : 'Соединение восстановлено'
          "
          position="top-start"
          shape="rounded-circle"
        >
          <span class="visually-hidden">{{
            connectionLost ? 'danger' : 'success'
          }}</span>
        </CBadge>
      </span>
      <a :href="serverUrl" target="_blank" class="me-1 nobr">{{ serverUrl }}</a>
      <span v-html="serverDate"></span
    ></span>
  </CFooter>
</template>

<script setup>
import {
  onMounted,
  ref,
  inject,
  computed,
  onBeforeMount,
  onUnmounted,
} from 'vue'

const WH = '0d563750-0209-11ef-a9cd-0800200c9a66'

const { auth, versionApi, dateService, version } = inject('services')
const bus = inject('bus')
const serverVersion = ref({})
const serverUrl = ref('')

const connectionLost = ref(false)

const connectionLostHandler = (isLost) => {
  connectionLost.value = isLost
}

const host = computed(() => location?.host || '')
const years = computed(() => `2011-${new Date().getFullYear()} НСБ`)
const portRx = /\d+$/
const serverToNameMap = {
  813: 'DEV-A',
  823: 'DEV-B',
  833: 'DEV-C',
  853: 'DEV-D',
  843: 'MASTER',
  943: 'STAGING',
  0: 'PRODUCTION',
}

// const serverVersionStr = computed(() => {
//   if (serverVersion.value?.branch) {
//     return serverVersion.value?.branch.substring(0, 8)
//   } else {
//     return ''
//   }
// })

const serverDate = computed(() => {
  if (serverVersion.value?.branch_date) {
    console.log(
      serverVersion.value?.branch_date,
      typeof serverVersion.value?.branch_date,
    )
    const d = new Date(serverVersion.value?.branch_date)
    return dateService.formatDateTime(d)
  } else {
    return ''
  }
})

// const clientBuild = computed(() => {
//   return version.clientBuild()
// })

const clientDate = computed(() => {
  return dateService.formatDateTime(version.clientDate())
})

const serverName = computed(getServerName)

function getServerName() {
  const portIdx = serverUrl.value.search(portRx)
  return portIdx >= 0
            ? serverToNameMap[serverUrl.value.slice(portIdx)]
            : serverToNameMap[0]
}

onBeforeMount(() => {
  bus.on('is-connection-lost', connectionLostHandler)
})

onMounted(async () => {
  serverUrl.value = await auth.getServerUrl()
  serverVersion.value = await versionApi.Retrieve(WH)
})

onUnmounted(() => {
  bus.off('is-connection-lost', connectionLostHandler)
})
</script>

<style lang="scss" scoped>
.app-footer {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: #23282c;
  background: #ffff;
  border-top: 1px solid #c8ced3;
  min-height: 50px;
  max-height: 72px;
  line-height: 13px;
}
a {
  color: #20a8d8;
  text-decoration: none;
  background-color: transparent;
}
</style>
