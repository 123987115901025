<template>
  <!-- Выбор id и типа данных -->
  <div class="mb-3" v-if="showObjectSelection">
    <CRow>
      <CCol :md="6" class="mb-3">
        <CFormSelect
          v-model="selectObjectType"
          size="sm"
          label="Тип объекта"
          :disabled="disableObjectSelection"
        >
          <option hidden value>Не выбран</option>
          <option
            v-for="option in CC.dataTypeOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </CFormSelect>
      </CCol>
      <CCol :md="6" class="mb-3">
        <!-- Ввод id и типа данных вручную -->
        <div v-if="selectObjectType === 'unknown'" class="flex gap-2">
          <div>
            <CFormLabel for="objectDataSet.id">ID</CFormLabel>
            <CFormInput
              v-model="objectDataSet.id"
              id="objectDataSet.id"
              size="sm"
              placeholder="100001"
              :disabled="disableObjectSelection"
              @keydown.prevent.stop.enter="getAllMedia"
            />
          </div>
          <div>
            <CFormLabel for="objectDataSet.data_type">Тип данных</CFormLabel>
            <CFormInput
              v-model="objectDataSet.data_type"
              id="objectDataSet.data_type"
              size="sm"
              placeholder="refbook__hotel"
              :disabled="disableObjectSelection"
              @keydown.prevent.stop.enter="getAllMedia"
            />
          </div>
          <div class="align-self-end">
            <CButton color="primary" class="px-4" size="sm" @click="getAllMedia"
              >OK</CButton
            >
          </div>
        </div>
        <!-- autocomplete -->
        <template v-else-if="selectObjectType">
          <CFormLabel for="selectAC">Объект</CFormLabel>
          <Autocomplete
            placeholder="начните вводить название"
            id="selectAC"
            v-model="selectAC"
            :disabled="disableObjectSelection"
            :search-area="selectedSearchArea"
            size="sm"
          />
        </template>
      </CCol>
    </CRow>
  </div>
  <div :id="`id-${WH1}`" class="position-relative" style="min-height: 100px">
    <CNav variant="tabs" role="tablist">
      <CNavItem>
        <CNavLink
          :active="activeTab === 1"
          href="javascript:void(0)"
          @click="activeTab = 1"
        >
          Файлы
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          :active="activeTab === 2"
          href="javascript:void(0)"
          @click="activeTab = 2"
        >
          Изображения
        </CNavLink>
      </CNavItem>
      <div class="flex flex-grow-1 justify-content-end align-items-center">
        <template v-if="showUploadButton">
          <ActionButton
            icon="create"
            :tooltip="`Загрузить ${fileTypeText}`"
            color="info"
            size="sm"
            variant="outline"
            style="padding: 4px !important; display: flex"
            icon-size="lg"
            :disabled="!objectDataSet.data_type || !objectDataSet.id"
            disabled-tooltip="Чтобы загрузить файлы выберите объект"
            :action="
              () => {
                shAddFiles = true
              }
            "
          />
          <UploadFilesModal
            v-if="shAddFiles"
            :object-type="objectDataSet.data_type"
            :object-id="objectDataSet.id"
            :fixed-title="fixedTitle"
            :file-type="currFileType"
            @close="onCloseAddFiles"
            @upload-complete="onUploadComplete"
          />
        </template>
      </div>
    </CNav>
    <CTabContent>
      <CTabPane role="tabpanel" :visible="activeTab === 1">
        <div :id="uniqSelectors.file">
          <v-client-table
            :data="filesList"
            :columns="columns"
            :options="options"
            :name="WH1"
          >
            <template #title="{ row }">
              <button
                class="border-0 w-100 h-100 bg-transparent text-primary flex p-0"
                @click="showFile(row)"
              >
                <template v-if="row.fixed_title">
                  <div class="fw-bold text-start">
                    {{ row.fixed_title }}
                  </div>
                </template>
                <template v-else>
                  <div class="fw-bold">{{ row.title }}</div>
                </template>
              </button>
              <div v-if="row.fixed_title" class="fw-normal text-start">
                {{ row.title }}
              </div>
            </template>
            <template #active="{ row }">
              <div class="flex justify-content-center">
                <AppIcon
                  v-if="row.inactive"
                  icon="reject"
                  color="danger"
                  size="lg"
                />
                <AppIcon v-else icon="accept" color="success" size="lg" />
              </div>
            </template>
            <template #actions="{ row }">
              <ActionButton
                icon="delete"
                tooltip="Удалить"
                color="danger"
                size="sm"
                icon-size="lg"
                :action="
                  () => {
                    deleteFile(row)
                  }
                "
              />
            </template>
          </v-client-table>
        </div>
      </CTabPane>
      <CTabPane role="tabpanel" :visible="activeTab === 2">
        <div :id="uniqSelectors.image">
          <v-client-table
            :data="imagesList"
            :columns="columns"
            :options="options"
            :name="WH2"
          >
            <template #thumb="{ row }">
              <div role="button">
                <img
                  class="max-w-3"
                  role="button"
                  @click="showImage(row)"
                  :src="row.url"
                />
              </div>
            </template>
            <template #title="{ row }">
              <button
                class="border-0 w-100 h-100 bg-transparent text-primary flex p-0"
                @click="showFile(row)"
              >
                <template v-if="row.fixed_title">
                  <div class="fw-bold text-start">
                    {{ row.fixed_title }}
                  </div>
                </template>
                <template v-else>
                  <div class="fw-bold">{{ row.title }}</div>
                </template>
              </button>
              <div v-if="row.fixed_title" class="fw-normal text-start">
                {{ row.title }}
              </div>
            </template>
            <template #active="{ row }">
              <div class="flex justify-content-center">
                <AppIcon
                  v-if="row.inactive"
                  icon="reject"
                  color="danger"
                  size="lg"
                />
                <AppIcon v-else icon="accept" color="success" size="lg" />
              </div>
            </template>
            <template #actions="{ row }">
              <ActionButton
                v-if="actions.includes('delete')"
                icon="delete"
                tooltip="Удалить"
                color="danger"
                size="sm"
                icon-size="lg"
                :action="
                  () => {
                    deleteFile(row)
                  }
                "
              />
            </template>
          </v-client-table>
        </div>
      </CTabPane>
    </CTabContent>
  </div>
  <teleport v-if="mounted" :to="`#id-dev-${modalWh}`" :disabled="!modalWh">
    <ThePodval
      title="Компонент просмотра загруженных файлов для объекта"
      :tasks="['https://tracker.yandex.ru/BACK-4534']"
      :wiki="[
        'https://wiki.yandex.ru//homepage/proekt/api/zaprosy/zapros-fajjlov-i-izobrazhenijj/',
        'https://wiki.yandex.ru/homepage/proekt/interfejjs/obshhie-jelementy/blok-dokumenty/',
      ]"
      :uuid="WH1"
    />
  </teleport>
  <ImagesViewModal
    v-if="showImageConf"
    :object-data="showImageConf.objectData"
    :active="showImageConf.active"
    :images="showImageConf.images"
    :upscale-image="showImageConf.upscaleImage"
    :main-image-sizes="showImageConf.mainImageSizes"
    @close="showImageConf = null"
  />
</template>

<script>
import { showFileWindow } from '@/helpers/showFileWindow'
import { inject, onBeforeMount, ref } from 'vue'

export const useShowFile = () => {
  const { auth } = inject('services')

  const serverUrl = ref('')
  const showImageConf = ref(null)

  // нужен если url файлов приходит без http (как сейчас у thumbnails)
  const getServerUrl = async () => {
    serverUrl.value = await auth.getServerUrl()
  }

  const showFile = (file) => {
    const params = { serverUrl: serverUrl.value }
    showFileWindow(file, params)
  }

  const showImage = (image) => {
    showImageConf.value = {
      active: true,
      images: [image],
      upscaleImage: false,
      mainImageSizes: {
        fullSize: true,
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        mediaMaxWidth: {
          w1020: {
            height: '100%',
          },
          w860: {
            height: '100%',
          },
          w580: {
            height: '100%',
          },
        },
      },
    }
  }

  onBeforeMount(async () => {
    await getServerUrl()
  })

  return {
    showFile,
    showImage,
    showImageConf,
  }
}

export const useDeleteFiles = () => {
  const { mediaApi } = inject('services')

  const getFileType = (file) => {
    return file.data_type === 'media__imagetoobject' ? 'image' : 'file'
  }

  const deleteFiles = async (files, { WH, filesWH, imagesWH }) => {
    if (!Array.isArray(files)) return
    const promises = files.map((file) => {
      const type = getFileType(file)
      const _WH = type === 'image' ? imagesWH || WH : filesWH || WH
      const method =
        type === 'image' ? mediaApi.imageDelete : mediaApi.fileDelete
      return method(_WH, file.id)
    })
    await Promise.allSettled(promises)
  }

  return {
    deleteFiles,
    getFileType,
  }
}
</script>

<script setup>
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  watch,
} from 'vue'
import ActionButton from '@/components/_common/ActionButton.vue'
import tableSettings from '@/config/table-config'
import { useRequest } from '@/composables/request'
import Autocomplete from '@/components/custom/Autocomplete.vue'
import UploadFilesModal from '@/components/_custom/upload-files/UploadFilesModal.vue'
import AppIcon from '@/plugins/app-icons/AppIcon.vue'
import ImagesViewModal from '@/components/_custom/images-view/ImagesViewModal.vue'
import CC from '@/config/constants'
import { useAuthData } from '@/composables/authData'
import ThePodval from '@/components/_shared/ThePodval.vue'

const props = defineProps({
  objectId: {
    type: [Number, String],
    required: true,
  },
  objectType: {
    type: String,
    required: true,
  },
  // active, disabled, hidden
  objectSelection: {
    type: String,
    default: 'active',
  },
  modalWh: {
    type: String,
    default: null,
  },
  showUploadButton: {
    type: Boolean,
    default: true,
  },
  // [delete]
  actions: {
    type: Array,
    default: () => [],
  },
  // TEMPORARY если временный файл для привязки к личности
  fixedTitle: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits(['upload-complete', 'delete-file'])

const WH1 = '7a49c683-b88f-4431-9297-426dd7e8bdfd'
const WH2 = 'f6ee44fd-63e9-48bd-af70-286393ff508e'
const { storage, mediaApi } = inject('services')

const { wrapRequest } = useRequest({
  lockTarget: `#id-${props.modalWh || WH1}`,
})
const { authReady, myIdentity } = useAuthData()
const { showFile, showImage, showImageConf } = useShowFile()
const { deleteFiles, getFileType } = useDeleteFiles()

const mounted = ref(false)
const isVisible = ref(false)
const filesList = ref([])
const imagesList = ref([])
// если нет objectId то используем личность для просмотра
// потому что при загрузке на ещё не созданный объект временно используется личность
const objectDataSet = reactive({
  id: props.objectId,
  data_type: props.objectType,
})
const selectAC = ref()
const selectObjectType = ref()

const activeTab = ref(1)

const headingsObj = {
  thumb: '',
  title: 'fixed_title / title',
  active: 'Актуален',
  size: 'Размер файла',
  actions: 'Действия',
}
const uniqSelectors = reactive({ file: '', image: '' })
const shAddFiles = ref(false)

const selectedSearchArea = computed(() => {
  return [
    {
      content_type: selectObjectType.value,
    },
  ]
})
const showObjectSelection = computed(() => {
  return (
    props.objectSelection === 'active' || props.objectSelection === 'disabled'
  )
})
const disableObjectSelection = computed(() => {
  return props.objectSelection === 'disabled'
})
const currFileType = computed(() => (activeTab.value === 1 ? 'file' : 'image'))
const fileTypeText = computed(() => {
  return currFileType.value === 'image' ? 'изображения' : 'файлы'
})
const headings = computed(() => {
  const _headings = { ...headingsObj }
  if (currFileType.value === 'file') {
    delete _headings.thumb
  }
  if (!props.actions?.length) {
    delete _headings.actions
  }
  return _headings
})
const columns = computed(() => Object.keys(headings.value))
const options = computed(() => ({
  ...tableSettings,
  headings: headings.value,
  sortable: [],
  pagination: {
    show: true,
    dropdown: false,
    chunk: 5,
    edge: true,
    nav: 'fixed',
  },
  columnsClasses: {
    thumb: 'text-center w-10 t-v-align-middle',
    active: 'text-center w-5 t-v-align-middle',
    size: 'text-center w-10 t-v-align-middle',
    actions: 'text-center w-5 t-v-align-middle',
  },
  perPage: 5,
  perPageValues: [5, 10, 20, 30, 50],
}))

function isUnknownObjectType(objType) {
  return (
    objType && CC.dataTypeOptions.find((opt) => objType === opt.value) == null
  )
}
const getFilesList = async () => {
  filesList.value = await mediaApi.fileListForObjectList(
    WH1,
    objectDataSet.data_type,
    objectDataSet.id,
  )
}
const getImagesList = async () => {
  imagesList.value = await mediaApi.imageListForObjectList(
    WH2,
    objectDataSet.data_type,
    objectDataSet.id,
  )
}
const getAllMedia = async () => {
  if (!objectDataSet.id || !objectDataSet.data_type) {
    filesList.value = []
    imagesList.value = []
    return
  }
  await wrapRequest(async () => Promise.all([getFilesList(), getImagesList()]))
}
const deleteFile = async (file) => {
  await wrapRequest(async () => {
    await deleteFiles([file], { filesWH: WH1, imagesWH: WH2 })
    const type = getFileType(file)
    const cb = type === 'image' ? getImagesList : getFilesList
    emit('delete-file', file)
    await cb()
  })
}
// const shorter = (str) => {
//   if (!str) return '---'
//   return `${str.substring(0, 4)}...${str.substring(str.length - 4)}`
// }
const onCloseAddFiles = () => {
  getAllMedia()
  shAddFiles.value = false
}

const onUploadComplete = (payload) => {
  emit('upload-complete', payload)
}

const init = async () => {
  await getAllMedia()
}

watch(selectObjectType, () => {
  selectAC.value = null
})
watch(selectAC, (val) => {
  objectDataSet.id = val?.id || null
  objectDataSet.data_type = val?.data_type || null
  getAllMedia()
})

watch(
  authReady,
  async (val) => {
    if (!val) return

    const objectId = props.objectId || myIdentity.value.id
    const objectType = props.objectId
      ? props.objectType
      : myIdentity.value.data_type

    objectDataSet.id = objectId
    objectDataSet.data_type = objectType

    selectObjectType.value = isUnknownObjectType(objectDataSet.data_type)
      ? 'unknown'
      : objectDataSet.data_type

    // установка selectObjectType обнуляет selectAC в watch поэтому
    // ставим selectAC заново после nextTick
    await nextTick()

    selectAC.value = { id: objectId, data_type: objectType }

    await wrapRequest(init)
  },
  { immediate: true },
)

onMounted(async () => {
  isVisible.value = true
  uniqSelectors.file = `media-files-view-tab-${Date.now()}`
  uniqSelectors.image = `media-images-view-tab-${Date.now()}`
  mounted.value = true
  await nextTick(async () => {
    tableSettings.perPageSelectToBottom(`#${uniqSelectors.file}`)
    tableSettings.perPageSelectToBottom(`#${uniqSelectors.image}`)
  })
})
onUnmounted(() => {
  storage.clr(WH1)
  storage.clr(WH2)
})
</script>
