// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgencybalanceApiMethodsCls {
    async agencyBalanceBuyerLatestList(wh, buyer_id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение текущих балансов покупателя
      // filters: entity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('agencyBalanceBuyerLatestList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.balance.buyer.latest.list(buyer_id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async agencyBalanceBuyerList(wh, buyer_id, date, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение текущих балансов покупателя на дату
      // filters: entity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('agencyBalanceBuyerList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.balance.buyer.list(buyer_id, date, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async agencyBalanceLatestList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение текущих балансов всех покупателей
      // filters: buyer, entity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('agencyBalanceLatestList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.balance.latest.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async agencyBalanceList(wh, date, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение текущих балансов всех покупателей на дату
      // filters: buyer, entity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('agencyBalanceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.balance.list(date, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.agencyBalanceApi = new AgencybalanceApiMethodsCls()
}

