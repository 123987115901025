// helpers request can contains /* $pending, $invalid, $model, $params */ parameters

import {
  helpers,
  email as emailValidator,
  required as requiredValidator,
  minLength as minLengthValidator,
  maxLength as maxLengthValidator,
  numeric as numericValidator,
  integer as integerValidator,
  decimal as decimalValidator,
  minValue as minValueValidator,
  maxValue as maxValueValidator,
} from '@vuelidate/validators'

// Native validations

export const minLength = (len) =>
  helpers.withMessage(({ $params }) => {
    return `Поле должно содержать минимум ${$params.min} символа(ов)`
  }, minLengthValidator(len))

export const maxLength = (len) =>
  helpers.withMessage(({ $params }) => {
    return `Поле должно содержать максимум ${$params.max} символа(ов)`
  }, maxLengthValidator(len))

export const minValue = (len) =>
  helpers.withMessage(({ $params }) => {
    return `Минимальное число: ${$params.min}`
  }, minValueValidator(len))

export const maxValue = (len) =>
  helpers.withMessage(({ $params }) => {
    return `Максимальное число: ${$params.max}`
  }, maxValueValidator(len))

export const email = helpers.withMessage(
  (val) => `Неправильный email ${val?.$model ? `"${val.$model}"` : ''}`,
  emailValidator,
)
export const required = helpers.withMessage(
  'Это поле не может быть пустым',
  requiredValidator,
)
export const numeric = helpers.withMessage(
  'Поле должно содержать только числа',
  numericValidator,
)
export const integer = helpers.withMessage(
  'Это поле должно содержать только числа',
  integerValidator,
)
export const decimal = helpers.withMessage(
  'Это поле должно содержать только десятичные числа',
  decimalValidator,
)

// Customs validations
export const listNotEmpty = helpers.withMessage(
  'Выберите минимум один элемент',
  (list) => {
    return list.length > 0
  },
)

export const minAndMaxNumber = (min, max) =>
  helpers.withMessage(`Должно быть числом от ${min} до ${max}`, (value) => {
    if (!value) return true
    const val = Number(value)
    return val >= min && val <= max
  })

export const percent = helpers.withMessage(
  'Поле должно быть процентом - числом от 0 до 100',
  (value) => {
    if (!value) return true
    return /^[0-9][0-9]?$|^100$/.test(value)
  },
)

export const phone = helpers.withMessage(
  `Пожалуйста введите правильный номер телефона`,
  (value) => {
    if (!value) return true

    return /^\+\d{7,19}$/.test(value)
  },
)

export const letters = helpers.withMessage(
  `Поле не должно содержать цифр`,
  (value) => {
    let pattern = /[0-9]/
    return !helpers.req(value) || !value.match(pattern)
  },
)

export const names = helpers.withMessage('Недопустимые символы', (val) => {
  const _val = val === undefined || val === null ? '' : val
  const res = _val.match(/[а-яА-ЯЁёa-zA-Z- ]*/)
  return res ? res[0] === _val : false
})

export const labelNum = helpers.withMessage('Недопустимые символы', (val) => {
  const _val = val === undefined || val === null ? '' : val
  const res = _val.match(/[а-яА-ЯЁёa-zA-Z- 0-9]*/)
  return res ? res[0] === _val : false
})

export const birthDocNum = helpers.withMessage(
  'Введите коррекный номер документа по примеру: IV ДЯ 123456',
  (val) => {
    const _val = val === undefined || val === null ? '' : val
    return _val.match(/^[IVXLX]{1,5}[\s-][А-ЯABCEHKMOPTXY]{2}[\s-]\d{6}$/)
  },
)

export const extra = helpers.withMessage(
  `Поле не должно содержать специальных символов`,
  (value) => {
    let pattern = /[^а-яЁёА-Яa-zA-Z0-9 _-]/
    return !helpers.req(value) || !value.match(pattern)
  },
)

export const passportRu = helpers.withMessage(
  `Паспорт должен состоять из 10 цифр`,
  (value) => {
    const pattern = /\d{10}$/
    const val = value.replaceAll(' ', '')
    return !helpers.req(value) || (val.match(pattern) && val.length === 10)
  },
)

// валидатор не допускает спец.символы, кроме 1-го дефиса
export const allowHyphen = helpers.withMessage(
  `Используйте дефис только для дефисных написаний(прим. папье-маше)`,
  (value) => {
    let pattern = /^[^-]+([-|\s][^-]+)*$/
    return !helpers.req(value) || value.match(pattern)
  },
)

// валидатор не допускает спец.символы, кроме кавычек
export const allowQuotationMarks = helpers.withMessage(
  'Поле не должно содержать специальных символов',
  (value) => {
    let pattern = /[^" а-яА-Яa-zA-Z0-9]/
    return !helpers.req(value) || !value.match(pattern)
  },
)

// валидатор не допускает спец.символы, кроме кавычек, знаков препинания и пробелов
// для формирования названия компаний и юр.лиц
export const allowSymbolsName = helpers.withMessage(
  'Поле не должно содержать специальных символов',
  (value) => {
    let pattern = /[^-"'«»№., а-яА-Яa-zA-Z0-9]/
    return !helpers.req(value) || !value.match(pattern)
  },
)

// валидатор не допускает спец.символы, кроме кавычек, знаков препинания и пробелов
// для формирования адреса
export const allowSymbolsAddress = helpers.withMessage(
  'Поле не должно содержать специальных символов',
  (value) => {
    let pattern = /[^-/;:"'., а-яА-Яa-zA-Z0-9]/
    return !helpers.req(value) || !value.match(pattern)
  },
)

export const spaces = helpers.withMessage(
  `Поле не должно содержать пробелов`,
  (value) => {
    return !helpers.req(value) || !value.includes(' ')
  },
)

export const length = (param) => {
  const message = `${
    typeof param === 'object'
      ? `Поле должно содержать ${param[0]} или ${param[1]} символа(ов)`
      : `Поле должно содержать ровно ${param} символа(ов)`
  }`

  return helpers.withMessage(message, (value) => {
    if (!value) return !value
    if (typeof param === 'object') {
      let valid
      param.forEach((item, index) => {
        valid = !index ? value.length === item : valid || value.length === item
      })
      return valid
    } else if (typeof param === 'number') return value.length === param
  })
}

export const notSameAsMany = (papam, message) => {
  return helpers.withMessage(
    `${message ? message : 'Значение не уникальное'}`,
    (value) => {
      return !papam.includes(value)
    },
  )
}

export const positiveNumeric = helpers.withMessage(
  `Значение должно быть положительным числом`,
  (value) => {
    value = Number(value)
    if (isNaN(value)) return false
    if (value < 0) return false
    return true
  },
)

/**
 *
 * @param minValue
 * @returns {(function(*): (boolean))|*}
 * @example
 * validValueOrZero: helpers.withMessage(
 *         (rule) => `Значение должно быть 0 или болше чем ${rule.min},
 *         validValueOrZero(5) // минимальное значение
 *       )
 */
export const noneOrMoreThan = (minValue) => {
  const validator = (value) => {
    // проверка что значение null, undefined, или >= minValue
    return value == null || value >= minValue
  }

  return helpers.withParams({ minValue }, validator)
}
