<template>
  <div class="minimizable" ref="modalContainer">
    <CModal
      ref="modal"
      :visible="isVisible"
      size="lg"
      backdrop="static"
      alignment="center"
      fullscreen="sm"
      @close="onCancel"
    >
      <ModalMinimize v-if="modalContainer" :modal-container="modalContainer" />
      <CModalHeader dismiss>
        <CModalTitle>{{ modalTitle }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CAlert color="danger" v-if="err">{{ err }}</CAlert>

        <div>
          <div class="mb-3">
            <div class="row">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel for="personName" class="required"> Имя </CFormLabel>
                <CFormInput
                  v-model.trim="v$.person.name.$model"
                  :invalid="v$.person.name.$error"
                  id="personName"
                  type="text"
                  size="sm"
                />
                <CFormFeedback v-if="v$.person.name.$error" invalid>
                  {{ v$.person.name.$silentErrors[0].$message }}
                </CFormFeedback>
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel
                  for="personSurname"
                  :class="surnameIsRequired ? 'required' : ''"
                >
                  Фамилия
                </CFormLabel>
                <CFormInput
                  :invalid="v$.person.surname.$error"
                  v-model.trim="v$.person.surname.$model"
                  id="personSurname"
                  type="text"
                  size="sm"
                />
                <CFormFeedback v-if="v$.person.surname.$error" invalid>
                  {{ v$.person.surname.$silentErrors[0].$message }}
                </CFormFeedback>
              </div>
              <div class="col-12 col-md-4">
                <CFormLabel for="personPatronymic"> Отчество </CFormLabel>
                <CFormInput
                  :invalid="v$.person.patronymic.$error"
                  v-model.trim="v$.person.patronymic.$model"
                  id="personPatronymic"
                  type="text"
                  size="sm"
                />
                <CFormFeedback v-if="v$.person.patronymic.$error" invalid>
                  {{ v$.person.patronymic.$silentErrors[0].$message }}
                </CFormFeedback>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel for="personBirthday"> Дата рождения </CFormLabel>
                <Datepicker
                  v-model="person.birthday"
                  @update:model-value="onBirthday"
                  label="Дата рождения"
                  placeholder=""
                  locale="ru-RU"
                  format="dd.MM.yyyy"
                  model-type="yyyy-MM-dd"
                  :enable-time-picker="false"
                  size="sm"
                  :range="false"
                  :multi-calendars="false"
                  :max-date="new Date()"
                  :week-start="1"
                  cancel-text="Отменить"
                  select-text="Выбрать"
                />
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel for="personAge"> Возраст </CFormLabel>
                <CFormInput
                  id="personAge"
                  :invalid="v$.person.age.$error"
                  v-model.number="v$.person.age.$model"
                  size="sm"
                  @update:model-value="onAge"
                />
                <CFormFeedback v-if="v$.person.age.$invalid" invalid>
                  {{ v$.person.age.$silentErrors[0].$message }}
                </CFormFeedback>
              </div>
              <div class="col-12 col-md-4">
                <CFormLabel for="personGender"> Пол </CFormLabel>
                <CFormSelect
                  id="personGender"
                  :invalid="v$.person?.gender?.$error"
                  v-model="person.gender"
                  size="sm"
                >
                  <option value="">Выберите значение</option>
                  <option
                    v-for="gender in Object.entries(C.GENDER_TITLES)"
                    :key="gender[0]"
                    :value="gender[0]"
                  >
                    {{ gender[1] }}
                  </option>
                </CFormSelect>
                <CFormFeedback v-if="v$.person?.gender?.$invalid" invalid>
                  {{ v$.person.gender.$silentErrors[0].$message }}
                </CFormFeedback>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel> Гражданство </CFormLabel>
                <Autocomplete
                  v-model="country"
                  :min_len="2"
                  size="sm"
                  :search-area="sAreaCountry"
                />
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel> Город местонахождения </CFormLabel>
                <Autocomplete
                  v-model="cityOfResident"
                  :min_len="3"
                  size="sm"
                  :search-area="sAreaCity"
                  @update:model-value="changeCity"
                />
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <CFormLabel> Социальная группа </CFormLabel>
                <CMultiSelect
                  placeholder="Выберите"
                  select-all-label="Выбрать все"
                  :cleaner="true"
                  :options="socialGroups"
                  @change="changeSocialGroup"
                  size="sm"
                />
              </div>
            </div>
          </div>
          <div v-if="haveContacts" class="mb-4">
            <div class="row">
              <div class="col-12 col-lg-6 mb-3 mb-md-0">
                <CFormLabel> Телефоны </CFormLabel>
                <PhonesEdit
                  object-type="core__person"
                  :object-id="personId"
                  :initial-phones="initialPhones"
                  @complete-action="onPhonesComplete"
                />
              </div>
              <div class="col-12 col-lg-6 mb-3 mb-md-0">
                <CFormLabel> Контакты </CFormLabel>
                <ContactsEdit
                  object-type="core__person"
                  :object-id="personId"
                  :initial-contacts="initialContacts"
                  :init-data="initData"
                  :validation-errors="v$.person.email.$errors"
                  @complete-action="onContactsComplete"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <CAccordion>
              <CAccordionItem>
                <CAccordionHeader ref="docAccordionHeaderRef">
                  Паспортные данные
                </CAccordionHeader>
                <CAccordionBody class="p-0">
                  <CRow>
                    <CCol xs="12" md="5">
                      <div class="mb-3">
                        <CFormLabel for="personDocumentType">
                          Тип документа
                        </CFormLabel>
                        <CFormSelect
                          id="personDocumentType"
                          size="sm"
                          :invalid="!!errors?.document_type"
                          v-model="person.document_type"
                        >
                          <option value="">Не выбрано</option>
                          <option
                            v-for="[type, title] in Object.entries(
                              C.PERSON_PASSPORT_TYPE_TITLES,
                            )"
                            :key="type"
                            :value="type"
                          >
                            {{ title }}
                          </option>
                        </CFormSelect>
                      </div>
                      <div class="mb-3">
                        <CFormLabel for="personPassportSeries">
                          Номер документа
                        </CFormLabel>

                        <input
                          v-if="isDocFieldBirthday"
                          v-maska:[optionsMaskBirthday]
                          :class="docFieldBirthdayClasses"
                          v-model.trim="v$.person.passport_number.$model"
                        />
                        <template v-else>
                          <CFormInput
                            id="personPassportSeries"
                            type="text"
                            size="sm"
                            :invalid="v$.person.passport_number.$error"
                            v-model.trim="v$.person.passport_number.$model"
                            placeholder=""
                            v-maska
                            data-maska="#-#"
                          />
                        </template>
                        <CFormFeedback
                          v-if="v$.person.passport_number.$invalid"
                          invalid
                        >
                          {{
                            v$.person.passport_number.$silentErrors[0].$message
                          }}
                        </CFormFeedback>
                      </div>
                      <div class="mb-3">
                        <CFormLabel for="personPassportDate">
                          Дата выдачи документа
                        </CFormLabel>
                        <Datepicker
                          :model-value="passportDate"
                          @update:model-value="onPassportDate"
                          label=""
                          placeholder=""
                          locale="ru-RU"
                          format="dd.MM.yyyy"
                          model-type="dd.MM.yyyy"
                          :enable-time-picker="false"
                          size="sm"
                          :range="false"
                          :multi-calendars="false"
                          :max-date="new Date()"
                          :week-start="1"
                          cancel-text="Отменить"
                          select-text="Выбрать"
                        />
                      </div>
                      <div class="mb-3">
                        <CFormLabel for="whoIssuedDoc">
                          Кем выдан документ
                        </CFormLabel>
                        <CFormInput
                          v-model.trim="person.passport_issued_by"
                          id="whoIssuedDoc"
                          type="text"
                          size="sm"
                        />
                      </div>
                    </CCol>
                    <CCol xs="12" md="7">
                      <div>
                        <CFormLabel for="personRegistrationAddress">
                          Адрес регистрации
                        </CFormLabel>
                        <CFormTextarea
                          id="personRegistrationAddress"
                          type="text"
                          rows="7"
                          :invalid="v$.person.registration_address.$error"
                          v-model.trim="v$.person.registration_address.$model"
                        />
                        <CFormFeedback
                          v-if="v$.person.registration_address.$invalid"
                          invalid
                        >
                          {{
                            v$.person.registration_address.$silentErrors[0]
                              .$message
                          }}
                        </CFormFeedback>
                      </div>
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </div>
          <div class="mb-3">
            <ObjectComments
              v-if="commentsObj"
              :obj="commentsObj"
              el-id="person-comments-wrap"
              title="Комментарии"
              :show-button="true"
              @update-data="(data) => emit('comments-obj-update', data)"
            />
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onCancel">Отмена</CButton>
        <CLoadingButton color="success" :loading="isLoading" @click="onOk">
          {{ personId ? 'Обновить' : 'Создать' }}
        </CLoadingButton>
      </CModalFooter>
      <ThePodval
        title="Окно просмотра / редактирования персоны"
        :tasks="['https://tracker.yandex.ru/BACK-3057']"
        :wiki="[]"
        :uuid="WH"
      />
    </CModal>
  </div>
</template>

<script setup>
import {
  computed,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue'
import useVuelidate from '@vuelidate/core'
import {
  allowSymbolsAddress,
  birthDocNum,
  email,
  labelNum,
  maxLength,
  maxValue,
  minValue,
  names,
  passportRu,
  required,
} from '@/helpers/vValidators'
import C from '@/config/back_const'
import Autocomplete from '@/components/custom/Autocomplete'
import PhonesEdit from '@/components/custom/PhonesEdit/PhonesEdit'
import ContactsEdit from '@/components/_custom/contacts-edit/ContactsEdit.vue'
import ObjectComments from '@/components/custom/ObjectComments'
import { helpers } from '@vuelidate/validators'
import { vMaska } from 'maska'
import moment from 'moment'
import ModalMinimize from '@/components/_common/ModalMinimize.vue'
import ThePodval from '@/components/_shared/ThePodval.vue'

const props = defineProps({
  personId: {
    type: [Number, String, Boolean],
    default: () => false,
  },
  windowHandler: {
    type: String,
    default: () => null,
  },
  ownerId: {
    type: [Number, String, Boolean],
    required: true,
  },
  initDataOwnerType: {
    type: String,
    required: true,
    default: '',
  },
  initData: {
    type: Object,
    default: () => {},
  },
  editTitle: {
    type: String,
    required: false,
    default: () => '',
  },
  haveContacts: {
    type: [Boolean],
    default: true,
  },
  personExternal: {
    type: [Object],
    default: () => null,
  },
  commentsObj: {
    type: Object,
    default: () => {},
  },
  err: {
    type: [Object, String],
    default: null,
  },
})
const emit = defineEmits(['close', 'cancel', 'update', 'comments-obj-update'])

const WH = 'd899d59c-8e4c-4a90-9f4b-42ca8e3ae873'

const sAreaCity = [
  {
    content_type: 'refbook__location',
    filters: [{ name: 'addr_type', value: C.LOCATION_ADDR_TYPE_CITY }],
  },
]

const sAreaCountry = [
  {
    content_type: 'refbook__location',
    filters: [
      {
        name: 'addr_type',
        value: C.LOCATION_ADDR_TYPE_COUNTRY,
      },
    ],
  },
]

const {
  personApi,
  refbookApi,
  personService,
  storage,
  dateService,
  phoneApi,
  contactApi,
  auth,
  accountApi,
  locationApi,
} = inject('services')

const optionsMaskBirthday = reactive({
  mask: '011113443222222',
  tokens: {
    0: { pattern: /[IVXLX]/, transform: (chr) => chr.toUpperCase() },
    1: {
      pattern: /[IVXLX]/,
      transform: (chr) => chr.toUpperCase(),
      optional: true,
    },
    2: { pattern: /[0-9]/ },
    3: { pattern: /[\s-]/ },
    4: { pattern: /[А-ЯABCEHKMOPTXY]/, transform: (chr) => chr.toUpperCase() },
  },
  eager: true,
})

const modalContainer = ref(null)
const surnameIsRequired = ref(false)
const modalTitle = computed(() =>
  props.personId
    ? props.editTitle || 'Редактирование персоны'
    : 'Создание персоны',
)
const isVisible = ref(false)
const isLoading = ref(false)
const docAccordionHeaderRef = ref(null)
const person = ref({})
const socialGroups = ref([])
const errors = ref(null)
const initialPhones = ref(null)
const initialContacts = ref(null)
const externalResults = ref({
  person: {},
})
const isDocFieldBirthday = computed(() => {
  return person.value.document_type === C.PERSON_PASSPORT_TYPE_BIRTH
})
const docFieldBirthdayClasses = computed(() => {
  return [
    'form-control form-control-sm',
    v$.value.person.passport_number.$error ? 'is-invalid' : '',
  ]
})
const getInitialPhones = async () => {
  let phones = []
  const ownerTypeObject = props.initData?.[props.initDataOwnerType]
  if (ownerTypeObject?.phones) {
    const ownerTypeDataType = ownerTypeObject.data_type
    const ownerTypeId = ownerTypeObject.id
    const res = await phoneApi.phoneForObjectList(
      WH,
      ownerTypeDataType,
      ownerTypeId,
    )
    phones = res.data
  }
  initialPhones.value = phones
}
const getInitialContacts = async () => {
  let contacts = []
  const ownerTypeObject = props.initData?.[props.initDataOwnerType]
  if (ownerTypeObject?.contacts) {
    const ownerTypeDataType = ownerTypeObject.data_type
    const ownerTypeId = ownerTypeObject.id
    const res = await contactApi.contactList(WH, ownerTypeId, ownerTypeDataType)
    contacts = res?.data || []
  }
  initialContacts.value = contacts
}

const newPersonPhones = ref(null)
const newPersonContacts = ref(null)
const onPhonesComplete = (data) => {
  if (!props.personId) {
    newPersonPhones.value = data
  }
}
const onContactsComplete = (data) => {
  if (!props.personId) {
    newPersonContacts.value = data
    // если в форме создания исправлен основной email то обновляем его в объекте персоны
    // иначе в персоне останется старый email
    const email = data?.find((contact) => contact.type === C.CONTACT_TYPE_EMAIL)
    if (email) person.value.email = email.number
  }
}

const validationRules = computed(() => {
  const surnameRules = { names, max: maxLength(C.PERSON_NAME_LENGTH) }
  if (surnameIsRequired.value) {
    surnameRules.required = required
  }

  const rules = {
    person: {
      name: { required, names, max: maxLength(C.PERSON_NAME_LENGTH) },
      surname: surnameRules,
      patronymic: { names, max: maxLength(C.PERSON_NAME_LENGTH) },
      age: {
        minValue: minValue(0),
        maxValue: maxValue(99),
      },
      registration_address: {
        allowSymbolsAddress: helpers.withMessage(
          'Адрес содержит символы, отличающиеся от набора',
          allowSymbolsAddress,
        ),
        maxLength: maxLength(C.ADDRESS_LENGTH),
      },
      passport_number: {},
      email: { email },
    },
  }
  if (person.value.document_type === C.PERSON_PASSPORT_TYPE_OTHER) {
    rules.person.passport_number = {
      labelNum,
      maxLength: maxLength(C.DOCUMENT_NUMBER_LENGTH),
    }
  }
  if (person.value.document_type === C.PERSON_PASSPORT_TYPE_PASSPORT) {
    rules.person.passport_number = {
      passportRu,
    }
  }
  if (person.value.document_type === C.PERSON_PASSPORT_TYPE_BIRTH) {
    rules.person.passport_number = {
      birthDocNum,
    }
  }
  return rules
})
const v$ = useVuelidate(
  validationRules,
  { person },
  { $externalResults: externalResults, $lazy: true },
)

const onBirthday = (val) => {
  if (val) {
    person.value.age = dateService.getAgeByBirthday(new Date(val))
  } else {
    person.value.age = null
  }
}

const passportDate = ref(null)
const onPassportDate = (val) => {
  const [day, month, year] = val.split('.')
  person.value.passport_date = `${year}-${month}-${day}`

  passportDate.value = val
}

const country = ref(null)
watch(country, (country) => {
  person.value.country = country?.id || null
})

const cityOfResident = ref(null)
watch(cityOfResident, (cityOfResident) => {
  person.value.city_of_resident = cityOfResident?.id ? cityOfResident.id : null
})

const onClose = () => {
  isVisible.value = false
  emit('close')
}
const onCancel = async () => {
  if (!props.personId) {
    // если это не правка, а создание нового - удалить identity, customer
    emit('cancel')
  }
  onClose()
}

const openDocAccordionItem = () => {
  const el = docAccordionHeaderRef.value?.$el?.firstChild
  if (el && el.classList.contains('collapsed')) el.click()
}

const changeCity = async (val) => {
  try {
    if (val?.id) {
      const countryNew = await locationApi.locationCountryOfRetrieve(
        WH,
        val?.id,
      )

      country.value = { ...countryNew, data_type: 'refbook__location' }
    }
  } catch (err) {
    console.error(err)
  }
}

const onOk = async () => {
  await v$.value.$validate()

  if (v$.value.$error) {
    const hasDocNumErr = v$.value.$errors?.some(
      ({ $property: prop }) => prop === 'passport_number',
    )
    if (hasDocNumErr) openDocAccordionItem()
    console.error('Validation error: ', v$.value.$errors)
    return
  }

  isLoading.value = true
  try {
    const params = dataPersonForRequest(person)
    const response = props.personId
      ? await personApi.personUpdate(
          props.windowHandler || WH,
          props.personId,
          params,
        )
      : await personApi.personCreate(props.windowHandler || WH, params)

    // создаем телефоны (если заполнены) для только что созданной персоны
    if (!props.personId && newPersonPhones.value) {
      for (const phone of newPersonPhones.value) {
        const { number, info } = phone
        await phoneApi.phoneForObjectCreate(WH, 'core__person', response.id, {
          number,
          info,
        })
      }
    }
    // создаем контакты (если заполнены) для только что созданной персоны
    if (!props.personId && newPersonContacts.value) {
      for (const contact of newPersonContacts.value) {
        const { type, number, info } = contact
        await contactApi.contactCreate(WH, response.id, 'core__person', {
          type,
          number,
          info,
        })
      }
    }

    emit('update', {
      message: props.personId ? 'Персона обновлена' : 'Персона создана',
      person: response,
    })
    onClose()
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const onAge = (val) => {
  person.value.birthday = moment()
    .add(val * -1, 'years')
    .format('YYYY-MM-DD')
}

const dataPersonForRequest = (personRef) => {
  const data = { ...personRef.value }
  if (data?.passport_number) {
    if (data.document_type === C.PERSON_PASSPORT_TYPE_PASSPORT) {
      data.passport_number = data.passport_number.replaceAll(' ', '')
    }
    if (data.document_type === C.PERSON_PASSPORT_TYPE_BIRTH) {
      data.passport_number = formedBirthDocNum(data.passport_number)
    }
  }
  return data
}

const formedBirthDocNum = (number) => {
  // переводим латинские буквы на русские у средней части номера с ру символами где возможна латиница
  const latinLettersRegex = /[ABCEHKMOPTXY]/g
  const russianLetters = {
    A: 'А',
    B: 'В',
    C: 'С',
    E: 'Е',
    H: 'Н',
    K: 'К',
    M: 'М',
    O: 'О',
    P: 'Р',
    T: 'Т',
    X: 'Х',
    Y: 'У',
  }
  const textRuOriginal = number.slice(-9, -7)
  const textRuReplacedEng = textRuOriginal.replace(
    latinLettersRegex,
    (match) => russianLetters[match],
  )
  return number.replace(
    new RegExp(`${textRuOriginal}\\w*`, 'ig'),
    textRuReplacedEng,
  )
}

const changeSocialGroup = (event) => {
  const selectedSocialGroups = []
  event.forEach((item) => {
    selectedSocialGroups.push(item.value)
  })
  person.value.social_groups = selectedSocialGroups
}

onMounted(async () => {
  isVisible.value = true
  if (props.personExternal) {
    person.value = props.personExternal
  } else {
    person.value = props.personId
      ? await personApi.personRetrieve(WH, props.personId)
      : personService.getEmptyPerson({
          owner: props.ownerId,
          data: props.initData,
        })
  }

  if (person.value) {
    socialGroups.value = await refbookApi.socialGroupList(WH)
    socialGroups.value = socialGroups.value.data.map((item) => {
      return {
        value: item.id,
        text: item.title,
        selected: person.value?.social_groups?.includes(item.id),
      }
    })

    person.value.document_type = person.value?.document_type || ''

    passportDate.value = person.value.passport_date
      ? dateService.formatDate(person.value.passport_date)
      : null

    country.value = person.value.country
      ? { data_type: 'refbook__location', id: person.value.country }
      : null

    cityOfResident.value = person.value.city_of_resident
      ? { data_type: 'refbook__location', id: person.value.city_of_resident }
      : null

    const myCompany = await auth.getCompany()
    const settings = await accountApi.companySettingRetrieve(WH, myCompany.id)
    surnameIsRequired.value =
      settings?.common?.person_surname_is_required?.value
  }

  await getInitialPhones()
  await getInitialContacts()
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
