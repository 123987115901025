// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class HmsApiMethodsCls {
    async flagDelete(wh, id) {
      // Удаление RoomFlag
      console.log('flagDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.hms.flag.delete(id)})
      ctx.$services.storage.del('hms__roomflag', id, wh)
      return null
    }
    async guestUpdate(wh, id, data, extra_data = null) {
      // data: Guest
      // Обновление Guest
      console.log('guestUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.guest.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async guestDelete(wh, id) {
      // Удаление Guest
      console.log('guestDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.hms.guest.delete(id)})
      ctx.$services.storage.del('core__guest', id, wh)
      return null
    }
    async hotelCrossAvailabilityRetrieve(wh, id, extra_data = null) {
      // Данные для шахматки по доступности для текущего периода (не реализовано)
      // Пока не реализовано
      console.log('hotelCrossAvailabilityRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.availability.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelCrossAvailabilityUpdate(wh, id, data, extra_data = null) {
      // data: CrossRequest
      // Данные для шахматки по доступности для заданного периода (не реализовано)
      // Пока не реализовано
      console.log('hotelCrossAvailabilityUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.availability.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelCrossPricesRetrieve(wh, id, extra_data = null) {
      // Данные для шахматки по ценам для текущего периода (не реализовано)
      // Пока не реализовано
      console.log('hotelCrossPricesRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.prices.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelCrossPricesUpdate(wh, id, data, extra_data = null) {
      // data: CrossRequest
      // Данные для шахматки по ценам для заданного периода (не реализовано)
      // Пока не реализовано
      console.log('hotelCrossPricesUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.prices.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelCrossRoomsRetrieve(wh, id, extra_data = null) {
      // Данные для шахматки по номерам для текущего периода
      console.log('hotelCrossRoomsRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.rooms.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelCrossRoomsUpdate(wh, id, data, extra_data = null) {
      // data: CrossRequest
      // Данные для шахматки по номерам для заданного периода
      // Фильтр по номерам пока не реализован
      console.log('hotelCrossRoomsUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.rooms.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reservationAddGuestPost(wh, id, data, extra_data = null) {
      // data: GuestCreate
      // Создание Guest для reservation
      console.log('reservationAddGuestPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.addGuest.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelPotentialResponsibleList(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Список потенциальных ответственных
      // filters: created_at, updated_at, company, user, person, role, division, handle,
      //          exact_position_name, is_active, is_deleted, is_managed, is_supervisor, supervisor,
      //          agent, notifications_contact, pk, fio, phone, user_email
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('hotelPotentialResponsibleList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.potentialResponsible.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.hmsApi = new HmsApiMethodsCls()
}

