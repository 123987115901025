import router from '@/router'
import { withActiveWin } from '@/helpers/winTracker.js'
import constants from '@/config/constants'
import { getWSMessage } from '@/helpers/index'
import { wsClientLoggerInit } from '@/helpers/clientLogger'

const messageType = {
  command(message) {
    withActiveWin(
      () => {
        const { items = [] } = message.data
        for (const item of items) {
          if (item.data_type === 'core__lead' && item.object_id) {
            router.push({ path: `/lead/${item.object_id}/` })
            break
          }
          if (item.data_type === 'core__tour' && item.object_id) {
            router.push({ path: `/tour/${item.object_id}/` })
            break
          }
        }
        if (items.length) {
          // отправляем message в content-script.js crome-extension для активации этого окна
          const type = constants.postMessages.CLIENT_ACTIVATE_WIN
          window.postMessage({ type, text: JSON.stringify({}) }, '*')
        }
      },
      { titleMarker: '🔶' },
    )
  },
  /**
   * BACK-4168: Отображение быстрых уведомлений
   * @param {{type: message, data: {level: string, content_type: string, message: string}}} message
   * @param {{}} services
   */
  message(message, { services }) {
    const { notification } = services
    const { level, message: messageContent } = message.data

    if (level === 'success') {
      notification.success(messageContent)
    } else {
      notification.error(messageContent)
    }
  },
}

const wsClientLogger = wsClientLoggerInit()

// todo для улучшения производительности можно передавать сюда уже распарсенное message из websocketService
// например использовать pub sub pattern везде для получения распарсенного message
export function setGlobalHandleWS(websocket, params = {}) {
  websocket.connection.addEventListener('message', (ev) => {
    const message = getWSMessage(ev)
    if (!message) return
    messageType[message.type]?.(message, params)
    wsClientLogger(message, params)
  })
}
