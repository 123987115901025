// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class LocalphoneApiMethodsCls {
    async localPhoneList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка внутренних телефонов пользователей
      // filters: identity, number, employee
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('localPhoneList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.localPhone.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async localPhoneCreate(wh, data, extra_data = null) {
      // data: LocalPhoneCreate
      // Создание внутреннего телефона пользователя
      console.log('localPhoneCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.localPhone.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async localPhoneRetrieve(wh, id, extra_data = null) {
      // Получение внутреннего телефона пользователя
      console.log('localPhoneRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('telephony__localphone', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'telephony__localphone', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.localPhone.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async localPhoneUpdate(wh, id, data, extra_data = null) {
      // data: LocalPhoneUpdate
      // Обновление внутреннего телефона пользователя
      console.log('localPhoneUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.localPhone.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async localPhoneDelete(wh, id) {
      // Удаление внутреннего телефона пользователя
      console.log('localPhoneDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.telephony.localPhone.delete(id)})
      ctx.$services.storage.del('telephony__localphone', id, wh)
      return null
    }
  }

  ctx.$services.localPhoneApi = new LocalphoneApiMethodsCls()
}

