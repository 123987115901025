// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class HotelApiMethodsCls {
    async oldToNewHotelRetrieve(wh, id, extra_data = null) {
      // Получение отеля по старому id
      console.log('oldToNewHotelRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__hotel', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__hotel', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.nsbOld.oldToNewHotel.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Список отелей
      // filters: id, title, description, is_active, title_with_city, company, mptt_parent,
      //          location_name, display
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('hotelList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async hotelCreate(wh, data, extra_data = null) {
      // data: HotelCreate
      // Создание отеля
      console.log('hotelCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelFindPut(wh, data, extra_data = null) {
      // data: HotelFind
      // Поиск отеля по региону, заболеваниям и услугам
      console.log('hotelFindPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.find.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelRetrieve(wh, id, extra_data = null) {
      // Получение отеля по id
      console.log('hotelRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__hotel', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__hotel', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelUpdate(wh, id, data, extra_data = null) {
      // data: HotelCreate
      // Изменение отеля
      console.log('hotelUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelDelete(wh, id) {
      // Удаление отеля (не использовать, будет удалено)
      console.log('hotelDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.refbook.hotel.delete(id)})
      ctx.$services.storage.del('refbook__hotel', id, wh)
      return null
    }
    async hotelActivatePartial(wh, id, data, extra_data = null) {
      // data: HotelActivate
      // Изменение поля is_active для отеля
      console.log('hotelActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelAttributeRetrieve(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка атрибутов отеля
      // filters: created_at, updated_at, attribute, value
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('hotelAttributeRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__hotel', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__hotel', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.attribute.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async hotelAttributePost(wh, id, data, extra_data = null) {
      // data: HotelAttributeValueCreate
      // Создание атрибута отеля
      console.log('hotelAttributePost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.attribute.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelDeepRetrieve(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получить модель отеля с вложенными сущностями
      // filters: id, title, description, is_active, title_with_city, company, mptt_parent,
      //          location_name, display
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('hotelDeepRetrieve')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.deep.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.hotelApi = new HotelApiMethodsCls()
}

