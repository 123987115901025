<template>
  <PopupPersonCreateEdit
    v-if="isCreateMode"
    :person-id="popupData?.personId"
    :init-data="popupData?.initData"
    :owner-id="popupData?.ownerId"
    :init-data-owner-type="popupData?.initDataOwnerType"
    :edit-title="popupData?.editTitle"
    :comments-obj="popupData?.commentsObj"
    @comments-obj-update="popupData?.commentsObjUpdate"
    @update="popupData.update"
    @close="popupData.close"
    @cancel="popupData.cancel"
  />
  <template v-else>
    <PopupPersonView
      v-if="isViewMode"
      :err="err"
      :person-id="popupData.personId"
      :person-external="person"
      @close="popupData.close"
      :comments-obj="popupData?.commentsObj"
      @comments-obj-update="popupData?.commentsObjUpdate"
    />
    <PopupPersonCreateEdit
      v-if="isEditMode"
      :err="err"
      :person-id="popupData?.personId"
      :person-external="person"
      :init-data="popupData?.initData"
      :owner-id="popupData?.ownerId"
      :init-data-owner-type="popupData?.initDataOwnerType"
      :edit-title="popupData?.editTitle"
      :comments-obj="popupData?.commentsObj"
      @comments-obj-update="popupData?.commentsObjUpdate"
      @update="popupData.update"
      @close="popupData.close"
      @cancel="popupData.cancel"
    />
  </template>
</template>

<script setup>
// TODO refactor переделать всё как в booking
import { computed, onMounted, inject, ref } from 'vue'
import PopupPersonView from '@/components/popups/PopupPerson/PopupPersonView.vue'
import PopupPersonCreateEdit from '@/components/popups/PopupPerson/PopupPersonCreateEdit.vue'
import { usePermissions } from '@/composables/permissions'
import { useRequest } from '@/composables/request'

const props = defineProps({
  data: {
    type: Object,
    default: () => null,
  },
  forceMode: {
    // [view, change, contacts] - как в view_permissions
    type: Array,
    default: null,
  },
})

const { personApi } = inject('services')

const WH = '4033a2c2-8def-11ee-b9d1-0242ac120002'

const { isEditMode, isViewMode, updPermissions } = usePermissions()
const { wrapRequest } = useRequest()

const err = ref(null)
const person = ref(null)

const popupData = computed(() => {
  return props.data?.data
})

const isCreateMode = computed(() => {
  return popupData.value?.personId == null
})

const init = async () => {
  const result = await personApi.personRetrieve(WH, popupData.value?.personId)
  const permObj = props.forceMode
    ? { view_permissions: props.forceMode }
    : result
  updPermissions(permObj)
  person.value = result
}

onMounted(async () => {
  if (isCreateMode.value || !popupData.value?.personId) return
  const { error } = await wrapRequest(init)
  if (error) {
    err.value = error
  }
})
</script>
