<template>
  <div class="minimizable" ref="modalContainer">
    <CModal
      ref="modal"
      :visible="visible"
      scrollable
      size="lg"
      fullscreen="sm"
      backdrop="static"
      alignment="center"
      @close="onClose"
    >
      <ModalMinimize v-if="modalContainer" :modal-container="modalContainer" />

      <CModalHeader dismiss>
        <CModalTitle :id="`id-title-${WH}`">Загрузка файлов</CModalTitle>
      </CModalHeader>
      <CModalBody :id="`id-${WH}`" class="position-relative popup-min-h">
        <UploadFiles
          :object-type="objectType"
          :fixed-title="fixedTitle"
          :file-type="fileType"
          :file-type-select="fileTypeSelect"
          :object-id="objectId"
          :link-to="linkTo"
          :add-to="addTo"
          :modal-wh="WH"
          @upload-complete="onUploadComplete"
        />
      </CModalBody>
      <CModalFooter :id="`id-footer-${WH}`">
        <div
          class="flex flex-wrap gap-3 justify-content-end"
          :id="`id-buttons-${WH}`"
        >
          <div>
            <CButton color="secondary" @click="onClose">Закрыть</CButton>
          </div>
        </div>
      </CModalFooter>
      <div :id="`id-dev-${WH}`"></div>
    </CModal>
  </div>
</template>
<script setup>
import { inject, onMounted, onUnmounted, ref } from 'vue'
import ModalMinimize from '@/components/_common/ModalMinimize.vue'
import UploadFiles from '@/components/_custom/upload-files/UploadFiles.vue'

defineProps({
  objectId: {
    type: [Number, String],
    required: true,
  },
  objectType: {
    type: String,
    required: true,
  },
  // image, file
  fileType: {
    type: String,
    default: 'file',
  },
  fileTypeSelect: {
    type: Boolean,
    default: true,
  },
  /** @exmple
   * // дополнительные объекты к которым также необходимо загрузить эти файлы отдельно
   * [
   *     { data_type: 'core__booking', id: props.data?.id },
   *     { data_type: 'core__tour', id: tourId.value },
   *   ]
   */
  addTo: {
    type: Array,
    default: () => [],
  },
  /** @exmple
   * // дополнительные объекты к которым необходимо привязать (прилинковать) загружаемые файлы
   * [
   *     { data_type: 'core__booking', id: props.data?.id },
   *     { data_type: 'core__tour', id: tourId.value },
   *   ]
   */
  linkTo: {
    type: Array,
    default: () => [],
  },
  // TEMPORARY если временный файл для привязки к личности
  fixedTitle: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits(['close', 'upload-complete'])

const WH = 'b79fcc01-fbce-47c4-b1e9-c14cc6e439b8'
const { storage } = inject('services')

const modalContainer = ref(null)
const visible = ref(false)

const onClose = () => {
  visible.value = false
  emit('close')
}
const onUploadComplete = (payload) => {
  emit('upload-complete', payload)
}

onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
