// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class UserApiMethodsCls {
    async userList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка User
      // filters: phone, name, surname, patronymic, birthday, location_id, is_superuser, is_active,
      //          is_staff, is_demo, fio
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('userList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async userCreate(wh, data, extra_data = null) {
      // data: UserCreate
      // Создание User
      console.log('userCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async userPasswordCreate(wh, data, extra_data = null) {
      // data: UserChangePassword
      // Изменение пароля пользователем
      console.log('userPasswordCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.password.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async userRetrieve(wh, id, extra_data = null) {
      // Получение User
      console.log('userRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('account__user', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'account__user', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async userUpdate(wh, id, data, extra_data = null) {
      // data: UserUpdate
      // Обновление модели User
      console.log('userUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async userDelete(wh, id) {
      // Удаление User
      console.log('userDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.account.user.delete(id)})
      ctx.$services.storage.del('account__user', id, wh)
      return null
    }
    async userActivatePartial(wh, id, data, extra_data = null) {
      // data: UserActivate
      // Изменение поля is_active для User
      console.log('userActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async userPasswordPartial(wh, id, data, extra_data = null) {
      // data: UserChangePassword
      // Изменение пароля пользователя, руководителем компании, если пользователь is_managed
      console.log('userPasswordPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.password.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.userApi = new UserApiMethodsCls()
}

