<template>
  <div class="mt-3 no-mt-div">
    <div class="mt-1 no-mt-div">
      <b v-if="title" class="mb-1">{{ title }}</b>
      <div
        v-if="elId && commentsPrepared?.length"
        :id="elId"
        class="overflow-scroll p-0 bg-body"
        :style="maxHeight ? `max-height: ${maxHeight}` : ''"
      >
        <div v-for="comment in commentsPrepared" :key="comment.id">
          <div class="flex gap-3 text-black-50 pt-1">
            <small> {{ comment.author_name }}</small>
            <small v-html="dateService.formatDateTime(comment.created_at)" />
          </div>
          <small
            class="d-block fst-italic"
            :class="{
              'text-decoration-line-through text-secondary': !comment.is_active,
            }"
            v-html="preprocessObjectsInText(comment.text)"
          />
        </div>
      </div>
    </div>
    <CButton
      v-if="showButton"
      class="mt-3 no-mt-div"
      color="primary"
      @click="openComments"
      :variant="variantBtn"
      size="sm"
    >
      {{ buttonName }}
    </CButton>

    <PopupComments
      v-if="idComments"
      :data-id="idComments"
      :data-type="obj.data_type"
      @closed="onCloseComments"
    />

    <Teleport v-if="ready && addSepButton" :to="addSepButton">
      <CButton
        color="primary"
        variant="outline"
        size="sm"
        @click="openComments"
        >{{ sepButtonName }}</CButton
      >
    </Teleport>
  </div>
</template>

<script setup>
import { inject, ref, onMounted, onUnmounted, nextTick, computed } from 'vue'
import { preprocessObjectsInText } from '@/helpers/preprocessObjectsInText'
import PopupComments from '@/components/_comments/PopupComments.vue'

const props = defineProps({
  //obj = {id Integer, comments: [], data_type String}
  obj: {
    type: Object,
    required: true,
  },
  elId: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: null,
  },
  showButton: {
    type: Boolean,
    required: false,
    default: true,
  },
  buttonName: {
    type: String,
    required: false,
    default: 'Комментарии',
  },
  maxHeight: {
    // ограниечние по высоте
    type: String,
    required: false,
    default: undefined,
  },
  maxCount: {
    // ограничение по количеству комментариев
    type: Number,
    required: false,
    default: undefined,
  },
  variantBtn: {
    type: String,
    default: '',
  },
  // selector
  addSepButton: {
    type: String,
    required: false,
    default: undefined,
  },
  sepButtonName: {
    type: String,
    required: false,
    default: 'Комментарии',
  },
})
const emit = defineEmits('update-data', 'prepared')

const WH = '6b774e42-406d-4578-8510-306cb60aeb37'
const { storage, dateService, commentApi } = inject('services')

const ready = ref(false)
const commentsPrepared = ref([])
const idComments = ref(null)

// если в объекте есть comments и это объекты комментариев (устаревший вариант)
// в новом варианте апи может присылать массив id комментариев. Но его использовать нет смысла, поэтому
// сейчас просто получаем комментарии по object_type, object_id если не устаревший вариант
// TODO optimisation
// НО для оптимизации если комментарии добавят в рел. дата можно будет формировать массив с объектами как в устаревшем варианте и использовать его.
const hasCommentsList = computed(
  () =>
    Array.isArray(props.obj?.comments) &&
    props.obj.comments.length &&
    props.obj.comments[0]?.id,
)

const getObjectComments = async () => {
  try {
    storage.clr(WH)
    const response = await commentApi.commentListForObjectList(
      WH,
      props.obj.id,
      props.obj.data_type,
      {
        sort: ['-created_at'],
        offset: 0,
        limit: 10,
      },
    )
    return response.data
  } catch (err) {
    console.error(err)
  }
}
const prepareComments = (comments) => {
  return (
    comments
      .filter((comment) => {
        if (!comment?.text) return false
        // заменить переносы строк, а если без данных, то заменить всё пустой строкой
        comment.text =
          comment.text !== 'Что было изменено в лиде:\n'
            ? comment.text.replace(/\n/g, `<br>`)
            : ''
        return !!comment.text
      })
      // .sort((c1, c2) => {
      //   const id1 = Number(c1.id)
      //   const id2 = Number(c2.id)
      //   return id1 > id2 ? -1 : id1 < id2 ? 1 : 0
      // })
      .slice(0, props.maxCount ?? comments.length)
  )
}

const onCloseComments = async () => {
  idComments.value = null
  const comments = await getObjectComments()
  if (!comments) return
  if (hasCommentsList.value) {
    emit('update-data', comments)
  } else {
    commentsPrepared.value = prepareComments(comments)
    emit('prepared')
  }
}
const openComments = () => {
  idComments.value = props.obj.id
}

const init = async () => {
  const comments = hasCommentsList.value
    ? props.obj.comments
    : await getObjectComments()
  commentsPrepared.value = prepareComments(comments)
  emit('prepared')
}

onMounted(async () => {
  await init()
  await nextTick(() => {
    ready.value = true
  })
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
