const FRONT_PAGE = {
  component: 'CNavItem',
  name: 'Главная',
  to: '/dashboard',
  icon: 'cil-home',
}

const DEMO = {
  component: 'CNavItem',
  name: 'Документация',
  items: [
    {
      component: 'CNavItem',
      name: 'Демо',
      to: '/demo/',
    },
    {
      component: 'CNavItem',
      name: 'Требования к коду',
      to: '/guides/requrements',
    },
    {
      component: 'CNavItem',
      name: 'Шаблон страницы',
      to: '/guides/page',
    },
    {
      component: 'CNavItem',
      name: 'Компоненты',
      items: [
        {
          component: 'CNavItem',
          name: 'AppIcon',
          to: '/components/app-icon',
        },
        {
          component: 'CNavItem',
          name: 'Кастомные стили',
          to: '/guides/css',
        },
        {
          component: 'CNavItem',
          name: 'Пример попапов',
          to: '/guides/popup',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: 'Проблемы компонентов',
      items: [
        {
          component: 'CNavItem',
          name: 'CFormSelect',
          to: '/components/components-problems/select',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: 'Свои компоненты',
      items: [
        {
          component: 'CNavItem',
          name: 'Загрузка файлов',
          to: '/components/file_upload',
        },
        {
          component: 'CNavItem',
          name: 'TelInput',
          to: '/components/tel-input#examples',
        },
        {
          component: 'CNavItem',
          name: 'Phone input',
          to: '/components/phone-input',
        },
        {
          component: 'CNavItem',
          name: 'Autocomplete Demo',
          to: '/components/autocomplete',
        },
        {
          component: 'CNavItem',
          name: 'DatesRange',
          to: '/components/dates-range',
        },
        {
          component: 'CNavItem',
          name: 'ServerErrors',
          to: '/components/server-errors',
        },
        {
          component: 'CNavItem',
          name: 'CopRubConvert',
          to: '/components/cop-rub-convert',
        },
        {
          component: 'CNavItem',
          name: 'PhonesEdit',
          to: '/components/phones-edit',
        },
        {
          component: 'CNavItem',
          name: 'ContactsEdit',
          to: '/components/contacts-edit',
        },
        {
          component: 'CNavItem',
          name: 'PhonesList',
          to: '/components/phones-list',
        },
        {
          component: 'CNavItem',
          name: 'ContactsList',
          to: '/components/contacts-list',
        },
        {
          component: 'CNavItem',
          name: 'CommentsList',
          to: '/components/comments-list',
        },
        {
          component: 'CNavItem',
          name: 'CollapseData',
          to: '/components/collapse-data',
        },
        {
          component: 'CNavItem',
          name: 'ProductsTable',
          to: '/components/products-table',
        },
        {
          component: 'CNavItem',
          name: 'ListMovingObjects',
          to: '/components/list-moving-objects',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: 'Руководства',
      items: [
        {
          component: 'CNavItem',
          name: 'Валидация',
          to: '/guides/validation',
        },
        {
          component: 'CNavItem',
          name: 'Валидация форм',
          to: '/guides/form-validation',
        },
        {
          component: 'CNavItem',
          name: 'Demo Table',
          to: '/guides/table',
        },
        {
          component: 'CNavItem',
          name: 'Верстка',
          to: '/guides/common',
        },
        {
          component: 'CNavItem',
          name: 'Demo Form',
          to: '/guides/form',
        },
        {
          component: 'CNavItem',
          name: 'Demo Multiform',
          to: '/guides/multiform',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: 'Демо попапов',
      items: [
        {
          component: 'CNavItem',
          name: 'PopupPriceModelDemo',
          to: '/components/popup-price-model-demo',
        },
        {
          component: 'CNavItem',
          name: 'PopupHotelInfo',
          to: '/components/popup-hotel-info',
        },
        {
          component: 'CNavItem',
          name: 'PopupHotelPlacesInfo',
          to: '/components/popup-hotel-places-info',
        },
        {
          component: 'CNavItem',
          name: 'PopupHotelPricesInfo',
          to: '/components/popup-hotel-prices-info',
        },
        {
          component: 'CNavItem',
          name: 'Person',
          to: '/components/popup-person',
        },
        {
          component: 'CNavItem',
          name: 'Organization',
          to: '/components/popup-organization',
        },
        {
          component: 'CNavItem',
          name: 'Bookings',
          to: '/components/popup-booking',
        },
        {
          component: 'CNavItem',
          name: 'Comments',
          to: '/components/comments-popup',
        },
        {
          component: 'CNavItem',
          name: 'SelectOrCreateBuyer',
          to: '/components/buyer-popup',
        },
        {
          component: 'CNavItem',
          name: 'EditListContracts',
          to: '/components/edit_list_contracts',
        },
      ],
    },
  ],
  icon: 'cil-description',
}

const COREUI = {
  component: 'CNavItem',
  name: 'CORE UI',
  icon: 'cil-description',
  items: [
    {
      component: 'CNavTitle',
      name: 'Theme',
    },
    {
      component: 'CNavItem',
      name: 'Colors',
      to: '/theme/colors',
      icon: 'cil-drop',
    },
    {
      component: 'CNavItem',
      name: 'Typography',
      to: '/theme/typography',
      icon: 'cil-pencil',
    },
    {
      component: 'CNavTitle',
      name: 'Components',
    },
    {
      component: 'CNavGroup',
      name: 'Base',
      to: '/base',
      icon: 'cil-puzzle',
      items: [
        {
          component: 'CNavItem',
          name: 'Accordion',
          to: '/base/accordion',
        },
        {
          component: 'CNavItem',
          name: 'Breadcrumbs',
          to: '/base/breadcrumbs',
        },
        {
          component: 'CNavItem',
          name: 'Cards',
          to: '/base/cards',
        },
        {
          component: 'CNavItem',
          name: 'Carousels',
          to: '/base/carousels',
        },
        {
          component: 'CNavItem',
          name: 'Collapses',
          to: '/base/collapses',
        },
        {
          component: 'CNavItem',
          name: 'List Groups',
          to: '/base/list-groups',
        },
        {
          component: 'CNavItem',
          name: 'Navs & Tabs',
          to: '/base/navs',
        },
        {
          component: 'CNavItem',
          name: 'Paginations',
          to: '/base/paginations',
        },
        {
          component: 'CNavItem',
          name: 'Placeholders',
          to: '/base/placeholders',
        },
        {
          component: 'CNavItem',
          name: 'Popovers',
          to: '/base/popovers',
        },
        {
          component: 'CNavItem',
          name: 'Progress',
          to: '/base/progress',
        },
        {
          component: 'CNavItem',
          name: 'Spinners',
          to: '/base/spinners',
        },
        {
          component: 'CNavItem',
          name: 'Tables',
          to: '/base/tables',
        },
        {
          component: 'CNavItem',
          name: 'Tooltips',
          to: '/base/tooltips',
        },
      ],
    },
    {
      component: 'CNavGroup',
      name: 'Buttons',
      to: '/buttons',
      icon: 'cil-cursor',
      items: [
        {
          component: 'CNavItem',
          name: 'Buttons',
          to: '/buttons/standard-buttons',
        },
        {
          component: 'CNavItem',
          name: 'Button Groups',
          to: '/buttons/button-groups',
        },
        {
          component: 'CNavItem',
          name: 'Dropdowns',
          to: '/buttons/dropdowns',
        },
      ],
    },
    {
      component: 'CNavGroup',
      name: 'Forms',
      to: '/forms',
      icon: 'cil-notes',
      items: [
        {
          component: 'CNavItem',
          name: 'Form Control',
          to: '/forms/form-control',
        },
        {
          component: 'CNavItem',
          name: 'Select',
          to: '/forms/select',
        },
        {
          component: 'CNavItem',
          name: 'Multi Select',
          to: '/forms/multi-select',
          badge: {
            color: 'danger',
            text: 'PRO',
          },
        },
        {
          component: 'CNavItem',
          name: 'Checks & Radios',
          to: '/forms/checks-radios',
        },
        {
          component: 'CNavItem',
          name: 'Range',
          to: '/forms/range',
        },
        {
          component: 'CNavItem',
          name: 'Input Group',
          to: '/forms/input-group',
        },
        {
          component: 'CNavItem',
          name: 'Floating Labels',
          to: '/forms/floating-labels',
        },
        {
          component: 'CNavItem',
          name: 'Layout',
          to: '/forms/layout',
        },
        {
          component: 'CNavItem',
          name: 'Validation',
          to: '/forms/validation',
        },
      ],
    },
    {
      component: 'CNavGroup',
      name: 'Icons',
      to: '/icons',
      icon: 'cil-star',
      items: [
        {
          component: 'CNavItem',
          name: 'CoreUI Icons',
          to: '/icons/coreui-icons',
          badge: {
            color: 'info',
            text: 'NEW',
          },
        },
        {
          component: 'CNavItem',
          name: 'Brands',
          to: '/icons/brands',
        },
        {
          component: 'CNavItem',
          name: 'Flags',
          to: '/icons/flags',
        },
      ],
    },
    {
      component: 'CNavGroup',
      name: 'Notifications',
      to: '/notifications',
      icon: 'cil-bell',
      items: [
        {
          component: 'CNavItem',
          name: 'Alerts',
          to: '/notifications/alerts',
        },
        {
          component: 'CNavItem',
          name: 'Badges',
          to: '/notifications/badges',
        },
        {
          component: 'CNavItem',
          name: 'Modals',
          to: '/notifications/modals',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: 'Widgets',
      to: '/widgets',
      icon: 'cil-calculator',
      badge: {
        color: 'info',
        text: 'NEW',
      },
    },
    {
      component: 'CNavItem',
      name: 'Smart Table',
      to: '/smart-table',
      icon: 'cil-grid',
      badge: {
        color: 'danger',
        text: 'PRO',
      },
    },
    {
      component: 'CNavTitle',
      name: 'Plugins',
    },
    {
      component: 'CNavItem',
      name: 'Calendar',
      to: '/calendar',
      icon: 'cil-calendar',
      badge: {
        color: 'danger',
        text: 'PRO',
      },
    },
    {
      component: 'CNavItem',
      name: 'Charts',
      to: '/charts',
      icon: 'cil-chart-pie',
    },
    {
      component: 'CNavTitle',
      name: 'Extras',
    },
    {
      component: 'CNavGroup',
      name: 'Pages',
      to: '/pages',
      icon: 'cil-star',
      items: [
        {
          component: 'CNavItem',
          name: 'Login',
          to: '/pages/login',
        },
        {
          component: 'CNavItem',
          name: 'Register',
          to: '/pages/register',
        },
        {
          component: 'CNavItem',
          name: 'Error 404',
          to: '/pages/404',
        },
        {
          component: 'CNavItem',
          name: 'Error 500',
          to: '/pages/500',
        },
      ],
    },
    {
      component: 'CNavGroup',
      name: 'Apps',
      to: '/apps',
      icon: 'cil-layers',
      items: [
        {
          component: 'CNavGroup',
          name: 'Invoicing',
          to: '/apps/invoicing',
          icon: 'cil-spreadsheet',
          items: [
            {
              component: 'CNavItem',
              name: 'Invoice',
              to: '/apps/invoicing/invoice',
              badge: {
                color: 'danger',
                text: 'PRO',
              },
            },
          ],
        },
        {
          component: 'CNavGroup',
          name: 'Email',
          to: '/apps/email',
          icon: 'cil-envelope-closed',
          items: [
            {
              component: 'CNavItem',
              name: 'Inbox',
              to: '/apps/email/inbox',
              icon: 'cil-envelope-closed',
              badge: {
                color: 'danger',
                text: 'PRO',
              },
            },
            {
              component: 'CNavItem',
              name: 'Message',
              to: '/apps/email/message',
              icon: 'cil-envelope-open',
              badge: {
                color: 'danger',
                text: 'PRO',
              },
            },
            {
              component: 'CNavItem',
              name: 'Compose',
              to: '/apps/email/compose',
              icon: 'cil-envelope-letter',
              badge: {
                color: 'danger',
                text: 'PRO',
              },
            },
          ],
        },
      ],
    },
  ],
}

const bookings = {
  to: '/bookings/',
  name: 'Брони',
  component: 'CNavItem',
  icon: 'cil-description',
}
const leads = {
  to: '/leads/',
  name: 'Лиды',
  icon: 'cil-envelope-letter',
  component: 'CNavItem',
}
const tours = {
  name: 'Туры',
  icon: 'cil-description',
  to: '/tours/',
  component: 'CNavItem',
}
const transferBookings = {
  to: '/transfer/bookings',
  name: 'Заявки на трансферы',
  icon: 'cil-description',
  component: 'CNavItem',
}
const reports = {
  to: '/reports/',
  name: 'Отчеты',
  icon: 'cil-description',
  component: 'CNavItem',
}

const COMMON = []

const HEAP = {
  component: 'CNavItem',
  name: 'Куча',
  icon: 'cil-description',
  items: [
    {
      component: 'CNavItem',
      name: '1 Агент',
      items: [
        {
          to: '/agent/leads_list',
          name: '1.1.1 Список лидов (агент)',
          icon: 'cil-star',
          component: 'CNavItem',
        },
        {
          to: '/agent/script_play',
          name: '1.2 Работа по скрипту',
          component: 'CNavItem',
        },
        {
          to: '/agent/agent_tours_list',
          name: '1.3.1 Список туров агента',
          component: 'CNavItem',
        },
        {
          to: '/agent/hotel_find_helper',
          name: '1.5. Помощник по подбору отеля',
          component: 'CNavItem',
        },
        {
          to: '/agent/tour_search_demo',
          name: '1.6.1 Попап поиска по турам/лидам',
          component: 'CNavItem',
        },
        {
          to: '/agent/tour_search_results',
          name: '1.6.2 Результаты поиск по турам/лидам',
          component: 'CNavItem',
        },
        {
          to: '/agent/check_in_out',
          name: '1.7. Заезды и выезды',
          component: 'CNavItem',
        },
        {
          to: '/agent/agent_profile',
          name: '1.8.1 Профайл агента',
          component: 'CNavItem',
        },
        {
          to: '/agent/agent_fee',
          name: '1.8.2 Вознаграждение агента',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: '2 Агентство',
      items: [
        {
          to: '/agency/operations_log',
          name: '2.1 Лог операций',
          component: 'CNavItem',
        },
        {
          to: '/agency/agency_tour_operate',
          name: '2.3.2 Создание/редактирование/операция с туром',
          component: 'CNavItem',
        },
        {
          to: '/agency/agency_tours_list',
          name: '2.3.1 Туры список туров агентства',
          component: 'CNavItem',
        },
        {
          to: '/agency/system_contracts',
          name: '2.6. Договора с НСБ',
          component: 'CNavItem',
        },
        {
          to: '/agency/tele_call_list',
          name: '2.5. Звонки список',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: '3 Вебмастер',
      items: [
        {
          to: '/webmaster/bookings_forms_list',
          name: '3.1.1 Список форм бронирования',
          component: 'CNavItem',
        },
        {
          to: '/webmaster/bookings_forms_edit',
          name: '3.1.2 Редактирование форм бронирования',
          component: 'CNavItem',
        },
        {
          to: '/webmaster/bookings_forms_integration',
          name: '3.1.3 Интеграция формы бронирования',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: '4 Бухгалтер агентства',
      items: [
        {
          to: '/accountant/agency_entries_list',
          name: '4.1.1 Список проводок агентства',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agency_entry_create',
          name: '4.1.2 Создание проводки агентства',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agency_payments_list',
          name: '4.2.1 Список платежей агентства',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agency_payments_split',
          name: '4.2.3 Разнесение платежа агентства',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agency_payments_create',
          name: '4.2.4 Создание платежа агентства',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agency_reports',
          name: '4.4 Отчеты',
          component: 'CNavItem',
        },
        {
          to: '/accountant/export',
          name: '4.5 Экспорт',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agent_fee_calculation_create',
          name: '4.6.1 Начисление вознаграждения агентам',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agent_fee_calculation_list',
          name: '4.6.3 Список начислений вознаграждения',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agent_fee_list',
          name: '4.6.2 Список начисленных вознаграждений',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agency_system_settlements',
          name: '4.7 Взаиморасчеты с системой',
          component: 'CNavItem',
        },
        {
          to: '/accountant/agency_system_documents',
          name: '4.8 Бухгалтерский документы с системой (сверки, акты)',
          component: 'CNavItem',
        },
        {
          to: '/accountant/payment_orders_list',
          name: '4.10 Cписок платежных поручений',
          component: 'CNavItem',
        },
        {
          to: '/accountant/qrcodes_list',
          name: '4.11 Список QR-кодов сбербанка',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: '5 Директор системы',
      items: [
        {
          to: '/system/company/',
          name: '5.1.3 просмотр компании',
          component: 'CNavItem',
        },
        {
          to: '/entities',
          name: '5.4.1 Юридические лица',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: '6 Бухгалтер системы',
      items: [
        {
          to: '/sys_accountant/agency_entries_list',
          name: '6.1.1 Список проводок системы ',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/agency_create_entry',
          name: '6.1.2 Создание проводки системы',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_payments_list',
          name: '6.2.1 Список платежей системы',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_payments_import',
          name: '6.2.2 Импорт платежей системы',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_payment_split',
          name: '6.2.3 Разнесение платежа системы',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_payment_create',
          name: '6.2.4 Создание платежа системы',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_documents',
          name: '6.3 Документы',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_reports',
          name: '6.4 Отчеты',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_export',
          name: '6.6 Экспорт',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_agency_contract_list',
          name: '6.8.1 Договора с агентствами',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_agency_contract_edit',
          name: '6.8.2 Договор агентства',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_agency_plans_list',
          name: '6.8.3 Тарифные планы список',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_agency_plans_edit',
          name: '6.8.4 Тарифный план редактирование',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_hotelier_contract_list',
          name: '6.9.1 Договора отели список',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/system_hotelier_contract_edit',
          name: '6.9.2 Договор отели редактирование',
          component: 'CNavItem',
        },
        {
          to: '/sys_accountant/payment_orders_list',
          name: '6.10 Cписок платежных поручений',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: '7 Менеджер',
      items: [
        {
          to: '/tours/',
          name: '7.1.1 Список туров',
          component: 'CNavItem',
        },
        {
          to: '/manager/tour_operate',
          name: '7.1.2 Просмотр тура',
          component: 'CNavItem',
        },

        {
          to: '/manager/quotes_list',
          name: '7.1.3 Список квот',
          component: 'CNavItem',
        },

        {
          to: '/manager/bookings_list',
          name: '7.4.1 Список броней',
          component: 'CNavItem',
        },
        {
          to: '/manager/booking_operate',
          name: '7.4.2 Операция с бронью',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: '9 Владелец',
      items: [
        {
          to: '/tech/hotels',
          name: '9.2 Список отелей сотрудника системы',
          icon: 'cilStar',
          component: 'CNavItem',
        },
        {
          to: '/admin/employees_list',
          name: '9.2 Список сотрудников компании',
          icon: 'cilStar',
          component: 'CNavItem',
        },
        {
          to: '/demo/employee_view/10',
          name: '9.2.2 Просм.профиля сотрудника',
          component: 'CNavItem',
        },
        {
          to: '/employee_edit/10',
          name: '9.2.3 Ред.профиля сотрудника',
          component: 'CNavItem',
        },
        {
          component: 'CNavItem',
          name: 'Местные номера',
          to: '/admin/local_phones_list',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: '10 HMS менеджер',
      items: [
        {
          to: '/hms/chess',
          name: '10.1 Шахматка',
          component: 'CNavItem',
        },
        {
          to: '/hms/hotel',
          name: '10.2.0 Редактирование отеля',
          component: 'CNavItem',
        },
        bookings,
        {
          to: '/hms/occupations',
          name: '10.4 Размещения',
          component: 'CNavItem',
        },
        {
          to: '/hms/checkins',
          name: '10.5 Заезды',
          component: 'CNavItem',
        },
        {
          to: '/hms/residents',
          name: '10.6 Резиденты',
          component: 'CNavItem',
        },
        {
          to: '/hms/fms',
          name: '10.6.1 ФМС карточка',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: 'Общее',
      items: [
        {
          to: '/common/tours',
          name: '8.2 Список туров',
          component: 'CNavItem',
        },
      ],
    },
    {
      component: 'CNavItem',
      name: 'Старое',
      items: [
        {
          component: 'CNavItem',
          name: 'Чат',
          items: [
            {
              component: 'CNavItem',
              name: 'Чат НСБ',
              to: '/chat',
            },
            {
              component: 'CNavItem',
              name: 'Обычный чат',
              to: '/chat',
            },
          ],
        },
        {
          component: 'CNavItem',
          name: 'Сотрудники',
          to: '/admin/employees_list2',
        },

        {
          component: 'CNavItem',
          name: 'Справка',
          to: '/info',
        },
        /*  ----------------- Агент Start---------------*/

        {
          component: 'CNavItem',
          name: 'Подбор отеля',
          to: '/hotel',
        },
        {
          component: 'CNavItem',
          name: 'Заезды и выезды',
          to: '/arrivals',
        },
        /*  ----------------- Агент END---------------*/

        /*  ----------------- Владелец Start---------------*/
        {
          component: 'CNavItem',
          name: 'Лог операций',
          to: '/info',
        },
        {
          component: 'CNavItem',
          name: 'Заказчик',
          to: '/customer',
        },
        tours,

        {
          component: 'CNavItem',
          name: 'Звонки',
          to: '/calls',
        },
        /*  ----------------- Владелец end---------------*/
        /*  ----------------- Вебмастер Start---------------*/
        {
          component: 'CNavItem',
          name: 'Формы',
          to: '/forms',
        },
        /*  ----------------- Вебмастер end---------------*/
        /*  ----------------- Бухгалтер start---------------*/

        {
          component: 'CNavItem',
          name: 'Док. по клиентам',
          to: '/docs',
        },
        {
          component: 'CNavItem',
          name: 'Отчеты',
          to: '/reports',
        },
        /*  ----------------- Бухгалтер end---------------*/
      ],
    },
  ],
}

export const common_top_nav = [FRONT_PAGE, ...COMMON]
export const common_bottom_nav = [DEMO, COREUI, HEAP]

export const accountant_common = [bookings, reports]

// Системный Бух
export const sys_acc_nav = [
  ...accountant_common,
  {
    to: '/system/company/',
    name: 'Контрагенты',
    component: 'CNavItem',
    icon: 'cil-education',
  },
  {
    component: 'CNavItem',
    name: 'Отчеты комиссионера',
    to: '/sys_accountant/commissioner_report_list',
    icon: 'cil-star',
  },
  {
    name: 'Платежи',
    component: 'CNavItem',
    icon: 'cil-star',
    items: [
      {
        to: '/sys_accountant/system_payments_list',
        name: 'Список платежей',
        component: 'CNavItem',
        icon: 'cil-description',
      },
      {
        to: '/sys_accountant/payment_orders_list',
        name: 'Платежные поручения',
        component: 'CNavItem',
        icon: 'cil-description',
      },
      {
        component: 'CNavItem',
        name: 'История загрузок',
        to: '/imports_history',
        icon: 'cil-description',
      },
      {
        component: 'CNavItem',
        name: 'Зачеты платежей',
        to: '/payment_offset_list',
        icon: 'cil-description',
      },
    ],
  },
  {
    to: '/sys_accountant/agency_invoice_list',
    name: 'Счета агентствам',
    component: 'CNavItem',
    icon: 'cil-star',
  },
  {
    to: '/sys_accountant/supplier_invoice_list',
    name: 'Счета от поставщиков',
    component: 'CNavItem',
    icon: 'cil-star',
  },
]

// Агенстский Бух
export const agency_acc_nav = [
  ...accountant_common,
  {
    to: '/accountant/incoming_invoice_list',
    name: 'Входящие счета',
    component: 'CNavItem',
    icon: 'cil-description',
  },
  {
    name: 'Платежи',
    component: 'CNavItem',
    icon: 'cil-description',
    items: [
      {
        to: '/accountant/agency_payments_list',
        name: 'Список платежей',
        component: 'CNavItem',
        icon: 'cil-description',
      },
      {
        component: 'CNavItem',
        name: 'История загрузок',
        to: '/imports_history',
        icon: 'cil-description',
      },
      {
        to: '/accountant/payment_orders_list',
        name: 'Платежные поручения',
        component: 'CNavItem',
        icon: 'cil-description',
      },
    ],
  },
  {
    to: '/accountant/qrcodes_list',
    name: 'QR коды',
    component: 'CNavItem',
    icon: 'cil-qr-code',
  },
  {
    name: 'Зарплата',
    component: 'CNavItem',
    icon: 'cil-description',
    items: [
      {
        name: 'Начисления',
        component: 'CNavItem',
        icon: 'cil-description',
        to: '/salary_accrual/',
      },
      {
        name: 'Вознаграждения (не реализовано)',
        component: 'CNavItem',
        icon: 'cil-description',
        to: '/salary/',
      },
    ],
  },
]

// Системный админ
export const sys_admin_nav = [
  {
    to: '/blacklist/',
    name: 'Черный список',
    icon: 'cil-description',
    component: 'CNavItem',
  },
  {
    to: '/system/company/',
    name: 'Контрагенты',
    component: 'CNavItem',
    icon: 'cil-education',
  },
  {
    component: 'CNavItem',
    name: 'Местные номера',
    to: '/admin/local_phones_list',
    icon: 'cilHeadphones',
  },
  {
    to: '/system/user_list',
    name: 'Пользователи',
    component: 'CNavItem',
    icon: 'cilFace',
  },
  {
    to: '/admin/employees_list',
    name: 'Сотрудники',
    component: 'CNavItem',
    icon: 'cilPeople',
  },
  {
    to: '/admin/online_users_list',
    name: 'Сотрудники онлайн',
    component: 'CNavItem',
    icon: 'cilVoiceOverRecord',
  },
  bookings,
  reports,
  {
    to: '/hotels/',
    name: 'Отели',
    icon: 'cil-description',
    component: 'CNavItem',
  },
]

// Системный менеджер
export const sys_manager_nav = [
  bookings,
  {
    to: '/hotels/',
    icon: 'cil-description',
    name: 'Отели',
    component: 'CNavItem',
  },
  {
    to: '/query/',
    name: 'Запросы',
    icon: 'cil-description',
    component: 'CNavItem',
  },
]

// Агент
export const agent_nav_partial = [
  leads,
  tours,
  {
    to: '/task/',
    name: 'Задачи',
    icon: 'cil-description',
    component: 'CNavItem',
  },
  {
    to: '/quotas/',
    name: 'Квоты',
    icon: 'cil-description',
    component: 'CNavItem',
  },
  {
    to: '/customer/',
    name: 'Заказчики',
    icon: 'cilPeople',
    component: 'CNavItem',
  },
  {
    to: '/telecall',
    name: 'Звонки',
    icon: 'cil-phone',
    component: 'CNavItem',
  },
  {
    to: '/agent/script_play',
    name: 'Скрипт',
    icon: 'cil-description',
    component: 'CNavItem',
  },
]

// Супервизор
export const supervisor_nav_partial = [
  {
    to: '/agent/supervisor/',
    name: 'Cупервизор',
    icon: 'cil-people',
    component: 'CNavItem',
  },
]

export const agent_supervisor_nav = [
  ...agent_nav_partial,
  ...supervisor_nav_partial,
]

export const agent_nav = [...agent_nav_partial]

export const transfer_manager_admin_common = [
  transferBookings,
  {
    name: 'Услуги трансфера',
    icon: 'cil-description',
    to: '/transfer-service/',
    component: 'CNavItem',
  },
  {
    to: '/assign-driver',
    name: 'Назначение водителя',
    icon: 'cil-description',
    component: 'CNavItem',
  },
  {
    to: '/cars/',
    name: 'Автомобили',
    icon: 'cil-description',
    component: 'CNavItem',
  },
]

export const transfer_manager_nav = [...transfer_manager_admin_common]

export const transfer_admin_nav = [...transfer_manager_admin_common]

export const transfer_acc_nav = [transferBookings]

export const agency_admin_nav = [
  leads,
  tours,
  {
    to: '/task/',
    name: 'Задачи',
    icon: 'cil-description',
    component: 'CNavItem',
  },
  {
    to: '/admin/employees_list',
    name: 'Сотрудники',
    component: 'CNavItem',
    icon: 'cilPeople',
  },
  {
    to: '/admin/online_users_list',
    name: 'Сотрудники онлайн',
    component: 'CNavItem',
    icon: 'cilVoiceOverRecord',
  },
  {
    component: 'CNavItem',
    name: 'Звонки',
    to: '/telecall',
    icon: 'cil-phone',
  },
  {
    component: 'CNavItem',
    name: 'Местные номера',
    to: '/admin/local_phones_list',
    icon: 'cilHeadphones',
  },
  {
    to: '/quotas/',
    name: 'Квоты',
    icon: 'cil-description',
    component: 'CNavItem',
  },
  {
    to: '/customer/',
    name: 'Заказчики',
    icon: 'cilPeople',
    component: 'CNavItem',
  },
  {
    to: '/hotels/',
    name: 'Отели',
    icon: 'cil-description',
    component: 'CNavItem',
  },
  reports,
]
