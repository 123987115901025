<template>
  <div>
    <CModal
      ref="modal"
      :visible="isVisible"
      size="xl"
      backdrop="static"
      alignment="center"
      fullscreen="lg"
      @close="onClose"
    >
      <CModalHeader dismiss>
        <CModalTitle
          ><span>{{
            data.category
              ? `${data.category}, программа "${data.name}"`
              : `${data.name}, программа "${data.program}"`
          }}</span></CModalTitle
        >
      </CModalHeader>
      <CModalBody class="popup-min-h">
        <div class="overflow-scroll">
          <CTable striped bordered>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell> Дата </CTableHeaderCell>
                <CTableHeaderCell>
                  {{ catNames.whole_room }}
                </CTableHeaderCell>
                <CTableHeaderCell v-if="data.mainPlaces > 1">
                  {{ catNames.single_occupancy }}
                </CTableHeaderCell>
                <CTableHeaderCell v-if="data.mainPlaces > 1">
                  {{ catNames.place }}
                </CTableHeaderCell>
                <CTableHeaderCell>
                  {{ catNames.extra_place }}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              <CTableRow
                v-for="(item, i) in Object.values(data.byDay)[0]"
                :key="i"
              >
                <CTableDataCell>
                  {{ dateService.formatDate(item[0]) }}
                </CTableDataCell>
                <CTableDataCell>
                  {{ formatCentsRounded(data.byDay.whole_room[i][1]) }}
                </CTableDataCell>
                <CTableDataCell v-if="data.mainPlaces > 1">
                  {{ formatCentsRounded(data.byDay.single_occupancy[i][1]) }}
                </CTableDataCell>
                <CTableDataCell v-if="data.mainPlaces > 1">
                  {{ formatCentsRounded(data.byDay.place[i][1]) }}
                </CTableDataCell>
                <CTableDataCell>
                  {{ formatCentsRounded(data.byDay.extra_place[i][1]) }}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
          <!--      Вариант представления дней в строку
          <CTable striped bordered>
            <CTableHead>
              <CTableRow>
                <CTableDataCell></CTableDataCell>
                <CTableHeaderCell v-for="(itemD, i) in Object.values(data.byDay)[0]" :key="i">
                  {{ dateService.formatDate(itemD[0]) }}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              <CTableRow v-for="(value, key) in data.byDay" :key="key">
                <CTableDataCell>
                  {{ catNames[key] }}
                </CTableDataCell>
                <CTableDataCell v-for="(item, i) in value" :key="i">
                  {{ formatCentsRounded(item[1]) }}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
-->
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onClose">Закрыть</CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { formatCentsRounded } from '@/helpers/textFormatter'

defineProps({
  data: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['close'])
const { dateService } = inject('services')

const isVisible = ref(false)
const catNames = {
  whole_room: 'Номер целиком',
  single_occupancy: 'Одноместное размещение',
  place: 'Основное место',
  extra_place: 'Дополнительное место',
}
const onClose = () => {
  isVisible.value = false
  emit('close')
}

onMounted(() => {
  isVisible.value = true
})
</script>
