// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BuyerpaymentApiMethodsCls {
    async buyerPaymentCreateCreate(wh, data, extra_data = null) {
      // data: BuyerPayment
      // Создание платежа покупателя
      console.log('buyerPaymentCreateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.create.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentRetrieve(wh, id, extra_data = null) {
      // Получение конкретного платежа покупателя
      console.log('buyerPaymentRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__buyerpayment', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__buyerpayment', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentAcceptPartial(wh, id, data, extra_data = null) {
      // data: BuyerPayment
      // Принять платеж покупателя
      console.log('buyerPaymentAcceptPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.accept.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentAnnulPartial(wh, id, data, extra_data = null) {
      // data: BuyerPayment
      // Аннулировать платеж покупателя
      console.log('buyerPaymentAnnulPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.annul.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentAutoSplitPartial(wh, id, data, extra_data = null) {
      // data: BuyerPayment
      // Разнести платеж покупателя по номеру счёта
      console.log('buyerPaymentAutoSplitPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.autoSplit.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentCancelPartial(wh, id, data, extra_data = null) {
      // data: BuyerPayment
      // Отменить платеж покупателя
      console.log('buyerPaymentCancelPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.cancel.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentHoldPartial(wh, id, data, extra_data = null) {
      // data: BuyerPayment
      // Провести платеж покупателя
      console.log('buyerPaymentHoldPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.hold.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentRestorePartial(wh, id, data, extra_data = null) {
      // data: BuyerPayment
      // Восстановить платеж покупателя
      console.log('buyerPaymentRestorePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.restore.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentSetBuyerPartial(wh, id, data, extra_data = null) {
      // data: BuyerPaymentBuyer
      // Установить покупателя для платежа
      console.log('buyerPaymentSetBuyerPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.setBuyer.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async entityBuyerPaymentsList(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка платежей покупателей для юр. лица агентства
      // filters: created_at, updated_at, owner_entity, operator_identity, state, object_type,
      //          doc_number, doc_date_after, doc_date_before, contractor_entity, tour,
      //          import_buyer_payments, invoice_number, invoice_date_after, invoice_date_before,
      //          payment_doc_number, payment_doc_date_after, payment_doc_date_before, amount_min,
      //          amount_max, type, info, cash, buyer_name, inn, kpp, bik, bank_account, buyer,
      //          split_amount, pk, split
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('entityBuyerPaymentsList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.entity.buyerPayments.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.buyerPaymentApi = new BuyerpaymentApiMethodsCls()
}

