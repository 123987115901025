// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BuyerApiMethodsCls {
    async buyerList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Buyer
      // filters: created_at, updated_at, company, customer, person, organization, cr_limit
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('buyerList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async buyerCreate(wh, data, extra_data = null) {
      // data: BuyerCreate
      // Создание Buyer
      // Если не передан owner, то owner становится тот, кто делает запрос
      console.log('buyerCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerRetrieve(wh, id, extra_data = null) {
      // Получение Buyer
      console.log('buyerRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__buyer', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__buyer', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerUpdate(wh, id, data, extra_data = null) {
      // data: BuyerCreate
      // Обновление Buyer
      console.log('buyerUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerDelete(wh, id) {
      // Удаление Buyer
      console.log('buyerDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.buyer.delete(id)})
      ctx.$services.storage.del('core__buyer', id, wh)
      return null
    }
    async buyerCrLimitPartial(wh, id, data, extra_data = null) {
      // data: BuyerChangeCrLimit
      // Изменение cr_limit у Buyer
      console.log('buyerCrLimitPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.crLimit.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPersonPartial(wh, id, data, extra_data = null) {
      // data: BuyerChangePerson
      // Изменение person и(или) organization у Buyer
      console.log('buyerPersonPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.person.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.buyerApi = new BuyerApiMethodsCls()
}

