/* Можно использовать - но только из api */
/*
  request возвращает
  1) return data в случае успеха
  2) throw error

  Ваш обработчик должен перехватывать все исключения
  для 400 - разносить ошибки по полям (по возможности)
  остальные ошибки (400 без полей и все прочие) - выводить в форме текстом
*/

import axios from 'axios'
import { QueuedResponseException } from '@/exceptions'
import { errorIgnoreListCheck } from '@/helpers/errorIgnoreList'
import { requestClientLoggerInit } from '@/helpers/clientLogger'

const errorsToStr = (errors) => {
  let res = ''
  if (typeof errors === 'object') {
    for (const [key, value] of Object.entries(errors)) {
      res += `<b>${key}</b>:`
      if (typeof value === 'string') {
        res += `<br>${value}`
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          res += `<br>${item}`
        })
      } else {
        res += '<br>' + JSON.stringify(value)
      }
    }
  } else {
    res = JSON.stringify(errors)
  }
  return res
}

const requestClientLogger = requestClientLoggerInit()

export default (ctx) => {
  class FetchService {
    async request({ config = {}, body = {}, file = null }) {
      const data = { ...(await this.#prepareRequest({ config, body })) }

      if (file) {
        data.onUploadProgress = function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          )

          if (file) {
            file.percentage = percentCompleted
          }
          return percentCompleted
        }
      }

      let response
      try {
        response = await axios(data)

        requestClientLogger(response, { request: data })
      } catch (error) {
        requestClientLogger(error, { request: data })

        if (!error.response) {
          return undefined
        }

        console.log('-----------------------------------------')
        console.error(error)
        console.error(error.response)
        console.error(typeof error.response)
        console.error('error.response.status', error.response.status)
        console.error('error.response.data', error.response.data)
        console.error('error.response.data.errors', error.response.data.errors)

        // игнорируем ошибки которые хотим специально обрабатывать на месте
        if (errorIgnoreListCheck(ctx, error.response)) return

        switch (error.response.status) {
          case 400:
          case 401: {
            const errorList = error?.response?.data?.errors?.__all__
            if (error.response.data?.errors?.length) {
              console.error(error.response.data.errors)
              if (
                error.response.data.errors?.length &&
                !error.response.data.errors.includes(
                  'Файл уже привязан к объекту',
                )
              ) {
                // TODO можно-ли добавить в errorIgnoreList
                // Временно не ругаться если файл уже привязан.
                // Пока не переделан FilesUploadForm
                // Окно принятия отчета комиссионера
                // https://tracker.yandex.ru/BACK-1286?focusedCommentId=11332
                ctx.$services.notification.error(
                  errorsToStr(error.response.data.errors),
                )
              }
            } else if (errorList?.length) {
              ctx.$services.notification.error(errorsToStr(errorList))
            } else if (
              error.response.data instanceof Object &&
              Object.keys(error.response.data).length
            ) {
              ctx.$services.notification.error(
                `Запрос: ${data.url}<br>Ответ: ${error.toString()}`,
                { header: `Ошибка запроса ${data.method}` },
              )
              ctx.$services.notification.error(errorsToStr(error.response.data))
            } else {
              console.error(error.response)
            }

            throw error
          }
          case 422: {
            const errorListText = error?.response?.data?.errors
            const errorDetail = error?.response?.data?.detail
            if (errorDetail) {
              ctx.$services.notification.error(errorsToStr(errorDetail))
            } else if (typeof errorListText === 'string') {
              ctx.$services.notification.error(errorListText)
            }

            throw error
          }
          case 426:
            throw error
          default:
            ctx.$services.notification.error(
              `Запрос: ${data.url}<br>Ответ: ${error.toString()}`,
              { header: `Ошибка запроса ${data.method}` },
            )
            throw error
        }
      }

      if (response.status === 202) {
        ctx.$services.expectant.addTask(response.data.task_id)
        console.log('response.data.task_id', response.data.task_id)
        throw new QueuedResponseException(response.data.task_id)
      }

      if (file) {
        file.id = response?.data?.data?.hash
      }
      return this.#responseHandler(response, await response.data)
    }

    async #prepareRequest({ config = {}, body = {} }) {
      let { url, method = 'GET', params = {}, json = true } = config
      if (body) {
        if (json) {
          body = JSON.stringify(body)
        } else {
          const formData = new FormData()
          Object.keys(body).forEach((key) => formData.append(key, body[key]))
          body = formData
        }
      }

      const headers = {}
      if (json) {
        headers['Content-Type'] = 'application/json'
      } else {
        headers['Content-Type'] = 'multipart/form-data'
      }

      const token = await ctx.$services.auth.getTokenKey()
      if (token) {
        headers['authorization'] = token
      }
      if (params) {
        params = Object.keys(params).length ? new URLSearchParams(params) : ''
      } else {
        params = ''
      }
      const server = await ctx.$services.auth.getServerUrl()
      url = `${server}/${url}?${params.toString()}`
      return {
        method: method,
        url: url,
        data: body,
        headers: headers,
      }
    }

    #responseHandler(response, data) {
      if (!response || response.status >= 300) {
        if (data.detail) {
          throw new Error(data.detail || 'Ошибка при выполнении запроса')
        } else {
          let allErrors = ''
          Object.keys(data).forEach((error) => {
            if (data[error].constructor.name == 'Object') {
              Object.keys(data[error]).forEach((errorDeep) => {
                if (data[error][errorDeep].constructor.name == 'Array') {
                  allErrors =
                    allErrors +
                    (allErrors ? '<br />' : '') +
                    data[error][errorDeep].join()
                }
              })
            }
          })
          throw new Error(allErrors || 'Ошибка при выполнении запроса')
        }
      }
      return data
    }

    #removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          // eslint-disable-next-line no-unused-vars
          ([_, v]) => v !== null && v !== '' && v !== undefined,
        ),
      )
    }

    prepareQueryParams(
      requestData,
      {
        extraFilters = {},
        autocompletes = [],
        dates = [],
        ranges = [],
        relations = [],
      },
    ) {
      const asc = requestData.ascending === 1 ? '-' : ''
      const sort = requestData.orderBy ? `${asc}${requestData.orderBy}` : ''

      const filters = this.#removeEmpty({
        ...requestData.query,
        ...requestData.filters,
        ...extraFilters,
      })

      console.log('filters', filters)

      autocompletes.forEach((key) => {
        if (
          filters[key] &&
          filters[key] !== null &&
          filters[key] !== '' &&
          filters[key] !== undefined
        ) {
          filters[key] = filters[key]?.id
        }
      })

      dates.forEach((key) => {
        if (
          filters[key] &&
          filters[key] !== null &&
          filters[key] !== '' &&
          filters[key] !== undefined
        ) {
          filters[key] = ctx.$services.dateService.toIso(filters[key])
        }
      })

      ranges.forEach((key) => {
        console.log('ranges', key)
        if (
          filters[key] &&
          filters[key] !== null &&
          filters[key] !== '' &&
          filters[key] !== undefined
        ) {
          filters[key + '_after'] = ctx.$services.dateService.toIso(
            filters[key][0],
          )
          filters[key + '_before'] = ctx.$services.dateService.toIso(
            filters[key][1],
          )
          delete filters[key]
        }
      })

      if (relations.length) {
        filters.relations = relations.join()
      }

      return {
        limit: requestData.limit,
        offset: requestData.limit * requestData.page - requestData.limit,
        sort: sort,
        filters: filters,
        is_active: requestData.status,
        query_time: new Date(),
      }
    }
  }
  ctx.$services.fetch = new FetchService()
}
