<template>
  <CSidebar
    position="fixed"
    :narrow="!store.state.sidebarExpanded"
    :visible="store.state.sidebarVisible"
    @visible-change="onVisibleChange"
  >
    <CSidebarBrand class="logo-container" role="button" @click="$router.push({ path: '/' })">
      <CIcon :icon="nsblogo" height="60" class="mt-2" alt="Logo" />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler @click="store.commit('toggleSidebar')" />
  </CSidebar>
</template>

<script setup>
import { inject, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { nsblogo } from '@/assets/brand/logo-nsb'

const store = useStore()
const { persistentService } = inject('services')

const onVisibleChange = (val) => {
  store.commit('toggleSidebarVisibility', val)
}

watch(() => store.state.sidebarExpanded, (val) => {
  const sidebarState = val ? 'expanded' : 'collapsed'
  persistentService.setSession('sidebarState', sidebarState)
  persistentService.set('sidebarState', sidebarState)
})

watch(() => store.state.asideVisible, (val) => {
   if (val) {
     store.commit('toggleSidebar', false)
   }
})

onBeforeMount(() => {
   persistentService.getSession('sidebarState').then((sidebarState) => {
     if (sidebarState) {
       store.commit('toggleSidebar', sidebarState === 'expanded')
     } else {
       persistentService.get('sidebarState').then((sidebarState) => {
         if (sidebarState) {
           store.commit('toggleSidebar', sidebarState === 'expanded')
         }
       })
     }
   })
})
</script>
