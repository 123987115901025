<template>
  <div>
    <CModal
      ref="modal"
      :visible="isVisible"
      size="md"
      backdrop="static"
      alignment="center"
      fullscreen="md"
      @close="onClosed"
      @show="onOpened"
    >
      <CModalHeader dismiss>
        <CModalTitle> Поиск по турам и лидам</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div>
          <CFormLabel> Телефон, e-mail, ФИО </CFormLabel>
          <CFormInput v-model="searchString" @keydown.enter="onOk" />
          <CFormText> Введите телефон, e-mail, либо ФИО заказчика </CFormText>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onClosed">Закрыть</CButton>
        <CButton
          color="primary"
          @click="onOk"
          :disabled="!Boolean(searchString)"
          >Найти</CButton
        >
      </CModalFooter>
      <CModalBody>
        <ThePodval
          title="1.6.1 Попап поиска по турам/лидам"
          :tasks="['https://tracker.yandex.ru/BACK-2575', 'https://tracker.yandex.ru/BACK-583']"
          :wiki="[]"
          :uuid="WH"
        />
      </CModalBody>
    </CModal>

    <PopupLeadsToursSearch
      v-if="shPopupLeadsToursSearch"
      :search-string="searchString"
      @close="shPopupLeadsToursSearch = false"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, defineEmits, nextTick, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import PopupLeadsToursSearch from '@/components/_shared/search-tours-leads/PopupLeadsToursSearch.vue'
import ThePodval from "@/components/_shared/ThePodval.vue";

const WH = '2c57d508-423d-481c-bf69-8a2edb6f25db'
const props = defineProps({
  defaultValue: {
    type: [String, null],
    required: false,
    default: null,
  },
})
const emit = defineEmits(['closed', 'update:modelValue'])

const modal = ref(null)
const router = useRouter()
const isVisible = ref(false)
const ready = ref(false)
const searchString = ref(props.defaultValue)
const shPopupLeadsToursSearch = ref(false)

const onClosed = () => {
  isVisible.value = false
  emit('closed')
}
const onOk = async () => {
  if (!searchString.value) return

  if (router.currentRoute.value.path.includes('tour_search_results')) {
    await router.push({
      path: '/agent/tour_search_results',
      query: {
        search: searchString.value,
      },
    })
    onClosed()
  } else {
    shPopupLeadsToursSearch.value = true
  }
}

const onKeyDown = (ev) => {
  if (ev.key === 'Escape') {
    onClosed()
    ev.preventDefault()
  }
}

watch(
  () => router.currentRoute.value.path,
  () => {
    onClosed()
  },
)

onMounted(() => {
  isVisible.value = true
  nextTick(() => {
    ready.value = true
    window.addEventListener('keydown', onKeyDown)
  })
})
onUnmounted(() => {
  window.removeEventListener('keydown', onKeyDown)
})
</script>
