<template>
  <div class="minimizable" ref="modalContainer">
    <CModal
      ref="modal"
      :visible="isVisible"
      size="xl"
      backdrop="static"
      alignment="center"
      fullscreen="lg"
      @close="onClose"
    >
      <ModalMinimize v-if="modalContainer" :modal-container="modalContainer" />
      <CModalHeader dismiss>
        <CModalTitle :id="`id-title-${WH}`">
          <div></div>
        </CModalTitle>
      </CModalHeader>
      <CModalBody class="popup-min-h" :id="`id-${WH}`">
        <HotelPlacesInfo :data-req="dataReq" :modal-wh="WH" :close="close" />
      </CModalBody>
      <div :id="`id-after-body-${WH}`" class="flex justify-content-end"></div>
      <CModalFooter :id="`id-footer-${WH}`">
        <div class="flex flex-grow-1"></div>
        <CButton color="secondary" @click="onClose">Закрыть</CButton>
      </CModalFooter>
      <ThePodval
        title="Наличие мест"
        :tasks="[
          'https://tracker.yandex.ru/BACK-2277',
          'https://tracker.yandex.ru/BACK-1136',
          'https://tracker.yandex.ru/BACK-4454',
        ]"
        :wiki="[
          'https://wiki.yandex.ru/homepage/proekt/interfejjs/polnyjj-spisok-jekranov/agent/nalichie-mest/',
          'https://wiki.yandex.ru/homepage/proekt/api/zaprosy/available-space/',
        ]"
        :uuid="WH"
      />
    </CModal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import HotelPlacesInfo from '@/components/_shared/hotel-places/HotelPlacesInfo.vue'
import ModalMinimize from '@/components/_common/ModalMinimize.vue'

const WH = '147c7af1-07de-4ca5-91a1-831818a68827'
const props = defineProps({
  /**
   * @type {{
   *   hotel: number
   *   hotelTitle?: string
   *   category?: number
   *   date_fr: string
   *   date_to: string
   *   days_to?: string
   * }}
   */
  dataReq: {
    type: Object,
    required: true,
  },
  // Передаём фн т.к. эмит close работатет c addException колбэком только при первом открытии окна, непонятно почему
  close: {
    type: Function,
    required: true,
  },
})

const isVisible = ref(false)
const modalContainer = ref(null)
const onClose = () => {
  isVisible.value = false
  props.close()
}

onMounted(() => {
  isVisible.value = true
})
</script>
