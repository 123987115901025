<template>
  <div>
    <CModal
      ref="modal"
      :visible="isVisible"
      size="xl"
      backdrop="static"
      alignment="center"
      fullscreen="md"
      scrollable
      @close="emit('closed')"
    >
      <template v-if="info">
        <CModalHeader dismiss>
          <CModalTitle>Событие телефонии #{{ info.id }}</CModalTitle>
        </CModalHeader>
        <CModalBody class="list">
          <template v-for="(value, label) in info" :key="label">
            <div v-if="formedLabel(label)" class="info mb-4">
              <b class="label mr-4"> {{ formedLabel(label) }} : </b>
              <div class="value">
                <template v-if="formedValue(value, label)">
                  <TelecallTableRow
                    v-if="isValLink(label)"
                    :info="info"
                    :label="label"
                    :value="value"
                  />
                  <template v-else>
                    <div v-html="formedValue(value, label)" />
                  </template>
                </template>
                <template v-else>__</template>
              </div>
            </div>
          </template>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" @click="emit('closed')">Закрыть</CButton>
        </CModalFooter>
      </template>
    </CModal>
  </div>
</template>

<script setup>
import TelecallTableRow from '@/components/_telecall/TelecallTableRow.vue'
import { computed } from 'vue'

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['closed'])

const isVisible = computed(() => {
  return Boolean(props.info)
})

const formedLabel = (key) => {
  switch (key) {
    case 'created_at':
      return 'Дата звонка'
    case 'started_at':
      return 'Начало звонка'
    case 'stopped_at':
      return 'Завершение звонка'
    case 'ring_duration':
      return 'Продолжительность звонка'
    case 'call_duration':
      return 'Продолжительность беседы'
    case 'record_url':
      return 'Запись разговора'
    case 'event_type':
      return 'Событие'
    case 'tele_call_type':
      return 'Тип звонка'
    case 'telephony_id':
      return 'Идентификатор звонка'
    case 'object_id':
      return 'Объект'
    case 'raw_data':
      return 'Прочие данные'
    case 'did':
      return 'Номер, на который пришёл звонок'
    case 'identity':
      return 'Сотрудник'
    case 'initiator':
      return 'Инициатор'
    case 'phone':
      return 'Номер, с которого поступил звонок'
    default:
      return ''
  }
}

const isValLink = (label) => {
  switch (label) {
    case 'record_url':
    case 'object_id':
    case 'initiator':
    case 'identity':
      return true
    default:
      return false
  }
}

const formedValue = (value, label) => {
  return props.info?.formedData?.[label] ? props.info.formedData[label] : value
}
</script>

<style lang="scss">
.info {
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
