<template>
  <div class="p-3">
    <CListGroup class="my-3" flush>
      <CListGroupItem>
        <b>Платежное поручение</b>:
        <ValueInfo :field="payment.doc_number || false" />
        <span>(#{{ payment.id }})</span>
      </CListGroupItem>
      <CListGroupItem> <b>Тип платежа</b>: {{ paymentType }} </CListGroupItem>
      <CListGroupItem>
        <b>Назначение</b>:
        {{ payment.info }}
      </CListGroupItem>
      <CListGroupItem>
        <b>Статус</b>: {{ C.DOCUMENT_STATE_TITLES[payment.state] }}
      </CListGroupItem>
      <CListGroupItem>
        <b>Cоздан</b>:
        <span v-html="dateService.formatDateTime(payment.created_at)"></span>
      </CListGroupItem>
      <CListGroupItem>
        <b>Изменён</b>:
        <span v-html="dateService.formatDateTime(payment.updated_at)"></span>
      </CListGroupItem>
      <CListGroupItem v-if="payment.payment_doc_date">
        <b>Дата П/П</b>: <span>{{ payment.payment_doc_date }}</span>
      </CListGroupItem>
      <CListGroupItem>
        <b>Платеж наличными</b>:
        <span>{{ payment.cash ? 'Да' : 'Нет' }}</span>
      </CListGroupItem>
      <CListGroupItem>
        <b>{{ isIncoming ? 'Плательщик' : 'Получатель' }}</b
        >: {{ contractor?.full_title }}
      </CListGroupItem>
      <CListGroupItem>
        <b>Сумма</b>:
        <span :class="isIncoming === false ? 'text-danger' : 'text-primary'">{{
          formatCents(payment.amount)
        }}</span>
      </CListGroupItem>
      <CListGroupItem>
        <b>Разнесение: </b>
        <ValueInfo
          :field="
            payment.split_amount ? 'Платеж разнесен' : 'Платеж не разнесен'
          "
        />
      </CListGroupItem>
      <CListGroupItem>
        <b>Договор</b>:
        <ValueInfo :field="payment?.extra_data?.contract_number || false" />
      </CListGroupItem>
      <CListGroupItem v-if="importId">
        <b>Источник</b>:
        <span
          role="button"
          @click="idPaymentImportFileView = importId"
          class="text-primary"
        >
          <span>Импорт #{{ importId }}</span>
        </span>
      </CListGroupItem>
    </CListGroup>
  </div>

  <PopupPaymentImportFileView
    main-title="Результат загрузки платежей системы"
    :id="idPaymentImportFileView"
    :show="idPaymentImportFileView"
    @close="idPaymentImportFileView = null"
    :run-parse="false"
  />
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import C from '@/config/back_const'
import ValueInfo from '@/components/shared/ValueInfo.vue'
import { formatCents } from '@/helpers/textFormatter'
import PopupPaymentImportFileView from '@/components/_payment/PopupPaymentImportFileView.vue'
import { useAuthData } from '@/composables/authData'

const { dateService } = inject('services')

const { isSystem } = useAuthData()

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const payment = ref(props.data.payment)
const contractor = ref(props.data.contractor)
const isIncoming = ref(props.data.isIncoming)
const idPaymentImportFileView = ref(null)
const importId = computed(() => payment.value.import_payments || payment.value.import_buyer_payments)

const paymentType = computed(() => {
  const type = payment.value?.type
  if (isSystem.value) {
    return C.PAYMENT_TYPE_TITLES?.[type]
  }
  return C.AGENCY_PAYMENT_TYPE_TITLES?.[type] || ''
})
</script>
