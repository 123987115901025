// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgencyentryApiMethodsCls {
    async agencyEntryList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка проводок агентства
      // filters: opcode, buyer, owner_entity, operator, payment
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('agencyEntryList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.entry.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async agencyEntryCreateCreate(wh, data, extra_data = null) {
      // data: AgencyEntryCustomCreate
      // Создание ручной проводки агентства
      console.log('agencyEntryCreateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.entry.create.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agencyEntryRetrieve(wh, id, extra_data = null) {
      // Получение конкретной проводки агентства
      console.log('agencyEntryRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('finance__agencyentry', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'finance__agencyentry', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.entry.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.agencyEntryApi = new AgencyentryApiMethodsCls()
}

