<template>
  <template v-if="field">
    {{ infoString }}
  </template>
  <span
    v-else
    class="text-disabled"
  >
    {{ infoString }}
  </span>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'ValueInfo',
  props: {
    field: {
      type: [Object, String, Number, Boolean],
      required: true
    },
    additional: {
      type: String,
      default: () => ''
    }
  },
  setup(props) {
    const infoString = computed(() => props.field
      ? `${props.field} ${props.additional}`
      : 'Информация отсутствует')

    return {
      infoString
    }
  }
}
</script>

<style scoped>

</style>
