<template>
  <template v-if="hasData">
    <div class="col-12 col-sm-4 col-md-3 col-lg-2 my-3">
      <CFormSelect v-model="selectMode" size="sm">
        <option value="cat">Цены по номерам</option>
        <option value="prog">Цены по программам</option>
      </CFormSelect>
    </div>
    <div class="overflow-scroll">
      <template v-if="selectMode === 'cat'">
        <div
          v-for="item in formatedData.byCategory"
          :key="item.name"
          class="pb-4 mt-2"
        >
          <h5>{{ item.name }}</h5>
          <CTable striped>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell> Программа </CTableHeaderCell>
                <CTableHeaderCell> Номер целиком </CTableHeaderCell>
                <CTableHeaderCell v-if="item.mainPlaces > 1">
                  Одноместное размещение
                </CTableHeaderCell>
                <CTableHeaderCell v-if="item.mainPlaces > 1">
                  Основное место
                </CTableHeaderCell>
                <CTableHeaderCell> Дополнительное место </CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              <template v-for="(progItem, index) in item.programs" :key="index">
                <CTableRow v-if="progItem?.name">
                  <CTableDataCell>
                    {{ progItem.name }}
                  </CTableDataCell>

                  <CTableDataCell>
                    <CTooltip
                      :content="getTooltipContent(progItem, 'whole_room')"
                    >
                      <template #toggler="{ on }">
                        <span
                          v-if="
                            progItem.minMax &&
                            progItem.minMax.whole_room[0] ===
                              progItem.minMax.whole_room[1]
                          "
                          class="p-2"
                          style="cursor: default"
                          v-on="on"
                          >{{
                            formatCentsRounded(progItem.total.whole_room)
                          }}</span
                        >
                        <a
                          v-else
                          href=""
                          class="p-2 dotted-underline"
                          role="button"
                          @click.prevent="
                            currDataByDay = {
                              ...progItem,
                              category: item.name,
                              mainPlaces: item.mainPlaces,
                            }
                          "
                          v-on="on"
                          >{{
                            formatCentsRounded(progItem.total.whole_room)
                          }}</a
                        >
                      </template>
                    </CTooltip>
                  </CTableDataCell>

                  <CTableDataCell v-if="item.mainPlaces > 1">
                    <CTooltip
                      :content="getTooltipContent(progItem, 'single_occupancy')"
                    >
                      <template #toggler="{ on }">
                        <span
                          v-if="
                            progItem.minMax &&
                            progItem.minMax.single_occupancy[0] ===
                              progItem.minMax.single_occupancy[1]
                          "
                          class="p-2"
                          style="cursor: default"
                          v-on="on"
                          >{{
                            formatCentsRounded(progItem.total.single_occupancy)
                          }}</span
                        >
                        <a
                          v-else
                          href=""
                          class="p-2 dotted-underline"
                          role="button"
                          @click.prevent="
                            currDataByDay = {
                              ...progItem,
                              category: item.name,
                              mainPlaces: item.mainPlaces,
                            }
                          "
                          v-on="on"
                          >{{
                            formatCentsRounded(progItem.total.single_occupancy)
                          }}</a
                        >
                      </template>
                    </CTooltip>
                  </CTableDataCell>

                  <CTableDataCell v-if="item.mainPlaces > 1">
                    <CTooltip :content="getTooltipContent(progItem, 'place')">
                      <template #toggler="{ on }">
                        <span
                          v-if="
                            progItem.minMax &&
                            progItem.minMax.place[0] ===
                              progItem.minMax.place[1]
                          "
                          class="p-2"
                          style="cursor: default"
                          v-on="on"
                          >{{ formatCentsRounded(progItem.total.place) }}</span
                        >
                        <a
                          v-else
                          href=""
                          class="p-2 dotted-underline"
                          role="button"
                          @click.prevent="
                            currDataByDay = {
                              ...progItem,
                              category: item.name,
                              mainPlaces: item.mainPlaces,
                            }
                          "
                          v-on="on"
                          >{{ formatCentsRounded(progItem.total.place) }}</a
                        >
                      </template>
                    </CTooltip>
                  </CTableDataCell>

                  <CTableDataCell>
                    <CTooltip
                      :content="getTooltipContent(progItem, 'extra_place')"
                    >
                      <template #toggler="{ on }">
                        <span
                          v-if="
                            progItem.minMax &&
                            progItem.minMax.extra_place[0] ===
                              progItem.minMax.extra_place[1]
                          "
                          class="p-2"
                          style="cursor: default"
                          v-on="on"
                          >{{
                            formatCentsRounded(progItem.total.extra_place)
                          }}</span
                        >
                        <a
                          v-else
                          href=""
                          class="p-2 dotted-underline"
                          role="button"
                          @click.prevent="
                            currDataByDay = {
                              ...progItem,
                              category: item.name,
                              mainPlaces: item.mainPlaces,
                            }
                          "
                          v-on="on"
                          >{{
                            formatCentsRounded(progItem.total.extra_place)
                          }}</a
                        >
                      </template>
                    </CTooltip>
                  </CTableDataCell>
                </CTableRow>
              </template>
            </CTableBody>
          </CTable>
        </div>
      </template>
      <template v-if="selectMode === 'prog'">
        <div
          v-for="item in formatedData.byProgram"
          :key="item.name"
          class="pb-4 mt-2"
        >
          <h5>{{ item.name }}</h5>
          <CTable striped>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell> Номер </CTableHeaderCell>
                <CTableHeaderCell> Номер целиком </CTableHeaderCell>
                <CTableHeaderCell> Одноместное размещение </CTableHeaderCell>
                <CTableHeaderCell> Основное место </CTableHeaderCell>
                <CTableHeaderCell> Дополнительное место </CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              <CTableRow v-for="catItem in item.categories" :key="catItem.name">
                <CTableDataCell>
                  {{ catItem.name }}
                </CTableDataCell>

                <CTableDataCell>
                  <CTooltip :content="getTooltipContent(catItem, 'whole_room')">
                    <template #toggler="{ on }">
                      <span
                        v-if="
                          catItem.minMax &&
                          catItem.minMax.whole_room[0] ===
                            catItem.minMax.whole_room[1]
                        "
                        class="p-2"
                        style="cursor: default"
                        v-on="on"
                        >{{
                          formatCentsRounded(catItem.total.whole_room)
                        }}</span
                      >
                      <a
                        v-else
                        href=""
                        class="p-2 dotted-underline"
                        role="button"
                        @click.prevent="
                          currDataByDay = {
                            ...catItem,
                            program: item.name,
                            mainPlaces: catItem.mainPlaces,
                          }
                        "
                        v-on="on"
                        >{{ formatCentsRounded(catItem.total.whole_room) }}</a
                      >
                    </template>
                  </CTooltip>
                </CTableDataCell>

                <CTableDataCell>
                  <template v-if="catItem.mainPlaces > 1">
                    <CTooltip
                      :content="getTooltipContent(catItem, 'single_occupancy')"
                    >
                      <template #toggler="{ on }">
                        <span
                          v-if="
                            catItem.minMax &&
                            catItem.minMax.single_occupancy[0] ===
                              catItem.minMax.single_occupancy[1]
                          "
                          class="p-2"
                          style="cursor: default"
                          v-on="on"
                          >{{
                            formatCentsRounded(catItem.total.single_occupancy)
                          }}</span
                        >
                        <a
                          v-else
                          href=""
                          class="p-2 dotted-underline"
                          role="button"
                          @click.prevent="
                            currDataByDay = {
                              ...catItem,
                              program: item.name,
                              mainPlaces: catItem.mainPlaces,
                            }
                          "
                          v-on="on"
                          >{{
                            formatCentsRounded(catItem.total.single_occupancy)
                          }}</a
                        >
                      </template>
                    </CTooltip>
                  </template>
                </CTableDataCell>

                <CTableDataCell>
                  <template v-if="catItem.mainPlaces > 1">
                    <CTooltip :content="getTooltipContent(catItem, 'place')">
                      <template #toggler="{ on }">
                        <span
                          v-if="
                            catItem.minMax &&
                            catItem.minMax.place[0] === catItem.minMax.place[1]
                          "
                          class="p-2"
                          style="cursor: default"
                          v-on="on"
                          >{{ formatCentsRounded(catItem.total.place) }}</span
                        >
                        <a
                          v-else
                          href=""
                          class="p-2 dotted-underline"
                          role="button"
                          @click.prevent="
                            currDataByDay = {
                              ...catItem,
                              program: item.name,
                              mainPlaces: catItem.mainPlaces,
                            }
                          "
                          v-on="on"
                          >{{ formatCentsRounded(catItem.total.place) }}</a
                        >
                      </template>
                    </CTooltip>
                  </template>
                </CTableDataCell>

                <CTableDataCell>
                  <CTooltip
                    :content="getTooltipContent(catItem, 'extra_place')"
                  >
                    <template #toggler="{ on }">
                      <span
                        v-if="
                          catItem.minMax &&
                          catItem.minMax.extra_place[0] ===
                            catItem.minMax.extra_place[1]
                        "
                        class="p-2"
                        style="cursor: default"
                        v-on="on"
                        >{{
                          formatCentsRounded(catItem.total.extra_place)
                        }}</span
                      >
                      <a
                        v-else
                        href=""
                        class="p-2 dotted-underline"
                        role="button"
                        @click.prevent="
                          currDataByDay = {
                            ...catItem,
                            program: item.name,
                            mainPlaces: catItem.mainPlaces,
                          }
                        "
                        v-on="on"
                        >{{ formatCentsRounded(catItem.total.extra_place) }}</a
                      >
                    </template>
                  </CTooltip>
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>
        </div>
      </template>
    </div>

    <PopupHotelPricesInfoByDay
      v-if="currDataByDay"
      :data="currDataByDay"
      @close="currDataByDay = null"
    />
  </template>
  <template v-else>Нет данных</template>
</template>

<script setup>
import { computed, ref } from 'vue'
import { formatCentsRounded } from '@/helpers/textFormatter'
import PopupHotelPricesInfoByDay from '@/components/_shared/hotel-prices/PopupHotelPricesInfoByDay.vue'

const props = defineProps({
  dayTariff: {
    type: Boolean,
    required: true,
  },
  categories: {
    type: Array,
    required: true,
  },
  programs: {
    type: Array,
    required: true,
  },
  booker: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  // cat, prog
  selectMode: {
    type: String,
    default: 'cat',
  },
})

const selectMode = ref(props.selectMode)
const currDataByDay = ref(null)

const dayNightText = computed(() => (props.dayTariff ? 'день' : 'ночь'))
const formatedData = computed(formatData)
const hasData = computed(
  () =>
    props.data?.requested != null &&
    Object.keys(props.data.requested).length > 0,
)

function getTooltipContent(obj, prop) {
  const min = obj.minMax[prop][0]
  const max = obj.minMax[prop][1]
  if (min === max) {
    return `цена за ${dayNightText.value} ${formatCentsRounded(min)}`
  } else {
    return `цена за ${dayNightText.value} от ${formatCentsRounded(
      min,
    )} до ${formatCentsRounded(max)}`
  }
}
function formatData() {
  const byCategory = {}
  const byProgram = {}
  const origData = hasData.value ? props.data.requested : {}

  for (const [resKey, resVal] of Object.entries(origData)) {
    const [catId, progId] = resKey.slice(1, -1).split(/[,\s]+/)
    const days = Object.keys(resVal)
    const pricesVal = Object.values(resVal)
    /** @type {{name?: string, main_places?: number}} */
    const categoryData = props.categories.find(({ id }) => id === Number(catId))
    byCategory[catId] = byCategory[catId] || {}
    byCategory[catId].name = categoryData?.name
    byCategory[catId].mainPlaces = categoryData?.main_places
    byCategory[catId].programs = byCategory[catId].programs || {}
    byCategory[catId].programs[progId] =
      byCategory[catId].programs[progId] || {}
    byCategory[catId].programs[progId].name = props.programs.find(
      (prog) => prog.id == progId,
    )?.title
    byCategory[catId].programs[progId].byDay = {}
    byCategory[catId].programs[progId].byDay.whole_room = []
    byCategory[catId].programs[progId].byDay.single_occupancy = []
    byCategory[catId].programs[progId].byDay.place = []
    byCategory[catId].programs[progId].byDay.extra_place = []
    byCategory[catId].programs[progId].total = pricesVal.reduce(
      (acc, item, i) => {
        byCategory[catId].programs[progId].byDay.whole_room.push([
          days[i],
          item.whole_room,
        ])
        byCategory[catId].programs[progId].byDay.single_occupancy.push([
          days[i],
          item.single_occupancy,
        ])
        byCategory[catId].programs[progId].byDay.extra_place.push([
          days[i],
          item.default?.extra_place,
        ])
        byCategory[catId].programs[progId].byDay.place.push([
          days[i],
          item.default?.place,
        ])
        return {
          whole_room: acc.whole_room + item.whole_room || 0,
          single_occupancy: acc.single_occupancy + item.single_occupancy || 0,
          place: acc.place + item.default?.place || 0,
          extra_place: acc.extra_place + item.default?.extra_place || 0,
        }
      },
      {
        whole_room: 0,
        single_occupancy: 0,
        place: 0,
        extra_place: 0,
      },
    )

    const pricesWR = byCategory[catId].programs[progId].byDay.whole_room.map(
      (item) => item[1],
    )
    const pricesSO = byCategory[catId].programs[
      progId
    ].byDay.single_occupancy.map((item) => item[1])
    const pricesP = byCategory[catId].programs[progId].byDay.place.map(
      (item) => item[1],
    )
    const pricesEP = byCategory[catId].programs[progId].byDay.extra_place.map(
      (item) => item[1],
    )
    byCategory[catId].programs[progId].minMax = {
      whole_room: [Math.min(...pricesWR), Math.max(...pricesWR)],
      single_occupancy: [Math.min(...pricesSO), Math.max(...pricesSO)],
      place: [Math.min(...pricesP), Math.max(...pricesP)],
      extra_place: [Math.min(...pricesEP), Math.max(...pricesEP)],
    }
    const byProgramCat = props.categories.find(({ id }) => id === Number(catId))
    byProgram[progId] = byProgram[progId] || {}
    byProgram[progId].name = props.programs.find(
      (prog) => prog.id == progId,
    )?.title
    byProgram[progId].categories = byProgram[progId].categories || {}
    byProgram[progId].categories[catId] =
      byProgram[progId].categories[catId] || {}
    byProgram[progId].categories[catId].name = byProgramCat?.name
    byProgram[progId].categories[catId].mainPlaces = byProgramCat?.main_places
    byProgram[progId].categories[catId].total =
      byCategory[catId].programs[progId].total
    byProgram[progId].categories[catId].byDay =
      byCategory[catId].programs[progId].byDay
    byProgram[progId].categories[catId].minMax =
      byCategory[catId].programs[progId].minMax
  }

  return { byCategory, byProgram }
}
</script>
