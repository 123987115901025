// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class IntegrationsOneCApiMethodsCls {
    async oneCAgencyInvoiceList(wh, extra_data = null) {
      // list за 31 последний день (для системы и для агентства)
      console.log('oneCAgencyInvoiceList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.agencyInvoice.list()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCAgencyInvoiceRangeList(wh, date_from, date_to, extra_data = null) {
      // выборка по временному диапазону (для системы и для агентства)
      console.log('oneCAgencyInvoiceRangeList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.agencyInvoice.range.list(date_from, date_to)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCAgencyInvoiceRetrieve(wh, id, extra_data = null) {
      // получение записи по ID (для системы и для агентства)
      console.log('oneCAgencyInvoiceRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.agencyInvoice.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCAgencyInvoiceWithFiltersList(wh, {sort, filters} = {}, extra_data = null) {
      // для экспорта "копии экрана" (для системы и для агентства)
      // filters: ids, company, contractor_entity, operator_identity, state, doc_number, doc_date,
      //          tour
      const params = { ...filters }
      if (sort) params.o = sort
      console.log('oneCAgencyInvoiceWithFiltersList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.agencyInvoiceWithFilters.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async oneCBuyerContractList(wh, extra_data = null) {
      // list за 31 последний день  (для агентства)
      console.log('oneCBuyerContractList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.buyerContract.list()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCBuyerContractRangeList(wh, date_from, date_to, extra_data = null) {
      // выборка по временному диапазону (для агентства)
      console.log('oneCBuyerContractRangeList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.buyerContract.range.list(date_from, date_to)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCBuyerContractRetrieve(wh, id, extra_data = null) {
      // получение записи по ID (для агентства)
      console.log('oneCBuyerContractRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.buyerContract.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCCommissionerReportList(wh, extra_data = null) {
      // список отчетов комиссионера (только для системы)
      console.log('oneCCommissionerReportList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.commissionerReport.list()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCCommissionerReportRangeList(wh, date_from, date_to, extra_data = null) {
      // выборка отчетов комиссионера по временному диапазону (для системы)
      console.log('oneCCommissionerReportRangeList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.commissionerReport.range.list(date_from, date_to)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCCommissionerReportRetrieve(wh, id, extra_data = null) {
      // получение отчета комиссионера по ID (только для системы)
      console.log('oneCCommissionerReportRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.commissionerReport.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCCommissionerReportWithFiltersList(wh, {sort, filters} = {}, extra_data = null) {
      // для экспорта отчетов комиссионера "копии экрана" (для системы и для агентства)
      // filters: date_fr, date_to, hotelier_contract, state, ids, location, principal, period,
      //          reward_type
      const params = { ...filters }
      if (sort) params.o = sort
      console.log('oneCCommissionerReportWithFiltersList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.commissionerReportWithFilters.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async oneCPaymentOrderList(wh, extra_data = null) {
      // list за 31 последний день  (для системы и для агентства)
      console.log('oneCPaymentOrderList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.paymentOrder.list()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCPaymentOrderRangeList(wh, date_from, date_to, extra_data = null) {
      // выборка по временному диапазону (для системы и для агентства)
      console.log('oneCPaymentOrderRangeList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.paymentOrder.range.list(date_from, date_to)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCPaymentOrderRetrieve(wh, id, extra_data = null) {
      // получение записи по ID (для системы и для агентства)
      console.log('oneCPaymentOrderRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.paymentOrder.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCPaymentOrderWithFiltersList(wh, {sort, filters} = {}, extra_data = null) {
      // для экспорта "копии экрана" (для системы и для агентства)
      // filters: id, ids, owner_entity, owner_bank_account, contractor_bank_account, amount, state,
      //          doc_number, doc_date
      const params = { ...filters }
      if (sort) params.o = sort
      console.log('oneCPaymentOrderWithFiltersList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.paymentOrderWithFilters.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async oneCSupplierInvoiceList(wh, extra_data = null) {
      // list за 31 последний день (только для системы)
      console.log('oneCSupplierInvoiceList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.supplierInvoice.list()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCSupplierInvoiceRangeList(wh, date_from, date_to, extra_data = null) {
      // выборка по временному диапазону (только для системы)
      console.log('oneCSupplierInvoiceRangeList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.supplierInvoice.range.list(date_from, date_to)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCSupplierInvoiceRetrieve(wh, id, extra_data = null) {
      // получение записи по ID (только для системы)
      console.log('oneCSupplierInvoiceRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.supplierInvoice.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async oneCSyncDelete(wh, object_id, object_type) {
      // отмена синхронизации  объекта системы и 1с
      // Создание и удаление синхронизации
      console.log('oneCSyncDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.integrations.oneC.sync.delete(object_id, object_type)})
      ctx.$services.storage.del('None', id, wh)
      return null
    }
    async oneCSyncCreate(wh, object_id, object_type, data, extra_data = null) {
      // data: OneCSyncCreate
      // синхронизация объекта системы и 1с - связать `data_type+id` и `uuid`
      // Создание и удаление синхронизации
      console.log('oneCSyncCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.oneC.sync.create(object_id, object_type), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.integrations_one_cApi = new IntegrationsOneCApiMethodsCls()
}

