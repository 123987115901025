import C from '@/config/back_const'

export const setAccessGuard = (app, router) => {
  const {
    $services: { auth },
  } = app
  router.beforeResolve(async (to, from, next) => {
    if (to.name === 'Login') {
      next()
      return
    }
    const [identity, company] = await Promise.all([
      auth.getIdentity(),
      auth.getCompany(),
    ])
    const accessState = {
      admin: C.ADMIN_ROLES.includes(identity?.role),
      agent: C.AGENT_ROLES.includes(identity?.role),
      accountant: identity?.role === C.ROLES_ACCOUNTANT,
      supervisor:
        C.AGENT_ROLES.includes(identity?.role) && identity?.is_supervisor,
      manager: identity?.role === C.ROLES_MANAGER,
      transferManager: identity?.role === C.ROLES_TRANSFER,
      owner: company?.role === C.ROLES_OWNER,
      system: company?.id === C.SYSTEM_COMPANY_ID,
      is_agency: company.is_agency,
      is_transfer: company.is_transfer,
    }
    const { access = [] } = to.meta
    const hasAccess =
      access.length === 0
        ? true
        : access.some((entry) => {
            if (typeof entry === 'string') {
              return accessState[entry]
            }
            if (Array.isArray(entry)) {
              return entry.every((item) => {
                return accessState[item]
              })
            }
          })
    if (to.path !== '/no_access/' && !hasAccess) {
      next({ path: '/no_access/' })
    } else next()
  })
}
