<template>
  <div :class="{ 'disabled-blured': contentDisabled }">
    <div>
      <CBadge color="info" class="me-2">
        {{ C.LEAD_STATUS_TITLES[lead?.status] }}
        <span
          v-if="
            lead?.status === C.LEAD_STATUS_POSTPONED && lead?.postponed_till
          "
          v-html="`до ${dateService.formatDateTime(lead.postponed_till)}`"
        ></span>
      </CBadge>
      <CBadge v-if="isClosed" color="warning">
        {{ C.LEAD_RESOLUTION_TITLES[lead?.result] }}
      </CBadge>
    </div>
    <h2 class="my-3">Лид №{{ id }}</h2>
    <div class="pb-3">
      <div v-if="lead?.id" class="flex flex-column mt-3" :style="styleWidth">
        <LeadButtons
          :disabled="v$.$errors.length > 0"
          :lead="lead"
          @action="onButtonCommon"
        />
        <CRow class="my-5">
          <CCol :sm="12" :md="6">
            <div id="lead-left-col-inn">
              <CCol class="mb-4">
                <CCard>
                  <CCardHeader>
                    <h3 class="mb-0">Источник</h3>
                  </CCardHeader>
                  <CCardBody>
                    <div class="flex flex-column gap-2">
                      <div
                        v-if="lead?.source_ip || lead?.raw_data?.source_ip"
                        class="flex gap-2"
                      >
                        <span>IP:</span>
                        <span class="fw-medium">{{
                          lead.source_ip || lead?.raw_data?.source_ip
                        }}</span>
                      </div>
                      <div
                        v-if="lead.origin"
                        class="flex gap-2 align-items-end"
                      >
                        <span>Источник заявки:</span>
                        <div
                          v-if="isEditable && canChangeSource"
                          class="flex flex-wrap flex-grow-1 gap-2"
                        >
                          <CFormSelect
                            v-model.number="lead.origin"
                            size="sm"
                            class="w-auto"
                          >
                            <!-- api изменения source -> origin, констаны ещё не изменены -->
                            <option
                              v-for="(val, key) in C.LEAD_SOURCE_TITLES"
                              :key="key"
                              :value="key"
                            >
                              {{ val }}
                            </option>
                          </CFormSelect>
                          <Autocomplete
                            v-if="lead.origin === C.LEAD_SOURCE_RECOMMENDATION"
                            id="lead.adviser"
                            v-model="leadAdviserAC"
                            :search-area="[
                              {
                                content_type: 'account__identity',
                                filters: [
                                  { name: 'role', value: C.ROLES_CUSTOMER },
                                ],
                              },
                            ]"
                            placeholder="Имя заказчика"
                            size="sm"
                            class="w-auto flex-grow-1"
                          />
                        </div>
                        <div v-else class="flex flex-wrap gap-2">
                          <span class="fw-medium">{{ leadOrigin }}</span>
                          <span
                            v-if="
                              lead.origin === C.LEAD_SOURCE_RECOMMENDATION &&
                              lead.adviser &&
                              leadAdviserAC?.generic_title
                            "
                          >
                            {{ leadAdviserAC.generic_title }}
                          </span>
                        </div>
                      </div>
                      <div v-if="lead.url" class="flex flex-column gap-2">
                        <div v-if="rawData.mainTitle">
                          <span>Сайт: </span>
                          <span class="fw-medium">{{ rawData.mainTitle }}</span>
                        </div>
                        <div v-if="rawData.title">
                          <span>Страница: </span>
                          <span v-if="rawData.title" class="fw-medium">{{
                            rawData.title
                          }}</span>
                        </div>
                        <div class="flex gap-2">
                          <span>URL:</span>
                          <a
                            :href="lead.url"
                            target="_blank"
                            class="d-inline-block text-nowrap overflow-hidden w-100 text-truncate"
                            >{{ lead.url }}</a
                          >
                        </div>
                      </div>
                      <div v-if="lead.form_id" class="flex gap-2">
                        <span>Форма:</span>
                        <span class="fw-medium">{{ lead.form_id }}</span>
                      </div>
                      <div v-if="lead.raw_data" class="flex gap-1 flex-column">
                        <div v-if="rawData.division" class="flex gap-1">
                          <span
                            >ID источника:
                            <span class="fw-medium">{{
                              rawData.division
                            }}</span></span
                          >
                        </div>
                        <div
                          v-if="rawData.historyFirst"
                          :class="{
                            'flex gap-1':
                              !rawData.historyFirst.title ||
                              !rawData.historyFirst.url,
                          }"
                        >
                          <span>Переход: </span>
                          <span
                            v-if="
                              rawData.historyFirst.url &&
                              rawData.historyFirst.title
                            "
                            class="fw-medium"
                            >{{ rawData.historyFirst.title }}</span
                          >
                          <div class="text-truncate">
                            <template v-if="rawData.historyFirst.url">
                              <a :href="rawData.historyFirst.url">{{
                                rawData.historyFirst.url
                              }}</a>
                            </template>
                            <span v-else class="fw-medium">по прямому URL</span>
                          </div>
                        </div>
                        <div
                          v-if="rawData.historyEntry.url"
                          :class="{ 'flex gap-1': !rawData.historyEntry.title }"
                        >
                          <span>Страница входа: </span>
                          <span
                            v-if="rawData.historyEntry.title"
                            class="fw-medium"
                            >{{ rawData.historyEntry.title }}</span
                          >
                          <div class="text-truncate">
                            <a :href="rawData.historyEntry.url">{{
                              rawData.historyEntry.url
                            }}</a>
                          </div>
                        </div>
                        <div v-if="rawData.GA" class="flex gap-1">
                          <span
                            >GA Client ID: <b>{{ rawData.GA }}</b></span
                          >
                        </div>
                        <div v-if="rawData.YM" class="flex gap-1">
                          <span
                            >YM Client ID: <b>{{ rawData.YM }}</b></span
                          >
                        </div>
                        <div
                          v-if="getSearchEngineName(rawData.historyFirst)"
                          class="flex gap-1"
                        >
                          <span
                            >Поисковая система:
                            <b>{{
                              getSearchEngineName(
                                lead.raw_data?.analytics?.history[0],
                              )
                            }}</b></span
                          >
                        </div>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol class="mb-4">
                <CCard>
                  <CCardHeader>
                    <h3 class="mb-0">Заселение</h3>
                  </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <div class="col-12 col-md-6 mb-1">
                        <CFormLabel> Город </CFormLabel>
                        <Autocomplete
                          v-model="leadCityAC"
                          :search-area="[
                            {
                              content_type: 'refbook__location',
                              filters: [
                                {
                                  name: 'addr_type',
                                  value: C.LOCATION_ADDR_TYPE_CITY,
                                },
                              ],
                            },
                          ]"
                          :disabled="!isEditable"
                          class="static-text-disabled"
                          size="sm"
                        />
                      </div>
                      <div class="col-12 col-md-6 mb-1">
                        <div class="flex gap-3">
                          <CFormLabel>Отель</CFormLabel>
                          <a
                            v-if="leadHotelAC"
                            href=""
                            @click.prevent="showHotelInfoModal = true"
                            class="form-label-dotted"
                            >Информация об отеле</a
                          >
                        </div>
                        <Autocomplete
                          v-model="leadHotelAC"
                          :search-area="[
                            {
                              content_type: 'refbook__hotel',
                              filters: leadCityAC?.id
                                ? [{ name: 'parent', value: leadCityAC.id }]
                                : [],
                            },
                          ]"
                          @click="onClickHotelAC"
                          :disabled="!isEditable"
                          class="static-text-disabled"
                          size="sm"
                        />
                      </div>
                      <div class="col-12 col-md-6 mb-1">
                        <CFormLabel> Номер </CFormLabel>
                        <!-- todo lead.room поменяется на lead.category -->
                        <CFormSelect
                          v-if="isEditable"
                          v-model="lead.room"
                          :disabled="!leadHotelCategories"
                          class="static-text-disabled"
                          size="sm"
                        >
                          <option value="">Выберите номер</option>
                          <template v-if="leadHotelCategories?.data?.length">
                            <option
                              v-for="category in leadHotelCategories.data"
                              :key="category.id"
                              :value="category.id"
                            >
                              {{ category.name }}
                            </option>
                          </template>
                        </CFormSelect>
                        <!-- todo lead.room поменяется на lead.category -->
                        <CStaticText
                          v-else
                          size="sm"
                          :text="
                            leadHotelCategories?.data?.length
                              ? leadHotelCategories.data.find(
                                  (cat) => cat.id === lead.room,
                                )?.name
                              : ''
                          "
                        />
                      </div>
                      <div class="col-12 col-md-6 mb-1">
                        <CFormLabel>Дата заезда</CFormLabel>
                        <Datepicker
                          id="filters.created_at"
                          v-model="leadCheckinDate"
                          label="Заезд"
                          placeholder="Выберите дату"
                          locale="ru-RU"
                          format="dd.MM.yyyy"
                          :enable-time-picker="false"
                          size="sm"
                          :range="true"
                          :multi-calendars="true"
                          :week-start="1"
                          :min-date="Date.now()"
                          cancel-text="Отменить"
                          select-text="Выбрать"
                          :disabled="!isEditable"
                        />
                      </div>
                      <div class="col-12 col-md-6 mb-1">
                        <CFormLabel> Срок проживания (дней) </CFormLabel>
                        <div class="d-flex align-items-start">
                          <div class="me-2">
                            <CFormInput
                              id="days_fr"
                              type="number"
                              min="1"
                              v-model.number="lead.days_fr"
                              :invalid="v$.lead.days_fr.$error"
                              @change="controlDaysChange"
                              placeholder="От"
                              size="sm"
                              :disabled="!isEditable"
                              class="static-text-disabled"
                            />
                            <CFormFeedback invalid>
                              Введите число от 1
                            </CFormFeedback>
                          </div>
                          <div>
                            <CFormInput
                              id="days_to"
                              type="number"
                              min="1"
                              v-model.number="lead.days_to"
                              :invalid="v$.lead.days_to.$error"
                              @change="controlDaysChange"
                              placeholder="До"
                              size="sm"
                              :disabled="!isEditable"
                              class="static-text-disabled"
                            />
                            <CFormFeedback invalid>
                              Введите число от {{ lead.days_fr || 1 }}
                            </CFormFeedback>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 mb-1">
                        <div
                          v-if="
                            leadHotelAC && lead.check_in_fr && lead.check_in_to
                          "
                          class="h-100 flex justify-content-end align-items-end gap-3"
                        >
                          <CButton
                            color="primary"
                            @click="showPlacesModal = true"
                            size="sm"
                            :disabled="hotelPlacesDisabled"
                            >Наличие мест</CButton
                          >
                          <CButton
                            color="primary"
                            @click="showPricesModal = true"
                            size="sm"
                            >Цены</CButton
                          >
                        </div>
                      </div>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol class="mb-4">
                <CCard>
                  <CCardHeader>
                    <h3 class="mb-0">Туристы</h3>
                  </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <div class="col-12 col-md-6 mb-1">
                        <CFormLabel> Количество взрослых </CFormLabel>
                        <CFormInput
                          type="number"
                          min="0"
                          max="1000"
                          v-model="lead.number_of_adults"
                          :invalid="v$.lead.number_of_adults.$error"
                          @input="vLeadSetDirty('number_of_adults')"
                          placeholder=""
                          size="sm"
                          class="static-text-disabled"
                          :disabled="!isEditable"
                          @update:model-value="
                            controlTouristsChange(
                              lead.number_of_adults,
                              'number_of_children',
                            )
                          "
                        />
                        <CFormFeedback invalid>
                          Введите число от 0 до 1000
                        </CFormFeedback>
                      </div>
                      <div class="col-12 col-md-6 mb-1">
                        <CFormLabel> Количество детей </CFormLabel>
                        <CFormInput
                          type="number"
                          min="0"
                          max="1000"
                          v-model="lead.number_of_children"
                          :invalid="v$.lead.number_of_children.$error"
                          @input="vLeadSetDirty('number_of_children')"
                          placeholder=""
                          size="sm"
                          class="static-text-disabled"
                          :disabled="!isEditable"
                          @update:model-value="
                            controlTouristsChange(
                              lead.number_of_children,
                              'number_of_adults',
                            )
                          "
                        />
                        <CFormFeedback invalid>
                          Введите число от 0 до 1000
                        </CFormFeedback>
                      </div>
                      <div class="col-12 col-md-6 mb-1">
                        <div>
                          <CFormLabel> Программа пребывания </CFormLabel>
                          <CFormSelect
                            v-if="isEditable"
                            v-model="lead.program"
                            :disabled="!leadHotelPrograms"
                            class="static-text-disabled"
                            size="sm"
                          >
                            <option value="">
                              Выберите программу пребывания
                            </option>
                            <template v-if="leadHotelPrograms?.data?.length">
                              <option
                                v-for="program in leadHotelPrograms.data"
                                :key="program.id"
                                :value="program.id"
                              >
                                {{ program.title }}
                              </option>
                            </template>
                          </CFormSelect>
                          <CStaticText
                            v-else
                            size="sm"
                            :text="
                              leadHotelPrograms?.data?.length
                                ? leadHotelPrograms.data.find(
                                    (pr) => pr.id === lead.program,
                                  )?.title
                                : ''
                            "
                          />
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-6 mb-1 position-relative multiselect-sm"
                      >
                        <CFormLabel> Лечение заболеваний </CFormLabel>
                        <Multiselect
                          v-if="diseases?.data?.length"
                          v-model="selectedDiseases"
                          track-by="id"
                          label="title"
                          :options="diseases.data"
                          :disabled="!isEditable"
                          :multiple="true"
                          placeholder="Выберите лечение"
                          select-group-label="выбрано"
                          selected-label="выбрано"
                          select-label=""
                          deselect-label=""
                          :max-height="250"
                          :option-height="26"
                          :options-limit="1000"
                          :close-on-select="true"
                          @select="addLeadDiseases"
                          @remove="rmLeadDiseases"
                        >
                          <template #noResult>Ничего не найдено</template>
                          <template #noOptions>Введите название</template>
                        </Multiselect>
                      </div>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol class="mb-4">
                <CCard>
                  <CCardHeader>
                    <div
                      class="flex justify-content-between align-items-center"
                    >
                      <h3 class="mb-0">Организационное</h3>
                      <div>
                        <CButton
                          v-if="isEditable"
                          color="primary"
                          @click="onButtonCommon('hand_over')"
                          :disabled="v$.$errors.length > 0"
                          size="sm"
                          >Передать</CButton
                        >
                      </div>
                    </div>
                  </CCardHeader>
                  <CCardBody>
                    <div class="flex flex-column gap-2">
                      <div v-if="lead.created_at" class="flex gap-2">
                        <span>Дата оформления:</span>
                        <b
                          v-html="dateService.formatDateTime(lead.created_at)"
                        />
                      </div>
                      <div
                        class="flex gap-2"
                        v-if="(inWork || isClosed) && lead.taken_at"
                      >
                        <span>Взята в работу:</span>
                        <b v-html="dateService.formatDateTime(lead.taken_at)" />
                      </div>
                      <div class="flex gap-2" v-if="isClosed && lead.closed_at">
                        <span>Закрыта:</span>
                        <b
                          v-html="dateService.formatDateTime(lead.closed_at)"
                        />
                      </div>
                      <div v-if="lead.agent" class="flex gap-2">
                        <span>Агент:</span>
                        <router-link :to="`/profile/${lead.agent}/`">
                          <small>(#{{ lead.agent }})&nbsp;&nbsp;</small>
                          <span>{{ getFullName(agentUser) }}</span>
                        </router-link>
                      </div>
                      <div v-if="lead.initiator" class="flex gap-2">
                        <span>Создатель лида:</span>
                        <router-link :to="`/profile/${lead.initiator}/`">
                          <small>(#{{ lead.initiator }})</small>
                          <span v-if="getFullName(initiatorUser)"
                            >&nbsp;&nbsp;{{ getFullName(initiatorUser) }}</span
                          >
                        </router-link>
                      </div>
                      <div v-if="tourList.length" class="tours flex gap-2">
                        <span
                          >{{ tourList.length === 1 ? 'Тур' : 'Туры' }}:</span
                        >
                        <div class="" v-for="tour in tourList" :key="tour">
                          <router-link
                            :to="`/tour/${tour}/`"
                            class="d-block me-2"
                          >
                            {{ 'Тур #' + tour }}
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol v-if="lead?.id" class="mb-4">
                <CCard>
                  <CCardHeader>
                    <div
                      class="flex justify-content-between align-items-center"
                    >
                      <h3 class="mb-0">Звонки лида</h3>
                    </div>
                  </CCardHeader>
                  <CCardBody>
                    <div class="flex flex-column gap-2">
                      <ContainerTelecallForEntity
                        type-entity="core__lead"
                        :id="lead?.id"
                        wh="128a5496-f8b2-11ee-a951-0242ac120002"
                      />
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol v-if="chat" class="mb-4">
                <CCard>
                  <CCardHeader>
                    <div
                      class="flex justify-content-between align-items-center"
                    >
                      <h3 class="mb-0">Сообщения в чате</h3>
                      <div v-if="chat.participants?.includes(myIdentity?.id)">
                        <CButton
                          color="primary"
                          @click="store.commit('toggleAside', true)"
                          size="sm"
                        >
                          Открыть чат
                        </CButton>
                      </div>
                    </div>
                  </CCardHeader>
                  <CCardBody>
                    <div v-if="loadingChat" class="pb-3">
                      <span class="me-3">получаем cообщения ...</span>
                      <CSpinner
                        component="span"
                        size="sm"
                        aria-hidden="true"
                        class="me-3"
                      />
                    </div>
                    <div
                      v-for="message in messages"
                      :key="message.id"
                      class="flex flex-column gap-1 my-2"
                    >
                      {{
                        ((templateVarSender = getFullName(
                          chatRelUsers[chatRelIdens[message.sender]?.user],
                        )),
                        null)
                      }}
                      <div class="flex justify-content-between">
                        <span>{{ templateVarSender || 'Анонимный' }}</span>
                        <small
                          v-html="
                            dateService.formatDateTime(message.created_at)
                          "
                        />
                      </div>
                      <span class="form-label">{{ message.content }}</span>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
            </div>
          </CCol>
          <CCol :sm="12" :md="6">
            <div id="lead-right-col-inn">
              <CCol class="mb-4">
                <CCard>
                  <CCardHeader>
                    <div
                      class="flex justify-content-between align-items-center"
                    >
                      <h3 class="mb-0">Заказчик</h3>
                      <div>
                        <CButton
                          v-if="isEditable"
                          color="primary"
                          @click="showCustomerModal = true"
                          size="sm"
                        >
                          {{
                            lead.customer
                              ? 'Выбрать другого заказчика'
                              : 'выбрать заказчика'
                          }}
                        </CButton>
                      </div>
                    </div>
                  </CCardHeader>
                  <CCardBody>
                    <div class="row">
                      <div class="mb-3">
                        <div
                          v-if="lead.customer"
                          class="flex justify-content-between align-items-end mt-1"
                        >
                          <a
                            v-if="isEditable"
                            href=""
                            @click.prevent="showCustomerEdit"
                            class="dotted-underline"
                            >{{ `(#${lead.customer}) ${customerName}` }}</a
                          >
                          <span v-else class="d-block">{{ customerName }}</span>
                        </div>
                      </div>
                      <div class="col-md-4 mb-1">
                        <CFormLabel>Фамилия</CFormLabel>
                        <CFormInput
                          v-model.trim="lead.surname"
                          :invalid="v$.lead.surname.$error"
                          @input="vLeadSetDirty('surname')"
                          :disabled="
                            !isEditable ||
                            (lead.customer && !editCustomerInLead)
                          "
                          class="static-text-disabled"
                          size="sm"
                        />
                        <CFormFeedback invalid>
                          Недопустимые символы
                        </CFormFeedback>
                      </div>
                      <div class="col-md-4 mb-1">
                        <CFormLabel>Имя</CFormLabel>
                        <CFormInput
                          v-model.trim="lead.name"
                          :invalid="v$.lead.name.$error"
                          @input="vLeadSetDirty('name')"
                          :disabled="
                            !isEditable ||
                            (lead.customer && !editCustomerInLead)
                          "
                          class="static-text-disabled"
                          size="sm"
                        />
                        <CFormFeedback invalid>
                          {{ v$.lead.name.$silentErrors[0]?.$message }}
                        </CFormFeedback>
                      </div>
                      <div class="col-md-4 mb-1">
                        <CFormLabel>Отчество</CFormLabel>
                        <CFormInput
                          v-model.trim="lead.patronymic"
                          :invalid="v$.lead.patronymic.$error"
                          @input="vLeadSetDirty('patronymic')"
                          :disabled="
                            !isEditable ||
                            (lead.customer && !editCustomerInLead)
                          "
                          class="static-text-disabled"
                          size="sm"
                        />
                        <CFormFeedback invalid>
                          Недопустимые символы
                        </CFormFeedback>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div>
                        <PhonesEdit
                          v-if="
                            (isEditable && !lead.customer) ||
                            (isEditable && lead.customer && editCustomerInLead)
                          "
                          class="mb-4"
                          id="lead.phones"
                          :object-id="lead.id"
                          object-type="core__lead"
                          @complete-action="onPhonesCompleteAction"
                        />
                        <div v-else-if="lead.phones?.length" class="row mb-3">
                          <div
                            class="col-md-4 mb-2"
                            v-for="phone in lead.phones"
                            :key="phone"
                          >
                            <div class="flex align-items-end">
                              <div class="flex-grow-1">
                                <CTooltip
                                  content="Двойной клик для поиска"
                                  placement="top"
                                >
                                  <template #toggler="{ on }">
                                    <CStaticText
                                      class="no-right-border-round"
                                      v-on="on"
                                      size="sm"
                                      :text="phone"
                                      @dblclick="
                                        () => {
                                          onSearchLeadsTours(phone)
                                        }
                                      "
                                      role="button"
                                    ></CStaticText>
                                  </template>
                                </CTooltip>
                              </div>
                              <PhoneButton
                                :phone="phone"
                                classes="flex-shrink-1 no-margin-btn no-left-border-round btn-fix-sm-size"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ContactsEdit
                          v-if="
                            (isEditable && !lead.customer) ||
                            (isEditable && lead.customer && editCustomerInLead)
                          "
                          id="lead.contacts"
                          :object-id="lead.id"
                          object-type="core__lead"
                          :company-id="lead.company_owner"
                          @complete-action="onContactsCompleteAction"
                        />
                        <div v-else-if="lead.contacts?.length" class="row">
                          <div
                            class="col-md-4 mb-2"
                            v-for="contact in lead.contacts.map((cStr) =>
                              convertContactStrToObj(cStr),
                            )"
                            :key="contact.id"
                          >
                            <CFormLabel>{{
                              C.CONTACT_TYPE_TITLES[contact.type]
                            }}</CFormLabel>
                            <CStaticText
                              size="sm"
                              :text="contact.number"
                            ></CStaticText>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="lead.customer && isEditable" class="flex">
                      <CFormCheck
                        id="editCustomerInLead"
                        v-model="editCustomerInLead"
                        label="Редактировать исходные контактные данные лида"
                      />
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol class="mb-4">
                <CCard>
                  <CCardHeader>
                    <h3 class="mb-0">Подробности</h3>
                  </CCardHeader>
                  <CCardBody>
                    <div>
                      <b class="mb-1">Текст заявки</b>
                      <CFormTextarea
                        v-if="isEditable"
                        v-model="lead.text"
                        :rows="lead.text ? 6 : 3"
                      />
                      <CStaticText
                        v-else
                        size="sm"
                        :text="lead.text"
                      ></CStaticText>
                    </div>
                    <ObjectComments
                      :key="lead.updated_at"
                      :obj="lead"
                      el-id="lead-comments-wrap"
                      title="Комментарии"
                      :show-button="
                        (lead.actions && lead.actions.includes('comment')) ||
                        lead.status === C.LEAD_STATUS_DRAFT
                      "
                      @prepared="onCommentsPrepared"
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </div>
          </CCol>
        </CRow>
        <LeadButtons
          :disabled="v$.$errors.length > 0"
          :lead="lead"
          @action="onButtonCommon"
        />
      </div>
    </div>

    <PopupSelectOrCreateCustomer
      v-if="showCustomerModal"
      :init-data="lead"
      @select="onSelect"
      @show-create-person="showCreatePerson"
      @close="onCloseBuyerModal"
    />
    <PopupPersonContainer v-if="showPersonModal" :data="popupPersonData" />
    <PopupHotelInfo
      v-if="showHotelInfoModal"
      :hotel-id="lead.hotel"
      @closed="showHotelInfoModal = false"
    />
    <PopupLeadHandOver
      v-if="showHandOverModal"
      :id="lead.id"
      @close="showHandOverModal = false"
      @complete="onHandOverComplete"
    />
    <PopupLeadCancel
      v-if="showCancelModal"
      :ip="lead.source_ip"
      @close="showCancelModal = false"
      @complete="onCancelComplete"
    />
    <PopupHotelPlacesInfo
      v-if="showPlacesModal && leadHotelAC?.id"
      :data-req="placesInfoDataReq"
      :close="closeHotelPlacesModal"
    />
    <PopupHotelPricesInfo
      v-if="showPricesModal && leadHotelAC?.id"
      :data="pricesInfoDataReq"
      :modal-title="leadHotel?.title"
      @close="showPricesModal = false"
    />
    <PopupLeadUnsaved
      v-if="showUnsavedModal"
      :unsaved="unsaved"
      @close="onCloseUnsavedModal"
      @save="onChangesSave"
      @continue="onChangesContinue"
    />
  </div>
  <PopupLeadMissed
    v-if="showPopupLeadMissed"
    @close="showPopupLeadMissed = false"
  />
  <ThePodval
    title="Просмотр и редактирование лида"
    :tasks="[
      'https://tracker.yandex.ru/BACK-161',
      'https://tracker.yandex.ru/BACK-1075',
      'https://tracker.yandex.ru/BACK-1137',
      'https://tracker.yandex.ru/BACK-1136',
      'https://tracker.yandex.ru/BACK-2111',
      'https://tracker.yandex.ru/BACK-2152',
      'https://tracker.yandex.ru/BACK-2386',
      'https://tracker.yandex.ru/BACK-2572',
      'https://tracker.yandex.ru/BACK-2686',
    ]"
    :wiki="[
      'https://wiki.yandex.ru//homepage/proekt/arxitektura-proekta/lidy-tury/li/',
    ]"
    :uuid="WH"
  />
</template>

<script setup>
import {
  computed,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
  onBeforeMount,
} from 'vue'
import C from '@/config/back_const'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import ContainerTelecallForEntity from '@/components/custom/ContainerTelecallForEntity.vue'
import Autocomplete from '@/components/custom/Autocomplete.vue'
import PhonesEdit from '@/components/custom/PhonesEdit/PhonesEdit.vue'
import ContactsEdit from '@/components/_custom/contacts-edit/ContactsEdit.vue'
import CStaticText from '@/components/_common/CStaticText.vue'
import PopupSelectOrCreateCustomer from '@/components/popups/PopupSelectOrCreateCustomer.vue'
import PopupPersonContainer from '@/components/popups/PopupPerson/PopupPersonContainer.vue'
import ObjectComments from '@/components/custom/ObjectComments.vue'
import PopupHotelInfo from '@/components/_hotel/PopupHotelInfo/PopupHotelInfo.vue'
import LeadButtons from '@/components/_leads/LeadButtons.vue'
import PopupLeadHandOver from '@/components/_leads/PopupLeadHandOver.vue'
import PopupHotelPricesInfo from '@/components/_shared/hotel-prices/PopupHotelPricesInfo.vue'
import PopupHotelPlacesInfo from '@/components/_shared/hotel-places/PopupHotelPlacesInfo.vue'
import PopupLeadCancel from '@/components/_leads/PopupLeadCancel.vue'
import useVuelidate from '@vuelidate/core'
import { required, minValue, between } from '@vuelidate/validators'
import { names } from '@/helpers/vValidators'
import PopupLeadUnsaved from '@/components/_leads/PopupLeadUnsaved.vue'
import PopupLeadMissed from '@/components/_leads/PopupLeadMissed.vue'
import Multiselect from 'vue-multiselect'
import { debounce } from 'lodash'
import { useUpdateWatcher } from '@/composables/updateWatcher'
import { useObjectChatListen } from '@/composables/objectChatListen'
import { useAuthData } from '@/composables/authData'
import { entityViewMode } from '@/helpers/entityView'
import { convertContactStrToObj, getFullName } from '@/helpers'
import PhoneButton from '@/components/_custom/phone-button/PhoneButton.vue'
import ThePodval from '@/components/_shared/ThePodval.vue'

const props = defineProps({
  id: {
    type: [Number, String],
    required: true,
  },
})
const emit = defineEmits(['exit'])
const WH = '56d386d9-a90c-4728-8c02-5f782b1e100b'
const {
  storage,
  dateService,
  leadApi,
  tourApi,
  identityApi,
  userApi,
  personApi,
  hotelApi,
  programApi,
  categoryApi,
  diseaseApi,
  notification,
  genericApi,
  messageApi,
  websocket,
  buyerApi,
} = inject('services')

const bus = inject('bus')

const store = useStore()
const router = useRouter()
const contentDisabled = ref(false)
const dataReady = ref(false)
const initializedWithHotel = ref(false)
const canControlTouristsChange = ref(false)

const withoutMessSaveWS = ref(false)
const lead = ref({})
const leadSnapshot = ref({})
const { myIdentity, isAgent } = useAuthData()
const { watchUpdate } = useUpdateWatcher(() => {
  setTimeout(() => {
    // timeout установлен для временного обхода бага https://tracker.yandex.ru/BACK-2883
    if (
      isAgent.value &&
      (lead.value?.status === C.LEAD_STATUS_NEW ||
        (lead.value?.agent && lead.value?.agent !== myIdentity.value?.id))
    ) {
      notification.info('Лид ушёл')
      contentDisabled.value = true
      showPopupLeadMissed.value = true
    } else if (!withoutMessSaveWS.value) {
      notification.info('Данные лида обновлены')
      withoutMessSaveWS.value = true
      init(props.id)
      return
    }
    withoutMessSaveWS.value = false
  }, 1500)
})
const {
  setChatListen,
  messages,
  chatRelIdens,
  chatRelUsers,
  loadingChat,
  chat,
} = useObjectChatListen({
  WH,
  messageApi,
  identityApi,
  userApi,
  storage,
  websocket,
})

const showCustomerModal = ref(false)
const showPersonModal = ref(false)
const showHotelInfoModal = ref(false)
const showHandOverModal = ref(false)
const showCancelModal = ref(false)
const showPlacesModal = ref(false)
const showPricesModal = ref(false)
const showUnsavedModal = ref(false)
const showPopupLeadMissed = ref(false)
const editCustomerInLead = ref(false)
const hotelPlacesDisabled = ref(false)

const relUsers = ref({})
const relPersons = ref({})
const relIdentities = ref({})

const popupPersonData = computed(() => {
  const type = editPersonId.value ? null : entityViewMode.CREATE
  return {
    type,
    data: {
      haveModeCheckbox: false,
      showPersonModal: showPersonModal.value,
      personId: editPersonId.value,
      initData: customerFormData.value,
      initDataOwnerType: 'lead',
      ownerId: newOwnerId.value,
      editTitle: 'Редактирование заказчика',
      update: onCreateUpdatePerson,
      close: onClosePersonModal,
      cancel: removeCustomer,
    },
  }
})

const inWork = computed(
  () =>
    lead.value.status === C.LEAD_STATUS_TAKEN ||
    lead.value.status === C.LEAD_STATUS_POSTPONED,
)
const isClosed = computed(() => lead.value.status === C.LEAD_STATUS_ARCHIVED)
const isLeadAgent = computed(
  () => lead.value.agent && lead.value.agent === myIdentity.value.id,
)
const isEditable = computed(() => {
  return (
    (inWork.value && isLeadAgent.value) ||
    (lead.value.status === C.LEAD_STATUS_DRAFT &&
      lead.value.initiator === myIdentity.value.id)
  )
})
const canChangeSource = ref(false) // источник можно менять только если он "вручную" и изменения ещё не сохранены

const leadCityAC = ref(null)
const leadHotelAC = ref(null)
const leadAdviserAC = ref(null)
const leadCheckinDate = ref(null)
const customerName = ref('')
const editPersonId = ref(null)

const leadHotel = ref(null)
const leadHotelPrograms = ref(null)
const leadHotelCategories = ref(null)
const diseases = ref(null)
const selectedDiseases = ref(null)
const newCustomer = ref(null)
const newOwnerId = ref(null)
const customerFormData = ref(null)
const agentUser = ref(null)
const initiatorUser = ref(null)
const routerContinueNoCheck = ref(false)

const tourList = ref([])

const pricesInfoDataReq = computed(() => {
  const dataReq = {
    hotel: lead.value.hotel,
    // Не передаём категории и программы чтобы показывать все https://tracker.yandex.ru/BACK-3660
    // category: lead.value.category || lead.value.room, // todo lead.room поменяется на lead.category
    // program: lead.value.program,
    date_from: lead.value.check_in_fr,
    date_to: lead.value.check_in_to,
    days_to: lead.value.days_to,
  }
  for (const [key, val] of Object.entries(dataReq)) {
    if (!val) delete dataReq[key]
  }
  return dataReq
})
const placesInfoDataReq = computed(() => {
  const dataReq = {
    hotel: lead.value.hotel,
    hotelTitle: leadHotel.value?.title,
    // Не передаём категорию чтобы показать все https://tracker.yandex.ru/BACK-4491
    // category: lead.value.category || lead.value.room, // todo lead.room поменяется на lead.category
    date_fr: lead.value.check_in_fr,
    date_to: lead.value.check_in_to,
    days_to: lead.value.days_to,
  }
  for (const [key, val] of Object.entries(dataReq)) {
    if (!val) delete dataReq[key]
  }
  return dataReq
})

const styleWidth = ref('')
const checkFields = [
  { key: 'location', title: 'Город' },
  { key: 'hotel', title: 'Отель' },
  { key: 'room', title: 'Номер' }, // todo lead.room поменяется на lead.category
  { key: 'category', title: 'Номер' },
  { key: 'check_in_fr', title: 'Дата заезда от' },
  { key: 'check_in_to', title: 'Дата заезда до' },
  { key: 'days_fr', title: 'Срок проживания от' },
  { key: 'days_to', title: 'Срок проживания до' },
  { key: 'name', title: 'Имя заказчика' },
  { key: 'surname', title: 'Фамилия заказчика' },
  { key: 'patronymic', title: 'Отчество заказчика' },
  { key: 'customer', title: 'Заказчик' },
  { key: 'number_of_adults', title: 'Количество взрослых' },
  { key: 'number_of_children', title: 'Количество детей' },
  { key: 'program', title: 'Программа пребывания' },
  { key: 'diseases', title: 'Лечение заболеваний' },
  { key: 'origin', title: 'Источник' },
  { key: 'adviser', title: 'По чьей рекомендации' },
]
const unsaved = ref([])
let currentActionFn = null
let saveApproveFn = null
let onResize = null
let resizeObserver = null

// валидация
const vRules = computed(() => {
  return {
    lead: {
      days_to: {
        required,
        minValue: minValue(lead.value.days_fr),
      },
      days_fr: {
        required,
        minValue: minValue(1),
      },
      name: { names },
      surname: { names },
      patronymic: { names },
      number_of_adults: {
        required,
        between: between(0, 1000),
      },
      number_of_children: {
        required,
        between: between(0, 1000),
      },
    },
  }
})
const v$ = useVuelidate(vRules, { lead }, { $lazy: true })
const vLeadSetDirty = (prop) => {
  if (!v$.value.lead[prop].$dirty) v$.value.lead[prop].$dirty = true
}

const getUserFromIden = async (identityId) => {
  const iden =
    relIdentities.value[identityId] ||
    (await identityApi.identityRetrieve(WH, identityId))
  const user =
    iden && iden.user
      ? relUsers.value[iden.user] || (await userApi.userRetrieve(WH, iden.user))
      : null
  return user
}
const getCustomerPerson = async (customerId) => {
  if (!customerId) return
  const customer = await identityApi.identityRetrieve(WH, customerId)
  if (!customer.person) return null
  return await personApi.personRetrieve(WH, customer.person)
}
const controlDaysChange = (ev) => {
  const newVal = ev.target.value ? Number(ev.target.value) : 1
  const to = Number(lead.value.days_to) || 1
  const fr = Number(lead.value.days_fr) || to
  if (ev.target.id === 'days_fr') {
    lead.value.days_to = newVal > to ? newVal : to
  } else {
    lead.value.days_fr = newVal < fr ? newVal : fr
  }
}
const controlTouristsChange = (val, controlProp) => {
  if (initializedWithHotel.value && !canControlTouristsChange.value) {
    // смена отеля вызывает эту функцию, но при открытии страницы устанавливается отель и это идет как смена отеля updateHotelCityCategProg
    // и получается что может поменяться поле с кол-м туристов и будут несохраненные изменения даже если открыли лид на просмотр.
    // поэтому при инициализации с отелем не выполняем
    canControlTouristsChange.value = true
    return
  }
  const change = Number(val)
  const control = Number(lead.value[controlProp])

  const needCheck =
    controlProp === 'number_of_adults'
      ? change > 0 && control === 0
      : change === 0 && control >= 0
  if (leadHotel.value && needCheck) {
    const controlVal =
      controlProp === 'number_of_adults' ? 1 : lead.value[controlProp] || 0
    // отправлять ребёнка одного только в for_children или лагерь
    if (
      !(
        leadHotel.value.display_as === C.HOTEL_DISPLAY_CAMP ||
        leadHotel.value.for_children
      )
    ) {
      // Если меняем number_of_adults на 0 то children ставится на 0
      lead.value[controlProp] = controlVal
      // Чтобы не было оба 0 ставим number_of_adults 1
      if (controlProp === 'number_of_children') {
        setTimeout(() => {
          lead.value.number_of_adults = 1
        }, 0)
      }
      return
    }
  }

  if (change === 0 && control === 0) {
    lead.value[controlProp] = 1
  }
}
const updateCity = async (cityACNew, cityACPrev) => {
  lead.value.location = cityACNew?.id || null
  if (!withoutMessSaveWS.value && cityACPrev) {
    leadHotelAC.value = null
  }
}
const updateHotelCityCategProg = async (hotelACNew, hotelACPrev) => {
  hotelPlacesDisabled.value = false

  lead.value.hotel = null
  leadHotel.value = null
  leadHotelCategories.value = null
  leadHotelPrograms.value = null
  if (!withoutMessSaveWS.value && hotelACPrev) {
    lead.value.category = null
    lead.value.room = null // todo room will be deprecated
    lead.value.program = null
  }
  const id = hotelACNew?.id
  if (id) {
    // storage.clr(WH)
    // TO DO STORAGE_BUG _2990
    const WH = Symbol()
    lead.value.hotel = id
    leadHotel.value = await hotelApi.hotelRetrieve(WH, id)
    leadHotelPrograms.value = await programApi.hotelProgramsRetrieve(WH, id, {
      limit: 100,
      filters: { is_active: true },
    })
    leadHotelCategories.value = await categoryApi.hotelListCategoriesList(
      WH,
      id,
      { limit: 100, filters: { is_active: true } },
    )
    if (!leadCityAC.value && leadHotel.value?.parent) {
      leadCityAC.value = {
        data_type: 'refbook__location',
        id: leadHotel.value.parent,
      }
    }
  }
  // Обновить проверку number_of_children при смене отеля
  if (
    lead.value.number_of_adults !== null ||
    lead.value.number_of_children !== null
  ) {
    controlTouristsChange(
      lead.value.number_of_adults || 0,
      'number_of_children',
    )
  }
}
const onClickHotelAC = () => {
  // исправляет проблему с выбором того же объекта повторно без очистки поля кнопкой X
  if (leadHotelAC.value) {
    const id = leadHotelAC.value.id
    leadHotelAC.value = { data_type: 'refbook__hotel', id }
  }
}
const addLeadDiseases = (option) => {
  if (!lead.value.diseases.find((d) => d === option.id)) {
    lead.value.diseases.push(option.id)
  }
}
const rmLeadDiseases = (option) => {
  lead.value.diseases = lead.value.diseases.filter((d) => d !== option.id)
}
const onPhonesCompleteAction = (val) => {
  lead.value.phones = Array.isArray(val)
    ? val.map((ph) => ph?.number || ph)
    : val?.number || val
}
const saveInputtedContacts = async () => {
  // сохранение введённого но не сохранённого телефона
  bus.emit('save-phone-input')
  bus.emit('save-contact-input')
}
const onContactsCompleteAction = (val) => {
  lead.value.contacts = Array.isArray(val)
    ? val.map((co) => (co?.type && co?.number ? `${co.type}:${co.number}` : co))
    : val?.type && val?.number
    ? `${val.type}:${val.number}`
    : val
}
const showCreate = ({ customer, form }) => {
  newCustomer.value = customer
  if (!newCustomer.value?.id) {
    console.error('Новый identity для заказчика не был создан')
    return
  }
  newOwnerId.value = newCustomer.value.id
  customerFormData.value = { ...form, lead: lead.value }
  showCustomerModal.value = false
}
const showCreatePerson = (payload) => {
  showCreate(payload)
  showPersonModal.value = true
}
const onCreateUpdatePerson = async ({ person }) => {
  const _saveApproveFn = saveApproveFn // save to local var. Дополнительно будет вызван close event кот. очистит saveApproveFn
  if (person) {
    const customer =
      newCustomer.value ||
      (await identityApi.identityRetrieve(WH, lead.value?.customer))
    if (customer) {
      customer.person = person.id
      await identityApi.identityPartial(WH, customer.id, {
        person: person.id,
      })
      customerName.value = getFullName(person)
      lead.value.customer = customer.id
      if (_saveApproveFn) _saveApproveFn()
    }
  }
}
const removeCustomer = async () => {
  const ownerId = newOwnerId.value
  const customerId = newCustomer.value?.id
  if (ownerId) {
    await identityApi.identityDelete(WH, ownerId)
    newOwnerId.value = null
  }
  if (customerId && customerId !== ownerId) {
    await identityApi.identityDelete(WH, customerId)
    newCustomer.value = null
  }
}
const onSelect = async (customerObj) => {
  showCustomerModal.value = false
  lead.value.customer = customerObj.id
  //todo убрать создание баера
  // сейчас ругается на создание тура без баера, поэтому создаём
  const dummyBuyer = await buyerApi.buyerCreate(WH, {
    customer: lead.value.customer,
  })
  lead.value.buyer = dummyBuyer.id
  if (saveApproveFn) {
    saveApproveFn()
    saveApproveFn = null
  }
}
const onCloseBuyerModal = () => {
  showCustomerModal.value = false
  saveApproveFn = null
}
const onClosePersonModal = () => {
  showPersonModal.value = false
  editPersonId.value = null
  saveApproveFn = null
}
const showCustomerEdit = async () => {
  const customerPerson = await getCustomerPerson(lead.value?.customer)
  if (!customerPerson) return
  customerFormData.value = customerPerson
  editPersonId.value = customerPerson.id
  showPersonModal.value = true
}
const checkUnsaved = async (actionFn) => {
  unsaved.value = checkFields.filter((item) => {
    const { key } = item
    if (Array.isArray(lead.value[key])) {
      return (
        lead.value[key].length === leadSnapshot.value[key].length &&
        lead.value[key].some((item) => !leadSnapshot.value[key].includes(item))
      )
    } else {
      if (
        lead.value[key] === null ||
        lead.value[key] === undefined ||
        lead.value[key] === ''
      ) {
        return Boolean(lead.value[key]) !== Boolean(leadSnapshot.value[key])
      }
      return lead.value[key] !== leadSnapshot.value[key]
    }
  })
  if (unsaved.value.length) {
    showUnsavedModal.value = true
    currentActionFn = actionFn
  } else {
    await actionFn()
  }
}

const onCancel = () => {
  showCancelModal.value = true
}
const onRestore = async () => {
  try {
    const data = { ...lead.value }
    lead.value = await leadApi.leadRestoreUpdate(WH, lead.value.id, data)
    leadSnapshot.value = { ...lead.value }
  } catch (err) {
    console.error(err)
  }
}
const onHandOver = () => {
  showHandOverModal.value = true
}
const onHijack = async () => {
  try {
    lead.value = await leadApi.leadHijackUpdate(WH, lead.value.id)
    leadSnapshot.value = { ...lead.value }
  } catch (err) {
    console.error(err)
  }
}
const onPostpone = async () => {
  try {
    lead.value = await leadApi.leadPostponeUpdate(WH, lead.value.id)
    leadSnapshot.value = { ...lead.value }
  } catch (err) {
    console.error(err)
  }
}
const onChange = async () => {
  try {
    await saveInputtedContacts()
    const data = { ...lead.value }
    if (Boolean(data.check_in_to) === false) {
      data.check_in_to = data.check_in_fr
    }
    withoutMessSaveWS.value = true
    lead.value = await leadApi.leadChangeUpdate(WH, lead.value.id, data)
    leadSnapshot.value = { ...lead.value }
    await notification.success('Изменения сохранены')
  } catch (err) {
    console.error(err)
    withoutMessSaveWS.value = false
  }
}
const onApprove = async () => {
  if (!lead.value?.customer) {
    saveApproveFn = async () => {
      await onChange()
      await onApprove()
    }
    showCustomerModal.value = true
    return
  }
  try {
    lead.value = await leadApi.leadApproveUpdate(WH, lead.value.id)
    leadSnapshot.value = { ...lead.value }
    // router.push({path: `/tour/${lead.value.tour}`})
  } catch (err) {
    console.error(err)
  }
}
const onExit = () => {
  router.push({ path: '/leads/' })
  emit('exit')
}

const onHandOverComplete = async (agentId) => {
  try {
    lead.value = await leadApi.leadHandOverUpdate(WH, props.id, {
      agent: agentId,
    })
    leadSnapshot.value = { ...lead.value }
  } catch (err) {
    console.error(err)
  } finally {
    showHandOverModal.value = false
  }
}
const onCancelComplete = async (payload) => {
  try {
    lead.value = await leadApi.leadArchiveUpdate(WH, lead.value.id, payload)
    leadSnapshot.value = { ...lead.value }
    router.push({ path: `/leads/` })
  } catch (err) {
    console.error(err)
  } finally {
    showCancelModal.value = false
  }
}
const onButtonCommon = (actionName) => {
  let actionFn = null
  switch (actionName) {
    case 'admin_restore':
      actionFn = onAdminRestore
      break
    case 'archive':
      actionFn = onCancel
      break
    case 'restore':
      actionFn = onRestore
      break
    case 'hand_over':
      actionFn = onHandOver
      break
    case 'hijack':
      actionFn = onHijack
      break
    case 'postpone':
      actionFn = onPostpone
      break
    case 'approve':
      actionFn = onApprove
      break
    case 'change':
      actionFn = onChange
      break
    default:
      actionFn = onExit
  }
  v$.value.$reset()
  if (actionName === 'change') {
    actionFn()
  } else {
    checkUnsaved(actionFn)
  }
}
const onAdminRestore = async () => {
  try {
    lead.value = await leadApi.leadAdminRestoreUpdate(WH, lead.value.id, {
      agent: lead.value.agent,
    })
    leadSnapshot.value = { ...lead.value }
  } catch (err) {
    console.error(err, 'onAdminRestore')
  }
}
const onChangesCommon = () => {
  if (currentActionFn) currentActionFn()
  currentActionFn = null
  showUnsavedModal.value = false
}
const onChangesSave = async () => {
  await onChange()
  onChangesCommon()
}
const onChangesContinue = () => {
  onChangesCommon()
}
const onCloseUnsavedModal = () => {
  showUnsavedModal.value = false
}
const calcCommentsHeight = () => {
  const leftColInn = document.getElementById('lead-left-col-inn')
  const rightColInn = document.getElementById('lead-right-col-inn')
  const commentsWrap = document.getElementById('lead-comments-wrap')
  if (!leftColInn || !rightColInn || !commentsWrap) return

  commentsWrap.style.height = 'unset'
  const commentsWrapHeight = commentsWrap.getBoundingClientRect().height
  const leftHeight = leftColInn.getBoundingClientRect().height
  const rightHeight = rightColInn.getBoundingClientRect().height
  const diff = leftHeight - rightHeight
  commentsWrap.style.height =
    diff < 0
      ? `${Math.round(commentsWrapHeight + diff)}px`
      : `${commentsWrapHeight}px`
}
const calcLeadWidth = () => {
  styleWidth.value =
    store.state.asideVisible && window.innerWidth > 694
      ? 'min-width: 694px'
      : ''
}
const beforeunloadHandler = (ev) => {
  checkUnsaved(() => {})
  if (unsaved.value.length) {
    // checkUnsaved откроет окно изменений, но надо открыть его только если юзер остался, для этого сначала скроем это окно до появления, а потом покажем с timeout
    showUnsavedModal.value = false
    ev.preventDefault()
    setTimeout(() => {
      // этот таймаут истекает во время показа диалога, встаёт в очередь и выполняется сразу после клика по кнопке диалога. (Форма может показаться перед перезагрузкой)
      setTimeout(() => {
        // этот таймаут начнёт отсчитываться только после клика в диалоге.
        showUnsavedModal.value = true
      }, 500)
    }, 0)
    return (ev.returnValue = '')
  }
}
const getTitleSafe = (urlData) => {
  return typeof urlData === 'string' ? null : urlData?.title || null
}
const getUrlSafe = (urlData) => {
  return typeof urlData === 'string' ? urlData : urlData?.url || null
}
const getSearchEngineName = (urlData) => {
  const url = getUrlSafe(urlData)
  if (!url) return null
  const ses = ['google.', 'bing.', 'yahoo.', 'yandex.', 'duckduckgo.', 'baidu.']
  const host = new URL(url).hostname
  const res = ses.find((se) => host.includes(se))
  return res ? `${res.slice(0, 1).toUpperCase()}${res.slice(1, -1)}` : null
}

const getLeadOrigin = (lead) => {
  // TODO изменения в api source -> origin, но константы ещё не обновлены
  if (!C.LEAD_SOURCE_TITLES) {
    console.log('>>> TODO', 'Заменить константы LEAD_SOURCE_TITLES для origin')
  }
  return C.LEAD_SOURCE_TITLES[lead?.origin]
}

const onCommentsPrepared = () => {
  nextTick(() => {
    setTimeout(calcCommentsHeight, 300)
  })
}

const getRelated = async (lead) => {
  const identitiesIds = [lead.customer, lead.initiator]
    .filter((id) => id != null)
    .map((id) => ({ id }))

  relIdentities.value = await storage.getRelations(
    identitiesIds,
    'id',
    'account__identity',
    identityApi.identityRetrieve,
    WH,
  )

  const idenValues = Object.values(relIdentities.value)

  const awaitRelUsers = storage.getRelations(
    idenValues,
    'user',
    'account__user',
    userApi.userRetrieve,
    WH,
  )
  const awaitRelPersons = storage.getRelations(
    idenValues,
    'person',
    'core__person',
    personApi.personRetrieve,
    WH,
  )

  const [_relUsers, _relPersons] = await Promise.all([
    awaitRelUsers,
    awaitRelPersons,
  ])

  relUsers.value = _relUsers
  relPersons.value = _relPersons
}

const init = async (leadId) => {
  storage.clr(WH)
  try {
    store.commit('lockPageWS')
    lead.value = await leadApi.leadRetrieve(WH, leadId)

    leadSnapshot.value = { ...lead.value }

    await getRelated(lead.value)

    leadCityAC.value = {
      data_type: 'refbook__location',
      id: lead.value.location,
    }
    if (lead.value.hotel) {
      leadHotelAC.value = { data_type: 'refbook__hotel', id: lead.value.hotel }
      initializedWithHotel.value = true
    }
    if (lead.value.adviser) {
      leadAdviserAC.value = await genericApi.genericTitleRetrieve(
        WH,
        'account__identity',
        lead.value.adviser,
      )
    }
    leadCheckinDate.value =
      lead.value.check_in_fr && lead.value.check_in_to
        ? [lead.value.check_in_fr, lead.value.check_in_to]
        : null
    diseases.value = await diseaseApi.diseaseLtList(WH, { limit: 1000 })
    selectedDiseases.value = diseases.value.data.filter((d) =>
      lead.value.diseases.includes(d.id),
    )

    setChatListen(lead.value.id, 'lead')

    const tourListData = await tourApi.tourList(WH, {
      limit: 10,
      offset: 0,
      filters: {
        lead: lead.value.id,
      },
    })
    tourList.value = tourListData?.data?.length
      ? tourListData?.data.map((t) => t.id)
      : []

    dataReady.value = true
  } catch (err) {
    console.error(err)
  } finally {
    store.commit('unlockPage')
  }
}
const onViewReady = () => {
  nextTick(() => {
    calcCommentsHeight()
    calcLeadWidth()
    onResize = debounce(() => {
      calcCommentsHeight()
      calcLeadWidth()
    }, 100)
    resizeObserver = new ResizeObserver(onResize)
    resizeObserver.observe(document.getElementById('body-container'))
    window.addEventListener('beforeunload', beforeunloadHandler, {
      capture: true,
    })
  })
}
const onDataReady = () => {
  // update watcher
  watchUpdate(lead)
}
const onSearchLeadsTours = (evOrValue) => {
  const defaultValue = evOrValue?.target?.value ?? evOrValue
  bus.emit('search-leads-tours-open', { defaultValue })
}
const closeHotelPlacesModal = ({ disableBtn = false } = {}) => {
  showPlacesModal.value = false
  hotelPlacesDisabled.value = disableBtn
}

// TODO изменения в api source -> origin но константы ещё не обновлены
const leadOrigin = computed(() => getLeadOrigin(lead.value))
// изменится api - source в виджете поменяется на division
const getRawDataDivision = (lead, raw) => {
  const d = raw?.source || raw?.division
  // источник "вручную" (1) также есть в raw_data.source при создании лида вручную. Здесь это не надо покзывать
  return d === 1 ? null : d
}
const rawData = computed(() => {
  const raw = lead.value.raw_data?.widget
    ? lead.value.raw_data
    : lead.value.raw_data?.data?.widget
    ? lead.value.raw_data.data
    : lead.value.raw_data?.data?.widget_data?.widget
    ? lead.value.raw_data.data.widget_data
    : lead.value.raw_data?.widget_data
  const history = raw?.analytics?.history
  return {
    division: getRawDataDivision(lead.value, raw),
    history,
    historyFirst: {
      url: getUrlSafe(history?.[0]),
      title: getTitleSafe(history?.[0]),
    },
    historyEntry: {
      url: getUrlSafe(history?.[1]),
      title: getTitleSafe(history?.[1]),
    },
    GA: raw?.analytics?.GA,
    YM: raw?.analytics?.YM,
    title: raw?.title,
    mainTitle: raw?.mainTitle,
  }
})

watch(leadCityAC, updateCity)
watch(leadHotelAC, updateHotelCityCategProg)
watch(leadAdviserAC, async (val) => {
  if (val.id) {
    lead.value.adviser = val.id
  }
})
watch(
  () => lead.value.customer,
  (val) => {
    getCustomerPerson(val).then((res) => {
      if (!res) return
      customerName.value = getFullName(res)
    })
  },
)
watch(
  () => lead.value.agent,
  (val) => {
    if (!val) return
    getUserFromIden(val).then((user) => {
      agentUser.value = user
    })
  },
)
watch(
  () => lead.value.initiator,
  (val) => {
    if (!val) return
    getUserFromIden(val).then((initiator) => {
      initiatorUser.value = initiator
    })
  },
)
watch(leadCheckinDate, (val) => {
  lead.value.check_in_fr =
    val?.length === 2
      ? dateService.formatDate(val[0]).split('.').reverse().join('-')
      : null
  lead.value.check_in_to =
    val?.length === 2
      ? dateService.formatDate(val[1]).split('.').reverse().join('-')
      : null
})
watch(leadSnapshot, () => {
  // leadSnapshot делается при каждом сохранении лида и в начале
  canChangeSource.value = lead.value.origin == C.LEAD_SOURCE_MANUALLY
})
watch(dataReady, (val) => {
  if (!val) return
  setTimeout(onDataReady, 500)
})

onBeforeMount(() => {
  init(props.id)
})
onMounted(() => {
  nextTick(() => {
    onViewReady()
  })
})
onUnmounted(() => {
  showUnsavedModal.value = false
  storage.clr(WH)
  window.removeEventListener('beforeunload', beforeunloadHandler, {
    capture: true,
  })
  if (resizeObserver?.disconnect) resizeObserver.disconnect()
})
onBeforeRouteLeave((to) => {
  if (routerContinueNoCheck.value) {
    return true
  }
  checkUnsaved(() => {
    // этот колбек вызывается при "сохранить и продолжить" и "продолжить без сохранения"
    // routerContinueNoCheck исключит повторную проверку в onBeforeRouteLeave после router.push
    routerContinueNoCheck.value = true
    router.push({ path: to.fullPath, query: to.query })
  })
  if (unsaved.value.length) {
    // если есть изменения - остаться
    return false
  } else {
    // переход по роутеру
    return true
  }
})
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
