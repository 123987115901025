// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class PriceApiMethodsCls {
    async priceList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение списка Price
      // filters: created_at, updated_at, hotel, category, program, period, is_active
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('priceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.price.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async priceCreate(wh, data, extra_data = null) {
      // data: Price
      // Создание Price
      console.log('priceCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.price.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async priceCopyPricesCreate(wh, data, extra_data = null) {
      // data: CopyPrice
      // Размножение цен (на основе категории, либо программы пребывания, либо периода).
      console.log('priceCopyPricesCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.price.copyPrices.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async priceCreateEmptyPricesCreate(wh, data, extra_data = null) {
      // data: CreateEmptyPrices
      // Создание отсутствующих price
      console.log('priceCreateEmptyPricesCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.price.createEmptyPrices.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async priceExtraActionPut(wh, data, extra_data = null) {
      // data: PriceIsActive
      // вкл/выкл, обнулить, удалить Price
      console.log('priceExtraActionPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.price.extraAction.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async priceRetrieve(wh, id, extra_data = null) {
      // Получение Price
      console.log('priceRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__price', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__price', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.price.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async priceUpdate(wh, id, data, extra_data = null) {
      // data: PriceUpdate
      // Обновление модели Price
      console.log('priceUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.price.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async priceDelete(wh, id) {
      // Удаление Price
      console.log('priceDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.hms.price.delete(id)})
      ctx.$services.storage.del('hms__price', id, wh)
      return null
    }
    async pricePartial(wh, id, data, extra_data = null) {
      // data: PriceUpdate
      // Обновление поля prices
      console.log('pricePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.price.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async programPriceList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка цен программ пребывания
      // filters: created_at, updated_at, hotel, program, period
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('programPriceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.programPrice.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async programPriceCreate(wh, data, extra_data = null) {
      // data: ProgramPrice
      // Создание цен программ пребывания
      console.log('programPriceCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.programPrice.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async programPriceRetrieve(wh, id, extra_data = null) {
      // Получение цен программ пребывания
      console.log('programPriceRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__programprice', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__programprice', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.programPrice.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async programPriceUpdate(wh, id, data, extra_data = null) {
      // data: ProgramPriceUpdate
      // Обновление модели цен программ пребывания
      console.log('programPriceUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.programPrice.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async programPriceDelete(wh, id) {
      // Удаление Price
      console.log('programPriceDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.hms.programPrice.delete(id)})
      ctx.$services.storage.del('hms__programprice', id, wh)
      return null
    }
    async programPricePartial(wh, id, data, extra_data = null) {
      // data: ProgramPriceUpdate
      // Обновление поля prices цен программ пребывания
      console.log('programPricePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.programPrice.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.priceApi = new PriceApiMethodsCls()
}

