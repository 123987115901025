<template>
  <template v-if="!hasInitialDates">
    <CRow>
      <CCol :xs="12" :sm="6" :md="4" :lg="3" :xl="2" :xxl="2" class="mb-2">
        <CFormLabel for="pricesRange">Даты</CFormLabel>
        <Datepicker
          id="pricesRange"
          v-model="pricesDatesRange"
          placeholder="Выберите период"
          format="dd.MM.yyyy"
          model-type="yyyy-MM-dd"
          locale="ru-RU"
          :enable-time-picker="false"
          size="sm"
          :range="true"
          :multi-calendars="false"
          :week-start="1"
          cancel-text="Отменить"
          select-text="Выбрать"
        />
      </CCol>
    </CRow>
  </template>
  <template v-if="result && Object.keys(result).length">
    <div>
      <template v-if="tabs.length > 1">
        <TableTabs :tabs="tabs" :set-tab="setTab" :active-tab="activeTab" />
        <CTabContent class="h-100">
          <CTabPane
            v-for="tab in tabs"
            :key="tab.name"
            :visible="tabs[activeTab].name === tab.name"
          >
            <HotelPricesTable
              :categories="hotelCategories"
              :programs="hotelPrograms"
              :day-tariff="dayTariff"
              :booker="activeTabData.booker"
              :data="activeTabData.data"
            />
          </CTabPane>
        </CTabContent>
      </template>
      <template v-else>
        <HotelPricesTable
          :categories="hotelCategories"
          :programs="hotelPrograms"
          :day-tariff="dayTariff"
          :booker="activeTabData.booker"
          :data="activeTabData.data"
          :select-mode="selectMode"
        />
      </template>
    </div>

    <template v-if="ready && modalWh">
      <teleport :to="`#id-after-body-${modalWh}`">
        <small class="text-disabled opacity-50 pe-2">{{
          activeTabData.booker
        }}</small>
      </teleport>
    </template>
  </template>
  <template v-else-if="ready && hasDates">Нет данных</template>
</template>

<script setup>
import { computed, inject, onUnmounted, ref, watch } from 'vue'
import { addDays } from '@/helpers'
import { useRequest } from '@/composables/request'
import HotelPricesTable from '@/components/_shared/hotel-prices/HotelPricesTable.vue'
import TableTabs from '@/components/_shared/TableWithTabsWrap/TableTabs.vue'

const WH = '49b0d695-0259-4425-8712-1dfbc46020c1'
const WHKeep = '106c7779-c844-4d11-aa96-f1f51c7e3fe4'
const { dateService, coreApi, categoryApi, programApi, hotelApi, storage } =
  inject('services')

const props = defineProps({
  modalWh: {
    type: String,
    required: false,
    default: () => null,
  },
  // cat, prog
  selectMode: {
    type: String,
    default: 'cat',
  },
  /**
   * @type {{
   *   hotel: number
   *   category?: number
   *   program?: number
   *   date_from?: string
   *   date_to?: string
   *   days_to?: number // todo проверить в брони запрос что там number
   * }}
   */
  data: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['shift-date-to'])

const { wrapRequest } = useRequest({ lockTarget: `#id-${props.modalWh}` })

const ready = ref(false)
const result = ref(null)
const hotel = ref({})
const hotelCategories = ref([])
const hotelPrograms = ref([])
const shiftedDateTo = ref(null)
const shiftedNightsTo = ref(null)
const activeTab = ref()
const dateFrom = ref(props.data.date_from)
const dateTo = ref(props.data.date_to)
const daysTo = ref(props.data.days_to)
const hasInitialDates = ref(Boolean(props.data.date_from && props.data.date_to))

const dayTariff = computed(() => Boolean(hotel.value?.day_tariff))
const hasDates = computed(() => Boolean(dateFrom.value && dateTo.value))
const tabs = computed(() =>
  Object.keys(result.value).map((name, i) => ({
    name: `Вариант ${i + 1}`,
    booker: name,
  })),
)
const activeTabData = computed(() => {
  const booker = tabs.value[activeTab.value].booker
  return { booker, data: result.value[booker] }
})
const pricesDatesRange = computed({
  get() {
    return hasDates.value ? [dateFrom.value, dateTo.value] : null
  },
  set(range) {
    if (range) {
      const [fr, to] = range
      dateFrom.value = fr
      dateTo.value = to
    } else {
      dateFrom.value = null
      dateTo.value = null
    }
  },
})

function setTab(val) {
  activeTab.value = val
}

const shiftDateTo = (to, daysStr) => {
  const dateTo = new Date(to)
  const days = daysStr ? Number(daysStr) : 0
  // убираем один из дней т.к. кол-во в daysStr уже включает date_to. Чтобы не добавлять этот день повторно.
  const daysToAdd = days > 0 ? days - 1 : 0
  const shiftedDateTo = addDays(dateTo, daysToAdd)
  return dateService.toIso(shiftedDateTo)
}

const getShiftedNightsDate = (daysShiftedDate) => {
  // если уже сдвинутая дата осталась такой же как date_from значит ничего не сдвигаем это 1день или 1 ночь
  if (daysShiftedDate === dateFrom.value) return daysShiftedDate
  const nightShiftedDate = addDays(new Date(daysShiftedDate), -1)
  return dateService.toIso(nightShiftedDate)
}

const prepResultData = (res = {}) => {
  const hotelId = props.data.hotel
  return Object.keys(res).reduce((acc, key) => {
    if (key.includes(hotelId)) {
      const _key = key.includes(':') ? key : `NONE:${key}`
      const [booker] = _key.split(':')
      acc[booker] = { requested: {}, alternatives: {} }
      acc[booker].requested = res[key]
    }
    // else потом могут быть другие отели как альтернатива их добавим в отдельный объект alternatives
    // можно выводить названия отелей в отдельной секции или вкладке с кнопками показать наличие и при нажаитии уже подгружать список категорий этого отеля
    // и показывать таблцу
    return acc
  }, {})
}

const prepPriceParams = (dayTariff, dateToShifted, nightsToShifted) => {
  return {
    hotel: props.data.hotel,
    date_from: dateFrom.value,
    date_to: dayTariff ? dateToShifted : nightsToShifted,
    program: props.data.program,
    category: props.data.category,
  }
}

const init = async () => {
  if (!hasDates.value) return

  shiftedDateTo.value = shiftDateTo(
    // dateTo.value если нужно сдвинуть на весь период заезда плюс кол-во дней
    dateTo.value,
    // если нужно только количество дней со дня date_from
    // dateFrom.value,
    daysTo.value,
  )

  // убираем один день для запроса кол-ва ночей если !hotel.value?.day_tariff
  shiftedNightsTo.value = getShiftedNightsDate(shiftedDateTo.value)

  const priceParams = prepPriceParams(
    dayTariff.value,
    shiftedDateTo.value,
    shiftedNightsTo.value,
  )

  setTab(0)

  hotel.value = await hotelApi.hotelRetrieve(WHKeep, props.data.hotel)

  emit('shift-date-to', {
    hotelTitle: `${hotel.value.title} / ${hotel.value.location_name}`,
    dateFrom: dateFrom.value,
    dateTo: shiftedDateTo.value,
    dayTariff: dayTariff.value,
  })

  storage.clr(WH)
  const [_hotelCategories, _hotelPrograms, _result] = await Promise.all([
    categoryApi.hotelListCategoriesList(WH, props.data.hotel, {
      limit: 50,
      filters: { is_active: true },
    }),
    programApi.hotelProgramsRetrieve(WH, props.data.hotel, {
      limit: 50,
      filters: { is_active: true },
    }),
    coreApi.allPricesPut(WH, priceParams),
  ])

  hotelCategories.value = _hotelCategories.data
  hotelPrograms.value = _hotelPrograms.data
  result.value = prepResultData(_result)
}

watch(
  [dateFrom, dateTo],
  async () => {
    ready.value = false
    await wrapRequest(init)
    ready.value = true
  },
  { immediate: true },
)

onUnmounted(() => {
  storage.clr(WH)
  storage.clr(WHKeep)
})
</script>
