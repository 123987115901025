/* Можно использовать  */

import CC from '@/config/constants'

// TODO требуется полностью пересмотреть систиему аутентификации

const WH = 'AUTH'
const REFRESH_THRESHOLD = 120 // 35980
let flag = false

function isDev() {
  return process.env.NODE_ENV === 'development'
}
// function isProd() {
//   return process.env.NODE_ENV === 'production'
// }

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export default (ctx) => {
  class AuthService {
    constructor() {
      this._orig_token = null
      this.isRefreshing = false
      this._server_url = null
      this._token = null
      this._temp_token_key = null
      this._identity = null
      this._user = null
      this._company = null
      this.serverToWSPortsMap = {
        813: 814, // DEV-A
        823: 824, // DEV-B
        833: 834, // DEV-C
        853: 854, // DEV-D
        843: 844, // MASTER
        943: 944, // STAGING
        0: ':444', // PRODUCTION
      }
    }

    async getWSServerUrl() {
      const portRx = /\d+$/
      const serverUrl = await this.getServerUrl()
      const portIdx = serverUrl.search(portRx)
      const wsPort =
        portIdx >= 0
          ? this.serverToWSPortsMap[serverUrl.slice(portIdx)]
          : this.serverToWSPortsMap[0]
      return portIdx >= 0
        ? serverUrl.replace(portRx, wsPort)
        : `${serverUrl}${wsPort}`
    }

    async getServerUrl() {
      if (!this._server_url) {
        this._server_url = await ctx.$services.persistentService.getGlobal(
          CC.LOCAL_STORAGE_SERVER_KEY,
        )
        if (!this._server_url) {
          this._server_url = 'http://127.0.0.1:8000'
        }
      }
      return this._server_url
    }

    async setServerUrl(server_url) {
      this._server_url = server_url
      await ctx.$services.persistentService.setGlobal(
        CC.LOCAL_STORAGE_SERVER_KEY,
        this._server_url,
      )
    }

    async getTokenKey() {
      while (this.isRefreshing) {
        await sleep(10)
      }
      return this._temp_token_key || this._token?.key
    }

    async validateToken() {
      try {
        let token = JSON.parse(
          localStorage.getItem(CC.LOCAL_STORAGE_LATEST_TOKEN_KEY),
        )
        if (!token) return false
        const timeBeforeExpire = 3 * 60 * 1000
        const {
          refresh_key: refreshKey,
          valid_till: validTill,
          refresh_till: refreshTill,
        } = token
        const needRefresh =
          new Date(validTill).getTime() - timeBeforeExpire < Date.now()
        const canRefresh = new Date(refreshTill).getTime() > Date.now()
        if (needRefresh) {
          if (!canRefresh) return false
          token = await ctx.$services.authApi.refreshCreate(WH, {
            refresh_token: refreshKey,
          })
          localStorage.setItem(
            CC.LOCAL_STORAGE_LATEST_TOKEN_KEY,
            JSON.stringify(token),
          )
        }
        this._token = token
        this._identity = await ctx.$services.identityApi.identityRetrieve(
          WH,
          this._token.identity,
        )
        this._user = null
        this._company = null
        return true
      } catch (err) {
        console.error('validateToken failed with error', err)
        return false
      }
    }

    async isAuthenticated() {
      return !!this._token || (await this.validateToken())
    }

    async goAuthenticated(parentLogin = false, retry_if_none = false, point) {
      if (this._token) {
        return true
      }

      if (isDev()) {
        this._temp_token_key = localStorage.getItem('latestTokenKey')
        if (this._temp_token_key) {
          try {
            this._token = await ctx.$services.accountApi.authinfoRetrieve(WH)
            this._identity = await ctx.$services.identityApi.identityRetrieve(
              WH,
              this._token.identity,
            )
            this._user = null
            this._company = null
          } catch (e) {
            // тихо умираем
          }
          delete this._temp_token_key
        }
      }

      if (this._token) {
        return true
      }

      if (!retry_if_none) {
        return false
      }

      try {
        this._token = await ctx.$services.accountApi.authinfoRetrieve(WH)
      } catch (e) {
        if (e.response) {
          if (e.response.status === 401) {
            if (!parentLogin) {
              return false
            }
            return false
          }
        }
        console.error('goAuthenticated failed at', point)
        throw e
      }
      this._identity = await ctx.$services.identityApi.identityRetrieve(
        WH,
        this._token.identity,
      )
      this._user = null
      this._company = null
      return true
    }

    resetAuthentication() {
      this._token = null
      this._identity = null
      this._user = null
      this._company = null
      localStorage.removeItem(CC.LOCAL_STORAGE_LATEST_TOKEN_KEY)
      ctx.$services.storage.clr(WH)
      ctx.$services.websocket.close()
      if (isDev()) {
        localStorage.removeItem('latestTokenKey')
      }
    }

    async authenticate(login, password) {
      ctx.$services.storage.clr(WH)
      this._server_url = await ctx.$services.persistentService.getGlobal(
        CC.LOCAL_STORAGE_SERVER_KEY,
      )
      this._token = await ctx.$services.authApi.personalCreate(WH, {
        login,
        password,
      })
      let latestIdentity
      try {
        latestIdentity = parseInt(localStorage.getItem('latestIdentity'))
      } catch {
        latestIdentity = null
      }
      if (
        !this._orig_token &&
        latestIdentity &&
        latestIdentity !== this._token.identity
      ) {
        try {
          this._token = await ctx.$services.accountApi.changeCreate(WH, {
            identity: latestIdentity,
          })
        } catch {
          console.log('Не удалось сменить роль')
        }
      }

      this._identity = await ctx.$services.identityApi.identityRetrieve(
        WH,
        this._token.identity,
      )
      this._orig_token = null
      this._user = null
      this._company = null
      localStorage.setItem('latestIdentity', this._identity.id)
      localStorage.setItem(
        CC.LOCAL_STORAGE_LATEST_TOKEN_KEY,
        JSON.stringify(this._token),
      )
      if (isDev()) {
        localStorage.setItem('latestTokenKey', this._token.key)
        localStorage.setItem('latestRefreshKey', this._token.refresh_key)
      }
    }

    async getIdentity() {
      if (!this._identity) {
        this.resetAuthentication()
      }
      return this._identity
    }

    async getUser() {
      if (!this._user) {
        this._user = await ctx.$services.userApi.userRetrieve(
          WH,
          this._identity.user,
        )
      }
      return this._user
    }

    async getUserId() {
      const user = await this.getUser()
      return user.id
    }

    async getCompany() {
      if (!this._company) {
        this._company = await ctx.$services.companyApi.companyRetrieve(
          WH,
          this._identity.company,
        )
      }
      return this._company
    }

    async getCompanyId() {
      const company = await this.getCompany()
      return company.id
    }

    async changeIdentity(identity) {
      ctx.$services.storage.clr(WH)
      this._token = await ctx.$services.accountApi.changeCreate(WH, {
        identity,
      })
      this._identity = await ctx.$services.identityApi.identityRetrieve(
        WH,
        this._token.identity,
      )
      this._user = null
      this._company = null
      await ctx.$services.websocket.restart()
      localStorage.setItem('latestIdentity', this._identity.id)
      localStorage.setItem(
        CC.LOCAL_STORAGE_LATEST_TOKEN_KEY,
        JSON.stringify(this._token),
      )
      if (isDev()) {
        localStorage.setItem('latestTokenKey', this._token.key)
        localStorage.setItem('latestRefreshKey', this._token.refresh_key)
      }
      return this._identity
    }

    async onRefreshTimer(self) {
      if (!self._token) {
        // console.log('onRefreshTimer - no auth', Date())
        return
      }
      const valid_till = new Date(self._token.valid_till)
      const now = new Date()
      const time_left = Math.round((valid_till - now) / 1000)
      // console.log('onRefreshTimer', time_left,
      //   self._token.key.substring(self._token.key.length-20, self._token.key.length-1),
      //   self._token.key.substring(self._token.refresh_key.length-20, self._token.refresh_key.length-1)
      // )
      if (time_left < REFRESH_THRESHOLD) {
        // console.log("doRefresh")
        // self.isRefreshing = true
        try {
          self._token = await ctx.$services.authApi.refreshCreate(WH, {
            refresh_token: self._token.refresh_key,
          })
          localStorage.setItem(
            CC.LOCAL_STORAGE_LATEST_TOKEN_KEY,
            JSON.stringify(this._token),
          )
          if (isDev()) {
            localStorage.setItem('latestTokenKey', self._token.key)
            localStorage.setItem('latestRefreshKey', self._token.refresh_key)
          }
          // console.log("doRefresh ok")
        } catch (e) {
          if (e.response) {
            if (e.response.status === 401) {
              self._token = null
              return false
            }
          }
          console.error('goAuthenticated failed at refresh token')
          throw e
        }
        //finally {
        // self.isRefreshing = false
        //}
      }
    }

    initRefresh() {
      setInterval(this.onRefreshTimer, 5000, this)
    }

    async canHijack() {
      const user = await this.getUser()
      console.log('user', user)
      console.log('user.is_superuser', user.is_superuser)
      console.log('this._orig_token', this._orig_token)
      return user.is_superuser && this._orig_token === null
    }

    isHijacked() {
      return this._orig_token !== null
    }

    async hijack(user) {
      console.log('hijack', user)
      if (!(await this.canHijack())) {
        return null
      }
      console.log('hijack can!', user)
      ctx.$services.storage.clr(WH)
      const origToken = { ...this._token }
      this._identity = null
      this._user = null
      this._company = null
      this._token = await ctx.$services.accountApi.hijackCreate(WH, { user })
      if (this._token) {
        this._orig_token = origToken
      }
      console.log('hijack this._token', this._token)
      this._identity = await ctx.$services.identityApi.identityRetrieve(
        WH,
        this._token.identity,
      )
      console.log('hijack this._identity', this._identity)
      localStorage.setItem('latestIdentity', this._identity.id)
      localStorage.setItem(
        CC.LOCAL_STORAGE_LATEST_TOKEN_KEY,
        JSON.stringify(this._token),
      )
      await ctx.$services.websocket.restart()
      if (isDev()) {
        localStorage.setItem('latestTokenKey', this._token.key)
        localStorage.setItem('latestRefreshKey', this._token.refresh_key)
      }
      return this._identity
    }

    async hijackIdentity(identity) {
      console.log('hijackIdentity', identity)
      if (!(await this.canHijack())) {
        return null
      }
      console.log('hijackIdentity can!', identity)
      ctx.$services.storage.clr(WH)
      const origToken = { ...this._token }
      this._identity = null
      this._user = null
      this._company = null
      this._token = await ctx.$services.accountApi.hijackIdentityCreate(WH, {
        identity,
      })
      if (this._token) {
        this._orig_token = origToken
      }
      console.log('hijackIdentity this._token', this._token)
      this._identity = await ctx.$services.identityApi.identityRetrieve(
        WH,
        this._token.identity,
      )
      console.log('hijackIdentity this._identity', this._identity)
      localStorage.setItem('latestIdentity', this._identity.id)
      localStorage.setItem(
        CC.LOCAL_STORAGE_LATEST_TOKEN_KEY,
        JSON.stringify(this._token),
      )
      await ctx.$services.websocket.restart()
      if (isDev()) {
        localStorage.setItem('latestTokenKey', this._token.key)
        localStorage.setItem('latestRefreshKey', this._token.refresh_key)
      }
      return this._identity
    }

    async release() {
      if (!this.isHijacked()) {
        return null
      }
      ctx.$services.storage.clr(WH)
      this._token = this._orig_token
      this._user = null
      this._company = null
      this._orig_token = null
      await ctx.$services.websocket.restart()
      this._identity = await ctx.$services.identityApi.identityRetrieve(
        WH,
        this._token.identity,
      )
      localStorage.setItem('latestIdentity', this._identity.id)
      localStorage.setItem(
        CC.LOCAL_STORAGE_LATEST_TOKEN_KEY,
        JSON.stringify(this._token),
      )
      if (isDev()) {
        localStorage.setItem('latestTokenKey', this._token.key)
        localStorage.setItem('latestRefreshKey', this._token.refresh_key)
      }
      return this._identity
    }
  }
  if (flag) {
    assert(False, 'Повторная инициализация AuthService')
  }
  flag = true
  ctx.$services.auth = new AuthService()
  ctx.$services.auth.initRefresh()
}
