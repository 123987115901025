<template>
  <template v-if="props.color">
    <span :class="'text-' + props.color"
      ><CIcon
        :icon="getIcon(props.icon)"
        :size="props.size"
        :class="props.class"
    /></span>
  </template>
  <template v-else>
    <CIcon
      :icon="getIcon(props.icon)"
      :size="props.size"
      :class="props.class"
    />
  </template>
</template>

<script setup>
//ИКОНКИ ИЗ COREUI!!!! иконки для кнопки экшена !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
import {
  cilMediaPlay,
  cilAccountLogout,
  cilApplicationsSettings,
  cilBug,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilArrowThickFromBottom,
  cilBell,
  cilChatBubble,
  cilCheckCircle,
  cilClock,
  cilCloudDownload,
  cilColorBorder,
  cilCommentSquare,
  cilFile,
  cilFork,
  cilImage,
  cilInfo,
  cilList,
  cilMenu,
  cilPlus,
  cilPowerStandby,
  cilPrint,
  cilRecycle,
  cilReload,
  cilSearch,
  cilSend,
  cilSettings,
  cilSquare,
  cilTask,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserPlus,
  cilUserX,
  cilXCircle,
  cilZoomIn,
  cilEnvelopeClosed,
  cilPhone,
  cibTelegram,
  cibWhatsapp,
  cibVk,
  cibOdnoklassniki,
  cibSkype,
  cibViber,
  cilFax,
  cilFilter,
  cilLibraryAdd,
  cilFullscreen,
  cilFullscreenExit,
  cilHome,
  cilFastfood,
  cilCasino,
  cilMedicalCross,
  cilBed,
  cilCalendarCheck,
  cilCaretRight,
  cilCaretLeft,
  cilCopy,
} from '@coreui/icons'

import { defineProps } from 'vue'
const ICONS = {
  in_call: cilArrowCircleLeft,
  out_call: cilArrowCircleRight,
  play: cilMediaPlay,
  accept: cilCheckCircle,
  alert: cilBell,
  appSettings: cilApplicationsSettings,
  bug: cilBug,
  chat: cilChatBubble,
  checkOff: cilSquare,
  checkOn: cilTask,
  clock: cilClock,
  comment: cilCommentSquare,
  create: cilPlus,
  create_user: cilUserPlus,
  delete: cilTrash,
  deleteUser: cilUserX,
  disabled: cilPowerStandby,
  download: cilCloudDownload,
  edit: cilColorBorder,
  enabled: cilPowerStandby,
  exit: cilAccountLogout,
  file: cilFile,
  hijack: cilArrowCircleRight,
  icon: cilImage,
  info: cilInfo,
  list: cilList,
  list_add: cilLibraryAdd,
  logout: cilAccountLogout,
  menu: cilMenu,
  paymentSplit: cilFork,
  print: cilPrint,
  reject: cilXCircle,
  release: cilArrowCircleLeft,
  reload: cilReload,
  restore: cilRecycle,
  search: cilSearch,
  send: cilSend,
  settings: cilSettings,
  task: cilFork,
  'task-black': cilFork, // для теста перехода на новые иконки
  upload: cilArrowThickFromBottom,
  user: cilUser,
  userFemale: cilUserFemale,
  view: cilZoomIn,
  envelope: cilEnvelopeClosed,
  phone: cilPhone,
  telegram: cibTelegram,
  whatsapp: cibWhatsapp,
  vk: cibVk,
  ok: cibOdnoklassniki,
  skype: cibSkype,
  viber: cibViber,
  fax: cilFax,
  filter: cilFilter,
  maximize: cilFullscreen,
  minimize: cilFullscreenExit,
  home: cilHome,
  food: cilFastfood,
  casino: cilCasino,
  medicine: cilMedicalCross,
  bed: cilBed,
  daySelect: cilCalendarCheck,
  rightArrow: cilCaretRight,
  leftArrow: cilCaretLeft,
  copy: cilCopy,
}

const getIcon = (iconHandle) => {
  const icon = ICONS[iconHandle]
  if (icon) return icon
  return ICONS.icon
}

const props = defineProps({
  icon: {
    type: String,
    default: () => 'icon',
  },
  size: {
    type: String,
    default: () => null,
  },
  class: {
    type: String,
    default: () => null,
  },
  color: {
    type: String,
    default: () => null,
  },
})
</script>
