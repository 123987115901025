<template>
  <div class="flex justify-content-end gap-3 flex-wrap">
    <CButton
      v-for="action in filteredActions"
      :key="action.name"
      :color="action.color"
      @click="emit('action', action.name)"
      :disabled="disabled || action.disabled"
      class="nobr"
    >
      {{ action.title }}
    </CButton>
    <CButton color="secondary" class="nobr" @click="emit('action', 'exit')"
      >В список</CButton
    >
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import C from '@/config/back_const'

const props = defineProps({
  lead: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false,
  },
})
const emit = defineEmits(['action'])

const saveBtnName = ref('')
const filteredActions = ref([])
const allActions = computed(() => {
  return [
    {
      name: 'archive',
      color: 'danger',
      title: 'В архив',
    },
    {
      name: 'restore',
      color: 'primary',
      title: 'В работу',
    },
    {
      name: 'hand_over',
      color: 'primary',
      title: 'Передать',
    },
    {
      name: 'admin_restore',
      color: 'primary',
      title: 'Передать агенту',
    },
    {
      name: 'hijack',
      color: 'primary',
      title: 'Забрать',
    },
    {
      name: 'postpone',
      color: 'warning',
      title: 'Не дозвонились',
    },
    {
      name: 'approve',
      color: 'success',
      title: 'Оформить тур',
    },
    {
      name: 'change',
      color: 'success',
      title: saveBtnName.value,
    },
  ]
})

watch(
  () => props.lead,
  (val) => {
    if (!val?.actions) return
    saveBtnName.value =
      val.status === C.LEAD_STATUS_DRAFT ? 'Принять заявку' : 'Сохранить'
    filteredActions.value = allActions.value
      .filter((act) => {
        return val.actions.includes(act.name)
      })
      .filter((act) => {
        // скрыть postpone когда меняем source на "в ручную"
        return !(
          act.name === 'postpone' && val.origin == C.LEAD_SOURCE_MANUALLY
        )
      })
  },
  { deep: true, immediate: true },
)
</script>
