<template>
  <div class="flex flex-column">
    <div
      v-if="chatData"
      class="w-100 p-3 border-bottom border-bottom-1 border-opacity-10 flex gap-2"
    >
      <div class="position-relative p-1">
        <CBadge
          class="badge-dot top-50"
          :color="chatData.is_customer_online ? 'success' : 'secondary'"
          :title="
            chatData.is_customer_online ? 'Клиент онлайн' : 'Клиент офлайн'
          "
          position="top-start"
          shape="rounded-circle"
        >
          <span class="visually-hidden">{{
            chatData.is_customer_online ? 'success' : 'secondary'
          }}</span>
        </CBadge>
      </div>
      <span>Чат</span>
      <span v-if="chatData.lead" class="me-2">
        с лидом
        <router-link :to="`/lead/${chatData.lead}/`"
          >{{ chatData.lead }}
        </router-link>
      </span>
      <span v-if="chatData.tour">
        Тур
        <router-link :to="`/tour/${chatData.tour}`">{{
          chatData.tour
        }}</router-link>
      </span>
    </div>
    <div class="p-3">
      <div v-if="isLoadingMessages" class="pb-3">
        <span class="me-3">получаем cообщения ...</span>
        <CSpinner component="span" size="sm" aria-hidden="true" class="me-3" />
      </div>
      <div
        id="chat-item-messages"
        class="flex flex-column gap-3 px-3 w-100 h-100"
        :style="`max-height: calc(100vh - ${
          isMobileAside ? '170px' : '335px'
        }); overflow: scroll`"
      >
        <template v-for="(msg, index) in messages[chatId]" :key="msg.id">
          <template v-if="messages[chatId][index - 1]">
            <div
              v-if="
                !sameDay(messages[chatId][index - 1].created_at, msg.created_at)
              "
              class="py-4 text-center"
            >
              <span class="p-2 rounded-pill text-bg-primary">{{
                toLocaleDayStr(msg.created_at)
              }}</span>
            </div>
          </template>
          <template v-else>
            <div class="py-4 text-center">
              <span class="p-2 rounded-pill text-bg-primary">{{
                toLocaleDayStr(msg.created_at)
              }}</span>
            </div>
          </template>
          <template v-if="msg.type === messageType.INTERNAL_REALTIME">
            <div class="flex gap-3 form-label fw-medium my-2">
              <span>{{ dateService.formatTime(msg.created_at) }}</span>
              <span>{{ msg.content }}</span>
            </div>
          </template>
          <template v-else>
            <div
              class="msg-row flex"
              :class="calcMsgClasses(msg)"
              :data-id="msg.id"
              :data-read="Boolean(msg.read_at)"
            >
              <div
                class="msg-row_inn px-3 pt-3 pb-4 rounded-3 position-relative text-wrap-any"
                style="min-width: 50px"
                :class="
                  msg.sender == myIdentity?.id
                    ? 'text-bg-info bg-opacity-25'
                    : 'text-bg-light'
                "
              >
                <div
                  class="text-primary fw-bold position-relative"
                  style="top: -5px; left: 0"
                >
                  {{
                    getUserName(msg.chat, msg.sender) ||
                    msg.senderName ||
                    'Анонимный'
                  }}
                </div>
                <span>{{ msg.content }}</span>
                <div
                  class="msg-row-time opacity-50 position-absolute text-end"
                  style="bottom: 1px; right: 5px"
                >
                  {{ dateService.formatTime(msg.created_at) }}
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="flex gap-3 pt-3 px-3">
        <CFormInput
          placeholder="Напишите ваше сообщение"
          v-model="message"
          @keyup.enter="send"
        />
        <ActionButton
          icon="send"
          color="primary"
          size="sm"
          icon-size="lg"
          variant="outline"
          :action="send"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
  reactive,
} from 'vue'
import ActionButton from '@/components/_common/ActionButton.vue'
import { debounce } from 'lodash'
import { useAuthData } from '@/composables/authData'
import { useAccessWS } from '@/composables/accessWS'
import { getWSMessage } from '@/helpers'

const WH = '44447d2a-065c-4845-9784-fb87f98b46b4'
const { storage, websocket, dateService, messageApi, identityApi, userApi } =
  inject('services')
const props = defineProps({
  chatId: {
    type: [Number, String],
    required: true,
  },
  chatData: {
    type: Object,
    required: true,
  },
})
defineEmits(['close'])

const { myIdentity, myUser } = useAuthData()
const { setWSAction } = useAccessWS(websocket)
const relData = ref({})
const message = ref(null)
const messages = ref({})
const isLoadingMessages = ref(false)
const messagesContEl = ref(null)
const isSenderActive = ref(false)
const isMobileAside = ref(false)

// TODO это должно быть в back_const.js
const messageType = {
  INTERNAL_REALTIME: 8,
}

let asideEl
let onResize
let resizeObserver

/**
 * [id]: {
 *   initialized: false,
 *   messageListData: null|[],
 *   messageListPage: 1,
 *   intersections: {
 *     cb: [],
 *     done: false
 *   }
 * }
 */
const chatState = reactive({})
const messageListLimit = 20

const execIntersectionCBs = () => {
  chatState[props.chatId].intersections.cb.forEach((cb) => cb())
  chatState[props.chatId].intersections.cb = []
  chatState[props.chatId].intersections.done = true
}
const scrollToLast = (behavior = 'auto') => {
  nextTick(() => {
    setTimeout(() => {
      // без timeout не скроллит при втором заходе и если до этого было отскроллено выше
      const lastMessageEl = document.querySelector('.msg-row:last-of-type')
      if (lastMessageEl) lastMessageEl.scrollIntoView({ behavior })
    }, 20)
  })
}
const scrollToLastInPart = (el, behavior = 'auto') => {
  setTimeout(() => {
    nextTick(() => {
      const el =
        document.querySelectorAll('.msg-row')[
          chatState[props.chatId].messageListData?.length
            ? chatState[props.chatId].messageListData.length - 1
            : 0
        ]
      if (el) el.scrollIntoView({ behavior })
      setTimeout(execIntersectionCBs, 300)
    })
  }, 20)
}
const send = () => {
  if (!message.value) return
  const id = props.chatId
  const data = {
    chat: id,
    content: message.value,
    data_type: 'messaging__message',
    mime_type: 'text/plain',
    sender: myIdentity.value?.id,
    created_at: new Date().toISOString(),
  }
  messages.value[id].push(data)
  message.value = null
  isSenderActive.value = true
  setWSAction(() => {
    websocket.connection.send(
      JSON.stringify({ type: 'create_chat_message', data }),
    )
  })
  scrollToLast('smooth')
}
const sendRead = (id) => {
  const msgData = messages.value[props.chatId]?.find((m) => m.id == id)
  if (msgData) {
    setWSAction(() => {
      websocket.connection.send(
        JSON.stringify({ type: 'message_read', data: { id: msgData.id } }),
      )
    })
  }
}
const getMessagesHistory = async (id) => {
  storage.clr(WH)
  try {
    if (
      Array.isArray(chatState[id].messageListData) &&
      chatState[id].messageListData.length < messageListLimit
    ) {
      // закончились сообщения в истории
      return []
    }
    const res = await messageApi.chatMessagesList(WH, id, {
      limit: messageListLimit,
      page: chatState[id].messageListPage,
    })

    if (chatState[id].messageListData !== null) {
      // будет выполнено на nextTick
      scrollToLastInPart()
    }
    chatState[id].messageListData = res?.data
    chatState[id].messageListPage += 1

    if (res?.data && res.data.length) {
      chatState[id].intersections.done = false
      return res.data.reverse()
    } else {
      return []
    }
  } catch (err) {
    console.error(err)
  }
}
const getRelUsers = async (id) => {
  relData.value[id] = relData.value[id] || {}

  relData.value[id].identities = await storage.getRelations(
    messages.value[id],
    'sender',
    'account__identity',
    identityApi.identityRetrieve,
    WH,
  )
  relData.value[id].users = await storage.getRelations(
    Object.values(relData.value[id].identities),
    'user',
    'account__user',
    userApi.userRetrieve,
    WH,
  )
  await addMyData(myIdentity.value)
}
const getInitialMessages = async (id) => {
  chatState[id] = chatState[id] || {
    initialized: false,
    messageListData: null,
    messageListPage: 1,
    intersections: {
      cb: [],
      done: false,
    },
  }
  if (id && chatState[id].initialized === false) {
    try {
      chatState[id].messageListData = null
      chatState[id].messageListPage = 1

      isLoadingMessages.value = true
      messages.value[id] = messages.value[id] || []
      messages.value[id] = await getMessagesHistory(id)
      await getRelUsers(id)
      chatState[id].initialized = true
    } catch (err) {
      console.error(err)
    } finally {
      isLoadingMessages.value = false
    }
  }
  scrollToLast()
  setTimeout(execIntersectionCBs, 300)
}
const toLocaleDayStr = (date) =>
  new Date(date).toLocaleDateString('ru', { month: 'long', day: 'numeric' })
const sameDay = (date1, date2) =>
  toLocaleDayStr(date1) === toLocaleDayStr(date2)
const addNewUser = async (id, sender) => {
  // добавляет новый identity, если пришло входящее от identity которого нет в related_data
  const data = relData.value[id] || {}
  const idens = data.identities || {}
  const users = data.users || {}
  const newIden = await identityApi.identityRetrieve(WH, sender)
  if (!newIden) return
  idens[newIden.id] = newIden
  if (!newIden.user) return
  const newUser = await userApi.userRetrieve(WH, newIden.user)
  users[newUser.id] = newUser
}
const getUserName = (id, sender) => {
  const data = relData.value[id]
  if (!data?.users || !data?.identities || !sender) return ''
  if (data.identities[sender]) {
    if (data.identities[sender].role === 'ROBOT') {
      return 'Автоответчик'
    }
    if (data.identities[sender].role === 'SYSTEM') {
      return 'Системный автоответчик'
    }
    const user = data.users[data.identities[sender].user]
    return user ? `${user?.name || ''} ${user?.surname || ''}` : null
  } else {
    return null
  }
}
const addMyData = async (myIden) => {
  if (!props.chatId || !myIden?.id) return
  // добавление своих identity и user в общую коллекцию
  relData.value[props.chatId] = relData.value[props.chatId] || {}
  if (relData.value[props.chatId]) {
    relData.value[props.chatId].identities =
      relData.value[props.chatId]?.identities || {}
    relData.value[props.chatId].identities[myIden.id] = myIden

    relData.value[props.chatId].users = relData.value[props.chatId]?.users || {}
    myUser.value = await userApi.userRetrieve(WH, myIden.user)
    if (relData.value[props.chatId]?.users) {
      relData.value[props.chatId].users[myUser.value.id] = myUser.value
    }
  }
}
const messagesScrollHandler = async () => {
  if (
    messagesContEl.value &&
    messagesContEl.value.scrollTop === 0 &&
    !isLoadingMessages.value
  ) {
    isLoadingMessages.value = true
    const msgs = await getMessagesHistory(props.chatId)
    if (msgs.length) {
      messages.value[props.chatId] = msgs.concat(
        messages.value[props.chatId] || [],
      )
    }
    isLoadingMessages.value = false
  }
}
const calcMsgClasses = (msg) => {
  const classes = []
  classes.push(
    msg.sender === myIdentity.value?.id
      ? 'msg-row-send justify-content-end'
      : 'msg-row-receive justify-content-start',
  )
  if (!msg.id) classes.push('opacity-50')
  return classes.join(' ')
}

const setupIntersectionForMessage = (parent, child) => {
  let observer

  function onIntersection(entries) {
    /*
     * При открытии чата или подгрузке из истории когда создаются строки сообщений выполняется прокрутка вниз, на это реагирует observer
     * несколько раз и правильное состояние видимости устанавливается на последней реакции.
     * Поэтому нельзя просто вызвать колбэк на первой реакции. Для получения последнего правильного состояния
     * устанавливается dataset.visible на каждой реакции, а колбеки сохраняются в initialIntersecions.cb
     * для того, чтобы вызвать колбэк с правильным (последним) состоянием. Потом, при появлении новых строк колбек вызывается сразу по intersection
     */
    const entry = entries[0]
    entry.target.dataset.visible = entry.isIntersecting.toString()

    const cb = () => {
      if (entry.target.classList.contains('msg-row-send')) {
        // отправляемые сообщения не нужны
        observer.unobserve(entry.target)
        entry.target.removeAttribute('data-visible')
        return
      }
      if (
        entry.target.dataset.visible === 'true' &&
        entry.target.dataset.read === 'false' &&
        entry.target.classList.contains('msg-row-receive')
      ) {
        sendRead(entry.target.dataset.id)
        entry.target.dataset.read = true
        observer.unobserve(entry.target)
      }
    }

    if (!chatState[props.chatId]) return

    if (chatState[props.chatId].intersections.done) {
      cb()
    } else {
      chatState[props.chatId].intersections.cb.push(cb)
    }
  }

  // define an observer instance
  observer = new IntersectionObserver(onIntersection, {
    root: parent, // default is the viewport
    threshold: 0.9, // percentage of target's visible area. Triggers "onIntersection"
  })
  observer.observe(child)
}
const setupMutationObserverForMessage = (parent) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        // Устанавливаем intersection obs. для всех добавленных сообщений т.к. class msg-row-receive вычисляемый и на данном этапе может быть не установлен
        if (node.classList && node.classList.contains('msg-row')) {
          setupIntersectionForMessage(parent, node)
        }
      })
    })
  })

  observer.observe(parent, { childList: true })
}

const resizeHandler = () => {
  isMobileAside.value = asideEl.offsetHeight === window.innerHeight
}

const wsAction = () => {
  websocket.connection.addEventListener('message', (ev) => {
    const message = getWSMessage(ev)
    if (!message) return

    const id = message.data?.chat
    messages.value[id] = messages.value[id] || []

    if (message.type === 'message_read') {
      const { id } = message.data
      const msgRowEl = messagesContEl.value.querySelector(`[data-id="${id}"]`)
      if (msgRowEl) {
        msgRowEl.dataset.read = `${true}`
      }
      return
    }

    if (
      message.type === 'chat' &&
      message.data.sender == myIdentity.value?.id
    ) {
      // Обновляем временное сообщение (opacity) если isSenderActive.value = true
      if (isSenderActive.value) {
        messages.value[id][messages.value[id].length - 1] = message.data
        isSenderActive.value = false
      } else {
        // отображаем как отправленное если sender тотже. (было отправлено в другой вкладке)
        messages.value[id].push(message.data)
      }
      return
    }

    if (message.type !== 'chat' || !message.data.content) {
      return
    }

    let senderName = addNewUser(message.data.chat, message.data.sender)
    if (!senderName) {
      addNewUser().then(() => {
        senderName = addNewUser(message.data.chat, message.data.sender)
        message.data.senderName = senderName
        messages.value[id].push(message.data)
        scrollToLast('smooth')
      })
    } else {
      messages.value[id].push(message.data)
      scrollToLast('smooth')
    }
  })
}
setWSAction(wsAction)

watch(() => props.chatId, getInitialMessages)
watch(myIdentity, async (val) => {
  if (val) addMyData(val)
})

onMounted(async () => {
  nextTick(() => {
    messagesContEl.value = document.getElementById('chat-item-messages')
    if (!messagesContEl.value) return
    messagesContEl.value.addEventListener('scroll', messagesScrollHandler)
    setupMutationObserverForMessage(messagesContEl.value)

    asideEl = document.querySelector('.aside-right .sidebar')
    onResize = debounce(resizeHandler, 10)
    resizeObserver = new ResizeObserver(onResize)
    resizeObserver.observe(asideEl)
  })
})
onUnmounted(() => {
  resizeObserver.unobserve(asideEl)
  storage.clr(WH)
})
</script>
