<template>
  <div
    v-if="route.name !== 'Login' && store.getters.webPhoneStarted"
    v-show="store.getters.webPhoneVisible"
    id="web-phone-container"
    class="web-phone"
    :class="{'web-phone__normal': sizeState.normal, 'web-phone__min': sizeState.minimized, 'web-phone__max': sizeState.maximized}"
  >
    <div class="web-phone-toolbar">
      <div class="web-phone-toolbar__inn">
        <CIcon title="Закрыть" :icon="cilX" :size="iconSize" role="button" @click="store.commit('toggleWebPhoneVisible', false)"/>
        <div class="web-phone-toolbar__middle"></div>
        <div class="web-phone-toolbar__right">
          <CIcon v-if="sizeState.minimized" title="Развернуть" :icon="cilWindowMaximize" :size="iconSize" role="button" @click="toNormalSize"/>
          <CIcon v-if="!sizeState.minimized" title="Свернуть" :icon="cilWindowMinimize" :size="iconSize" role="button" @click="toMinSize"/>
          <CIcon v-if="!sizeState.maximized" title="На весь экран" :icon="cilFullscreen" :size="iconSize" role="button" @click="toMaxSize"/>
          <CIcon v-if="sizeState.maximized" title="Минимизировать" :icon="cilFullscreenExit" :size="iconSize" role="button" @click="toNormalSize"/>
        </div>
      </div>
    </div>
    <iframe
      :src="url"
      width="100%"
      height="100%">
    </iframe>
  </div>
</template>

<script setup>
import {reactive, /*watch*/} from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { cilWindowMaximize, cilWindowMinimize,  cilX, cilFullscreenExit, cilFullscreen } from '@coreui/icons'

/*
  // для открытия на странице
  $store.commit('toggleWebPhoneStarted', true)
  $store.commit('toggleWebPhoneVisible')

  // сейчас этот компонент не используется т.к. webphone открываем в отдельном окне
*/

const route = useRoute()
const store = useStore()
const sizeState = reactive({
  minimized: false,
  normal: true,
  maximized: false
})
// TODO брать url из настроек компании telephony_webphone_url
const url = 'https://pbx.rnsb.su/webphone/'
const iconSize = 'xl'
// const origDocTitle = document.title

const toNormalSize = () => {
  sizeState.normal = true
  sizeState.minimized = false
  sizeState.maximized = false
}
const toMaxSize = () => {
  sizeState.maximized = true
  sizeState.normal = false
  sizeState.minimized = false
}
const toMinSize = () => {
  sizeState.minimized = true
  sizeState.maximized = false
  sizeState.normal = false
}
// watch(() => store.state.webPhoneStarted, (val) => {
//   document.title = val ? `⭕ ${origDocTitle} ⭕` : origDocTitle
// })
</script>
