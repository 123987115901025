// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AccountApiMethodsCls {
    async allOnlineList(wh, {filters} = {}, extra_data = null) {
      // Получение списка статусов онлайн. Фильтр - одно из значений константы STATUS_ONLINE_FILTER
      // filters: filter
      const params = { ...filters }
      console.log('allOnlineList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.allOnline.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async authinfoRetrieve(wh, extra_data = null) {
      // Получение информации о текущей аутентификации
      // без параметров
      console.log('authinfoRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.authinfo.retrieve()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async changeCreate(wh, data, extra_data = null) {
      // data: IdentityChange
      // Смена роли
      console.log('changeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.change.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyRolesList(wh, id, extra_data = null) {
      // Список ролей в компании
      console.log('companyRolesList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.roles.list(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companySettingRetrieve(wh, id, extra_data = null) {
      // Получение настроек компании
      console.log('companySettingRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.setting.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companySettingPutUpdate(wh, id, data, extra_data = null) {
      // data: UpdateCompanySetting
      // Обновление настроек компании
      console.log('companySettingPutUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.settingPut.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hijackCreate(wh, data, extra_data = null) {
      // data: UserHijack
      // Получение токена другого пользователя
      console.log('hijackCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.hijack.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hijackIdentityCreate(wh, data, extra_data = null) {
      // data: IdentityHijack
      // Получение токена другой личности
      console.log('hijackIdentityCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.hijack.identity.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async logoutDelete(wh, data) {
      // data: Logout
      // Выход из системы для временных токенов
      // Данный метод действует только для временных токенов. При вызове без параметров прекращается только текущая сессия. Для постоянного токена - ошибка 400. 
      console.log('logoutDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.account.logout.delete(), body: data})
      ctx.$services.storage.del('None', id, wh)
      return null
    }
    async myrolesList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка доступных мне ролей, за исключением CUSTOMER
      // без параметров
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('myrolesList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.myroles.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async offlineCreate(wh, extra_data = null) {
      // Поменять статус на офлайн
      console.log('offlineCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.offline.create()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async onlineCreate(wh, extra_data = null) {
      // Поменять статус на онлайн
      console.log('onlineCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.online.create()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async statusRetrieve(wh, extra_data = null) {
      // Получение статуса для Identity
      console.log('statusRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.status.retrieve()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async supervisorList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение агентов супервизора
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('supervisorList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.supervisor.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async supervisorOfflinePost(wh, id, extra_data = null) {
      // Поменять статус агента на офлайн (для супервизора, или админа)
      console.log('supervisorOfflinePost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.supervisor.offline.post(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async supervisorOnlinePost(wh, id, extra_data = null) {
      // Поменять статус агента на онлайн (для супервизора, или админа)
      console.log('supervisorOnlinePost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.supervisor.online.post(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async userSettingRetrieve(wh, id, extra_data = null) {
      // Получение настроек юзера
      console.log('userSettingRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.setting.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async userSettingPutUpdate(wh, id, data, extra_data = null) {
      // data: UpdateUserSettings
      // Обновление настроек юзера
      console.log('userSettingPutUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.settingPut.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.accountApi = new AccountApiMethodsCls()
}

