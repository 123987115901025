// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ExternalsApiMethodsCls {
    async telephonyLeadbackCreate(wh, token, extra_data = null) {
      console.log('telephonyLeadbackCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.token.telephony.leadback.create(token)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async telephonyLocalStatusList(wh, token, extra_data = null) {
      console.log('telephonyLocalStatusList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.token.telephony.localStatus.list(token)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async telephonyTelecallCreate(wh, token, extra_data = null) {
      console.log('telephonyTelecallCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.token.telephony.telecall.create(token)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async timelinesWebhookCreate(wh, token, data, extra_data = null) {
      // data: TimelinesWebhook
      console.log('timelinesWebhookCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.token.timelines.webhook.create(token), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.externalsApi = new ExternalsApiMethodsCls()
}

