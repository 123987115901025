export const showFileWindow = (file, { serverUrl, windowFeatures } = {}) => {
  const url = typeof file === 'string' ? file : file.url
  // 'popup=0' - открывать в новой вкладке
  const _windowFeatures = windowFeatures || 'popup=1,left=100,top=100,width=800,height=600'
  const handle = window.open(
    serverUrl ? url.replace(/^(.*)(\/files.*)/, `${serverUrl}$2`) : url,
    '_blank',
    _windowFeatures
  )
  if (!handle) {
    console.log('Окно было заблокировано')
  }
}
