<template>
  <span v-if="hasNewIcon" :class="iconClass"></span>
  <AppIconDeprecated
    v-else
    :icon="icon"
    :color="color"
    :size="size"
    :class="props.class"
  />
</template>

<script setup>
import { computed, inject } from 'vue'
import AppIconDeprecated from '@/plugins/app-icons/AppIconDeprecated.vue'

// JSON всех иконок nsb-icons сейчас нужен для переходного периода с AppIconDeprecated на новый AppIcon
// когда все иконки будут nsb-icons использование этого файла можно убрать для улучшения перфоманса
const nsbIconsData = inject('nsbIconsData')

const props = defineProps({
  icon: {
    type: String,
    default: 'poop',
  },
  color: {
    type: String,
    default: null,
  },
  class: {
    type: String,
    default: '',
  },
  // Props for deprecated AppIconDeprecated
  // удалить когда все иконки будут nsb-icon
  // у новых иконок размер определяется размером шрифта классы fs1 - fs6
  size: {
    type: String,
    default: () => null,
  },
})

const iconClass = computed(() => {
  const icon = `nsb-icon-${props.icon}`
  const color = props.color ? ` text-${props.color} ` : ' '
  return `${icon}${color}${props.class}`.trim()
})

// удалить когда все иконки будут nsb-icon
const hasNewIcon = computed(() => {
  return nsbIconsData.icons.find((icon) => icon.properties.name === props.icon)
})
</script>
