<template>
  <div>
    <CModal
      ref="modal"
      :visible="isVisible"
      size="lg"
      backdrop="static"
      alignment="center"
      fullscreen="sm"
      @close="onClosed"
    >
      <CModalHeader dismiss>
        <CModalTitle>Выбор или добавление заказчика</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs="12" sm="5" class="pt-1 pb-4">
            <div class="mb-3">
              <CFormLabel class="pe-1 mb-0"> Телефон </CFormLabel>
              <template v-if="initData?.phones && initData.phones.length">
                <small>
                  <a
                    v-for="initDataPhone in initData.phones"
                    :key="initDataPhone"
                    href=""
                    @click.prevent="selectPhone(initDataPhone)"
                    class="form-label-dotted px-1 d-inline-block"
                  >
                    {{ initDataPhone }}
                  </a>
                </small>
              </template>
              <TelInput
                :phone="form.phone"
                :label="false"
                @input="onPhoneInput"
                @keyup="throttleSearchBuyers"
                :error-message="
                  v$.form.phone.$error
                    ? v$.form.phone.$silentErrors[0].$message
                    : null
                "
              />
            </div>
            <div class="mb-3">
              <CFormLabel> Электроная почта </CFormLabel>
              <CFormInput
                :invalid="v$.form.email.$error"
                v-model="form.email"
                @keyup="throttleSearchBuyers"
              />
              <CFormFeedback v-if="v$.form.email.$invalid" invalid>
                {{ v$.form.email.$silentErrors[0].$message }}
              </CFormFeedback>
            </div>
            <div class="mb-3">
              <CFormLabel> Фамилия </CFormLabel>
              <CFormInput
                v-model.trim="form.surname"
                :invalid="v$.form.surname.$error"
                @keyup="throttleSearchBuyers"
              />
              <CFormFeedback v-if="v$.form.surname.$error" invalid>
                {{ v$.form.surname.$silentErrors[0].$message }}
              </CFormFeedback>
            </div>
            <div class="mb-3">
              <CFormLabel> Имя </CFormLabel>
              <CFormInput
                v-model.trim="form.name"
                :invalid="v$.form.name.$error"
                @keyup="throttleSearchBuyers"
              />
              <CFormFeedback v-if="v$.form.name.$error" invalid>
                {{ v$.form.name.$silentErrors[0].$message }}
              </CFormFeedback>
            </div>
            <div class="mb-3">
              <CFormLabel> Отчество </CFormLabel>
              <CFormInput
                v-model.trim="form.patronymic"
                :invalid="v$.form.patronymic.$error"
                @keyup="throttleSearchBuyers"
              />
              <CFormFeedback v-if="v$.form.patronymic.$error" invalid>
                {{ v$.form.patronymic.$silentErrors[0].$message }}
              </CFormFeedback>
            </div>
          </CCol>

          <CCol xs="12" sm="7">
            <div
              v-if="isSearch"
              class="flex justify-content-center align-center py-4"
            >
              <CSpinner
                component="span"
                size="sm"
                aria-hidden="true"
                class="me-3"
              />
              <span>Идет поиск...</span>
            </div>
            <div
              v-else-if="searchMessage"
              class="flex justify-content-center align-center py-4"
            >
              {{ searchMessage }}
            </div>
            <template v-if="!hasInput">
              <div
                class="flex justify-content-between py-2 mb-1"
                v-for="i in 6"
                :key="i"
              >
                <div class="w-100 flex">
                  <span class="skeleton-box mx-3" style="width: 30px"></span>
                  <span class="skeleton-box flex-grow-1"></span>
                </div>
                <span class="skeleton-box mx-3" style="width: 90px"></span>
              </div>
            </template>
            <CRow v-if="!isSearch && foundBuyers?.length">
              <CCol class="overflow-scroll" style="max-height: 445px">
                <CListGroup flush>
                  <CListGroupItem
                    v-for="searchBuyerResult in foundBuyers"
                    :key="searchBuyerResult.id"
                    class="flex align-items-start justify-content-between ps-1 pe-0"
                  >
                    <div class="flex flex-column">
                      <span>
                        <small class="me-2">#{{ searchBuyerResult.id }}</small>
                        {{
                          getIdentityExtraDataValue(searchBuyerResult, 'fio')
                        }}
                      </span>
                      <div class="flex gap-2">
                        <span
                          v-if="
                            getIdentityExtraDataValue(
                              searchBuyerResult,
                              'phone',
                            )
                          "
                          class="form-label m-0"
                          >тел:
                          {{
                            getIdentityExtraDataValue(
                              searchBuyerResult,
                              'phone',
                            )
                          }}</span
                        >
                        <span
                          v-if="
                            getIdentityExtraDataValue(
                              searchBuyerResult,
                              'email',
                            )
                          "
                          class="form-label m-0"
                          >email:
                          {{
                            getIdentityExtraDataValue(
                              searchBuyerResult,
                              'email',
                            )
                          }}</span
                        >
                      </div>
                    </div>

                    <CButton
                      color="success"
                      variant="outline"
                      size="sm"
                      @click="onSelect(searchBuyerResult)"
                    >
                      Выбрать
                    </CButton>
                  </CListGroupItem>
                </CListGroup>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onCancel"> Закрыть </CButton>
        <CButton
          @click="onCreatePerson"
          color="primary"
          :disabled="v$.$errors.length || isSearch"
        >
          Создать персону
        </CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  defineEmits,
  defineProps,
  computed,
  inject,
  onUnmounted,
  nextTick,
} from 'vue'
import useVuelidate from '@vuelidate/core'
import { email, phone, names } from '@/helpers/vValidators'
import C from '@/config/back_const'
import TelInput from '@/components/custom/TelInput.vue'
import _ from 'lodash'
import { getFullName } from '@/helpers'

const WH = '5a63da7f-3917-4505-900e-825dcaae8cef'
const props = defineProps({
  initData: {
    type: Object,
    default: () => {},
  },
})
const emit = defineEmits([
  'close',
  'select',
  'show-create-person',
  'show-create-org',
])
const {
  auth: authService,
  // genericApi,
  identityApi,
  storage,
} = inject('services')

const isVisible = ref(false)
const isSearch = ref(false)
const defaultForm = (initData) => ({
  phone:
    props.initData?.phones && props.initData.phones.length
      ? props.initData.phones[0].number
      : null,
  email:
    props.initData?.contacts && props.initData.contacts.length
      ? props.initData.contacts.find((c) => c.type === 'email')?.number || props.initData.contacts.find((c) => c.includes('email'))?.split(':')?.pop()
      : null,
  name: initData?.name || null,
  surname: initData?.surname || null,
  patronymic: initData?.patronymic || null,
  org: null,
})

const searchMessage = ref('')
const hasInput = ref(false)
const form = ref(defaultForm(props.initData))
const currentIdentity = ref(null)
const currentCompanyId = ref(null)
const customerIdentity = ref(null)
// const newBuyer = ref(null)
const foundBuyers = ref(null)
const searchExtraData = ref({})
const isOrganization = computed(() => {
  if (form.value?.org?.length) return true
  return false
})

const validationsRules = computed(() => {
  return {
    form: {
      phone: {
        phone,
      },
      email: {
        email,
      },
      name: { names },
      surname: { names },
      patronymic: { names },
    },
  }
})
const v$ = useVuelidate(validationsRules, { form }, { $autoDirty: true })

const onClosed = () => {
  isVisible.value = false
  emit('close')
}
const onCancel = () => {
  isVisible.value = false
  emit('close')
}

const getIdentityExtraDataValue = (obj, prop) => {
  const { id } = obj
  return searchExtraData.value.identity_extra_data?.[id]?.[prop] || null
}

const createCustomerIdentity = async () => {
  try {
    customerIdentity.value = await identityApi.identityCreate(WH, {
      role: C.ROLES_CUSTOMER,
      company: currentCompanyId.value,
    })
  } catch (error) {
    console.error(error)
  }
}

const onCreatePerson = async () => {
  await createCustomerIdentity()
  emit('show-create-person', {
    customer: customerIdentity.value,
    form: form.value,
  })
}
const onSelect = async (searchResult) => {
  emit('select', searchResult)
}
const onPhoneInput = (phone) => {
  form.value.phone = phone
}
const selectPhone = (selectedPhone) => {
  form.value.phone = selectedPhone
  throttleSearchBuyers()
}

const searchBuyers = async () => {
  if (v$.value.$errors.length) return
  const preprocessData = {}
  if (form.value.phone) preprocessData.phone = form.value.phone
  if (form.value.email) preprocessData.email = form.value.email
  if (form.value.org) preprocessData.org = form.value.org
  if (!isOrganization.value) {
    if (form.value.surname) preprocessData.surname = form.value.surname
    if (form.value.name) preprocessData.name = form.value.name
    if (form.value.patronymic) preprocessData.patronymic = form.value.patronymic
  }
  const filters = {}
  if (form.value.phone) filters.phone = form.value.phone
  if (form.value.email) filters.contact = `email:${form.value.email}`
  if (form.value.surname || form.value.name || form.value.patronymic) {
    filters.fio = getFullName(form.value)
  }
  // if (form.value.name) preprocessData.name = form.value.name
  // if (form.value.patronymic) preprocessData.patronymic = form.value.patronymic

  try {
    isSearch.value = true
    storage.clr(WH)
    // foundBuyers.value = await genericApi.searchBuyersPut(WH, preprocessData)
    hasInput.value = Object.keys(filters).length !== 0
    if (hasInput.value) {
      const extraData = {}
      const result = await identityApi.customersList(WH, { filters }, extraData)
      searchExtraData.value = extraData
      foundBuyers.value = result.data
    }
    if (foundBuyers.value?.length === 0) {
      searchMessage.value = !hasInput.value
        ? 'Для выбора заказчика введите данные в форму'
        : 'В соответствии с Вашими данными ничего не найдено'
    } else {
      searchMessage.value = ''
      hasInput.value = true
    }
  } catch (error) {
    console.error(error)
  } finally {
    isSearch.value = false
  }
}
const throttleSearchBuyers = _.debounce(() => {
  searchBuyers()
}, 800)

onMounted(async () => {
  isVisible.value = true
  currentIdentity.value = await authService.getIdentity()
  currentCompanyId.value = currentIdentity.value.company
  await nextTick(searchBuyers)
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
