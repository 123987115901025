import { watch, ref, onUnmounted } from 'vue'

export function useUpdateWatcher(cb) {
  const lastVersion = ref(null)
  const watchingUpdate = ref(false)
  const diff = ref({})
  const throttleTime = 1500
  const ignore = ['updated_at', '__storage_counter']
  let unWatchUpdate = null

  const findDiff = (nVal, pVal) => {
    const keys = new Set(Object.keys(nVal).concat(Object.keys(pVal)))
    keys.forEach((key) => {
      if (!ignore.includes(key)) {
        if (JSON.stringify(nVal[key]) !== JSON.stringify(pVal[key]))
          diff.value[key] = [nVal[key], pVal[key]]
      }
    })
    // TODO есть баг https://tracker.yandex.ru/BACK-2883 который мешает вычислить правильно diff
  }

  const watchUpdate = (objectRef) => {
    lastVersion.value = { ...objectRef.value }
    unWatchUpdate = watch(() => objectRef.value.__storage_counter, () => {
      if (
        watchingUpdate.value ||
        objectRef?.value == null ||
        !objectRef.value?.id
      )
        return
      watchingUpdate.value = true
      const nVal = { ...objectRef.value }
      const pVal = { ...lastVersion.value }
      findDiff(nVal, pVal)
      if (cb) cb(diff.value)
      lastVersion.value = { ...objectRef.value }
      setTimeout(() => (watchingUpdate.value = false), throttleTime)
    })
  }

  onUnmounted(() => {
    if (unWatchUpdate) unWatchUpdate()
  })

  return {
    watchUpdate,
    unWatchUpdate,
  }
}
