<template>
    <a href="#"
       class="text-primary"
       @click.prevent="showDetailsModal = true"
    >
        {{ linkText }}
    </a>
    <CModal ref="modal" :visible="showDetailsModal" size="xl" backdrop="static" alignment="center" fullscreen="md"
        @close="onClosed">
        <CModalHeader dismiss>
            <span class="h5">Детали платежа</span>
        </CModalHeader>
        <CModalBody>
            <ul>
                <li>
                    <b>Инфо</b>:
                    <ValueInfo :field="contend?.info" />
                </li>
                <li>
                    <b>Плательщик</b>:
                    <ul>
                        <li>ИНН:
                            <ValueInfo :field="contend?.payer?.inn" />
                        </li>
                        <li>КПП:
                            <ValueInfo :field="contend?.payer?.kpp" />
                        </li>
                    </ul>

                </li>
                <li>
                    <b>Получатель платежа</b>:
                    <ul>
                        <li>ИНН:
                            <ValueInfo :field="contend?.payee?.inn" />
                        </li>
                        <li>КПП:
                            <ValueInfo :field="contend?.payee?.kpp" />
                        </li>
                    </ul>

                </li>
                <li>
                    <b>Номер договора</b>:
                    <ValueInfo :field="contend?.contract?.number" />
                </li>


                <li>
                    <b>ID (recipient) </b>:
                    <ul>
                        <li>Организации:
                            <ValueInfo :field="contend?.recipient?.org" />
                        </li>
                        <li>Отеля:
                            <ValueInfo :field="contend?.recipient?.hotel" />
                        </li>
                    </ul>

                </li>
                <li>
                    <b>Платеж (statement)</b>:
                    <ul>
                        <li v-if="contend?.statement?.date">Дата:
                            <ValueInfo :field="toDate(contend?.statement?.date)" />
                        </li>
                        <li v-if="contend.statement?.number">Номер:
                            <ValueInfo :field="contend.statement?.number" />
                        </li>
                    </ul>
                </li>
                <li>
                    <b>Документ (document)</b>:
                    <ul>
                        <li v-if="contend?.document?.date">Дата:
                            <ValueInfo :field="toDate(contend?.document?.date)" />
                        </li>
                        <li v-if="contend.document?.number">Номер:
                            <ValueInfo :field="contend.document?.number" />
                        </li>
                    </ul>
                </li>
                <li>
                    <b>1C</b>: <code> <ValueInfo :field="contend?.uuid1c?.value" /> </code>
                </li>
                <li>
                    <b>Сумма</b>: <ValueInfo :field="copToRub(contend?.amount?.value)" />
                </li>
                <li>
                    <b>voucher</b>:
                    <ValueInfo :field="contend?.voucher || ''" />
                </li>
            </ul>
        </CModalBody>
        <CModalFooter>
            <CButton color="secondary" @click="onClosed">Закрыть</CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
import { ref, onMounted } from "vue"
import ValueInfo from "@/components/shared/ValueInfo";
const WH = 'PopupXml'
import { copToRub } from "@/helpers/amountHelper"

export default {
    name: WH,
    components: {
      ValueInfo,
    },
    props: {
      linkText: {
        type: String,
        required: false,
        default: 'Подробнее'
      },
      data: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const showDetailsModal = ref(false)
        const onClosed = () => {
            showDetailsModal.value = false
        }
        const contend = ref({})
        onMounted(() => {
            const XmlNode = new DOMParser().parseFromString(props.data, 'text/xml');
            xmlToJson(XmlNode);
            contend.value = xmlToJson(XmlNode).payment
        })
        const xmlToJson = (xml) => {
            // Create the return object
            var obj = {};

            if (xml.nodeType == 1) {
                // element
                // do attributes
                if (xml.attributes.length > 0) {
                    obj = {};
                    for (var j = 0; j < xml.attributes.length; j++) {
                        var attribute = xml.attributes.item(j);
                        obj[attribute.nodeName] = attribute.nodeValue;
                    }
                }
            } else if (xml.nodeType == 3) {
                // text
                obj = xml.nodeValue;
            }

            // do children
            // If all text nodes inside, get concatenated text from them.
            var textNodes = [].slice.call(xml.childNodes).filter(function (node) {
                return node.nodeType === 3;
            });
            if (xml.hasChildNodes() && xml.childNodes.length === textNodes.length) {
                obj = [].slice.call(xml.childNodes).reduce(function (text, node) {
                    return text + node.nodeValue;
                }, "");
            } else if (xml.hasChildNodes()) {
                for (var i = 0; i < xml.childNodes.length; i++) {
                    var item = xml.childNodes.item(i);
                    var nodeName = item.nodeName;
                    if (typeof obj[nodeName] == "undefined") {
                        obj[nodeName] = xmlToJson(item);
                    } else {
                        if (typeof obj[nodeName].push == "undefined") {
                            var old = obj[nodeName];
                            obj[nodeName] = [];
                            obj[nodeName].push(old);
                        }
                        obj[nodeName].push(xmlToJson(item));
                    }
                }
            }
            return Object.keys(obj).length ? obj : '';
        }
        const toDate = (str) => {
            return str.split('-').reverse().join('.')
        }
        return {
            onClosed,
            showDetailsModal,
            xmlToJson,
            contend,
            toDate,
            copToRub
        }
    }
}
</script>
