// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BuyercontractApiMethodsCls {
    async buyerContractList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка BuyerContract
      // filters: created_at, updated_at, tour, buyer, amount, invoice_number, invoice_date,
      //          bank_account, is_active, is_rendered, is_send, is_received, split_amount,
      //          deferred_amount, payment_to_object, booking
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('buyerContractList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async buyerContractCreate(wh, data, extra_data = null) {
      // data: BuyerContract
      // Создание BuyerContract
      console.log('buyerContractCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractRetrieve(wh, id, extra_data = null) {
      // Получение BuyerContract
      console.log('buyerContractRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__buyercontract', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__buyercontract', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractUpdate(wh, id, data, extra_data = null) {
      // data: BuyerContract
      // Обновление BuyerContract
      console.log('buyerContractUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractDelete(wh, id) {
      // Удаление BuyerContract
      console.log('buyerContractDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.buyerContract.delete(id)})
      ctx.$services.storage.del('core__buyercontract', id, wh)
      return null
    }
    async buyerContractAsTextRetrieve(wh, id, extra_data = null) {
      // Печать HTML
      console.log('buyerContractAsTextRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__buyercontract', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__buyercontract', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.asText.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractChangeDeferredPaymentPost(wh, id, data, extra_data = null) {
      // data: DeferredPaymentCreate
      // Изменить сумму отложенного платежа
      console.log('buyerContractChangeDeferredPaymentPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.changeDeferredPayment.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractCreateDeferredPaymentPost(wh, id, data, extra_data = null) {
      // data: DeferredPaymentCreate
      // Создать отложенный платеж
      console.log('buyerContractCreateDeferredPaymentPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.createDeferredPayment.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractDeleteDeferredPaymentPost(wh, id, extra_data = null) {
      // Удалить отложенный платеж
      console.log('buyerContractDeleteDeferredPaymentPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.deleteDeferredPayment.post(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractGetUpdate(wh, id, data, extra_data = null) {
      // data: BuyerContractPrint
      // Скачать документы
      console.log('buyerContractGetUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.get.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractPrintUpdate(wh, id, data, extra_data = null) {
      // data: BuyerContractPrint
      // Печать документов
      console.log('buyerContractPrintUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.print.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerContractSendUpdate(wh, id, data, extra_data = null) {
      // data: BuyerContractSend
      // Выслать документы клиенту
      console.log('buyerContractSendUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyerContract.send.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.buyerContractApi = new BuyercontractApiMethodsCls()
}

