// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgencypaymentApiMethodsCls {
    async buyerPaymentImportCreate(wh, data, extra_data = null) {
      // data: ImportBuyerPaymentsCreate
      // Создание импорта платежей покупателей
      console.log('buyerPaymentImportCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.import.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentImportAcceptPartial(wh, id, data, extra_data = null) {
      // data: ImportBuyerPaymentsAccept
      // Принятие платежей покупателей
      console.log('buyerPaymentImportAcceptPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.import.accept.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentImportProcessPartial(wh, id, data, extra_data = null) {
      // data: ForceForeground
      // Импорт платежей покупателей
      // Параметры "min_date" и "max_date" пока не влияют на результат
      console.log('buyerPaymentImportProcessPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.import.process.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentSplitCreate(wh, data, extra_data = null) {
      // data: BuyerPaymentSplit
      // Создание разнесения платежа покупателя
      console.log('buyerPaymentSplitCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.split.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentSplitRetrieve(wh, id, extra_data = null) {
      // Получение конкретного разнесения платежа покупателя
      console.log('buyerPaymentSplitRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__buyerpaymentsplit', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__buyerpaymentsplit', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.split.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentSplitDeleteDelete(wh, id) {
      // Удаление разнесения платежа покупателя
      console.log('buyerPaymentSplitDeleteDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.split.delete.delete(id)})
      ctx.$services.storage.del('None', id, wh)
      return null
    }
    async buyerPaymentSplitUpdatePartial(wh, id, data, extra_data = null) {
      // data: BuyerPaymentSplitUpdate
      // Обновление разнесения платежа покупателя
      console.log('buyerPaymentSplitUpdatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.buyerPayment.split.update.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async buyerPaymentListSplitList(wh, id, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка разнесений для конкретного платежа покупателя
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('buyerPaymentListSplitList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.buyerPayment.listSplit.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.agencyPaymentApi = new AgencypaymentApiMethodsCls()
}

