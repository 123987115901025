// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BookerApiMethodsCls {
    async bookerList(wh, {limit, offset, page} = {}, extra_data = null) {
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('bookerList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booker.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, false, extra_data), count:response.data.count}
    }
    async bookerRetrieve(wh, id, extra_data = null) {
      console.log('bookerRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booker.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async bookerPricesPut(wh, data, extra_data = null) {
      // Список цен из базы
      // @see integratons.booker.get_prices
      console.log('bookerPricesPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.prices.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookerAttachCreate(wh, booker, data, extra_data = null) {
      // Связывает объект системы бронирования с объектом НСБ
      // @see integratons.booker.attach
      console.log('bookerAttachCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.attach.create(booker), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookerDetachCreate(wh, booker, data, extra_data = null) {
      // Отвязывает объект системы бронирования от объекта НСБ
      // @see integratons.booker.detach
      console.log('bookerDetachCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.detach.create(booker), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookerDetachedList(wh, booker, {limit, offset, page} = {}, extra_data = null) {
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('bookerDetachedList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.detached.list(booker, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async bookerNewAndChangedList(wh, booker, {limit, offset, page} = {}, extra_data = null) {
      // Возвращает список несвязанных объектов - флаг "new"
      // @see integratons.booker.get_new_and_chenged
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('bookerNewAndChangedList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.newAndChanged.list(booker, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async bookerSynchroniseCreate(wh, booker, data, extra_data = null) {
      // Синхронизирует объект системы бронирования с объектом НСБ
      // @see integratons.booker.synchronise
      console.log('bookerSynchroniseCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.synchronise.create(booker), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookerBookingCreate(wh, booker, booking_id, data, extra_data = null) {
      // Выполняет бронирование
      // @see integratons.booker.booking
      console.log('bookerBookingCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.booking.create(booker, booking_id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookerPut(wh, booker, hotel_id, data, extra_data = null) {
      // Выдает стоимость бронирования
      // @see integratons.booker.calculate
      console.log('bookerPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.put(booker, hotel_id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookerBookingPricePut(wh, booker, hotel_id, data, extra_data = null) {
      // Выдает стоимость бронирования
      // @see integratons.booker.calculate
      console.log('bookerBookingPricePut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.bookingPrice.put(booker, hotel_id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookerPlacesPut(wh, booker, hotel_id, data, extra_data = null) {
      // data: GetPlaces
      // Список свободных мест из базы
      // @see integratons.booker.get_free_places
      console.log('bookerPlacesPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.places.put(booker, hotel_id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookerInfoList(wh, booker, obj_id, obj_type, {limit, offset, page} = {}, extra_data = null) {
      // Возвращает созданный но не сохраненный объект(отель, категория, программа)
      // @see integratons.booker.get_info
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('bookerInfoList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.info.list(booker, obj_id, obj_type, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async bookerBookersList(wh, hotel_id, {limit, offset, page} = {}, extra_data = null) {
      // Букеры доступные для отеля
      // @see integratons.booker.get_bookers
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('bookerBookersList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.booker.bookers.list(hotel_id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async bookersList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Список доступных букеров
      // @see integratons.booker.list_bookers
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('bookersList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.bookers.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.bookerApi = new BookerApiMethodsCls()
}

