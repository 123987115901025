<template>
  <div class="tel-input">
    <CFormLabel v-if="props.label">
      {{ props.label }}
    </CFormLabel>
    <vue-tel-input
      :value="props.phone || ''"
      :input-options="{
        placeholder: ''
      }"
      @input="onInput"
      class="tel-input__component"
      :class="{
        'vue-tel-input--sm': props.size === 'sm',
        'vue-tel-input--lg': props.size === 'lg',
        'vue-tel-input--invalid': state.invalid
      }"
    />
    <small
      v-if="state.errorMessage"
      class="d-block text-danger w-100 mt-1"
    >
      {{ state.errorMessage }}
    </small>
  </div>
</template>

<script setup>
import {
  reactive,
  watch
} from 'vue'
import { VueTelInput } from 'vue3-tel-input'

const emit = defineEmits(['input'])

const props = defineProps({
  phone: {
    type: String,
    default: () => ''
  },
  label: {
    type: [String, Number, Boolean],
    default: () => false
  },
  size: {
    type: [String, Boolean],
    default: () => false
  },
  invalid: {
    type: Boolean,
    default: () => false
  },
  errorMessage: {
    type: [String, Number, Boolean],
    default: () => false
  }
})

const state = reactive({
  invalid: props.invalid,
  errorMessage: props.errorMessage
})

watch(() => props.invalid, (newValue) => {
  state.invalid = newValue
})

watch(() => props.errorMessage, (newValue) => {
  state.errorMessage = newValue
})

const onInput = (ev, phoneObj) => {
  emit('input', phoneObj?.number || ev?.target?.value || null)
}
</script>
