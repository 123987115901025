// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ReminderApiMethodsCls {
    async reminderList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка напоминаний для того, кому они предназначены (своих)
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('reminderList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.reminder.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async reminderCreate(wh, data, extra_data = null) {
      // data: ReminderCreate
      // Создание напоминания
      console.log('reminderCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.reminder.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reminderAllList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение списка всех напоминаний
      // filters: target, is_expired
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('reminderAllList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.reminder.all.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async reminderRetrieve(wh, id, extra_data = null) {
      // Получение напоминания
      console.log('reminderRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__reminder', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__reminder', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.reminder.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reminderDelete(wh, id) {
      // Закрыть напоминание
      console.log('reminderDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.reminder.delete(id)})
      ctx.$services.storage.del('core__reminder', id, wh)
      return null
    }
    async reminderPartial(wh, id, data, extra_data = null) {
      // data: ReminderUpdate
      // Изменение напоминания
      console.log('reminderPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.reminder.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.reminderApi = new ReminderApiMethodsCls()
}

