export const nsblogo = [
  '80 70',
  `
  <title>Логотип НСБ</title>
  <g>
    <g style="fill:#fff;">
    </g>
      <path class="cls-1" d="M194.36,354.8h11.37c0-.06,0-.12.05-.19,2-6.37,5.46-11.52,9.77-14.51a26.51,26.51,0,0,0-21.19,14.7Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-2" d="M190.71,366.5" transform="translate(-180.17 -340.1)"/><path class="cls-2" d="M245.08,366.5" transform="translate(-180.17 -340.1)"/>
      <polygon class="cls-1" points="25.17 24.4 17.47 24.4 17.47 17.44 13.8 17.44 13.8 35.26 17.47 35.26 17.47 27.82 25.17 27.82 25.17 35.26 28.84 35.26 28.84 17.44 25.17 17.44 25.17 24.4"/>
      <path class="cls-1" d="M221.21,357.42c-6,0-9.08,3-9.08,8.79,0,6.15,3.05,9.26,9.08,9.26a13.25,13.25,0,0,0,4.9-.86l.2-.08v-3.61l-.46.21a10.5,10.5,0,0,1-4.41.93c-3.72,0-5.53-1.91-5.53-5.85,0-3.61,1.81-5.37,5.53-5.37a10.5,10.5,0,0,1,4.41.93l.46.21v-3.61l-.2-.08A13,13,0,0,0,221.21,357.42Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M236,364.86h-1.66v3.42h1.56c2.37,0,2.65,1.1,2.65,1.92s-.21,1.7-2,1.7h-3.39V361h8.36v-3.42h-12v17.81h7.27c3.65,0,5.51-1.77,5.51-5.28S240.12,364.86,236,364.86Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M256,361h-.07a1.55,1.55,0,0,1-1.48-1.62,6.58,6.58,0,0,0-.32-2.4c-1.2-3.57-5.62-6-12.44-6.78a1.56,1.56,0,1,1,.37-3.09c8.08.95,13.41,4.11,15,8.88a9.5,9.5,0,0,1,.48,3.53A1.55,1.55,0,0,1,256,361Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M255.48,363a1.39,1.39,0,0,0-1.43-.12l-7.92,3.83a1.41,1.41,0,1,0,1.24,2.54l2.77-1.34c-5.36,6.05-15.13,11.95-26.3,15.7-9.61,3.22-19.17,4.59-26.93,3.86-7.22-.68-12.06-3.19-13.3-6.87s.9-8.22,5.93-13a1.54,1.54,0,0,0,.06-2.19,1.56,1.56,0,0,0-2.2-.06c-6,5.68-8.35,11.45-6.74,16.25,1.67,5,7.34,8.17,16,9q2.17.21,4.53.21a76.29,76.29,0,0,0,23.68-4.22c11.88-4,22-10.15,27.74-16.69l-.42,3.57a1.41,1.41,0,0,0,1.24,1.57h.17a1.42,1.42,0,0,0,1.4-1.25l1.11-9.44A1.42,1.42,0,0,0,255.48,363Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M207.09,354.8h10.43V340.4C212.93,343,209.17,348.2,207.09,354.8Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M218.83,340.4v14.4h10.43C227.19,348.2,223.42,343,218.83,340.4Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M232.52,344.17a26.5,26.5,0,0,0-11.72-4.07c4.32,3,7.81,8.14,9.77,14.51l.06.19H242A26.78,26.78,0,0,0,232.52,344.17Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M200.71,386.46h.51a57.9,57.9,0,0,0,7.92-.57,32.55,32.55,0,0,1-3.35-7.51l-.06-.2H194.36A26.66,26.66,0,0,0,200.71,386.46Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M209.3,391.5a26.2,26.2,0,0,0,6.25,1.4,21.28,21.28,0,0,1-2.42-2C211.84,391.14,210.57,391.34,209.3,391.5Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M217.52,384.24v-6.05H207.09a31.15,31.15,0,0,0,3.46,7.5C212.83,385.33,215.16,384.85,217.52,384.24Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M214.73,390.6a18.12,18.12,0,0,0,2.79,2V390C216.59,390.21,215.66,390.41,214.73,390.6Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M223.5,382.48q2.49-.84,4.88-1.82c.32-.79.62-1.62.88-2.47H218.83v5.71Q221.15,383.26,223.5,382.48Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M218.83,389.66v2.94a19.05,19.05,0,0,0,5.16-4.42C222.26,388.73,220.54,389.23,218.83,389.66Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M233.85,378.19h-3.22l-.06.2c-.16.53-.34,1.06-.53,1.57Q232,379.12,233.85,378.19Z" transform="translate(-180.17 -340.1)"/>
      <path class="cls-1" d="M226.2,387.44a22.43,22.43,0,0,1-5.4,5.46,26.51,26.51,0,0,0,19.56-11.85A83.9,83.9,0,0,1,226.2,387.44Z" transform="translate(-180.17 -340.1)"/>

  </g>
`,
]
