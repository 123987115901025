<template>
  <CModal
    ref="modal"
    :visible="visible"
    size="xl"
    fullscreen="lg"
    backdrop="static"
    alignment="center"
    @close="onCloseModal"
  >
    <CModalHeader dismiss>
      <CModalTitle :id="`id-title-${WH}`"></CModalTitle>
    </CModalHeader>
    <CModalBody>
      <span>{{ fileName }}</span>
      <PaymentImportFileView
        :id="id"
        :task-id="taskId"
        :run-parse="runParse"
        :payment-type="paymentType"
        :modal-wh="WH"
        @fail="onFail"
      />
    </CModalBody>
    <CModalFooter>
      <div class="flex justify-content-end flex-row-reverse">
        <CButton color="secondary" class="mx-2" @click="onCloseModal"
          >закрыть</CButton
        >
      </div>
    </CModalFooter>

    <CModalBody>
      <ThePodval
        title="Импорт платежей системы. Просмотр импорта"
        :tasks="[
          'https://tracker.yandex.ru/BACK-1832',
          'https://tracker.yandex.ru/BACK-556',
        ]"
        :wiki="[]"
        :uuid="WH"
      />
    </CModalBody>
  </CModal>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import PaymentImportFileView from '@/components/_payment/PaymentImportFileView.vue'
import ThePodval from '@/components/_shared/ThePodval.vue'

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
  fileName: {
    type: String,
    required: false,
    default: '',
  },
  runParse: {
    type: Boolean,
    required: false,
    default: false,
  },
  taskId: {
    type: Number,
    required: false,
    default: null,
  },
  paymentType: {
    type: Number,
    required: false,
    default: undefined,
  },
})
const emit = defineEmits(['close'])

const WH = 'd76b5d2d-05b9-4056-b6c3-4db8cec8f01e'

const visible = ref(false)

const onCloseModal = () => {
  visible.value = false
  emit('close')
}

const onFail = () => {
  onCloseModal()
}

watch(
  () => props.show,
  (val) => {
    visible.value = val
  },
)

onMounted(() => {
  visible.value = props.show
})
</script>
