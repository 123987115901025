<template>
  <CModal
    ref="modal"
    :visible="visible"
    size="xl"
    fullscreen="lg"
    backdrop="static"
    alignment="center"
    @close="onClose"
  >
    <CModalHeader dismiss>
      <CModalTitle :id="`id-title-${WH}`"
        >Результаты поиска туров и лидов</CModalTitle
      >
    </CModalHeader>
    <CModalBody :id="`id-${WH}`" class="position-relative popup-min-h">
      <LeadsToursSearchResultsTable
        :search-string="searchString"
        :modal-w-h="WH"
        @get-data="showSpinner"
        @data-ready="hideSpinner"
        @close="onClose"
      />
    </CModalBody>
    <CModalFooter :id="`id-footer-${WH}`">
      <div class="flex gap-3">
        <CButton color="secondary" @click="onClose">закрыть</CButton>
      </div>
      <!--      <div class="flex gap-3 ms-3" :id="`id-buttons-${WH}`"></div>-->
    </CModalFooter>
  </CModal>
</template>
<script setup>
import { inject, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import LeadsToursSearchResultsTable from '@/components/_shared/search-tours-leads/LeadsToursSearchResultsTable.vue'

defineProps({
  searchString: {
    type: String,
    required: true,
  },
})

const { storage } = inject('services')

const emit = defineEmits(['close'])
const store = useStore()
const WH = 'f4992e3e-b271-4073-8302-65af61f3f24d'

const visible = ref(false)

const onClose = () => {
  visible.value = false
  emit('close')
}
const showSpinner = () => {
  store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
}
const hideSpinner = () => {
  store.commit('toggleLockEl', { lock: false, target: null })
}

onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
