// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ReportsApiMethodsCls {
    async reportList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка отчетов
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('reportList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.report.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async reportCreate(wh, data, extra_data = null) {
      // data: Report
      // Создание отчета
      console.log('reportCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.report.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reportRetrieve(wh, id, extra_data = null) {
      // Получение отчета по идентификатору
      console.log('reportRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('reports__report', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'reports__report', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.report.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reportUpdate(wh, id, data, extra_data = null) {
      // data: Report
      // Обновление отчета
      console.log('reportUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.report.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reportDelete(wh, id) {
      console.log('reportDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.reports.report.delete(id)})
      ctx.$services.storage.del('reports__report', id, wh)
      return null
    }
    async reportPartial(wh, id, data, extra_data = null) {
      // data: Report
      console.log('reportPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.report.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reportTemplateList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка своих шаблонов отчетов
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('reportTemplateList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.reportTemplate.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async reportTemplateCreate(wh, data, extra_data = null) {
      // data: ReportTemplate
      console.log('reportTemplateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.reportTemplate.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reportTemplateRetrieve(wh, id, extra_data = null) {
      // Получение шаблона отчета по идентификатору
      console.log('reportTemplateRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('reports__reporttemplate', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'reports__reporttemplate', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.reportTemplate.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reportTemplateUpdate(wh, id, data, extra_data = null) {
      // data: ReportTemplate
      console.log('reportTemplateUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.reportTemplate.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reportTemplateDelete(wh, id) {
      console.log('reportTemplateDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.reports.reportTemplate.delete(id)})
      ctx.$services.storage.del('reports__reporttemplate', id, wh)
      return null
    }
    async reportTemplatePartial(wh, id, data, extra_data = null) {
      // data: ReportTemplate
      console.log('reportTemplatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.reports.reportTemplate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.reportsApi = new ReportsApiMethodsCls()
}

