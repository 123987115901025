<template>
  <div :id="`id-${WH}`" class="position-relative">
    <strong v-if="isAccepted" class="text-success"
      >Платежи импортированного файла приняты</strong
    >
    <CNav variant="tabs" role="tablist" class="mt-3">
      <CNavItem>
        <CNavLink
          :active="activeTab === 1"
          :disabled="!paymentsDraft.length"
          href="javascript:void(0)"
          @click="activeTab = 1"
        >
          Ожидает акцепта
          {{ paymentsDraft.length ? `(${paymentsDraft.length})` : '' }}
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          :active="activeTab === 2"
          :disabled="!paymentsOther.length"
          href="javascript:void(0)"
          @click="activeTab = 2"
        >
          Принято {{ paymentsOther.length ? `(${paymentsOther.length})` : '' }}
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          :active="activeTab === 3"
          :disabled="!errorsImport.length"
          href="javascript:void(0)"
          @click="activeTab = 3"
        >
          Ошибки импорта
          {{ errorsImport.length ? `(${errorsImport.length})` : '' }}
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          :active="activeTab === 4"
          :disabled="!warnsImport.length"
          href="javascript:void(0)"
          @click="activeTab = 4"
        >
          Предупреждения
          {{ warnsImport.length ? `(${warnsImport.length})` : '' }}
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink
          :active="activeTab === 5"
          :disabled="!errorsAutoLen"
          href="javascript:void(0)"
          @click="activeTab = 5"
        >
          Ошибки обработки {{ errorsAutoLen ? `(${errorsAutoLen})` : '' }}
        </CNavLink>
      </CNavItem>
    </CNav>
    <CTabContent style="min-height: 600px">
      <CTabPane role="tabpanel" :visible="activeTab === 1">
        <PaymentImportFileTab1
          :active="activeTab === 1"
          :data="paymentsDraft"
          :contractors="relContractorsData"
          :payment-type="currPaymentType"
          @accept="accept"
        />
      </CTabPane>
      <CTabPane role="tabpanel" :visible="activeTab === 2">
        <PaymentImportFileTab2
          :active="activeTab === 2"
          :data="paymentsOther"
          :contractors="relContractorsData"
          :payment-type="currPaymentType"
        />
      </CTabPane>
      <CTabPane role="tabpanel" :visible="activeTab === 3">
        <PaymentImportFileTab3
          :active="activeTab === 3"
          :data="errorsImport"
          @to-csv="toCSV"
        />
      </CTabPane>
      <CTabPane role="tabpanel" :visible="activeTab === 4">
        <PaymentImportFileTab4
          :active="activeTab === 4"
          :data="warnsImport"
          @to-csv="toCSV"
        />
      </CTabPane>
      <CTabPane role="tabpanel" :visible="activeTab === 5">
        <PaymentImportFileTab5
          :active="activeTab === 5"
          :data="errorsAuto"
          :payments="payments"
          :payment-type="currPaymentType"
          @to-csv="toCSV"
        />
      </CTabPane>
    </CTabContent>

    <Teleport v-if="ready && modalWh" :to="`#id-title-${modalWh}`">
      {{ modalTitle }}
    </Teleport>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  inject,
  onUnmounted,
  watch,
  computed,
  nextTick,
} from 'vue'
import { useStore } from 'vuex'
import C from '@/config/back_const'
import { QueuedResponseException } from '@/exceptions'
import PaymentImportFileTab1 from '@/components/_payment/paymentImportFileTabs/PaymentImportFileTab1.vue'
import PaymentImportFileTab2 from '@/components/_payment/paymentImportFileTabs/PaymentImportFileTab2.vue'
import PaymentImportFileTab3 from '@/components/_payment/paymentImportFileTabs/PaymentImportFileTab3.vue'
import PaymentImportFileTab4 from '@/components/_payment/paymentImportFileTabs/PaymentImportFileTab4.vue'
import PaymentImportFileTab5 from '@/components/_payment/paymentImportFileTabs/PaymentImportFileTab5.vue'

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  taskId: {
    type: Number,
    required: false,
    default: null,
  },
  runParse: {
    type: Boolean,
    required: false,
    default: false,
  },
  paymentType: {
    type: Number,
    required: false,
    default: undefined,
  },
  modalWh: {
    type: String,
    required: false,
    default: null,
  },
})
const emit = defineEmits(['fail'])
const WH = '7847dd92-b2dd-46c5-bcbd-faa3785e98cf'
const {
  storage,
  paymentApi,
  buyerPaymentApi,
  agencyPaymentApi,
  mediaApi,
  expectant,
  entityApi,
} = inject('services')
const store = useStore()

const taskWH = ref()
const activeTab = ref(1)
const ready = ref(false)
const payments = ref([])
const paymentsDraft = ref([])
const paymentsOther = ref([])
const errorsImport = ref([])
const warnsImport = ref([])
const errorsAuto = ref({})
const isAccepted = ref(false)
const relPaymentsData = ref({})
const relContractorsData = ref({})
const errorsAutoLen = computed(() => Object.keys(errorsAuto.value).length)
const paymentDataTypes = {
  [C.IMPORT_FILE_TYPE_IMPORT_PAYMENTS]: 'legal__payment',
  [C.IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS]: 'legal__buyerpayment',
}
const currPaymentType = ref(props.paymentType)
const modalTitle = computed(
  () =>
    `Результат загрузки платежей ${
      currPaymentType.value === C.IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS
        ? 'покупателей'
        : 'системы'
    }`,
)

const paymentRetrieve = (...theArgs) => {
  return currPaymentType.value === C.IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS
    ? buyerPaymentApi.buyerPaymentRetrieve(...theArgs)
    : paymentApi.paymentRetrieve(...theArgs)
}
const paymentImportProcessPartial = (...theArgs) => {
  return currPaymentType.value === C.IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS
    ? agencyPaymentApi.buyerPaymentImportProcessPartial(...theArgs)
    : paymentApi.paymentImportProcessPartial(...theArgs)
}
const paymentImportAcceptPartial = (...theArgs) => {
  return currPaymentType.value === C.IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS
    ? agencyPaymentApi.buyerPaymentImportAcceptPartial(...theArgs)
    : paymentApi.paymentImportAcceptPartial(...theArgs)
}

const updateView = async (importData) => {
  try {
    currPaymentType.value = importData.type
    const paymentDataType = paymentDataTypes[currPaymentType.value]
    const paymentsData = importData?.imported?.[paymentDataType]
    if (paymentsData?.length) {
      relPaymentsData.value = await storage.getRelations(
        paymentsData.map((id) => ({ id })),
        'id',
        paymentDataType,
        paymentRetrieve,
        taskWH.value ? taskWH.value : WH,
      )
      relContractorsData.value = await storage.getRelations(
        Object.values(relPaymentsData.value),
        'contractor_entity',
        'legal__entity',
        entityApi.entityRetrieve,
        taskWH.value ? taskWH.value : WH,
      )
      payments.value = Object.values(relPaymentsData.value)
    }
    const processData = importData?.statistics?.process
    if (processData) {
      errorsImport.value = processData.errors || []
      warnsImport.value = processData.warnings || []
    }
    const autoSplitData = importData?.statistics?.auto_split
    if (autoSplitData) {
      errorsAuto.value = autoSplitData.errors || {}
    }
  } catch (error) {
    console.error(error)
  }
}

const importPayment = async () => {
  try {
    const importData = props.runParse
      ? await paymentImportProcessPartial(WH, props.id, {
          force_foreground: false,
        })
      : await mediaApi.importFileRetrieve(WH, props.id)
    await updateView(importData)
  } catch (error) {
    if (error instanceof QueuedResponseException) {
      setTimeout(() => {
        store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
      })
      const taskId = Number(error.message)
      expectant.subscribe(taskId, async (taskData) => {
        // колбек чтобы обновить вид если окно осталось открыто
        if (taskData?.result?.data) {
          taskWH.value = expectant.getWH(taskData.id)
          await updateView(taskData.result.data)
          store.commit('toggleLockEl', { lock: false, target: null })
        }
      })
      expectant.subscribeFailure(taskId, () => {
        store.commit('toggleLockEl', { lock: false, target: null })
        emit('fail')
      })
    } else {
      throw error
    }
  }
}

const accept = async ({ ids, options }) => {
  store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
  isAccepted.value = false
  try {
    await paymentImportAcceptPartial(
      taskWH.value ? taskWH.value : WH,
      props.id,
      {
        payments: ids,
        ...options,
      },
    )
    isAccepted.value = true
  } catch (error) {
    if (error instanceof QueuedResponseException) {
      setTimeout(() => {
        store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
      })
      const taskId = error.message
      expectant.subscribe(taskId, async (taskData) => {
        if (taskData?.result?.data) {
          taskWH.value = expectant.getWH(taskData.id)
          await updateView(taskData.result.data)
          store.commit('toggleLockEl', { lock: false, target: null })
        }
      })
      expectant.subscribeFailure(taskId, () => {
        store.commit('toggleLockEl', { lock: false, target: null })
      })
    } else {
      throw error
    }
  } finally {
    store.commit('toggleLockEl', { lock: false, target: null })
  }
}

const toCSV = ({ headings = [], errors = [] }) => {
  store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
  try {
    const dataStringed = []
    errors.map((el) => {
      const objNew = {}
      for (const key in el) {
        objNew[key] = el[key]
      }
      dataStringed.push(objNew)
    })
    const delimiter = ';'
    const csvArr = [
      headings.join(delimiter),
      '\r\n', // header row first
      ...dataStringed
        .map((row) => {
          const errors = row.errors || row.warnings
          if (errors) {
            const arr = []
            if (row.uuid1c) arr.push(row.uuid1c)
            if (row.uuid) arr.push(row.uuid)
            if (row.id) arr.push(row.id)

            const errors_list = []
            for (const v of Object.values(errors)) {
              errors_list.push(v.replace(delimiter, ' '))
            }
            arr.push(errors_list.join(','))

            if (row.doc_number) arr.push(row.doc_number)
            if (row.doc_date) arr.push(row.doc_date)
            if (row.amount) arr.push(row.amount.replace(',', '.'))
            return arr.join(delimiter)
          }
        })
        .join('\r\n'),
    ]
    const csv = `\ufeff${csvArr.join('')}`

    const link = document.createElement('a')
    link.setAttribute(
      'href',
      'data:text/csv; charset=utf-8,' + encodeURIComponent(csv),
    )
    link.setAttribute('download', 'my_data.csv')
    document.body.appendChild(link) // Required for FF
    link.click()
  } catch (err) {
    console.error(err)
  } finally {
    store.commit('toggleLockEl', { lock: false, target: null })
  }
}

const getData = async () => {
  store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
  try {
    taskWH.value = expectant.getWH(props.taskId)
    const data = storage.get('media__importfile', props.id, taskWH.value)
    if (data) {
      await updateView(data)
    } else {
      await importPayment()
    }
    nextTick(() => {
      ready.value = true
    })
  } catch (error) {
    console.error(error)
  } finally {
    store.commit('toggleLockEl', { lock: false, target: null })
  }
}

watch(payments, (val) => {
  paymentsDraft.value = []
  paymentsOther.value = []
  val.forEach((p) => {
    if (p.state === C.DOCUMENT_STATE_DRAFT) {
      paymentsDraft.value.push(p)
    } else {
      paymentsOther.value.push(p)
    }
  })
})
watch(errorsImport, (errImport) => {
  if (errImport.length) activeTab.value = 3
})

onMounted(() => {
  getData()
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
