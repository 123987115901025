// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class TransferApiMethodsCls {
    async carList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка машин
      // filters: seats, luggage_seats, model, number, drivers, is_active
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('carList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.car.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async carCreate(wh, data, extra_data = null) {
      // data: Car
      // Создание машины
      console.log('carCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.car.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async carRetrieve(wh, id, extra_data = null) {
      // Получение машины
      console.log('carRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__car', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__car', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.car.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async carUpdate(wh, id, data, extra_data = null) {
      // data: Car
      // Обновление машины
      console.log('carUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.car.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async carDelete(wh, id) {
      // Удаление машины
      console.log('carDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.car.delete(id)})
      ctx.$services.storage.del('core__car', id, wh)
      return null
    }
    async carPartial(wh, id, data, extra_data = null) {
      // data: Car
      console.log('carPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.car.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async carActivatePartial(wh, id, data, extra_data = null) {
      // data: Car
      // Активация машины
      console.log('carActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.car.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async carDeactivatePartial(wh, id, data, extra_data = null) {
      // data: Car
      // Деактивация машины
      console.log('carDeactivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.car.deactivate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferBookingList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка бронирований трансфера
      // filters: created_at, updated_at, status, transfer_service, tour, date, time, amount,
      //          receive_amount, transfer_amount, driver_payment, agent, location_from,
      //          location_to, from_text, to_text, from_latitude, from_longitude, to_latitude,
      //          to_longitude, car, driver, instructions, give_away_documents, get_signature,
      //          get_documents, id
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('transferBookingList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferBooking.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async transferBookingCreate(wh, data, extra_data = null) {
      // data: TransferBooking
      // Создание бронирования трансфера
      console.log('transferBookingCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferBooking.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferBookingRetrieve(wh, id, extra_data = null) {
      // Получение бронирования трансфера
      console.log('transferBookingRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__transferbooking', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__transferbooking', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferBooking.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferBookingUpdate(wh, id, data, extra_data = null) {
      // data: TransferBooking
      // Обновление бронирования трансфера
      console.log('transferBookingUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferBooking.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferBookingDelete(wh, id) {
      console.log('transferBookingDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.transferBooking.delete(id)})
      ctx.$services.storage.del('core__transferbooking', id, wh)
      return null
    }
    async transferBookingPartial(wh, id, data, extra_data = null) {
      // data: TransferBooking
      console.log('transferBookingPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferBooking.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferBookingAddProductUpdate(wh, id, data, extra_data = null) {
      // data: ProductCreate
      // добавление продукта к бронированию трансфера
      console.log('transferBookingAddProductUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferBooking.addProduct.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferBookingPrintPartial(wh, id, data, extra_data = null) {
      // data: TransferBookingPrint
      // Распечатать бронирование трансфера
      console.log('transferBookingPrintPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferBooking.print.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async transferServiceList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка услуг трансфера
      // filters: title, description, type, company, is_active, is_public,
      //          exclude_from_buyer_contract, location_from, location_to, car, driver,
      //          need_confirmation, price, night_price, id
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('transferServiceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferService.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async transferServiceCreate(wh, data, extra_data = null) {
      // data: TransferService
      // Создание услуги трансфера
      console.log('transferServiceCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferService.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferServiceRetrieve(wh, id, extra_data = null) {
      // Получение услуги трансфера
      console.log('transferServiceRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__transferservice', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__transferservice', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferService.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferServiceUpdate(wh, id, data, extra_data = null) {
      // data: TransferService
      // Обновление услуги трансфера
      console.log('transferServiceUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferService.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferServiceDelete(wh, id) {
      // Удаление услуги трансфера
      console.log('transferServiceDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.transferService.delete(id)})
      ctx.$services.storage.del('core__transferservice', id, wh)
      return null
    }
    async transferServicePartial(wh, id, data, extra_data = null) {
      // data: TransferService
      console.log('transferServicePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferService.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferServiceActivatePartial(wh, id, data, extra_data = null) {
      // data: TransferService
      // Активация услуги трансфера
      console.log('transferServiceActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferService.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transferServiceDeactivatePartial(wh, id, data, extra_data = null) {
      // data: TransferService
      // Деактивация услуги трансфера
      console.log('transferServiceDeactivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.transferService.deactivate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.transferApi = new TransferApiMethodsCls()
}

