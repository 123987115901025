<template>
  <CModal
    ref="modal"
    :visible="visible"
    size="lg"
    :fullscreen="false"
    backdrop="static"
    alignment="center"
    @close="onClose"
  >
    <CModalHeader dismiss>
      <CModalTitle>Отправить в архив</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="flex gap-5 align-items-end">
        <div class="col-8 col-xl-6">
          <CFormLabel for="cancel.resolution">Причина</CFormLabel>
          <CFormSelect id="cancel.resolution" v-model="resolution">
            <option value="" selected disabled>Выберите причину</option>
            <option
              v-for="[key, title] in Object.entries(
                C.LEAD_RESOLUTION_TITLES,
              ).filter(([k]) => k != C.LEAD_RESOLUTION_VOUCHER)"
              :key="key"
              :value="key"
            >
              {{ title }}
            </option>
          </CFormSelect>
          <div v-if="Number(resolution) === 6" class="mt-3">
            <CFormCheck
              id="blockIP"
              v-model="blockIP"
              :label="`Заблокировать IP адрес ${ip}`"
            />
          </div>
          <div class="mt-3">
            <CFormLabel
              for="cancel.comment"
              :class="{ required: requireComment }"
            >
              Комментарий
            </CFormLabel>
            <CFormTextarea
              id="cancel.comment"
              v-model="comment"
              rows="2"
              style="resize: both"
            />
          </div>
        </div>
      </div>
    </CModalBody>
    <CModalFooter>
      <div class="flex gap-3">
        <CButton color="secondary" @click="onClose">Отмена</CButton>
        <CButton
          color="success"
          @click="onComplete"
          :disabled="
            (!comment && requireComment) || !resolution
          "
        >
          Подтвердить</CButton
        >
      </div>
    </CModalFooter>
  </CModal>
</template>
<script setup>
import {computed, inject, onMounted, onUnmounted, ref} from 'vue'
import C from '@/config/back_const'

const props = defineProps({
  ip: {
    type: String,
    required: false,
    default: null,
  },
})

const emit = defineEmits(['close', 'complete'])
const WH = '03103337-9504-4f1b-909c-6bb2f1029fa4'
const { storage, coreApi } = inject('services')

const visible = ref(false)
const comment = ref('')
const resolution = ref('')
const blockIP = ref(false)

const requireComment = computed(() => {
  const r = Number(resolution.value)
  return r === C.LEAD_RESOLUTION_UNDEF || r === C.LEAD_RESOLUTION_SPAM && blockIP.value
})
const onClose = () => {
  visible.value = false
  emit('close')
}
const onComplete = async () => {
  const payload = {
    resolution: resolution.value,
    comment: comment.value,
  }
  if (!payload.comment) delete payload.comment
  emit('complete', payload)
  if (blockIP.value && props.ip) {
    const data = {
      ip_low: props.ip,
      ip_high: props.ip,
      validity_period: C.VALIDITY_PERIOD_WEEK,
      reason: comment.value,
      whitelist: false,
    }
    await coreApi.blackListCreate(WH, data)
  }
}

onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
