// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class NsbOldApiMethodsCls {
    async importPricesCreate(wh, extra_data = null) {
      // Синхронизация цен с cp.nsb.su
      console.log('importPricesCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.nsbOld.importPrices.create()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.nsb_oldApi = new NsbOldApiMethodsCls()
}

