import { asyncDebounce } from '@/helpers/index.js'

/**
 * Ожидает выполнение запроса, все новые вызовы во время ожидания добавляет в очередь.
 * Если  очередь не пуста то (в текущей реализации) после выполнения запроса выполняется последний запрос из очереди.
 * В дальнейшем можно добавить оппционально выполнение последнего или обработать всю очередь.
 * @param {function} fn
 * @param {number} delay
 * @returns {function(...[*]): Promise<unknown>}
 */
export function asyncDebouncedQueue(fn, delay) {
  let requestQueue = []
  let isProcessing = false

  /**
   * Собирает очередь
   * @param {[]} args
   */
  const enqueueRequest = (args) => {
    requestQueue.push(args)
  }

  const processLastRequest = async () => {
    if (requestQueue.length > 0) {
      const lastRequestArgs = requestQueue.pop()
      return await processRequest(lastRequestArgs)
    }
  }

  // если понадобится можно добавить processQueue и вызвать все накопившееся колбэки

  /**
   * Обрабатывает запрос
   * @param {[]} args
   * @returns {Promise<*>}
   */
  const processRequest = async (args) => {
    if (isProcessing) {
      enqueueRequest(args)
      return
    }

    requestQueue.length = 0
    isProcessing = true
    const res = await fn(...args)
    isProcessing = false

    await processLastRequest()

    return res
  }

  const debouncedProcessRequest = asyncDebounce(processRequest, delay)

  return async (...args) => {
    return await debouncedProcessRequest(args)
  }
}
