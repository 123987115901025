// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class OccupationApiMethodsCls {
    async hotelOccupationRetrieve(wh, id, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка Occupation для отеля
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('hotelOccupationRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__occupation', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__occupation', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.occupation.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async occupationCreate(wh, data, extra_data = null) {
      // data: Occupation
      // Создание Occupation
      console.log('occupationCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationRetrieve(wh, id, extra_data = null) {
      // Получение Occupation
      console.log('occupationRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__occupation', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__occupation', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationUpdate(wh, id, data, extra_data = null) {
      // data: Occupation
      // Обновление модели Occupation
      console.log('occupationUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationDelete(wh, id) {
      // Удаление Occupation, только для статуса Draft
      console.log('occupationDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.hms.occupation.delete(id)})
      ctx.$services.storage.del('hms__occupation', id, wh)
      return null
    }
    async occupationPartial(wh, id, data, extra_data = null) {
      // data: Occupation
      // Обновление полей Occupation
      console.log('occupationPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationAddResidentPost(wh, id, data, extra_data = null) {
      // data: ResidentCreate
      // Создание Resident для occupation
      console.log('occupationAddResidentPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.addResident.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationCancelPartial(wh, id, data, extra_data = null) {
      // data: Occupation
      // Отмена. Переводит в статус CANCELED
      console.log('occupationCancelPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.cancel.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationCheckInPartial(wh, id, data, extra_data = null) {
      // data: Occupation
      // Переводит в статус CHECK_IN
      console.log('occupationCheckInPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.checkIn.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationCheckOutPartial(wh, id, data, extra_data = null) {
      // data: Occupation
      // Переводит в статус CHECK_OUT
      console.log('occupationCheckOutPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.checkOut.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationMovePartial(wh, id, data, extra_data = null) {
      // data: Occupation
      // Переезд в другой номер
      console.log('occupationMovePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.move.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async occupationProlongPartial(wh, id, data, extra_data = null) {
      // data: Occupation
      // Продление. Меняет плановое время, если есть свободные места
      console.log('occupationProlongPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.prolong.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async residentUpdate(wh, id, data, extra_data = null) {
      // data: Resident
      // Обновление Resident
      console.log('residentUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.resident.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async residentDelete(wh, id) {
      // Удаление Resident
      console.log('residentDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.hms.resident.delete(id)})
      ctx.$services.storage.del('hms__resident', id, wh)
      return null
    }
  }

  ctx.$services.occupationApi = new OccupationApiMethodsCls()
}

