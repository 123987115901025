// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class IdentityApiMethodsCls {
    async customerChangeAgentPartial(wh, id, data, extra_data = null) {
      // data: IdentityChangeAgent
      // Смена агента у заказчика
      console.log('customerChangeAgentPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.customer.changeAgent.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async customerListNewAgentsRetrieve(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Список агентов на которых можно сменить агента у данного заказчика
      // filters: created_at, updated_at, company, user, person, role, division, handle,
      //          exact_position_name, customer_password, is_active, is_deleted, is_managed,
      //          is_supervisor, supervisor, notifications_contact, hotel, city, contact, phone,
      //          fio, agent, without_agent, tags, birthday, booking_date_fr
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('customerListNewAgentsRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('account__identity', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'account__identity', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.customer.listNewAgents.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async customersList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка клиентов (заказчиков). Основная информация приходит в ключе extra_data 
      // filters: created_at, updated_at, company, user, person, role, division, handle,
      //          exact_position_name, customer_password, is_active, is_deleted, is_managed,
      //          is_supervisor, supervisor, notifications_contact, hotel, city, contact, phone,
      //          fio, agent, without_agent, tags, birthday, booking_date_fr
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('customersList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.customers.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async identityList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Все Identity компании, к которой принадлежит пользователь
      // filters: created_at, updated_at, company, user, person, role, division, handle,
      //          exact_position_name, is_active, is_deleted, is_managed, is_supervisor, supervisor,
      //          agent, notifications_contact, pk, fio, phone, user_email
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('identityList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async identityCreate(wh, data, extra_data = null) {
      // data: IdentityCreate
      // Создание Identity
      console.log('identityCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityCreateexCreate(wh, data, extra_data = null) {
      // data: UserIdentityCreate
      // Одновременное создание User и Identity
      console.log('identityCreateexCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.createex.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityPasswordCreate(wh, data, extra_data = null) {
      // data: IdentityChangePassword
      // Изменение пароля заказчика
      console.log('identityPasswordCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.password.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityRetrieve(wh, id, extra_data = null) {
      // Получение модели Identity
      console.log('identityRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('account__identity', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'account__identity', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityUpdate(wh, id, data, extra_data = null) {
      // data: IdentityUpdate
      // Обновление модели Identity
      console.log('identityUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityDelete(wh, id) {
      // Удаление Identity
      console.log('identityDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.account.identity.delete(id)})
      ctx.$services.storage.del('account__identity', id, wh)
      return null
    }
    async identityPartial(wh, id, data, extra_data = null) {
      // data: IdentityPatch
      // Обновление полей модели Identity
      console.log('identityPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityActivatePartial(wh, id, data, extra_data = null) {
      // data: IdentityActivate
      // Изменение поля is_active для личности (Identity)
      console.log('identityActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityOrganizationsList(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Organization для Identity
      // filters: full_title, ogrn, inn, kpp, okpo, address, postal_address, email
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('identityOrganizationsList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.organizations.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async identityPersonsList(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Person для Identity
      // filters: name, surname, patronymic, passport_number, fio
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('identityPersonsList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.persons.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async personalList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Персонал компании оператора
      // filters: created_at, updated_at, company, user, person, role, division, handle,
      //          exact_position_name, is_active, is_deleted, is_managed, is_supervisor, supervisor,
      //          agent, notifications_contact, pk, fio, phone, user_email
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('personalList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.personal.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.identityApi = new IdentityApiMethodsCls()
}

