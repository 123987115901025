<template>
   <CToaster placement="top-start">
    <CToast
      v-for="(toast, i) in toasts"
      :key="'toast' + i"
      :color="toast.type"
      :delay="toast.delay || 5000"
      :dismissible="toast.dismissible!==undefined ? toast.dismissible : false"
      :autohide="toast.autohide!==undefined ? toast.autohide : true"
      class="text-white align-items-center"
    >
      <CToastHeader close-button>
        <span class="me-auto fw-bold">{{ toast.header }}</span>
        <small>{{ timeDiff(toast.created_at, time) }} {{i}}</small>
      </CToastHeader>
      <CToastBody v-html="toast.text" />
    </CToast>
  </CToaster>
</template>

<!--
autohide
   Auto hide the toast.	boolean	-	true
color
   Sets the color context of the component to one of CoreUI’s themed colors.	string
   'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', string
delay
   Delay hiding the toast (ms).	number	-	5000
dismissible
   Optionally add a close button to component and allow it to self dismiss.	boolean	-	true
index
   index of the component.	number	-	-
title
   Title node for your component.	string	-	-
visible
   Toggle the visibility of component.	boolean	-	true
-->

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      toasts: 'toasts',
    })
  },
}
</script>

<script setup>
import {onMounted, ref} from "vue";
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
const time =  ref(new Date())

const timeDiff = (toastTime, systemTime) => {
  let diff = differenceInSeconds(systemTime, toastTime)
  if (diff < 60) {
    return diff + ' сек'
  }
  diff = differenceInMinutes(systemTime, toastTime)
  if (diff < 60) {
    return diff + ' мин'
  }
  diff = differenceInHours(systemTime, toastTime)
  return diff + ' час'
}

onMounted(async () =>{
  setInterval(() => {time.value = new Date()}, 1000)
})

</script>
