// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class WidgetApiMethodsCls {
    async widgetList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка виджетов
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('widgetList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.widget.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async widgetCreate(wh, data, extra_data = null) {
      // data: Widget
      // Создание виджета
      console.log('widgetCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.widget.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async widgetRetrieve(wh, id, extra_data = null) {
      // Получение виджета
      console.log('widgetRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__widget', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__widget', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.widget.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async widgetUpdate(wh, id, data, extra_data = null) {
      // data: Widget
      // Изменение виджета
      console.log('widgetUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.widget.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.widgetApi = new WidgetApiMethodsCls()
}

