<template>
  <div class="p-3">
    <span class="d-block"
      ><b>Общая сумма</b>: {{ formatCents(payment.amount) }}</span
    >
    <span class="d-block"
      ><b>Неразнесенная сумма</b>:
      {{ formatCents(payment.amount - payment.split_amount) }}</span
    >

    <CTable borderless class="mt-3">
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell class="col-5 d-none d-md-table-cell">
            Счет на оплату:
          </CTableHeaderCell>
          <CTableHeaderCell class="col-4 d-none d-md-table-cell">
            Сумма:
          </CTableHeaderCell>
          <CTableHeaderCell class="col-3 d-none d-md-table-cell">
          </CTableHeaderCell>
        </CTableRow>
      </CTableHead>

      <CTableBody>
        <CTableRow v-for="(split, key) in splits" :key="key">
          <CTableDataCell class="col-5">
            <span>{{ formatedData[split.id].invoiceTitle }}</span>
          </CTableDataCell>

          <CTableDataCell class="col-4">
            {{ formatCents(split.amount) }}
          </CTableDataCell>

          <CTableDataCell class="col-3">
            <CButton
              v-if="split.state === C.DOCUMENT_STATE_ANNULLED"
              color="secondary"
              :disabled="true"
              @click="cancelSplit(split)"
            >
              Отменено
            </CButton>
            <CButton
              v-else-if="split.state === C.DOCUMENT_STATE_HELD"
              color="danger"
              @click="cancelSplit(split)"
            >
              &nbsp;&nbsp;Отмена&nbsp;&nbsp;
            </CButton>
          </CTableDataCell>
        </CTableRow>
        <CTableRow
          v-if="!paymentIsHeld"
          class="d-flex flex-wrap d-md-table-row"
        >
          <CTableDataCell class="flex-grow-1 flex-md-grow-0">
            <div>
              <Autocomplete
                v-model="selectedInvoice"
                :search-area="searchArea"
                :min_len="1"
                placeholder="введите номер счета"
                id="new-invoice"
              />
            </div>
          </CTableDataCell>

          <CTableDataCell class="flex-grow-1 flex-md-grow-0">
            <div>
              <CurrencyInput
                v-model="newInvoiceSum"
                placeholder="введите сумму"
              />
            </div>
          </CTableDataCell>

          <CTableDataCell class="flex-grow-1 flex-md-grow-0">
            <div>
              <CButton
                color="primary"
                @click="holdAddedInvoice"
                :disabled="selectedInvoice == null"
              >
                Провести
              </CButton>
            </div>
          </CTableDataCell>
        </CTableRow>
      </CTableBody>
    </CTable>
  </div>
</template>

<script setup>
import { computed, inject, onUnmounted, reactive, ref, watch } from 'vue'
import { formatCents } from '@/helpers/textFormatter'
import C from '@/config/back_const'
import Autocomplete from '@/components/custom/Autocomplete.vue'
import CurrencyInput from '@/components/_custom/currency-input/CurrencyInput.vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    required: false,
    default: '',
  },
})
const emit = defineEmits(['tab-event'])
const { storage, legalApi, buyerContractApi } = inject('services')
const WH = '518f8b75-d066-4692-9dae-53008bc56fd6'

const payment = ref(props.data.payment.value)
const splits = ref(props.data.splitsReq.value || [])
const searchArea = ref(props.data.searchArea.value)
const relInvoices = ref(props.data.relInvoices.value || {})
const selectedInvoice = ref(null)
const newInvoiceData = ref({})
const newInvoiceSum = ref(undefined)
const paymentIsHeld = ref(false)
const formatedData = reactive({})

const invoiceType = computed(() =>
  props.mode === 'buyer' ? 'buyer_contract' : 'payed_invoice',
)

const formatData = () => {
  for (const split of splits.value) {
    const inv = relInvoices.value[split[invoiceType.value]]
    formatedData[split.id] = {
      invoiceTitle:
        props.mode === 'buyer'
          ? `${inv?.invoice_number}${
              inv?.invoice_date ? ` от ${inv.invoice_date}` : ''
            }`
          : `${inv?.doc_number}${inv?.doc_date ? ` от ${inv.doc_date}` : ''}`,
    }
  }
}

const cancelSplit = async (split) => {
  emit('tab-event', {
    eventName: 'cancel-split',
    id: split.id,
  })
}

const holdAddedInvoice = () => {
  emit('tab-event', {
    eventName: 'hold-invoice',
    newInvoice: newInvoiceData.value,
    payed_invoice: newInvoiceData.value.id,
    amount: newInvoiceSum.value,
  })
  selectedInvoice.value = null
  newInvoiceSum.value = 0
}

watch(
  () => props.data,
  (val) => {
    payment.value = val.payment.value || {}
    splits.value = val.splitsReq.value.data || []
    searchArea.value = val.searchArea.value || {}
    relInvoices.value = val.relInvoices.value || {}
    paymentIsHeld.value = payment.value.amount === payment.value.split_amount
    formatData()
  },
  { deep: true },
)

watch(selectedInvoice, async (val) => {
  if (!val) return
  const getInvoiceFn =
    val.data_type === 'core__buyercontract'
      ? buyerContractApi.buyerContractRetrieve
      : val.data_type === 'legal__agencyinvoice'
      ? legalApi.agencyInvoiceRetrieve
      : legalApi.supplierInvoiceRetrieve
  newInvoiceData.value = await getInvoiceFn(WH, val.id)
  newInvoiceSum.value = Math.min(
    payment.value.amount - payment.value.split_amount,
    newInvoiceData.value.amount - newInvoiceData.value?.split_amount || 0,
  )
})

onUnmounted(() => {
  selectedInvoice.value = {}
  newInvoiceSum.value = undefined
  relInvoices.value = {}
  storage.clr(WH)
})
</script>
