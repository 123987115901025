import {
  defineComponent,
  h,
  onMounted,
  ref,
  resolveComponent,
  computed,
} from 'vue'
import C from '@/config/back_const'
import { RouterLink, useRoute } from 'vue-router'
import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle,
} from '@coreui/vue-pro'
import {
  agency_admin_nav,
  agent_nav,
  agent_supervisor_nav,
  sys_acc_nav,
  agency_acc_nav,
  sys_admin_nav,
  sys_manager_nav,
  transfer_admin_nav,
  transfer_manager_nav,
  transfer_acc_nav,
  common_top_nav,
  common_bottom_nav,
} from '@/_nav.js'
import { inject } from 'vue'
import store from '@/store'

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, '')
    .replace(/(index)?\.(html)$/, '')

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: 'AppSidebarNav',
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle,
  },
  setup() {
    const route = useRoute()
    const { auth } = inject('services')
    const bus = inject('bus')

    const firstRender = ref(true)
    const myIdentity = ref({})
    const myCompany = ref({})

    const role = computed(() => {
      return myIdentity.value.role
    })

    const is_system = computed(() => {
      return myCompany.value.is_system
    })

    const is_agency = computed(() => {
      return myCompany.value.is_agency
    })

    const is_transfer = computed(() => {
      return myCompany.value.is_transfer
    })

    bus.on('authentication-changed', async () => {
      myIdentity.value = await auth.getIdentity()
      myCompany.value = await auth.getCompany()
    })

    onMounted(async () => {
      firstRender.value = false
      myIdentity.value = await auth.getIdentity()
      myCompany.value = await auth.getCompany()
    })

    const navigation = computed(() => {
      let nav = common_top_nav

      if (is_agency.value) {
        switch (role.value) {
          case C.ROLES_ADMIN:
          case C.ROLES_OWNER:
            nav = nav.concat(agency_admin_nav)
            break
          case C.ROLES_AGENT:
            myIdentity.value?.is_supervisor
              ? (nav = nav.concat(agent_supervisor_nav))
              : (nav = nav.concat(agent_nav))
            break
          case C.ROLES_ACCOUNTANT:
            nav = nav.concat(agency_acc_nav)
        }
      }

      if (is_system.value) {
        switch (role.value) {
          case C.ROLES_ADMIN:
          case C.ROLES_OWNER:
            nav = nav.concat(sys_admin_nav)
            break
          case C.ROLES_ACCOUNTANT:
            nav = nav.concat(sys_acc_nav)
            break
          case C.ROLES_MANAGER:
            nav = nav.concat(sys_manager_nav)
        }
      }

      if (is_transfer.value) {
        switch (role.value) {
          case C.ROLES_ADMIN:
          case C.ROLES_OWNER:
            nav = nav.concat(transfer_admin_nav)
            break
          case C.ROLES_ACCOUNTANT:
            nav = nav.concat(transfer_acc_nav)
            break
          case C.ROLES_TRANSFER:
            nav = nav.concat(transfer_manager_nav)
        }
      }
      return nav.concat(common_bottom_nav)
    })

    const renderItem = (item) => {
      // Фильтрация навигации по ролям юзера
      if (item.items) {
        return h(
          'span',
          {
            title: item.name,
          },
          [
            h(
              CNavGroup,
              {
                ...(firstRender.value && {
                  visible: item.items.some((child) =>
                    isActiveItem(route, child),
                  ),
                }),
                onClick: () => {
                  store.commit('toggleSidebar', true)
                },
              },
              {
                togglerContent: () => [
                  h(resolveComponent('CIcon'), {
                    customClassName: 'nav-icon',
                    name: item.icon,
                  }),
                  item.name,
                ],
                default: () => item.items.map((child) => renderItem(child)),
              },
            ),
          ],
        )
      }

      return item.to
        ? h(
            RouterLink,
            {
              to: item.to,
              custom: true,
              key: item.to,
            },
            {
              default: (props) =>
                h(
                  resolveComponent(item.component),
                  {
                    active: props.isActive,
                    href: props.href,
                    title: item.name,
                    onClick: () => props.navigate(),
                  },
                  {
                    default: () => [
                      item.icon &&
                        h(resolveComponent('CIcon'), {
                          customClassName: 'nav-icon',
                          icon: item.icon,
                        }),
                      item.name,
                      item.badge &&
                        h(
                          CBadge,
                          {
                            class: 'ms-auto',
                            color: item.badge.color,
                          },
                          {
                            default: () => item.badge.text,
                          },
                        ),
                    ],
                  },
                ),
            },
          )
        : h(
            resolveComponent(item.component),
            {},
            {
              default: () => item.name,
            },
          )
    }

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => navigation.value.map((item) => renderItem(item)),
        },
      )
  },
})
export { AppSidebarNav }
