import {onMounted, onUnmounted, ref} from 'vue'

export function useMediaSize ({w1 = '620px', w2 = '420px', w3 = '980px'} = {}) {
  const mediaQListW1 = window.matchMedia(`(max-width: ${w1})`)
  const mediaQListW2 = window.matchMedia(`(max-width: ${w2})`)
  const mediaQListW3 = window.matchMedia(`(max-width: ${w3})`)
  const isW1 = ref(mediaQListW1.matches)
  const isW2 = ref(mediaQListW2.matches)
  const isW3 = ref(mediaQListW3.matches)

  const mediaQListW1Handler = (ev) => {
    isW1.value = ev.matches
  }
  const mediaQListW2Handler = (ev) => {
    isW2.value = ev.matches
  }
  const mediaQListW3Handler = (ev) => {
    isW3.value = ev.matches
  }

  onMounted(() => {
    mediaQListW1.addEventListener('change', mediaQListW1Handler)
    mediaQListW2.addEventListener('change', mediaQListW2Handler)
    mediaQListW3.addEventListener('change', mediaQListW3Handler)
  })
  onUnmounted(() => {
    mediaQListW1.removeEventListener('change', mediaQListW1Handler)
    mediaQListW2.removeEventListener('change', mediaQListW2Handler)
    mediaQListW3.removeEventListener('change', mediaQListW3Handler)
  })

  return {
    isW1,
    isW2,
    isW3
  }
}
