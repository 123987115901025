// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class IntegrationsSberQrApiMethodsCls {
    async sberQrQrcodeList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // filters: id, status, type, created_at, payed_at, tour, buyer_contract, amount, net_amount,
      //          buyer_contract_date
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('sberQrQrcodeList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.sberQr.qrcode.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async sberQrQrcodeCreate(wh, data, extra_data = null) {
      // data: SberbankQrcode
      console.log('sberQrQrcodeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.integrations.sberQr.qrcode.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.integrations_sber_qrApi = new IntegrationsSberQrApiMethodsCls()
}

