<template>
  <div :id="tableId" class="custom-adaptive-table">
    <v-server-table
      :columns="columns"
      :options="options"
      ref="tableRef"
      :name="WH"
      @pagination="(page) => tableSettings.paginateHandle(page, `#${tableId}`)"
      @loaded="onTableContentLoaded"
    >
      <template #id="{ row }">
        <TableRowAdaptive>
          <template #mobile-column-title> ID </template>
          <template #content>
            <router-link :to="`/lead/${row.id}/`" class="d-block me-2"
              >#{{ row.id }}</router-link
            >
            <small class="nobr">{{ C.LEAD_STATUS_TITLES[row.status] }}</small>
          </template>
        </TableRowAdaptive>
      </template>

      <template #dates="{ row }">
        <TableRowAdaptive>
          <template #mobile-column-title> Даты </template>
          <template
            #content
            v-if="row.created_at || row.taken_at || row.closed_at"
          >
            <span class="d-block"
              >C:<span
                class="ps-2"
                v-html="dateService.formatDateTime(row.created_at)"
            /></span>
            <span class="d-block" v-if="row.taken_at"
              >Р:<span
                class="ps-2"
                v-html="dateService.formatDateTime(row.taken_at)"
            /></span>
            <span class="d-block" v-if="row.closed_at"
              >З:<span
                class="ps-2"
                v-html="dateService.formatDateTime(row.closed_at)"
            /></span>
          </template>
        </TableRowAdaptive>
      </template>

      <template #customer="{ row }">
        <TableRowAdaptive>
          <template #mobile-column-title> Заказчик </template>
          <template #content>
            <router-link class="d-block" :to="`/customer/${row.customer}`">
              {{ getCustomerName(row.customer) }}
            </router-link>
          </template>
        </TableRowAdaptive>
      </template>

      <template #locationAndHotel="{ row }">
        <TableRowAdaptive>
          <template #mobile-column-title> Содержание<br />заявки </template>
          <template #content>
            <div>
              <b v-if="row.status === C.LEAD_STATUS_ARCHIVED">{{
                C.LEAD_RESOLUTION_TITLES[row?.result]
              }}</b>
              <CollapseContent
                :id="`${row.id}-info`"
                max-height="60px"
                :recalc="doRecalcCollapseInfo"
                @recalced="doRecalcCollapseInfo = false"
              >
                <div class="flex mb-1 flex-wrap w-100">
                  <b v-if="relHotels[row.hotel]?.title">{{
                    relHotels[row.hotel]?.title
                  }}</b>
                  <span
                    v-if="
                      relHotels[row.hotel]?.title &&
                      relLocations[row.location]?.name
                    "
                    class="px-1"
                    >/</span
                  >
                  <b v-if="relLocations[row.location]?.name">{{
                    relLocations[row.location]?.name
                  }}</b>
                </div>
                <div>
                  <template v-if="row.text">
                    <small class="d-block fst-italic text-break">
                      {{ row.text }}
                    </small>
                  </template>
                  <!--                  <template v-if="row.comments?.length">-->
                  <!--                    <div-->
                  <!--                      v-for="comment in prepComments(row.comments)"-->
                  <!--                      :key="comment.id"-->
                  <!--                    >-->
                  <!--                      <div class="flex gap-3 text-black-50 pt-1">-->
                  <!--                        <small> {{ comment.author_name }}</small>-->
                  <!--                        <small-->
                  <!--                          v-html="-->
                  <!--                            dateService.formatDateTime(comment.created_at)-->
                  <!--                          "-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                      <small-->
                  <!--                        v-html="preprocessObjectsInText(comment.text)"-->
                  <!--                        class="d-block fst-italic"-->
                  <!--                      />-->
                  <!--                    </div>-->
                  <!--                  </template>-->
                </div>
              </CollapseContent>
              <div>
                <a
                  href=""
                  @click.prevent="showComments(row.id)"
                  class="link-primary form-label dotted-underline"
                >
                  <span>Комментарии</span>
                </a>
              </div>
            </div>
          </template>
        </TableRowAdaptive>
      </template>

      <template #contacts="{ row }">
        <TableRowAdaptive>
          <template #mobile-column-title> Контакты </template>
          <template #content>
            <div v-if="row.phones?.length">
              <CustomerPhones :phones="row.phones" :with-info="false" />
            </div>
            <div v-if="row.contacts?.length">
              <CustomerContacts
                :contacts="row.contacts.join(', ')"
                :with-info="false"
              />
            </div>
          </template>
        </TableRowAdaptive>
      </template>

      <template #source="{ row }">
        <TableRowAdaptive>
          <template #mobile-column-title> Источник </template>
          <template #content>
            <div class="me-2">{{ getLeadOrigin(row) }}</div>
            <a
              :href="row.url"
              v-if="row.url"
              class="d-inline-block text-nowrap overflow-hidden me-2"
              target="_blank"
              style="width: 150px; text-overflow: ellipsis"
              >{{ row.url }}</a
            >
            <div v-if="isAdmin && row.source_ip" class="me-2">
              IP: {{ row.source_ip }}
            </div>
          </template>
        </TableRowAdaptive>
      </template>

      <template #agent="{ row }">
        <TableRowAdaptive>
          <template #mobile-column-title> Агент </template>
          <template #content v-if="row.agent">
            <span class="d-block">{{ getAgentName(row.agent) }}</span>
          </template>
        </TableRowAdaptive>
      </template>
    </v-server-table>

    <PopupComments
      v-if="commentsConfig.id && commentsConfig.data_type"
      :data-id="commentsConfig.id"
      :data-type="commentsConfig.data_type"
      @closed="commentsConfig.id = null"
    />
  </div>
</template>

<script>
export const getDefaultFilters = () => {
  return {
    id: null,
    status: null,
    result: null,
    created_at: null,
    full_name: null,
    phone: null,
    contact: null,
    hotel: null,
    location: null,
    text: null,
    source_ip: null,
    origin: null,
    url: null,
    agent: null,
  }
}
</script>

<script setup>
import {
  ref,
  inject,
  watch,
  defineProps,
  onUnmounted,
  computed,
  onMounted,
  reactive,
} from 'vue'
import { useStore } from 'vuex'
import tableSettings from '@/config/table-config'
import C from '@/config/back_const'
import CollapseContent from '@/components/custom/CollapseContent.vue'
import { useAuthData } from '@/composables/authData'
import TableRowAdaptive from '@/components/custom/TableRowAdaptive.vue'
import { getFullName } from '@/helpers'
import CustomerPhones from '@/components/_customers/CustomerPhones.vue'
import CustomerContacts from '@/components/_customers/CustomerContacts.vue'
import PopupComments from '@/components/_comments/PopupComments.vue'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
    default: getDefaultFilters(),
  },
  tabFilter: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  api: {
    type: String,
    required: false,
    default: null,
  },
  doRefresh: {
    type: Boolean,
    required: false,
    default: false,
  },
  wh: { type: String, default: '' },
})
const emit = defineEmits(['refreshed', 'init', 'filters-update'])

const {
  fetch,
  tableService,
  dateService,
  storage,
  leadApi,
  personApi,
  locationApi,
  hotelApi,
  identityApi,
  userApi,
} = inject('services')

// eslint-disable-next-line vue/no-setup-props-destructure
const WH = props.wh

const store = useStore()

const { isAdmin, isSupervisor } = useAuthData()
const doRecalcCollapseInfo = ref(false)
const doRecalcCollapseContacts = ref(false)

const relCustomers = ref({})
const relPersons = ref({})
const relLocations = ref({})
const relHotels = ref({})
const relIdentities = ref({})
const relUsers = ref({})
const commentsConfig = reactive({ id: null, data_type: null })

const mediaQList = window.matchMedia('(max-width: 620px)')
const mediaQListMob = window.matchMedia('(max-width: 420px)')
const isMob = ref(mediaQListMob.matches)

const columns = computed(() => {
  const cols = [
    'id',
    'dates',
    'customer',
    'locationAndHotel',
    'contacts',
    'source',
  ]
  if (isAdmin.value || isSupervisor.value) {
    cols.push('agent')
  }
  return cols
})
const headings = {
  dates: 'Даты',
  customer: 'Заказчик',
  locationAndHotel: 'Содержание заявки',
  source: 'Источник',
}
const tableRef = ref('tableRef')
const tableData = ref([])
const tableId = computed(() => `leads-table-${props.wh}`)

const preprocessResponse = async (response) => {
  relCustomers.value = await storage.getRelations(
    response.data,
    'customer',
    'account__identity',
    identityApi.identityRetrieve,
    WH,
  )
  relPersons.value = await storage.getRelations(
    Object.values(relCustomers.value),
    'person',
    'core__person',
    personApi.personRetrieve,
    WH,
  )
  relLocations.value = await storage.getRelations(
    response.data,
    'location',
    'refbook__location',
    locationApi.locationRetrieve,
    WH,
  )
  relHotels.value = await storage.getRelations(
    response.data,
    'hotel',
    'refbook__hotel',
    hotelApi.hotelRetrieve,
    WH,
  )
  relIdentities.value = await storage.getRelations(
    response.data,
    'agent',
    'account__identity',
    identityApi.identityRetrieve,
    WH,
  )
  relUsers.value = await storage.getRelations(
    Object.values(relIdentities.value),
    'user',
    'account__user',
    userApi.userRetrieve,
    WH,
  )

  return response
}
const requestFunction = async (req) => {
  try {
    storage.clr(WH)
    store.commit('lockPageWS')
    const params = fetch.prepareQueryParams(req, {
      extraFilters: { ...props.filters, ...props.tabFilter },
      autocompletes: ['location', 'hotel', 'agent'],
      ranges: ['created_at'],
    })
    if (!params.sort) {
      // по умолчанию сортируем по дате от новых
      params.sort = '-dates'
    }
    params.sort = params.sort.replace(/dates$/, 'created_at') // переименование потому, что параметр sort отличается от имени колонки
    let response = null
    if (props.api) {
      response = await leadApi[props.api](WH, { ...params })
    } else {
      response = await leadApi.leadList(WH, { ...params })
    }

    tableData.value = await preprocessResponse(response)

    return {
      data: tableData.value,
    }
  } catch (error) {
    console.error(error)
  } finally {
    store.commit('unlockPage')
  }
}
const options = {
  ...tableSettings,
  columnsClasses: {
    dates: 'z-index-10',
    locationAndHotel: 'z-index-10',
    contacts: 'z-index-10',
  },
  headings: {
    ...tableSettings.headings,
    ...headings,
  },
  requestFunction: tableService.requestFnDebounceWrap(requestFunction),
  sortable: ['id', 'dates'],
  saveState: true,
  pagination: {
    ...tableSettings.pagination,
    chunk: mediaQList.matches ? (mediaQListMob.matches ? 1 : 5) : 10,
    edge: !mediaQListMob.matches,
  },
}
const getCustomerName = (customerId) => {
  const customerIdentity = relCustomers.value[customerId]
  const customerPerson = relPersons.value[customerIdentity?.person]
  return getFullName(customerPerson)
}
const getAgentName = (agentId) => {
  const agentIdentity = relIdentities.value[agentId]
  const agentUser = relUsers.value[agentIdentity?.user]
  return getFullName(agentUser)
}
const onTableContentLoaded = () => {
  emit('refreshed')
  doRecalcCollapseInfo.value = true
  doRecalcCollapseContacts.value = true
}
const mediaQListMobHandler = (ev) => {
  isMob.value = ev.matches
}
const showComments = (id, data_type = 'core__lead') => {
  commentsConfig.id = id
  commentsConfig.data_type = data_type
}
const getLeadOrigin = (row) => {
  // TODO изменения в api source -> origin, но константы ещё не обновлены
  if (!C.LEAD_SOURCE_TITLES) {
    console.log('>>> TODO', 'Заменить константы LEAD_SOURCE_TITLES для origin')
  }
  return C.LEAD_SOURCE_TITLES[row.origin]
}

watch(
  () => props.filters,
  () => {
    emit('filters-update')
    tableRef.value.refresh()
  },
  { deep: true },
)
watch(
  () => props.doRefresh,
  (val) => {
    if (val) {
      storage.clr(WH)
      tableRef.value.refresh()
    }
  },
)

onMounted(() => {
  mediaQListMob.addEventListener('change', mediaQListMobHandler)
  tableSettings.perPageSelectToBottom(`#${tableId.value}`)
  emit('init')
})
onUnmounted(() => {
  mediaQListMob.removeEventListener('change', mediaQListMobHandler)
  storage.clr(WH)
})
</script>
