// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class GenericApiMethodsCls {
    async autocompletePut(wh, data, extra_data = null) {
      // data: Autocomplete
      // поиск
      console.log('autocompletePut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.autocomplete.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async genericGetRetrieve(wh, data_type, id, extra_data = null) {
      // Получение произвольного объекта
      console.log('genericGetRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.genericGet.retrieve(data_type, id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async genericGetMultiplePut(wh, data, extra_data = null) {
      // data: MultipleGenericGet
      // Получение списка произвольных объектов
      console.log('genericGetMultiplePut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.genericGetMultiple.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async genericPrintRetrieve(wh, data_type, fmt, id, extra_data = null) {
      // Получение печатной формы произвольного объекта (fmt "html" или "pdf")
      console.log('genericPrintRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.genericPrint.retrieve(data_type, fmt, id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async genericTitleRetrieve(wh, data_type, id) {
      // Получение заголовка произвольного объекта
      console.log('genericTitleRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.genericTitle.retrieve(data_type, id)})
      return response.data
    }
    async searchList(wh, {filters} = {}, extra_data = null) {
      // Поиск туров по переданным параметрам
      // filters: search_string
      const params = { ...filters }
      console.log('searchList')
      // ----- 5 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.search.list(params)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async searchBuyersPut(wh, data, extra_data = null) {
      // data: SearchBuyers
      // Поиск Buyer и Identity customer по переданным параметрам
      console.log('searchBuyersPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.searchBuyers.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async typesList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка content types
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('typesList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.types.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.genericApi = new GenericApiMethodsCls()
}

