function readyStateCompleteCB(wnd, cb) {
  const interval = setInterval(() => {
    if (wnd.document.readyState === 'complete') {
      clearInterval(interval)
      if (cb) cb()
    }
  }, 100)
}

function rewriteDocument(wnd, content, onReadyCB = null) {
  wnd.document.open()
  wnd.document.write(content)
  readyStateCompleteCB(wnd, onReadyCB)
  wnd.document.close()
}

// добавляем стили чтобы скрыть контент в окне и показывать только в предпросмотре печати
function insertStyle(wnd) {
  const style = document.createElement('style')
  style.type = 'text/css'
  const css = `
    @media print {
      body * {
        visibility: visible;
      }
    }
    @media screen {
      body * {
        visibility: hidden;
      }
    }
  `
  style.appendChild(document.createTextNode(css))
  wnd.document.head.appendChild(style)
}

function printDocuments(wnd, documents, closeCb) {
  function printNextDocument(index) {
    if (index >= documents.length) {
      if (closeCb) closeCb()
      wnd.close()
      return
    }

    const doc = documents[index]
    rewriteDocument(wnd, doc?.content, () => {
      setTimeout(() => {
        insertStyle(wnd)
        wnd.print()
        rewriteDocument(wnd, '', () => {
          printNextDocument(index + 1)
        })
      }, 300)
    })
  }

  printNextDocument(0)
}

export const showPrintWindow = async (documents, closeCb = null) => {
  if (!documents?.length) return

  const winSettings = 'popup=1,left=100,top=100,width=800,height=600'
  const wnd = await window.open('/', '_blank', winSettings)

  if (!wnd) {
    console.log('Окно было заблокировано.')
    return
  }

  printDocuments(wnd, documents, closeCb)
}
