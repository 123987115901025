export default async (ctx) => {
  class PersistentService {

    constructor() {
      sessionStorage.clear()
    }

    async set(key, value) {
      const currentIdentityId = await this._getIdentityId()
      localStorage.setItem(`identity-${currentIdentityId}_${key}`, JSON.stringify(value))
    }

    async get(key) {
      const currentIdentityId = await this._getIdentityId()
      const item = localStorage.getItem(`identity-${currentIdentityId}_${key}`)
      return JSON.parse(item)
    }

    async remove(key) {
      const currentIdentityId = await this._getIdentityId()
      return localStorage.removeItem(`identity-${currentIdentityId}_${key}`)
    }

    async _getIdentityId() {
      const identity = await ctx.$services.auth.getIdentity()
      return identity?.id
    }

    async setGlobal(key, value) {
      // TODO refactor JSON.stringify сохраняет строку с кавычками + убрать асинхронность
      localStorage.setItem(`global_${key}`, JSON.stringify(value))
    }

    async getGlobal(key) {
      // TODO refactor убрать асинхронность
      const item = localStorage.getItem(`global_${key}`)
      return JSON.parse(item)
    }

    async removeGlobal(key) {
      return localStorage.removeItem(`global_${key}`)
    }

    async setLocal(key, value) {
      const currentIdentityId = await this._getIdentityId()
      localStorage.setItem(`identity-${currentIdentityId}_${key}`, JSON.stringify(value))
    }

    async getLocal(key) {
      const currentIdentityId = await this._getIdentityId()
      const item = localStorage.getItem(`identity-${currentIdentityId}_${key}`)
      return JSON.parse(item)
    }

    async removeLocal(key) {
      const currentIdentityId = await this._getIdentityId()
      return localStorage.removeItem(`identity-${currentIdentityId}_${key}`)
    }

    async setSession(key, value) {
      const currentIdentityId = await this._getIdentityId()
      sessionStorage.setItem(`identity-${currentIdentityId}_${key}`, JSON.stringify(value))
    }

    async getSession(key) {
      const currentIdentityId = await this._getIdentityId()
      const item = sessionStorage.getItem(`identity-${currentIdentityId}_${key}`)
      return JSON.parse(item)
    }

    async removeSession(key) {
      const currentIdentityId = await this._getIdentityId()
      return sessionStorage.removeItem(`identity-${currentIdentityId}_${key}`)
    }
  }

  ctx.$services.persistentService = new PersistentService()
}
