<template>
  <CNav variant="underline" role="tablist" class="mb-3">
    <CNavItem v-for="navItem in tabs" :key="navItem.name">
      <CNavLink
        href="javascript:void(0);"
        :active="activeTab === navItem.name"
        @click="activeTab = navItem.name"
        class="ps-2"
      >
        <span class="h6 fw-normal mb-0">
          <AppIcon :icon="navItem.icon" class="me-1" />
          {{ navItem.title }}
        </span>
      </CNavLink>
    </CNavItem>
  </CNav>
  <CTabContent class="px-1">
    <CTabPane
      v-for="tab in tabs"
      :key="tab.name"
      role="tabpanel"
      aria-labelledby="home-tab"
      :visible="activeTab === tab.name"
    >
      <component
        v-if="tab.content && props.hotel?.id"
        :is="tab.content"
        :hotel="props.hotel"
        :active="activeTab === tab.name"
        @get-data="emit('get-data')"
        @data-ready="emit('data-ready')"
      />
    </CTabPane>
  </CTabContent>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import tabs from './tabs'
import AppIcon from '@/plugins/app-icons/AppIcon.vue'

const props = defineProps({
  hotel: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['get-data', 'data-ready'])

const activeTab = ref(tabs[0].name)
</script>
