import { normalizeDownloadFilename } from '@/helpers/index.js'

const MIMETypes = {
  html: 'text/html',
  text: 'text/plain',
  pdf: 'application/pdf',
  xml: 'application/xml',
}

function clickLink(href, name) {
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadRelativeFile = (file, { fileName } = {}) => {
  // для файлов на том же домене
  // скачивать можно только по относительному url e.g. /files/documents/...
  const url = typeof file === 'string' ? file : file.url
  const name = fileName || url.substr(url.lastIndexOf('/') + 1)
  clickLink(url, name)
}

export const generateBlobUrl = (document, fmt) => {
  const blob = new Blob([document], { type: MIMETypes[fmt] })
  return URL.createObjectURL(blob)
}

export const downloadDocBlob = (
  document,
  { fmt = 'html', filename = 'download' } = {},
) => {
  const fileNameNorm = normalizeDownloadFilename(filename)
  const url = generateBlobUrl(document, fmt)

  clickLink(url, fileNameNorm)

  URL.revokeObjectURL(url)
}

export const downloadDocBlobByUrl = async (
  url,
  { fileName = 'download' } = {},
) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const downloadUrl = URL.createObjectURL(blob)

  clickLink(downloadUrl, fileName)

  URL.revokeObjectURL(url)
}
