import {ref} from 'vue'

export function useAccessWS (websocket) {
  const wsReady = ref(false)
  let onReadyActions = []

  // в теории при потере соединения нужно получать уведомление, сбрасывать wsReady, onReadyActions
  // и запускать checkWS. Все новое что попадёт в onReadyActions например send выполнится по готовности ws,
  // а старое продолжит работать с обновлённым значением websocket

  const checkWS = () => {
     if (!websocket?.connection || websocket.connection.readyState !== 1) {
       setTimeout(checkWS, 1000)
     } else {
       wsReady.value = true
       onReadyActions.forEach(cb => {
         if (cb) {
           setTimeout(cb)
         }
       })
       onReadyActions = []
     }
  }

  const setWSAction = (cb) => {
    if (!cb) return
    if (wsReady.value) {
      cb()
    } else {
      onReadyActions.push(cb)
    }
  }

  checkWS()

  return {
    setWSAction
  }
}
