<template>
  <div>
    <v-client-table
      v-if="data.length"
      :data="data"
      :columns="columns"
      :options="options"
    >

      <template #docNumber="{row}">
        <a href="" @click.prevent="idPayment=row.id">{{ row.doc_number }}</a>
      </template>

      <template #docDate="{row}">
        {{ dateService.formatDate(row.doc_date) }}
      </template>

      <template #amount="{row}">
        <div class="text-end">{{ formatCents(row.amount) }}</div>
      </template>

      <template #type="{row}">
        {{ C.DOCUMENT_STATE_TITLES[row.type] }}
      </template>

      <template #info="{row}">
        <strong>{{ contractors[row.contractor_entity]?.title }}</strong><br>
        {{ row.info }}
      </template>

      <template #paymentNumber="{row}">
        {{ row.payment_doc_number }}
      </template>

      <template #paymentDate="{row}">
        {{ dateService.formatDate(row.payment_doc_date) }}
      </template>

    </v-client-table>

  </div>
  <PopupPaymentInfo v-if="idPayment && (!paymentType || paymentType === C.IMPORT_FILE_TYPE_IMPORT_PAYMENTS)" :id="idPayment" @close="idPayment=null" />
  <PopupPaymentInfoBuyer v-if="idPayment && paymentType === C.IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS" :id="idPayment" @close="idPayment=null" />

</template>

<script setup>
import {computed, inject, onUnmounted, ref} from 'vue'
import {formatCents} from '@/helpers/textFormatter.js'
import C from "@/config/back_const"
import tableSettings from '@/config/table-config.js'
import PopupPaymentInfoBuyer from "@/components/_payment/PopupPaymentInfoBuyer.vue";
import PopupPaymentInfo from "@/components/_payment/PopupPaymentInfo.vue";

defineProps({
  data: {
    type: Array,
    required: true
  },
  contractors: {
    type: Object,
    required: false,
    default: () => ({})
  },
  active: {
    type: Boolean,
    required: true
  },
  paymentType: {
    type: Number,
    required: false,
    default: C.IMPORT_FILE_TYPE_IMPORT_PAYMENTS,
  },
})

const WH = 'c6dc51ee-f085-4e0b-9a44-3d79fb95a1bd'
const { storage, dateService } = inject('services')

const headings = {
  docNumber: '№ выписки',
  docDate: 'Дата выписки',
  amount: 'Сумма',
  type: 'Статус',
  info: 'Инфо',
  paymentNumber: '№ п/п',
  paymentDate: 'Дата п/п'
}
const columns = Object.keys(headings)
const columnsClasses = {
  check: 'w-5',
  paymentNumber: 'w-5 nobr',
  paymentDate: 'w-5 nobr z-index-5',
  type: 'w-5 nobr',
  info: 'w-50',
  docNumber: 'w-5 nobr',
  docDate: 'w-5 nobr z-index-5'
}
const options = computed(() => {
  return {
    ...tableSettings,
    filterable: false,
    texts: { count: '', limit: '' },
    headings,
    columnsClasses,
    sortable: []
  }
})
const idPayment = ref(null)

onUnmounted(() => {
  storage.clr(WH)
})
</script>

<style lang="scss" scoped>

</style>
