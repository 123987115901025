// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class LeadApiMethodsCls {
    async leadList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Заявок
      // filters: created_at, updated_at, company_owner, status, result, origin, division, url,
      //          initiator, agent, customer, adviser, taken_at, action_till, action_at,
      //          postponed_till, reserved_till, close_till, closed_at, location, diseases, hotel,
      //          room, program, number_of_adults, number_of_children, check_in_fr, check_in_to,
      //          days_fr, days_to, name, surname, patronymic, source_ip, form_id, text, duplicate,
      //          postponement_count, hijack_at, tele_call, utm_source, utm_program, utm_content,
      //          utm_term, utm_block, id, full_name, phone, contact, xstatus
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('leadList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async leadCreate(wh, data, extra_data = null) {
      // data: Lead
      // Создание Заявки
      console.log('leadCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadFuckedUpList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Проё***ные лиды
      // filters: created_at, updated_at, company_owner, status, result, origin, division, url,
      //          initiator, agent, customer, adviser, taken_at, action_till, action_at,
      //          postponed_till, reserved_till, close_till, closed_at, location, diseases, hotel,
      //          room, program, number_of_adults, number_of_children, check_in_fr, check_in_to,
      //          days_fr, days_to, name, surname, patronymic, source_ip, form_id, text, duplicate,
      //          postponement_count, hijack_at, tele_call, utm_source, utm_program, utm_content,
      //          utm_term, utm_block, id, full_name, phone, contact, xstatus
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('leadFuckedUpList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.fuckedUp.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async leadTakePost(wh, data, extra_data = null) {
      // data: Lead
      // Взять заявку в работу агентом
      console.log('leadTakePost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.take.post(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadRetrieve(wh, id, extra_data = null) {
      // Получение Заявки
      console.log('leadRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__lead', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__lead', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadActionListRetrieve(wh, id, extra_data = null) {
      // Список доступных действий для конкретной заявки
      console.log('leadActionListRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.actionList.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadAdminRestoreUpdate(wh, id, data, extra_data = null) {
      // data: Lead
      // Передача админом лида из архива агенту
      console.log('leadAdminRestoreUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.adminRestore.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadApproveUpdate(wh, id, extra_data = null) {
      // Создать путевку
      console.log('leadApproveUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.approve.update(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadArchiveUpdate(wh, id, data, extra_data = null) {
      // data: Lead
      // Отменить
      console.log('leadArchiveUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.archive.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadChangeUpdate(wh, id, data, extra_data = null) {
      // data: Lead
      // Обновление Заявки
      console.log('leadChangeUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.change.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadHandOverUpdate(wh, id, data, extra_data = null) {
      // data: Lead
      // Передать другому агенту
      console.log('leadHandOverUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.handOver.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadHijackUpdate(wh, id, extra_data = null) {
      // Агент забирает заявку у другого агента
      console.log('leadHijackUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.hijack.update(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadPostponeUpdate(wh, id, extra_data = null) {
      // Отложить
      console.log('leadPostponeUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.postpone.update(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async leadRestoreUpdate(wh, id, data, extra_data = null) {
      // data: Lead
      // Восстановить
      console.log('leadRestoreUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.restore.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.leadApi = new LeadApiMethodsCls()
}

