<template>
  <div>
    <CModal
      ref="modal" :visible="storageViewerVisible"
      backdrop="static" alignment="center" size="xl" fullscreen="md" @close="onClose"

    >
      <CModalHeader dismiss>
        <CModalTitle> Хранилище</CModalTitle>
      </CModalHeader>
      <CModalBody>

        <CNav variant="tabs">
          <CNavItem>
            <CNavLink :active="tabPaneActiveKey === 1" @click="() => { tabPaneActiveKey = 1 }"> TOP</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink :active="tabPaneActiveKey === 2" @click="() => { tabPaneActiveKey = 2 }">AUTH</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink :active="tabPaneActiveKey === 3" @click="() => { tabPaneActiveKey = 3 }">storage
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent class="overflow-scroll">
          <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 1">
            <h2>TOP</h2>
            <pre style="max-height:800px; font-size:70%; background-color: lightblue">{{
                storage._storage['TOP']
              }}</pre>
          </CTabPane>
          <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 2">
            <h2>AUTH</h2>
            <pre style="max-height:800px; font-size:70%; background-color: lightblue">{{
                storage._storage['AUTH']
              }}</pre>
          </CTabPane>
          <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 3">
            <h1>storage</h1>
            <pre style="max-height:800px; font-size:70%; background-color: lightblue">{{ storage._storage }}</pre>
          </CTabPane>
        </CTabContent>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onClose">Закрыть</CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>


<script>

import {computed, inject, ref} from 'vue'
import {useStore} from "vuex";

export default {
  name: 'StorageViewer',
  components: {},
  setup() {
    const {storage} = inject('services')
    const store = useStore()

    const onClose = () => {
      store.commit('toggleStorageViewer')
    }

    const tabPaneActiveKey = ref(1)

    return {
      storage,
      storageViewerVisible: computed(() => store.state.storageViewerVisible),
      onClose,
      tabPaneActiveKey
    }
  },
}

</script>

