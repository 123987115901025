<template>
  <!--  Выбор типа файлов  -->
  <div>
    <CRow v-if="fileTypeSelect" class="flex justify-content-center p-2">
      <CCol>
        <CFormSelect
          v-model="fileTypeModel"
          label="Тип загрузки"
          class="min-w-2"
        >
          <option value="image">Фото</option>
          <option value="file">Файл</option>
        </CFormSelect>
      </CCol>
    </CRow>
  </div>

  <!-- Выбор файлов -->
  <div>
    <CRow class="flex justify-content-center">
      <CCol class="p-3">
        <div class="upload-file-input position-relative">
          <input
            class="opacity-0 position-absolute w-100 h-100"
            ref="fileInput"
            type="file"
            name="file"
            id="file"
            multiple
            @change="handleFileChange"
          />
          <label
            for="file"
            @dragover.prevent="onDragOver"
            @dragleave.prevent="onDragLeave"
            @drop.prevent="onDrop"
            @click.prevent=""
            class="position-relative rounded flex justify-content-center align-items-center text-center"
            :class="{ 'upload-drag-over': isDragOver }"
          >
            <span class="flex flex-column gap-2">
              <template v-if="!isDragOver">
                <span class="fw-medium fs-4 m-0 no-mouse-events">
                  Перетащите {{ fileTypeText }} для загрузки
                </span>
                <span class="text-secondary fw-medium fs-5 no-mouse-events">
                  или
                </span>
                <CButton
                  color="primary"
                  variant="outline"
                  class="px-5 mx-auto mt-2"
                  @click="triggerFileInput"
                  ><span>Выберите</span>
                </CButton>
              </template>
            </span>
          </label>
        </div>
      </CCol>
    </CRow>
    <CRow class="flex justify-content-center">
      <CCol :class="{ 'p-3': showPreUpload || showFilesList }">
        <CListGroup>
          <CListGroupItem
            v-if="showPreUpload"
            active
            class="d-flex justify-content-between align-items-center"
          >
            {{ filesListText }}
          </CListGroupItem>
          <template v-if="showFilesList">
            <CListGroupItem
              v-for="(file, idx) in fileList"
              :key="file.id"
              :color="statusToColorName(resultPerFile[idx]?.status)"
              class="d-flex justify-content-between align-items-center file-list-item"
            >
              <div>
                <small>{{ file.name }}</small>
                <small class="ms-2 fw-medium">{{
                  formatFileSize(file.size)
                }}</small>
                <div>{{ getFileErrorText(idx) }}</div>
              </div>
              <CCloseButton v-if="!result" @click="removeFile(file)" />
            </CListGroupItem>
          </template>
        </CListGroup>
      </CCol>
    </CRow>
    <CRow class="flex justify-content-center">
      <CCol class="text-end">
        <!-- результат -->
        <div v-if="result">
          <CAlert
            :color="result.color"
            class="flex justify-content-between align-items-center"
          >
            {{ result.text }}
            <CSpinner v-if="result.state === 'loading'" size="sm" />
          </CAlert>

          <!-- результат по загрузке доп файлов -->
          <div v-if="addUploadStatusFormated">
            <div class="fw-bold text-start mb-1">
              Результат загрузки файлов для других объектов:
            </div>
            <div
              v-for="uploadStatus in addUploadStatusFormated"
              :key="uploadStatus.objectId"
              class="mb-2"
            >
              <CListGroup>
                <CListGroupItem :color="uploadStatus.success ? 'success' : ''">
                  <div
                    class="fw-medium text-start flex justify-content-between"
                  >
                    <div>
                      {{ uploadStatus.objectTitle }} #{{
                        uploadStatus.objectId
                      }}
                    </div>
                    <div>{{ uploadStatus.success ? 'Успешно' : '' }}</div>
                  </div>
                </CListGroupItem>
                <CListGroupItem
                  v-for="(file, index) in uploadStatus.files"
                  :key="index"
                  :color="file.error ? 'danger' : 'success'"
                >
                  <div class="flex justify-content-between">
                    <small class="fw-medium">{{ file.fileName }}</small>
                    <small>{{
                      file.error ? 'Ошибка загрузки' : 'Загружен'
                    }}</small>
                  </div>
                </CListGroupItem>
              </CListGroup>
            </div>
          </div>
        </div>
        <template v-if="showPreUpload">
          <teleport
            v-if="mounted"
            :to="`#id-buttons-${modalWh}`"
            :disabled="!modalWh"
          >
            <CButton
              color="primary"
              :disabled="fileList.length === 0 || result?.state === 'loading'"
              @click="handleUpload"
              >Загрузить</CButton
            >
          </teleport>
        </template>
      </CCol>
    </CRow>
    <teleport v-if="mounted" :to="`#id-dev-${modalWh}`" :disabled="!modalWh">
      <ThePodval
        title="Компонент загрузки файлов"
        :tasks="['https://tracker.yandex.ru/BACK-4534']"
        :wiki="[
          'https://wiki.yandex.ru//homepage/proekt/api/zaprosy/zapros-fajjlov-i-izobrazhenijj/',
          'https://wiki.yandex.ru/homepage/proekt/interfejjs/obshhie-jelementy/blok-dokumenty/',
        ]"
        :uuid="WH"
      />
    </teleport>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { v4 as uuid } from 'uuid'
import { formatFileSize, inflectNoun } from '@/helpers/textFormatter'
import { useRequest } from '@/composables/request'
import CC from '@/config/constants'
import { useAuthData } from '@/composables/authData'
import { normalizeApiErrors } from '@/helpers'
import ThePodval from '@/components/_shared/ThePodval.vue'

const props = defineProps({
  objectId: {
    type: [Number, String],
    required: true,
  },
  objectType: {
    type: String,
    required: true,
  },
  modalWh: {
    type: String,
    default: null,
  },
  // image, file
  fileType: {
    type: String,
    default: 'file',
  },
  fileTypeSelect: {
    type: Boolean,
    default: true,
  },
  /** @exmple
   * // дополнительные объекты к которым также необходимо загрузить эти файлы отдельно
   * [
   *     { data_type: 'core__booking', id: props.data?.id },
   *     { data_type: 'core__tour', id: tourId.value },
   *   ]
   */
  addTo: {
    type: Array,
    default: () => [],
  },
  /** @exmple
   * // дополнительные объекты к которым необходимо привязать (прилинковать) загружаемые файлы
   * [
   *     { data_type: 'core__booking', id: props.data?.id },
   *     { data_type: 'core__tour', id: tourId.value },
   *   ]
   */
  linkTo: {
    type: Array,
    default: () => [],
  },
  // TEMPORARY если временный файл для привязки к личности
  fixedTitle: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits(['upload-complete'])

const WH = '4d1f9d1d-6810-4bc3-9460-769d34f7bd01'
const { mediaApi } = inject('services')

const { wrapRequest: wrapUpload } = useRequest()
const { myIdentity } = useAuthData()

const mounted = ref(false)
const fileInput = ref(null)
const isDragOver = ref(false)
const fileTypeModel = ref(props.fileType)
const fileList = ref([])
const resultConf = {
  loading: {
    state: 'loading',
    color: 'primary',
    text: 'Подождите, идёт загрузка...',
  },
  success: {
    state: 'success',
    color: 'success',
    text: 'Загрузка успешно завершена!',
  },
  warning: {
    state: 'warning',
    color: 'warning',
    text: 'Некоторые файлы не были загружены!',
  },
  error: {
    state: 'error',
    color: 'danger',
    text: 'Произошла ошибка при загрузке файлов!',
  },
}
const result = ref()
const resultPerFile = ref([])
const addUploadStatusFormated = ref(null)

const filesListText = computed(() => {
  const len = fileList.value.length
  const loadFileText = inflectNoun(len, ['загружен', 'загружено', 'загружено'])
  const loadImageText = inflectNoun(len, [
    'загружено',
    'загружено',
    'загружено',
  ])
  const typeFileText = inflectNoun(len, ['файл', 'файла', 'файлов'])
  const typeImageText = inflectNoun(len, ['фото', 'фото', 'фото'])
  const loadText =
    fileTypeModel.value === 'image' ? loadImageText : loadFileText
  const fileTypeText =
    fileTypeModel.value === 'image' ? typeImageText : typeFileText
  return len ? `Будет ${loadText} ${len} ${fileTypeText}` : 'Ничего не выбрано'
})
const showFilesList = computed(() => {
  return !result.value || result.value.state !== 'success'
})
const showPreUpload = computed(() => {
  return !result.value && fileList.value.length
})
const fileTypeText = computed(() => {
  return fileTypeModel.value === 'image' ? 'фото' : 'файлы'
})

const triggerFileInput = () => {
  fileInput.value?.click()
}
const processFiles = (files) => {
  fileList.value = Array.from(files).map((f) => {
    f.id = uuid()
    return f
  })
}
const onSelectFiles = (files) => {
  result.value = null
  resultPerFile.value = []
  processFiles(files)
}
const handleFileChange = (event) => {
  const files = event.target.files
  onSelectFiles(files)
}
const removeFile = (file) => {
  fileList.value = fileList.value.filter(({ id }) => id !== file.id)
}
const statusToColorName = (status) => {
  if (!status) return null
  return status === 'fulfilled' ? 'success' : 'danger'
}
const getFileErrorText = (idx) => {
  const response = resultPerFile.value[idx]
  const errData = normalizeApiErrors(response)
  const firstErr = errData?.[0]
  return firstErr || null
}

const onDrop = (event) => {
  isDragOver.value = false
  const files = event.dataTransfer.files
  onSelectFiles(files)
}
const onDragOver = () => {
  isDragOver.value = true
}
const onDragLeave = () => {
  isDragOver.value = false
}

const uploadFiles = async ({
  objectType = props.objectType,
  objectId = props.objectId,
} = {}) => {
  const files = fileList.value

  if (!objectId) {
    objectId = myIdentity.value.id
    objectType = myIdentity.value.data_type
  }

  const uploadFn =
    fileTypeModel.value === 'image'
      ? mediaApi.imageUploadCreate
      : mediaApi.fileUploadCreate

  const uploadProm = files.map((file) => {
    const data = {
      file,
      title: file.name,
      object_id: objectId,
      object_type: objectType,
    }
    if (props.fixedTitle) {
      data.fixed_title = props.fixedTitle
    }
    return uploadFn(WH, data)
  })

  return await Promise.allSettled(uploadProm)
}

// ручки прилинковки отменили (оставим эту функцию на случай если передумают)
// прилинковать загруженные файлы к доп. объектам
const linkFiles = async (files) => {
  const linkFn =
    fileTypeModel.value === 'image'
      ? (wh, hash, data) => {
          console.log(`Link Images ${wh}, ${hash}, ${JSON.stringify(data)}`)
        }
      : (wh, hash, data) => {
          console.log(`Link Images ${wh}, ${hash}, ${JSON.stringify(data)}`)
        }

  const linkProm = files.map((file) => {
    const { title, file: hash } = file
    const data = {
      objects: [],
      unlink_temp: false,
    }
    for (const obj of props.linkTo) {
      const { data_type: object_type, id: object_id } = obj
      data.objects.push({
        object_type,
        object_id,
        title,
        fixed_title: null,
      })
    }
    return linkFn(WH, hash, data)
  })

  return await Promise.allSettled(linkProm)
}

const formatAdditionalUploadStatus = (uploadState) => {
  return uploadState.result.map(
    ({ objectType, objectId, result: filesResult }) => {
      const namedDataType = CC.dataTypeOptions.find(
        ({ value }) => value === objectType,
      )
      const objectTitle = namedDataType?.label || objectType
      const success = uploadState.status !== 'error'
      let files = []
      if (!success) {
        // если где-то ошибка то показываем по файлам
        files = filesResult.map(({ status }, i) => {
          const fileName = fileList.value[i].name
          const error = status === 'rejected'
          return {
            fileName,
            error,
          }
        })
      }
      return {
        objectTitle,
        objectId,
        success,
        files,
      }
    },
  )
}

// загрузить как отдельные файлы для доп. объектов
const additionalUpload = async () => {
  const uploadState = { status: 'success', result: [] }

  for (const obj of props.addTo) {
    const { data_type: objectType, id: objectId } = obj
    const { result, error } = await wrapUpload(async () =>
      uploadFiles({ objectType, objectId }),
    )

    if (error) {
      uploadState.result.push({
        error,
        objectId,
        objectType,
      })
      uploadState.status = 'error'
      continue
    }
    const someHasError = result?.some((r) => r?.status !== 'fulfilled')
    if (someHasError) {
      uploadState.status = 'error'
    }

    uploadState.result.push({
      result,
      objectId,
      objectType,
    })
  }

  addUploadStatusFormated.value = formatAdditionalUploadStatus(uploadState)
}

const handleUpload = async () => {
  result.value = resultConf.loading
  const { result: requestResult, error } = await wrapUpload(uploadFiles)
  if (error) {
    result.value = resultConf.error
    return
  }
  resultPerFile.value = requestResult

  // TODO бэк
  // если файл уже привязан к объекту - не выдавать ошибку, а возвращать этот файл

  const someHasError = resultPerFile.value.some(
    (r) => r?.status !== 'fulfilled',
  )
  const someHasSuccess = resultPerFile.value.some(
    (r) => r?.status === 'fulfilled',
  )
  result.value =
    someHasError && someHasSuccess
      ? resultConf.warning
      : someHasError
      ? resultConf.error
      : resultConf.success

  const uploadedFiles = requestResult || []
  const uploadedFileValues = uploadedFiles
    .filter(({ status }) => status === 'fulfilled')
    .map(({ value }) => value)

  if (props.addTo.length) {
    await additionalUpload()
  }

  if (props.linkTo.length) {
    await linkFiles(uploadedFileValues)
  }

  emit('upload-complete', uploadedFileValues)
}

onMounted(() => {
  mounted.value = true
})
</script>

<style lang="scss" scoped>
$bg-highlight: rgba(var(--cui-primary-rgb), 0.1) !important;
$brd_highlight: rgba(var(--cui-primary-rgb), 0.5) !important;

.toast-hover:hover {
  color: var(--cui-primary) !important;
  border-color: $brd_highlight;
}
.file-list-item:hover {
  background-color: $bg-highlight;
}

.upload-drag-over {
  border-color: $brd_highlight;
  background-color: $bg-highlight;
}

.upload-file-input {
  height: 210px;
  & label {
    border: 1px dashed var(--cui-gray-200);
    min-height: 200px;
    padding: 48px;
  }
}
</style>
