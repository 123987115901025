<template>
  <div class="minimizable" ref="modalContainer">
    <CModal
      ref="modal"
      :visible="isVisible"
      size="xl"
      backdrop="static"
      alignment="center"
      fullscreen="lg"
      scrollable
      @close="onClose"
    >
      <ModalMinimize v-if="modalContainer" :modal-container="modalContainer" />
      <CModalHeader dismiss>
        <CModalTitle>
          <span>{{ title }}</span>
        </CModalTitle>
      </CModalHeader>
      <CModalBody class="popup-min-h" :id="`id-${WH}`">
        <HotelPricesInfo
          :data="data"
          :modal-wh="WH"
          :select-mode="selectMode"
          @shift-date-to="onShiftDateTo"
        />
      </CModalBody>
      <div :id="`id-after-body-${WH}`" class="flex justify-content-end"></div>
      <CModalFooter>
        <CButton color="secondary" @click="onClose">Закрыть</CButton>
      </CModalFooter>
      <ThePodval
        title="Просмотр цен"
        :tasks="[
          'https://tracker.yandex.ru/BACK-2277',
          'https://tracker.yandex.ru/BACK-1136',
          'https://tracker.yandex.ru/BACK-4456',
          'https://tracker.yandex.ru/BACK-3986',
        ]"
        :wiki="[
          'https://wiki.yandex.ru/homepage/proekt/api/zaprosy/zapros-cen/',
        ]"
        :uuid="WH"
      />
    </CModal>
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import HotelPricesInfo from '@/components/_shared/hotel-prices/HotelPricesInfo.vue'
import ModalMinimize from '@/components/_common/ModalMinimize.vue'
import ThePodval from '@/components/_shared/ThePodval.vue'

const props = defineProps({
  modalTitle: {
    type: String,
    required: false,
    default: () => '',
  },
  // cat, prog
  selectMode: {
    type: String,
    default: 'cat',
  },
  /**
   * @type {{
   *   hotel: number
   *   category?: number
   *   program?: number
   *   date_from?: string
   *   date_to?: string
   *   days_to?: number
   * }}
   */
  data: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['close'])
const { dateService } = inject('services')
const WH = '3e95e53b-9f44-40f1-ac6a-d27c9c511272'

const isVisible = ref(false)
const modalContainer = ref(null)
const title = ref(props.modalTitle)
const onClose = () => {
  isVisible.value = false
  emit('close')
}

const onShiftDateTo = ({ hotelTitle = '', dateFrom, dateTo, dayTariff }) => {
  title.value = `${hotelTitle}, цены за период с ${dateService.formatDate(
    dateFrom,
  )} по ${dateService.formatDate(dateTo)} (${
    dayTariff ? 'тариф дневной' : 'тариф ночной'
  })`
}

onMounted(() => {
  isVisible.value = true
})
</script>
