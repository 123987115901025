import { setURLDebug } from '@/helpers/index.js'

class ClientLogger {
  interval = 5e3
  maxSize = 60
  log = {
    ws: { lstore: 'ws-log', data: [], initialized: false },
    console: { lstore: 'console-log', data: [], initialized: false },
    request: { lstore: 'request-log', data: [], initialized: false },
  }

  constructor() {
    this.#init()
  }

  getDateStr() {
    return new Date().toLocaleString()
  }

  #clearLocalLog(logname) {
    this.log[logname].data.length = 0
  }

  #getFromLSRaw(logname) {
    return localStorage.getItem(this.log[logname].lstore)
  }

  #getFromLS(logname) {
    return JSON.parse(this.#getFromLSRaw(logname)) || []
  }

  #saveToLS(logname, val) {
    try {
      localStorage.setItem(this.log[logname].lstore, JSON.stringify(val))
    } catch (err) {
      console.log('Client logger error:')
      console.log(err)
    }
  }

  save(logname) {
    const curLog = this.log[logname]

    if (!curLog.initialized) return

    const data = this.#getFromLS(logname)
    const combined = data.concat(this.log[logname].data)
    this.#saveToLS(logname, combined.slice(this.maxSize * -1))
    this.#clearLocalLog(logname)
  }

  addRefreshRecord(logname) {
    const curLog = this.log[logname]

    if (!curLog.initialized) return

    const record = {
      time: this.getDateStr(),
      data: 'REFRESH PAGE',
    }
    this.log[logname].data.push(record)
  }

  addRecord = (logname, data, params) => {
    const curLog = this.log[logname]

    if (!curLog.initialized) return

    const record = {
      time: this.getDateStr(),
      data,
    }

    if (params?.identityRef) {
      record.identity = params.identityRef.value
    }

    if (params?.request) {
      record.request = params.request
    }

    this.log[logname].data.push(record)
  }

  #download = (logname) => {
    this.save(logname)

    const blob = new Blob([this.#getFromLSRaw(logname)], {
      type: 'application/json',
    })
    const link = document.createElement('a')

    link.download = `${this.log[logname].lstore}-${this.getDateStr().replace(
      /[\s:,]+/g,
      '-',
    )}.json`
    link.href = URL.createObjectURL(blob)
    link.click()
    URL.revokeObjectURL(link.href)
  }

  #setKeys() {
    window.addEventListener('keydown', (ev) => {
      if (ev.repeat) return
      if (ev.ctrlKey && ev.altKey && (ev.key === 'l' || ev.key === 'д')) {
        for (const [key, val] of Object.entries(this.log)) {
          if (val.initialized) {
            this.#download(key)
          }
        }
      }
    })
  }

  initConsoleLogger() {
    const local = this
    const origLog = console.log.bind(console)
    const origErrLog = console.error.bind(console)
    setURLDebug('showLogTrace', true)
    console.log = function () {
      origLog.apply(console, arguments)
      if (window.dbg?.showLogTrace) console.trace()
      local.addRecord('console', arguments)
    }
    console.error = function () {
      origErrLog.apply(console, arguments)
      local.addRecord('console', arguments)
    }
    this.log.console.initialized = true
  }

  #init() {
    this.#setKeys()
    const logNames = Object.keys(this.log)
    setInterval(() => {
      logNames.forEach(this.save.bind(this))
    }, this.interval)
  }
}

// todo DOCS
const logger = new ClientLogger()

export const consoleClientLoggerInit = () => {
  logger.initConsoleLogger()
  logger.addRefreshRecord('console')
}

export const wsClientLoggerInit = () => {
  logger.log.ws.initialized = true
  logger.addRefreshRecord('ws')
  return (message, params) => {
    logger.addRecord('ws', message, params)
  }
}

export const requestClientLoggerInit = () => {
  logger.log.request.initialized = true
  logger.addRefreshRecord('request')
  return (data, params) => {
    logger.addRecord('request', data, params)
  }
}
