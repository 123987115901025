/**
 * Добавляет возможность прокручивать элемент мышкой
 * @param {string} selector - селектор элемента с прокруткой
 * @returns {(function(): void)} - возвращает функцию для отмены события
 * @example ImagesTab.vue
 */
export const setDragScroll = (selector) => {
  const pos = { top: 0, left: 0, x: 0, y: 0 }

  const el = document.querySelector(selector)
  el.style.cursor = 'grab'

  function mouseMoveHandler(ev) {
    ev.preventDefault()

    const dx = ev.clientX - pos.x
    const dy = ev.clientY - pos.y

    el.scrollTop = pos.top - dy
    el.scrollLeft = pos.left - dx
  }
  function mouseDownHandler(ev) {
    el.style.cursor = 'grabbing'
    el.style.userSelect = 'none'
    pos.left = el.scrollLeft
    pos.top = el.scrollTop
    pos.x = ev.clientX
    pos.y = ev.clientY

    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler)
  }
  function mouseUpHandler() {
    document.removeEventListener('mousemove', mouseMoveHandler)
    document.removeEventListener('mouseup', mouseUpHandler)

    el.style.cursor = 'grab'
    el.style.removeProperty('user-select')
  }

  el.addEventListener('mousedown', mouseDownHandler)

  return () => {
    el.removeEventListener('mousedown', mouseDownHandler)
  }
}
