import { onUnmounted, inject } from 'vue'
import { useTelecalls } from '@/composables/telecalls/telecalls'

export const useTelecallsForSimpleTable = (WH, request, extra = null) => {
  const {
    rowFullinfo,
    TelecallFullinfoModal,
    TableRowAdaptive,
    TelecallTableRow,
    getTelecall,
    load
  } = useTelecalls(WH, request, extra)

  const { storage } = inject('services')

  const handlerTableRow = (data) => {
    if (data) {
      rowFullinfo.value = data
    }
  }

  onUnmounted(() => {
    storage.clr(WH)
  })

  return {
    getTelecall,
    rowFullinfo,
    TelecallFullinfoModal,
    TableRowAdaptive,
    TelecallTableRow,
    handlerTableRow,
    load
  }
}
