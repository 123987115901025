// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class OperationsApiMethodsCls {
    async operationBookingArchiveCreate(wh, data, extra_data = null) {
      // data: TourOperation
      console.log('operationBookingArchiveCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingArchive.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingAvailableCreate(wh, data, extra_data = null) {
      // data: OperationBookingAvailable
      // Получено подтверждение наличия свободных мест
      console.log('operationBookingAvailableCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingAvailable.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingBookCreate(wh, data, extra_data = null) {
      // data: OperationBookingGotResponse
      // Бронирование
      console.log('operationBookingBookCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingBook.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingBookPaymentToObjectCreate(wh, data, extra_data = null) {
      // data: BookingBookPaymentToObject
      // Бронирование, если у брони оплата в объект отдыха
      console.log('operationBookingBookPaymentToObjectCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingBookPaymentToObject.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingCancelCreate(wh, data, extra_data = null) {
      // data: BookingCancel
      // Отмена брони
      console.log('operationBookingCancelCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingCancel.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingInquireCreateCreate(wh, data, extra_data = null) {
      // data: OperationBookingInquire
      // Создание запроса на бронирование
      console.log('operationBookingInquireCreateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingInquireCreate.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingInquireSendCreate(wh, data, extra_data = null) {
      // data: OperationBookingInquireSend
      // Отправка запроса на бронирование
      console.log('operationBookingInquireSendCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingInquireSend.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingInvoicePendingCreate(wh, data, extra_data = null) {
      // data: OperationBookingInvoicePending
      // Ожидание счета для брони
      console.log('operationBookingInvoicePendingCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingInvoicePending.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingPayCreate(wh, data, extra_data = null) {
      // data: TourOperation
      // Оплата брони
      console.log('operationBookingPayCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingPay.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingRefuseCreate(wh, data, extra_data = null) {
      // data: OperationBookingRefuse
      // Не может быть забронировано
      console.log('operationBookingRefuseCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingRefuse.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingRequestCreateCreate(wh, data, extra_data = null) {
      // data: OperationBookingPlacesRequest
      // Создание запроса на наличие свободных мест
      console.log('operationBookingRequestCreateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingRequestCreate.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingRequestSendCreate(wh, data, extra_data = null) {
      // data: OperationBookingPlacesSendRequest
      // Отправка запроса наличия свободных мест
      console.log('operationBookingRequestSendCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingRequestSend.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingRestoreCreate(wh, data, extra_data = null) {
      // data: BookingRestore
      // Восстановление брони
      console.log('operationBookingRestoreCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingRestore.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingRevokeApprovedCreate(wh, data, extra_data = null) {
      // data: BookingRevokeApproved
      // Отказ от брони утвержден
      console.log('operationBookingRevokeApprovedCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingRevokeApproved.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingRevokeCreateCreate(wh, data, extra_data = null) {
      // data: BookingRevokeCreate
      // Создание запроса на отказ от брони
      console.log('operationBookingRevokeCreateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingRevokeCreate.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingRevokeSendCreate(wh, data, extra_data = null) {
      // data: BookingRevokeSend
      // Отправка запроса на отказ от брони
      console.log('operationBookingRevokeSendCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingRevokeSend.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingStartCreate(wh, data, extra_data = null) {
      // data: TourOperation
      // Начало оказания услуг
      console.log('operationBookingStartCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingStart.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingSwapBookCreate(wh, data, extra_data = null) {
      // data: OperationSwapBook
      // Изменение брони
      console.log('operationBookingSwapBookCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingSwapBook.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingSwapCreateCreate(wh, data, extra_data = null) {
      // data: BookingSwapCreate
      // Создание запроса на изменение брони
      console.log('operationBookingSwapCreateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingSwapCreate.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingSwapRefuseCreate(wh, data, extra_data = null) {
      // data: BookingSwapRefuse
      // Бронь не может быть изменена
      console.log('operationBookingSwapRefuseCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingSwapRefuse.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingSwapSendCreate(wh, data, extra_data = null) {
      // data: BookingSwapSend
      // Отправка запроса на изменение брони
      console.log('operationBookingSwapSendCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingSwapSend.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingToQuotaCreate(wh, data, extra_data = null) {
      // data: BookingToQuota
      // Перевести бронь в квоту
      console.log('operationBookingToQuotaCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingToQuota.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationBookingUnavailableCreate(wh, data, extra_data = null) {
      // data: OperationBookingUnavailable
      // Получен отказ наличия свободных мест
      console.log('operationBookingUnavailableCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.bookingUnavailable.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationGetBookingCostCreate(wh, data, extra_data = null) {
      // data: BookingGetCost
      // Расчет стоимости брони
      console.log('operationGetBookingCostCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.getBookingCost.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourAgreeCreate(wh, data, extra_data = null) {
      // data: OperationTourAgree
      // Операция согласования тура
      console.log('operationTourAgreeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourAgree.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourAnnulCreate(wh, data, extra_data = null) {
      // data: OperationTourAnnul
      // Операция аннуляции тура
      console.log('operationTourAnnulCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourAnnul.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourCancelCreate(wh, data, extra_data = null) {
      // data: OperationTourCancel
      // Операция отмены тура
      console.log('operationTourCancelCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourCancel.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourCancelCheckinPaymentCreate(wh, data, extra_data = null) {
      // data: OperationTourCancelPayment
      // Отмена отсроченного платежа по номеру договора
      console.log('operationTourCancelCheckinPaymentCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourCancelCheckinPayment.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourCloseCreate(wh, data, extra_data = null) {
      // data: OperationTourClose
      // Операция закрытия тура
      console.log('operationTourCloseCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourClose.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourFinishCreate(wh, data, extra_data = null) {
      // data: OperationTourFinish
      // Операция завершения тура
      console.log('operationTourFinishCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourFinish.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourReceiveCheckinPaymentCreate(wh, data, extra_data = null) {
      // data: OperationTourReceiveCheckinPayment
      // Прием отсроченного платежа по номеру договора
      console.log('operationTourReceiveCheckinPaymentCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourReceiveCheckinPayment.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourResetCorrectionsCreate(wh, data, extra_data = null) {
      // data: TourResetCorrections
      // Сброс корректировок и скидок
      console.log('operationTourResetCorrectionsCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourResetCorrections.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourRevokeCreate(wh, data, extra_data = null) {
      // data: OperationTourRevoke
      // Операция отказа от тура
      console.log('operationTourRevokeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourRevoke.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourSetCorrectionsCreate(wh, data, extra_data = null) {
      // data: TourSetCorrections
      // Установка корректировок и скидок
      console.log('operationTourSetCorrectionsCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourSetCorrections.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourSetDiscountPercentCreate(wh, data, extra_data = null) {
      // data: TourSetDiscountPercent
      // Установка одинаковой скидки
      console.log('operationTourSetDiscountPercentCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourSetDiscountPercent.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTourStartCreate(wh, data, extra_data = null) {
      // data: OperationTourStart
      // Операция запуска тура в работу
      console.log('operationTourStartCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.tourStart.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferAcceptCreate(wh, data, extra_data = null) {
      // data: TransferBookingAccept
      // Принять трансфер к исполнению
      console.log('operationTransferAcceptCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferAccept.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferAgreeCreate(wh, data, extra_data = null) {
      // data: TransferBookingAgree
      // Согласование бронирования трансфера
      console.log('operationTransferAgreeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferAgree.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferAppointCreate(wh, data, extra_data = null) {
      // Назначение водителя с машиной на бронирование трансфера
      console.log('operationTransferAppointCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferAppoint.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferCancelCreate(wh, data, extra_data = null) {
      // data: TransferBookingCancel
      // Отменить трансфер
      console.log('operationTransferCancelCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferCancel.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferCloseCreate(wh, data, extra_data = null) {
      // data: TransferBookingClose
      // Закрытие бронирования трансфера
      console.log('operationTransferCloseCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferClose.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferConfirmCreate(wh, data, extra_data = null) {
      // data: TransferBookingConfirm
      // Подтверждение бронирования трансфера
      console.log('operationTransferConfirmCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferConfirm.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferDoneCreate(wh, data, extra_data = null) {
      // data: TransferBookingDone
      // Завершение бронирования трансфера
      console.log('operationTransferDoneCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferDone.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferRejectCreate(wh, data, extra_data = null) {
      // data: TransferBookingReject
      // Отклонение бронирования трансфера
      console.log('operationTransferRejectCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferReject.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferRequestCreate(wh, data, extra_data = null) {
      // data: TransferBookingRequest
      // Выслать запрос трансфера
      console.log('operationTransferRequestCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferRequest.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferSendToCustomerCreate(wh, data, extra_data = null) {
      // data: TransferBookingSendToCustomer
      // Отправить трансфер заказчику и туристам
      console.log('operationTransferSendToCustomerCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferSendToCustomer.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferSendToDriverCreate(wh, data, extra_data = null) {
      // data: TransferBookingSendToDriver
      // Отправить трансфер водителю
      console.log('operationTransferSendToDriverCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferSendToDriver.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferSetInstructionsCreate(wh, data, extra_data = null) {
      // data: TransferBookingSetInstructions
      // Инструкции для водителя трансфера
      console.log('operationTransferSetInstructionsCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferSetInstructions.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async operationTransferUnappointCreate(wh, data, extra_data = null) {
      // data: TransferBookingUnAppoint
      // Отмена назначения водителя с машиной на бронирование трансфера
      console.log('operationTransferUnappointCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.operation.transferUnappoint.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
  }

  ctx.$services.operationsApi = new OperationsApiMethodsCls()
}

