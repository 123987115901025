// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AuthApiMethodsCls {
    async tokenList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка постоянных токенов для компании
      // filters: key, refresh_key, identity, persistent, valid_till, refresh_till, ip_address, info
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('tokenList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.token.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async tokenCreate(wh, data, extra_data = null) {
      // data: PersistentTokenCreate
      // Создание постоянного токена
      console.log('tokenCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.token.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tokenRetrieve(wh, id, extra_data = null) {
      // Получение постоянного токена по id
      console.log('tokenRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.token.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tokenDelete(wh, id) {
      // Удаление постоянного токена
      console.log('tokenDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.account.token.delete(id)})
      ctx.$services.storage.del('None', id, wh)
      return null
    }
    async acceptDataCreate(wh, data, extra_data = null) {
      // data: AcceptInviteStageThree
      // Принятие инвайта, стадия 3
      // Создание User (или получение, в случае если есть Identity c ролью CUSTOMER) + создание Identity
      console.log('acceptDataCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.accept.data.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async acceptPincodeCreate(wh, data, extra_data = null) {
      // data: AcceptInviteStageTwo
      // Принятие инвайта, стадия 2
      // Проверка пинкода, возвращение данных инвайта
      console.log('acceptPincodeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.accept.pincode.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async acceptStartCreate(wh, data, extra_data = null) {
      // data: AcceptInviteStageOne
      // Принятие инвайта, стадия 1
      // Получение телефона, инвайта
      console.log('acceptStartCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.accept.start.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async customerCreate(wh, data, extra_data = null) {
      // data: CustomerLogin
      // Аутентификация заказчика
      // выполняется при работе со сторонними сайтами
      console.log('customerCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.customer.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async customerRegisterDataCreate(wh, data, extra_data = null) {
      // data: StageThreeCustomerRegister
      // Регистрация заказчика, стадия 3
      console.log('customerRegisterDataCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.customerRegister.data.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async customerRegisterPincodeCreate(wh, data, extra_data = null) {
      // data: StageTwo
      // Регистрация заказчика, стадия 2
      console.log('customerRegisterPincodeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.customerRegister.pincode.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async customerRegisterStartCreate(wh, data, extra_data = null) {
      // data: StageOneCustomerRegister
      // Регистрация заказчика, стадия 1
      console.log('customerRegisterStartCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.customerRegister.start.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hashCreate(wh, data, extra_data = null) {
      // data: Hash
      // Авторизация по хешу
      console.log('hashCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.hash.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async personalCreate(wh, data, extra_data = null) {
      // data: PersonalLogin
      // Аутентификация пользователя
      // выполняется при работе из веб-морды
      console.log('personalCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.personal.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async pincodePincodeCreate(wh, data, extra_data = null) {
      // data: StageTwo
      // Авторизация по пинкоду, стадия 2
      console.log('pincodePincodeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.pincode.pincode.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async pincodeStartCreate(wh, data, extra_data = null) {
      // data: StageOneAuthByPinCode
      // Авторизация по пинкоду, стадия 1
      // Получение телефона, генерация токена, пинкода
      console.log('pincodeStartCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.pincode.start.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async refreshCreate(wh, data, extra_data = null) {
      // data: RefreshToken
      // Перегенерация токена
      // Перегенерация токена должна выполняться когда срок действия основного токена подходит концу или уже завершен
      console.log('refreshCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.refresh.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async registerDataCreate(wh, data, extra_data = null) {
      // data: StageThree
      // Регистрация, стадия 3
      // Регистрация Company, Entity, User, Identity
      console.log('registerDataCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.register.data.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async registerPincodeCreate(wh, data, extra_data = null) {
      // data: StageTwo
      // Регистрация, стадия 2
      // Проверка пинкода
      console.log('registerPincodeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.register.pincode.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async registerStartCreate(wh, data, extra_data = null) {
      // data: StageOne
      // Регистрация, стадия 1
      // Получение телефона, генерация токена, пинкода
      console.log('registerStartCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.register.start.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async restorePincodeCreate(wh, data, extra_data = null) {
      // data: StageTwo
      // Запрос восстановления пароля, стадия 2
      // Проверка пинкода, авторизация
      console.log('restorePincodeCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.restore.pincode.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async restoreStartCreate(wh, data, extra_data = null) {
      // data: StageOneAuthByPinCode
      // Запрос восстановления пароля, стадия 1
      // Получение телефона, генерация токена, пинкода
      console.log('restoreStartCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.auth.restore.start.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.authApi = new AuthApiMethodsCls()
}

