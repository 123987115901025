// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ShiftApiMethodsCls {
    async hotelShiftsRetrieve(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка смен для отеля
      // filters: created_at, updated_at, name, check_in, check_out
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('hotelShiftsRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__shift', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__shift', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.shifts.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async shiftCreate(wh, data, extra_data = null) {
      // data: Shift
      // Создание Смены
      console.log('shiftCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.shift.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async shiftRetrieve(wh, id, extra_data = null) {
      // Получение Смены
      console.log('shiftRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__shift', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__shift', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.shift.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async shiftUpdate(wh, id, data, extra_data = null) {
      // data: Shift
      // Обновление Смены
      console.log('shiftUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.shift.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async shiftDelete(wh, id) {
      // Удаление Смены
      console.log('shiftDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.refbook.shift.delete(id)})
      ctx.$services.storage.del('refbook__shift', id, wh)
      return null
    }
  }

  ctx.$services.shiftApi = new ShiftApiMethodsCls()
}

