<template>
  <div class="contacts-edit">
    <template v-if="contactsIsLoaded">
      <template v-if="contactsList.length">
        <ContactsEditRow
          v-for="(contact, index) in contactsList"
          :key="contact.id"
          v-model="contactsList[index]"
          :object-type="contact.object_type"
          :object-id="contact.object_id"
          @complete-action="onCompleteAction"
        />
        <FieldModelErrors :errors="validationErrors" />
      </template>
      <div
        v-else
        class="border rounded-3 d-flex flex-row align-items-center justify-content-center mb-3"
      >
        <div
          class="label d-flex flex-row align-items-center text-secondary"
          style="padding: 0.38rem"
        >
          <span>Контакты</span>
        </div>
      </div>
      <div v-if="addBtnVisible" class="d-flex justify-content-end">
        <CButton @click="addNewContact" size="sm" color="primary">
          <CIcon icon="cilPlus" size="sm" />
          Добавить контакт
        </CButton>
      </div>
    </template>
    <div
      v-else
      class="border rounded-3 d-flex flex-row align-items-center justify-content-center p-4"
    >
      <div class="label d-flex flex-row align-items-center">
        <CSpinner color="secondary" class="me-2" />
        <span>Загрузка</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import ContactsEditRow from '@/components/_custom/contacts-edit/ContactsEditRow.vue'
import { provide, inject, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import FieldModelErrors from '@/components/form/FieldModelErrors.vue'

const WH = 'ContactsEdit'
const props = defineProps({
  dataSearch: {
    type: [Array, Boolean],
    default: false,
  },
  objectType: {
    type: String,
    required: true,
  },
  objectId: {
    type: [String, Number],
    required: false,
    default: () => undefined,
  },
  companyId: {
    type: [Number, String],
    required: true,
  },
  // начальные значения.
  // Например для формы создания новой персоны, когда персоны ещё нет, но нужно добавить в форму контакты. Затем после создания персоны добавить эти контакты к ней.
  initialContacts: {
    type: Array,
    required: false,
    default: () => [],
  },
  validationErrors: {
    type: Array,
    default: null,
  },
})
const emit = defineEmits(['complete-action'])
const { contactApi } = inject('services')
const contactsObjectId = ref(props.objectId)
const contactsObjectType = ref(props.objectType)
const contactsList = ref([])
const contactsIsLoaded = ref(false)
const addBtnVisible = ref(true)

provide('contactsList', contactsList)

const newContact = ({ objectType, objectId }) => {
  return {
    id: uuidv4(),
    new: true,
    number: '',
    info: null,
    object_type: objectType,
    object_id: objectId,
    company: props.companyId,
  }
}
const addNewContact = () => {
  addBtnVisible.value = false
  contactsList.value.push(
    newContact({
      objectType: props.objectType,
      objectId: props.objectId,
    }),
  )
}
const onCompleteAction = () => {
  emit('complete-action', contactsList.value)
  addBtnVisible.value = true
}

watch(
  () => props.initialContacts,
  (val) => {
    if (!val || props.objectId) return
    // добавляем начальные значения так же как если бы они пришли с сервера
    contactsList.value = val
    contactsIsLoaded.value = true
    onCompleteAction()
  },
  { immediate: true },
)

onMounted(async () => {
  if (props.dataSearch) {
    try {
      for (let object of props.dataSearch) {
        let contactsObject = await contactApi.contactList(
          WH,
          object.object_id,
          object.object_type,
        )
        contactsObject.data.forEach((contact) => {
          contactsList.value.push(contact)
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      contactsIsLoaded.value = true
    }
    return
  }
  if (props.objectId) {
    try {
      const list = await contactApi.contactList(
        WH,
        contactsObjectId.value,
        contactsObjectType.value,
      )
      contactsList.value = list.data
      contactsIsLoaded.value = true
    } catch (error) {
      console.error(error)
    } finally {
      contactsIsLoaded.value = true
    }
  }
})
</script>
