<template>
  <CHeader position="sticky">
    <CContainer fluid class="justify-content-start">
      <ActionButton
        icon="menu"
        color="primary"
        size="sm"
        icon-size="lg"
        :action="toggleSidebar"
      />
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <!--<CNavLink href="/#/"> Главная </CNavLink>-->
        </CNavItem>
      </CHeaderNav>

      <!--      <CHeaderBrand class="mx-auto" to="/">-->
      <!--        <CIcon :icon="logo" height="48" alt="Logo" />-->
      <!--      </CHeaderBrand>-->

      <div class="icon-lg-size-fix pe-2">
        <ActionButton
          v-if="
            (C.ADMIN_ROLES.includes(myIdentity?.role) ||
              C.AGENT_ROLES.includes(myIdentity?.role)) &&
            myCompany.is_agency
          "
          icon="create"
          tooltip="Создать лид"
          color="info"
          size="sm"
          icon-size="lg"
          :action="createDraftLead"
        />
      </div>

      <AppStatus />

      <CTooltip content="Текущая градация сетки">
        <template #toggler="{ on }">
          <span class="mx-3" v-on="on"> <GridInfo /> </span>
        </template>
      </CTooltip>

      <CTooltip content="Текущая роль">
        <template #toggler="{ on }">
          <span class="mx-3" v-on="on">{{ roleTitle(myIdentity?.role) }}</span>
        </template>
      </CTooltip>

      <CTooltip content="Текущая компания" v-if="myCompanyShortName">
        <template #toggler="{ on }">
          <span class="mx-3" v-on="on">{{ myCompanyShortName }}</span>
        </template>
      </CTooltip>

      <div id="header-online-status">
        <!-- для Teleport из AppStatus-->
      </div>

      <ActionButton
        v-if="auth.isHijacked()"
        icon="release"
        tooltip="Прекратить работу из-под другого пользователя"
        color="warning"
        size="sm"
        icon-size="lg"
        :action="onRelease"
      />

      <ActionButton
        icon="info"
        tooltip="Просмотр хранилища данных"
        color="info"
        size="sm"
        icon-size="lg"
        :action="
          () => {
            $store.commit('toggleStorageViewer')
          }
        "
      />

      <ActionButton
        icon="tasks"
        tooltip="Просмотр списка фоновых задач"
        color="info"
        size="sm"
        icon-size="lg"
        :action="
          () => {
            $store.commit('togglePopup', 'taskViewer')
          }
        "
      />

      <ActionButton
        icon="search"
        tooltip="Поиск"
        color="primary"
        size="sm"
        icon-size="lg"
        :action="() => store.commit('togglePopup', 'searchLT')"
      />

      <ActionButton
        icon="bug"
        tooltip="Багрепорт"
        color="primary"
        size="sm"
        icon-size="lg"
        :action="() => store.commit('togglePopup', 'newIssue')"
      />

      <div>
        <ActionButton
          icon="file"
          color="primary"
          size="sm"
          icon-size="lg"
          tooltip="Файлы"
          :action="
            () => {
              shFilesModal = true
            }
          "
        />
        <ViewFilesModal
          v-if="shFilesModal"
          object-type=""
          :object-id="null"
          @close="shFilesModal = false"
        />
      </div>

      <CHeaderNav class="me-2">
        <AppHeaderDropdownTasks />
        <!-- <AppHeaderDropdownNotif />-->
        <!-- <AppHeaderDropdownMssgs />-->
      </CHeaderNav>

      <div
        id="header-phone-icon"
        :style="`--header-phone-icon-scale: ${
          store.getters.webPhoneStarted ? 1.4 : 1
        }`"
      >
        <ActionButton
          icon="phone"
          tooltip="Телефония"
          :color="phoneColor"
          size="sm"
          icon-size="lg"
          :action="onOpenWebPhone"
          :disabled="!localPhones?.length"
        />
      </div>

      <div id="header-chat-icon">
        <!-- для Teleport из AppStatus-->
      </div>

      <CHeaderNav class="ms-2 me-2">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider class="d-none d-md-block" />
    <CContainer fluid class="d-none d-md-block">
      <AppBreadcrumb />
    </CContainer>
  </CHeader>

  <PopupLeadView v-if="newLeadId" :id="newLeadId" @close="newLeadId = false" />
  <PopupWebphoneClose
    v-if="showPopupWebPhoneClose"
    @close="onCloseWebPhonePopup"
  />
</template>

<script setup>
import { inject, ref, onBeforeMount, watch, computed, onUnmounted } from 'vue'
import AppBreadcrumb from './AppBreadcrumb'
import C from '@/config/back_const'

import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
// import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
// import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ActionButton from '@/components/_common/ActionButton'
import GridInfo from '@/pages/components/GridInfo'
import { strLimit } from '@/helpers/textFormatter'
import PopupLeadView from '@/popups/common/PopupLeadView.vue'
import PopupWebphoneClose from '@/components/popups/PopupWebphoneClose.vue'
import AppStatus from '@/components/AppStatus.vue'
import { useWebPhone } from '@/composables/webPhone'
import { useAuthData } from '@/composables/authData'
import ViewFilesModal from '@/components/_custom/view-files/ViewFilesModal.vue'

const WH = 'TOP'
const { auth, storage, leadApi, accountApi, localPhoneApi } = inject('services')
const bus = inject('bus')

const DEBUG_APP_HEADER = true
const router = useRouter()
const store = useStore()

const origDocTitle = document.title
const { myIdentity, myUser, myCompany } = useAuthData()
const { openWebPhone, closeWebPhone, focusWebPhone, webPhoneSetDial } =
  useWebPhone({
    unloadCb: () => {
      showPopupWebPhoneClose.value = true
    },
  })
const newLeadId = ref(null)
const showPopupWebPhoneClose = ref(false)
const localPhones = ref(null)
const myCompanyShortName = computed(() => {
  if (myCompany.value?.name) {
    return strLimit(myCompany.value.name, 20)
  } else {
    return ''
  }
})
const phoneColor = computed(() => {
  return store.getters.webPhoneStarted ? 'success' : 'danger'
})
const shFilesModal = ref(false)

const roleTitle = (role) => C.ROLES_TITLES[role]

const updLocalPhones = async () => {
  try {
    const _localPhones = await localPhoneApi.localPhoneList(WH, {
      filters: { identity: myIdentity.value.id },
    })
    localPhones.value = _localPhones.data
  } catch (err) {
    console.error(err)
  }
}

const onRelease = async () => {
  storage.clr(WH) // TO DO STORAGE_BUG _2990
  await auth.release()
  bus.emit('authentication-changed')
  router.push({ path: '/' })
}

const toggleSidebar = () => {
  if (store.state.sidebarVisible) {
    store.commit('toggleSidebar')
  } else {
    store.commit('toggleSidebarVisibility', true)
  }
}

const createDraftLead = async () => {
  const data = {
    // todo изменено api source -> origin константы пока не переименованы
    origin: C.LEAD_SOURCE_MANUALLY,
    agent:
      myIdentity.value?.role === C.ROLES_AGENT ? myIdentity.value.id : null,
  }
  const res = await leadApi.leadCreate(WH, data)
  newLeadId.value = res.id
}

const onOpenWebPhone = () => {
  if (DEBUG_APP_HEADER) {
    console.log('>>>>> Open WebPhone')
    console.log(
      '>>>>> current store.getters.webPhoneStarted',
      store.getters.webPhoneStarted,
    )
  }

  try {
    if (store.getters.webPhoneStarted) {
      if (focusWebPhone) {
        if (DEBUG_APP_HEADER) {
          console.log('>>>>> focusWebPhone')
        }
        focusWebPhone()
      }
      return
    }
    // принудительно очистим перед открытием
    // на случай если до этого было некорректное закрытие и в LS ничего не прописалось
    if (DEBUG_APP_HEADER) {
      console.log('>>>>> clear webphone LS state')
    }
    window.localStorage.setItem(
      'webPhoneWin',
      JSON.stringify({ isOpen: false, isReady: false }),
    )
    store.commit('toggleWebPhoneStarted', true)
    accountApi.onlineCreate(WH)
    openWebPhone()
    console.log(
      '>>>>> current store.getters.webPhoneStarted',
      store.getters.webPhoneStarted,
    )
  } catch (err) {
    console.error(err)
  }
}
const onCloseWebPhone = () => {
  store.commit('toggleWebPhoneStarted', false)
  if (closeWebPhone) closeWebPhone()
}
const onCloseWebPhonePopup = (closeArg) => {
  store.commit('toggleWebPhoneStarted', false)
  showPopupWebPhoneClose.value = false
  if (closeArg === 'cancel') {
    onOpenWebPhone()
  }
}
const beforeunloadHandler = () => {
  onCloseWebPhone()
}

watch(
  myIdentity,
  (val) => {
    updLocalPhones()
    if (DEBUG_APP_HEADER) {
      console.log(
        'AppHeader authentication-changed myIdentity',
        val.__storage_counter,
        val.user,
      )
    }
  },
  { deep: true },
)
watch(
  myUser,
  (val) => {
    if (DEBUG_APP_HEADER) {
      console.log(
        'AppHeader authentication-changed myUser',
        val.__storage_counter,
        val.name,
      )
    }
  },
  { deep: true },
)
watch(
  myCompany,
  (val) => {
    if (DEBUG_APP_HEADER) {
      console.log(
        'AppHeader authentication-changed myCompany',
        val.__storage_counter,
        val.name,
      )
    }
  },
  { deep: true },
)
watch(
  () => store.state.webPhoneStarted,
  (val) => {
    document.title = val ? `⭕ ${origDocTitle} ⭕` : origDocTitle
  },
)

onBeforeMount(async () => {
  bus.on('set-user-online', onOpenWebPhone)
  bus.on('set-user-offline', onCloseWebPhone)
  bus.on('webphone-set-dial', webPhoneSetDial)
  window.addEventListener('beforeunload', beforeunloadHandler, {
    capture: true,
  })
})
onUnmounted(() => {
  bus.off('set-user-online', openWebPhone)
  bus.off('set-user-offline', closeWebPhone)
  bus.off('webphone-set-dial', webPhoneSetDial)
  window.removeEventListener('beforeunload', beforeunloadHandler, {
    capture: true,
  })
})
</script>
