// Этот файл сгенерирован автоматически. Не надо его редактировать.

export default {
    account: {
        allOnline: {
            // Получение списка статусов онлайн. Фильтр - одно из значений константы STATUS_ONLINE_FILTER
            list: (params = {}) => ({url:`api/account/all_online/`, method:'GET', params})
        },
        authinfo: {
            // Получение информации о текущей аутентификации
            retrieve: () => ({url:`api/account/authinfo/`, method:'GET'})
        },
        change: {
            // Смена роли
            create: () => ({url:`api/account/change/`, method:'POST'})
        },
        company: {
            // Получение списка компаний
            list: (params = {}) => ({url:`api/account/company/`, method:'GET', params}),
            // Создание компании
            create: () => ({url:`api/account/company/`, method:'POST'}),
            createex: {
                // Создание Компании и юрлица
                create: () => ({url:`api/account/company/createex/`, method:'POST'})
            },
            // Получение компании
            retrieve: (id) => ({url:`api/account/company/${id}/`, method:'GET'}),
            // Обновление компании
            update: (id) => ({url:`api/account/company/${id}/`, method:'PUT'}),
            // Удаление компании
            delete: (id) => ({url:`api/account/company/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для компании (Company)
                partial: (id) => ({url:`api/account/company/${id}/activate/`, method:'PATCH'})
            },
            calendar: {
                year: {
                    // Получение календаря компании на год
                    list: (id, year) => ({url:`api/account/company/${id}/calendar/${year}/`, method:'GET'})
                },
                month: {
                    // Получение календаря компании на месяц
                    list: (id, month, year) => ({url:`api/account/company/${id}/calendar/${year}/${month}/`, method:'GET'})
                }
            },
            calendarFill: {
                // Заполнение календаря компании на год
                post: (id) => ({url:`api/account/company/${id}/calendar_fill/`, method:'POST'})
            },
            calendarSet: {
                // Установка (изменения) в календаре компании
                post: (id) => ({url:`api/account/company/${id}/calendar_set/`, method:'POST'})
            },
            identities: {
                // Все доступные Identities компании, за исключением CUSTOMER
                list: (id, params = {}) => ({url:`api/account/company/${id}/identities/`, method:'GET', params})
            },
            phones: {
                // Все внутренние телефоны компании
                list: (id, params = {}) => ({url:`api/account/company/${id}/phones/`, method:'GET', params})
            },
            roles: {
                // Список ролей в компании
                list: (id) => ({url:`api/account/company/${id}/roles/`, method:'GET'})
            },
            setting: {
                // Получение настроек компании
                retrieve: (id) => ({url:`api/account/company/${id}/setting/`, method:'GET'})
            },
            settingPut: {
                // Обновление настроек компании
                update: (id) => ({url:`api/account/company/${id}/setting_put/`, method:'PUT'})
            }
        },
        customer: {
            changeAgent: {
                // Смена агента у заказчика
                partial: (id) => ({url:`api/account/customer/${id}/change_agent/`, method:'PATCH'})
            },
            listNewAgents: {
                // Список агентов на которых можно сменить агента у данного заказчика
                retrieve: (id, params = {}) => ({url:`api/account/customer/${id}/list_new_agents/`, method:'GET', params})
            }
        },
        customers: {
            // Получение списка клиентов (заказчиков). Основная информация приходит в ключе extra_data 
            list: (params = {}) => ({url:`api/account/customers/`, method:'GET', params})
        },
        dev: {
            error: {
                // Получение ответа с заданной ошибкой
                retrieve: (code) => ({url:`api/account/dev/error/${code}/`, method:'GET'})
            }
        },
        hijack: {
            // Получение токена другого пользователя
            create: () => ({url:`api/account/hijack/`, method:'POST'}),
            identity: {
                // Получение токена другой личности
                create: () => ({url:`api/account/hijack/identity/`, method:'POST'})
            }
        },
        identity: {
            // Все Identity компании, к которой принадлежит пользователь
            list: (params = {}) => ({url:`api/account/identity/`, method:'GET', params}),
            // Создание Identity
            create: () => ({url:`api/account/identity/`, method:'POST'}),
            createex: {
                // Одновременное создание User и Identity
                create: () => ({url:`api/account/identity/createex/`, method:'POST'})
            },
            password: {
                // Изменение пароля заказчика
                create: () => ({url:`api/account/identity/password/`, method:'POST'})
            },
            // Получение модели Identity
            retrieve: (id) => ({url:`api/account/identity/${id}/`, method:'GET'}),
            // Обновление модели Identity
            update: (id) => ({url:`api/account/identity/${id}/`, method:'PUT'}),
            // Обновление полей модели Identity
            partial: (id) => ({url:`api/account/identity/${id}/`, method:'PATCH'}),
            // Удаление Identity
            delete: (id) => ({url:`api/account/identity/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для личности (Identity)
                partial: (id) => ({url:`api/account/identity/${id}/activate/`, method:'PATCH'})
            },
            calendar: {
                year: {
                    // Получение календаря сотрудника на год
                    list: (id, year) => ({url:`api/account/identity/${id}/calendar/${year}/`, method:'GET'})
                },
                month: {
                    // Получение календаря сотрудника на месяц
                    list: (id, month, year) => ({url:`api/account/identity/${id}/calendar/${year}/${month}/`, method:'GET'})
                }
            },
            calendarFill: {
                // Заполнение календаря сотрудника на год
                post: (id) => ({url:`api/account/identity/${id}/calendar_fill/`, method:'POST'})
            },
            calendarSet: {
                // Установка (изменения) в календаре сотрудника
                post: (id) => ({url:`api/account/identity/${id}/calendar_set/`, method:'POST'})
            },
            organizations: {
                // Получение списка Organization для Identity
                list: (id, params = {}) => ({url:`api/account/identity/${id}/organizations/`, method:'GET', params})
            },
            persons: {
                // Получение списка Person для Identity
                list: (id, params = {}) => ({url:`api/account/identity/${id}/persons/`, method:'GET', params})
            }
        },
        invite: {
            // Создать инвайт
            create: () => ({url:`api/account/invite/`, method:'POST'})
        },
        invites: {
            // Список инвайтов
            list: () => ({url:`api/account/invites/`, method:'GET'})
        },
        logout: {
            // Выход из системы для временных токенов
            delete: () => ({url:`api/account/logout/`, method:'DELETE'})
        },
        myroles: {
            // Получение списка доступных мне ролей, за исключением CUSTOMER
            list: (params = {}) => ({url:`api/account/myroles/`, method:'GET', params})
        },
        offline: {
            // Поменять статус на офлайн
            create: () => ({url:`api/account/offline/`, method:'POST'})
        },
        online: {
            // Поменять статус на онлайн
            create: () => ({url:`api/account/online/`, method:'POST'})
        },
        personal: {
            // Персонал компании оператора
            list: (params = {}) => ({url:`api/account/personal/`, method:'GET', params})
        },
        status: {
            // Получение статуса для Identity
            retrieve: () => ({url:`api/account/status/`, method:'GET'})
        },
        supervisor: {
            // Получение агентов супервизора
            list: (params = {}) => ({url:`api/account/supervisor/`, method:'GET', params}),
            offline: {
                // Поменять статус агента на офлайн (для супервизора, или админа)
                post: (id) => ({url:`api/account/supervisor/${id}/offline/`, method:'POST'})
            },
            online: {
                // Поменять статус агента на онлайн (для супервизора, или админа)
                post: (id) => ({url:`api/account/supervisor/${id}/online/`, method:'POST'})
            }
        },
        token: {
            // Получение списка постоянных токенов для компании
            list: (params = {}) => ({url:`api/account/token/`, method:'GET', params}),
            // Создание постоянного токена
            create: () => ({url:`api/account/token/`, method:'POST'}),
            // Получение постоянного токена по id
            retrieve: (id) => ({url:`api/account/token/${id}/`, method:'GET'}),
            // Удаление постоянного токена
            delete: (id) => ({url:`api/account/token/${id}/`, method:'DELETE'})
        },
        user: {
            // Получение списка User
            list: (params = {}) => ({url:`api/account/user/`, method:'GET', params}),
            // Создание User
            create: () => ({url:`api/account/user/`, method:'POST'}),
            password: {
                // Изменение пароля пользователем
                create: () => ({url:`api/account/user/password/`, method:'POST'}),
                // Изменение пароля пользователя, руководителем компании, если пользователь is_managed
                partial: (id) => ({url:`api/account/user/${id}/password/`, method:'PATCH'})
            },
            // Получение User
            retrieve: (id) => ({url:`api/account/user/${id}/`, method:'GET'}),
            // Обновление модели User
            update: (id) => ({url:`api/account/user/${id}/`, method:'PUT'}),
            // Удаление User
            delete: (id) => ({url:`api/account/user/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для User
                partial: (id) => ({url:`api/account/user/${id}/activate/`, method:'PATCH'})
            },
            setting: {
                // Получение настроек юзера
                retrieve: (id) => ({url:`api/account/user/${id}/setting/`, method:'GET'})
            },
            settingPut: {
                // Обновление настроек юзера
                update: (id) => ({url:`api/account/user/${id}/setting_put/`, method:'PUT'})
            }
        }
    },
    auth: {
        accept: {
            data: {
                // Принятие инвайта, стадия 3
                create: () => ({url:`api/auth/accept/data/`, method:'POST'})
            },
            pincode: {
                // Принятие инвайта, стадия 2
                create: () => ({url:`api/auth/accept/pincode/`, method:'POST'})
            },
            start: {
                // Принятие инвайта, стадия 1
                create: () => ({url:`api/auth/accept/start/`, method:'POST'})
            }
        },
        customer: {
            // Аутентификация заказчика
            create: () => ({url:`api/auth/customer/`, method:'POST'})
        },
        customerRegister: {
            data: {
                // Регистрация заказчика, стадия 3
                create: () => ({url:`api/auth/customer_register/data/`, method:'POST'})
            },
            pincode: {
                // Регистрация заказчика, стадия 2
                create: () => ({url:`api/auth/customer_register/pincode/`, method:'POST'})
            },
            start: {
                // Регистрация заказчика, стадия 1
                create: () => ({url:`api/auth/customer_register/start/`, method:'POST'})
            }
        },
        hash: {
            // Авторизация по хешу
            create: () => ({url:`api/auth/hash/`, method:'POST'})
        },
        personal: {
            // Аутентификация пользователя
            create: () => ({url:`api/auth/personal/`, method:'POST'})
        },
        pincode: {
            pincode: {
                // Авторизация по пинкоду, стадия 2
                create: () => ({url:`api/auth/pincode/pincode/`, method:'POST'})
            },
            start: {
                // Авторизация по пинкоду, стадия 1
                create: () => ({url:`api/auth/pincode/start/`, method:'POST'})
            }
        },
        refresh: {
            // Перегенерация токена
            create: () => ({url:`api/auth/refresh/`, method:'POST'})
        },
        register: {
            data: {
                // Регистрация, стадия 3
                create: () => ({url:`api/auth/register/data/`, method:'POST'})
            },
            pincode: {
                // Регистрация, стадия 2
                create: () => ({url:`api/auth/register/pincode/`, method:'POST'})
            },
            start: {
                // Регистрация, стадия 1
                create: () => ({url:`api/auth/register/start/`, method:'POST'})
            }
        },
        restore: {
            pincode: {
                // Запрос восстановления пароля, стадия 2
                create: () => ({url:`api/auth/restore/pincode/`, method:'POST'})
            },
            start: {
                // Запрос восстановления пароля, стадия 1
                create: () => ({url:`api/auth/restore/start/`, method:'POST'})
            }
        }
    },
    core: {
        agentContract: {
            // Получение док-та договора вознаграждений
            retrieve: (id) => ({url:`api/core/agent_contract/${id}/`, method:'GET'}),
            // Обновление док-та договора вознаграждений
            update: (id) => ({url:`api/core/agent_contract/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/core/agent_contract/${id}/`, method:'PATCH'})
        },
        agentSalary: {
            // Получение списка док-тов начисления вознаграждений
            list: (params = {}) => ({url:`api/core/agent_salary/`, method:'GET', params}),
            // Получение док-та начисления вознаграждений
            retrieve: (id) => ({url:`api/core/agent_salary/${id}/`, method:'GET'})
        },
        agentSalaryAccrual: {
            // Получение списка док-тов начисления вознаграждений
            list: (params = {}) => ({url:`api/core/agent_salary_accrual/`, method:'GET', params}),
            // Создание док-та начисления вознаграждений
            create: () => ({url:`api/core/agent_salary_accrual/`, method:'POST'}),
            // Получение док-та начисления вознаграждений
            retrieve: (id) => ({url:`api/core/agent_salary_accrual/${id}/`, method:'GET'}),
            // Обновление док-та начисления вознаграждений
            update: (id) => ({url:`api/core/agent_salary_accrual/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/core/agent_salary_accrual/${id}/`, method:'PATCH'}),
            approve: {
                // Утверждение док-та начисления вознаграждений
                partial: (id) => ({url:`api/core/agent_salary_accrual/${id}/approve/`, method:'PATCH'})
            },
            remove: {
                // Удаление док-та начисления вознаграждений
                partial: (id) => ({url:`api/core/agent_salary_accrual/${id}/remove/`, method:'PATCH'})
            }
        },
        allPrices: {
            // Запрос цен (все букеры)
            put: () => ({url:`api/core/all_prices/`, method:'PUT'})
        },
        autocomplete: {
            // поиск
            put: () => ({url:`api/core/autocomplete/`, method:'PUT'})
        },
        availableSpace: {
            // Список свободных мест
            put: () => ({url:`api/core/available_space/`, method:'PUT'})
        },
        blackList: {
            list: (params = {}) => ({url:`api/core/black_list/`, method:'GET', params}),
            create: () => ({url:`api/core/black_list/`, method:'POST'}),
            check: {
                list: (ip, params = {}) => ({url:`api/core/black_list/check/${ip}/`, method:'GET', params})
            },
            createByMask: {
                create: () => ({url:`api/core/black_list/create_by_mask/`, method:'POST'})
            },
            retrieve: (id) => ({url:`api/core/black_list/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/core/black_list/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/core/black_list/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/core/black_list/${id}/`, method:'DELETE'}),
            disableIp: {
                partial: (id) => ({url:`api/core/black_list/${id}/disable_ip/`, method:'PATCH'})
            }
        },
        booker: {
            list: (params = {}) => ({url:`api/core/booker/`, method:'GET', params}),
            retrieve: (id) => ({url:`api/core/booker/${id}/`, method:'GET'})
        },
        booking: {
            list: (params = {}) => ({url:`api/core/booking/`, method:'GET', params}),
            // Создание брони (Booking)
            create: () => ({url:`api/core/booking/`, method:'POST'}),
            retrieve: (id) => ({url:`api/core/booking/${id}/`, method:'GET'}),
            // Изменение брони
            partial: (id) => ({url:`api/core/booking/${id}/`, method:'PATCH'}),
            addProduct: {
                // Создание Product для Booking
                post: (id) => ({url:`api/core/booking/${id}/add_product/`, method:'POST'})
            },
            booker: {
                // Установка API для брони
                partial: (id) => ({url:`api/core/booking/${id}/booker/`, method:'PATCH'})
            },
            cost: {
                // Расчет стоимости брони
                partial: (id) => ({url:`api/core/booking/${id}/cost/`, method:'PATCH'})
            }
        },
        buyer: {
            // Получение списка Buyer
            list: (params = {}) => ({url:`api/core/buyer/`, method:'GET', params}),
            // Создание Buyer
            create: () => ({url:`api/core/buyer/`, method:'POST'}),
            // Получение Buyer
            retrieve: (id) => ({url:`api/core/buyer/${id}/`, method:'GET'}),
            // Обновление Buyer
            update: (id) => ({url:`api/core/buyer/${id}/`, method:'PUT'}),
            // Удаление Buyer
            delete: (id) => ({url:`api/core/buyer/${id}/`, method:'DELETE'}),
            crLimit: {
                // Изменение cr_limit у Buyer
                partial: (id) => ({url:`api/core/buyer/${id}/cr_limit/`, method:'PATCH'})
            },
            person: {
                // Изменение person и(или) organization у Buyer
                partial: (id) => ({url:`api/core/buyer/${id}/person/`, method:'PATCH'})
            }
        },
        buyerContract: {
            // Получение списка BuyerContract
            list: (params = {}) => ({url:`api/core/buyer_contract/`, method:'GET', params}),
            // Создание BuyerContract
            create: () => ({url:`api/core/buyer_contract/`, method:'POST'}),
            // Получение BuyerContract
            retrieve: (id) => ({url:`api/core/buyer_contract/${id}/`, method:'GET'}),
            // Обновление BuyerContract
            update: (id) => ({url:`api/core/buyer_contract/${id}/`, method:'PUT'}),
            // Удаление BuyerContract
            delete: (id) => ({url:`api/core/buyer_contract/${id}/`, method:'DELETE'}),
            asText: {
                // Печать HTML
                retrieve: (id) => ({url:`api/core/buyer_contract/${id}/as_text/`, method:'GET'})
            },
            changeDeferredPayment: {
                // Изменить сумму отложенного платежа
                post: (id) => ({url:`api/core/buyer_contract/${id}/change_deferred_payment/`, method:'POST'})
            },
            createDeferredPayment: {
                // Создать отложенный платеж
                post: (id) => ({url:`api/core/buyer_contract/${id}/create_deferred_payment/`, method:'POST'})
            },
            deleteDeferredPayment: {
                // Удалить отложенный платеж
                post: (id) => ({url:`api/core/buyer_contract/${id}/delete_deferred_payment/`, method:'POST'})
            },
            get: {
                // Скачать документы
                update: (id) => ({url:`api/core/buyer_contract/${id}/get/`, method:'PUT'})
            },
            print: {
                // Печать документов
                update: (id) => ({url:`api/core/buyer_contract/${id}/print/`, method:'PUT'})
            },
            send: {
                // Выслать документы клиенту
                update: (id) => ({url:`api/core/buyer_contract/${id}/send/`, method:'PUT'})
            }
        },
        car: {
            // Получение списка машин
            list: (params = {}) => ({url:`api/core/car/`, method:'GET', params}),
            // Создание машины
            create: () => ({url:`api/core/car/`, method:'POST'}),
            // Получение машины
            retrieve: (id) => ({url:`api/core/car/${id}/`, method:'GET'}),
            // Обновление машины
            update: (id) => ({url:`api/core/car/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/core/car/${id}/`, method:'PATCH'}),
            // Удаление машины
            delete: (id) => ({url:`api/core/car/${id}/`, method:'DELETE'}),
            activate: {
                // Активация машины
                partial: (id) => ({url:`api/core/car/${id}/activate/`, method:'PATCH'})
            },
            deactivate: {
                // Деактивация машины
                partial: (id) => ({url:`api/core/car/${id}/deactivate/`, method:'PATCH'})
            }
        },
        clientBooking: {
            // Создание брони из виджета
            create: () => ({url:`api/core/client_booking/`, method:'POST'})
        },
        clientPhoneConfirmed: {
            // Проверка пин кода
            create: () => ({url:`api/core/client_phone_confirmed/`, method:'POST'})
        },
        comment: {
            // Создание комментария
            create: () => ({url:`api/core/comment/`, method:'POST'}),
            // Изменение комментария
            update: (id) => ({url:`api/core/comment/${id}/`, method:'PUT'}),
            // Удаление комментария
            delete: (id) => ({url:`api/core/comment/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для комментария
                partial: (id) => ({url:`api/core/comment/${id}/activate/`, method:'PATCH'})
            },
            listForObject: {
                // Получение списка комментариев для объекта
                list: (id, object_type, params = {}) => ({url:`api/core/comment/${object_type}/${id}/list/`, method:'GET', params})
            }
        },
        fms: {
            // Получение списка Fms (фмс карточка)
            list: (params = {}) => ({url:`api/core/fms/`, method:'GET', params}),
            // Создание Fms (фмс карточка)
            create: () => ({url:`api/core/fms/`, method:'POST'}),
            // Получение Fms (фмс карточка)
            retrieve: (id) => ({url:`api/core/fms/${id}/`, method:'GET'}),
            // Обновление Fms (фмс карточка)
            update: (id) => ({url:`api/core/fms/${id}/`, method:'PUT'}),
            // Удаление Fms (фмс карточка)
            delete: (id) => ({url:`api/core/fms/${id}/`, method:'DELETE'})
        },
        genericGet: {
            // Получение произвольного объекта
            retrieve: (data_type, id) => ({url:`api/core/generic_get/${data_type}/${id}/`, method:'GET'})
        },
        genericGetMultiple: {
            // Получение списка произвольных объектов
            put: () => ({url:`api/core/generic_get_multiple/`, method:'PUT'})
        },
        genericPrint: {
            // Получение печатной формы произвольного объекта (fmt "html" или "pdf")
            retrieve: (data_type, fmt, id) => ({url:`api/core/generic_print/${data_type}/${id}/${fmt}/`, method:'GET'})
        },
        genericTitle: {
            // Получение заголовка произвольного объекта
            retrieve: (data_type, id) => ({url:`api/core/generic_title/${data_type}/${id}/`, method:'GET'})
        },
        identityTask: {
            // Получение списка задач Identity
            list: (params = {}) => ({url:`api/core/identity_task/`, method:'GET', params}),
            // Получение задачи Identity
            retrieve: (id) => ({url:`api/core/identity_task/${id}/`, method:'GET'}),
            close: {
                // Закрыть задачу (параметры не нужны)
                partial: (id) => ({url:`api/core/identity_task/${id}/close/`, method:'PATCH'})
            }
        },
        lead: {
            // Получение списка Заявок
            list: (params = {}) => ({url:`api/core/lead/`, method:'GET', params}),
            // Создание Заявки
            create: () => ({url:`api/core/lead/`, method:'POST'}),
            fuckedUp: {
                // Проё***ные лиды
                list: (params = {}) => ({url:`api/core/lead/fucked_up/`, method:'GET', params})
            },
            take: {
                // Взять заявку в работу агентом
                post: () => ({url:`api/core/lead/take/`, method:'POST'})
            },
            // Получение Заявки
            retrieve: (id) => ({url:`api/core/lead/${id}/`, method:'GET'}),
            actionList: {
                // Список доступных действий для конкретной заявки
                retrieve: (id) => ({url:`api/core/lead/${id}/action_list/`, method:'GET'})
            },
            adminRestore: {
                // Передача админом лида из архива агенту
                update: (id) => ({url:`api/core/lead/${id}/admin_restore/`, method:'PUT'})
            },
            approve: {
                // Создать путевку
                update: (id) => ({url:`api/core/lead/${id}/approve/`, method:'PUT'})
            },
            archive: {
                // Отменить
                update: (id) => ({url:`api/core/lead/${id}/archive/`, method:'PUT'})
            },
            change: {
                // Обновление Заявки
                update: (id) => ({url:`api/core/lead/${id}/change/`, method:'PUT'})
            },
            handOver: {
                // Передать другому агенту
                update: (id) => ({url:`api/core/lead/${id}/hand_over/`, method:'PUT'})
            },
            hijack: {
                // Агент забирает заявку у другого агента
                update: (id) => ({url:`api/core/lead/${id}/hijack/`, method:'PUT'})
            },
            postpone: {
                // Отложить
                update: (id) => ({url:`api/core/lead/${id}/postpone/`, method:'PUT'})
            },
            restore: {
                // Восстановить
                update: (id) => ({url:`api/core/lead/${id}/restore/`, method:'PUT'})
            }
        },
        operation: {
            bookingArchive: {
                create: () => ({url:`api/core/operation/booking_archive/`, method:'POST'})
            },
            bookingAvailable: {
                // Получено подтверждение наличия свободных мест
                create: () => ({url:`api/core/operation/booking_available/`, method:'POST'})
            },
            bookingBook: {
                // Бронирование
                create: () => ({url:`api/core/operation/booking_book/`, method:'POST'})
            },
            bookingBookPaymentToObject: {
                // Бронирование, если у брони оплата в объект отдыха
                create: () => ({url:`api/core/operation/booking_book_payment_to_object/`, method:'POST'})
            },
            bookingCancel: {
                // Отмена брони
                create: () => ({url:`api/core/operation/booking_cancel/`, method:'POST'})
            },
            bookingInquireCreate: {
                // Создание запроса на бронирование
                create: () => ({url:`api/core/operation/booking_inquire_create/`, method:'POST'})
            },
            bookingInquireSend: {
                // Отправка запроса на бронирование
                create: () => ({url:`api/core/operation/booking_inquire_send/`, method:'POST'})
            },
            bookingInvoicePending: {
                // Ожидание счета для брони
                create: () => ({url:`api/core/operation/booking_invoice_pending/`, method:'POST'})
            },
            bookingPay: {
                // Оплата брони
                create: () => ({url:`api/core/operation/booking_pay/`, method:'POST'})
            },
            bookingRefuse: {
                // Не может быть забронировано
                create: () => ({url:`api/core/operation/booking_refuse/`, method:'POST'})
            },
            bookingRequestCreate: {
                // Создание запроса на наличие свободных мест
                create: () => ({url:`api/core/operation/booking_request_create/`, method:'POST'})
            },
            bookingRequestSend: {
                // Отправка запроса наличия свободных мест
                create: () => ({url:`api/core/operation/booking_request_send/`, method:'POST'})
            },
            bookingRestore: {
                // Восстановление брони
                create: () => ({url:`api/core/operation/booking_restore/`, method:'POST'})
            },
            bookingRevokeApproved: {
                // Отказ от брони утвержден
                create: () => ({url:`api/core/operation/booking_revoke_approved/`, method:'POST'})
            },
            bookingRevokeCreate: {
                // Создание запроса на отказ от брони
                create: () => ({url:`api/core/operation/booking_revoke_create/`, method:'POST'})
            },
            bookingRevokeSend: {
                // Отправка запроса на отказ от брони
                create: () => ({url:`api/core/operation/booking_revoke_send/`, method:'POST'})
            },
            bookingStart: {
                // Начало оказания услуг
                create: () => ({url:`api/core/operation/booking_start/`, method:'POST'})
            },
            bookingSwapBook: {
                // Изменение брони
                create: () => ({url:`api/core/operation/booking_swap_book/`, method:'POST'})
            },
            bookingSwapCreate: {
                // Создание запроса на изменение брони
                create: () => ({url:`api/core/operation/booking_swap_create/`, method:'POST'})
            },
            bookingSwapRefuse: {
                // Бронь не может быть изменена
                create: () => ({url:`api/core/operation/booking_swap_refuse/`, method:'POST'})
            },
            bookingSwapSend: {
                // Отправка запроса на изменение брони
                create: () => ({url:`api/core/operation/booking_swap_send/`, method:'POST'})
            },
            bookingToQuota: {
                // Перевести бронь в квоту
                create: () => ({url:`api/core/operation/booking_to_quota/`, method:'POST'})
            },
            bookingUnavailable: {
                // Получен отказ наличия свободных мест
                create: () => ({url:`api/core/operation/booking_unavailable/`, method:'POST'})
            },
            getBookingCost: {
                // Расчет стоимости брони
                create: () => ({url:`api/core/operation/get_booking_cost/`, method:'POST'})
            },
            tourAgree: {
                // Операция согласования тура
                create: () => ({url:`api/core/operation/tour_agree/`, method:'POST'})
            },
            tourAnnul: {
                // Операция аннуляции тура
                create: () => ({url:`api/core/operation/tour_annul/`, method:'POST'})
            },
            tourCancel: {
                // Операция отмены тура
                create: () => ({url:`api/core/operation/tour_cancel/`, method:'POST'})
            },
            tourCancelCheckinPayment: {
                // Отмена отсроченного платежа по номеру договора
                create: () => ({url:`api/core/operation/tour_cancel_checkin_payment/`, method:'POST'})
            },
            tourClose: {
                // Операция закрытия тура
                create: () => ({url:`api/core/operation/tour_close/`, method:'POST'})
            },
            tourFinish: {
                // Операция завершения тура
                create: () => ({url:`api/core/operation/tour_finish/`, method:'POST'})
            },
            tourReceiveCheckinPayment: {
                // Прием отсроченного платежа по номеру договора
                create: () => ({url:`api/core/operation/tour_receive_checkin_payment/`, method:'POST'})
            },
            tourResetCorrections: {
                // Сброс корректировок и скидок
                create: () => ({url:`api/core/operation/tour_reset_corrections/`, method:'POST'})
            },
            tourRevoke: {
                // Операция отказа от тура
                create: () => ({url:`api/core/operation/tour_revoke/`, method:'POST'})
            },
            tourSetCorrections: {
                // Установка корректировок и скидок
                create: () => ({url:`api/core/operation/tour_set_corrections/`, method:'POST'})
            },
            tourSetDiscountPercent: {
                // Установка одинаковой скидки
                create: () => ({url:`api/core/operation/tour_set_discount_percent/`, method:'POST'})
            },
            tourStart: {
                // Операция запуска тура в работу
                create: () => ({url:`api/core/operation/tour_start/`, method:'POST'})
            },
            transferAccept: {
                // Принять трансфер к исполнению
                create: () => ({url:`api/core/operation/transfer_accept/`, method:'POST'})
            },
            transferAgree: {
                // Согласование бронирования трансфера
                create: () => ({url:`api/core/operation/transfer_agree/`, method:'POST'})
            },
            transferAppoint: {
                // Назначение водителя с машиной на бронирование трансфера
                create: () => ({url:`api/core/operation/transfer_appoint/`, method:'POST'})
            },
            transferCancel: {
                // Отменить трансфер
                create: () => ({url:`api/core/operation/transfer_cancel/`, method:'POST'})
            },
            transferClose: {
                // Закрытие бронирования трансфера
                create: () => ({url:`api/core/operation/transfer_close/`, method:'POST'})
            },
            transferConfirm: {
                // Подтверждение бронирования трансфера
                create: () => ({url:`api/core/operation/transfer_confirm/`, method:'POST'})
            },
            transferDone: {
                // Завершение бронирования трансфера
                create: () => ({url:`api/core/operation/transfer_done/`, method:'POST'})
            },
            transferReject: {
                // Отклонение бронирования трансфера
                create: () => ({url:`api/core/operation/transfer_reject/`, method:'POST'})
            },
            transferRequest: {
                // Выслать запрос трансфера
                create: () => ({url:`api/core/operation/transfer_request/`, method:'POST'})
            },
            transferSendToCustomer: {
                // Отправить трансфер заказчику и туристам
                create: () => ({url:`api/core/operation/transfer_send_to_customer/`, method:'POST'})
            },
            transferSendToDriver: {
                // Отправить трансфер водителю
                create: () => ({url:`api/core/operation/transfer_send_to_driver/`, method:'POST'})
            },
            transferSetInstructions: {
                // Инструкции для водителя трансфера
                create: () => ({url:`api/core/operation/transfer_set_instructions/`, method:'POST'})
            },
            transferUnappoint: {
                // Отмена назначения водителя с машиной на бронирование трансфера
                create: () => ({url:`api/core/operation/transfer_unappoint/`, method:'POST'})
            }
        },
        organization: {
            // Получение списка Organization
            list: (params = {}) => ({url:`api/core/organization/`, method:'GET', params}),
            // Создание Organization
            create: () => ({url:`api/core/organization/`, method:'POST'}),
            // Получение Organization
            retrieve: (id) => ({url:`api/core/organization/${id}/`, method:'GET'}),
            // Обновление Organization
            update: (id) => ({url:`api/core/organization/${id}/`, method:'PUT'}),
            // Удаление Organization
            delete: (id) => ({url:`api/core/organization/${id}/`, method:'DELETE'})
        },
        person: {
            // Получение списка Person
            list: (params = {}) => ({url:`api/core/person/`, method:'GET', params}),
            // Создание Person
            create: () => ({url:`api/core/person/`, method:'POST'}),
            // Получение Person
            retrieve: (id) => ({url:`api/core/person/${id}/`, method:'GET'}),
            // Обновление Person
            update: (id) => ({url:`api/core/person/${id}/`, method:'PUT'}),
            // Удаление Person
            delete: (id) => ({url:`api/core/person/${id}/`, method:'DELETE'})
        },
        prices: {
            // Запрос цен
            put: () => ({url:`api/core/prices/`, method:'PUT'})
        },
        product: {
            // Получение Product по id
            retrieve: (id) => ({url:`api/core/product/${id}/`, method:'GET'}),
            // Обновление Product
            update: (id) => ({url:`api/core/product/${id}/`, method:'PUT'}),
            // Удаление Product
            delete: (id) => ({url:`api/core/product/${id}/`, method:'DELETE'})
        },
        quota: {
            // Список квот (брони, которые являются квотами)
            list: (params = {}) => ({url:`api/core/quota/`, method:'GET', params})
        },
        reminder: {
            // Получение списка напоминаний для того, кому они предназначены (своих)
            list: (params = {}) => ({url:`api/core/reminder/`, method:'GET', params}),
            // Создание напоминания
            create: () => ({url:`api/core/reminder/`, method:'POST'}),
            all: {
                // Получение списка всех напоминаний
                list: (params = {}) => ({url:`api/core/reminder/all/`, method:'GET', params})
            },
            // Получение напоминания
            retrieve: (id) => ({url:`api/core/reminder/${id}/`, method:'GET'}),
            // Изменение напоминания
            partial: (id) => ({url:`api/core/reminder/${id}/`, method:'PATCH'}),
            // Закрыть напоминание
            delete: (id) => ({url:`api/core/reminder/${id}/`, method:'DELETE'})
        },
        search: {
            // Поиск туров по переданным параметрам
            list: (params = {}) => ({url:`api/core/search/`, method:'GET', params})
        },
        searchBuyers: {
            // Поиск Buyer и Identity customer по переданным параметрам
            put: () => ({url:`api/core/search_buyers/`, method:'PUT'})
        },
        tag: {
            // Создание/удаление тэгов
            create: () => ({url:`api/core/tag/`, method:'POST'}),
            forObject: {
                // Получение тэгов
                list: (object_id, object_type, params = {}) => ({url:`api/core/tag/${object_type}/${object_id}/`, method:'GET', params})
            }
        },
        tour: {
            // Получение списка туров
            list: (params = {}) => ({url:`api/core/tour/`, method:'GET', params}),
            // Создание тура (Tour)
            create: () => ({url:`api/core/tour/`, method:'POST'}),
            // Получение конкретного тура по id
            retrieve: (id) => ({url:`api/core/tour/${id}/`, method:'GET'}),
            addAgent: {
                // Передача тура другому агенту
                partial: (id) => ({url:`api/core/tour/${id}/add_agent/`, method:'PATCH'})
            },
            addPerson: {
                // Добавление персоны (Person)
                partial: (id) => ({url:`api/core/tour/${id}/add_person/`, method:'PATCH'})
            },
            booking: {
                // Получение списка booking по id тура
                list: (id, params = {}) => ({url:`api/core/tour/${id}/booking/`, method:'GET', params})
            },
            setAgencyEntity: {
                // Изменение юрлица агенства в туре (legal.Entity)
                partial: (id) => ({url:`api/core/tour/${id}/set_agency_entity/`, method:'PATCH'})
            },
            setBankAccount: {
                // Изменение счета в туре (legal.BankAccount)
                partial: (id) => ({url:`api/core/tour/${id}/set_bank_account/`, method:'PATCH'})
            },
            setPercentage: {
                // Установка % вознаграждения агентам
                partial: (id) => ({url:`api/core/tour/${id}/set_percentage/`, method:'PATCH'})
            },
            setSystemEntity: {
                // Изменение юридического лица системы в туре (legal.Entity)
                partial: (id) => ({url:`api/core/tour/${id}/set_system_entity/`, method:'PATCH'})
            }
        },
        transferBooking: {
            // Получение списка бронирований трансфера
            list: (params = {}) => ({url:`api/core/transfer_booking/`, method:'GET', params}),
            // Создание бронирования трансфера
            create: () => ({url:`api/core/transfer_booking/`, method:'POST'}),
            // Получение бронирования трансфера
            retrieve: (id) => ({url:`api/core/transfer_booking/${id}/`, method:'GET'}),
            // Обновление бронирования трансфера
            update: (id) => ({url:`api/core/transfer_booking/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/core/transfer_booking/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/core/transfer_booking/${id}/`, method:'DELETE'}),
            addProduct: {
                // добавление продукта к бронированию трансфера
                update: (id) => ({url:`api/core/transfer_booking/${id}/add_product/`, method:'PUT'})
            },
            print: {
                // Распечатать бронирование трансфера
                partial: (id) => ({url:`api/core/transfer_booking/${id}/print/`, method:'PATCH'})
            }
        },
        transferService: {
            // Получение списка услуг трансфера
            list: (params = {}) => ({url:`api/core/transfer_service/`, method:'GET', params}),
            // Создание услуги трансфера
            create: () => ({url:`api/core/transfer_service/`, method:'POST'}),
            // Получение услуги трансфера
            retrieve: (id) => ({url:`api/core/transfer_service/${id}/`, method:'GET'}),
            // Обновление услуги трансфера
            update: (id) => ({url:`api/core/transfer_service/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/core/transfer_service/${id}/`, method:'PATCH'}),
            // Удаление услуги трансфера
            delete: (id) => ({url:`api/core/transfer_service/${id}/`, method:'DELETE'}),
            activate: {
                // Активация услуги трансфера
                partial: (id) => ({url:`api/core/transfer_service/${id}/activate/`, method:'PATCH'})
            },
            deactivate: {
                // Деактивация услуги трансфера
                partial: (id) => ({url:`api/core/transfer_service/${id}/deactivate/`, method:'PATCH'})
            }
        },
        types: {
            // Получение списка content types
            list: (params = {}) => ({url:`api/core/types/`, method:'GET', params})
        },
        widget: {
            // Получение списка виджетов
            list: (params = {}) => ({url:`api/core/widget/`, method:'GET', params}),
            // Создание виджета
            create: () => ({url:`api/core/widget/`, method:'POST'}),
            // Получение виджета
            retrieve: (id) => ({url:`api/core/widget/${id}/`, method:'GET'}),
            // Изменение виджета
            update: (id) => ({url:`api/core/widget/${id}/`, method:'PUT'})
        }
    },
    docs: {
        article: {
            // Список Article
            list: (params = {}) => ({url:`api/docs/article/`, method:'GET', params}),
            handle: {
                handle: {
                    // Получение Article по handle
                    list: (handle) => ({url:`api/docs/article/handle/${handle}/`, method:'GET'})
                }
            },
            // Получение Article по id
            retrieve: (id) => ({url:`api/docs/article/${id}/`, method:'GET'})
        },
        search: {
            // Поиск Article
            put: () => ({url:`api/docs/search/`, method:'PUT'})
        }
    },
    finance: {
        agency: {
            balance: {
                buyer: {
                    buyerId: {
                        latest: {
                            // Получение текущих балансов покупателя
                            list: (buyer_id, params = {}) => ({url:`api/finance/agency/balance/buyer/${buyer_id}/latest/`, method:'GET', params})
                        },
                        date: {
                            // Получение текущих балансов покупателя на дату
                            list: (buyer_id, date, params = {}) => ({url:`api/finance/agency/balance/buyer/${buyer_id}/${date}/`, method:'GET', params})
                        }
                    }
                },
                latest: {
                    // Получение текущих балансов всех покупателей
                    list: (params = {}) => ({url:`api/finance/agency/balance/latest/`, method:'GET', params})
                },
                date: {
                    // Получение текущих балансов всех покупателей на дату
                    list: (date, params = {}) => ({url:`api/finance/agency/balance/${date}/`, method:'GET', params})
                }
            },
            entry: {
                // Получение списка проводок агентства
                list: (params = {}) => ({url:`api/finance/agency/entry/`, method:'GET', params}),
                create: {
                    // Создание ручной проводки агентства
                    create: () => ({url:`api/finance/agency/entry/create/`, method:'POST'})
                },
                // Получение конкретной проводки агентства
                retrieve: (id) => ({url:`api/finance/agency/entry/${id}/`, method:'GET'})
            }
        },
        balance: {
            // Получение текущих балансов всех контрагентов системы
            list: (params = {}) => ({url:`api/finance/balance/`, method:'GET', params})
        },
        balanceForDate: {
            date: {
                // Получение балансов всех контрагентов системы на дату
                list: (date, params = {}) => ({url:`api/finance/balance_for_date/${date}/`, method:'GET', params})
            }
        },
        contractorBalance: {
            contractorEntityId: {
                // Получение текущих балансов контрагента системы
                list: (contractor_entity_id, params = {}) => ({url:`api/finance/contractor_balance/${contractor_entity_id}/`, method:'GET', params})
            }
        },
        contractorBalanceForDate: {
            contractorEntityId: {
                date: {
                    // Получение балансов контрагента системы на дату
                    list: (contractor_entity_id, date, params = {}) => ({url:`api/finance/contractor_balance_for_date/${contractor_entity_id}/${date}/`, method:'GET', params})
                }
            }
        },
        buyerPayment: {
            listSplit: {
                // Получение списка разнесений для конкретного платежа покупателя
                list: (id, params = {}) => ({url:`api/legal/buyer_payment/${id}/split/`, method:'GET', params})
            }
        },
        entity: {
            buyerPayments: {
                // Получение списка платежей покупателей для юр. лица агентства
                list: (id, params = {}) => ({url:`api/legal/entity/${id}/buyer_payments/`, method:'GET', params})
            }
        },
        payment: {
            listSplit: {
                // Получение списка разнесений для конкретного платежа
                list: (id, params = {}) => ({url:`api/legal/payment/${id}/split/`, method:'GET', params})
            }
        }
    },
    hms: {
        flag: {
            // Удаление RoomFlag
            delete: (id) => ({url:`api/hms/flag/${id}/`, method:'DELETE'})
        },
        guest: {
            // Обновление Guest
            update: (id) => ({url:`api/hms/guest/${id}/`, method:'PUT'}),
            // Удаление Guest
            delete: (id) => ({url:`api/hms/guest/${id}/`, method:'DELETE'})
        },
        hotel: {
            cross: {
                availability: {
                    // Данные для шахматки по доступности для текущего периода (не реализовано)
                    retrieve: (id) => ({url:`api/hms/hotel/${id}/cross/availability/`, method:'GET'}),
                    // Данные для шахматки по доступности для заданного периода (не реализовано)
                    update: (id) => ({url:`api/hms/hotel/${id}/cross/availability/`, method:'PUT'})
                },
                prices: {
                    // Данные для шахматки по ценам для текущего периода (не реализовано)
                    retrieve: (id) => ({url:`api/hms/hotel/${id}/cross/prices/`, method:'GET'}),
                    // Данные для шахматки по ценам для заданного периода (не реализовано)
                    update: (id) => ({url:`api/hms/hotel/${id}/cross/prices/`, method:'PUT'})
                },
                rooms: {
                    // Данные для шахматки по номерам для текущего периода
                    retrieve: (id) => ({url:`api/hms/hotel/${id}/cross/rooms/`, method:'GET'}),
                    // Данные для шахматки по номерам для заданного периода
                    update: (id) => ({url:`api/hms/hotel/${id}/cross/rooms/`, method:'PUT'})
                }
            },
            occupation: {
                // Получение списка Occupation для отеля
                retrieve: (id, params = {}) => ({url:`api/hms/hotel/${id}/occupation/`, method:'GET', params})
            },
            period: {
                // Получение списка периодов для отеля
                retrieve: (id, params = {}) => ({url:`api/hms/hotel/${id}/period/`, method:'GET', params}),
                // Создание периода для отеля
                post: (id) => ({url:`api/hms/hotel/${id}/period/`, method:'POST'})
            },
            reservation: {
                // Получение списка Reservation для отеля
                retrieve: (id, params = {}) => ({url:`api/hms/hotel/${id}/reservation/`, method:'GET', params})
            }
        },
        occupation: {
            // Создание Occupation
            create: () => ({url:`api/hms/occupation/`, method:'POST'}),
            // Получение Occupation
            retrieve: (id) => ({url:`api/hms/occupation/${id}/`, method:'GET'}),
            // Обновление модели Occupation
            update: (id) => ({url:`api/hms/occupation/${id}/`, method:'PUT'}),
            // Обновление полей Occupation
            partial: (id) => ({url:`api/hms/occupation/${id}/`, method:'PATCH'}),
            // Удаление Occupation, только для статуса Draft
            delete: (id) => ({url:`api/hms/occupation/${id}/`, method:'DELETE'}),
            addResident: {
                // Создание Resident для occupation
                post: (id) => ({url:`api/hms/occupation/${id}/add_resident/`, method:'POST'})
            },
            cancel: {
                // Отмена. Переводит в статус CANCELED
                partial: (id) => ({url:`api/hms/occupation/${id}/cancel/`, method:'PATCH'})
            },
            checkIn: {
                // Переводит в статус CHECK_IN
                partial: (id) => ({url:`api/hms/occupation/${id}/check_in/`, method:'PATCH'})
            },
            checkOut: {
                // Переводит в статус CHECK_OUT
                partial: (id) => ({url:`api/hms/occupation/${id}/check_out/`, method:'PATCH'})
            },
            move: {
                // Переезд в другой номер
                partial: (id) => ({url:`api/hms/occupation/${id}/move/`, method:'PATCH'})
            },
            prolong: {
                // Продление. Меняет плановое время, если есть свободные места
                partial: (id) => ({url:`api/hms/occupation/${id}/prolong/`, method:'PATCH'})
            }
        },
        period: {
            copyPeriodWithPrices: {
                // Копирование периода. А так же цен с этим объектом связанных
                create: () => ({url:`api/hms/period/copy_period_with_prices/`, method:'POST'})
            },
            // Получение периода отеля
            retrieve: (id) => ({url:`api/hms/period/${id}/`, method:'GET'}),
            // Обновление дат периода отеля
            update: (id) => ({url:`api/hms/period/${id}/`, method:'PUT'}),
            // Удаление периода отеля
            delete: (id) => ({url:`api/hms/period/${id}/`, method:'DELETE'}),
            activate: {
                // Обновление поля is_active периода (Period)
                partial: (id) => ({url:`api/hms/period/${id}/activate/`, method:'PATCH'})
            }
        },
        price: {
            // Получение списка Price
            list: (params = {}) => ({url:`api/hms/price/`, method:'GET', params}),
            // Создание Price
            create: () => ({url:`api/hms/price/`, method:'POST'}),
            copyPrices: {
                // Размножение цен (на основе категории, либо программы пребывания, либо периода).
                create: () => ({url:`api/hms/price/copy_prices/`, method:'POST'})
            },
            createEmptyPrices: {
                // Создание отсутствующих price
                create: () => ({url:`api/hms/price/create_empty_prices/`, method:'POST'})
            },
            extraAction: {
                // вкл/выкл, обнулить, удалить Price
                put: () => ({url:`api/hms/price/extra_action/`, method:'PUT'})
            },
            // Получение Price
            retrieve: (id) => ({url:`api/hms/price/${id}/`, method:'GET'}),
            // Обновление модели Price
            update: (id) => ({url:`api/hms/price/${id}/`, method:'PUT'}),
            // Обновление поля prices
            partial: (id) => ({url:`api/hms/price/${id}/`, method:'PATCH'}),
            // Удаление Price
            delete: (id) => ({url:`api/hms/price/${id}/`, method:'DELETE'})
        },
        programPrice: {
            // Получение списка цен программ пребывания
            list: (params = {}) => ({url:`api/hms/program_price/`, method:'GET', params}),
            // Создание цен программ пребывания
            create: () => ({url:`api/hms/program_price/`, method:'POST'}),
            // Получение цен программ пребывания
            retrieve: (id) => ({url:`api/hms/program_price/${id}/`, method:'GET'}),
            // Обновление модели цен программ пребывания
            update: (id) => ({url:`api/hms/program_price/${id}/`, method:'PUT'}),
            // Обновление поля prices цен программ пребывания
            partial: (id) => ({url:`api/hms/program_price/${id}/`, method:'PATCH'}),
            // Удаление Price
            delete: (id) => ({url:`api/hms/program_price/${id}/`, method:'DELETE'})
        },
        query: {
            // Получение списка Query
            list: (params = {}) => ({url:`api/hms/query/`, method:'GET', params}),
            // Создание Query
            create: () => ({url:`api/hms/query/`, method:'POST'}),
            // Получение Query
            retrieve: (id) => ({url:`api/hms/query/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/hms/query/${id}/`, method:'PUT'}),
            // Поменять текст запроса
            partial: (id) => ({url:`api/hms/query/${id}/`, method:'PATCH'}),
            // перевод в CANCELLED
            delete: (id) => ({url:`api/hms/query/${id}/`, method:'DELETE'}),
            send: {
                // Отправить повторно
                update: (id) => ({url:`api/hms/query/${id}/send/`, method:'PUT'})
            }
        },
        reservation: {
            // Создание Reservation
            create: () => ({url:`api/hms/reservation/`, method:'POST'}),
            // Получение Reservation
            retrieve: (id) => ({url:`api/hms/reservation/${id}/`, method:'GET'}),
            // Обновление модели Reservation
            update: (id) => ({url:`api/hms/reservation/${id}/`, method:'PUT'}),
            // Обновление полей Reservation
            partial: (id) => ({url:`api/hms/reservation/${id}/`, method:'PATCH'}),
            // Удаление Reservation, только для статуса Draft
            delete: (id) => ({url:`api/hms/reservation/${id}/`, method:'DELETE'}),
            addGuest: {
                // Создание Guest для reservation
                post: (id) => ({url:`api/hms/reservation/${id}/add_guest/`, method:'POST'})
            },
            book: {
                // Запрос на бронирование
                partial: (id) => ({url:`api/hms/reservation/${id}/book/`, method:'PATCH'})
            },
            cancel: {
                // Переводит в статус CANCELLED. Для любого статуса, кроме DRAFT
                partial: (id) => ({url:`api/hms/reservation/${id}/cancel/`, method:'PATCH'})
            },
            confirm: {
                // Переводит статус ON_REQUEST в BOOKED
                partial: (id) => ({url:`api/hms/reservation/${id}/confirm/`, method:'PATCH'})
            }
        },
        resident: {
            // Обновление Resident
            update: (id) => ({url:`api/hms/resident/${id}/`, method:'PUT'}),
            // Удаление Resident
            delete: (id) => ({url:`api/hms/resident/${id}/`, method:'DELETE'})
        },
        room: {
            // Получение списка Room
            list: (params = {}) => ({url:`api/hms/room/`, method:'GET', params}),
            // Создание Room
            create: () => ({url:`api/hms/room/`, method:'POST'}),
            // Получение Room
            retrieve: (id) => ({url:`api/hms/room/${id}/`, method:'GET'}),
            // Обновление модели Room
            update: (id) => ({url:`api/hms/room/${id}/`, method:'PUT'}),
            flag: {
                // Создание RoomFlag
                post: (id) => ({url:`api/hms/room/${id}/flag/`, method:'POST'})
            }
        }
    },
    integrations: {
        booker: {
            prices: {
                // Список цен из базы
                put: () => ({url:`api/integrations/booker/prices/`, method:'PUT'})
            },
            booker: {
                attach: {
                    // Связывает объект системы бронирования с объектом НСБ
                    create: (booker) => ({url:`api/integrations/booker/${booker}/attach/`, method:'POST'})
                },
                detach: {
                    // Отвязывает объект системы бронирования от объекта НСБ
                    create: (booker) => ({url:`api/integrations/booker/${booker}/detach/`, method:'POST'})
                },
                detached: {
                    list: (booker, params = {}) => ({url:`api/integrations/booker/${booker}/detached/`, method:'GET', params})
                },
                newAndChanged: {
                    // Возвращает список несвязанных объектов - флаг "new"
                    list: (booker, params = {}) => ({url:`api/integrations/booker/${booker}/new_and_changed/`, method:'GET', params})
                },
                synchronise: {
                    // Синхронизирует объект системы бронирования с объектом НСБ
                    create: (booker) => ({url:`api/integrations/booker/${booker}/synchronise/`, method:'POST'})
                },
                bookingId: {
                    booking: {
                        // Выполняет бронирование
                        create: (booker, booking_id) => ({url:`api/integrations/booker/${booker}/${booking_id}/booking/`, method:'POST'})
                    }
                },
                hotelId: {
                    // Выдает стоимость бронирования
                    put: (booker, hotel_id) => ({url:`api/integrations/booker/${booker}/${hotel_id}/`, method:'PUT'}),
                    bookingPrice: {
                        // Выдает стоимость бронирования
                        put: (booker, hotel_id) => ({url:`api/integrations/booker/${booker}/${hotel_id}/booking_price/`, method:'PUT'})
                    },
                    places: {
                        // Список свободных мест из базы
                        put: (booker, hotel_id) => ({url:`api/integrations/booker/${booker}/${hotel_id}/places/`, method:'PUT'})
                    }
                },
                objType: {
                    objId: {
                        info: {
                            // Возвращает созданный но не сохраненный объект(отель, категория, программа)
                            list: (booker, obj_id, obj_type, params = {}) => ({url:`api/integrations/booker/${booker}/${obj_type}/${obj_id}/info/`, method:'GET', params})
                        }
                    }
                }
            },
            bookers: {
                // Букеры доступные для отеля
                list: (hotel_id, params = {}) => ({url:`api/integrations/booker/${hotel_id}/bookers/`, method:'GET', params})
            }
        },
        bookers: {
            // Список доступных букеров
            list: (params = {}) => ({url:`api/integrations/bookers/`, method:'GET', params})
        },
        oneC: {
            agencyInvoice: {
                // list за 31 последний день (для системы и для агентства)
                list: () => ({url:`api/integrations/one_c/agency_invoice/`, method:'GET'}),
                range: {
                    // выборка по временному диапазону (для системы и для агентства)
                    list: (date_from, date_to) => ({url:`api/integrations/one_c/agency_invoice/${date_from}/${date_to}/`, method:'GET'})
                },
                // получение записи по ID (для системы и для агентства)
                retrieve: (id) => ({url:`api/integrations/one_c/agency_invoice/${id}/`, method:'GET'})
            },
            agencyInvoiceWithFilters: {
                // для экспорта "копии экрана" (для системы и для агентства)
                list: (params = {}) => ({url:`api/integrations/one_c/agency_invoice_with_filters/`, method:'GET', params})
            },
            buyerContract: {
                // list за 31 последний день  (для агентства)
                list: () => ({url:`api/integrations/one_c/buyer_contract/`, method:'GET'}),
                range: {
                    // выборка по временному диапазону (для агентства)
                    list: (date_from, date_to) => ({url:`api/integrations/one_c/buyer_contract/${date_from}/${date_to}/`, method:'GET'})
                },
                // получение записи по ID (для агентства)
                retrieve: (id) => ({url:`api/integrations/one_c/buyer_contract/${id}/`, method:'GET'})
            },
            commissionerReport: {
                // список отчетов комиссионера (только для системы)
                list: () => ({url:`api/integrations/one_c/commissioner_report/`, method:'GET'}),
                range: {
                    // выборка отчетов комиссионера по временному диапазону (для системы)
                    list: (date_from, date_to) => ({url:`api/integrations/one_c/commissioner_report/${date_from}/${date_to}/`, method:'GET'})
                },
                // получение отчета комиссионера по ID (только для системы)
                retrieve: (id) => ({url:`api/integrations/one_c/commissioner_report/${id}/`, method:'GET'})
            },
            commissionerReportWithFilters: {
                // для экспорта отчетов комиссионера "копии экрана" (для системы и для агентства)
                list: (params = {}) => ({url:`api/integrations/one_c/commissioner_report_with_filters/`, method:'GET', params})
            },
            paymentOrder: {
                // list за 31 последний день  (для системы и для агентства)
                list: () => ({url:`api/integrations/one_c/payment_order/`, method:'GET'}),
                range: {
                    // выборка по временному диапазону (для системы и для агентства)
                    list: (date_from, date_to) => ({url:`api/integrations/one_c/payment_order/${date_from}/${date_to}/`, method:'GET'})
                },
                // получение записи по ID (для системы и для агентства)
                retrieve: (id) => ({url:`api/integrations/one_c/payment_order/${id}/`, method:'GET'})
            },
            paymentOrderWithFilters: {
                // для экспорта "копии экрана" (для системы и для агентства)
                list: (params = {}) => ({url:`api/integrations/one_c/payment_order_with_filters/`, method:'GET', params})
            },
            supplierInvoice: {
                // list за 31 последний день (только для системы)
                list: () => ({url:`api/integrations/one_c/supplier_invoice/`, method:'GET'}),
                range: {
                    // выборка по временному диапазону (только для системы)
                    list: (date_from, date_to) => ({url:`api/integrations/one_c/supplier_invoice/${date_from}/${date_to}/`, method:'GET'})
                },
                // получение записи по ID (только для системы)
                retrieve: (id) => ({url:`api/integrations/one_c/supplier_invoice/${id}/`, method:'GET'})
            },
            sync: {
                objectType: {
                    objectId: {
                        // синхронизация объекта системы и 1с - связать `data_type+id` и `uuid`
                        create: (object_id, object_type) => ({url:`api/integrations/one_c/sync/${object_type}/${object_id}/`, method:'POST'}),
                        // отмена синхронизации  объекта системы и 1с
                        delete: (object_id, object_type) => ({url:`api/integrations/one_c/sync/${object_type}/${object_id}/`, method:'DELETE'})
                    }
                }
            }
        },
        sberQr: {
            qrcode: {
                list: (params = {}) => ({url:`api/integrations/sber_qr/qrcode/`, method:'GET', params}),
                create: () => ({url:`api/integrations/sber_qr/qrcode/`, method:'POST'})
            }
        }
    },
    legal: {
        agencyInvoice: {
            // Получение списка AgencyInvoice (счет системы в агентство)
            list: (params = {}) => ({url:`api/legal/agency_invoice/`, method:'GET', params}),
            // Получение модели AgencyInvoice (счет системы в агентство)
            retrieve: (id) => ({url:`api/legal/agency_invoice/${id}/`, method:'GET'}),
            offsets: {
                // Получение зачётов оплат для счёта агентству
                retrieve: (id) => ({url:`api/legal/agency_invoice/${id}/offsets/`, method:'GET'})
            }
        },
        bankAccount: {
            // Получение списка BankAccount
            list: (params = {}) => ({url:`api/legal/bank_account/`, method:'GET', params}),
            // Создание BankAccount
            create: () => ({url:`api/legal/bank_account/`, method:'POST'}),
            // Получение модели BankAccount
            retrieve: (id) => ({url:`api/legal/bank_account/${id}/`, method:'GET'}),
            partial: (id) => ({url:`api/legal/bank_account/${id}/`, method:'PATCH'}),
            // Удаление BankAccount
            delete: (id) => ({url:`api/legal/bank_account/${id}/`, method:'DELETE'}),
            setMain: {
                // Сделать счёт основным для юр. лица
                partial: (id) => ({url:`api/legal/bank_account/${id}/set_main/`, method:'PATCH'})
            }
        },
        buyerPayment: {
            create: {
                // Создание платежа покупателя
                create: () => ({url:`api/legal/buyer_payment/create/`, method:'POST'})
            },
            import: {
                // Создание импорта платежей покупателей
                create: () => ({url:`api/legal/buyer_payment/import/`, method:'POST'}),
                accept: {
                    // Принятие платежей покупателей
                    partial: (id) => ({url:`api/legal/buyer_payment/import/${id}/accept/`, method:'PATCH'})
                },
                process: {
                    // Импорт платежей покупателей
                    partial: (id) => ({url:`api/legal/buyer_payment/import/${id}/process/`, method:'PATCH'})
                }
            },
            split: {
                // Создание разнесения платежа покупателя
                create: () => ({url:`api/legal/buyer_payment/split/`, method:'POST'}),
                // Получение конкретного разнесения платежа покупателя
                retrieve: (id) => ({url:`api/legal/buyer_payment/split/${id}/`, method:'GET'}),
                delete: {
                    // Удаление разнесения платежа покупателя
                    delete: (id) => ({url:`api/legal/buyer_payment/split/${id}/delete/`, method:'DELETE'})
                },
                update: {
                    // Обновление разнесения платежа покупателя
                    partial: (id) => ({url:`api/legal/buyer_payment/split/${id}/update/`, method:'PATCH'})
                }
            },
            // Получение конкретного платежа покупателя
            retrieve: (id) => ({url:`api/legal/buyer_payment/${id}/`, method:'GET'}),
            accept: {
                // Принять платеж покупателя
                partial: (id) => ({url:`api/legal/buyer_payment/${id}/accept/`, method:'PATCH'})
            },
            annul: {
                // Аннулировать платеж покупателя
                partial: (id) => ({url:`api/legal/buyer_payment/${id}/annul/`, method:'PATCH'})
            },
            autoSplit: {
                // Разнести платеж покупателя по номеру счёта
                partial: (id) => ({url:`api/legal/buyer_payment/${id}/auto_split/`, method:'PATCH'})
            },
            cancel: {
                // Отменить платеж покупателя
                partial: (id) => ({url:`api/legal/buyer_payment/${id}/cancel/`, method:'PATCH'})
            },
            hold: {
                // Провести платеж покупателя
                partial: (id) => ({url:`api/legal/buyer_payment/${id}/hold/`, method:'PATCH'})
            },
            restore: {
                // Восстановить платеж покупателя
                partial: (id) => ({url:`api/legal/buyer_payment/${id}/restore/`, method:'PATCH'})
            },
            setBuyer: {
                // Установить покупателя для платежа
                partial: (id) => ({url:`api/legal/buyer_payment/${id}/set_buyer/`, method:'PATCH'})
            }
        },
        comissionerReportRow: {
            // Получение строки отчета комиссионера
            retrieve: (id) => ({url:`api/legal/comissioner_report_row/${id}/`, method:'GET'})
        },
        commission: {
            // Получить список активных планов комиссий
            list: (params = {}) => ({url:`api/legal/commission/`, method:'GET', params}),
            // Создать план комиссий
            create: () => ({url:`api/legal/commission/`, method:'POST'}),
            all: {
                // Получить список всех планов комиссий
                list: (params = {}) => ({url:`api/legal/commission/all/`, method:'GET', params})
            },
            // Получить конкретный план комиссий
            retrieve: (id) => ({url:`api/legal/commission/${id}/`, method:'GET'}),
            // Изменить план комиссий
            update: (id) => ({url:`api/legal/commission/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/legal/commission/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/legal/commission/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для AgencyCommissionPlan
                partial: (id) => ({url:`api/legal/commission/${id}/activate/`, method:'PATCH'})
            }
        },
        commissionerReport: {
            // Получить список отчетов комиссионера
            list: (params = {}) => ({url:`api/legal/commissioner_report/`, method:'GET', params}),
            // Создать отчеты комиссионера
            create: () => ({url:`api/legal/commissioner_report/`, method:'POST'}),
            // Получить конкретный отчет комиссионера
            retrieve: (id) => ({url:`api/legal/commissioner_report/${id}/`, method:'GET'}),
            // Изменить строки отчета комиссионера
            update: (id) => ({url:`api/legal/commissioner_report/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/legal/commissioner_report/${id}/`, method:'PATCH'}),
            // Удалить отчет комиссионера
            delete: (id) => ({url:`api/legal/commissioner_report/${id}/`, method:'DELETE'}),
            annul: {
                // Анулировать отчет комиссионера
                partial: (id) => ({url:`api/legal/commissioner_report/${id}/annul/`, method:'PATCH'})
            },
            downloadReport: {
                // Получить url файла отчета комиссионера (поле в ответе file_url)
                retrieve: (id) => ({url:`api/legal/commissioner_report/${id}/download_report/`, method:'GET'})
            },
            hold: {
                // Зафиксировать принятие отчета комиссионера
                partial: (id) => ({url:`api/legal/commissioner_report/${id}/hold/`, method:'PATCH'})
            },
            print: {
                // Получить "отчет комиссионера" в виде html
                retrieve: (id) => ({url:`api/legal/commissioner_report/${id}/print/`, method:'GET'})
            },
            reject: {
                // Зафиксировать отклонение отчета комиссионера
                partial: (id) => ({url:`api/legal/commissioner_report/${id}/reject/`, method:'PATCH'})
            },
            restore: {
                // Зафиксировать восстановление отчета комиссионера
                partial: (id) => ({url:`api/legal/commissioner_report/${id}/restore/`, method:'PATCH'})
            },
            resume: {
                // Зафиксировать возобновление отчета комиссионера
                partial: (id) => ({url:`api/legal/commissioner_report/${id}/resume/`, method:'PATCH'})
            },
            send: {
                // Выслать документ "отчет комиссионера"
                partial: (id) => ({url:`api/legal/commissioner_report/${id}/send/`, method:'PATCH'})
            }
        },
        contract: {
            // Получить список договоров
            list: (params = {}) => ({url:`api/legal/contract/`, method:'GET', params}),
            agency: {
                // Список договоров системы с агентством
                list: (params = {}) => ({url:`api/legal/contract/agency/`, method:'GET', params}),
                // Создать договор системы с агентством
                create: () => ({url:`api/legal/contract/agency/`, method:'POST'}),
                // Получить договор системы с агентством
                retrieve: (id) => ({url:`api/legal/contract/agency/${id}/`, method:'GET'}),
                // Изменить договор системы с агентством
                update: (id) => ({url:`api/legal/contract/agency/${id}/`, method:'PUT'}),
                delete: (id) => ({url:`api/legal/contract/agency/${id}/`, method:'DELETE'})
            },
            hotelier: {
                // Список договоров системы с отелем
                list: (params = {}) => ({url:`api/legal/contract/hotelier/`, method:'GET', params}),
                // Создать договор системы с отелем
                create: () => ({url:`api/legal/contract/hotelier/`, method:'POST'}),
                find: {
                    // Поиск действующего договора системы с отелем
                    put: () => ({url:`api/legal/contract/hotelier/find/`, method:'PUT'})
                },
                term: {
                    // Получить условие договора системы с отелем (id условия)
                    retrieve: (id) => ({url:`api/legal/contract/hotelier/term/${id}/`, method:'GET'}),
                    // Обновить условие договора системы с отелем
                    update: (id) => ({url:`api/legal/contract/hotelier/term/${id}/`, method:'PUT'}),
                    partial: (id) => ({url:`api/legal/contract/hotelier/term/${id}/`, method:'PATCH'})
                },
                // Получить договор системы с отелем
                retrieve: (id) => ({url:`api/legal/contract/hotelier/${id}/`, method:'GET'}),
                // Изменить договор системы с отелем
                update: (id) => ({url:`api/legal/contract/hotelier/${id}/`, method:'PUT'}),
                delete: (id) => ({url:`api/legal/contract/hotelier/${id}/`, method:'DELETE'})
            },
            // Получить конкретный договор без данных соответствующего ему типа
            retrieve: (id) => ({url:`api/legal/contract/${id}/`, method:'GET'}),
            approve: {
                // Одобрить договор
                partial: (id) => ({url:`api/legal/contract/${id}/approve/`, method:'PATCH'})
            },
            originalSend: {
                // Выслать оригинал договора
                partial: (id) => ({url:`api/legal/contract/${id}/original_send/`, method:'PATCH'})
            },
            resume: {
                // Возобновить договор
                partial: (id) => ({url:`api/legal/contract/${id}/resume/`, method:'PATCH'})
            },
            sign: {
                // Подписать договор
                partial: (id) => ({url:`api/legal/contract/${id}/sign/`, method:'PATCH'})
            },
            terminate: {
                // Расторгнуть договор
                partial: (id) => ({url:`api/legal/contract/${id}/terminate/`, method:'PATCH'})
            }
        },
        documentTemplate: {
            // Получение списка шаблонов документов
            list: (params = {}) => ({url:`api/legal/document_template/`, method:'GET', params}),
            // Создание шаблона документа
            create: () => ({url:`api/legal/document_template/`, method:'POST'}),
            // Получение конкретного шаблона документа
            retrieve: (id) => ({url:`api/legal/document_template/${id}/`, method:'GET'}),
            // Изменение шаблона документа
            update: (id) => ({url:`api/legal/document_template/${id}/`, method:'PUT'}),
            // Удаление шаблона документа
            delete: (id) => ({url:`api/legal/document_template/${id}/`, method:'DELETE'})
        },
        documentTemplateType: {
            // Получение списка типов шаблонов документов
            list: (params = {}) => ({url:`api/legal/document_template_type/`, method:'GET', params}),
            default: {
                // Получение шаблона по умолчанию для определённого типа шаблонов документов
                list: (type) => ({url:`api/legal/document_template_type/${type}/default/`, method:'GET'})
            }
        },
        entity: {
            // Получение списка юр лиц
            list: (params = {}) => ({url:`api/legal/entity/`, method:'GET', params}),
            // Создание юр лица
            create: () => ({url:`api/legal/entity/`, method:'POST'}),
            // Получение юр лица
            retrieve: (id) => ({url:`api/legal/entity/${id}/`, method:'GET'}),
            // Обновление модели юр лица
            update: (id) => ({url:`api/legal/entity/${id}/`, method:'PUT'}),
            // Удаление юр лица
            delete: (id) => ({url:`api/legal/entity/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для юрлица (Entity)
                partial: (id) => ({url:`api/legal/entity/${id}/activate/`, method:'PATCH'})
            },
            payments: {
                // Получение списка платежей для юр. лица системы
                retrieve: (id, params = {}) => ({url:`api/legal/entity/${id}/payments/`, method:'GET', params})
            },
            phones: {
                // Все внутренние телефоны юр лица
                retrieve: (id, params = {}) => ({url:`api/legal/entity/${id}/phones/`, method:'GET', params})
            }
        },
        hotelierContract: {
            withBookingsForDate: {
                // Получить список договоров по которым были операции в заданном  периоде
                list: (date, option) => ({url:`api/legal/hotelier_contract/with_bookings/${date}/${option}/`, method:'GET'})
            }
        },
        payment: {
            create: {
                // Создание платежа
                create: () => ({url:`api/legal/payment/create/`, method:'POST'})
            },
            import: {
                // Создание импорта платежей
                create: () => ({url:`api/legal/payment/import/`, method:'POST'}),
                accept: {
                    // Принятие платежей
                    partial: (id) => ({url:`api/legal/payment/import/${id}/accept/`, method:'PATCH'})
                },
                process: {
                    // Импорт платежей
                    partial: (id) => ({url:`api/legal/payment/import/${id}/process/`, method:'PATCH'})
                }
            },
            split: {
                // Создание разнесения платежа
                create: () => ({url:`api/legal/payment/split/`, method:'POST'}),
                // Получение конкретного разнесения платежа
                retrieve: (id) => ({url:`api/legal/payment/split/${id}/`, method:'GET'}),
                annul: {
                    // Аннулировние разнесения платежа
                    partial: (id) => ({url:`api/legal/payment/split/${id}/annul/`, method:'PATCH'})
                },
                delete: {
                    delete: (id) => ({url:`api/legal/payment/split/${id}/delete/`, method:'DELETE'})
                },
                update: {
                    partial: (id) => ({url:`api/legal/payment/split/${id}/update/`, method:'PATCH'})
                }
            },
            // Получение конкретного платежа
            retrieve: (id) => ({url:`api/legal/payment/${id}/`, method:'GET'}),
            accept: {
                // Принять платеж
                partial: (id) => ({url:`api/legal/payment/${id}/accept/`, method:'PATCH'})
            },
            annul: {
                // Аннулировать платеж
                partial: (id) => ({url:`api/legal/payment/${id}/annul/`, method:'PATCH'})
            },
            autoSplit: {
                // Разнести платеж по идентификатору брони
                partial: (id) => ({url:`api/legal/payment/${id}/auto_split/`, method:'PATCH'})
            },
            cancel: {
                // Отменить платеж
                partial: (id) => ({url:`api/legal/payment/${id}/cancel/`, method:'PATCH'})
            },
            hold: {
                // Провести платеж
                partial: (id) => ({url:`api/legal/payment/${id}/hold/`, method:'PATCH'})
            },
            restore: {
                // Восстановить платеж
                partial: (id) => ({url:`api/legal/payment/${id}/restore/`, method:'PATCH'})
            },
            update: {
                // Обновление платежа(только для статусов NEW/DRAFT)
                update: (id) => ({url:`api/legal/payment/${id}/update/`, method:'PUT'})
            }
        },
        paymentOffset: {
            // Получение полного списка зачётов
            list: (params = {}) => ({url:`api/legal/payment_offset/`, method:'GET', params}),
            // Создание зачёта платежа
            create: () => ({url:`api/legal/payment_offset/`, method:'POST'}),
            // Получение конкретного зачёта оплаты
            retrieve: (id) => ({url:`api/legal/payment_offset/${id}/`, method:'GET'}),
            // Обновление зачёта оплаты
            update: (id) => ({url:`api/legal/payment_offset/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/legal/payment_offset/${id}/`, method:'PATCH'}),
            annul: {
                // Аннулирование зачёта оплаты
                partial: (id) => ({url:`api/legal/payment_offset/${id}/annul/`, method:'PATCH'})
            },
            cancel: {
                // Отмена зачёта оплаты
                partial: (id) => ({url:`api/legal/payment_offset/${id}/cancel/`, method:'PATCH'})
            },
            download: {
                // Получить url файла зачёта оплаты (поле в ответе file_url)
                retrieve: (id) => ({url:`api/legal/payment_offset/${id}/download/`, method:'GET'})
            },
            hold: {
                // Подтверждение контрагента
                partial: (id) => ({url:`api/legal/payment_offset/${id}/hold/`, method:'PATCH'})
            },
            print: {
                // Распечатать зачёт оплаты
                retrieve: (id) => ({url:`api/legal/payment_offset/${id}/print/`, method:'GET'})
            },
            reject: {
                // Отказ контрагента
                partial: (id) => ({url:`api/legal/payment_offset/${id}/reject/`, method:'PATCH'})
            },
            restore: {
                // Восстановление зачёта оплаты
                partial: (id) => ({url:`api/legal/payment_offset/${id}/restore/`, method:'PATCH'})
            },
            submit: {
                // Отправка контрагенту на согласование
                partial: (id) => ({url:`api/legal/payment_offset/${id}/submit/`, method:'PATCH'})
            }
        },
        paymentOrder: {
            // Получение списка PaymentOrder
            list: (params = {}) => ({url:`api/legal/payment_order/`, method:'GET', params}),
            // Создание PaymentOrder
            create: () => ({url:`api/legal/payment_order/`, method:'POST'}),
            // Получение модели PaymentOrder
            retrieve: (id) => ({url:`api/legal/payment_order/${id}/`, method:'GET'}),
            // Обновление строк PaymentOrder
            update: (id) => ({url:`api/legal/payment_order/${id}/`, method:'PUT'}),
            // Удаление PaymentOrder
            delete: (id) => ({url:`api/legal/payment_order/${id}/`, method:'DELETE'})
        },
        paymentOrderRow: {
            // Получение PaymentOrderRow
            retrieve: (id) => ({url:`api/legal/payment_order_row/${id}/`, method:'GET'})
        },
        supplierInvoice: {
            // Получить список счетов поставщиков
            list: (params = {}) => ({url:`api/legal/supplier_invoice/`, method:'GET', params}),
            // Получить конкретный счёт поставщика
            retrieve: (id) => ({url:`api/legal/supplier_invoice/${id}/`, method:'GET'}),
            offsets: {
                // Получение зачётов оплат для счёта от поставщика
                retrieve: (id) => ({url:`api/legal/supplier_invoice/${id}/offsets/`, method:'GET'})
            }
        }
    },
    media: {
        f2o: {
            // Получение файла по id
            retrieve: (id) => ({url:`api/media/f2o/${id}/`, method:'GET'})
        },
        file: {
            // Получение файлов
            put: () => ({url:`api/media/file/`, method:'PUT'}),
            import: {
                // Загрузка файла для импорта
                create: () => ({url:`api/media/file/import/`, method:'POST'})
            },
            upload: {
                // Загрузка файла
                create: () => ({url:`api/media/file/upload/`, method:'POST', json:false})
            },
            listForObject: {
                // Получение всех файлов объекта
                list: (data_type, id) => ({url:`api/media/file/${data_type}/${id}/`, method:'GET'})
            },
            // Удаление файла
            delete: (id) => ({url:`api/media/file/${id}/`, method:'DELETE'})
        },
        i2o: {
            // Получение изображения по id
            retrieve: (id) => ({url:`api/media/i2o/${id}/`, method:'GET'})
        },
        image: {
            // Получение изображений
            put: () => ({url:`api/media/image/`, method:'PUT'}),
            upload: {
                // Загрузка изображения
                create: () => ({url:`api/media/image/upload/`, method:'POST', json:false})
            },
            listForObject: {
                // Получение всех изображений объекта
                list: (data_type, id) => ({url:`api/media/image/${data_type}/${id}/`, method:'GET'})
            },
            // Удаление изображения
            delete: (id) => ({url:`api/media/image/${id}/`, method:'DELETE'})
        },
        importFile: {
            // Получение списка всех импортов файлов
            list: (params = {}) => ({url:`api/media/import_file/`, method:'GET', params}),
            prune: {
                // Удаление всех незавершенных импортов файлов
                delete: () => ({url:`api/media/import_file/prune/`, method:'DELETE'})
            },
            // Получение импорта файла
            retrieve: (id) => ({url:`api/media/import_file/${id}/`, method:'GET'}),
            // Удаление импорта
            delete: (id) => ({url:`api/media/import_file/${id}/`, method:'DELETE'})
        }
    },
    message: {
        chat: {
            list: (params = {}) => ({url:`api/message/chat/`, method:'GET', params}),
            create: () => ({url:`api/message/chat/`, method:'POST'}),
            retrieve: (id) => ({url:`api/message/chat/${id}/`, method:'GET'}),
            messages: {
                list: (id, params = {}) => ({url:`api/message/chat/${id}/messages/`, method:'GET', params})
            }
        },
        message: {
            // Получение списка Message
            list: (params = {}) => ({url:`api/message/message/`, method:'GET', params}),
            // Создание Message
            create: () => ({url:`api/message/message/`, method:'POST'}),
            // Создание Message
            retrieve: (id) => ({url:`api/message/message/${id}/`, method:'GET'}),
            // Обновление Message
            update: (id) => ({url:`api/message/message/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/message/message/${id}/`, method:'PATCH'}),
            // Удаление Message
            delete: (id) => ({url:`api/message/message/${id}/`, method:'DELETE'})
        },
        newchat: {
            // Новый чат с лидом
            create: () => ({url:`api/message/newchat/`, method:'POST'})
        },
        transports: {
            // Получение списка транспортов
            list: () => ({url:`api/message/transports/`, method:'GET'})
        }
    },
    nsbOld: {
        importPrices: {
            // Синхронизация цен с cp.nsb.su
            create: () => ({url:`api/nsb_old/import_prices/`, method:'POST'})
        },
        oldToNewHotel: {
            // Получение отеля по старому id
            retrieve: (id) => ({url:`api/nsb_old/old_to_new_hotel/${id}/`, method:'GET'})
        }
    },
    refbook: {
        age: {
            // Создание Age
            create: () => ({url:`api/refbook/age/`, method:'POST'}),
            // Получение Age
            retrieve: (id) => ({url:`api/refbook/age/${id}/`, method:'GET'}),
            // Обновление Age
            update: (id) => ({url:`api/refbook/age/${id}/`, method:'PUT'}),
            // Удаление Age
            delete: (id) => ({url:`api/refbook/age/${id}/`, method:'DELETE'})
        },
        attribute: {
            // Получение списка Attribute
            list: (params = {}) => ({url:`api/refbook/attribute/`, method:'GET', params}),
            // Получение атрибута отеля
            retrieve: (id) => ({url:`api/refbook/attribute/${id}/`, method:'GET'}),
            // Обновление атрибута отеля
            update: (id) => ({url:`api/refbook/attribute/${id}/`, method:'PUT'}),
            // Удаление атрибута отеля (не использовать, будет удалено)
            delete: (id) => ({url:`api/refbook/attribute/${id}/`, method:'DELETE'})
        },
        bedType: {
            // Получение списка BedType
            list: (params = {}) => ({url:`api/refbook/bed_type/`, method:'GET', params})
        },
        category: {
            // Создание категории
            create: () => ({url:`api/refbook/category/`, method:'POST'}),
            copyCategoryWithPrices: {
                // Копирование категории. А так же цен с этим объектом связанных
                create: () => ({url:`api/refbook/category/copy_category_with_prices/`, method:'POST'})
            },
            // Получение категории по id
            retrieve: (id) => ({url:`api/refbook/category/${id}/`, method:'GET'}),
            // Обновление модели категории
            update: (id) => ({url:`api/refbook/category/${id}/`, method:'PUT'}),
            // Удаление модели категории (не использовать, будет удалено)
            delete: (id) => ({url:`api/refbook/category/${id}/`, method:'DELETE'}),
            deep: {
                // Получить модель отеля с вложенными сущностями
                retrieve: (id, params = {}) => ({url:`api/refbook/category/${id}/deep/`, method:'GET', params})
            }
        },
        categoryType: {
            // Получение списка CategoryType
            list: (params = {}) => ({url:`api/refbook/category_type/`, method:'GET', params})
        },
        disease: {
            // Список заболеваний
            list: (params = {}) => ({url:`api/refbook/disease/`, method:'GET', params}),
            lt: {
                // Список заболеваний (облегченный)
                list: (params = {}) => ({url:`api/refbook/disease/lt/`, method:'GET', params})
            },
            // Получение заболевания по id
            retrieve: (id) => ({url:`api/refbook/disease/${id}/`, method:'GET'})
        },
        diseaseCategory: {
            // Список категорий заболеваний
            list: (params = {}) => ({url:`api/refbook/disease_category/`, method:'GET', params}),
            // Получение категории заболеваний по id
            retrieve: (id) => ({url:`api/refbook/disease_category/${id}/`, method:'GET'})
        },
        hotel: {
            // Список отелей
            list: (params = {}) => ({url:`api/refbook/hotel/`, method:'GET', params}),
            // Создание отеля
            create: () => ({url:`api/refbook/hotel/`, method:'POST'}),
            find: {
                // Поиск отеля по региону, заболеваниям и услугам
                put: () => ({url:`api/refbook/hotel/find/`, method:'PUT'})
            },
            listCategories: {
                // Список категорий в отеле
                list: (hotel_id, params = {}) => ({url:`api/refbook/hotel/${hotel_id}/categories/`, method:'GET', params})
            },
            // Получение отеля по id
            retrieve: (id) => ({url:`api/refbook/hotel/${id}/`, method:'GET'}),
            // Изменение отеля
            update: (id) => ({url:`api/refbook/hotel/${id}/`, method:'PUT'}),
            // Удаление отеля (не использовать, будет удалено)
            delete: (id) => ({url:`api/refbook/hotel/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для отеля
                partial: (id) => ({url:`api/refbook/hotel/${id}/activate/`, method:'PATCH'})
            },
            ages: {
                // Получение списка Age для отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/ages/`, method:'GET', params})
            },
            attribute: {
                // Получение списка атрибутов отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/attribute/`, method:'GET', params}),
                // Создание атрибута отеля
                post: (id) => ({url:`api/refbook/hotel/${id}/attribute/`, method:'POST'})
            },
            deep: {
                // Получить модель отеля с вложенными сущностями
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/deep/`, method:'GET', params})
            },
            meals: {
                // Получение списка программ питания для отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/meals/`, method:'GET', params})
            },
            potentialResponsible: {
                // Список потенциальных ответственных
                list: (id, params = {}) => ({url:`api/refbook/hotel/${id}/potential_responsible/`, method:'GET', params})
            },
            programs: {
                // Получение списка программ пребывания для отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/programs/`, method:'GET', params})
            },
            shifts: {
                // Получение списка смен для отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/shifts/`, method:'GET', params})
            }
        },
        hotelService: {
            // Список услуг отеля
            list: (params = {}) => ({url:`api/refbook/hotel_service/`, method:'GET', params}),
            // Получение услуги отеля по id
            retrieve: (id) => ({url:`api/refbook/hotel_service/${id}/`, method:'GET'})
        },
        hotelServiceCategory: {
            // Список категорий услуг отеля
            list: (params = {}) => ({url:`api/refbook/hotel_service_category/`, method:'GET', params}),
            // Получение категории услуг отеля по id
            retrieve: (id) => ({url:`api/refbook/hotel_service_category/${id}/`, method:'GET'})
        },
        location: {
            // Получение списка Локаций
            list: (params = {}) => ({url:`api/refbook/location/`, method:'GET', params}),
            // Создание Локации
            create: () => ({url:`api/refbook/location/`, method:'POST'}),
            // Получение Локации
            retrieve: (id) => ({url:`api/refbook/location/${id}/`, method:'GET'}),
            // Обновление Локации
            update: (id) => ({url:`api/refbook/location/${id}/`, method:'PUT'}),
            // Изменение поля is_active
            partial: (id) => ({url:`api/refbook/location/${id}/`, method:'PATCH'}),
            countryOf: {
                // Поиск страны по адресу
                retrieve: (id) => ({url:`api/refbook/location/${id}/country_of/`, method:'GET'})
            }
        },
        meal: {
            // Создание Программы питания
            create: () => ({url:`api/refbook/meal/`, method:'POST'}),
            // Получение Программы питания
            retrieve: (id) => ({url:`api/refbook/meal/${id}/`, method:'GET'}),
            // Обновление Программы питания
            update: (id) => ({url:`api/refbook/meal/${id}/`, method:'PUT'}),
            // Изменение поля is_active Программы питания
            partial: (id) => ({url:`api/refbook/meal/${id}/`, method:'PATCH'}),
            // Удаление Программы питания
            delete: (id) => ({url:`api/refbook/meal/${id}/`, method:'DELETE'})
        },
        program: {
            // Создание Программы пребывания
            create: () => ({url:`api/refbook/program/`, method:'POST'}),
            copyProgramWithPrices: {
                // Копирование программы. А так же цен с этим объектом связанных
                create: () => ({url:`api/refbook/program/copy_program_with_prices/`, method:'POST'})
            },
            // Получение Программы пребывания
            retrieve: (id) => ({url:`api/refbook/program/${id}/`, method:'GET'}),
            // Обновление Программы пребывания
            update: (id) => ({url:`api/refbook/program/${id}/`, method:'PUT'}),
            // Изменение поля is_active Программы пребывания
            partial: (id) => ({url:`api/refbook/program/${id}/`, method:'PATCH'}),
            // Удаление Программы пребывания
            delete: (id) => ({url:`api/refbook/program/${id}/`, method:'DELETE'}),
            deep: {
                retrieve: (id, params = {}) => ({url:`api/refbook/program/${id}/deep/`, method:'GET', params})
            }
        },
        roomService: {
            // Список услуг номера
            list: (params = {}) => ({url:`api/refbook/room_service/`, method:'GET', params}),
            // Получение услуги номера по id
            retrieve: (id) => ({url:`api/refbook/room_service/${id}/`, method:'GET'})
        },
        shift: {
            // Создание Смены
            create: () => ({url:`api/refbook/shift/`, method:'POST'}),
            // Получение Смены
            retrieve: (id) => ({url:`api/refbook/shift/${id}/`, method:'GET'}),
            // Обновление Смены
            update: (id) => ({url:`api/refbook/shift/${id}/`, method:'PUT'}),
            // Удаление Смены
            delete: (id) => ({url:`api/refbook/shift/${id}/`, method:'DELETE'})
        },
        socialGroup: {
            // Получение списка SocialGroups
            list: (params = {}) => ({url:`api/refbook/social_group/`, method:'GET', params})
        },
        viewType: {
            // Получение списка ViewType
            list: (params = {}) => ({url:`api/refbook/view_type/`, method:'GET', params})
        }
    },
    reports: {
        report: {
            // Получение списка отчетов
            list: (params = {}) => ({url:`api/reports/report/`, method:'GET', params}),
            // Создание отчета
            create: () => ({url:`api/reports/report/`, method:'POST'}),
            // Получение отчета по идентификатору
            retrieve: (id) => ({url:`api/reports/report/${id}/`, method:'GET'}),
            // Обновление отчета
            update: (id) => ({url:`api/reports/report/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/reports/report/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/reports/report/${id}/`, method:'DELETE'})
        },
        reportTemplate: {
            // Получение списка своих шаблонов отчетов
            list: (params = {}) => ({url:`api/reports/report_template/`, method:'GET', params}),
            create: () => ({url:`api/reports/report_template/`, method:'POST'}),
            // Получение шаблона отчета по идентификатору
            retrieve: (id) => ({url:`api/reports/report_template/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/reports/report_template/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/reports/report_template/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/reports/report_template/${id}/`, method:'DELETE'})
        }
    },
    schedule: {
        task: {
            // Получение списка задач
            list: (params = {}) => ({url:`api/schedule/task/`, method:'GET', params}),
            // Создание задачи
            create: () => ({url:`api/schedule/task/`, method:'POST'}),
            all: {
                // Все задачи (в карточке заказчика)
                list: (params = {}) => ({url:`api/schedule/task/all/`, method:'GET', params})
            },
            created: {
                // Создание тура на основе задачи
                list: (params = {}) => ({url:`api/schedule/task/created/`, method:'GET', params})
            },
            history: {
                // Закрытые задачи
                list: (params = {}) => ({url:`api/schedule/task/history/`, method:'GET', params})
            },
            massCreate: {
                // Пакетное создание задач
                create: () => ({url:`api/schedule/task/mass_create/`, method:'POST'})
            },
            massDelete: {
                // Пакетное удаление задач
                create: () => ({url:`api/schedule/task/mass_delete/`, method:'POST'})
            },
            // Получение задачи
            retrieve: (id) => ({url:`api/schedule/task/${id}/`, method:'GET'}),
            // Обновление задачи
            update: (id) => ({url:`api/schedule/task/${id}/`, method:'PUT'}),
            // Частичное обновление задачи
            partial: (id) => ({url:`api/schedule/task/${id}/`, method:'PATCH'}),
            // Удаление задачи
            delete: (id) => ({url:`api/schedule/task/${id}/`, method:'DELETE'}),
            close: {
                // Пометить задачу как выполненную
                partial: (id) => ({url:`api/schedule/task/${id}/close/`, method:'PATCH'})
            },
            newTour: {
                // Создание тура на основе задачи
                partial: (id) => ({url:`api/schedule/task/${id}/new_tour/`, method:'PATCH'})
            },
            noAnswer: {
                // Действие при недозвоне
                partial: (id) => ({url:`api/schedule/task/${id}/no_answer/`, method:'PATCH'})
            },
            postponeScheduled: {
                // Закрыть текущую задачу, создать новую задачу на основе текущей
                partial: (id) => ({url:`api/schedule/task/${id}/postpone_scheduled/`, method:'PATCH'})
            }
        },
        tasksForObject: {
            // Список задач объекта
            list: (object_id, object_type, params = {}) => ({url:`api/schedule/task/${object_type}/${object_id}/`, method:'GET', params})
        }
    },
    scripts: {
        exit: {
            list: (params = {}) => ({url:`api/scripts/exit/`, method:'GET', params}),
            create: () => ({url:`api/scripts/exit/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/exit/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/exit/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/exit/${id}/`, method:'DELETE'})
        },
        question: {
            list: (params = {}) => ({url:`api/scripts/question/`, method:'GET', params}),
            create: () => ({url:`api/scripts/question/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/question/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/question/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/question/${id}/`, method:'DELETE'})
        },
        script: {
            list: (params = {}) => ({url:`api/scripts/script/`, method:'GET', params}),
            create: () => ({url:`api/scripts/script/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/script/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/script/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/script/${id}/`, method:'DELETE'}),
            deep: {
                retrieve: (id) => ({url:`api/scripts/script/${id}/deep/`, method:'GET'})
            }
        },
        stage: {
            list: (params = {}) => ({url:`api/scripts/stage/`, method:'GET', params}),
            create: () => ({url:`api/scripts/stage/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/stage/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/stage/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/stage/${id}/`, method:'DELETE'})
        },
        variable: {
            list: (params = {}) => ({url:`api/scripts/variable/`, method:'GET', params}),
            create: () => ({url:`api/scripts/variable/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/variable/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/variable/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/variable/${id}/`, method:'DELETE'})
        }
    },
    support: {
        screen: {
            // Получение списка экранов
            list: (params = {}) => ({url:`api/support/screen/`, method:'GET', params}),
            create: () => ({url:`api/support/screen/`, method:'POST'}),
            retrieve: (id) => ({url:`api/support/screen/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/support/screen/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/support/screen/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/support/screen/${id}/`, method:'DELETE'})
        }
    },
    telephony: {
        backcall: {
            // Обратный звонок
            create: () => ({url:`api/telephony/backcall/`, method:'POST'})
        },
        click: {
            // Хук обратного звонка
            create: () => ({url:`api/telephony/click/`, method:'POST'})
        },
        contact: {
            // Получение контакта по id
            retrieve: (id) => ({url:`api/telephony/contact/${id}/`, method:'GET'}),
            // Изменение контакта
            partial: (id) => ({url:`api/telephony/contact/${id}/`, method:'PATCH'}),
            // Удаление контакта
            delete: (id) => ({url:`api/telephony/contact/${id}/`, method:'DELETE'}),
            // Получение списка контактов для объекта
            list: (object_id, object_type, params = {}) => ({url:`api/telephony/contact/${object_type}/${object_id}/`, method:'GET', params}),
            // Создание контакта для объекта
            create: (object_id, object_type) => ({url:`api/telephony/contact/${object_type}/${object_id}/`, method:'POST'})
        },
        localPhone: {
            // Получение списка внутренних телефонов пользователей
            list: (params = {}) => ({url:`api/telephony/local_phone/`, method:'GET', params}),
            // Создание внутреннего телефона пользователя
            create: () => ({url:`api/telephony/local_phone/`, method:'POST'}),
            // Получение внутреннего телефона пользователя
            retrieve: (id) => ({url:`api/telephony/local_phone/${id}/`, method:'GET'}),
            // Обновление внутреннего телефона пользователя
            update: (id) => ({url:`api/telephony/local_phone/${id}/`, method:'PUT'}),
            // Удаление внутреннего телефона пользователя
            delete: (id) => ({url:`api/telephony/local_phone/${id}/`, method:'DELETE'})
        },
        phoneNumber: {
            // Получение информации о телефоне по номеру
            retrieve: (phone_number) => ({url:`api/telephony/phone/+${phone_number}/`, method:'GET'}),
            // Обновление поля info (по номеру)
            update: (phone_number) => ({url:`api/telephony/phone/+${phone_number}/`, method:'PUT'}),
            // Удаление всех связей у телефона (по номеру)
            delete: (phone_number) => ({url:`api/telephony/phone/+${phone_number}/`, method:'DELETE'})
        },
        phoneForObject: {
            // Получение списка телефонов для объекта
            list: (data_type, object_id, params = {}) => ({url:`api/telephony/phone/${data_type}/${object_id}/`, method:'GET', params}),
            // Создание телефона
            create: (data_type, object_id) => ({url:`api/telephony/phone/${data_type}/${object_id}/`, method:'POST'}),
            // Удаление телефона у объекта по id
            delete: (data_type, id, object_id) => ({url:`api/telephony/phone/${data_type}/${object_id}/${id}/`, method:'DELETE'})
        },
        phoneNumberForObject: {
            // Удаление телефона у объекта по номеру
            delete: (data_type, object_id, phone_number) => ({url:`api/telephony/phone/${data_type}/${object_id}/+${phone_number}/`, method:'DELETE'})
        },
        phone: {
            // Получение информации о телефоне по id
            retrieve: (id) => ({url:`api/telephony/phone/${id}/`, method:'GET'}),
            // Обновление поля info info (по id)
            update: (id) => ({url:`api/telephony/phone/${id}/`, method:'PUT'}),
            // Удаление всех связей у телефона (по id)
            delete: (id) => ({url:`api/telephony/phone/${id}/`, method:'DELETE'})
        },
        server: {
            // Получить информацию о сервере телефонии
            retrieve: () => ({url:`api/telephony/server/`, method:'GET'}),
            // Задать сервер телефонии
            post: () => ({url:`api/telephony/server/`, method:'POST'})
        },
        telecall: {
            // Получение списка телефонных звонков
            list: (params = {}) => ({url:`api/telephony/telecall/`, method:'GET', params}),
            // Создание телефонного звонка
            create: () => ({url:`api/telephony/telecall/`, method:'POST'})
        },
        telecallsForObject: {
            // Получение списка телефонных звонков связанных с объектом
            list: (data_type, object_id, params = {}) => ({url:`api/telephony/telecall/${data_type}/${object_id}/`, method:'GET', params})
        }
    },
    token: {
        token: {
            telephony: {
                leadback: {
                    create: (token) => ({url:`api/token/${token}/telephony/leadback/`, method:'POST'})
                },
                localStatus: {
                    list: (token) => ({url:`api/token/${token}/telephony/local_status/`, method:'GET'})
                },
                telecall: {
                    create: (token) => ({url:`api/token/${token}/telephony/telecall/`, method:'POST'})
                }
            },
            timelines: {
                webhook: {
                    create: (token) => ({url:`api/token/${token}/timelines/webhook/`, method:'POST'})
                }
            }
        }
    },
    version: {
        // Получение информации о версии
        retrieve: () => ({url:`api/version/`, method:'GET'})
    }
}
