<template>
  <div class="collapse-content" :id="`collapse-${id}`">
    <div class="collapse-content__body" :class="{ collapsed: collapsed }">
      <slot />
    </div>
    <div v-if="isOverflown" class="collapse-content__button">
      <a
        href=""
        @click.prevent="toggleCollapse"
        class="link-primary form-label dotted-underline"
      >
        <span>{{ togglerText }}</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { debounce } from 'lodash'

const props = defineProps({
  id: {
    // uniq id to identify the element
    type: [Number, String],
    required: true,
  },
  maxHeight: {
    type: String,
    required: false,
    default: () => '100px',
  },
  openText: {
    type: String,
    required: false,
    default: () => 'Развернуть',
  },
  closeText: {
    type: String,
    required: false,
    default: () => 'Свернуть',
  },
  recalc: {
    type: Boolean,
    required: false,
    default: () => false,
  },
})
const emit = defineEmits(['recalced'])

let onResize = null
const el = ref(null)
const collapsed = ref(true)
const maxHeight = ref(props.maxHeight)
const maxHeightNum = parseInt(props.maxHeight)
const isOverflown = ref(false)
const togglerText = computed(() =>
  collapsed.value ? props.openText : props.closeText,
)
const toggleCollapse = () => {
  collapsed.value = !collapsed.value
}
const checkOverflow = ({ scrollHeight }) => {
  nextTick(() => {
    isOverflown.value = scrollHeight > maxHeightNum
  })
}

watch(
  () => props.recalc,
  (val) => {
    if (val && onResize) {
      collapsed.value = true
      nextTick(() => {
        onResize()
        emit('recalced')
      })
    }
  },
)

onMounted(() => {
  el.value = document.querySelector(
    `#collapse-${props.id}>.collapse-content__body`,
  )
  onResize = debounce(checkOverflow.bind(null, el.value), 100)
  window.addEventListener('resize', onResize)
  onResize()
})
onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<style lang="scss" scoped>
.collapse-content {
  position: relative;
  &__body {
    position: relative;
    max-height: unset;
    overflow: hidden;
    &.collapsed {
      max-height: v-bind(maxHeight);
    }
  }
  &__button {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
