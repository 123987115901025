<template>
  <CAlert color="danger">
    <ul class="m-0">
      <li
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </li>
    </ul>
  </CAlert>
</template>

<script>
export default {
  name: 'ServerFormErrors',
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
