// Этот файл сгенерирован автоматически. Не надо его редактировать.
// Здесь описаны константы из бэкенда

export default {
  ADDRESS_LENGTH:254,
  ARTICLE_SUMMARY_LENGTH:300,
  ARTICLE_TITLE_LENGTH:100,
  BANK_ACCOUNT_LENGTH:20,
  BIK_LENGTH:9,
  CHILD_AGE_RANGE:[6, 14],
  CITY_NAME_LENGTH:34,
  CONTACTS_LIMIT:20,
  CURRENCY_CHAR:"₽",
  DATA_TYPE_ID_LENGTH:70,
  DATA_TYPE_LENGTH:50,
  DATE_RANGE_FILTER_MODES:["start", "end", "all", "any"],
  DAYS_UNREAD_MESSAGE_CLEANUP:30,
  DEFAULT_MEDIUM_ADVANTAGE_TIME:150,
  DEFAULT_STRONG_ADVANTAGE_TIME:750,
  DEFAULT_WEAK_ADVANTAGE_TIME:30,
  DEFAULT_PAGINATION:20,
  DEFAULT_WORK_DAY_END:"22:00:00",
  DEFAULT_WORK_DAY_START:"08:00:00",
  DOCUMENT_ISSUER_LENGTH:100,
  DOCUMENT_NUMBER_LENGTH:32,
  DOCUMENT_TYPE_LENGTH:31,
  EMAIL_MAX_LENGTH:100,
  EXTERNAL_HANDLE_LENGTH:20,
  FAKE_PAGINATOR_LIMIT:10000,
  FETCH_EMAIL_TEST_FREQUENCY:1,
  FETCH_EMAIL_TEST_PERIOD:60,
  FILE_NAME_LENGTH:249,
  FINANCE_INFO_LENGTH:500,
  FMS_PROFESSION_LENGTH:35,
  FUCKED_UP_VIEW_DAYS:7,
  HANDLE_LENGTH:20,
  HASH_LENGTH:64,
  IMPORT_FILE_PROCESS_TIME_LIMIT:900,
  INFO_LENGTH:252,
  INN_FIXED_LENGTH_MAX:12,
  INN_FIXED_LENGTH_MIN:10,
  INVOICE_NUMBER_LENGTH:120,
  KPP_LENGTH:9,
  LEAD_CLOSE_WARNING_TIME:120,
  LEAD_DRAFT_LIFETIME:60,
  LEAD_FIRST_ACTION_TIMEOUT:120,
  LEAD_INACTIVE_TIMEOUT:480,
  LOCAL_PHONE_MIN_LENGTH:3,
  LONG_TITLE_LENGTH:253,
  MAX_AGE:110,
  MOBILE_INFO_LENGTH:500,
  OBJECT_TYPE_STR_LEN:50,
  OBSOLETE_FILE_IMPORT_DAYS:10,
  OKPO_FIXED_LENGTH_MAX:10,
  OKPO_FIXED_LENGTH_MIN:8,
  ORGN_FIXED_LENGTH_MAX:15,
  ORGN_FIXED_LENGTH_MIN:13,
  PASSWORD_LENGTH:100,
  PATH_LENGTH:1000,
  PAYMENT_ORDER_PURPOSE_LENGTH:257,
  PERSON_FIO_LENGTH:92,
  PERSON_NAME_LENGTH:33,
  PHONE_MAX_LENGTH:20,
  PIN_MAX_LENGTH:10,
  POSITION_LENGTH:251,
  REDIS_MAIN_SCREEN_ERROR_KEY:"MAIN_SCREEEN_ERROR",
  REMINDER_PRUNE_TIMEOUT:7,
  REMOVE_PHONES_PERIOD:3600.0,
  ROOM_NUMBER_LENGTH:10,
  SBER_QR_CODE_URL_LEN:150,
  SHORT_TITLE_LENGTH:60,
  SITE_LENGTH:255,
  SLUG_LENGTH:150,
  STORE_BLACK_LIST_DAYS:30,
  SYSTEM_COMPANY_ID:1,
  SYSTEM_USER_ID:1,
  TAG_MAX_LENGTH:20,
  TIMEZONE_MAX_LENGTH:100,
  TOKEN_MAX_LENGTH:1000,
  URL_LENGTH:2048,
  WEEKENDS:[5, 6],
  BACK_CALL_CLICK_CACHE_EXPIRE:15,
  UTM_MAX_LENGTH:200,
  SECONDS_TO_CHECK_BOOKING_EVERY_10_MIN:3600,
  SECONDS_TO_CHECK_BOOKING_EVERY_HOUR:21600,
  DEFAULT_PROFKURORT_PAY_TIME:[12, 0, 0],
  AGENCY_COMPANY_ID:2,
  HOTELIER_COMPANY_ID:3,
  EDUARD_LEON_PHONE:"+79999990105",


// ========================================  -- ROLES --  ======================================== 

  ROLES_ACCOUNTANT:"accountant",
  ROLES_ACC_SYNC:"acc_sync",
  ROLES_CRM_SYNC:"crm_sync",
  ROLES_ADMIN:"admin",
  ROLES_AGENT:"agent",
  ROLES_CONTROLLER:"controller",
  ROLES_CUSTOMER:"customer",
  ROLES_VISITOR:"visitor",
  ROLES_DRIVER:"driver",
  ROLES_HOTELIER:"hotelier",
  ROLES_JURIST:"jurist",
  ROLES_MANAGER:"manager",
  ROLES_OFFLINE:"offline",
  ROLES_OWNER:"owner",
  ROLES_SALES:"sales",
  ROLES_SYSTEM:"system",
  ROLES_TECH:"tech",
  ROLES_TELEPHONY:"telephony",
  ROLES_TRANSFER:"transfer",
  ROLES_WEBMASTER:"webmaster",
  ROLES_WEBSITE:"website",
  ROLES_ROBOT:"robot",

  ROLES_TITLES:{
    accountant:"Бухгалтер",
    acc_sync:"Синхронизация с 1С",
    crm_sync:"Синхронизация с CRM",
    admin:"Администратор",
    agent:"Агент",
    controller:"Контролер",
    customer:"Заказчик",
    visitor:"Посетитель",
    driver:"Водитель",
    hotelier:"Менеджер отеля",
    jurist:"Юрист",
    manager:"Менеджер системы",
    offline:"Без доступа",
    owner:"Владелец",
    sales:"Поставщик услуг",
    system:"Системный",
    tech:"Техотдел",
    telephony:"Телефония",
    transfer:"Менеджер перевозчика",
    webmaster:"Вебмастер",
    website:"Для форм",
    robot:"Робот",
  },
  ADMIN_ROLES:["admin", "owner", "crm_sync"],
  AGENCY_ROLES:["accountant", "acc_sync", "crm_sync", "admin", "agent", "controller", "owner", "telephony", "webmaster", "website"],
  AGENT_ROLES:["agent"],
  EMPLOYEE_ROLES:["accountant", "admin", "agent", "controller", "driver", "hotelier", "jurist", "manager", "sales", "tech", "transfer", "webmaster"],
  HEADLESS_ROLES:["acc_sync", "crm_sync", "telephony", "website"],
  HOTELIER_ROLES:["accountant", "acc_sync", "admin", "hotelier", "owner", "telephony", "webmaster", "website"],
  LEAD_CREATOR_ROLES:["admin", "owner", "agent", "telephony", "website", "crm_sync"],
  PERSONAL_ROLES:["accountant", "admin", "agent", "controller", "driver", "hotelier", "jurist", "manager", "sales", "tech", "transfer", "webmaster", "owner"],
  RESPONSIBLE_ROLES:["admin", "hotelier", "owner", "tech"],
  SERVICE_ROLES:["accountant", "acc_sync", "admin", "owner", "sales", "telephony", "webmaster", "website"],
  SYSTEM_ROLES:["accountant", "admin", "jurist", "manager", "owner", "system", "tech"],
  TRANSFER_ROLES:["accountant", "acc_sync", "admin", "driver", "owner", "telephony", "transfer", "webmaster", "website"],


// ========================================  -- ACTIONS_INFO --  ======================================== 

  ACTIONS_INFO:{
    remove: ["удаление", []],
    accept: ["принятие", [1]],
    submit: ["на согласование", [8, 4]],
    hold: ["проведение", [7, 6]],
    cancel: ["отмена", [7, 1]],
    annul: ["аннуляция", [2]],
    reject: ["отклонение", [5]],
    resume: ["возобновление", [1]],
    restore: ["восстановление", [1]],
    reset: ["сброс", [1]],
    send: ["отправка контрагенту", []],
    download: ["скачивание", []],
    print: ["печать", []],
    change: ["корректировка", []],
  },


// ========================================  -- BASE_DOCUMENT_ACTIONS --  ======================================== 

  BASE_DOCUMENT_ACTIONS:{
    3: ["remove", "accept", "submit", "hold"],
    1: ["submit", "hold", "annul"],
    8: ["hold", "annul", "reject", "reset"],
    4: ["hold", "annul", "reject", "reset"],
    5: ["annul", "resume"],
    7: ["hold", "cancel"],
    6: ["cancel"],
    2: ["restore"],
  },


// ========================================  -- PRINCIPAL_REPORT_ACTIONS --  ======================================== 

  PRINCIPAL_REPORT_ACTIONS:{
    3: ["remove", "accept", "submit", "hold", "send", "change", "download", "print"],
    1: ["submit", "hold", "annul", "change", "download", "send", "print"],
    4: ["hold", "annul", "reject", "reset", "download", "print"],
    5: ["annul", "resume", "change", "download", "print"],
    6: ["cancel", "download", "print", "send"],
    2: ["restore", "change", "download", "print"],
  },


// ========================================  -- TOUR_STATUS_DESCRIPTION --  ======================================== 

  TOUR_STATUS_DESCRIPTION:{
    1: "Черновик, идут переговоры и бронирования",
    2: "Тур согласован с заказчиком, ожидаются оплаты",
    5: "Тур оплачен заказчиком и исполняется",
    9: "Турист получил все услуги. Получить закрывающие документы.",
    6: "Архив, работа по туру завершена",
    7: "Тур отменен заказчиком/агентом. Менеджер должен выполнить аннуляцию связанных броней",
    8: "Аннулировано. Тур аннулирован, вся работа по нему прекращена.",
  },


// ========================================  -- ADMIN_STATUS_FILTER --  ======================================== 

  ADMIN_STATUS_FILTER_NEW:5,
  ADMIN_STATUS_FILTER_ACTIVE:1,
  ADMIN_STATUS_FILTER_REFUSED:3,
  ADMIN_STATUS_FILTER_DUPLICATES:4,
  ADMIN_STATUS_FILTER_ARCHIVED:2,
  ADMIN_STATUS_FILTER_SUCCESS:6,
  ADMIN_STATUS_FILTER_ALL:0,

  ADMIN_STATUS_FILTER_TITLES:{
    5:"Новые",
    1:"В работе",
    3:"Отказы",
    4:"Дубликаты",
    2:"Архив",
    6:"Тур создан",
    0:"Все",
  },


// ========================================  -- AGENCY_CONTRACT_SETTLEMENT_METHOD --  ======================================== 

  AGENCY_CONTRACT_SETTLEMENT_METHOD_FULL_SUM:0,
  AGENCY_CONTRACT_SETTLEMENT_METHOD_SUB_COMMISSION:1,

  AGENCY_CONTRACT_SETTLEMENT_METHOD_TITLES:{
    0:"Полная сумма",
    1:"За вычетом агентского вознаграждения",
  },


// ========================================  -- AGENCY_ENTRY_OPCODE --  ======================================== 

  AGENCY_ENTRY_OPCODE_CUSTOM:1,
  AGENCY_ENTRY_OPCODE_STORNO:2,
  AGENCY_ENTRY_OPCODE_INCOME:3,
  AGENCY_ENTRY_OPCODE_CREDIT:4,
  AGENCY_ENTRY_OPCODE_REPAYMENT:5,
  AGENCY_ENTRY_OPCODE_BLOCKING:6,
  AGENCY_ENTRY_OPCODE_REFUND:7,
  AGENCY_ENTRY_OPCODE_FAULTY_DEPOSIT:8,
  AGENCY_ENTRY_OPCODE_UNBLOCKING:9,
  AGENCY_ENTRY_OPCODE_BOOKING:10,
  AGENCY_ENTRY_OPCODE_CANCEL_BOOKING:11,
  AGENCY_ENTRY_OPCODE_REFUND_DECISION:12,
  AGENCY_ENTRY_OPCODE_REFUND_BLOCKED_DECISION:13,
  AGENCY_ENTRY_OPCODE_INCOME_FOR_REPAYMENT:14,
  AGENCY_ENTRY_OPCODE_SERVICE_PROVIDED:15,
  AGENCY_ENTRY_OPCODE_NULL:16,

  AGENCY_ENTRY_OPCODE_TITLES:{
    1:"Вручную указанные поля для бух. проводки",
    2:"Отмена любой предыдущей операции",
    3:"Поступления денежных средств от заказчика",
    4:"Предоставление кредита заказчику",
    5:"Возврат кредита заказчиком",
    6:"Блокировка денежных средств заказчика",
    7:"Возврат денежных средств заказчику",
    8:"Ошибочное зачисление",
    9:"Разблокировка денежных средств заказчика",
    10:"Бронирование",
    11:"Отмена бронирования",
    12:"Решение о возврате денежных средств заказчику",
    13:"Решение о возврате заблокированных денежных средств заказчику",
    14:"Зачисление в счет погашения кредита",
    15:"Оказаны услуги",
    16:"Нулевая проводка",
  },


// ========================================  -- AGENCY_PAYMENT_TYPE --  ======================================== 

  AGENCY_PAYMENT_TYPE_PAYMENT:1,
  AGENCY_PAYMENT_TYPE_REFUND:2,

  AGENCY_PAYMENT_TYPE_TITLES:{
    1:"оплата",
    2:"возврат",
  },


// ========================================  -- AGENT_SALARY_ACCRUAL_STATUS --  ======================================== 

  AGENT_SALARY_ACCRUAL_STATUS_DRAFT:1,
  AGENT_SALARY_ACCRUAL_STATUS_APPROVED:2,

  AGENT_SALARY_ACCRUAL_STATUS_TITLES:{
    1:"Черновик",
    2:"Утверждено",
  },


// ========================================  -- AGENT_STATUS_FILTER --  ======================================== 

  AGENT_STATUS_FILTER_ACTIVE:1,
  AGENT_STATUS_FILTER_ARCHIVED:2,
  AGENT_STATUS_FILTER_REFUSED:3,
  AGENT_STATUS_FILTER_ALL:0,

  AGENT_STATUS_FILTER_TITLES:{
    1:"В работе",
    2:"Архив",
    3:"Отказы",
    0:"Все",
  },


// ========================================  -- BACK_CALL_RESPONSE_TYPE --  ======================================== 

  BACK_CALL_RESPONSE_TYPE_BACKCALL_SUCCESS:0,
  BACK_CALL_RESPONSE_TYPE_BACKCALL_NO_MANAGER:1,
  BACK_CALL_RESPONSE_TYPE_BACKCALL_BAD_PHONE:2,
  BACK_CALL_RESPONSE_TYPE_BACKCALL_BL_PHONE:3,
  BACK_CALL_RESPONSE_TYPE_BACKCALL_BL_IP:4,
  BACK_CALL_RESPONSE_TYPE_BACKCALL_FAILED:5,

  BACK_CALL_RESPONSE_TYPE_TITLES:{
    0:"успешно",
    1:"менеджеров нет на месте",
    2:"запрос на иностранный/неверный номер",
    3:"черный список номеров",
    4:"черный список IP",
    5:"запрос не удался",
  },


// ========================================  -- BANK_ACCOUNT --  ======================================== 

  BANK_ACCOUNT_RUSSIAN_BANK:1,
  BANK_ACCOUNT_RUSSIAN_TREASURY:2,
  BANK_ACCOUNT_OTHER:3,

  BANK_ACCOUNT_TITLES:{
    1:"Российский Банк",
    2:"Казначейство",
    3:"Что-то другое",
  },


// ========================================  -- BOOKING_RESPONSE_PRICE_TYPE --  ======================================== 

  BOOKING_RESPONSE_PRICE_TYPE_TOTAL_AMOUNT_FOR_ALL:1,
  BOOKING_RESPONSE_PRICE_TYPE_TOTAL_AMOUNT_PER_TOURIST:2,
  BOOKING_RESPONSE_PRICE_TYPE_DAY_AMOUNT_FOR_ALL:3,
  BOOKING_RESPONSE_PRICE_TYPE_DAY_AMOUNT_PER_TOURIST:4,

  BOOKING_RESPONSE_PRICE_TYPE_TITLES:{
    1:"Общая стоимость для всех",
    2:"Общая стоимость по каждому туристу",
    3:"Стоимость за день для всех туристов",
    4:"Стоимость за день по каждому туристу",
  },


// ========================================  -- BOOKING_STATUS --  ======================================== 

  BOOKING_STATUS_TEMPORARY:0,
  BOOKING_STATUS_DRAFT:1,
  BOOKING_STATUS_PLACES_REQUEST_CREATED:2,
  BOOKING_STATUS_PLACES_REQUEST_SENT:3,
  BOOKING_STATUS_AVAILABLE:4,
  BOOKING_STATUS_INQUIRE_CREATED:5,
  BOOKING_STATUS_INQUIRE_SENT:6,
  BOOKING_STATUS_BOOKED:7,
  BOOKING_STATUS_PAYED:8,
  BOOKING_STATUS_STARTED:9,
  BOOKING_STATUS_ARCHIVED:10,
  BOOKING_STATUS_CANCEL_REQUEST_SENT:11,
  BOOKING_STATUS_CANCELLED:12,
  BOOKING_STATUS_SWAP_CREATED:13,
  BOOKING_STATUS_SWAP_TARGET:14,
  BOOKING_STATUS_SWAP_SENT:15,
  BOOKING_STATUS_REVOKE_CREATED:16,
  BOOKING_STATUS_REVOKE_SENT:17,
  BOOKING_STATUS_INVOICE_PENDING:18,
  BOOKING_STATUS_DIRECT_BOOKED:114,
  BOOKING_STATUS_DIRECT_PAYED:115,
  BOOKING_STATUS_DIRECT_CLOSED:116,

  BOOKING_STATUS_TITLES:{
    0:"Временный",
    1:"Черновик",
    2:"Запрос мест",
    3:"Запрос мест выслан",
    4:"Доступно",
    5:"Запрос брони",
    6:"Запрос брони выслан",
    7:"Забронировано",
    8:"Оплачено",
    9:"Исполняется",
    10:"Архив",
    11:"Запрос на отмену выслан",
    12:"Отменено",
    13:"Запрос изменения",
    14:"Цель для запроса изменения",
    15:"Запрос изменения выслан",
    16:"Отказ от брони",
    17:"Запрос на отказ выслан",
    18:"Ожидается счет",
    114:"Забронировано (прямая оплата)",
    115:"Оплачено (прямая оплата)",
    116:"Закрыто (прямая оплата)",
  },


// ========================================  -- BOOKING_XSTATUS --  ======================================== 

  BOOKING_XSTATUS_ALL:1,
  BOOKING_XSTATUS_CANCELLED:2,
  BOOKING_XSTATUS_BOOKED:3,
  BOOKING_XSTATUS_ACTIVE:4,
  BOOKING_XSTATUS_NEED_ACTION:5,

  BOOKING_XSTATUS_TITLES:{
    1:"Все",
    2:"Отмененные",
    3:"Забронировано",
    4:"В работе",
    5:"Требуют действия",
  },


// ========================================  -- CHAT_TYPE --  ======================================== 

  CHAT_TYPE_LEAD:1,
  CHAT_TYPE_CUSTOMER:2,
  CHAT_TYPE_PUBLIC:3,
  CHAT_TYPE_SYSTEM:4,
  CHAT_TYPE_PRIVATE:5,
  CHAT_TYPE_TOUR:6,

  CHAT_TYPE_TITLES:{
    1:"чат с лидом",
    2:"чат с заказчиком (по туру)",
    3:"групповой чат",
    4:"чат с системой",
    5:"приватный чат",
    6:"чат по туру",
  },


// ========================================  -- COMMENT_TYPE --  ======================================== 

  COMMENT_TYPE_PRIVATE:1,
  COMMENT_TYPE_COMPANY:2,
  COMMENT_TYPE_PUBLIC:3,
  COMMENT_TYPE_RESTRICTED:4,
  COMMENT_TYPE_INTERNAL:5,
  COMMENT_TYPE_RESPONSE:6,
  COMMENT_TYPE_PROTECTED:7,

  COMMENT_TYPE_TITLES:{
    1:"Комментарий доступен только автору комментария",
    2:"Комментарий доступен всем сотрудникам компании",
    3:"Комментарий доступен всем у кого есть доступ к объекту",
    4:"Комментарий доступен автору, системе и владельцу объекта, и администрации компании",
    5:"Комментарий доступен всем пользователям кроме заказчиков, у которых есть доступ к объекту",
    6:"Отзыв",
    7:"Комментарий доступен только автору, владельцу объекта и администрации компании",
  },


// ========================================  -- CREATE_USER_EX_ERROR --  ======================================== 

  CREATE_USER_EX_ERROR_USER_EXISTS:1,
  CREATE_USER_EX_ERROR_ROLE_EXISTS:2,

  CREATE_USER_EX_ERROR_TITLES:{
    1:"Такой пользователь уже зарегистрирован. Вы можете отправить ему \"Приглашение\"",
    2:"Такая роль у данного пользователя уже есть",
  },


// ========================================  -- DOCUMENT_STATE --  ======================================== 

  DOCUMENT_STATE_DRAFT:3,
  DOCUMENT_STATE_NEW:1,
  DOCUMENT_STATE_PROGRESS:8,
  DOCUMENT_STATE_SUBMITTED:4,
  DOCUMENT_STATE_REJECTED:5,
  DOCUMENT_STATE_PARTIAL:7,
  DOCUMENT_STATE_HELD:6,
  DOCUMENT_STATE_ANNULLED:2,

  DOCUMENT_STATE_TITLES:{
    3:"черновик",
    1:"новый",
    8:"обработан частично",
    4:"обработан",
    5:"отклонен",
    7:"проведен частично",
    6:"проведен",
    2:"аннулирован",
  },


// ========================================  -- DOCUMENT_TEMPLATE_FORMAT --  ======================================== 

  DOCUMENT_TEMPLATE_FORMAT_MARKDOWN:1,
  DOCUMENT_TEMPLATE_FORMAT_HTML:2,
  DOCUMENT_TEMPLATE_FORMAT_WWW:3,

  DOCUMENT_TEMPLATE_FORMAT_TITLES:{
    1:"Шаблон в формате «MarkDown»",
    2:"Шаблон в формате HTML",
    3:"Шаблон в формате HTML для WWW (веб-страница целиком)",
  },


// ========================================  -- DOCUMENT_TEMPLATE_TYPE --  ======================================== 

  DOCUMENT_TEMPLATE_TYPE_SYSTEM_CONTRACT_AGENCY:1,
  DOCUMENT_TEMPLATE_TYPE_SYSTEM_CONTRACT_HOTELIER:2,
  DOCUMENT_TEMPLATE_TYPE_SYSTEM_COMMISSIONER_REPORT:3,
  DOCUMENT_TEMPLATE_TYPE_AGENCY_CONTRACT:4,
  DOCUMENT_TEMPLATE_TYPE_AGENCY_CONTRACT_ORG:5,
  DOCUMENT_TEMPLATE_TYPE_AGENCY_RECEIPT:6,
  DOCUMENT_TEMPLATE_TYPE_AGENCY_RECEIPT_ORG:7,
  DOCUMENT_TEMPLATE_TYPE_PAYMENT_OFFSET_LETTER:8,
  DOCUMENT_TEMPLATE_TYPE_GENERIC:9,
  DOCUMENT_TEMPLATE_TYPE_VOUCHER:10,
  DOCUMENT_TEMPLATE_TYPE_BANNER:11,
  DOCUMENT_TEMPLATE_TYPE_CHECKIN:12,
  DOCUMENT_TEMPLATE_TYPE_TRANSFER_BOOKING_TO_CUSTOMER:13,
  DOCUMENT_TEMPLATE_TYPE_TRANSFER_BOOKING_TO_DRIVER:14,

  DOCUMENT_TEMPLATE_TYPE_TITLES:{
    1:"Договор системы с агентством",
    2:"Договор системы с отелем",
    3:"Отчет комиссионера",
    4:"Договор агентства с заказчиком",
    5:"Договор агентства с заказчиком - юр. лицом",
    6:"Счет агентства заказчику физлицу",
    7:"Счет агентства заказчику юр. лицу",
    8:"Заявление о возврате платежа",
    9:"Служебный тестовый шаблон",
    10:"Путевка",
    11:"Баннер для встречи",
    12:"Лист заезжающего",
    13:"Трансфер заказчику",
    14:"Трансфер водителю",
  },


// ========================================  -- ENTRY_OPCODE --  ======================================== 

  ENTRY_OPCODE_CUSTOM:10,
  ENTRY_OPCODE_STORNO:20,
  ENTRY_OPCODE_INCOME:100,
  ENTRY_OPCODE_INCOME_BLOCKED:110,
  ENTRY_OPCODE_INCOME_CREDIT:120,
  ENTRY_OPCODE_INCOME_PENALTY:125,
  ENTRY_OPCODE_OUTCOME:130,
  ENTRY_OPCODE_OUTCOME_FEE:140,
  ENTRY_OPCODE_S_OUTCOME:200,
  ENTRY_OPCODE_S_OUTCOME_MULCT:210,
  ENTRY_OPCODE_S_INCOME:220,
  ENTRY_OPCODE_S_INCOME_REWARD:230,
  ENTRY_OPCODE_BLOCK:300,
  ENTRY_OPCODE_UNBLOCK:310,
  ENTRY_OPCODE_CREDIT:400,
  ENTRY_OPCODE_CREDIT_FEE:410,
  ENTRY_OPCODE_RE_CREDIT:420,
  ENTRY_OPCODE_RE_CREDIT_FEE:430,
  ENTRY_OPCODE_BOOK:500,
  ENTRY_OPCODE_BOOK_FEE_CREDIT:505,
  ENTRY_OPCODE_CANCEL_BOOK:510,
  ENTRY_OPCODE_CANCEL_BOOK_FEE_CREDIT:515,
  ENTRY_OPCODE_FINISH_BOOK:520,
  ENTRY_OPCODE_FEE:530,
  ENTRY_OPCODE_FEE_CREDIT:535,
  ENTRY_OPCODE_PENALTY:540,
  ENTRY_OPCODE_REFUND:550,
  ENTRY_OPCODE_REFUND_BLOCKED:555,
  ENTRY_OPCODE_RESERVE:600,
  ENTRY_OPCODE_RESERVE_DEBIT_REWARD:605,
  ENTRY_OPCODE_CANCEL_RESERVE:610,
  ENTRY_OPCODE_CANCEL_RESERVE_DEBIT_REWARD:615,
  ENTRY_OPCODE_FINISH_RESERVE:620,
  ENTRY_OPCODE_REWARD:630,
  ENTRY_OPCODE_REWARD_DEBIT_REWARD:635,
  ENTRY_OPCODE_MULCT:640,
  ENTRY_OPCODE_REPAYMENT:650,
  ENTRY_OPCODE_DEBIT:700,
  ENTRY_OPCODE_DEBIT_REWARD:710,
  ENTRY_OPCODE_RE_DEBIT:720,
  ENTRY_OPCODE_RE_DEBIT_FEE:730,
  ENTRY_OPCODE_NULL:740,

  ENTRY_OPCODE_TITLES:{
    10:"Операция введённая вручную",
    20:"Операция сторно",
    100:"Поступления денежных средств от агентства",
    110:"Поступления денежных средств от агентства с их блокировкой",
    120:"Поступления денежных средств от агентства в счёт погашения кредита",
    125:"Поступления в счёт оплаты штрафов от агентства",
    130:"Возврат агентству",
    140:"Оплата вознаграждения агентству",
    200:"Оплата поставщику",
    210:"Оплата штрафа поставщику",
    220:"Возврат от поставщика",
    230:"Оплата вознаграждения от поставщика",
    300:"Блокировка",
    310:"Отмена блокировки",
    400:"Предоставление кредита агентству",
    410:"Предоставление кредита агентству (в пределах агентского вознаграждения)",
    420:"Возврат кредита от агентства",
    430:"Возврат кредита от агентства (в пределах агентского вознаграждения)",
    500:"Бронирование агентством",
    505:"Бронирование агентством в кредит от вознаграждения",
    510:"Отмена бронирования агентством",
    515:"Отмена бронирования агентством в кредит от вознаграждения",
    520:"Списание средств за оказание услуг",
    530:"Начисление вознаграждения агентству",
    535:"Начисление вознаграждения агентству списанием кредита от вознаграждения",
    540:"Выставление штрафа агентству",
    550:"Решение о возврате",
    555:"Решение о возврате заблокированных средств",
    600:"Бронирование у поставщика",
    605:"Бронирование у поставщика в пределах агентского вознаграждения",
    610:"Отмена бронирования у поставщика",
    615:"Отмена бронирования у поставщикав пределах агентского вознаграждения",
    620:"Списание средств у поставщика за оказание услуг",
    630:"Начисление вознаграждения поставщиком",
    635:"Начисление вознаграждения поставщиком",
    640:"Выставление штрафа поставщиком",
    650:"Решение о возврате поставщиком",
    700:"Предоставление кредита поставщиком",
    710:"Предоставление кредита поставщиком (в пределах агентского вознаграждения)",
    720:"Возврат кредита от поставщика",
    730:"Возврат кредита поставщику (в пределах агентского вознаграждения)",
    740:"Пустая операция",
  },


// ========================================  -- HOTELIER_CONTRACT_CALCULATION_TYPE --  ======================================== 

  HOTELIER_CONTRACT_CALCULATION_TYPE_CHECK_IN:1,
  HOTELIER_CONTRACT_CALCULATION_TYPE_DAILY:2,

  HOTELIER_CONTRACT_CALCULATION_TYPE_TITLES:{
    1:"расчет стоимости по цене на дату заезда",
    2:"расчет стоимости по цене каждого дня",
  },


// ========================================  -- HOTELIER_CONTRACT_INVOICE_TYPE --  ======================================== 

  HOTELIER_CONTRACT_INVOICE_TYPE_INCLUDE_REWARD:1,
  HOTELIER_CONTRACT_INVOICE_TYPE_DOESNT_INCLUDE_REWARD:2,

  HOTELIER_CONTRACT_INVOICE_TYPE_TITLES:{
    1:"включает вознаграждение",
    2:"не включает вознаграждение",
  },


// ========================================  -- HOTELIER_CONTRACT_PAYMENTSPLITMODE --  ======================================== 

  HOTELIER_CONTRACT_PAYMENTSPLITMODE_SPLIT_PER_INVOICE:1,
  HOTELIER_CONTRACT_PAYMENTSPLITMODE_SPLIT_PER_CONTRACT:2,

  HOTELIER_CONTRACT_PAYMENTSPLITMODE_TITLES:{
    1:"Разносить по счетам",
    2:"Разносить на договор",
  },


// ========================================  -- HOTELIER_CONTRACT_PAYMENT_TYPE --  ======================================== 

  HOTELIER_CONTRACT_PAYMENT_TYPE_WITH_REWARD:1,
  HOTELIER_CONTRACT_PAYMENT_TYPE_WITHOUT_REWARD:2,

  HOTELIER_CONTRACT_PAYMENT_TYPE_TITLES:{
    1:"полной суммы",
    2:"за вычетом вознаграждения",
  },


// ========================================  -- HOTELIER_CONTRACT_REWARD_TYPE --  ======================================== 

  HOTELIER_CONTRACT_REWARD_TYPE_CHECKIN:1,
  HOTELIER_CONTRACT_REWARD_TYPE_CHECKOUT:2,
  HOTELIER_CONTRACT_REWARD_TYPE_DAILY:3,
  HOTELIER_CONTRACT_REWARD_TYPE_BUY:4,

  HOTELIER_CONTRACT_REWARD_TYPE_TITLES:{
    1:"по дате заезда",
    2:"по дате выезда",
    3:"с учетом переходного периода",
    4:"по дате покупки",
  },


// ========================================  -- HOTEL_DISPLAY --  ======================================== 

  HOTEL_DISPLAY_HOTEL:1,
  HOTEL_DISPLAY_CAMP:2,
  HOTEL_DISPLAY_CRUISE:3,
  HOTEL_DISPLAY_SANATORIUM:4,

  HOTEL_DISPLAY_TITLES:{
    1:"Отель",
    2:"Лагерь со сменами",
    3:"Круиз",
    4:"Санаторий",
  },


// ========================================  -- HOTEL_MODE --  ======================================== 

  HOTEL_MODE_MANAGED:1,
  HOTEL_MODE_EXTERN_HMS:2,

  HOTEL_MODE_TITLES:{
    1:"полный контроль",
    2:"внешняя САУ",
  },


// ========================================  -- IDENTITY_TASK_STATUS --  ======================================== 

  IDENTITY_TASK_STATUS_SUCCESS:1,
  IDENTITY_TASK_STATUS_FAILURE:2,
  IDENTITY_TASK_STATUS_CLOSED:3,
  IDENTITY_TASK_STATUS_RUNNING:4,

  IDENTITY_TASK_STATUS_TITLES:{
    1:"Выполнена успешно",
    2:"Выполнена неуспешно",
    3:"Закрыта",
    4:"Запущена",
  },


// ========================================  -- IMPORT_FILE_STATE --  ======================================== 

  IMPORT_FILE_STATE_PROCESSED:1,
  IMPORT_FILE_STATE_FAILED:2,
  IMPORT_FILE_STATE_ACCEPTED:3,
  IMPORT_FILE_STATE_CANCELLED:4,
  IMPORT_FILE_STATE_NEW:5,
  IMPORT_FILE_STATE_DRAFT:6,

  IMPORT_FILE_STATE_TITLES:{
    1:"обработан",
    2:"не обработан",
    3:"принят",
    4:"отменено",
    5:"новый",
    6:"черновик",
  },


// ========================================  -- IMPORT_FILE_TYPE --  ======================================== 

  IMPORT_FILE_TYPE_IMPORT_PAYMENTS:1,
  IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS:2,

  IMPORT_FILE_TYPE_TITLES:{
    1:"импорт платежей",
    2:"импорт платежей покупателей",
  },


// ========================================  -- LEAD_RESOLUTION --  ======================================== 

  LEAD_RESOLUTION_VOUCHER:1,
  LEAD_RESOLUTION_DUPLICATE:2,
  LEAD_RESOLUTION_REFUSED:3,
  LEAD_RESOLUTION_BAD_DATA:4,
  LEAD_RESOLUTION_NO_PLACES:5,
  LEAD_RESOLUTION_SPAM:6,
  LEAD_RESOLUTION_PRIVILEGES:7,
  LEAD_RESOLUTION_HAS_BOOK:8,
  LEAD_RESOLUTION_ILLNESS:9,
  LEAD_RESOLUTION_CHANGE:10,
  LEAD_RESOLUTION_BOOK_OTHER:11,
  LEAD_RESOLUTION_BOOK_DIRECT:12,
  LEAD_RESOLUTION_UNDEF:13,
  LEAD_RESOLUTION_AUTO_DUPLICATE:14,
  LEAD_RESOLUTION_AUTO_SPAM:15,

  LEAD_RESOLUTION_TITLES:{
    1:"Оформлена путевка",
    2:"Дубликат",
    3:"Отказ",
    4:"Некорректные данные",
    5:"Нет мест",
    6:"Спам",
    7:"Есть уже льготная путевка",
    8:"Есть бронь в объекте отдыха",
    9:"Отказ по болезни",
    10:"Отказ передумал ехать",
    11:"Отказ забронировал в другом агентстве",
    12:"Отказ забронировал напрямую в объекте",
    13:"Не определено",
    14:"Дубликат (автоматически)",
    15:"Спам (автоматически)",
  },


// ========================================  -- LEAD_SOURCE --  ======================================== 

  LEAD_SOURCE_MANUALLY:1,
  LEAD_SOURCE_SITE:2,
  LEAD_SOURCE_MESSENGER:3,
  LEAD_SOURCE_PHONE:4,
  LEAD_SOURCE_WHATSAPP:5,
  LEAD_SOURCE_TELEGRAM:6,
  LEAD_SOURCE_BACK_CALL:7,
  LEAD_SOURCE_LEADBACK:8,
  LEAD_SOURCE_OLD_CLIENT:9,
  LEAD_SOURCE_RECOMMENDATION:10,
  LEAD_SOURCE_OTHER:100,

  LEAD_SOURCE_TITLES:{
    1:"Вручную",
    2:"Заявка с сайта",
    3:"Веб чат",
    4:"Телефон",
    5:"Whatsappp",
    6:"Telegram",
    7:"Обратный звонок",
    8:"Leadback",
    9:"Старый клиент",
    10:"По рекомендации",
    100:"Прочее",
  },


// ========================================  -- LEAD_STATUS --  ======================================== 

  LEAD_STATUS_NEW:1,
  LEAD_STATUS_TAKEN:2,
  LEAD_STATUS_POSTPONED:3,
  LEAD_STATUS_ARCHIVED:4,
  LEAD_STATUS_DRAFT:5,
  LEAD_STATUS_RESERVED:6,
  LEAD_STATUS_NEED_PINCODE:7,

  LEAD_STATUS_TITLES:{
    1:"Новый",
    2:"В обработке",
    3:"Отложена",
    4:"В архиве",
    5:"Черновик",
    6:"В резерве",
    7:"Требуется подтверждение телефона заказчика",
  },


// ========================================  -- LEAD_X_STATUS --  ======================================== 

  LEAD_X_STATUS_NEW:5,
  LEAD_X_STATUS_ACTIVE:1,
  LEAD_X_STATUS_REFUSED:3,
  LEAD_X_STATUS_DUPLICATES:4,
  LEAD_X_STATUS_ARCHIVED:2,
  LEAD_X_STATUS_SUCCESS:6,
  LEAD_X_STATUS_ALL:0,
  LEAD_X_STATUS_SPAM:7,

  LEAD_X_STATUS_TITLES:{
    5:"Новые",
    1:"В работе",
    3:"Отказы",
    4:"Дубликаты",
    2:"Архив",
    6:"Тур создан",
    0:"Все",
    7:"Спам",
  },


// ========================================  -- LOCATION_ADDR_TYPE --  ======================================== 

  LOCATION_ADDR_TYPE_AREA:0,
  LOCATION_ADDR_TYPE_REGION:1,
  LOCATION_ADDR_TYPE_COUNTRY:2,
  LOCATION_ADDR_TYPE_STATE:3,
  LOCATION_ADDR_TYPE_CITY:4,
  LOCATION_ADDR_TYPE_TERRITORY:5,
  LOCATION_ADDR_TYPE_STREET:6,
  LOCATION_ADDR_TYPE_ADDRESS:7,

  LOCATION_ADDR_TYPE_TITLES:{
    0:"Часть света",
    1:"Регион",
    2:"Страна",
    3:"Область, штат",
    4:"Населенный пункт",
    5:"Территория",
    6:"Улица",
    7:"Адрес",
  },


// ========================================  -- MEDIA_FIXED_TITLES --  ======================================== 

  MEDIA_FIXED_TITLES_RENDER:"render",
  MEDIA_FIXED_TITLES_STAMP_IMAGE:"stamp_image",
  MEDIA_FIXED_TITLES_TEMPORARY:"temporary",
  MEDIA_FIXED_TITLES_USER_PHOTO:"user_photo",
  MEDIA_FIXED_TITLES_USER_SIGNATURE:"user_signature",

  MEDIA_FIXED_TITLES_TITLES:{
    render:"Печатная форма документа",
    stamp_image:"Фото печати",
    temporary:"Временный файл",
    user_photo:"Фото пользователя",
    user_signature:"Подпись пользователя",
  },


// ========================================  -- OCCUPATION_STATUS --  ======================================== 

  OCCUPATION_STATUS_DRAFT:1,
  OCCUPATION_STATUS_CHECK_IN:2,
  OCCUPATION_STATUS_CHECK_OUT:3,
  OCCUPATION_STATUS_CANCELLED:4,

  OCCUPATION_STATUS_TITLES:{
    1:"Черновик",
    2:"Заехал",
    3:"Выехал",
    4:"отменено",
  },


// ========================================  -- OPERATION_TYPE --  ======================================== 

  OPERATION_TYPE_SEND_PLACES_REQUEST:2,
  OPERATION_TYPE_PERSON_HAS_CHANGED:10,
  OPERATION_TYPE_BOOKING_INQUIRE_RESPONSE:12,
  OPERATION_TYPE_TOUR_AGREE:115,
  OPERATION_TYPE_TOUR_CANCEL:116,
  OPERATION_TYPE_TOUR_CLOSE:117,
  OPERATION_TYPE_TOUR_REVOKE:118,
  OPERATION_TYPE_TOUR_START:119,
  OPERATION_TYPE_TOUR_ANNUL:120,
  OPERATION_TYPE_TOUR_REJECT:121,
  OPERATION_TYPE_TOUR_SET_CORRECTIONS:123,
  OPERATION_TYPE_TOUR_RESET_CORRECTIONS:124,
  OPERATION_TYPE_TOUR_SET_DISCOUNT_PERCENT:125,
  OPERATION_TYPE_TOUR_FINISH:126,
  OPERATION_TYPE_TOUR_RECEIVE_CHECKIN_PAYMENT:127,
  OPERATION_TYPE_TOUR_CANCEL_CHECKIN_PAYMENT:128,
  OPERATION_TYPE_BOOKING_REQUEST_CREATE:221,
  OPERATION_TYPE_BOOKING_REQUEST_SEND:222,
  OPERATION_TYPE_BOOKING_AVAILABLE:223,
  OPERATION_TYPE_BOOKING_UNAVAILABLE:224,
  OPERATION_TYPE_BOOKING_INQUIRE_CREATE:225,
  OPERATION_TYPE_BOOKING_INQUIRE_SEND:226,
  OPERATION_TYPE_BOOKING_BOOK:227,
  OPERATION_TYPE_BOOKING_BOOK_PAYMENT_TO_OBJECT:258,
  OPERATION_TYPE_BOOKING_REFUSE:228,
  OPERATION_TYPE_BOOKING_SWAP_CREATE:229,
  OPERATION_TYPE_BOOKING_SWAP_SEND:230,
  OPERATION_TYPE_BOOKING_SWAP_REFUSE:231,
  OPERATION_TYPE_BOOKING_SWAP_BOOK:232,
  OPERATION_TYPE_BOOKING_REVOKE_CREATE:233,
  OPERATION_TYPE_BOOKING_REVOKE_SEND:234,
  OPERATION_TYPE_BOOKING_REVOKE_APPROVED:235,
  OPERATION_TYPE_BOOKING_CANCEL:236,
  OPERATION_TYPE_BOOKING_RESTORE:237,
  OPERATION_TYPE_BOOKING_PAY:238,
  OPERATION_TYPE_BOOKING_START:239,
  OPERATION_TYPE_BOOKING_ARCHIVE:240,
  OPERATION_TYPE_BOOKING_INVOICE_PENDING:241,
  OPERATION_TYPE_BOOKING_TO_QUOTA:242,
  OPERATION_TYPE_BOOKING_GET_BOOKING_COST:256,
  OPERATION_TYPE_TRANSFER_BOOKING_REQUEST:243,
  OPERATION_TYPE_TRANSFER_BOOKING_CANCEL:244,
  OPERATION_TYPE_TRANSFER_BOOKING_CONFIRM:245,
  OPERATION_TYPE_TRANSFER_BOOKING_REJECT:246,
  OPERATION_TYPE_TRANSFER_BOOKING_AGREE:247,
  OPERATION_TYPE_TRANSFER_BOOKING_DONE:248,
  OPERATION_TYPE_TRANSFER_BOOKING_CLOSE:249,
  OPERATION_TYPE_TRANSFER_BOOKING_APPOINT:250,
  OPERATION_TYPE_TRANSFER_BOOKING_UNAPPOINT:251,
  OPERATION_TYPE_TRANSFER_BOOKING_SEND_TO_CUSTOMER:252,
  OPERATION_TYPE_TRANSFER_BOOKING_SEND_TO_DRIVER:253,
  OPERATION_TYPE_TRANSFER_BOOKING_PRINT:254,
  OPERATION_TYPE_TRANSFER_BOOKING_SET_INSTRUCTIONS:255,
  OPERATION_TYPE_TRANSFER_BOOKING_ACCEPT:257,

  OPERATION_TYPE_TITLES:{
    2:"Отправка запроса наличия свободных мест в отель",
    10:"Персона изменена",
    12:"Получен ответ на запрос бронирования",
    115:"Согласование тура",
    116:"Отмена тура",
    117:"Закрытие тура",
    118:"Отказ от тура",
    119:"Старт тура",
    120:"Аннулирование тура",
    121:"Отмена тура",
    123:"Установка корректировок и скидок в туре",
    124:"Сброс корректировок и скидок в туре",
    125:"Установка одинаковой скидки в туре",
    126:"Завершение тура",
    127:"Прием отсроченного платежа по туру",
    128:"Отмена отсроченного платежа по туру",
    221:"Создание запроса на наличие свободных мест",
    222:"Отправка запроса наличия свободных мест",
    223:"Получено подтверждение наличия свободных мест",
    224:"Получен отказ наличия свободных мест",
    225:"Создание запроса на бронирование",
    226:"Отправка запроса на бронирование",
    227:"Бронирование",
    258:"Бронирование, если оплата в объект отдыха",
    228:"Не может быть забронировано",
    229:"Создание запроса на изменение брони",
    230:"Отправка запроса на изменение брони",
    231:"Бронь не может быть изменена",
    232:"Изменение брони",
    233:"Создание запроса на отказ от брони",
    234:"Отправка запроса на отказ от брони",
    235:"Отказ от брони утвержден",
    236:"Отмена брони",
    237:"Восстановление брони",
    238:"Оплата брони",
    239:"Начало оказания услуг",
    240:"Архивирование брони",
    241:"Ожидание счета для брони",
    242:"Перевод брони в квоту",
    256:"Расчет стоимости брони",
    243:"Выслать запрос",
    244:"Отмена бронирования трансфера",
    245:"Подтверждение бронирования трансфера",
    246:"Отклонение бронирования трансфера",
    247:"Согласование бронирования трансфера",
    248:"Трансфер выполнен",
    249:"Закрытие бронирования трансфера",
    250:"Назначение водителя",
    251:"Отмена назначения водителя",
    252:"Отправить заказчику и туристам",
    253:"Отправить водителю",
    254:"Распечатать",
    255:"Инструкции для водителя",
    257:"Принять трансфер к исполнению",
  },


// ========================================  -- PAYMENT_TYPE --  ======================================== 

  PAYMENT_TYPE_AGENCY_PENALTY:1,
  PAYMENT_TYPE_HOTELIER_MULCT:2,
  PAYMENT_TYPE_AGENCY:3,
  PAYMENT_TYPE_AGENCY_REFUND:4,
  PAYMENT_TYPE_AGENCY_FEE:5,
  PAYMENT_TYPE_HOTELIER:6,
  PAYMENT_TYPE_HOTELIER_REFUND:7,
  PAYMENT_TYPE_HOTELIER_REWARD:8,

  PAYMENT_TYPE_TITLES:{
    1:"оплата штрафа агентством системе",
    2:"оплата штрафа системой поставщику",
    3:"оплата агентством системе",
    4:"возврат системой агентству",
    5:"выплата вознаграждения системой агентству",
    6:"оплата системой поставщику",
    7:"возврат отелем системе",
    8:"выплата вознаграждения поставщиком системе",
  },


// ========================================  -- PRICE_STATE --  ======================================== 

  PRICE_STATE_AS_USUAL:1,
  PRICE_STATE_NOT_ALLOWED:2,
  PRICE_STATE_FOR_FREE:3,
  PRICE_STATE_FOR_FREE_NO_PLACE:4,
  PRICE_STATE_NULL:5,

  PRICE_STATE_TITLES:{
    1:"заполняется обычном порядке",
    2:"не допускается",
    3:"бесплатно",
    4:"бесплатно без места",
    5:"нет цены",
  },


// ========================================  -- PRINCIPAL_REPORT_STATE --  ======================================== 

  PRINCIPAL_REPORT_STATE_DRAFT:3,
  PRINCIPAL_REPORT_STATE_NEW:1,
  PRINCIPAL_REPORT_STATE_SUBMITTED:4,
  PRINCIPAL_REPORT_STATE_REJECTED:5,
  PRINCIPAL_REPORT_STATE_HELD:6,
  PRINCIPAL_REPORT_STATE_ANNULLED:2,

  PRINCIPAL_REPORT_STATE_TITLES:{
    3:"черновик",
    1:"новый",
    4:"на согласовании",
    5:"отклонен",
    6:"принят",
    2:"аннулирован",
  },


// ========================================  -- QRCODE_STATUS --  ======================================== 

  QRCODE_STATUS_NEW:0,
  QRCODE_STATUS_CREATED:1,
  QRCODE_STATUS_PAID:2,
  QRCODE_STATUS_REVERSED:3,
  QRCODE_STATUS_REFUNDED:4,
  QRCODE_STATUS_REVOKED:5,
  QRCODE_STATUS_DECLINED:6,
  QRCODE_STATUS_EXPIRED:7,
  QRCODE_STATUS_AUTHORIZED:8,
  QRCODE_STATUS_CONFIRMED:9,
  QRCODE_STATUS_ON_PAYMENT:10,
  QRCODE_STATUS_SEND:11,
  QRCODE_STATUS_PAYED:12,
  QRCODE_STATUS_FAILED:99,

  QRCODE_STATUS_TITLES:{
    0:"Новый",
    1:"Получен",
    2:"Оплачен",
    3:"REVERSED",
    4:"REFUNDED",
    5:"отменен",
    6:"отклонен",
    7:"истекло время оплаты",
    8:"AUTHORIZED",
    9:"CONFIRMED",
    10:"ON_PAYMENT",
    11:"Выслан",
    12:"Оплачен",
    99:"Ошибка",
  },


// ========================================  -- QRCODE_TYPE --  ======================================== 

  QRCODE_TYPE_SBER:0,
  QRCODE_TYPE_SBP:1,

  QRCODE_TYPE_TITLES:{
    0:"SberPay",
    1:"СБП",
  },


// ========================================  -- QUERY_STATUS --  ======================================== 

  QUERY_STATUS_NEW:1,
  QUERY_STATUS_SEND:2,
  QUERY_STATUS_SEND_ERROR:3,
  QUERY_STATUS_GOT_RESPONSE:4,
  QUERY_STATUS_CANCELLED:5,
  QUERY_STATUS_INVOICE_PENDING:6,

  QUERY_STATUS_TITLES:{
    1:"Запрос создан",
    2:"Запрос отправлен",
    3:"Ошибка отправки запроса",
    4:"Получен ответ",
    5:"Отменен",
    6:"Ожидание счета для брони",
  },


// ========================================  -- QUERY_TYPE --  ======================================== 

  QUERY_TYPE_PRICE:1,
  QUERY_TYPE_PLACES:2,
  QUERY_TYPE_BOOKING:3,
  QUERY_TYPE_CHANGE:4,
  QUERY_TYPE_CANCEL:5,
  QUERY_TYPE_SWAP:6,
  QUERY_TYPE_DOCUMENTS:51,

  QUERY_TYPE_TITLES:{
    1:"Запрос цен",
    2:"Запрос мест",
    3:"Запрос бронирования",
    4:"Запрос изменения брони",
    5:"Запрос отмены брони",
    6:"Запрос замены брони",
    51:"Запрос документов",
  },


// ========================================  -- QUESTION_TYPE --  ======================================== 

  QUESTION_TYPE_BOOL:1,
  QUESTION_TYPE_BUTTONS:2,
  QUESTION_TYPE_DB_CHOICE:3,
  QUESTION_TYPE_MULTIPLE_CHOICE:4,
  QUESTION_TYPE_MULTIPLE_DB_CHOICE:5,
  QUESTION_TYPE_INFO_BLOCK:6,
  QUESTION_TYPE_COMPOUND_QUESTION:7,
  QUESTION_TYPE_INPUT:8,

  QUESTION_TYPE_TITLES:{
    1:"Да/Нет",
    2:"Выбор из списка ответов (Отображается как список кнопок)",
    3:"Выбор из бд (Отображается как select)",
    4:"Множественный выбор (список) (Select с multi выбором)",
    5:"Множественный выбор (бд) (Select с multi выбором)",
    6:"Вопрос не выявлет какое либо знание",
    7:"Составной вопрос",
    8:"Вводятся какие то данные в инпут",
  },


// ========================================  -- REMINDER_AUTOMATION --  ======================================== 

  REMINDER_AUTOMATION_MANUAL:0,
  REMINDER_AUTOMATION_CONTACT_TO_LEAD:1,

  REMINDER_AUTOMATION_TITLES:{
    0:"Ручное управление",
    1:"Связаться с лидом",
  },


// ========================================  -- REMINDER_LEVEL --  ======================================== 

  REMINDER_LEVEL_CRITICAL:50,
  REMINDER_LEVEL_ERROR:40,
  REMINDER_LEVEL_WARNING:30,
  REMINDER_LEVEL_INFO:20,
  REMINDER_LEVEL_DEBUG:10,
  REMINDER_LEVEL_NOTSET:0,

  REMINDER_LEVEL_TITLES:{
    50:"Критический",
    40:"Ошибка",
    30:"Предупреждение",
    20:"Информация",
    10:"Отладка",
    0:"Не задано",
  },


// ========================================  -- REMINDER_SEVERITY --  ======================================== 

  REMINDER_SEVERITY_HIGH:0,
  REMINDER_SEVERITY_NORMAL:1,
  REMINDER_SEVERITY_LOW:2,

  REMINDER_SEVERITY_TITLES:{
    0:"Высокая",
    1:"Нормальная",
    2:"Низкая",
  },


// ========================================  -- REPORT_CATEGORY --  ======================================== 

  REPORT_CATEGORY_MANAGER_REPORTS:1,
  REPORT_CATEGORY_ACCOUNTANT_REPORTS:2,

  REPORT_CATEGORY_TITLES:{
    1:"Управленческие отчеты",
    2:"Бухгалтерские отчеты",
  },


// ========================================  -- REPORT_TYPE --  ======================================== 

  REPORT_TYPE_CUSTOM:0,
  REPORT_TYPE_INCOMING_LEADS:1,
  REPORT_TYPE_INCOMING_TOURS:2,
  REPORT_TYPE_ACTIVE_TOURS:3,
  REPORT_TYPE_BOOKING_TO_PAY:4,

  REPORT_TYPE_TITLES:{
    0:"Произвольный отчет",
    1:"Отчет по поступившим лидам",
    2:"Отчет по поступившим турам",
    3:"Отчет по активным турам",
    4:"Отчет по бронированиям к оплате",
  },


// ========================================  -- REPORT_COMPANY_TYPE --  ======================================== 

  REPORT_COMPANY_TYPE_SYSTEM:1,
  REPORT_COMPANY_TYPE_AGENCY:2,
  REPORT_COMPANY_TYPE_TRANSFER:3,

  REPORT_COMPANY_TYPE_TITLES:{
    1:"Для системы",
    2:"Для агентства",
    3:"Для трансфера",
  },


// ========================================  -- REPORT_STATUS --  ======================================== 

  REPORT_STATUS_PENDING:1,
  REPORT_STATUS_READY:2,
  REPORT_STATUS_ERROR:3,

  REPORT_STATUS_TITLES:{
    1:"В процессе",
    2:"Готов",
    3:"Ошибка",
  },


// ========================================  -- RESERVATION_STATUS --  ======================================== 

  RESERVATION_STATUS_DRAFT:1,
  RESERVATION_STATUS_ON_REQUEST:2,
  RESERVATION_STATUS_BOOKED:3,
  RESERVATION_STATUS_PAYED:4,
  RESERVATION_STATUS_CANCELLED:5,
  RESERVATION_STATUS_CHECKIN:6,

  RESERVATION_STATUS_TITLES:{
    1:"Черновик",
    2:"Под запрос",
    3:"Забронировано",
    4:"Оплачено",
    5:"Отменено",
    6:"Заезд",
  },


// ========================================  -- RESERVATION_TYPE --  ======================================== 

  RESERVATION_TYPE_BOOKING:1,
  RESERVATION_TYPE_QUOTA:2,
  RESERVATION_TYPE_RESERVATION:3,

  RESERVATION_TYPE_TITLES:{
    1:"Бронь",
    2:"Квота",
    3:"Резерв",
  },


// ========================================  -- ROOMFLAG_TYPE --  ======================================== 

  ROOMFLAG_TYPE_NEED_CLEAN:1,
  ROOMFLAG_TYPE_CLEAN:2,
  ROOMFLAG_TYPE_RED:3,
  ROOMFLAG_TYPE_GREEN:4,
  ROOMFLAG_TYPE_BLUE:5,
  ROOMFLAG_TYPE_YELLOW:6,
  ROOMFLAG_TYPE_CYAN:7,
  ROOMFLAG_TYPE_MAGENTA:8,

  ROOMFLAG_TYPE_TITLES:{
    1:"требует уборки",
    2:"убран",
    3:"красный",
    4:"зеленый",
    5:"синий",
    6:"желтый",
    7:"циан",
    8:"маджента",
  },


// ========================================  -- SITE_TYPE --  ======================================== 

  SITE_TYPE_AIRPORT:1,
  SITE_TYPE_RR_STATION:2,
  SITE_TYPE_BUS_STATION:3,
  SITE_TYPE_OTHER:200,

  SITE_TYPE_TITLES:{
    1:"Аэропорт",
    2:"Ж/Д вокзал",
    3:"Автостанция",
    200:"Иное",
  },


// ========================================  -- SPACE_TYPE --  ======================================== 

  SPACE_TYPE_BEDROOM:1,
  SPACE_TYPE_LIVING:2,
  SPACE_TYPE_HALL:3,
  SPACE_TYPE_KITCHEN:4,
  SPACE_TYPE_BATHROOM:5,
  SPACE_TYPE_DINING:6,

  SPACE_TYPE_TITLES:{
    1:"Спальня",
    2:"Гостиная",
    3:"Холл",
    4:"Кухня",
    5:"Ванная",
    6:"Столовая",
  },


// ========================================  -- STATUS_ONLINE_FILTER --  ======================================== 

  STATUS_ONLINE_FILTER_ONLINE:1,
  STATUS_ONLINE_FILTER_OFFLINE:2,
  STATUS_ONLINE_FILTER_ALL:3,

  STATUS_ONLINE_FILTER_TITLES:{
    1:"Только онлайн",
    2:"Только офлайн",
    3:"Все",
  },


// ========================================  -- TELECALL_EVENT --  ======================================== 

  TELECALL_EVENT_START:1,
  TELECALL_EVENT_TAKEN:2,
  TELECALL_EVENT_END:3,
  TELECALL_EVENT_MISSED:4,
  TELECALL_EVENT_MANAGER:5,
  TELECALL_EVENT_PERSONAL_MISSED:6,
  TELECALL_EVENT_CALLER_MISSED:7,
  TELECALL_EVENT_INIT:8,
  TELECALL_EVENT_BACK_CALL_REQUEST:9,

  TELECALL_EVENT_TITLES:{
    1:"Начало",
    2:"Принят",
    3:"Завершение",
    4:"Пропущен",
    5:"Запрос оператора",
    6:"Пропущен персональный",
    7:"Пропущен клиентом",
    8:"Инициализация",
    9:"Запрос обратного",
  },


// ========================================  -- TELECALL_TYPE --  ======================================== 

  TELECALL_TYPE_IN:1,
  TELECALL_TYPE_OUT:2,
  TELECALL_TYPE_BACK:3,

  TELECALL_TYPE_TITLES:{
    1:"Входящий",
    2:"Исходящий",
    3:"Обратный",
  },


// ========================================  -- TICKET_TYPE --  ======================================== 

  TICKET_TYPE_AVIA:1,
  TICKET_TYPE_RAIL:2,
  TICKET_TYPE_BUS:3,
  TICKET_TYPE_OTHER:200,

  TICKET_TYPE_TITLES:{
    1:"Авиа",
    2:"Ж/Д",
    3:"Автобус",
    200:"Иное",
  },


// ========================================  -- TRANSFER_BOOKING_STATUS --  ======================================== 

  TRANSFER_BOOKING_STATUS_DRAFT:1,
  TRANSFER_BOOKING_STATUS_REQUESTED:2,
  TRANSFER_BOOKING_STATUS_CONFIRMED:3,
  TRANSFER_BOOKING_STATUS_AGREED:4,
  TRANSFER_BOOKING_STATUS_APPOINTED:5,
  TRANSFER_BOOKING_STATUS_ACCEPTED:6,
  TRANSFER_BOOKING_STATUS_DONE:7,
  TRANSFER_BOOKING_STATUS_CLOSED:8,
  TRANSFER_BOOKING_STATUS_CANCELLED:9,

  TRANSFER_BOOKING_STATUS_TITLES:{
    1:"черновик",
    2:"запрос",
    3:"подтверждено",
    4:"согласовано",
    5:"назначено",
    6:"принято",
    7:"выполнено",
    8:"закрыто",
    9:"отменено",
  },


// ========================================  -- TRANSFER_TYPE --  ======================================== 

  TRANSFER_TYPE_ARRIVAL:"arrival",
  TRANSFER_TYPE_DEPARTURE:"departure",
  TRANSFER_TYPE_MEETING:"meeting",
  TRANSFER_TYPE_PAYMENT:"payment",
  TRANSFER_TYPE_DOCUMENTS:"documents",

  TRANSFER_TYPE_TITLES:{
    arrival:"Встреча туристов",
    departure:"Провод туристов",
    meeting:"Встреча в холле отеля",
    payment:"Получение оплаты",
    documents:"Доставка документов",
  },


// ========================================  -- TOUR_STATUS --  ======================================== 

  TOUR_STATUS_DRAFT:1,
  TOUR_STATUS_AGREED:2,
  TOUR_STATUS_STARTED:5,
  TOUR_STATUS_FINISHED:9,
  TOUR_STATUS_ARCHIVED:6,
  TOUR_STATUS_REVOKED:7,
  TOUR_STATUS_ANNULLED:8,

  TOUR_STATUS_TITLES:{
    1:"Черновик",
    2:"Согласован",
    5:"Исполняется",
    9:"Завершен",
    6:"Архив",
    7:"Отменен",
    8:"Аннулирован",
  },


// ========================================  -- TOUR_X_STATUS --  ======================================== 

  TOUR_X_STATUS_ALL:0,
  TOUR_X_STATUS_DRAFT:1,
  TOUR_X_STATUS_AGREED:2,
  TOUR_X_STATUS_STARTED:5,
  TOUR_X_STATUS_ARCHIVED:6,
  TOUR_X_STATUS_CANCELLED:8,
  TOUR_X_STATUS_V_NEGOTIATIONS:100,
  TOUR_X_STATUS_V_BOOKING_IN_PROGRESS:101,
  TOUR_X_STATUS_V_BOOKED:102,
  TOUR_X_STATUS_V_PAYED:103,
  TOUR_X_STATUS_V_OVERDUE:104,
  TOUR_X_STATUS_V_WAIT_FOR_PAYMENT:105,
  TOUR_X_STATUS_V_PAYED_BY_AGENCY:106,

  TOUR_X_STATUS_TITLES:{
    0:"Все",
    1:"Черновик",
    2:"Согласован",
    5:"Исполняется",
    6:"Архив",
    8:"Аннулирован",
    100:"Переговоры",
    101:"Бронируется",
    102:"Забронировано",
    103:"Оплачено",
    104:"Просрочено",
    105:"Ожидает оплаты",
    106:"Оплачено агентством",
  },


// ========================================  -- VAT_TYPE --  ======================================== 

  VAT_TYPE_NO_VAT:1,
  VAT_TYPE_RUS_ZERO:2,
  VAT_TYPE_RUS_TEN:3,
  VAT_TYPE_RUS_TWENTY:4,

  VAT_TYPE_TITLES:{
    1:"Без НДС",
    2:"НДС 0%",
    3:"НДС 10%",
    4:"НДС 20%",
  },


// ========================================  -- VALIDITY_PERIOD --  ======================================== 

  VALIDITY_PERIOD_HOUR:1,
  VALIDITY_PERIOD_DAY:24,
  VALIDITY_PERIOD_WEEK:168,
  VALIDITY_PERIOD_MONTH:720,
  VALIDITY_PERIOD_YEAR:8760,
  VALIDITY_PERIOD_FOREVER:876000,

  VALIDITY_PERIOD_TITLES:{
    1:"1 час",
    24:"1 день",
    168:"1 неделя",
    720:"1 месяц",
    8760:"1 год",
    876000:"Навсегда",
  },


// ========================================  -- ANALYTICS_EVENT_TYPE --  ======================================== 

  ANALYTICS_EVENT_TYPE_CALL_MISSED:"call_missed",
  ANALYTICS_EVENT_TYPE_CALL_PERSONAL_MISSED:"call_personal_missed",
  ANALYTICS_EVENT_TYPE_CALL_TAKEN:"call_taken",
  ANALYTICS_EVENT_TYPE_CHAT_MISSED:"chat_missed",
  ANALYTICS_EVENT_TYPE_CHAT_TAKEN:"chat_taken",
  ANALYTICS_EVENT_TYPE_LEAD_TAKEN:"lead_taken",
  ANALYTICS_EVENT_TYPE_LEAD_CANCELLED:"lead_cancelled",
  ANALYTICS_EVENT_TYPE_TELEPHONY_FAILED:"telephony_failed",
  ANALYTICS_EVENT_TYPE_USER_LOGIN:"user_login",
  ANALYTICS_EVENT_TYPE_USER_LOGOUT:"user_logout",
  ANALYTICS_EVENT_TYPE_USER_OFFLINE:"user_offline",
  ANALYTICS_EVENT_TYPE_USER_ONLINE:"user_online",
  ANALYTICS_EVENT_TYPE_BOOKING_ANNUL:"booking_annul",

  ANALYTICS_EVENT_TYPE_TITLES:{
    call_missed:"Звонок пропущен",
    call_personal_missed:"Звонок пропущен основным сотрудником",
    call_taken:"Звонок принят",
    chat_missed:"Чат пропущен",
    chat_taken:"Чат принят",
    lead_taken:"Лид принят в работу",
    lead_cancelled:"Лид возвращен в новые",
    telephony_failed:"Ошибка телефонии",
    user_login:"Вход пользователя",
    user_logout:"Выход пользователя",
    user_offline:"Пользователь оффлайн",
    user_online:"Пользователь онлайн",
    booking_annul:"Аннуляция забронированной брони на профкурорте",
  },


// ========================================  -- BACK_CALL_STATUS --  ======================================== 

  BACK_CALL_STATUS_BACKCALL_STATUS_MISSED:"missed",
  BACK_CALL_STATUS_BACKCALL_STATUS_TAKEN:"taken",
  BACK_CALL_STATUS_BACKCALL_STATUS_FINISHED:"finished",
  BACK_CALL_STATUS_BACKCALL_STATUS_NO_ANSWER:"no_answer",
  BACK_CALL_STATUS_BACKCALL_STATUS_BUSY:"busy",
  BACK_CALL_STATUS_BACKCALL_STATUS_FAILED:"failed",

  BACK_CALL_STATUS_TITLES:{
    missed:"менеджер не ответил",
    taken:"менеджер принял звонок",
    finished:"звонок завершился",
    no_answer:"абонент не отвечает",
    busy:"абонент занят",
    failed:"ошибка телефонии",
  },


// ========================================  -- BOOKING_ACTIONS --  ======================================== 

  BOOKING_ACTIONS_ARCHIVE:"archive",
  BOOKING_ACTIONS_AVAILABLE:"available",
  BOOKING_ACTIONS_BOOK:"book",
  BOOKING_ACTIONS_CANCEL:"cancel",
  BOOKING_ACTIONS_CREATE_AVAILABILITY:"create_availability",
  BOOKING_ACTIONS_CREATE_INQUIRE:"create_inquire",
  BOOKING_ACTIONS_CREATE_REVOKE:"create_revoke",
  BOOKING_ACTIONS_CREATE_SWAP:"create_swap",
  BOOKING_ACTIONS_INVOICE_PENDING:"invoice_pending",
  BOOKING_ACTIONS_OCCUPY_QUOTA:"occupy_quota",
  BOOKING_ACTIONS_PAY:"pay",
  BOOKING_ACTIONS_REFUSE:"refuse",
  BOOKING_ACTIONS_RESTORE:"restore",
  BOOKING_ACTIONS_REVOKE_APPROVED:"revoke_approved",
  BOOKING_ACTIONS_SEND_AVAILABILITY:"send_availability",
  BOOKING_ACTIONS_SEND_INQUIRE:"send_inquire",
  BOOKING_ACTIONS_SEND_REVOKE:"send_revoke",
  BOOKING_ACTIONS_SEND_SWAP:"send_swap",
  BOOKING_ACTIONS_START:"start",
  BOOKING_ACTIONS_SWAP_BOOK:"swap_book",
  BOOKING_ACTIONS_SWAP_REFUSE:"swap_refuse",
  BOOKING_ACTIONS_TO_QUOTA:"to_quota",
  BOOKING_ACTIONS_UNAVAILABLE:"unavailable",
  BOOKING_ACTIONS_BOOKED_ONLINE:"booked_online",
  BOOKING_ACTIONS_ONLINE_SWAP_FAILED:"online_swap_failed",
  BOOKING_ACTIONS_SWAPPED_ONLINE:"swapped_online",
  BOOKING_ACTIONS_ONLINE_BOOK_FAILED:"online_book_failed",
  BOOKING_ACTIONS_REVOKED_ONLINE:"revoked_online",
  BOOKING_ACTIONS_GET_COST:"get_cost",

  BOOKING_ACTIONS_TITLES:{
    archive:"В архив",
    available:"Получено подтверждение наличия свободных мест",
    book:"Бронирование",
    cancel:"Отмена брони",
    create_availability:"Создание запроса на наличие свободных мест",
    create_inquire:"Создание запроса на бронирование",
    create_revoke:"Создание запроса на отказ от брони",
    create_swap:"Создание запроса на изменение брони",
    invoice_pending:"Ожидание счета",
    occupy_quota:"занять квоту",
    pay:"Оплата брони",
    refuse:"Не может быть забронировано",
    restore:"Восстановление брони",
    revoke_approved:"Отказ от брони утвержден",
    send_availability:"Отправка запроса наличия свободных мест",
    send_inquire:"Отправка запроса на бронирование",
    send_revoke:"Отправка запроса на отказ от брони",
    send_swap:"Отправка запроса на изменение брони",
    start:"Начало оказания услуг",
    swap_book:"Изменение брони",
    swap_refuse:"Бронь не может быть изменена",
    to_quota:"Перевести в квоту",
    unavailable:"Получен отказ на наличия свободных мест",
    booked_online:"Бронь создана онлайн",
    online_swap_failed:"Не удалось изменить бронь онлайн",
    swapped_online:"Бронь изменена онлайн",
    online_book_failed:"Не удалось забронировать онлайн",
    revoked_online:"Бронь отменена онлайн",
    get_cost:"Расчет стоимости брони",
  },


// ========================================  -- BUYER_CONTRACT_DOCUMENT --  ======================================== 

  BUYER_CONTRACT_DOCUMENT_AGENCY_CONTRACT:4,
  BUYER_CONTRACT_DOCUMENT_AGENCY_RECEIPT:6,
  BUYER_CONTRACT_DOCUMENT_VOUCHER:10,

  BUYER_CONTRACT_DOCUMENT_TITLES:{
    4:"Договор агентства с заказчиком",
    6:"Счет агентства заказчику физлицу",
    10:"Путевка",
  },


// ========================================  -- COMMISSIONER_REPORT_CREATE_OPTION --  ======================================== 

  COMMISSIONER_REPORT_CREATE_OPTION_CLOSED:"closed",
  COMMISSIONER_REPORT_CREATE_OPTION_PAYED_AND_CLOSED:"payed_and_closed",
  COMMISSIONER_REPORT_CREATE_OPTION_BOOKED:"booked",
  COMMISSIONER_REPORT_CREATE_OPTION_PAYED_PLUS:"payed_plus",

  COMMISSIONER_REPORT_CREATE_OPTION_TITLES:{
    closed:"брони в статусе CLOSED",
    payed_and_closed:"брони в статусе PAYED и CLOSED",
    booked:"брони в статусах PAYED и BOOKED",
    payed_plus:"BOOKED - при условии что связанный с бронью supplierInvoice в статусе SUBMITTED HELD, при условии что agency_invoice у связанного тура в статусе SUBMITTED HELD,PAYED, CLOSED",
  },


// ========================================  -- CONTACT_TYPE --  ======================================== 

  CONTACT_TYPE_TELEGRAM:"telegram",
  CONTACT_TYPE_WHATSAPP:"whatsapp",
  CONTACT_TYPE_VK:"vk",
  CONTACT_TYPE_OK:"ok",
  CONTACT_TYPE_SKYPE:"skype",
  CONTACT_TYPE_VIBER:"viber",
  CONTACT_TYPE_EMAIL:"email",
  CONTACT_TYPE_FAX:"fax",
  CONTACT_TYPE_ICQ:"icq",

  CONTACT_TYPE_TITLES:{
    telegram:"Telegram",
    whatsapp:"Whatsapp",
    vk:"вКонтакте",
    ok:"Одноклассники",
    skype:"Skype",
    viber:"Viber",
    email:"E-mail",
    fax:"Факс",
    icq:"ICQ",
  },


// ========================================  -- ENUMERATOR_MODE --  ======================================== 

  ENUMERATOR_MODE_THROUGH:"THROUGH",
  ENUMERATOR_MODE_YEARLY:"YEARLY",
  ENUMERATOR_MODE_MONTHLY:"MONTHLY",

  ENUMERATOR_MODE_TITLES:{
    THROUGH:"сквозная",
    YEARLY:"погодная",
    MONTHLY:"помесячная",
  },


// ========================================  -- FMS_DOCUMENT_ID_TYPE --  ======================================== 

  FMS_DOCUMENT_ID_TYPE_A:"foreign",
  FMS_DOCUMENT_ID_TYPE_B:"passport",
  FMS_DOCUMENT_ID_TYPE_C:"birth",
  FMS_DOCUMENT_ID_TYPE_D:"other",
  FMS_DOCUMENT_ID_TYPE_F:"kazakh",
  FMS_DOCUMENT_ID_TYPE_G:"service",
  FMS_DOCUMENT_ID_TYPE_H:"diplomat",
  FMS_DOCUMENT_ID_TYPE_J:"shelters",
  FMS_DOCUMENT_ID_TYPE_K:"sng",

  FMS_DOCUMENT_ID_TYPE_TITLES:{
    foreign:"Национальный заграничный паспорт",
    passport:"Иностранный паспорт",
    birth:"Иностранное свидетельство о рождении",
    other:"Иной документ, удостоверяющий личность",
    kazakh:"Удостоверение личности гражданина республики Казахстан",
    service:"Служебный паспорт",
    diplomat:"Дипломатический паспорт иностранного гражданина",
    shelters:"Свидетельство о предоставлении временного убежища на территории Российской Федерации",
    sng:"Удостоверение личности гражданина государств-участников СНГ",
  },


// ========================================  -- FMS_DOCUMENT_TYPE --  ======================================== 

  FMS_DOCUMENT_TYPE_A:"visa",
  FMS_DOCUMENT_TYPE_B:"permit",
  FMS_DOCUMENT_TYPE_C:"temp_permit",

  FMS_DOCUMENT_TYPE_TITLES:{
    visa:"Виза",
    permit:"Вид на жительство",
    temp_permit:"Разрешение на временное проживание",
  },


// ========================================  -- FMS_PURPOSE_OF_ENTRY --  ======================================== 

  FMS_PURPOSE_OF_ENTRY_A:"service",
  FMS_PURPOSE_OF_ENTRY_B:"tourism",
  FMS_PURPOSE_OF_ENTRY_C:"business",
  FMS_PURPOSE_OF_ENTRY_D:"education",
  FMS_PURPOSE_OF_ENTRY_E:"work",
  FMS_PURPOSE_OF_ENTRY_F:"private",
  FMS_PURPOSE_OF_ENTRY_G:"transit",
  FMS_PURPOSE_OF_ENTRY_H:"human",
  FMS_PURPOSE_OF_ENTRY_J:"other",

  FMS_PURPOSE_OF_ENTRY_TITLES:{
    service:"Служебная",
    tourism:"Туризм",
    business:"Деловая",
    education:"Учеба",
    work:"Работа",
    private:"Частная",
    transit:"Транзит",
    human:"Гуманитарная",
    other:"Другая",
  },


// ========================================  -- GENDER --  ======================================== 

  GENDER_MALE:"M",
  GENDER_FEMALE:"F",

  GENDER_TITLES:{
    M:"Мужчина",
    F:"Женщина",
  },


// ========================================  -- OPERATION_STATUS --  ======================================== 

  OPERATION_STATUS_NEW:"new",
  OPERATION_STATUS_DONE:"done",
  OPERATION_STATUS_DENIED:"denied",
  OPERATION_STATUS_FAILED:"failed",

  OPERATION_STATUS_TITLES:{
    new:"Новая",
    done:"Завершена",
    denied:"Отклонена",
    failed:"Ошибка",
  },


// ========================================  -- PERSON_PASSPORT_TYPE --  ======================================== 

  PERSON_PASSPORT_TYPE_PASSPORT:"passport",
  PERSON_PASSPORT_TYPE_BIRTH:"birth",
  PERSON_PASSPORT_TYPE_OTHER:"other",

  PERSON_PASSPORT_TYPE_TITLES:{
    passport:"паспорт РФ",
    birth:"свидетельство о рождении РФ",
    other:"Иной документ, удостоверяющий личность",
  },


// ========================================  -- PLACE_TYPE --  ======================================== 

  PLACE_TYPE_AUTO:"_auto",
  PLACE_TYPE_MAIN:"main",
  PLACE_TYPE_EXTRA:"ext",
  PLACE_TYPE_CHILD:"child",

  PLACE_TYPE_TITLES:{
    _auto:"назначается автоматически",
    main:"основное",
    ext:"дополнительное",
    child:"дополнительное детское",
  },


// ========================================  -- PLACE_TYPE_IN_CATEGORY_CHOICES --  ======================================== 

  PLACE_TYPE_IN_CATEGORY_CHOICES_GENERAL:"general",
  PLACE_TYPE_IN_CATEGORY_CHOICES_ADDITIONAL:"additional",

  PLACE_TYPE_IN_CATEGORY_CHOICES_TITLES:{
    general:"Основное",
    additional:"Дополнительное",
  },


// ========================================  -- QUERY_ACTIONS --  ======================================== 

  QUERY_ACTIONS_RESEND:"send",

  QUERY_ACTIONS_TITLES:{
    send:"отправить повторно",
  },


// ========================================  -- SCHEDULED_TASK_TYPE --  ======================================== 

  SCHEDULED_TASK_TYPE_CONTACT_TO_CLIENT:"contact_to_client",
  SCHEDULED_TASK_TYPE_CONTACT_TO_TOUR:"contact_to_tour",
  SCHEDULED_TASK_TYPE_CUSTOM_TASK:"custom_task",

  SCHEDULED_TASK_TYPE_TITLES:{
    contact_to_client:"Контакт со старым заказчиком",
    contact_to_tour:"Контакт с заказчиком по туру",
    custom_task:"Пользовательская задача",
  },


// ========================================  -- SPECIAL_EVENTS --  ======================================== 

  SPECIAL_EVENTS_ONLINE_STATUS:"online_status",
  SPECIAL_EVENTS_CHAT_CHANGE:"chat_change",

  SPECIAL_EVENTS_TITLES:{
    online_status:"изменение статуса онлайн другого сотрудника",
    chat_change:"обновление чата",
  },


// ========================================  -- TOUR_ACTIONS --  ======================================== 

  TOUR_ACTIONS_AGREE:"agree",
  TOUR_ACTIONS_REVOKE:"revoke",
  TOUR_ACTIONS_CANCEL:"cancel",
  TOUR_ACTIONS_START:"start",
  TOUR_ACTIONS_FINISH:"finish",
  TOUR_ACTIONS_CLOSE:"close",
  TOUR_ACTIONS_ANNUL:"annul",
  TOUR_ACTIONS_FORCED_CLOSE:"forced_close",
  TOUR_ACTIONS_CHECKOUT:"checkout",
  TOUR_ACTIONS_CHANGE:"change",
  TOUR_ACTIONS_ADD_AGENT:"add_agent",
  TOUR_ACTIONS_SET_PERCENTAGE:"set_percentage",
  TOUR_ACTIONS_SET_BANK_ACCOUNT:"set_bank_account",
  TOUR_ACTIONS_SET_AGENCY_ENTITY:"set_agency_entity",
  TOUR_ACTIONS_SET_SYSTEM_ENTITY:"set_system_entity",

  TOUR_ACTIONS_TITLES:{
    agree:"Согласование",
    revoke:"Отказ",
    cancel:"Отмена",
    start:"Начало исполнения",
    finish:"Завершение исполнения",
    close:"В архив",
    annul:"Аннуляция",
    forced_close:"Принудительное закрытие работы по туру",
    checkout:"Преждевременный выезд",
    change:"Замена брони в туре после начала исполнения",
    add_agent:"добавление агента к туру",
    set_percentage:"установка % вознаграждений агентам",
    set_bank_account:"изменение счета в туре",
    set_agency_entity:"изменение юрлица агенства в туре",
    set_system_entity:"изменение юридического лица системы в туре",
  },


// ========================================  -- TRANSFER_BOOKING_ACTIONS --  ======================================== 

  TRANSFER_BOOKING_ACTIONS_REQUEST:"request",
  TRANSFER_BOOKING_ACTIONS_CONFIRM:"confirm",
  TRANSFER_BOOKING_ACTIONS_REJECT:"reject",
  TRANSFER_BOOKING_ACTIONS_AGREE:"agree",
  TRANSFER_BOOKING_ACTIONS_DONE:"done",
  TRANSFER_BOOKING_ACTIONS_CLOSE:"close",
  TRANSFER_BOOKING_ACTIONS_CANCEL:"cancel",
  TRANSFER_BOOKING_ACTIONS_APPOINT:"appoint",
  TRANSFER_BOOKING_ACTIONS_UNAPPOINT:"unappoint",
  TRANSFER_BOOKING_ACTIONS_ACCEPT:"accept",
  TRANSFER_BOOKING_ACTIONS_PRINT:"print",
  TRANSFER_BOOKING_ACTIONS_SEND_TO_CUSTOMER:"send_to_customer",
  TRANSFER_BOOKING_ACTIONS_SEND_TO_DRIVER:"send_to_driver",
  TRANSFER_BOOKING_ACTIONS_SET_INSTRUCTIONS:"set_instructions",

  TRANSFER_BOOKING_ACTIONS_TITLES:{
    request:"Выслать запрос",
    confirm:"Подтвердить",
    reject:"Отклонить",
    agree:"Согласовать",
    done:"Выполнено",
    close:"Закрыть",
    cancel:"Отменить",
    appoint:"Назначить водителя и машину",
    unappoint:"Снять назначение водителя и машины",
    accept:"Принять к исполнению",
    print:"Распечатать",
    send_to_customer:"Отправить заказчику и туристам",
    send_to_driver:"Отправить водителю",
    set_instructions:"Инструкции для водителя",
  },


// ========================================  -- TRANSFER_BOOKING_DOCUMENT --  ======================================== 

  TRANSFER_BOOKING_DOCUMENT_TRANSFER_BOOKING_TO_CUSTOMER:13,
  TRANSFER_BOOKING_DOCUMENT_TRANSFER_BOOKING_TO_DRIVER:14,
  TRANSFER_BOOKING_DOCUMENT_BANNER:11,
  TRANSFER_BOOKING_DOCUMENT_CHECKIN:12,

  TRANSFER_BOOKING_DOCUMENT_TITLES:{
    13:"Трансфер заказчику",
    14:"Трансфер водителю",
    11:"Баннер для встречи",
    12:"Лист заезжающего",
  },


// ========================================  -- TRANSPORT_TYPE --  ======================================== 

  TRANSPORT_TYPE_EMAIL:"email",
  TRANSPORT_TYPE_SMS:"sms",
  TRANSPORT_TYPE_WEBCHAT:"webchat",
  TRANSPORT_TYPE_WHATSAPP:"whatsapp",
  TRANSPORT_TYPE_TELEGRAM:"telegram",

  TRANSPORT_TYPE_TITLES:{
    email:"Email",
    sms:"SMS",
    webchat:"Веб-чат",
    whatsapp:"Whatsapp",
    telegram:"Telegram",
  },


// ========================================  -- VARIABLE_TYPE --  ======================================== 

  VARIABLE_TYPE_STR:"str",
  VARIABLE_TYPE_INT:"int",
  VARIABLE_TYPE_BOOL:"bool",
  VARIABLE_TYPE_INT_LIST:"int_list",
  VARIABLE_TYPE_STR_LIST:"str_list",

  VARIABLE_TYPE_TITLES:{
    str:"строка",
    int:"число",
    bool:"булевское значение",
    int_list:"список из чисел",
    str_list:"список из строк",
  },


// ========================================  -- VIEW_PERMISSIONS --  ======================================== 

  VIEW_PERMISSIONS_VIEW:"view",
  VIEW_PERMISSIONS_CHANGE:"change",
  VIEW_PERMISSIONS_PRINT:"print",
  VIEW_PERMISSIONS_CONTACTS:"contacts",
  VIEW_PERMISSIONS_PAYMENTS:"payments",
  VIEW_PERMISSIONS_CHANGE_INSTRUCTIONS:"change_instructions",

  VIEW_PERMISSIONS_TITLES:{
    view:"View",
    change:"Change",
    print:"Print",
    contacts:"Contacts",
    payments:"Payments",
    change_instructions:"Change Instructions",
  },
}
