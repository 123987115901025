import _ from 'lodash'
import FC from '@/config/constants'

export const setCustomFilters = ({ table = null, filters = {} } = {}) => {
  if (!table) return

  const filtersEntries = Object.entries(filters)
  const filteredFiltersEntries = filtersEntries.filter(([, value]) => value)

  if (filteredFiltersEntries.length) {
    table.value.setCustomFilters({
      filters: Object.fromEntries(filteredFiltersEntries),
    })
  } else {
    table.value.resetCustomFilters()
  }
  table.value.getData()
}

// таблица запоминает последнюю страницу, чтобы открыться на ней в след. раз
// если в след. раз такой страницы уже нет - сбрасываем таблицу на первую страницу.
function onMissedOffset(ctx, { count = 0, data = [] }) {
  if (count > 0 && data.length === 0) {
    ctx.setPage(1)
  }
}

export const debounceSetCustomFilters = _.debounce(
  setCustomFilters,
  FC.DEBOUNCE_TIMEOUT,
)

export default {
  addSortedClassToCells: false,
  alwaysShowPerPageSelect: true,
  caption: false,
  cellClasses: {},
  childRow: false,
  childRowTogglerFirst: true,
  clientMultiSorting: true,
  columnsClasses: {
    button: 'w-5',
  },
  collapseGroups: false,
  columnsDisplay: {},
  columnsDropdown: false,
  // customFilters: [],
  // customFilters: ['alphabet','range'],
  customSorting: {},
  dateColumns: [],
  dateFormat: 'DD/MM/YYYY',
  dateFormatPerColumn: {},
  datepickerOptions: {
    locale: {
      cancelLabel: 'Clear',
    },
  },
  datepickerPerColumnOptions: {},
  debounce: 0, // debounce is set with requestFnDebounceWrap
  descOrderColumns: [],
  destroyEventBus: false,
  disabledChildRows: false,
  editableColumns: [],
  filterable: false,
  filterAlgorithm: {},
  footerHeadings: false,
  groupBy: false,
  groupMeta: [],
  headings: {
    'contractor_bank_account.entity': `Получатель платежа`,
    'invoice-amount': '',
    'invoice-date': 'Дата счёта',
    actions: 'Действия',
    address: 'Адрес',
    agency: 'Агентство',
    agent: 'Агент',
    amount: 'Сумма',
    button: '',
    buyer: 'Покупатель',
    change: '',
    check: '',
    chosen: 'Изб.',
    comment: 'Комментарий',
    contractor: 'Контрагент',
    principal: 'Комитент',
    company: 'Компания',
    contacts: 'Контакты',
    created_at: 'Создан',
    dates: 'Даты',
    default_place: 'Основное',
    delete: '',
    doc_date: 'Дата документа',
    doc_number: 'Номер документа',
    edit: 'Ред.',
    email: 'E-Mail',
    employee: 'Сотрудник',
    end: 'Конец',
    entity: 'Юр.лица',
    exact_position_name: 'Должность',
    extra_place: 'Дополнительное',
    fio: 'Ф.И.О.',
    id: 'ID',
    identity: 'Сотрудник',
    invoice: 'Счёт',
    is_active: 'Активен',
    location_name: 'Местоположение',
    mode_list: 'Режим',
    name: 'Имя',
    number: 'Номер',
    period: 'Период',
    phone: 'Телефон',
    program: 'Программа',
    responsible: 'Ответсвенные',
    role: 'Роль',
    room: 'Номер',
    single_occupancy: '1-местное',
    source_ip: 'IP заявки',
    source_type: 'Источник заявки',
    start: 'Начало',
    state: 'Статус',
    status: 'Статус',
    title: 'Название',
    title__icontains: 'Название',
    title_with_city: 'Город',
    type: 'Тип',
    toggle: '',
    tour: 'Тур',
    url: 'Ссылка',
    view: 'Вид',
    whole_room: 'Весь номер',
  },
  headingsTooltips: {},
  hiddenColumns: false,
  hidePerPageSelect: false,
  highlightMatches: false,
  infiniteScroll: false,
  initFilters: {},
  // initialPage: 1,
  listColumns: {},
  multiSorting: {},
  orderBy: false,
  pagination: {
    show: true,
    dropdown: false,
    chunk: 10,
    edge: true,
    // align: "center",
    nav: 'fixed', // fixed scroll
  },
  params: {},
  perPage: 10,
  perPageValues: [10, 20],
  preserveState: false,
  requestFunction: false,
  resizableColumns: false,
  requestAdapter: function (data) {
    return data
  },
  responseAdapter: function (resp) {
    const data = this.getResponseData(resp)
    const local = this
    onMissedOffset(local, data)

    return {
      data: data.data,
      count: data.count,
    }
  },
  requestKeys: {
    query: 'query',
    limit: 'limit',
    orderBy: 'orderBy',
    ascending: 'ascending',
    page: 'page',
    byColumn: 'byColumn',
  },
  rowClassCallback: false,
  saveState: true,
  sendEmptyFilters: false,
  sendInitialRequest: true,
  serverMultiSorting: false,
  showChildRowToggler: true,
  skin: false,
  sortable: true,
  sortIcon: {
    base: '',
    up: 'table-sort-desc',
    down: 'table-sort-asc',
    is: 'table-sort-base',
  },
  sortingAlgorithm(data, column) {
    return data.sort(this.getSortFn(column))
  },
  stickyHeader: true,
  storage: 'local',
  summary: false,
  tabbable: true,
  templates: {},
  texts: {
    count:
      'Показано от {from} до {to} из {count} записей|{count} записей|запись',
    first: 'Первый',
    last: 'Последний',
    filter: 'Фильтр:',
    filterPlaceholder: 'Поисковый  запрос',
    limit: 'Показать на странице:',
    page: 'Страница:',
    noResults: 'Нет соответствующих записей',
    noRequest: 'Please select at least one filter to fetch results',
    filterBy: 'Фильтровать - {column}',
    loading: 'Загрузка...',
    defaultOption: 'Все',
    columns: 'Columns',
    loadingError: 'Произошла ошибка',
  },
  toMomentFormat: false,
  uniqueKey: 'id',
  visibleColumns: false,
  checkLSSetting(WH) {
    // здесь проверяем настройки таблицы сохраненные в LS и фиксим если надо
    // вызывается в setup с WH таблицы
    try {
      const lsKey = `vuetables_${WH}`
      const lsStrData = localStorage.getItem(lsKey)
      const data = JSON.parse(lsStrData)

      // Fix: если когда-то в LS записался null как строка 'null' то это вызывало ошибку чтения ls в пакете таблицы
      // такой ключ удаляем
      if (lsStrData === 'null') {
        localStorage.removeItem(lsKey)
      }

      // Устанавливать в true если data модифицируется
      let updated = false

      // Кол-во строк на странице
      const perPage = data?.perPage != null ? Number(data.perPage) : 10
      // Если в настройках больше чем 20 строк - сбрасываем до 10-ти
      // Т.к. теперь ограничиваем до 20 на страницу, а раньше было больше и эти настройки ещё могут быть у кого-то а LS
      if (perPage > 20) {
        data.perPage = 10
        updated = true
      }

      if (updated) {
        localStorage.setItem(lsKey, JSON.stringify(data))
      }
    } catch (err) {
      console.error(
        `Ошибка парсинга localStorage настроек таблицы vuetable, WH: ${WH}`,
      )
    }
  },
  perPageSelectToBottom(selector) {
    const root = selector ? document.querySelector(selector) : document
    const limitEl = root.querySelector('.VueTables__limit')
    const limitLabel = limitEl.querySelector('label')
    const paginEl = root.querySelector('.VuePagination')
    const paginElNav = root.querySelector('.VuePagination>nav')
    const paginText = paginEl.querySelector('.VuePagination__count')
    const paginTextWrapEl = document.createElement('div')
    paginTextWrapEl.style.cssText =
      'display: flex; justify-content: center; margin-top: 1rem;'
    limitEl.setAttribute('title', 'показать на странице')
    limitEl.style.padding = '0'
    limitLabel.style.display = 'none'
    paginEl.style.cssText =
      'margin: 0; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between !important;'
    paginElNav.style.cssText = 'padding: 0; width: auto;'
    paginEl.append(limitEl)
    paginTextWrapEl.append(paginText)
    paginEl.append(paginTextWrapEl)
  },
  paginateHandle(_, selector) {
    const root = selector ? document.querySelector(selector) : document
    root.querySelector('.VueTables').scrollIntoView({ behavior: 'smooth' })
  },
}
