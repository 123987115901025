<template>
  <div class="minimizable" ref="modalContainer">
    <CModal
      ref="modal"
      :visible="visible"
      scrollable
      size="lg"
      fullscreen="sm"
      backdrop="static"
      alignment="center"
      @close="onClose"
    >
      <ModalMinimize v-if="modalContainer" :modal-container="modalContainer" />

      <CModalHeader dismiss>
        <CModalTitle :id="`id-title-${WH}`">Все файлы</CModalTitle>
      </CModalHeader>
      <CModalBody :id="`id-${WH}`" class="position-relative popup-min-h">
        <ViewFiles
          :show-upload-button="showUploadButton"
          :object-selection="objectSelection"
          :fixed-title="fixedTitle"
          :object-type="objectType"
          :object-id="objectId"
          :actions="actions"
          :modal-wh="WH"
          @upload-complete="onUploadComplete"
          @delete-file="onDeleteFile"
        />
      </CModalBody>
      <CModalFooter :id="`id-footer-${WH}`">
        <div
          class="flex flex-wrap gap-3 justify-content-end"
          :id="`id-buttons-${WH}`"
        >
          <div>
            <CButton color="secondary" @click="onClose">Закрыть</CButton>
          </div>
        </div>
      </CModalFooter>
      <div :id="`id-dev-${WH}`"></div>
    </CModal>
  </div>
</template>
<script setup>
import { inject, onMounted, onUnmounted, ref } from 'vue'
import ModalMinimize from '@/components/_common/ModalMinimize.vue'
import ViewFiles from '@/components/_custom/view-files/ViewFiles.vue'

defineProps({
  objectId: {
    type: [Number, String],
    required: true,
  },
  objectType: {
    type: String,
    required: true,
  },
  // active, disabled, hidden
  objectSelection: {
    type: String,
    default: 'active',
  },
  showUploadButton: {
    type: Boolean,
    default: true,
  },
  // [delete]
  actions: {
    type: Array,
    default: () => [],
  },
  // TEMPORARY если временный файл для привязки к личности
  fixedTitle: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits(['close', 'upload-complete', 'delete-file'])

const WH = '29d3d31e-06b0-4d25-bb4f-8469c3deb989'
const { storage } = inject('services')

const modalContainer = ref(null)
const visible = ref(false)

const onClose = () => {
  visible.value = false
  emit('close')
}
const onUploadComplete = (payload) => {
  emit('upload-complete', payload)
}
const onDeleteFile = (payload) => {
  emit('delete-file', payload)
}

onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
