// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ContactApiMethodsCls {
    async contactRetrieve(wh, id, extra_data = null) {
      // Получение контакта по id
      console.log('contactRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('telephony__contact', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'telephony__contact', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contactDelete(wh, id) {
      // Удаление контакта
      console.log('contactDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.telephony.contact.delete(id)})
      ctx.$services.storage.del('telephony__contact', id, wh)
      return null
    }
    async contactPartial(wh, id, data, extra_data = null) {
      // data: Contact
      // Изменение контакта
      console.log('contactPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contactList(wh, object_id, object_type, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка контактов для объекта
      // filters: type, number, info, primary, pk
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('contactList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.list(object_id, object_type, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async contactCreate(wh, object_id, object_type, data, extra_data = null) {
      // data: ContactCreate
      // Создание контакта для объекта
      // Если у объекта, к которому привязывается контакт нет поля company, то company нужно указать в запросе
      console.log('contactCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.create(object_id, object_type), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.contactApi = new ContactApiMethodsCls()
}

