import { webphoneBCName, webphoneMessageTypes } from '@/helpers/broadcast'
import constants from '@/config/constants'
import { withActiveWin } from '@/helpers/winTracker'
import { inject } from 'vue'

const DEBUG = true
const origin = 'https://pbx.rnsb.su'
const url = `${origin}/webphone/`
const name = 'webPhoneWin'
const bc = new BroadcastChannel(webphoneBCName)

let initialized = false
let unloadCb
let removeMessageHandler
let bus
let readySetDialCb

const getLSData = () => {
  try {
    return JSON.parse(localStorage.getItem(name)) || null
  } catch (err) {
    return null
  }
}

const isOpen = () => {
  const lsData = getLSData()
  return lsData && lsData.isOpen === true
}

const isReady = () => {
  const lsData = getLSData()
  return lsData && lsData.isReady === true
}

const closeAction = () => {
  window.webPhoneWin = null
  window.localStorage.setItem(
    name,
    JSON.stringify({ isOpen: false, isReady: false }),
  )
  if (removeMessageHandler) removeMessageHandler()
}

const unloadAction = () => {
  closeAction()
  if (unloadCb) unloadCb()
}

const openWebPhone = () => {
  if (DEBUG) {
    console.log('>>>>> openWebPhone')
  }
  const done = withActiveWin(openWebPhoneAction)
  if (DEBUG) {
    console.log('>>>>> done with active win', done)
  }

  if (!done) {
    bc.postMessage({ type: webphoneMessageTypes.open })
  }
}

const openWebPhoneAction = () => {
  if (DEBUG) {
    console.log('>>>>> openWebPhoneAction')
  }

  if (isOpen()) {
    if (DEBUG) {
      console.log('>>>>> isOpen => make focus')
    }

    focusWebPhone()
    return
  }

  if (DEBUG) {
    console.log('>>>>> is not opened => open')
  }

  const lsData = getLSData() || {}
  lsData.isOpen = true
  window.localStorage.setItem(name, JSON.stringify(lsData))

  if (removeMessageHandler) removeMessageHandler()

  const windowMessageHandler = (ev) => {
    if (ev.origin !== origin) return

    if (ev.data === webphoneMessageTypes.unload) {
      unloadAction()
      // уведомить другие вкладки
      bc.postMessage({ type: webphoneMessageTypes.unload })
    }

    if (ev.data === webphoneMessageTypes.ready) {
      const lsData = getLSData() || {}
      lsData.isReady = true
      window.localStorage.setItem(name, JSON.stringify(lsData))
      if (readySetDialCb) readySetDialCb()
    }
  }

  window.addEventListener('message', windowMessageHandler)

  removeMessageHandler = () => {
    window.removeEventListener('message', windowMessageHandler)
  }

  if (DEBUG) {
    console.log('>>>>> window.webPhoneWin:', window.webPhoneWin)
  }

  if (!window.webPhoneWin) {
    window.webPhoneWin = window.open(
      url,
      name,
      'height=600, width=800,status=no,location=no,toolbar=no,menubar=no',
    )
  }

  if (DEBUG) {
    console.log('>>>>> now should open')
  }
}

function closeWebPhone() {
  if (window.webPhoneWin) {
    window.webPhoneWin.close()
    closeAction()
  }
}
function focusWebPhone() {
  if (DEBUG) {
    console.log('>>>>> focusWebPhone')
  }

  const type = constants.postMessages.WEBPHONE_ACTIVATE_WIN
  window.postMessage({ type }, '*')
}

function webPhoneSetDialAction({ dialNumber }) {
  if (window.webPhoneWin) {
    const type = constants.postMessages.WEBPHONE_SET_DIAL
    window.webPhoneWin.postMessage({ type, dialNumber }, '*')
  }
}

function webPhoneSetDial({ dialNumber }) {
  // если офлайн выполнится коллбэк по готовности вебфона
  readySetDialCb = () => {
    if (!window.webPhoneWin) {
      bc.postMessage({ type: webphoneMessageTypes.dial, dialNumber })
      return
    }
    webPhoneSetDialAction({ dialNumber })

    readySetDialCb = null
  }

  bus.emit('set-user-online')

  // если уже онлайн вызвать сразу
  if (isReady()) {
    readySetDialCb()
  }
}

// инициализируется один раз, затем только возвращает returnObj
export const useWebPhone = (params) => {
  bus = inject('bus')

  const returnObj = {
    openWebPhone,
    closeWebPhone,
    focusWebPhone,
    webPhoneSetDial,
  }

  if (initialized) return returnObj

  initialized = true
  unloadCb = params.unloadCb

  bc.onmessage = (ev) => {
    if (DEBUG) {
      console.log('>>>>> on bc message', ev.data)
    }

    const data = ev.data
    if (data.type === webphoneMessageTypes.unload) {
      unloadAction()
    }
    if (data.type === webphoneMessageTypes.open) {
      withActiveWin(openWebPhoneAction)
    }
    if (data.type === webphoneMessageTypes.dial) {
      webPhoneSetDialAction(data)
    }
  }
  return returnObj
}
