// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class PeriodApiMethodsCls {
    async hotelPeriodRetrieve(wh, id, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение списка периодов для отеля
      // filters: is_active
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('hotelPeriodRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__period', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__period', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.period.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async hotelPeriodPost(wh, id, data, extra_data = null) {
      // data: PeriodPost
      // Создание периода для отеля
      console.log('hotelPeriodPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.period.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async periodCopyPeriodWithPricesCreate(wh, data, extra_data = null) {
      // data: PeriodClone
      // Копирование периода. А так же цен с этим объектом связанных
      console.log('periodCopyPeriodWithPricesCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.period.copyPeriodWithPrices.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async periodRetrieve(wh, id, extra_data = null) {
      // Получение периода отеля
      console.log('periodRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__period', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__period', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.period.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async periodUpdate(wh, id, data, extra_data = null) {
      // data: PeriodUpdate
      // Обновление дат периода отеля
      console.log('periodUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.period.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async periodDelete(wh, id) {
      // Удаление периода отеля
      console.log('periodDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.hms.period.delete(id)})
      ctx.$services.storage.del('hms__period', id, wh)
      return null
    }
    async periodActivatePartial(wh, id, data, extra_data = null) {
      // data: PeriodActivate
      // Обновление поля is_active периода (Period)
      console.log('periodActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.period.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.periodApi = new PeriodApiMethodsCls()
}

