// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class LocationApiMethodsCls {
    async locationList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Локаций
      // filters: name, addr_type, parent, latitude, longitude, slug, lft, rght, tree_id, level,
      //          mptt_parent
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('locationList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.location.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, false, extra_data), count:response.data.count}
    }
    async locationCreate(wh, data, extra_data = null) {
      // data: Location
      // Создание Локации
      console.log('locationCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.location.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async locationRetrieve(wh, id, extra_data = null) {
      // Получение Локации
      console.log('locationRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__location', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__location', id:id}, wh, false)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.location.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async locationUpdate(wh, id, data, extra_data = null) {
      // data: Location
      // Обновление Локации
      console.log('locationUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.location.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async locationPartial(wh, id, data, extra_data = null) {
      // data: Location
      // Изменение поля is_active
      console.log('locationPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.location.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, false, extra_data)
    }
    async locationCountryOfRetrieve(wh, id, extra_data = null) {
      // Поиск страны по адресу
      console.log('locationCountryOfRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__location', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__location', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.location.countryOf.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.locationApi = new LocationApiMethodsCls()
}

