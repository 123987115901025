// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ScheduleApiMethodsCls {
    async taskList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка задач
      // filters: created_at, updated_at, assignee, author, type, object_id, object_type, summary,
      //          description, due_to, start_at, close_at, resolution, is_active, show_future_tasks
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('taskList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async taskCreate(wh, data, extra_data = null) {
      // data: ScheduledTask
      // Создание задачи
      console.log('taskCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskAllList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Все задачи (в карточке заказчика)
      // filters: created_at, updated_at, assignee, author, type, object_id, object_type, summary,
      //          description, due_to, start_at, close_at, resolution, is_active, show_future_tasks
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('taskAllList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.all.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async taskCreatedList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Создание тура на основе задачи
      // filters: created_at, updated_at, assignee, author, type, object_id, object_type, summary,
      //          description, due_to, start_at, close_at, resolution, is_active, show_future_tasks
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('taskCreatedList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.created.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async taskHistoryList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Закрытые задачи
      // filters: created_at, updated_at, assignee, author, type, object_id, object_type, summary,
      //          description, due_to, start_at, close_at, resolution, is_active, show_future_tasks
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('taskHistoryList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.history.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async taskMassCreateCreate(wh, data, extra_data = null) {
      // data: ScheduledTaskMassCreate
      // Пакетное создание задач
      console.log('taskMassCreateCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.massCreate.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskMassDeleteCreate(wh, data, extra_data = null) {
      // data: ScheduledTaskMassDelete
      // Пакетное удаление задач
      console.log('taskMassDeleteCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.massDelete.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskRetrieve(wh, id, extra_data = null) {
      // Получение задачи
      console.log('taskRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('schedule__scheduledtask', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'schedule__scheduledtask', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskUpdate(wh, id, data, extra_data = null) {
      // data: ScheduledTask
      // Обновление задачи
      console.log('taskUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskDelete(wh, id) {
      // Удаление задачи
      console.log('taskDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.schedule.task.delete(id)})
      ctx.$services.storage.del('schedule__scheduledtask', id, wh)
      return null
    }
    async taskPartial(wh, id, data, extra_data = null) {
      // data: ScheduledTask
      // Частичное обновление задачи
      console.log('taskPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskClosePartial(wh, id, data, extra_data = null) {
      // data: ScheduledTaskClose
      // Пометить задачу как выполненную
      console.log('taskClosePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.close.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskNewTourPartial(wh, id, data, extra_data = null) {
      // data: ScheduledTaskCreateTour
      // Создание тура на основе задачи
      console.log('taskNewTourPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.newTour.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskNoAnswerPartial(wh, id, data, extra_data = null) {
      // data: ScheduledTask
      // Действие при недозвоне
      console.log('taskNoAnswerPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.noAnswer.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async taskPostponeScheduledPartial(wh, id, data, extra_data = null) {
      // data: ScheduledTaskPostpone
      // Закрыть текущую задачу, создать новую задачу на основе текущей
      console.log('taskPostponeScheduledPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.task.postponeScheduled.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async tasksForObjectList(wh, object_id, object_type, {limit, offset, page} = {}, extra_data = null) {
      // Список задач объекта
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('tasksForObjectList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.schedule.tasksForObject.list(object_id, object_type, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.scheduleApi = new ScheduleApiMethodsCls()
}

