<template>
  <div>
    <CAccordion v-if="categoriesList?.data?.length">
      <CAccordionItem
        v-for="category in categoriesList.data"
        :key="category.id"
        :item-key="category.id"
      >
        <CAccordionHeader>
          {{ category.name }}
        </CAccordionHeader>
        <CAccordionBody>
          <Markdown
            v-if="category?.description"
            :source="category.description"
          />
          <p v-else class="text-secondary mb-0">Описание еще не добавленно</p>
          <div>
            <CButton
              color="primary"
              @click="currentCategoryId = category.id"
              size="sm"
              >Показать цены</CButton
            >
          </div>
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
    <p v-if="!categoriesList?.data?.length" class="text-secondary mb-0">
      Номера не добавленны
    </p>

    <PopupHotelPricesInfo
      v-if="currentCategoryId"
      :data="pricesParams"
      @close="currentCategoryId = null"
    />
  </div>
</template>

<script setup>
import Markdown from 'vue3-markdown-it'
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import PopupHotelPricesInfo from '@/components/_shared/hotel-prices/PopupHotelPricesInfo.vue'

const props = defineProps({
  hotel: {
    type: Object,
    required: true,
  },
})
const WH = 'e70f830c-995a-42e7-ade6-14b8441ecba9'
const { categoryApi, storage } = inject('services')

const categoriesList = ref([])
const currentCategoryId = ref(null)

const pricesParams = computed(() => {
  return {
    hotel: props.hotel.id,
    category: currentCategoryId.value,
  }
})

const fetchCategories = async (id) => {
  storage.clr(WH)
  try {
    if (id || props.hotel?.id) {
      categoriesList.value = await categoryApi.hotelListCategoriesList(
        WH,
        id || props.hotel.id,
        { limit: 50, filters: { is_active: true } },
      )
    }
  } catch (error) {
    console.error(error)
  }
}

watch(
  () => props.hotel,
  (val) => {
    fetchCategories(val.id)
  },
)

onMounted(fetchCategories)
onUnmounted(() => {
  storage.clr(WH)
})
</script>
