import { ref, inject, onBeforeMount, onUnmounted, computed } from 'vue'
import C from '@/config/back_const'

/**
 * @typedef { import("vue").Ref } Ref
 */

/**
 * @param [cb]
 * @returns {{myCompany: Ref<{}>, isSystem: ComputedRef<boolean>, isAgency: ComputedRef<boolean>, isTransfer: ComputedRef<boolean>, isPrivileged: ComputedRef<boolean>, isSupervisor: ComputedRef<unknown>, isSystemManager: ComputedRef<unknown>, isAgent: ComputedRef<boolean>, myUser: Ref<{}>, myIdentity: Ref<{}>, isAdmin: ComputedRef<boolean>, isAccountant: ComputedRef<boolean>, authReady: Ref<boolean>, updateAuth: function}}
 */
export function useAuthData(cb) {
  const bus = inject('bus')
  const { auth } = inject('services')

  const authReady = ref(false)
  const myIdentity = ref({})
  const myCompany = ref({})
  const myUser = ref({})

  const isOwner = computed(() => {
    return myIdentity.value?.role === C.ROLES_OWNER
  })
  const isSystem = computed(() => {
    return myCompany.value?.id === C.SYSTEM_COMPANY_ID
  })
  const isAgency = computed(() => {
    return myCompany.value?.is_agency === true
  })
  const isTransfer = computed(() => {
    return myCompany.value?.is_transfer === true
  })
  const isPrivileged = computed(() => {
    return myCompany.value?.is_privileged === true
  })
  const isAdmin = computed(() => {
    return C.ADMIN_ROLES.includes(myIdentity.value?.role)
  })
  const isAgent = computed(() => {
    return C.AGENT_ROLES.includes(myIdentity.value?.role)
  })
  const isSupervisor = computed(() => {
    return isAgent.value && myIdentity.value?.is_supervisor
  })
  const isAccountant = computed(() => {
    return myIdentity.value?.role === C.ROLES_ACCOUNTANT
  })
  const isSystemAccountant = computed(() => {
    return isAccountant.value && isSystem.value
  })
  const isSystemManager = computed(() => {
    return isSystem.value && myIdentity.value.role === C.ROLES_MANAGER
  })
  const isAdminOrOwner = computed(() => {
    return isOwner.value || isAdmin.value
  })

  async function updateAuth() {
    const [identity, company, user] = await Promise.all([
      auth.getIdentity(),
      auth.getCompany(),
      auth.getUser(),
    ])
    myIdentity.value = identity
    myCompany.value = company
    myUser.value = user
  }

  onBeforeMount(async () => {
    await updateAuth()
    bus.on('authentication-changed', async () => {
      await updateAuth()
      if (cb) cb()
    })
    authReady.value = true
  })
  onUnmounted(() => {
    bus.off('authentication-changed', updateAuth)
  })

  return {
    isAdminOrOwner,
    isSupervisor,
    isAccountant,
    myIdentity,
    authReady,
    myCompany,
    isOwner,
    isAgent,
    myUser,
    isAdmin,
    isAgency,
    isSystem,
    isTransfer,
    isPrivileged,
    isSystemManager,
    isSystemAccountant,
    updateAuth,
  }
}
