/* Можно использовать */

import { getWSMessage, setURLDebug } from '@/helpers'

const DEBUG_WS = false
// const OPEN = 1
const CONNECTING = 0

function reSubscribe(ctx) {
  const { subscriptions } = ctx
  ctx.subscriptions = {}
  const keys = Object.keys(subscriptions)
  keys.forEach((key) => {
    const digitIndex = key.search(/\d/)
    const dataType = key.slice(0, digitIndex)
    const idStr = key.slice(digitIndex)
    const id = idStr ? Number(idStr) : null
    if (key.includes('special_event_')) {
      const [event_type, identity] = subscriptions[key]
      ctx.subscribe_single_special(event_type, identity)
    } else {
      subscriptions[key].forEach((wh) => {
        ctx.subscribe_single(dataType, id, wh)
      })
    }
  })
}

function reconnectAction(ctx) {
  reSubscribe(ctx)
}

export default (ctx) => {
  class WebsocketService {
    token = null
    url = null
    connection = null
    reconnectionTime = 1000 * 60
    reconnectionAttemptStart = null
    subscriptions = {}

    pingTimeOutId = null
    pongTimeOutId = null
    connectionLost = false
    pingStatusHandler = null
    connectionStartedCBs = []
    connectionCount = 0

    constructor() {
      setURLDebug('wsService', this)
    }

    _send = async (msg) => {
      if (DEBUG_WS) console.error('_send', msg)
      if (this.connection.readyState === 1) {
        if (DEBUG_WS) console.log('===WS==> message sent!!!')
        this.connection.send(msg)
      } else {
        if (DEBUG_WS) console.log('===WS==> wait for connection...')
        setTimeout(this._send, 10, msg)
      }
    }

    // установить колбэк на изменение пинг статуса
    setPingStatusHandler(fn) {
      if (typeof fn === 'function') {
        this.pingStatusHandler = fn
      }
    }

    _setPing() {
      const time = 1e4

      const clear = () => {
        clearTimeout(this.pingTimeOutId)
        clearTimeout(this.pongTimeOutId)
      }

      if (this.connection?.readyState === CONNECTING) {
        // Такое может возникать прии переключениях ролей.
        // Открывается новый сокет, но старый таймаут в котором вызывается _setPing ещё может выполнится для старого соединения.
        console.log('setPing остановлен')
        clear()
        return
      }

      const ping = () => {
        if (this.connection?.readyState === CONNECTING) return
        this.pongTimeOutId = setTimeout(() => {
          // если за время ожитания time не пришёл pong - выполняем pingStatusHandler разрываем и реконнектимся

          // временно отключили уведомления взамен на точку в футере
          // ctx.$services.notification.error('Соединение потеряно!')
          this.connectionLost = true
          if (this.pingStatusHandler) {
            this.pingStatusHandler({ connectionLost: this.connectionLost })
          }
          this.close()
        }, time)
        this.connection.send('ping')
      }

      const pongListener = (ev) => {
        const data = ev.data || ev.detail
        if (data !== 'pong') return

        clear()
        this.pingTimeOutId = setTimeout(ping, time)
        if (this.connectionLost) {
          // если за время ожидания time пришёл pong то мы оставляем это соединение
          this.connectionLost = false
          // временно отключили уведомления взамен на точку в футере
          // ctx.$services.notification.success('Соединение восстановлено!', {
          //   autohide: false,
          // })
          if (this.pingStatusHandler) {
            this.pingStatusHandler({ connectionLost: this.connectionLost })
          }
        }
      }

      this.connection.addEventListener('message', pongListener)

      clear()
      ping()

      console.log('setPing стартовал')
    }

    connect = async () => {
      if (DEBUG_WS) console.log('===WS==> connect')

      this.token = await ctx.$services.auth.getTokenKey()
      if (DEBUG_WS) console.log('===WS==> token', this.token)

      const regex = /^http/i
      this.server = await ctx.$services.auth.getWSServerUrl()
      if (DEBUG_WS) console.log('===WS==> server', this.server)
      this.server = this.server.replace(regex, 'ws')
      if (DEBUG_WS) console.log('===WS==> server', this.server)
      this.url = `${this.server}/ws/obj/${this.token}/`

      if (DEBUG_WS) console.log('===WS==> url', this.url)
      this.connection = await new WebSocket(this.url)
      if (DEBUG_WS) console.log('===WS==> connection', this.connection)

      this.connection.onmessage = async (e) => {
        const data = getWSMessage(e)
        if (!data) return
        if (DEBUG_WS) console.log('===WS==> onmessage', data)

        if (data.type === 'notifier') {
          if (DEBUG_WS)
            console.log('===WS==> notifier', data.data.data_type, data.data.id)
          if (this.subscriptions[data.data.data_type + data.data.id]) {
            if (DEBUG_WS)
              console.log(
                this.subscriptions[data.data.data_type + data.data.id],
              )
            for (let wh of this.subscriptions[
              data.data.data_type + data.data.id
            ]) {
              ctx.$services.storage.put(data.data, wh, false)
            }
          }
        }
      }

      if (DEBUG_WS) console.log('===WS==> 1')

      this.connection.onclose = async (e) => {
        if (DEBUG_WS)
          console.error(
            '===WS==> onclose. Reconnect will be attempted in 1 second',
            e,
          )

        if (!this.token) {
          this.connection = null
          return
        }

        clearTimeout(this.pongTimeOutId)

        if (!this.reconnectionAttemptStart) {
          this.reconnectionAttemptStart = Date.now()
        }

        if (
          Date.now() - this.reconnectionAttemptStart <=
          this.reconnectionTime
        ) {
          setTimeout(this.connect, 1000)

          if (!this.connectionStartedCBs.find((cb) => cb === reconnectAction)) {
            this.connectionStartedCBs.push(reconnectAction)
          }
        }
      }

      this.connection.onerror = async (e) => {
        console.error('===WS==>  onerror: ', e)
        this.connection = null
        setTimeout(this.close, 1000)
      }

      if (DEBUG_WS) console.log('===WS==> 2')

      this.connection.onopen = async (e) => {
        if (DEBUG_WS) console.info('===WS==> onopen: ', e)

        this.connectionCount++

        setTimeout(() => {
          this._setPing()
        }, 1000)

        if (this.connectionStartedCBs.length) {
          this.connectionStartedCBs.forEach((cb) => {
            cb(this)
          })
          this.connectionStartedCBs = []
        }

        if (!this.reconnectionAttemptStart && this.connectionCount > 1) {
          reSubscribe(this)
        }

        this.reconnectionAttemptStart = null
      }

      if (DEBUG_WS) console.log('===WS==> 3')

      ctx.$services.expectant.init(this.connection)
    }

    close = async () => {
      await this.connection?.close()
    }

    restart = async () => {
      // пo onclose сервис начнёт реконнектится
      await this.close()
    }

    subscribe_single_special = async (event_type, identity) => {
      if (event_type == null || identity == null) return

      if (!this.subscriptions['special_event_' + event_type + identity]) {
        this.subscriptions['special_event_' + event_type + identity] = [
          event_type,
          identity,
        ]
      }

      if (this.connection) {
        await this._send(
          JSON.stringify({
            type: 'special_event',
            event_type,
            identity,
          }),
        )
      } else {
        console.warn(
          `subscribe_single => connection is null. failed for special_event ${event_type}:${identity}`,
        )
      }
    }

    subscribe_single = async (data_type, id, window) => {
      if (window == null || typeof window === 'symbol') return

      if (!this.subscriptions[data_type + id]) {
        this.subscriptions[data_type + id] = new Set()
      }
      if (this.subscriptions[data_type + id].has(window)) {
        return
      }

      this.subscriptions[data_type + id].add(window)
      if (this.connection) {
        await this._send(
          JSON.stringify({
            type: 'subscribe',
            data_type: data_type,
            object_id: id,
            window_handler: window,
          }),
        )
      } else {
        console.warn(
          `subscribe_single => connection is null. failed for ${data_type}:${id}`,
        )
      }
    }

    subscribe_single_obj = async (obj, window) => {
      await this.subscribe_single(obj.data_type, obj.id, window)
    }

    unsubscribe_single = async (data_type, id, window) => {
      if (this.subscriptions[data_type + id]) {
        this.subscriptions[data_type + id].delete(window)
      }

      if (this.connection) {
        await this._send(
          JSON.stringify({
            type: 'unsubscribe',
            data_type: data_type,
            object_id: id,
            window_handler: window,
          }),
        )
      } else {
        console.warn(
          `unsubscribe_single => connection is null. failed for ${data_type}:${id}`,
        )
      }
    }

    unsubscribe_single_obj = async (obj, window) => {
      await this.unsubscribe_single(obj.data_type, obj.id, window)
    }
  }
  ctx.$services.websocket = new WebsocketService()
  // ctx.$services.auth = new AuthService()
}
