<template>
    <CModal
      ref="modal"
      :visible="visible"
      size="sm"
      :fullscreen="false"
      backdrop="static"
      alignment="center"
      @close="onComplete"
    >
      <CModalHeader dismiss>
        <CModalTitle>Лид ушёл!</CModalTitle>
      </CModalHeader>
      <CModalFooter>
        <div>
          <CButton color="primary" @click="onComplete">Перейти в список</CButton>
        </div>
      </CModalFooter>
    </CModal>
</template>
<script setup>
import {onMounted, onUnmounted, ref} from 'vue'
import {useRouter} from 'vue-router'

const emit = defineEmits(['close'])

const visible = ref(false)
const router = useRouter()
let completeTO = null

const onClose = () => {
  visible.value = false
  emit('close')
}
const onComplete = async () => {
  onClose()
  router.push({path: '/leads/'})
}

onMounted(() => {
  visible.value = true
  completeTO = setTimeout(() => {
    onComplete()
  }, 10e3)
})
onUnmounted(() => {
  clearTimeout(completeTO)
})
</script>
