import { ref } from 'vue'
import { useAccessWS } from '@/composables/accessWS'
import { getWSMessage } from '@/helpers'

export function useObjectChatListen({
  messageApi,
  identityApi,
  userApi,
  storage,
  WH,
  websocket,
  listLimit = 4,
}) {
  const { setWSAction } = useAccessWS(websocket)
  const chat = ref(null)
  const messages = ref([])
  const chatRelIdens = ref({})
  const chatRelUsers = ref({})
  const loadingChat = ref(false)

  const onEnd = () => {
    loadingChat.value = false
  }
  const onError = (err) => {
    onEnd()
    console.error(err)
  }

  const getRelUsers = async (messages) => {
    const rIden = await storage.getRelations(
      messages,
      'sender',
      'account__identity',
      identityApi.identityRetrieve,
      WH,
    )
    chatRelIdens.value = Object.assign(chatRelIdens.value, rIden)

    const rUsers = await storage.getRelations(
      Object.values(chatRelIdens.value),
      'user',
      'account__user',
      userApi.userRetrieve,
      WH,
    )
    chatRelUsers.value = Object.assign(chatRelUsers.value, rUsers)
  }

  const setupWS = (chatId) => {
    websocket.connection.addEventListener('message', (ev) => {
      const message = getWSMessage(ev)
      if (!message) return
      if (message.type === 'chat') {
        const id = message.data?.chat
        if (chatId !== id) return
        messages.value = [
          ...messages.value.slice((listLimit - 1) * -1),
          message.data,
        ]
      }
    })
  }

  const getHistory = (chatId) => {
    messageApi
      .chatMessagesList(WH, chatId, { limit: listLimit })
      .then((res) => {
        const _messages = res.data?.slice(0, listLimit)?.reverse() || []
        getRelUsers(_messages)
          .then(() => {
            messages.value = _messages
          })
          .catch((err) => {
            onError(err)
          })
          .finally(() => {
            onEnd()
          })
      })
      .catch((err) => {
        onError(err)
      })
  }

  const getChat = (objId, objType) => {
    const filters = {}
    filters[objType] = objId
    messageApi
      .chatList(WH, { filters })
      .then((res) => {
        chat.value = res.data?.length ? res.data[0] : null
        if (!chat.value) {
          onEnd()
          return
        }
        getHistory(chat.value.id)
        setWSAction(() => {
          setupWS(chat.value.id)
        })
      })
      .catch((err) => {
        onError(err)
      })
  }

  const setChatListen = (objId, objType) => {
    loadingChat.value = true
    getChat(objId, objType)
  }

  return {
    chat,
    loadingChat,
    setChatListen,
    chatRelIdens,
    chatRelUsers,
    messages,
  }
}
