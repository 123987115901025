// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ScriptApiMethodsCls {
    async exitList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // filters: created_at, updated_at, script, base_question, next_question, label, sort_order
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('exitList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.exit.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async exitCreate(wh, data, extra_data = null) {
      // data: Exit
      console.log('exitCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.exit.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async exitRetrieve(wh, id, extra_data = null) {
      console.log('exitRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('script__exit', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'script__exit', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.exit.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async exitUpdate(wh, id, data, extra_data = null) {
      // data: Exit
      console.log('exitUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.exit.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async exitDelete(wh, id) {
      console.log('exitDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.scripts.exit.delete(id)})
      ctx.$services.storage.del('script__exit', id, wh)
      return null
    }
    async questionList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // filters: created_at, updated_at, script, stage, name, type, variable, condition, required,
      //          required_force, template, manager_information, sort_order, base_question
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('questionList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.question.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async questionCreate(wh, data, extra_data = null) {
      // data: Question
      console.log('questionCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.question.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async questionRetrieve(wh, id, extra_data = null) {
      console.log('questionRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('script__question', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'script__question', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.question.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async questionUpdate(wh, id, data, extra_data = null) {
      // data: Question
      console.log('questionUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.question.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async questionDelete(wh, id) {
      console.log('questionDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.scripts.question.delete(id)})
      ctx.$services.storage.del('script__question', id, wh)
      return null
    }
    async scriptList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // filters: created_at, updated_at, name, description
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('scriptList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async scriptCreate(wh, data, extra_data = null) {
      // data: ScriptList
      console.log('scriptCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async scriptRetrieve(wh, id, extra_data = null) {
      console.log('scriptRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('script__salesscript', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'script__salesscript', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async scriptUpdate(wh, id, data, extra_data = null) {
      // data: ScriptList
      console.log('scriptUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async scriptDelete(wh, id) {
      console.log('scriptDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.scripts.script.delete(id)})
      ctx.$services.storage.del('script__salesscript', id, wh)
      return null
    }
    async scriptDeepRetrieve(wh, id, extra_data = null) {
      console.log('scriptDeepRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.deep.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async stageList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // filters: created_at, updated_at, script, start, name, description, sort_order
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('stageList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.stage.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async stageCreate(wh, data, extra_data = null) {
      // data: Stage
      console.log('stageCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.stage.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async stageRetrieve(wh, id, extra_data = null) {
      console.log('stageRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('script__stage', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'script__stage', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.stage.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async stageUpdate(wh, id, data, extra_data = null) {
      // data: Stage
      console.log('stageUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.stage.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async stageDelete(wh, id) {
      console.log('stageDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.scripts.stage.delete(id)})
      ctx.$services.storage.del('script__stage', id, wh)
      return null
    }
    async variableList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // filters: created_at, updated_at, script, title, default, lead_field, type, content_type,
      //          sort_order
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('variableList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.variable.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async variableCreate(wh, data, extra_data = null) {
      // data: Variable
      console.log('variableCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.variable.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async variableRetrieve(wh, id, extra_data = null) {
      console.log('variableRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('script__variable', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'script__variable', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.variable.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async variableUpdate(wh, id, data, extra_data = null) {
      // data: Variable
      console.log('variableUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.variable.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async variableDelete(wh, id) {
      console.log('variableDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.scripts.variable.delete(id)})
      ctx.$services.storage.del('script__variable', id, wh)
      return null
    }
  }

  ctx.$services.scriptApi = new ScriptApiMethodsCls()
}

