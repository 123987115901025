// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BalanceApiMethodsCls {
    async balanceList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение текущих балансов всех контрагентов системы
      // filters: owner_entity, contractor_entity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('balanceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.balance.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async balanceForDateList(wh, date, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение балансов всех контрагентов системы на дату
      // filters: owner_entity, contractor_entity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('balanceForDateList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.balanceForDate.list(date, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async contractorBalanceList(wh, contractor_entity_id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение текущих балансов контрагента системы
      // filters: owner_entity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('contractorBalanceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.contractorBalance.list(contractor_entity_id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async contractorBalanceForDateList(wh, contractor_entity_id, date, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение балансов контрагента системы на дату
      // filters: owner_entity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('contractorBalanceForDateList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.finance.contractorBalanceForDate.list(contractor_entity_id, date, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.balanceApi = new BalanceApiMethodsCls()
}

