// Этот файл сгенерирован автоматически. Не надо его редактировать.
import accountApiMethods from  './accountApiMethods'
import companyApiMethods from  './companyApiMethods'
import calendarApiMethods from  './calendarApiMethods'
import identityApiMethods from  './identityApiMethods'
import devApiMethods from  './devApiMethods'
import inviteApiMethods from  './inviteApiMethods'
import authApiMethods from  './authApiMethods'
import userApiMethods from  './userApiMethods'
import tourApiMethods from  './tourApiMethods'
import coreApiMethods from  './coreApiMethods'
import genericApiMethods from  './genericApiMethods'
import bookerApiMethods from  './bookerApiMethods'
import bookingApiMethods from  './bookingApiMethods'
import buyerApiMethods from  './buyerApiMethods'
import buyerContractApiMethods from  './buyerContractApiMethods'
import transferApiMethods from  './transferApiMethods'
import commentApiMethods from  './commentApiMethods'
import fmsApiMethods from  './fmsApiMethods'
import identityTaskApiMethods from  './identityTaskApiMethods'
import leadApiMethods from  './leadApiMethods'
import operationsApiMethods from  './operationsApiMethods'
import organizationApiMethods from  './organizationApiMethods'
import personApiMethods from  './personApiMethods'
import reminderApiMethods from  './reminderApiMethods'
import tagApiMethods from  './tagApiMethods'
import widgetApiMethods from  './widgetApiMethods'
import ArticleApiMethods from  './ArticleApiMethods'
import agencyBalanceApiMethods from  './agencyBalanceApiMethods'
import agencyEntryApiMethods from  './agencyEntryApiMethods'
import balanceApiMethods from  './balanceApiMethods'
import hmsApiMethods from  './hmsApiMethods'
import occupationApiMethods from  './occupationApiMethods'
import periodApiMethods from  './periodApiMethods'
import reservationApiMethods from  './reservationApiMethods'
import priceApiMethods from  './priceApiMethods'
import queryApiMethods from  './queryApiMethods'
import roomApiMethods from  './roomApiMethods'
import integrationsOneCApiMethods from  './integrationsOneCApiMethods'
import integrationsSberQrApiMethods from  './integrationsSberQrApiMethods'
import legalApiMethods from  './legalApiMethods'
import bankAccountApiMethods from  './bankAccountApiMethods'
import buyerPaymentApiMethods from  './buyerPaymentApiMethods'
import agencyPaymentApiMethods from  './agencyPaymentApiMethods'
import commissionerReportApiMethods from  './commissionerReportApiMethods'
import commissionApiMethods from  './commissionApiMethods'
import contractApiMethods from  './contractApiMethods'
import documentTemplateApiMethods from  './documentTemplateApiMethods'
import entityApiMethods from  './entityApiMethods'
import financeApiMethods from  './financeApiMethods'
import paymentApiMethods from  './paymentApiMethods'
import mediaApiMethods from  './mediaApiMethods'
import messageApiMethods from  './messageApiMethods'
import nsbOldApiMethods from  './nsbOldApiMethods'
import hotelApiMethods from  './hotelApiMethods'
import ageApiMethods from  './ageApiMethods'
import attributeApiMethods from  './attributeApiMethods'
import refbookApiMethods from  './refbookApiMethods'
import categoryApiMethods from  './categoryApiMethods'
import diseaseApiMethods from  './diseaseApiMethods'
import mealApiMethods from  './mealApiMethods'
import programApiMethods from  './programApiMethods'
import shiftApiMethods from  './shiftApiMethods'
import hotelServiceApiMethods from  './hotelServiceApiMethods'
import locationApiMethods from  './locationApiMethods'
import websiteApiMethods from  './websiteApiMethods'
import reportsApiMethods from  './reportsApiMethods'
import scheduleApiMethods from  './scheduleApiMethods'
import scriptApiMethods from  './scriptApiMethods'
import supportApiMethods from  './supportApiMethods'
import telephonyApiMethods from  './telephonyApiMethods'
import contactApiMethods from  './contactApiMethods'
import localPhoneApiMethods from  './localPhoneApiMethods'
import phoneApiMethods from  './phoneApiMethods'
import externalsApiMethods from  './externalsApiMethods'
import versionApiMethods from  './versionApiMethods'
export const apiList = [
  accountApiMethods,
  companyApiMethods,
  calendarApiMethods,
  identityApiMethods,
  devApiMethods,
  inviteApiMethods,
  authApiMethods,
  userApiMethods,
  tourApiMethods,
  coreApiMethods,
  genericApiMethods,
  bookerApiMethods,
  bookingApiMethods,
  buyerApiMethods,
  buyerContractApiMethods,
  transferApiMethods,
  commentApiMethods,
  fmsApiMethods,
  identityTaskApiMethods,
  leadApiMethods,
  operationsApiMethods,
  organizationApiMethods,
  personApiMethods,
  reminderApiMethods,
  tagApiMethods,
  widgetApiMethods,
  ArticleApiMethods,
  agencyBalanceApiMethods,
  agencyEntryApiMethods,
  balanceApiMethods,
  hmsApiMethods,
  occupationApiMethods,
  periodApiMethods,
  reservationApiMethods,
  priceApiMethods,
  queryApiMethods,
  roomApiMethods,
  integrationsOneCApiMethods,
  integrationsSberQrApiMethods,
  legalApiMethods,
  bankAccountApiMethods,
  buyerPaymentApiMethods,
  agencyPaymentApiMethods,
  commissionerReportApiMethods,
  commissionApiMethods,
  contractApiMethods,
  documentTemplateApiMethods,
  entityApiMethods,
  financeApiMethods,
  paymentApiMethods,
  mediaApiMethods,
  messageApiMethods,
  nsbOldApiMethods,
  hotelApiMethods,
  ageApiMethods,
  attributeApiMethods,
  refbookApiMethods,
  categoryApiMethods,
  diseaseApiMethods,
  mealApiMethods,
  programApiMethods,
  shiftApiMethods,
  hotelServiceApiMethods,
  locationApiMethods,
  websiteApiMethods,
  reportsApiMethods,
  scheduleApiMethods,
  scriptApiMethods,
  supportApiMethods,
  telephonyApiMethods,
  contactApiMethods,
  localPhoneApiMethods,
  phoneApiMethods,
  externalsApiMethods,
  versionApiMethods,
]
