// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class TelephonyApiMethodsCls {
    async backcallCreate(wh, data, extra_data = null) {
      // data: BackCall
      // Обратный звонок
      console.log('backcallCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.backcall.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async clickCreate(wh, data, extra_data = null) {
      // data: BackCall
      // Хук обратного звонка
      console.log('clickCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.click.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async serverRetrieve(wh, extra_data = null) {
      // Получить информацию о сервере телефонии
      console.log('serverRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.server.retrieve()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async serverPost(wh, data, extra_data = null) {
      // data: TelephonyServer
      // Задать сервер телефонии
      console.log('serverPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.server.post(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async telecallList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка телефонных звонков
      // Получение списка телефонных звонков с возможностью фильтрации по параметрам.
      //         Дополнительно возвращается related_data которая содержит:
      //         - account__identity - информация о личностях связанных с звонком (поля identity и initiator)
      //         - account__user - информация о пользователе  (поля identity.user и initiator.user)
      //         - core__person - информация о персоне (поле initiator.person)
      // filters: created_at, identity, initiator, phone, local_phone, started_at, stopped_at,
      //          ring_duration, call_duration, event_type, tele_call_type, telephony_id, object_id,
      //          object_type, did
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('telecallList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.telecall.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async telecallCreate(wh, data, extra_data = null) {
      // data: TeleCall
      // Создание телефонного звонка
      console.log('telecallCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.telecall.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async telecallsForObjectList(wh, data_type, object_id, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка телефонных звонков связанных с объектом
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('telecallsForObjectList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.telecallsForObject.list(data_type, object_id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.telephonyApi = new TelephonyApiMethodsCls()
}

