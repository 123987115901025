// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ArticleApiMethodsCls {
    async articleList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Список Article
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('articleList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.docs.article.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async articleHandleList(wh, handle, extra_data = null) {
      // Получение Article по handle
      console.log('articleHandleList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.docs.article.handle.list(handle)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async articleRetrieve(wh, id, extra_data = null) {
      // Получение Article по id
      console.log('articleRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('docs__article', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'docs__article', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.docs.article.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async searchPut(wh, data, extra_data = null) {
      // data: ArticleSearch
      // Поиск Article
      console.log('searchPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.docs.search.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.ArticleApi = new ArticleApiMethodsCls()
}

