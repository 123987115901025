<template>
  <div class="h-100">
    <div v-if="showAddNewChat" class="flex flex-column">
      <div v-if="newChatDialog" class="flex flex-column mb-3">
        <b class="mb-2">Дополнительные параметры нового чата</b>
        <div class="flex gap-3">
          <div>
            <CFormLabel for="newChatName">Имя собеседника</CFormLabel>
            <CFormInput id="newChatName" v-model="newChatName" size="sm" />
          </div>
          <div>
            <CFormLabel for="newChatPhone">Телефон собеседника</CFormLabel>
            <CFormInput id="newChatPhone" v-model="newChatPhone" size="sm" />
          </div>
        </div>
      </div>
      <div class="flex mb-3 gap-3">
        <CButton
          v-if="newChatDialog"
          color="light"
          class="flex align-items-center"
          @click="newChatDialog = false"
          size="sm"
        >
          <span class="px-2">Закрыть</span>
        </CButton>
        <CButton
          color="primary"
          class="flex align-items-center"
          @click="createNewChat"
          size="sm"
        >
          <AppIcon icon="create" />
          <span class="px-2">{{
            newChatDialog ? 'Создать' : 'Новый чат'
          }}</span>
        </CButton>
      </div>
    </div>
    <div id="chat-group-wrap" class="h-100">
      <div
        id="chat-group"
        class="overflow-scroll"
        :style="`height: ${chatGroupMaxHeight}px;`"
      >
        <TransitionGroup name="sort-list" class="position-relative p-0">
          <div
            v-for="chat in chatGroup"
            :key="chat.id"
            class="message py-3 pe-3 border-bottom border-bottom-1 border-opacity-10"
            role="button"
            @click="openChat(chat)"
          >
            <div class="flex justify-content-between">
              <div
                class="text-truncate"
                :class="{
                  'text-primary text-decoration-underline': chat.id === chatId,
                  'fw-semibold': !chat.lastMessage?.read_at,
                }"
              >
                {{ chat.title }}
              </div>
              <div class="position-relative p-1">
                <CBadge
                  class="badge-dot top-50"
                  :color="chat.is_customer_online ? 'success' : 'secondary'"
                  :title="
                    chat.is_customer_online ? 'Клиент онлайн' : 'Клиент офлайн'
                  "
                  position="top-start"
                  shape="rounded-circle"
                >
                  <span class="visually-hidden">{{
                    chat.is_customer_online ? 'success' : 'secondary'
                  }}</span>
                </CBadge>
              </div>
            </div>
            <div v-if="chat.lastMessage" class="flex justify-content-between">
              <div class="flex gap-2 align-items-center" style="max-width: 80%">
                <small class="text-medium-emphasis fw-semibold nobr">
                  {{ getUserName(chat.lastMessage) || 'Анонимный' }}:
                </small>
                <small class="text-medium-emphasis text-truncate">
                  {{ chat.lastMessage.content }}
                </small>
              </div>

              <div
                v-if="chat.lastMessage"
                class="form-label fw-semibold fst-normal m-0"
              >
                {{ dateService.dayTimeLiteral(chat.lastMessage.created_at) }}
              </div>
            </div>
          </div>
        </TransitionGroup>
      </div>
      <div
        v-if="loadingChatList"
        id="chat-group-spinn-wrap"
        class="position-relative"
      >
        <div
          class="position-absolute w-100 flex justify-content-center align-items-center text-center"
          style="height: 30px"
        >
          <CSpinner component="span" size="sm" aria-hidden="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { debounce } from 'lodash'
import C from '@/config/back_const'
import { useAuthData } from '@/composables/authData'
import { useAccessWS } from '@/composables/accessWS'
import { getWSMessage } from '@/helpers'

const { storage, websocket, dateService, messageApi, identityApi, userApi } =
  inject('services')
const WH = 'b974aea6-b815-4c2d-88a6-b779dd908849'
defineProps({
  chatId: {
    type: [Number, String],
    required: false,
    default: () => null,
  },
})
const emit = defineEmits(['open-chat', 'chat-group'])
const router = useRouter()

const { myIdentity, myUser, authReady } = useAuthData()
const { setWSAction } = useAccessWS(websocket)
const chatGroup = ref([])
const relData = ref({})
const relMessages = ref(null)
const loadingChatList = ref(false)
const resizing = ref(false)
const resizingTO = ref(null)
const chatListPage = ref(1)
const chatGroupWrapEl = ref(null)
const chatGroupEl = ref(null)
const chatGroupMaxHeight = ref(0)
const limit = 20

const showAddNewChat = ref(false) // временно чтобы скрывать добавление нового чата (ещё не реализовано)
const newChatDialog = ref(false)
const newChatPhone = ref('')
const newChatName = ref('')
const insertedChatsN = ref(0)

let onResize = null
let resizeObserver = null
let onScroll = null

const openChat = (chatObj) => {
  emit('open-chat', chatObj)
  // сейчас переход на тур/лид сам откроет чат, но всё-равно дублируем открытие emit('open-chat', id) на случай если та логика изменится
  const path = chatObj.tour
    ? `/tour/${chatObj.tour}/`
    : chatObj.lead
    ? `/lead/${chatObj.lead}/`
    : '/'
  router.push({ path })
}
const createNewChat = async () => {
  if (!newChatDialog.value) {
    newChatDialog.value = true
  } else {
    newChatDialog.value = false
    try {
      // todo создавать чат
      // demo
      const chat = {
        id: Math.floor(Math.random() * 100),
        phone: newChatPhone.value,
        name: newChatName.value,
        title: 'Новый чат',
      }
      chatGroup.value.push({ ...chat, messages: [] })
      newChatPhone.value = ''
      newChatName.value = ''
    } catch (err) {
      console.error(err)
    }
  }
}
const getChatById = (id) => {
  const idx = chatGroup.value.findIndex((chat) => id === chat.id)
  return idx !== -1 ? chatGroup.value[idx] : null
}
const setLastMessage = (message) => {
  const chat = getChatById(message.chat)
  if (chat) {
    chat.lastMessage = message
  }
}
const updateRelMessages = async (chatsArr) => {
  relMessages.value = relMessages.value || {}
  const rel = await storage.getRelations(
    chatsArr,
    'last_message',
    'messaging__message',
    messageApi.messageRetrieve,
    WH,
  )
  for (const [key, val] of Object.entries(rel)) {
    relMessages.value[key] = val
  }

  return rel
}
const updateRelUsers = async (messageArr) => {
  relData.value = relData.value || {}
  relData.value.identities = relData.value.identities || {}
  relData.value.users = relData.value.users || {}
  const rIden = await storage.getRelations(
    messageArr,
    'sender',
    'account__identity',
    identityApi.identityRetrieve,
    WH,
  )
  for (const [key, val] of Object.entries(rIden)) {
    relData.value.identities[key] = val
  }
  const rUsers = await storage.getRelations(
    Object.values(relData.value.identities),
    'user',
    'account__user',
    userApi.userRetrieve,
    WH,
  )
  for (const [key, val] of Object.entries(rUsers)) {
    relData.value.users[key] = val
  }
}
const getUserName = (message) => {
  const data = relData.value
  const sender = message.sender
  if (!data.users || !data.identities || !sender) return null
  const user = data.identities[sender]
    ? data.users[data.identities[sender].user]
    : myUser.value
  return user ? `${user?.name || ''} ${user?.surname || ''}`.trim() : null
}
const getChatList = async (refresh) => {
  try {
    loadingChatList.value = true
    let page = chatListPage.value
    if (refresh) {
      page =
        (chatGroup.value.length - insertedChatsN.value) / limit ===
        chatListPage.value - 1
          ? chatListPage.value
          : chatListPage.value - 1
    }
    const res = await messageApi.chatList(WH, { limit, page })
    if (res.data.length !== 0) {
      chatListPage.value = page + 1
    }
    return res.data
  } catch (err) {
    console.error(err)
  } finally {
    loadingChatList.value = false
  }
}
const updRelForMessages = (relMessagesObj) => {
  const messages = Object.values(relMessagesObj)
  updateRelUsers(messages)
  for (const message of messages) {
    setTimeout(() => {
      setLastMessage(message)
    })
  }
}
const updateChatList = async (refresh) => {
  const list = (await getChatList(refresh)) || []
  const filteredList = refresh
    ? list.filter(
        (item) =>
          !chatGroup.value.some((ch) => {
            return ch.id === item.id
          }),
      )
    : list
  chatGroup.value = chatGroup.value.concat(filteredList)

  const newRelMessages = await updateRelMessages(filteredList)

  // сортировка по дате last_message
  chatGroup.value.sort((itemA, itemB) => {
    const lmDateA = relMessages.value[itemA.last_message]?.created_at
      ? new Date(relMessages.value[itemA.last_message].created_at)
      : null
    const lmDateB = relMessages.value[itemB.last_message]?.created_at
      ? new Date(relMessages.value[itemB.last_message].created_at)
      : null
    return lmDateA > lmDateB ? -1 : lmDateA === lmDateB ? 0 : 1
  })

  emit('chat-group', chatGroup.value)

  updRelForMessages(newRelMessages)
}
const scrollHandler = async () => {
  if (resizing.value) return
  const isScrollEnd =
    chatGroupEl.value.offsetHeight + chatGroupEl.value.scrollTop >
    chatGroupEl.value.scrollHeight
  if (isScrollEnd && !loadingChatList.value) {
    await updateChatList(true)
    loadingChatList.value = true
    nextTick(() => {
      setTimeout(() => {
        // timeout для случая если ничего нового не отрендерилось
        // После подгрузки оставляем скролл почти внизу чтобы можно было продолжать скролл колесом
        chatGroupEl.value.scroll(
          0,
          chatGroupEl.value.scrollHeight - chatGroupEl.value.offsetHeight - 1,
        )
      }, 50)
      setTimeout(() => {
        loadingChatList.value = false
      }, 550)
    })
  }
}
const resizeHandler = () => {
  clearTimeout(resizingTO.value)
  resizing.value = true
  chatGroupMaxHeight.value = chatGroupWrapEl.value?.offsetHeight - 20 || 0
  resizingTO.value = setTimeout(() => {
    resizing.value = false
  }, 500)
}
const getChatIndexById = (id) => {
  return chatGroup.value.findIndex((item) => item.id === id)
}
const insertNewChat = async (data) => {
  if (data.chat != null) {
    try {
      storage.clr(WH)
      const chat = await messageApi.chatRetrieve(WH, data.chat)
      if (chat != null) {
        chatGroup.value.unshift(chat)
        const newRelMessages = await updateRelMessages([chat])
        updRelForMessages(newRelMessages)
        insertedChatsN.value += 1
      }
    } catch (err) {
      console.error(err)
    }
  }
}
const putExistingChatOnTop = (chatIndex) => {
  chatGroup.value = chatGroup.value.splice(chatIndex, 1).concat(chatGroup.value)
}

const wsAction = () => {
  websocket.connection.addEventListener('message', (ev) => {
    const message = getWSMessage(ev)
    if (!message) return

    if (message.type === 'chat') {
      const id = message.data?.chat
      const chatIndex = getChatIndexById(id)
      if (chatIndex !== -1) {
        chatGroup.value[chatIndex].lastMessage = message.data
        putExistingChatOnTop(chatIndex)
      }
      return
    }

    // обновляем данные чата. Имеет смысл только для чатов которые сейчас видны в списке (находятся в группе)
    if (
      message.type === 'notifier' &&
      message.data?.data_type === 'messaging__chat'
    ) {
      const chatIndex = getChatIndexById(message.data.id)
      if (chatIndex !== -1) {
        chatGroup.value[chatIndex] = message.data
        emit('chat-group', chatGroup.value)
      }
    }

    if (
      message.type === 'special_event' &&
      message.data.event_type === C.SPECIAL_EVENTS_CHAT_CHANGE
    ) {
      // добавить новый чат в список если появился новый лид с чатом
      // или пришло новое сообщение но чат находится не в этом чанке
      const intData = message.data.data
      if (intData.chat == null || intData.identity !== myIdentity.value?.id)
        return
      const chatIndex = getChatIndexById(intData.chat)
      if (chatIndex === -1) {
        insertNewChat(intData)
      }
    }
  })

  websocket.subscribe_single_special(C.SPECIAL_EVENTS_CHAT_CHANGE, myIdentity.value?.id)
}

watch(authReady, (val) => {
  if (val) setWSAction(wsAction)
})

onMounted(async () => {
  try {
    await updateChatList()
    nextTick(() => {
      chatGroupWrapEl.value = document.getElementById('chat-group-wrap')
      chatGroupEl.value = document.getElementById('chat-group')

      if (chatGroupWrapEl.value) {
        onResize = debounce(resizeHandler, 50)
        resizeObserver = new ResizeObserver(onResize)
        resizeObserver.observe(chatGroupWrapEl.value)
      }

      if (chatGroupEl.value) {
        onScroll = debounce(scrollHandler, 100)
        chatGroupEl.value.addEventListener('scroll', onScroll)
      }
    })
  } catch (err) {
    console.error(err)
  }
})
onUnmounted(() => {
  storage.clr(WH)
  if (chatGroupWrapEl.value) resizeObserver.unobserve(chatGroupWrapEl.value)
  if (chatGroupEl.value)
    chatGroupEl.value.removeEventListener('scroll', onScroll)
})
</script>
