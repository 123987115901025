<template>
  <Teleport v-if="ready && modalWh" :to="`#id-title-${modalWh}`">
    <template v-if="payment.doc_number && payment.doc_date">
      Платёж №{{ payment.doc_number }} от
      {{ dateService.formatDate(payment.doc_date) }}
    </template>
    <template v-else> Платёж #{{ payment.id }} </template>
  </Teleport>
  <div>
    <CNav variant="tabs" role="tablist">
      <CNavItem v-for="(tab, index) in tabs" :key="tab.name">
        <CNavLink
          :active="activeTab === index"
          href="javascript:void(0)"
          @click="activeTab = index"
        >
          {{ tab.title }}
        </CNavLink>
      </CNavItem>
    </CNav>

    <CTabContent style="min-height: 600px">
      <CTabPane
        v-for="(tab, index) in tabs"
        :key="tab.name"
        role="tabpanel"
        :visible="activeTab === index"
      >
        <component
          :is="tab.component"
          :data="tab.tabData"
          :mode="mode"
          @tab-event="onTabEvent"
        />
      </CTabPane>
    </CTabContent>
  </div>
</template>

<script setup>
import { inject, onUnmounted, onMounted, ref, computed, nextTick } from 'vue'
import PaymentInfoCommonTab from '@/components/_payment/paymentInfoTabs/PaymentInfoCommonTab.vue'
import PaymentInfoDetailsTab from '@/components/_payment/paymentInfoTabs/PaymentInfoDetailsTab.vue'
import PaymentInfoSplitTab from '@/components/_payment/paymentInfoTabs/PaymentInfoSplitTab.vue'
import C from '@/config/back_const'
import { useAuthData } from '@/composables/authData'

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  mode: {
    type: String,
    default: '',
  },
  modalWh: {
    type: String,
    required: false,
    default: null,
  },
})
const emit = defineEmits(['get-data', 'data-ready'])

const {
  storage,
  paymentApi,
  buyerContractApi,
  buyerPaymentApi,
  bank_accountApi,
  entityApi,
  legalApi,
  dateService,
  agencyPaymentApi,
} = inject('services')
const WH = '18679b8d-241e-4064-8b03-62f8c996050f'

const { isSystem } = useAuthData()
const ready = ref(false)
const payment = ref({})
const owner = ref({})
const ownerBankAccount = ref({})
const contractor = ref({})
const contractorBankAccount = ref({})
const isIncoming = ref()
const splitsReq = ref({})
const relInvoices = ref({})
const searchArea = ref([])
const paymentInvoicesType = ref({})

const tabs = [
  {
    name: 'common',
    title: 'Информация о платеже',
    component: PaymentInfoCommonTab,
    tabData: { payment, contractor, isIncoming },
  },
  {
    name: 'details',
    title: 'Реквизиты',
    component: PaymentInfoDetailsTab,
    tabData: {
      payment,
      owner,
      ownerBankAccount,
      contractor,
      contractorBankAccount,
      isIncoming,
    },
  },
  {
    name: 'split',
    title: 'Pазнесения',
    component: PaymentInfoSplitTab,
    tabData: { payment, splitsReq, relInvoices, searchArea },
  },
]
const activeTab = ref(tabs.findIndex((t) => t.name === 'common'))

const agencyPayment = computed(
  () =>
    payment.value.type === C.PAYMENT_TYPE_AGENCY_PENALTY ||
    payment.value.type === C.PAYMENT_TYPE_AGENCY ||
    payment.value.type === C.PAYMENT_TYPE_AGENCY_REFUND ||
    payment.value.type === C.PAYMENT_TYPE_AGENCY_FEE,
)

const paymentDirectionIncome = (payment) => {
  // Возвращает направление платежа
  // true - Входящий платёж
  // false - Исходящий платёж

  if (payment.data_type === 'legal__buyerpayment') return true

  if (isSystem.value) {
    switch (payment.type) {
      case C.PAYMENT_TYPE_AGENCY:
      case C.PAYMENT_TYPE_AGENCY_PENALTY:
      case C.PAYMENT_TYPE_HOTELIER_REFUND:
      case C.PAYMENT_TYPE_HOTELIER_REWARD:
        return true
      default:
        return false
    }
  }

  switch (payment.type) {
    case C.AGENCY_PAYMENT_TYPE_PAYMENT:
      return true
    default:
      return false
  }
}

const onHoldInvoice = async ({ payed_invoice, amount, newInvoice }) => {
  try {
    const paymentArgs = {
      src_payment: payment.value.id,
      // payed_invoice: newInvoice.value.id,
      payed_invoice,
      // amount: newInvoiceSum.value
      amount,
    }
    const buyerPaymentsArgs = {
      amount,
      buyer_payment: payment.value.id,
      buyer_contract: payed_invoice,
    }
    const res = await paymentInvoicesType.value.splitCreate(
      WH,
      props.mode === 'buyer' ? buyerPaymentsArgs : paymentArgs,
    )
    splitsReq.value.data.push(res)
    relInvoices.value = { ...relInvoices.value, [newInvoice.id]: newInvoice }
  } catch (err) {
    console.error(err)
  }
}

// TODO
const onCancelSplit = async ({ id }) => {
  try {
    await paymentApi.paymentSplitAnnulPartial(WH, id, {})
  } catch (err) {
    console.error(err)
  }
}

const onTabEvent = (payload) => {
  if (payload.eventName === 'hold-invoice') {
    onHoldInvoice(payload)
    return
  }
  if (payload.eventName === 'cancel-split') {
    onCancelSplit(payload)
    return
  }
}

onMounted(async () => {
  emit('get-data')
  try {
    if (props.mode === 'buyer') {
      payment.value = await buyerPaymentApi.buyerPaymentRetrieve(WH, props.id)
    } else {
      payment.value = await paymentApi.paymentRetrieve(WH, props.id)
    }

    const relOwnerEntity = await storage.getRelations(
      [payment.value],
      'owner_entity',
      'legal__entity',
      entityApi.entityRetrieve,
      WH,
    )
    const relOwnerBankAccount = await storage.getRelations(
      [payment.value],
      'owner_bank_account',
      'legal__bankaccount',
      bank_accountApi.bankAccountRetrieve,
      WH,
    )
    const relContractorEntity = await storage.getRelations(
      [payment.value],
      'contractor_entity',
      'legal__entity',
      entityApi.entityRetrieve,
      WH,
    )
    const relContractorBankAccount = await storage.getRelations(
      [payment.value],
      'contractor_bank_account',
      'legal__bankaccount',
      bank_accountApi.bankAccountRetrieve,
      WH,
    )

    const searchFilters = [
      {
        name: 'state__in',
        value: [C.DOCUMENT_STATE_NEW, C.DOCUMENT_STATE_PARTIAL],
      },
    ]
    if (payment.value.owner_entity)
      searchFilters.push({
        name: 'owner_entity',
        value: payment.value.owner_entity,
      })
    if (payment.value.contractor_entity)
      searchFilters.push({
        name: 'contractor_entity',
        value: payment.value.contractor_entity,
      })

    if (payment.value.data_type === 'legal__payment') {
      if (agencyPayment.value) {
        paymentInvoicesType.value = {
          content_type: 'legal__agencyinvoice',
          retrieveMethod: legalApi.agencyInvoiceRetrieve,
        }
      } else {
        paymentInvoicesType.value = {
          content_type: 'legal__supplierinvoice',
          retrieveMethod: legalApi.supplierInvoiceRetrieve,
        }
      }
      paymentInvoicesType.value.splitListRetrieve =
        paymentApi.paymentListSplitList
      paymentInvoicesType.value.splitCreate = paymentApi.paymentSplitCreate
      paymentInvoicesType.value.invoiceType = 'payed_invoice'
    }

    if (payment.value.data_type === 'legal__buyerpayment') {
      paymentInvoicesType.value = {
        content_type: 'core__buyercontract',
        retrieveMethod: buyerContractApi.buyerContractRetrieve,
        splitListRetrieve: agencyPaymentApi.buyerPaymentListSplitList,
        splitCreate: agencyPaymentApi.buyerPaymentSplitCreate,
        invoiceType: 'buyer_contract',
      }
    }

    searchArea.value = [
      {
        content_type: paymentInvoicesType.value.content_type,
        filters: searchFilters,
      },
    ]

    splitsReq.value = await paymentInvoicesType.value.splitListRetrieve(
      WH,
      payment.value.id,
    )

    relInvoices.value = await storage.getRelations(
      splitsReq.value.data,
      paymentInvoicesType.value.invoiceType,
      paymentInvoicesType.value.content_type,
      paymentInvoicesType.value.retrieveMethod,
      WH,
    )

    owner.value = relOwnerEntity[payment.value.owner_entity]
    ownerBankAccount.value =
      relOwnerBankAccount[payment.value.owner_bank_account]
    contractor.value = relContractorEntity[payment.value.contractor_entity]
    contractorBankAccount.value =
      relContractorBankAccount[payment.value.contractor_bank_account]
    isIncoming.value = paymentDirectionIncome(payment.value)

    nextTick(() => {
      ready.value = true
    })
  } catch (err) {
    console.error(err)
  } finally {
    emit('data-ready')
  }
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
