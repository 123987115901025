// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class PersonApiMethodsCls {
    async personList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Person
      // filters: name, surname, patronymic, passport_number, fio
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('personList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async personCreate(wh, data, extra_data = null) {
      // data: PersonCreate
      // Создание Person
      // Если роль не CUSTOMER, то должен быть указан buyer
      console.log('personCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async personRetrieve(wh, id, extra_data = null) {
      // Получение Person
      console.log('personRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__person', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__person', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async personUpdate(wh, id, data, extra_data = null) {
      // data: PersonUpdate
      // Обновление Person
      console.log('personUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async personDelete(wh, id) {
      // Удаление Person
      console.log('personDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.person.delete(id)})
      ctx.$services.storage.del('core__person', id, wh)
      return null
    }
  }

  ctx.$services.personApi = new PersonApiMethodsCls()
}

