<template>
    <CModal
      ref="modal"
      :visible="visible"
      size="xl"
      backdrop="static"
      alignment="center"
      @close="onCloseModal"
    >
      <CModalHeader dismiss>
        <CModalTitle></CModalTitle>
      </CModalHeader>
      <CModalBody>
        <strong>Задача: </strong><span>{{ task.name }}</span><br>
        <strong>ID: </strong><span>{{ task.id }}</span><br>
        <strong>celery id: </strong><span>{{ task.task_id }}</span><br>
        <strong>Статус: </strong>
        <span>
           {{ task?.result?.success === 'false' ? 'Не выполнено' : C.IDENTITY_TASK_STATUS_TITLES[task.status] }}
        </span><br>
        <div>
          <strong>Ошибки:</strong>
          <ul class="list-group">
            <li
              v-for="(err, index) in task.result.errors"
              :key="index"
              class="d-block px-2"
            >- {{ err }}</li>
          </ul>
        </div>
      </CModalBody>
      <CModalFooter>
        <div class="flex justify-content-end flex-row-reverse">
          <CButton color="secondary" class="mx-2" @click="onCloseModal">закрыть</CButton>
        </div>
      </CModalFooter>
    </CModal>
</template>
<script setup>
import {inject, onMounted, onUnmounted, ref, watch} from 'vue'
import C from '@/config/back_const.js'

const props = defineProps({
  task: {
    type: Object,
    required: true
  },
  show: {
    type: Boolean,
    required: false,
    default: false
  }
})
const emit = defineEmits(['close'])
const WH = 'b0517869-fe8f-41fe-930c-1714ca0801c0'
const {storage} = inject('services')

const visible = ref(false)

const onCloseModal = () => {
  visible.value = false
  emit('close')
}

watch(() => props.show, (val) => {
  visible.value = val
})

onMounted(() => {
  visible.value = props.show
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
