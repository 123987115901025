// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ReservationApiMethodsCls {
    async hotelReservationRetrieve(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Reservation для отеля
      // filters: created_at, updated_at, status, company, entity, buyer, category, room,
      //          partial_places, group, type, date_in, time_in, date_out, time_out
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('hotelReservationRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__reservation', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__reservation', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.reservation.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async reservationCreate(wh, data, extra_data = null) {
      // data: Reservation
      // Создание Reservation
      console.log('reservationCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reservationRetrieve(wh, id, extra_data = null) {
      // Получение Reservation
      console.log('reservationRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('hms__reservation', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'hms__reservation', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reservationUpdate(wh, id, data, extra_data = null) {
      // data: Reservation
      // Обновление модели Reservation
      console.log('reservationUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reservationDelete(wh, id) {
      // Удаление Reservation, только для статуса Draft
      console.log('reservationDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.hms.reservation.delete(id)})
      ctx.$services.storage.del('hms__reservation', id, wh)
      return null
    }
    async reservationPartial(wh, id, data, extra_data = null) {
      // data: Reservation
      // Обновление полей Reservation
      console.log('reservationPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reservationBookPartial(wh, id, data, extra_data = null) {
      // data: Reservation
      // Запрос на бронирование
      console.log('reservationBookPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.book.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reservationCancelPartial(wh, id, data, extra_data = null) {
      // data: Reservation
      // Переводит в статус CANCELLED. Для любого статуса, кроме DRAFT
      console.log('reservationCancelPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.cancel.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async reservationConfirmPartial(wh, id, data, extra_data = null) {
      // data: Reservation
      // Переводит статус ON_REQUEST в BOOKED
      console.log('reservationConfirmPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.confirm.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.reservationApi = new ReservationApiMethodsCls()
}

