// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ContractApiMethodsCls {
    async contractList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получить список договоров
      // filters: base_contract, company, entity, number, conclusion_date, start_date, finish_date,
      //          approved_at, original_sent_at, signed_at, terminated_at, template, doc_isnull,
      //          object_model, is_active, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('contractList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async contractAgencyList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Список договоров системы с агентством
      // filters: base_contract, company, entity, number, conclusion_date, start_date, finish_date,
      //          approved_at, original_sent_at, signed_at, terminated_at, template, doc_isnull,
      //          object_model, is_active, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('contractAgencyList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async contractAgencyCreate(wh, data, extra_data = null) {
      // data: AgencyContract
      // Создать договор системы с агентством
      console.log('contractAgencyCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractAgencyRetrieve(wh, id, extra_data = null) {
      // Получить договор системы с агентством
      console.log('contractAgencyRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__agencycontract', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__agencycontract', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractAgencyUpdate(wh, id, data, extra_data = null) {
      // data: AgencyContractUpdate
      // Изменить договор системы с агентством
      console.log('contractAgencyUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractAgencyDelete(wh, id) {
      console.log('contractAgencyDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.delete(id)})
      ctx.$services.storage.del('legal__agencycontract', id, wh)
      return null
    }
    async contractHotelierList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Список договоров системы с отелем
      // filters: base_contract, company, entity, number, conclusion_date, start_date, finish_date,
      //          approved_at, original_sent_at, signed_at, terminated_at, template, doc_isnull,
      //          object_model, is_active, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('contractHotelierList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async contractHotelierCreate(wh, data, extra_data = null) {
      // data: HotelierContract
      // Создать договор системы с отелем
      console.log('contractHotelierCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractHotelierFindPut(wh, data, extra_data = null) {
      // data: HotelierContractFind
      // Поиск действующего договора системы с отелем
      console.log('contractHotelierFindPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.find.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractHotelierTermRetrieve(wh, id, extra_data = null) {
      // Получить условие договора системы с отелем (id условия)
      console.log('contractHotelierTermRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__contractterms', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__contractterms', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.term.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractHotelierTermUpdate(wh, id, data, extra_data = null) {
      // data: ContractTerms
      // Обновить условие договора системы с отелем
      console.log('contractHotelierTermUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.term.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractHotelierTermPartial(wh, id, data, extra_data = null) {
      // data: ContractTerms
      console.log('contractHotelierTermPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.term.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractHotelierRetrieve(wh, id, extra_data = null) {
      // Получить договор системы с отелем
      console.log('contractHotelierRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__hoteliercontract', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__hoteliercontract', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractHotelierUpdate(wh, id, data, extra_data = null) {
      // data: HotelierContractUpdate
      // Изменить договор системы с отелем
      console.log('contractHotelierUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractHotelierDelete(wh, id) {
      console.log('contractHotelierDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.delete(id)})
      ctx.$services.storage.del('legal__hoteliercontract', id, wh)
      return null
    }
    async contractRetrieve(wh, id, extra_data = null) {
      // Получить конкретный договор без данных соответствующего ему типа
      console.log('contractRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__contract', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__contract', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractApprovePartial(wh, id, extra_data = null) {
      // Одобрить договор
      console.log('contractApprovePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.approve.partial(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractOriginalSendPartial(wh, id, extra_data = null) {
      // Выслать оригинал договора
      console.log('contractOriginalSendPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.originalSend.partial(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractResumePartial(wh, id, extra_data = null) {
      // Возобновить договор
      console.log('contractResumePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.resume.partial(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractSignPartial(wh, id, extra_data = null) {
      // Подписать договор
      console.log('contractSignPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.sign.partial(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async contractTerminatePartial(wh, id, extra_data = null) {
      // Расторгнуть договор
      console.log('contractTerminatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.terminate.partial(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async hotelierContractWithBookingsForDateList(wh, date, option, extra_data = null) {
      // Получить список договоров по которым были операции в заданном  периоде
      // date - дата, option - одно из значений константы COMMISSIONER_REPORT_CREATE_OPTION
      console.log('hotelierContractWithBookingsForDateList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.hotelierContract.withBookingsForDate.list(date, option)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.contractApi = new ContractApiMethodsCls()
}

