// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BankAccountApiMethodsCls {
    async bankAccountList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка BankAccount
      // filters: id, bank_name, entity, ks, rs, bik
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('bankAccountList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async bankAccountCreate(wh, data, extra_data = null) {
      // data: BankAccount
      // Создание BankAccount
      console.log('bankAccountCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bankAccountRetrieve(wh, id, extra_data = null) {
      // Получение модели BankAccount
      console.log('bankAccountRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__bankaccount', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__bankaccount', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bankAccountDelete(wh, id) {
      // Удаление BankAccount
      console.log('bankAccountDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.delete(id)})
      ctx.$services.storage.del('legal__bankaccount', id, wh)
      return null
    }
    async bankAccountPartial(wh, id, data, extra_data = null) {
      // data: BankAccount
      console.log('bankAccountPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bankAccountSetMainPartial(wh, id, data, extra_data = null) {
      // data: BankAccount
      // Сделать счёт основным для юр. лица
      console.log('bankAccountSetMainPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.setMain.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.bank_accountApi = new BankAccountApiMethodsCls()
}

