/* eslint-disable */

/* Можно использовать */

/*
Хранилище объектов

Помещает объект или массив объектов в хранилище.
При наличии в хранилище объекта данные нового объекта заносятся в старый.
При отсутствии в хранилище, объект помещается хранилище.
Возвращается объект, находящийся в хранилище.
Если был передан массив объектов, то возвращается соответствующий массив объектов из хранилища.

*/


import {setURLDebug} from "@/helpers";

const DEBUG_STORAGE = false
let counter = 0

import { reactive } from 'vue'

export default ctx => {

  if (DEBUG_STORAGE) console.log('------------------- StorageService ----------------')

  class StorageService {
    constructor() {
      this._storage = {}
      this.data = {}
      setURLDebug('storage', this)
    }

    storage = (window, data_type) => {
      if (window == null) return
      if (this._storage[window] === undefined) {
        this._storage[window] = {}
      }
      if (this._storage[window][data_type] === undefined) {
        this._storage[window][data_type] = {}
      }
      return this._storage[window][data_type]
    }

    // не использовать
    // TODO будет удалено
    setItem(data, mainDataName) {
      if (DEBUG_STORAGE) console.log(`setItem(${data}, ${mainDataName})`)
    }

    // не использовать
    // TODO будет удалено
    getItemById(name, value) {
      if (DEBUG_STORAGE) console.log(`getItemById(${data}, ${mainDataName})`)
      return this.data?.[name]?.find(item => item.id === value)
    }

    // не использовать
    // TODO будет удалено
    reset() {
      if (DEBUG_STORAGE) console.log(`reset`)
    }

    _ids_key(window) {
      let ids = []
      if (this._storage[window]) {
        if (DEBUG_STORAGE) console.log('_ids_key 2', window)
        Object.keys(this._storage[window]).forEach(data_type => {
          if (DEBUG_STORAGE) console.log('_ids_key 3', data_type)
          Object.keys(this._storage[window][data_type]).forEach(id => {
            if (DEBUG_STORAGE) console.log('_ids_key 4', id)
            ids.push({data_type:data_type, id:id})
          })
        })
      }
      return ids
    }

    _ids(window = null) {
      if (DEBUG_STORAGE) console.log('_ids', window)
      if (window !== null) {
        if (DEBUG_STORAGE) console.log('_ids 2', window)
        return this._ids_key(window)
      }
      if (DEBUG_STORAGE) console.log('_ids 3', window)
      let ids = []
      if (DEBUG_STORAGE) console.log('_ids 4', window)
      Object.keys(this._storage).forEach(key => {
        if (DEBUG_STORAGE) console.log('_ids 5', window)
        ids = [...ids, ...this._ids_key(key)]
      })
      return ids
    }

    _unsubscribe_single = (obj, window) => {
      if (DEBUG_STORAGE) console.log('_unsubscribe_single OK 1', obj)
      ctx.$services.websocket.unsubscribe_single_obj(obj, window).then(()=>{
        if (DEBUG_STORAGE) console.log('_unsubscribe_single OK 2', obj)
      })
      if (DEBUG_STORAGE) console.log('_unsubscribe_single OK 3', obj)
    }

    _unsubscribe_list = (obj_list, window) => {
       obj_list.forEach(val => {
        this._unsubscribe_single(val, window)
      })
    }

    _subscribe_single = (obj, window) => {
      if (DEBUG_STORAGE) console.log('_subscribe_single OK 1', obj)
      const wsService = ctx.$services.websocket
      const subscribeFn = () => {
        wsService.subscribe_single_obj(obj, window).then(()=>{
          if (DEBUG_STORAGE) console.log('_subscribe_single OK 2', obj)
        })
      }
      if (!wsService.connection || wsService.connection.readyState !== 1) {
        wsService.connectionStartedCBs.push(subscribeFn)
      } else {
        subscribeFn()
      }
    }

    _subscribe_list = (obj_list, window) => {
       obj_list.forEach(val => {
        this._subscribe_single(val, window)
      })
    }

    _put_array = (obj_list, window, subscribe) => {
      let new_list = []
      obj_list.forEach(val => {
        new_list.push(this._put_single(val, window, false))
      })

      if (subscribe) {
        this._subscribe_list(obj_list, window)
      }
      return new_list
    }

    _put_single = (obj, window, subscribe) => {
      if (obj && !obj['data_type']) return obj
      let self
      if (this.storage(window, obj['data_type'])[obj['id']] === undefined) {
        if (DEBUG_STORAGE) console.log('put==', 2)
        this.storage(window, obj['data_type'])[obj['id']] = reactive(obj)
        self = this.storage(window, obj['data_type'])[obj['id']]
      }
      else {
        if (DEBUG_STORAGE) console.log('put==', 3)
        self = this.storage(window, obj['data_type'])[obj['id']]
        Object.keys(self).forEach(key => delete self[key])
        Object.keys(obj).forEach(key => self[key] = obj[key])
        if (DEBUG_STORAGE) console.log('put==', self, window)
      }
      if (DEBUG_STORAGE) console.log('put==', 4)
      if (DEBUG_STORAGE) console.log('put>>', this.storage(window, obj['data_type'])[obj['id']], window)
      if (DEBUG_STORAGE) console.log('put>>', self, window)
      if (subscribe) {
        this._subscribe_list([self], window)
        if (DEBUG_STORAGE) console.log('IDS', this._ids())
      }
      counter ++
      self.__storage_counter = counter
      // self['X'+counter] =counter
      return self
    }

    put = (obj, window=null, subscribe=true) => {
      if (DEBUG_STORAGE) console.log('put<<', obj, window)
      if (window == null) return
      if (Array.isArray(obj)) {
        if (DEBUG_STORAGE) console.log('put', 1)
        return this._put_array(obj, window, subscribe)
      }
      return this._put_single(obj, window, subscribe)
    }

    get = (data_type, id, window=null) => {
      if (window == null) return
      return this.storage(window, data_type)[id]
    }

    del = (data_type, id, window=null) => {
      if (window == null) return
      if (DEBUG_STORAGE) console.log(this._storage)
      const self = this.storage(window, data_type)[id] || {}
      Object.keys(self).forEach(key => delete self[key])
      delete this.storage(window, data_type)[id]
    }

    put_response = (response, window=null, subscribe=true, extra_data=null) => {
      let stored_obj
      if (window == null) {
        stored_obj = response?.data
      } else {
        stored_obj = this.put(response?.data || {}, window, subscribe)
        if (response?.related_data) {
          Object.keys(response.related_data).forEach(data_type => {
            Object.keys(response.related_data[data_type]).forEach(key => {
              this.put(response.related_data[data_type][key], window)
            })
          })
        }
      }

      if (response?.extra_data && extra_data) {
        Object.entries(response.extra_data).forEach(([key, value]) => {
          extra_data[key] = value
        })
      }

      return stored_obj
    }

    clr = (window=null) => {
      if (DEBUG_STORAGE) console.log('clear<<', window)
      if (window === null) {
        if (DEBUG_STORAGE) console.log('clear==', 1)
        this._unsubscribe_list(this._ids(), null)
        this._storage = {}
      }
      else {
        if (DEBUG_STORAGE) console.log('clear==', 2)
        if (this._storage[window]!== undefined) {
          this._unsubscribe_list(this._ids(window), window)
          delete this._storage[window]
        }
      }
      if (DEBUG_STORAGE) console.log('IDS', this._ids())
    }

    // Возвращает объект ключами которого являются идентификаторы А значениями объекты с запрошенным data_type
    getList = (data_type, window =null) => {
      return this.storage(window, data_type)
    }

    getRelations = async (objectsList, field_name, field_type, retrieve_method, WH) => {
      if (WH == null) return

      const ids_set = new Set()

      const list = Array.isArray(objectsList) ? objectsList : Object.values(objectsList)
      if (list.every(obj => obj[field_name] === undefined)) return {}

      try {
        // если передан список объектов
        objectsList.forEach(obj => {
          if (typeof(obj[field_name]) === 'number') {
            ids_set.add(obj[field_name])
          }
          else if (obj[field_name]) {
            if (obj[field_name].length) {
              obj[field_name].forEach(el => { ids_set.add(el) })
            }
          }
        })
        if (DEBUG_STORAGE) console.log('getRelations got List')
      }
      catch(error) {
        // если передан объект у которого ключи => объекты
        for (const [key, obj] of Object.entries(objectsList)) {
          if (typeof(obj[field_name]) === 'number') {
            ids_set.add(obj[field_name])
          }
          else if (obj[field_name]) {
            ids_set.add(...obj[field_name])
          }
        }
        if (DEBUG_STORAGE) console.log('getRelations got Object')
      }

      if (DEBUG_STORAGE) console.log('ids_set', ids_set)
      for (let id of ids_set) {
        if (DEBUG_STORAGE) console.log('-->', id)
        const response = await retrieve_method(WH, id)
        if (DEBUG_STORAGE) console.log('-->', response)
      }
      let relations = this.getList(field_type, WH)
      if (DEBUG_STORAGE) console.log('relations =>', relations)
      return relations;
    }
  }

  ctx.$services.storage = new StorageService()
  return ctx.$services.storage
}
