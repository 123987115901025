<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle
      @click.prevent
      placement="bottom-end"
      class="p-0"
      :caret="false"
      :component="'span'"
      :custom="false"
    >
      <CAvatar :src="avatar" size="lg" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        {{ myCompany?.name }}
      </CDropdownHeader>

      <CDropdownItem
        component="button"
        @click="$router.push({ path: '/profile/' })"
      >
        <AppIcon icon="user" />
        <template v-if="myUser?.name">
          {{ myUser.surname }} {{ myUser.name }}
          {{ myUser.patronymic }} (профайл)
        </template>
      </CDropdownItem>

      <template v-if="myIdentity?.role">
        <CDropdownItem component="button" @click="shChangeRole = !shChangeRole">
          <AppIcon icon="hijack" /> {{ C.ROLES_TITLES[myIdentity.role] }} (cмена
          роли)
        </CDropdownItem>
        <CDropdownItem
          v-if="C.PERSONAL_ROLES.includes(myIdentity.role)"
          @click="shUserSettings = !shUserSettings"
          role="button"
        >
          <AppIcon icon="settings" color="warning" /> Настройки пользователя
        </CDropdownItem>

        <CDropdownItem
          v-if="C.ADMIN_ROLES.includes(myIdentity.role)"
          @click="shCompanySettings = !shCompanySettings"
          role="button"
        >
          <AppIcon icon="settings" color="warning" /> Настройки компании
        </CDropdownItem>
      </template>

      <CDropdownItem>
        <AppIcon icon="cil-bell" color="secondary" />
        <span class="text-secondary">Updates (демо)</span>
        <CBadge color="info-gradient" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <AppIcon icon="cil-envelope-open" color="secondary" />
        <span class="text-secondary">Messages (демо)</span>
        <CBadge color="success-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <AppIcon icon="cil-task" color="secondary" />
        <span class="text-secondary">Tasks (демо)</span>
        <CBadge color="danger-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <AppIcon icon="cil-comment-square" color="secondary" />
        <span class="text-secondary">Comments (демо)</span>
        <CBadge color="warning-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>

      <CDropdownItem>
        <AppIcon icon="cil-dollar" color="secondary" />
        <span class="text-secondary">Payments (демо)</span>
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <AppIcon icon="cil-file" color="secondary" />
        <span class="text-secondary">Projects (демо)</span>
        <CBadge color="primary-gradient" class="ms-auto">{{
          itemsCount
        }}</CBadge>
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <AppIcon icon="cil-shield-alt" color="secondary " />
        <span class="text-secondary">Lock Account (демо)</span>
      </CDropdownItem>
      <CDropdownItem component="button" @click="doLogout">
        <AppIcon icon="exit" /> Выход
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
  <ChangeRoleForm
    v-if="shChangeRole"
    @closed="
      () => {
        shChangeRole = false
      }
    "
  ></ChangeRoleForm>
  <CompanySettings
    v-if="shCompanySettings"
    @close="shCompanySettings = false"
  />
  <UserSettings v-if="shUserSettings" @close="shUserSettings = false" />
</template>

<script setup>
import { CBadge } from '@coreui/vue-pro'
import { useRouter } from 'vue-router'
import { inject, ref } from 'vue'
import { useAuthData } from '@/composables/authData'
import avatar from '@/assets/images/avatars/8.jpg'
import AppIcon from '@/plugins/app-icons/AppIcon'
import ChangeRoleForm from '@/popups/common/ChangeRoleForm'
import C from '@/config/back_const'
import CompanySettings from '@/popups/common/_account_settings/_company_settings/CompanySettings.vue'
import UserSettings from '@/popups/common/_account_settings/_user_settings/UserSettings.vue'

const { myUser, myCompany, myIdentity } = useAuthData()

const { auth } = inject('services')
const router = useRouter()
const shChangeRole = ref(false)
const shCompanySettings = ref(false)
const shUserSettings = ref(false)
const itemsCount = 777

const doLogout = () => {
  auth.resetAuthentication()
  router.push({ name: 'Login' })
  return false
}
</script>
