// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class LegalApiMethodsCls {
    async agencyInvoiceList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка AgencyInvoice (счет системы в агентство)
      // filters: ids, company, contractor_entity, operator_identity, state, doc_number, doc_date,
      //          tour
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('agencyInvoiceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.agencyInvoice.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async agencyInvoiceRetrieve(wh, id, extra_data = null) {
      // Получение модели AgencyInvoice (счет системы в агентство)
      console.log('agencyInvoiceRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__agencyinvoice', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__agencyinvoice', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.agencyInvoice.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agencyInvoiceOffsetsRetrieve(wh, id, extra_data = null) {
      // Получение зачётов оплат для счёта агентству
      console.log('agencyInvoiceOffsetsRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__paymentoffset', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__paymentoffset', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.agencyInvoice.offsets.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение полного списка зачётов
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('paymentOffsetList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async paymentOffsetCreate(wh, data, extra_data = null) {
      // data: PaymentOffset
      // Создание зачёта платежа
      console.log('paymentOffsetCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetRetrieve(wh, id, extra_data = null) {
      // Получение конкретного зачёта оплаты
      console.log('paymentOffsetRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__paymentoffset', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__paymentoffset', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetUpdate(wh, id, data, extra_data = null) {
      // data: PaymentOffset
      // Обновление зачёта оплаты
      console.log('paymentOffsetUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetPartial(wh, id, data, extra_data = null) {
      // data: PaymentOffset
      console.log('paymentOffsetPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetAnnulPartial(wh, id, data, extra_data = null) {
      // data: PaymentOffset
      // Аннулирование зачёта оплаты
      console.log('paymentOffsetAnnulPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.annul.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetCancelPartial(wh, id, data, extra_data = null) {
      // data: PaymentOffset
      // Отмена зачёта оплаты
      console.log('paymentOffsetCancelPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.cancel.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetDownloadRetrieve(wh, id, extra_data = null) {
      // Получить url файла зачёта оплаты (поле в ответе file_url)
      console.log('paymentOffsetDownloadRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.download.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetHoldPartial(wh, id, data, extra_data = null) {
      // data: PaymentOffset
      // Подтверждение контрагента
      console.log('paymentOffsetHoldPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.hold.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetPrintRetrieve(wh, id, extra_data = null) {
      // Распечатать зачёт оплаты
      console.log('paymentOffsetPrintRetrieve')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.print.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetRejectPartial(wh, id, data, extra_data = null) {
      // data: PaymentOffset
      // Отказ контрагента
      console.log('paymentOffsetRejectPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.reject.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetRestorePartial(wh, id, data, extra_data = null) {
      // data: PaymentOffset
      // Восстановление зачёта оплаты
      console.log('paymentOffsetRestorePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.restore.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOffsetSubmitPartial(wh, id, data, extra_data = null) {
      // data: PaymentOffset
      // Отправка контрагенту на согласование
      console.log('paymentOffsetSubmitPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOffset.submit.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOrderList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка PaymentOrder
      // filters: id, ids, owner_entity, owner_bank_account, contractor_bank_account, amount, state,
      //          doc_number, doc_date
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('paymentOrderList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOrder.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async paymentOrderCreate(wh, data, extra_data = null) {
      // data: PaymentOrderCreate
      // Создание PaymentOrder
      console.log('paymentOrderCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOrder.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOrderRetrieve(wh, id, extra_data = null) {
      // Получение модели PaymentOrder
      console.log('paymentOrderRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__paymentorder', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__paymentorder', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOrder.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOrderUpdate(wh, id, data, extra_data = null) {
      // data: PaymentOrderUpdate
      // Обновление строк PaymentOrder
      console.log('paymentOrderUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOrder.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async paymentOrderDelete(wh, id) {
      // Удаление PaymentOrder
      console.log('paymentOrderDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.paymentOrder.delete(id)})
      ctx.$services.storage.del('legal__paymentorder', id, wh)
      return null
    }
    async paymentOrderRowRetrieve(wh, id, extra_data = null) {
      // Получение PaymentOrderRow
      console.log('paymentOrderRowRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__paymentorderrow', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__paymentorderrow', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.paymentOrderRow.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async supplierInvoiceList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получить список счетов поставщиков
      // filters: company, contractor_entity, operator_identity, state, doc_number, doc_date,
      //          booking, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('supplierInvoiceList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.supplierInvoice.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async supplierInvoiceRetrieve(wh, id, extra_data = null) {
      // Получить конкретный счёт поставщика
      console.log('supplierInvoiceRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__supplierinvoice', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__supplierinvoice', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.supplierInvoice.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async supplierInvoiceOffsetsRetrieve(wh, id, extra_data = null) {
      // Получение зачётов оплат для счёта от поставщика
      console.log('supplierInvoiceOffsetsRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__paymentoffset', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__paymentoffset', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.supplierInvoice.offsets.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.legalApi = new LegalApiMethodsCls()
}

