<template>
  <div>
    <CModal
      ref="modal"
      :visible="isVisible"
      backdrop="static"
      alignment="center"
      size="xl"
      fullscreen="md"
      @close="onClose"
    >
      <CModalHeader dismiss>
        <CModalTitle>Задачи</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div :id="`id-${WH}`" class="position-relative">
          <v-server-table ref="tableRef" :columns="columns" :options="options">
            <template #celeryId="{ row }">
              {{ row.task_id }}
            </template>

            <template #result="{ row }">
              <span :class="isSuccess(row) ? 'text-success' : 'text-danger'">
                {{ isSuccess(row) ? 'выполнена' : 'не выполнена' }}
              </span>
            </template>

            <template #date="{ row }">
              <span
                v-if="row.celery_result?.date_created"
                v-html="
                  dateService.formatDateTime(row.celery_result.date_created)
                "
              ></span>
            </template>

            <template #name="{ row }">
              {{ row.name }}
            </template>

            <template #status="{ row }">
              {{ C.IDENTITY_TASK_STATUS_TITLES[row.status] }}
              <div
                class="w-100 h-100 flex justify-content-center align-items-center"
                v-if="row.status !== C.IDENTITY_TASK_STATUS_CLOSED"
              >
                <ActionButton
                  icon="reject"
                  tooltip="Завершить задачу"
                  color="warning"
                  size="sm"
                  icon-size="lg"
                  :action="
                    (ev) => {
                      expectant.closeTask(row.id)
                    }
                  "
                />
              </div>
            </template>

            <template #show="{ row }">
              <a
                class="link-primary"
                href="javascript:void(0)"
                @click="show(row)"
              >
                Просмотр результата
              </a>
            </template>
          </v-server-table>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onClose">Закрыть</CButton>
      </CModalFooter>
      <ThePodval
        title="Список отложенных задач"
        :tasks="['https://tracker.yandex.ru/BACK-1814']"
        :wiki="[]"
        :uuid="WH"
      />
    </CModal>

    <PopupTaskFailViewer
      v-if="currTask"
      :task="currTask"
      :show="showFailViewer"
      @close="showFailViewer = false"
    />

    <PopupPaymentImportFileView
      v-if="currTask"
      :id="currTask?.result?.data?.id"
      :task-id="currTask?.id"
      :show="showPaymentImport"
      @close="showPaymentImport = false"
    />
  </div>
</template>

<script setup>
import { inject, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import C from '@/config/back_const'
import tableSettings from '@/config/table-config.js'
import ActionButton from '@/components/_common/ActionButton.vue'
import PopupTaskFailViewer from '@/components/popups/PopupTaskFailViewer.vue'
import PopupPaymentImportFileView from '@/components/_payment/PopupPaymentImportFileView.vue'
import ThePodval from '@/components/_shared/ThePodval.vue'

const {
  storage,
  identityTaskApi,
  dateService,
  tableService,
  fetch,
  expectant,
} = inject('services')
const store = useStore()
const WH = 'a759428c-7736-4453-be92-b843831f18b0'
const isVisible = ref(false)

const currTask = ref()
const showFailViewer = ref(false)
const showPaymentImport = ref(false)

const isSuccess = (task) => {
  return (
    task.result?.success === 'true' ||
    task.status === C.IDENTITY_TASK_STATUS_SUCCESS
  )
}
const show = (task) => {
  if (isSuccess(task)) {
    showCompleted(task)
  } else {
    showFailed(task)
  }
}
const showCompleted = (task) => {
  currTask.value = task
  const { data = {} } = task.result || {}
  if (!data.data_type) {
    console.error(`Нет данных. task_id: ${task?.id}`)
  }

  switch (data.data_type) {
    case 'media__importfile':
      showPaymentImport.value = true
  }
}
const showFailed = (task) => {
  currTask.value = task
  showFailViewer.value = true
}

const tableRef = ref()
const headings = {
  celeryId: 'celery id',
  result: 'Результат',
  date: 'Дата',
  name: 'Название',
  status: 'Статус',
  show: '',
}
const columns = Object.keys(headings)
const columnsClasses = {
  result: 'nobr',
}
const requestFunction = async (req) => {
  try {
    storage.clr(WH)
    store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
    const params = fetch.prepareQueryParams(req, {})
    const data = await identityTaskApi.identityTaskList(WH, { ...params })
    return { data }
  } catch (error) {
    console.log(error)
  } finally {
    store.commit('toggleLockEl', { lock: false, target: null })
  }
}
const options = {
  ...tableSettings,
  requestFunction: tableService.requestFnDebounceWrap(requestFunction),
  filterable: false,
  texts: { count: '', limit: '', first: 'в начало', last: 'последняя' },
  columnsClasses,
  headings,
  sortable: [],
}

const onClose = () => {
  store.commit('togglePopup', 'taskViewer')
}

onMounted(async () => {
  isVisible.value = true
})

onUnmounted(() => {
  storage.clr(WH)
})
</script>
