// https://tracker.yandex.ru/BACK-2848
// https://tracker.yandex.ru/BACK-3220
// обработка объектов для ссылок в тексте

const getCommonRx = (dataType) => {
  // eslint-disable-next-line
  const rxSrt = `\\[${dataType}#(\\d+?)(\\|(.+?))?\\]`
  return new RegExp(rxSrt, 'g')
}
const getCommonReplacer = (path) => {
  return ({ link } = {}, _, p1, __, p3) => {
    const withName = `(#${p1}) ${p3}`
    const onlyId = `#${p1}`
    const text = `${p3 ? withName : onlyId}`
    return link ? `<a href="/#/${path}/${p1}/">${text}</a>` : text
  }
}

const replaces = {
  lead: {
    rx: getCommonRx('core__lead'),
    fn: getCommonReplacer('lead'),
    link: true,
  },
  tour: {
    rx: getCommonRx('core__tour'),
    fn: getCommonReplacer('tour'),
    link: true,
  },
  identity: {
    rx: getCommonRx('account__identity'),
    fn: getCommonReplacer('profile'),
    link: true,
  },
  hotel: {
    rx: getCommonRx('refbook__hotel'),
    fn: getCommonReplacer('hotel'),
    link: true,
  },
  location: {
    rx: getCommonRx('refbook__location'),
    fn: getCommonReplacer('location'),
    link: true,
  },
  category: {
    rx: getCommonRx('refbook__category'),
    fn: getCommonReplacer('category'),
    link: false,
  },
  program: {
    rx: getCommonRx('refbook__program'),
    fn: getCommonReplacer('program'),
    link: false,
  },
}

export const preprocessObjectsInText = (text, { link } = {}) => {
  let res = text
  for (const rConf of Object.values(replaces)) {
    const asLink = link === undefined ? rConf.link : link
    res = res.replace(rConf.rx, rConf.fn.bind(null, { link: asLink }))
  }
  return res
}
