<template>
  <CModal
    ref="modal"
    :visible="visible"
    size="xl"
    fullscreen="lg"
    backdrop="static"
    alignment="center"
    @close="onCloseModal"
  >
    <CModalHeader dismiss>
      <CModalTitle :id="`id-title-${WH}`"></CModalTitle>
    </CModalHeader>
    <CModalBody :id="`id-${WH}`">
      <PaymentInfoView
        :id="id"
        :modal-wh="WH"
        @get-data="showSpinner"
        @data-ready="hideSpinner"
        mode="buyer"
      />
    </CModalBody>
    <CModalFooter>
      <div class="flex justify-content-end flex-row-reverse">
        <CButton color="secondary" class="mx-2" @click="onCloseModal">
          Закрыть
        </CButton>
      </div>
    </CModalFooter>

    <CModalBody>
      <ThePodval
        title="Окно просмотра платежа"
        :tasks="['https://tracker.yandex.ru/BACK-3762']"
        :wiki="[]"
        :uuid="WH"
      />
    </CModalBody>
  </CModal>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import PaymentInfoView from '@/components/_payment/PaymentInfoView.vue'
import { useStore } from 'vuex'
import ThePodval from '@/components/_shared/ThePodval.vue'

defineProps({
  id: {
    type: Number,
    required: true,
  },
})
const emit = defineEmits(['close'])
const WH = 'a0ea5304-e1ff-11ee-bd3d-0242ac120002'
const store = useStore()

const visible = ref(false)

const onCloseModal = () => {
  visible.value = false
  emit('close')
}
const showSpinner = () => {
  store.commit('toggleLockEl', { lock: true, target: `#id-${WH}` })
}
const hideSpinner = () => {
  store.commit('toggleLockEl', { lock: false, target: null })
}

onMounted(() => {
  visible.value = true
})
</script>
