export { _templateMethods } from './modules/_templateMethods'
export { hotelMethods } from './modules/hotelMethods'
export { roomMethods } from './modules/roomMethods'
// import { apiList } from '@/api/modules/api' // До лучших времен //

export const API = (context) => {
  class APILocator {}
  context.$api = new APILocator()
  for (let key in context.$services) {
    context.$api[key] = context.$services[key]
  }

  context.provide('api', context.$api)
  // apiList.forEach(fn => fn(context)) // До лучших времен //
}
