<template>
    <CModal
      ref="modal"
      :visible="visible"
      size="xl"
      fullscreen="lg"
      backdrop="static"
      alignment="center"
      @close="onClose"
    >
      <CModalHeader dismiss>
        <CModalTitle></CModalTitle>
      </CModalHeader>
      <CModalBody>
        <LeadView :id="id" @exit="onClose"/>
      </CModalBody>
      <CModalFooter>
        <div class="flex gap-3">
          <CButton color="secondary" @click="onClose">Закрыть</CButton>
        </div>
      </CModalFooter>
    </CModal>
</template>
<script setup>
import {inject, onMounted, onUnmounted, ref} from 'vue'
import LeadView from "@/components/_leads/LeadView.vue";

defineProps({
  id: {
    type: [Number, String],
    required: true
  }
})
const emit = defineEmits(['close'])
const WH = '59c1e7dc-4a1f-4e02-bb41-96c14a024ba0'
const { storage } = inject('services')

const visible = ref(false)
const onClose = () => {
  visible.value = false
  emit('close')
}

onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
