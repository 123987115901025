// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CoreApiMethodsCls {
    async agentSalaryAccrualList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка док-тов начисления вознаграждений
      // filters: created_at, updated_at, company, operator, date_fr, date_to, status, title,
      //          version, comment, created_at_range, date_fr_range, date_to_range
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('agentSalaryAccrualList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalaryAccrual.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async agentSalaryAccrualCreate(wh, data, extra_data = null) {
      // data: CreateAgentSalaryAccrual
      // Создание док-та начисления вознаграждений
      console.log('agentSalaryAccrualCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalaryAccrual.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agentSalaryAccrualRetrieve(wh, id, extra_data = null) {
      // Получение док-та начисления вознаграждений
      console.log('agentSalaryAccrualRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__agentsalaryaccrual', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__agentsalaryaccrual', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalaryAccrual.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agentSalaryAccrualUpdate(wh, id, data, extra_data = null) {
      // data: FullAccrualDocumentUpdate
      // Обновление док-та начисления вознаграждений
      console.log('agentSalaryAccrualUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalaryAccrual.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agentSalaryAccrualPartial(wh, id, data, extra_data = null) {
      // data: AgentSalaryAccrual
      console.log('agentSalaryAccrualPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalaryAccrual.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agentSalaryAccrualApprovePartial(wh, id, data, extra_data = null) {
      // data: AgentSalaryAccrual
      // Утверждение док-та начисления вознаграждений
      console.log('agentSalaryAccrualApprovePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalaryAccrual.approve.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async agentSalaryAccrualRemovePartial(wh, id, data, extra_data = null) {
      // data: AgentSalaryAccrual
      // Удаление док-та начисления вознаграждений
      console.log('agentSalaryAccrualRemovePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentSalaryAccrual.remove.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async allPricesPut(wh, data, extra_data = null) {
      // data: PriceRequest
      // Запрос цен (все букеры)
      console.log('allPricesPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.allPrices.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async availableSpacePut(wh, data, extra_data = null) {
      // data: GetQuota
      // Список свободных мест
      console.log('availableSpacePut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.availableSpace.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async blackListList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение черного списка
      // filters: ip_low, ip_low__in, ip_high, ip_high__in, validity_period, validity_period__in,
      //          active_till, active_till__in, reason, reason__in, whitelist, whitelist__in,
      //          created_at, created_at__in
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('blackListList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.blackList.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async blackListCreate(wh, data, extra_data = null) {
      // Добавление IP в черный список
      console.log('blackListCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.blackList.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async blackListCheckList(wh, ip, {limit, offset, page} = {}, extra_data = null) {
      // Проверка, входит ли ip в черный список. Если входит, возвращает список BlackList куда входит данный ip. Если нет не возвращает ничего}
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('blackListCheckList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.blackList.check.list(ip, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async blackListCreateByMaskCreate(wh, data, extra_data = null) {
      // Добавление IP в черный список
      console.log('blackListCreateByMaskCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.blackList.createByMask.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async blackListRetrieve(wh, id, extra_data = null) {
      // Получение IP из черного списка
      console.log('blackListRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__blacklist', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__blacklist', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.blackList.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async blackListUpdate(wh, id, data, extra_data = null) {
      // data: BlackList
      console.log('blackListUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.blackList.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async blackListDelete(wh, id) {
      // Удаление IP из черного списка
      console.log('blackListDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.blackList.delete(id)})
      ctx.$services.storage.del('core__blacklist', id, wh)
      return null
    }
    async blackListPartial(wh, id, data, extra_data = null) {
      // data: BlackList
      console.log('blackListPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.blackList.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async blackListDisableIpPartial(wh, id, data, extra_data = null) {
      // data: BlackList
      // Отключение IP из черного списка
      console.log('blackListDisableIpPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.blackList.disableIp.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async clientPhoneConfirmedCreate(wh, data, extra_data = null) {
      // data: LeadClientPhoneConfirmed
      // Проверка пин кода
      console.log('clientPhoneConfirmedCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.clientPhoneConfirmed.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async pricesPut(wh, data, extra_data = null) {
      // data: PriceRequest
      // Запрос цен
      console.log('pricesPut')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.prices.put(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async quotaList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Список квот (брони, которые являются квотами)
      // filters: id, date_fr, date_to, hotel_title, city, location, hotel
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('quotaList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.quota.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.coreApi = new CoreApiMethodsCls()
}

