<template>
  <input
    type="text"
    :placeholder="placeholder"
    v-model="rubs"
    class="form-control input-no-arrows"
    :class="style"
    @blur="onBlur"
    @input="onInput"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { numberToStrWithSign, truncate } from '@/helpers'

const props = defineProps({
  modelValue: {
    type: [Number, String],
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: '',
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  autoZero: { type: Boolean, default: true },
})
const emit = defineEmits(['update:modelValue'])

const rubs = computed({
  get() {
    if (props.modelValue == null) return ''
    return copToRub(props.modelValue)
  },
  set(value) {
    const val = filter(value)
    update(val)
  },
})
const style = computed(() => ({
  'form-control-lg': props.size === 'lg',
  'form-control-sm': props.size === 'sm',
  'is-invalid': props.invalid,
}))
const dirty = ref(false)

const eRx = /^-*[1-9]\d*e[1-9]?$/
const replaceRx = /^(-\D+)|(\d-+)|([^-.,\d])|([.,].*\D+)|(.*\s.*)|(^[.,])$/
const isE = (val) => eRx.test(val)
const isOnlyMinus = (val) => /^-$/.test(val)
const endsWithDot = (val) => val.slice(-1) === '.'
const endsWithE = (val) => /e$/.test(val)
const isEmpty = (val) => val == null || val === ''
const isNullishValue = (val) => isEmpty(val) || isOnlyMinus(val)
const canSetZero = computed(
  () => props.autoZero && !props.modelValue && dirty.value,
)

const copToRub = (cop) => {
  if (cop == null || cop === '') return ''
  const rub = cop * 0.01
  return truncate(numberToStrWithSign(rub))
}
const rubToCop = (value) => {
  const rub = parseFloat(value)
  const cop = Math.round(rub * 100)
  const truncated = truncate(numberToStrWithSign(cop), 0)
  return parseFloat(truncated)
}
const update = (value) => {
  if (!isNullishValue(value)) {
    emit('update:modelValue', rubToCop(value))
  } else {
    emit('update:modelValue', '')
  }
}
const filter = (value) => {
  let newVal = value
  if (isE(newVal)) {
    return truncate(newVal, 2)
  }
  const needReplace = replaceRx.test(value)
  if (needReplace) newVal = value.slice(0, -1)
  newVal = newVal.replace(',', '.')
  return truncate(newVal, 2)
}
const onInputFilter = (ev) => {
  const { value } = ev.target
  ev.target.value = filter(value)
}
const onInput = (ev) => {
  onInputFilter(ev)
  dirty.value = true
}

const onBlur = (ev) => {
  const val = ev.target.value
  if (
    isOnlyMinus(val) ||
    endsWithDot(val) ||
    endsWithE(val) ||
    canSetZero.value
  ) {
    ev.target.value = 0
    emit('update:modelValue', 0)
    dirty.value = false
  }
}
</script>
