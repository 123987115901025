<template>
  <div>
    <AppSidebar />
    <div class="wrapper overflow-hidden">
      <div id="inner-container" class="flex">
        <div class="d-flex flex-column min-vh-100 flex-grow-1">
          <AppHeader />
          <div id="body-wrapper" class="body flex flex-grow-1 bg-body">
            <div
              class="body flex flex-grow-1"
              style="width: calc(100vw - 55rem)"
            >
              <CContainer fluid class="mt-4" id="body-container">
                <div class="position-relative h-100">
                  <div
                    v-if="lockPage"
                    class="screen-locker-without-spinner"
                  ></div>
                  <div v-if="lockPageWS" class="screen-locker-with-spinner">
                    <CSpinner color="dark" />
                  </div>
                  <router-view />
                </div>
              </CContainer>
              <Teleport v-if="lockEl && lockElTarget" :to="lockElTarget">
                <div class="screen-locker-with-spinner">
                  <CSpinner color="dark" />
                </div>
              </Teleport>
            </div>
            <AppAside
              :key="asideRenderKey"
              v-if="
                (C.ADMIN_ROLES.includes(myIdentity.role) ||
                  C.AGENT_ROLES.includes(myIdentity.role)) &&
                myCompany.is_agency
              "
            />
          </div>
          <AppFooter />
        </div>
      </div>
    </div>
    <StorageViewer />
    <PopupSearchLT
      v-if="store.getters.popupVisible('searchLT')"
      :default-value="searchLeadsToursDefaultValue"
      @closed="
        () => {
          store.commit('togglePopup', 'searchLT')
          searchLeadsToursDefaultValue = null
        }
      "
    />
    <TaskViewer
      v-if="store.getters.popupVisible('taskViewer')"
      @closed="store.commit('togglePopup', 'taskViewer')"
    />
    <NewIssue
      v-if="store.getters.popupVisible('newIssue')"
      @closed="store.commit('togglePopup', 'newIssue')"
    />
  </div>
</template>

<script setup>
import AppAside from '@/components/AppAside.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import {
  inject,
  ref,
  onMounted,
  computed,
  onUnmounted,
  nextTick,
  onBeforeMount,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import StorageViewer from '@/popups/common/StorageViewer'
import PopupSearchLT from '@/components/_shared/search-tours-leads/PopupSearchLT.vue'
import NewIssue from '@/components/_tm/NewIssue.vue'
import TaskViewer from '@/popups/common/TaskViewer'
import { debounce } from 'lodash'
import C from '@/config/back_const'
import { setGlobalHandleWS } from '@/helpers/setGlobalHandleWS'
import { useAccessWS } from '@/composables/accessWS'
import { useAuthData } from '@/composables/authData'
import { consoleClientLoggerInit } from '@/helpers/clientLogger'

let authCheckTimeoutId = null
let isAuthenticated = false

const store = useStore()
const router = useRouter()
const services = inject('services')
const { auth, websocket } = services
const bus = inject('bus')

const { setWSAction } = useAccessWS(websocket)
const { myIdentity, myCompany } = useAuthData(onUpdateUser)
const lockPageWS = computed(() => store.state.lockPageWS)
const lockPage = computed(() => store.state.lockPage)
const lockEl = computed(() => store.state.lockEl)
const lockElTarget = computed(() => store.state.lockElTarget)

let onResize = null
let resizeObserver = null
let bodyEl = null
let headerEl = null
const footerHeight = 72
const bodyMaxHeight = ref('99vh')
const asideRenderKey = ref(1)
const searchLeadsToursDefaultValue = ref(null)

const resizeHandler = () => {
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  )
  bodyMaxHeight.value = `${vh - (footerHeight + headerEl.offsetHeight)}px`
}
async function refreshAuthentication(ms) {
  try {
    isAuthenticated = await auth.isAuthenticated()
  } catch (err) {
    clearTimeout(authCheckTimeoutId)
    console.log('refreshAuthentication error', err)
    router.push({ name: 'Login' })
  } finally {
    if (!isAuthenticated) {
      clearTimeout(authCheckTimeoutId)
      console.warn('AuthFailed ==> Login')
      router.push({ name: 'Login' })
    } else {
      authCheckTimeoutId = setTimeout(refreshAuthentication, ms, ms)
    }
  }
}

function onUpdateUser() {
  // re-render
  asideRenderKey.value += 1
}

const wsAction = () => {
  setGlobalHandleWS(websocket, { identityRef: myIdentity, services })
}
setWSAction(wsAction)

const pingStatusHandler = ({ connectionLost }) => {
  bus.emit('is-connection-lost', connectionLost)
}
const searchLeadsToursOpenHandler = ({ defaultValue }) => {
  searchLeadsToursDefaultValue.value = defaultValue
  store.commit('togglePopup', 'searchLT')
}

onBeforeMount(async () => {
  consoleClientLoggerInit()
  websocket.setPingStatusHandler(pingStatusHandler)
  await websocket.connect()
  await refreshAuthentication(1500)
  bus.on('search-leads-tours-open', searchLeadsToursOpenHandler)
})
onMounted(() => {
  nextTick(() => {
    bodyEl = document.querySelector('.body')
    headerEl = document.querySelector('.header')

    if (bodyEl && headerEl) {
      onResize = debounce(resizeHandler, 50)
      resizeObserver = new ResizeObserver(onResize)
      resizeObserver.observe(headerEl)
    }
  })
})
onUnmounted(async () => {
  if (websocket.close) {
    websocket.connection.onclose = () => {}
    await websocket.close()
  }
  clearTimeout(authCheckTimeoutId)
  bus.off('search-leads-tours-open', searchLeadsToursOpenHandler)
})
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  max-height: v-bind(bodyMaxHeight);
  overflow: auto;
}

.screen-locker-with-spinner {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1501;
  display: flex;
  justify-content: center;
  .spinner-border {
    position: sticky;
    top: 45%;
  }
}

.screen-locker-without-spinner {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1501;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
