/* eslint-disable no-undef */
import mitt from 'mitt'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import VCalendar from 'v-calendar'
import vClickOutside from 'click-outside-vue3'
import { ClientTable, ServerTable } from 'v-tables-3'
import { services } from '@/services'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Maska from 'maska'
import VueTelInput from 'vue3-tel-input'
import constants from '@/config/constants'
import AppIcons from '@/plugins/app-icons'
import { setAuthGuard } from '@/router/setAuthGuard.js'
import { setAccessGuard } from '@/router/setAccessGuard.js'
import { setWinTracker } from '@/helpers/winTracker.js'

// JSON всех иконок nsb-icons сейчас нужен для переходного периода с AppIconDeprecated на новый AppIcon
// когда все иконки будут nsb-icons использование этого файла можно убрать для улучшения перфоманса
// также удалить app.provide('nsbIconsData', nsbIconsData)
import nsbIconsData from '@/assets/nsb-icons/selection.json'

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

const emitter = mitt()
const app = createApp(App)

app.component('Datepicker', Datepicker)

// SET ICONS CONSTANTS
app.config.globalProperties.icons = constants.icons

// SET GLOBAL EVENT BUS //
app.config.globalProperties.bus = emitter
app.provide('bus', emitter)

app.provide('nsbIconsData', nsbIconsData)

window.axios = require('axios')

app.use(VueTelInput)
app.use(AppIcons)
app.use(Maska)
app.use(services)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VCalendar, {})
app.use(vClickOutside)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.use(ServerTable, {}, 'bootstrap4')
app.use(ClientTable, {}, 'bootstrap4')

// Sentry.init({
//   app,
//   dsn: "http://88e41b1c627345c2b1ab272d9ee3acdf@rnsb.ru:9000/2",
//   integrations: [
//     // new BrowserTracing({
//     //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     //   tracingOrigins: ["*"]
//     //   // tracePropagationTargets: ["dev-api.rnsb.su", "dev.rnsb.su", "10.0.1.47", "127.0.0.1", "localhost", /^\//],
//     // }),
//   ],
//   tracesSampleRate: 1.0,
//  environment: process.env.VUE_APP_ENVIRONMENT || 'not_specified'
// });

app.mount('#app')

setAuthGuard(app, router)
setAccessGuard(app, router)

setWinTracker()
