// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class OrganizationApiMethodsCls {
    async organizationList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Organization
      // filters: full_title, ogrn, inn, kpp, okpo, address, postal_address, email
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('organizationList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async organizationCreate(wh, data, extra_data = null) {
      // data: OrganizationCreate
      // Создание Organization
      // Если роль не CUSTOMER, то должен быть указан buyer
      console.log('organizationCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async organizationRetrieve(wh, id, extra_data = null) {
      // Получение Organization
      console.log('organizationRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__organization', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__organization', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async organizationUpdate(wh, id, data, extra_data = null) {
      // data: OrganizationUpdate
      // Обновление Organization
      console.log('organizationUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async organizationDelete(wh, id) {
      // Удаление Organization
      console.log('organizationDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.organization.delete(id)})
      ctx.$services.storage.del('core__organization', id, wh)
      return null
    }
  }

  ctx.$services.organizationApi = new OrganizationApiMethodsCls()
}

