<template>
  <div :class="classes">
    <ActionButton
      variant="outline"
      icon="phone"
      color="primary"
      :size="size"
      :disabled="!phone"
      tooltip="Позвонить"
      disabled-tooltip="Номер не указан"
      icon-size="lg"
      :action="
        () => {
          bus.emit('webphone-set-dial', {
            dialNumber: phone,
          })
        }
      "
    />
  </div>
</template>

<script setup>
import ActionButton from '@/components/_common/ActionButton.vue'
import { inject } from 'vue'

defineProps({
  phone: {
    type: String,
    default: null,
  },
  classes: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'sm',
  },
})

const bus = inject('bus')
</script>
