
// добавляем в порядке сортировки
import {
  cibBehance,
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibDribbble,
  cibFacebook,
  cibFlickr,
  cibGithub,
  cibGoogle,
  cibInstagram,
  cibLinkedin,
  cibPinterest,
  cibReddit,
  cibStackoverflow,
  cibTelegram,
  cibTumblr,
  cibTwitter,
  cibVimeo,
  cibVk,
  cibXing,
  cibYahoo,
  cibYoutube,
} from '@coreui/icons'

// добавляем в порядке сортировки
import {
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifRu,
  cifUa,
  cifUs,
} from '@coreui/icons'

// добавляем в порядке сортировки
import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilArrowBottom,
  cilArrowRight,
  cilArrowThickFromBottom,
  cilArrowThickToBottom,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilBriefcase,
  cilBuilding,
  cilBusAlt,
  cilCalculator,
  cilCalendar,
  cilCalendarCheck,
  cilCarAlt,
  cilCaretRight,
  cilChartPie,
  cilChatBubble,
  cilCheck,
  cilCheckAlt,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronCircleLeftAlt,
  cilChevronCircleRightAlt,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCloudDownload,
  cilCloudUpload,
  cilCode,
  cilCommentSquare,
  cilCopy,
  cilCursor,
  cilDescription,
  cilDollar,
  cilDrop,
  cilEducation,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilExitToApp,
  cilFace,
  cilFax,
  cilFile,
  cilFork,
  cilGlobeAlt,
  cilGrid,
  cilHandPointRight,
  cilHome,
  cilHeadphones,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInfo,
  cilItalic,
  cilJustifyCenter,
  cilLan,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoney,
  cilMonitor,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperPlane,
  cilPaperclip,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPlus,
  cilPowerStandby,
  cilPrint,
  cilPuzzle,
  cilQrCode,
  cilRuble,
  cilSave,
  cilScreenDesktop,
  cilSearch,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSitemap,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserPlus,
  cilVoiceOverRecord,
  cilWarning,
  cilX,
  cilXCircle,
  cilZoom,
} from '@coreui/icons'

// добавляем в порядке сортировки
export const iconsSet = Object.assign(
  {},
  // добавляем в порядке сортировки
  {
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowCircleLeft,
    cilArrowCircleRight,
    cilArrowBottom,
    cilArrowRight,
    cilArrowThickFromBottom,
    cilArrowThickToBottom,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBusAlt,
    cilBookmark,
    cilBriefcase,
    cilBuilding,
    cilCalculator,
    cilCalendar,
    cilCalendarCheck,
    cilCarAlt,
    cilCaretRight,
    cilChartPie,
    cilChatBubble,
    cilCheck,
    cilCheckAlt,
    cilCheckCircle,
    cilChevronBottom,
    cilChevronCircleLeftAlt,
    cilChevronCircleRightAlt,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCloudDownload,
    cilCloudUpload,
    cilCode,
    cilCommentSquare,
    cilCopy,
    cilCursor,
    cilDescription,
    cilDollar,
    cilDrop,
    cilEducation,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilExitToApp,
    cilFace,
    cilFax,
    cilFile,
    cilFork,
    cilGlobeAlt,
    cilGrid,
    cilHandPointRight,
    cilHome,
    cilHeadphones,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInfo,
    cilItalic,
    cilJustifyCenter,
    cilLan,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoney,
    cilMonitor,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperPlane,
    cilPaperclip,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPlus,
    cilPowerStandby,
    cilPrint,
    cilPuzzle,
    cilQrCode,
    cilRuble,
    cilSave,
    cilScreenDesktop,
    cilSearch,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSitemap,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserPlus,
    cilVoiceOverRecord,
    cilWarning,
    cilX,
    cilXCircle,
    cilZoom,
  },
  // добавляем в порядке сортировки
  {
    cifBr,
    cifEs,
    cifFr,
    cifIn,
    cifPl,
    cifRu,
    cifUa,
    cifUs,
  },
  // добавляем в порядке сортировки
  {
    cibBehance,
    cibCcAmex,
    cibCcApplePay,
    cibCcMastercard,
    cibCcPaypal,
    cibCcStripe,
    cibCcVisa,
    cibDribbble,
    cibFacebook,
    cibFlickr,
    cibGithub,
    cibGoogle,
    cibInstagram,
    cibLinkedin,
    cibPinterest,
    cibReddit,
    cibStackoverflow,
    cibTelegram,
    cibTumblr,
    cibTwitter,
    cibVimeo,
    cibVk,
    cibXing,
    cibYahoo,
    cibYoutube,
  },
)
