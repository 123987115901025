<template>
  <div class="p-3">
    <div class="flex gap-4">
      <ul class="list-unstyled p-3 w-50">
        <li>
          <b>Плательщик: </b>
          <ul>
            <li>{{ senderObj.company?.full_title }}</li>
            <li>ОРГН: {{ senderObj.company?.ogrn }}</li>
            <li>ИНН: {{ senderObj.company?.inn }}</li>
            <li>КПП: {{ senderObj.company?.kpp }}</li>
          </ul>
        </li>

        <li>
          <b>Счет плательщика: </b> <br />
          <ul>
            <li>Банк: {{ senderObj.bankAcc?.bank_name }}</li>
            <li>Р/С: {{ senderObj.bankAcc?.rs }}</li>
            <li>К/С: {{ senderObj.bankAcc?.ks }}</li>
            <li>БИК: {{ senderObj.bankAcc?.bik }}</li>
          </ul>
        </li>

        <li v-if="payment?.cash">
          <b>Платеж наличными </b>
        </li>
      </ul>
      <ul class="list-unstyled p-3 w-50">
        <li>
          <b>Получатель: </b>
          <ul>
            <li>{{ receiverObj.company?.full_title }}</li>
            <li>ОРГН: {{ receiverObj.company?.ogrn }}</li>
            <li>ИНН: {{ receiverObj.company?.inn }}</li>
            <li>КПП: {{ receiverObj.company?.kpp }}</li>
          </ul>
        </li>

        <li>
          <b>Счет получателя: </b> <br />
          <ul>
            <li>Банк: {{ receiverObj.bankAcc?.bank_name }}</li>
            <li>Р/С: {{ receiverObj.bankAcc?.rs }}</li>
            <li>К/С: {{ receiverObj.bankAcc?.ks }}</li>
            <li>БИК: {{ receiverObj.bankAcc?.bik }}</li>
          </ul>
        </li>

        <li v-if="payment?.cash">
          <b>Платеж наличными </b>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const payment = ref(props.data.payment)
const owner = ref(props.data.owner)
const ownerBankAccount = ref(props.data.ownerBankAccount)
const contractor = ref(props.data.contractor)
const contractorBankAccount = ref(props.data.contractorBankAccount)
const isIncoming = ref(props.data.isIncoming)
const senderObj = computed(() =>
  isIncoming.value
    ? { company: contractor.value, bankAcc: contractorBankAccount.value }
    : { company: owner.value, bankAcc: ownerBankAccount.value },
)
const receiverObj = computed(() =>
  !isIncoming.value
    ? { company: contractor.value, bankAcc: contractorBankAccount.value }
    : { company: owner.value, bankAcc: ownerBankAccount.value },
)
</script>
