import { computed, reactive, toRaw } from 'vue'

// если поле view_permissions null то разрешается всё
// change_instructions - специальный для заявки на трансфер (не используется)

/**
 * @typedef {Array<'change' | 'view' | 'contacts' | 'print' | 'change_instructions' | undefined>} Permission
 */
/**
 * @typedef {Object} PermissionsObject
 * @property {boolean} change
 * @property {boolean} contacts
 * @property {boolean} view
 * @property {boolean} print
 * @property {boolean} change_instructions
 */

/**
 * @type {Permission}
 */
const PERMISSIONS = [
  'change',
  'view',
  'contacts',
  'print',
  'change_instructions',
]

const parsePermissions = (p) => p?.split(/,\s*/)
const setPermissionsFromObject = (permissions, viewPermissions) => {
  PERMISSIONS.forEach((entry) => {
    permissions[entry] = viewPermissions.includes(entry)
  })
}

/**
 * @param {PermissionsObject} permissions
 * @returns {boolean}
 */
const setEditMode = (permissions) => permissions.change
/**
 * @param {PermissionsObject} permissions
 * @returns {boolean}
 */
const setPrintMode = (permissions) => permissions.print || permissions.change
/**
 * @param {PermissionsObject} permissions
 * @returns {boolean}
 */
const setViewMode = (permissions) => permissions.view && !permissions.change
/**
 * @param {PermissionsObject} permissions
 * @returns {boolean}
 */
const setContactsMode = (permissions) => permissions.contacts

export function usePermissions() {
  /** @type {PermissionsObject} */
  const permissions = reactive({
    change: false,
    contacts: false,
    view: false,
    print: false,
    change_instructions: false,
  })

  /**
   * Обновление реактивных значений прав доступа для объекта
   * @param obj
   * @param props
   * @param {Permission} [props.defaults] - применяется если установлено и если view_permissions null или undefined.
   * Если не установлено и если view_permissions null или undefined то можно всё.
   * @returns {void}
   */
  function updPermissions(obj, { defaults = PERMISSIONS } = {}) {
    if (obj.view_permissions == null) {
      console.error('Не установлены view_permissions', obj)
    }
    const viewPermissions = parsePermissions(obj.view_permissions) || defaults
    setPermissionsFromObject(permissions, viewPermissions)
  }

  /**
   * Получение значений прав доступа для объекта
   * @param obj
   * @param props
   * @param {Permission} [props.defaults] - применяется если установлено и если view_permissions null или undefined.
   * Если не установлено и если view_permissions null или undefined то можно всё.
   * @returns {{isContactsMode: boolean, isEditMode: boolean, permissions: {change: boolean, contacts: boolean, view: boolean, print: boolean, change_instructions: boolean}, isPrintMode: boolean, isViewMode: boolean}}
   */
  function getPermissions(obj, { defaults = PERMISSIONS } = {}) {
    if (obj.view_permissions == null) {
      console.error('Не установлены view_permissions', obj)
    }
    const viewPermissions = parsePermissions(obj.view_permissions) || defaults
    const localPermissions = toRaw(permissions)
    setPermissionsFromObject(localPermissions, viewPermissions)

    return {
      isViewMode: setViewMode(localPermissions),
      isEditMode: setEditMode(localPermissions),
      isContactsMode: setContactsMode(localPermissions),
      isPrintMode: setPrintMode(localPermissions),
      permissions: localPermissions,
    }
  }

  const isEditMode = computed(() => setEditMode(permissions))
  const isPrintMode = computed(() => setPrintMode(permissions))
  const isViewMode = computed(() => setViewMode(permissions))
  const isContactsMode = computed(() => setContactsMode(permissions))

  return {
    isViewMode,
    isEditMode,
    isContactsMode,
    isPrintMode,
    permissions,
    updPermissions,
    getPermissions,
  }
}
