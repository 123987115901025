<template>
  <div v-if="formedValue" class="telecall-table-row">
    <div
      v-if="props.label === 'event_type_icon' && formedValue"
      class="event-type-icon"
      :title="formedValue.tooltip"
    >
      <AppIcon :icon="formedValue.icon" size="lg" :color="formedValue.color" />
    </div>
    <div v-else-if="props.label === 'status'" class="status">
      {{ formedValue.text }}
      <router-link
        v-if="isRowStatusFortelecallBack"
        @click.stop
        :to="formedLink"
        class="d-block me-2 simple-link"
        :target="formedLinkBlank"
        title="Ссылка на запись"
      >
        {{ formedValue }}
      </router-link>
    </div>
    <div v-else-if="isRowIdentityWithoutUserAndIdentity" class="phone-identity">
      {{ formedValue }}
    </div>
    <a
      v-else-if="props.label === 'record_url'"
      :href="formedLink"
      :target="formedLinkBlank"
      @click.stop
      :title="formedLinkForTooltip"
      class="record-url"
    >
      <AppIcon icon="play" size="lg" />
    </a>
    <router-link
      v-else
      @click.stop
      :to="formedLink"
      class="d-block me-2 simple-link"
      :target="formedLinkBlank"
      :title="formedLinkForTooltip"
    >
      {{ formedValue }}
    </router-link>
  </div>
</template>

<script setup>
//компонент для контента ячейки таблицы телефонных звонков, по-дефолту выводит роут линк
import { computed } from 'vue'
import C from '@/config/back_const'
import AppIcon from '@/plugins/app-icons/AppIcon'

const props = defineProps({
  //значение свойства
  value: {
    type: [String, Number, Object],
    required: true,
    default: () => null,
  },
  //название свойства
  label: {
    type: String,
    required: true,
  },
  //объкект со всеми свойствами объекта
  info: {
    type: Object,
    default: () => null,
  },
})

//данные свойства(обычно свойство - это это объект)
const formedData = computed(() => props.info?.formedData?.[props.label])

const formedValue = computed(formValue)
const formedLink = computed(formValueLinkTo)
const formedLinkBlank = computed(formLinkBlank)
const formedLinkForTooltip = computed(() => {
  return formedLink.value ? 'Ссылка на "' + formedValue.value + '"' : ''
})
const isRowIdentityWithoutUserAndIdentity = computed(() => {
  return props.label === 'identity' && formedData.value?.notIdentity
})
const isRowStatusFortelecallBack = computed(() => {
  return (
    formedValue.value?.tele_call_type === C.TELECALL_TYPE_BACK &&
    formedValue.value?.text &&
    formedValue.value?.record_url &&
    props.label != 'status'
  )
})

function formValueLinkTo() {
  if (props.label === 'identity' && formedData.value?.id) {
    return `/profile/${formedData.value.id}`
  } else if (props.label === 'record_url') {
    return props.value || ''
  } else if (props.label === 'object_id') {
    if (formedData.value.object_type === 'core__lead') {
      return formedData.value?.id ? `/lead/${formedData.value.id}` : ''
    } else if (formedData.value.type_object === 'core__tour') {
      return formedData.value?.id ? `/tour/${formedData.value.id}` : ''
    }
  } else if (props.label === 'initiator') {
    return formedData.value?.id ? `/customer/${formedData.value.id}` : ''
  } else if (isRowStatusFortelecallBack.value) {
    return formedValue.value?.record_url || ''
  }
  return ''
}

function formLinkBlank() {
  if (props.label === 'identity' || props.label === 'object_id') {
    return `_self`
  }
  return '_blank'
}

function formValue() {
  let text = props.value || ''

  if (props.label === 'object_id') {
    if (formedData.value.object_type === 'core__lead') {
      text = 'Лид #' + formedData.value.id
    } else if (formedData.value.type_object === 'core__tour') {
      text = 'Тур #' + formedData.value.id
    }
  } else if (props.label === 'initiator') {
    text = formedData.value?.name
  } else if (props.label === 'identity') {
    const role = formedData.value?.role
      ? C.ROLES_TITLES[formedData.value.role]
      : ''
    if (formedData.value?.identityUser) {
      const name = formedData.value?.identityUser?.name || ''
      const surname = formedData.value?.identityUser?.surname || ''
      const fullname = surname ? `${name} ${surname}` : name
      text = `${fullname} (${role})`
    } else if (formedData.value?.id) {
      text = `${role} #${formedData.value.id}`
    } else {
      text = `__`
    }
  }

  return text
}
</script>

<style lang="scss" scoped>
.status {
  word-break: break-word;
}
</style>
