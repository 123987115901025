<template>
  <div class="flex gap-2 me-3">
    <template v-if="(isAdmin || isAgent) && myCompany.is_agency">
      <!-- Задачи -->
      <div class="position-relative p-1">
        <ActionButton
          icon="checkOn"
          tooltip="Задачи"
          :color="tasksColor"
          size="sm"
          icon-size="lg"
          :action="
            () => {
              $router.push({ path: '/task/' })
            }
          "
        />
      </div>
      <!-- Сообщения -->
      <div class="position-relative p-1">
        <ActionButton
          icon="envelope"
          tooltip="Сообщения"
          :color="messageColor"
          size="sm"
          icon-size="lg"
          :action="() => {}"
        />
        <CBadge
          class="position-absolute status-badge-position no-mouse-events"
          :color="messageColor"
          shape="rounded-pill"
          >{{ messageCountLabel }}</CBadge
        >
      </div>
      <!-- Уведомления -->
      <div class="position-relative p-1">
        <ActionButton
          icon="alert"
          :tooltip="reminderStatus.tooltip"
          :color="reminderStatus.color"
          size="sm"
          icon-size="lg"
          :action="
            () => {
              $router.push({ path: '/reminders/' })
            }
          "
        />
        <CBadge
          v-if="reminderStatus.count"
          class="position-absolute status-badge-position no-mouse-events"
          :color="reminderStatus.color"
          shape="rounded-pill"
          >{{ reminderStatus.count }}</CBadge
        >
      </div>
      <!-- Лиды -->
      <template v-if="isAgent || isAdmin">
        <CDropdown
          variant="nav-item"
          :visible="toggleMenuVisible"
          @hide="toggleMenuVisible = false"
          @show="toggleMenuVisible = true"
          :disabled="leadDropdownDisabled"
        >
          <CDropdownToggle @click.prevent :caret="false" placement="bottom-end">
            <div
              class="position-relative p-1"
              :class="{ blink: leadStatus.blink }"
            >
              <ActionButton
                icon=""
                :tooltip="leadStatus.tooltip"
                :color="leadStatus.color || 'secondary'"
                size="sm"
                icon-size="lg"
                :action="() => {}"
              />
              <CBadge
                class="position-absolute status-badge-position no-mouse-events"
                :color="leadStatus.color"
                shape="rounded-pill"
              ></CBadge>
            </div>
          </CDropdownToggle>

          <CDropdownMenu class="p-0" @mouseup.stop="onDropMenuClick">
            <CDropdownItem
              v-if="canTakeLead"
              class="d-block py-2 user-select-none"
              href="javascript:void(0)"
              @click="takeNewLead"
            >
              <div class="message flex-column">
                <div class="position-relative">Взять в работу</div>
              </div>
            </CDropdownItem>

            <CDropdownItem
              class="d-block py-2 user-select-none"
              href="javascript:void(0)"
              :disabled="isAgent"
              @click="goToLeads(1)"
            >
              <div class="message flex-column">
                <div class="position-relative">
                  Новых лидов {{ leadStatus.new_leads_count }}
                </div>
              </div>
            </CDropdownItem>

            <CDropdownItem
              class="d-block py-2 user-select-none"
              href="javascript:void(0)"
              :disabled="isAgent"
              @click="goToLeads(1)"
            >
              <div class="message flex-column">
                <div class="position-relative">
                  Новых с чатом {{ leadStatus.new_leads_with_chat }}
                </div>
              </div>
            </CDropdownItem>

            <CDropdownItem
              class="d-block py-2 user-select-none"
              href="javascript:void(0)"
              @click="goToLeads(2)"
            >
              <div class="message flex-column">
                <div class="position-relative">
                  Лидов в работе {{ leadStatus.lead_count }}
                </div>
              </div>
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </template>
      <!-- Туры в работе -->
      <div v-if="tour_count" class="position-relative p-1">
        <ActionButton
          icon=""
          tooltip="Туры в работе"
          :color="tour_count ? 'success' : 'secondary'"
          size="sm"
          icon-size="lg"
          :action="() => {}"
        />
        <CBadge
          class="position-absolute status-badge-position no-mouse-events"
          :color="tour_count ? 'success' : 'secondary'"
          shape="rounded-pill"
          >{{ tour_count || '' }}</CBadge
        >
      </div>
      <!-- Иконка чатов -->
      <Teleport v-if="ready" to="#header-chat-icon">
        <div
          :class="{ blink: chatStatus.blink }"
          :style="`--header-chat-icon-scale: ${chatStatus.scale}`"
        >
          <ActionButton
            icon="chat"
            tooltip="Чаты"
            :color="chatStatus.color"
            size="sm"
            icon-size="lg"
            :action="chatStatus.action"
          />
        </div>
      </Teleport>
      <!-- Кнопка онлайн -->
      <Teleport
        v-if="ready && C.AGENT_ROLES.includes(myIdentity?.role)"
        to="#header-online-status"
      >
        <div v-if="onlineStatus !== null" class="ms-2 me-3">
          <CTooltip
            v-if="onlineStatus"
            content="Переключить в офлайн"
            placement="bottom"
          >
            <template #toggler="{ on }">
              <CButton
                v-on="on"
                color="success"
                size="sm"
                @click="toggleOnline"
              >
                <span class="me-2">Онлайн</span>
                <AppIcon icon="phone" size="sm" />
              </CButton>
            </template>
          </CTooltip>
          <CTooltip v-else content="Переключить в онлайн" placement="bottom">
            <template #toggler="{ on }">
              <CButton
                v-on="on"
                color="danger"
                size="sm"
                variant="outline"
                @click="toggleOnline"
              >
                <span class="me-2">Офлайн</span>
                <AppIcon icon="phone" size="sm" />
              </CButton>
            </template>
          </CTooltip>
        </div>
      </Teleport>
    </template>
  </div>

  <LeadTake
    v-if="leadTakePopupVisible"
    :lead-status="leadStatus"
    @close="onLeadTakeClose"
    @take="onTake"
  />
</template>

<script setup>
import {
  computed,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import ActionButton from '@/components/_common/ActionButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import LeadTake from '@/popups/common/LeadTake.vue'
import C from '@/config/back_const'
import constants from '@/config/constants'
import { getDefaultFilters } from '@/components/_leads/LeadsTable.vue'
import { useAccessWS } from '@/composables/accessWS'
import { useAuthData } from '@/composables/authData'
import AppIcon from '@/plugins/app-icons/AppIcon.vue'
import { getWSMessage } from '@/helpers'
import { withActiveWin } from '@/helpers/winTracker'

const WH = 'b5c8b999-7b71-4db9-8ce2-c4c84d520c17'
// const leadsWH = '1db4b8a6-4cff-4020-a843-dba09b6b147e' // WH Leads.vue для установки фильтров
const DEBUG = true

const { websocket, leadApi, accountApi /*persistentService*/ } =
  inject('services')
const bus = inject('bus')
const store = useStore()
const router = useRouter()
const route = useRoute()

const { setWSAction } = useAccessWS(websocket)
const { myIdentity, myCompany, isAdmin, isAgent } = useAuthData(onAuthChange)
const ready = ref(false)
const sound1 = new Audio('lead-sound-1.wav')
const sound2 = new Audio('lead-sound-2.wav')

const messages_count = ref(0)
const messages_need_to_answer = ref(0)
const messageColor = computed(() => {
  return messages_count.value > 0
    ? 'danger'
    : messages_need_to_answer.value > 0
    ? 'warning'
    : 'secondary'
})
const messageCountLabel = computed(() => {
  return `${messages_count.value || ''}${
    messages_count.value && messages_need_to_answer.value ? '/' : ''
  }${messages_need_to_answer.value || ''}`
})
const scheduled_tasks = ref(0)
const scheduled_tasks_expired = ref(0)
const tasksColor = computed(() => {
  return scheduled_tasks_expired.value > 0
    ? 'danger'
    : scheduled_tasks.value > 0
    ? 'primary'
    : 'secondary'
})
const tour_count = ref(0)
const leadStatus = ref({
  // -1 чтобы не было смены с 0 на не 0 когда начальный статус будет получен на onMounted, т.к. это не смена статуса а первоначальное получение статуса
  lead_count: -1,
  new_leads_count: -1,
  new_leads_with_chat: -1,
  count: 0,
  color: 'secondary',
  blink: false,
})
const reminderStatus = ref({
  count: 0,
  color: 'secondary',
  tooltip: '',
  notifications_count: 0,
  notifications_expired: 0,
})
const chatStatusDefault = {
  color: 'primary',
  blink: false,
  scale: 1.1,
  action: () => {
    store.commit('toggleAside')
  },
}
const chatStatus = ref(chatStatusDefault)
const onlineStatus = ref(null)
const importStatus = ref(null)
const toggleMenuVisible = ref(false)
const leadDropdownDisabled = ref(true)
const leadTakePopupVisible = ref(false)
const processing = ref(false) // чтобы процессить только один статус в 200ms т.к. иногда прилетает сразу два
const bodyWrapEl = ref(null)

const canTakeLead = computed(() => {
  return (
    isAgent.value &&
    (leadStatus.value.new_leads_count > 0 ||
      leadStatus.value.new_leads_with_chat > 0)
  )
})

const takeNewLead = async () => {
  try {
    const lead = await leadApi.leadTakePost(WH)
    leadStatus.value.blink = false
    if (lead?.id) {
      router.push({ path: `/lead/${lead.id}/` })
    }
  } catch (err) {
    console.error(err)
  }
}
const goToLeads = async (status) => {
  const path = '/leads/'
  const leadListFilter = { ...getDefaultFilters(), status }
  if (route.path !== path) {
    await router.push(path)
  }
  // Установить фильтр для первой вкладки в списке лидов
  bus.emit('leads_list_filter', leadListFilter)
}
const getLeadStatus = (status) => {
  const lead_count = Number(status?.lead_count || 0)
  const new_leads_count = Number(status?.new_leads_count || 0)
  const new_leads_with_chat = Number(status?.new_leads_with_chat || 0)

  const leadCountData = { lead_count, new_leads_count, new_leads_with_chat }
  // count решено не выводить в иконке
  const lStatus = {
    count: lead_count,
    color: 'success',
    blink: false,
    tooltip:
      new_leads_with_chat > 0
        ? 'Есть новые чаты'
        : new_leads_count > 0
        ? 'Есть новые лиды'
        : 'Лиды',
    ...leadCountData,
  }
  if (
    (new_leads_with_chat > 0 || new_leads_count > 0) &&
    onlineStatus.value &&
    isAgent.value
  ) {
    lStatus.blink = true
    lStatus.color = 'danger'
  }
  return lStatus
}
const getReminderStatus = (status) => {
  const notifications_count = Number(status?.notifications_count || 0)
  const notifications_expired = Number(status?.notifications_expired || 0)

  const countData = { notifications_count, notifications_expired }
  const _status = {
    count: notifications_count,
    color:
      notifications_count > 0
        ? notifications_expired > 0
          ? 'danger'
          : 'success'
        : 'secondary',
    tooltip:
      notifications_count > 0
        ? 'Перейти ко всем уведомлениям'
        : 'Уведомлений нет',
    ...countData,
  }

  return _status
}
const onDropMenuClick = (ev) => {
  // если пункт меню disabled, то target само меню - ничего не делаем
  if (ev.currentTarget === ev.target) return
  toggleMenuVisible.value = false
}
const processStatus = (data) => {
  // data = fakeStatus // для теста
  if (!data || processing.value) return
  processing.value = true
  try {
    onlineStatus.value = data.online
    leadStatus.value = getLeadStatus(data)
    reminderStatus.value = getReminderStatus(data)
    messages_count.value = Number(data?.messages_count || 0)
    messages_need_to_answer.value = Number(data?.messages_need_to_answer || 0)
    scheduled_tasks.value = Number(data?.scheduled_tasks || 0)
    scheduled_tasks_expired.value = Number(data?.scheduled_tasks_expired || 0)
    tour_count.value = Number(data?.tour_count || 0)
    importStatus.value = {
      is_importing: data.is_importing,
      import_failed: data.import_failed,
    }

    setTimeout(() => {
      processing.value = false
    }, 200)
  } catch (err) {
    processing.value = false
    console.error(err)
  }
}
const getStatus = async () => {
  try {
    const res = await accountApi.statusRetrieve(WH)
    return res
  } catch (err) {
    console.error(err)
  }
}
const onTake = () => {
  takeNewLead()
  leadTakePopupVisible.value = false
}
const onLeadTakeClose = () => {
  leadTakePopupVisible.value = false
}
const toggleOnline = async () => {
  if (DEBUG) {
    console.log('>>>>> toggleOnline button')
  }
  try {
    if (DEBUG) {
      console.log('>>>>> onlineStatus:', onlineStatus.value)
    }
    if (onlineStatus.value) {
      accountApi.offlineCreate(WH)
      onlineStatus.value = false
      // В статусе офлайн не мигать
      chatStatus.value = chatStatusDefault
      leadStatus.value.blink = false
    } else {
      onlineStatus.value = true
      await accountApi.onlineCreate(WH)
      processStatus(await getStatus())
    }
  } catch (err) {
    console.error(err)
  }
}

async function onAuthChange() {
  processStatus(await getStatus())
}
const activateWindow = () => {
  withActiveWin(() => {
    // отправляем message в content-script.js crome-extension для активации этого окна
    const type = constants.postMessages.CLIENT_ACTIVATE_WIN
    window.postMessage({ type, text: JSON.stringify({}) }, '*')
  })
}

const wsAction = () => {
  websocket.connection.addEventListener('message', (ev) => {
    const message = getWSMessage(ev)
    if (!message) return

    if (message.type !== 'notifier') return
    const {
      data: { data },
    } = message
    processStatus(data)
  })
}
setWSAction(wsAction)

// Статус для теста + change in processStatus
/*
const fakeStatus = {
  id: 226,
  role: 'agent',
  company: 2,
  lead_count: 0,
  tour_count: 2,
  messages_count: 0,
  company__is_agency: 1,
  notifications_count: 3,
  notifications_expired: 1,
  messages_need_to_answer: 0,
  scheduled_tasks: 0,
  scheduled_tasks_expired: 0,
  new_leads_count: 0,
  new_leads_with_chat: 1,
  data_type: 'virtual__status',
  is_importing: false,
  import_failed: false,
  online: false,
  last_action_at: '2023-09-26 14:56:49.272964+03:00',
  expire_till: '2023-09-26 22:00:00+03:00',
  last_action_url: '/api/account/status/',
  telephony_status: null,
}
*/

watch(
  leadStatus,
  (newVal, prevVal) => {
    leadDropdownDisabled.value =
      newVal.new_leads_count === 0 &&
      newVal.lead_count === 0 &&
      newVal.new_leads_with_chat === 0

    if (
      onlineStatus.value &&
      isAgent.value &&
      prevVal.new_leads_with_chat !== -1 &&
      newVal.new_leads_with_chat > prevVal.new_leads_with_chat
      /*newVal.new_leads_with_chat > 0 &&
      prevVal.new_leads_with_chat === 0*/
    ) {
      activateWindow()
      leadTakePopupVisible.value = true
      sound2.play()
      bus.emit('new_leads_with_chat')
      return
    }
    if (
      onlineStatus.value &&
      isAgent.value &&
      newVal.new_leads_count > 0 &&
      prevVal.new_leads_count === 0
    ) {
      sound1.play()
    }
  },
  { deep: true },
)
watch(messages_need_to_answer, (val) => {
  if (val > 0 && onlineStatus.value) {
    chatStatus.value = {
      color: 'primary',
      blink: true,
      scale: 1.3,
      action: () => {
        store.commit('toggleAside', true)
      },
    }
  } else {
    chatStatus.value = chatStatusDefault
  }
})
watch(importStatus, (val) => {
  const { is_importing, import_failed } = val
  const bodyStyles = bodyWrapEl.value.style
  if (import_failed) {
    bodyStyles.setProperty('--cui-body-bg-rgb', '229, 83, 83') // --cui-danger
    bodyStyles.setProperty('--cui-bg-opacity', '0.2')
  } else {
    if (is_importing) {
      bodyStyles.setProperty('--cui-body-bg-rgb', '255, 193, 7') // --cui-yellow
      bodyStyles.setProperty('--cui-bg-opacity', '0.2')
      return
    }
    bodyStyles.removeProperty('--cui-body-bg-rgb')
    bodyStyles.removeProperty('--cui-bg-opacity')
  }
})
watch(onlineStatus, (val) => {
  if (DEBUG) {
    console.log('>>>>> change onlineStatus:', val)
  }
  const emitType = val ? 'set-user-online' : 'set-user-offline'
  bus.emit(emitType)
})

onMounted(async () => {
  bodyWrapEl.value = document.getElementById('body-wrapper')
  processStatus(await getStatus())
  nextTick(() => {
    ready.value = true
  })
})

onUnmounted(() => {
  bus.off('authentication-changed', onAuthChange)
})
</script>
