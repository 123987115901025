<template>
  <template v-if="result && Object.keys(result).length">
    <div
      class="overflow-scroll"
      :style="`max-height: calc(100vh ${
        isLG ? '- var(--cui-modal-margin) * 3' : '- var(--cui-modal-padding)'
      } - var(--cui-modal-padding) * 2 - ${headerElH}px - ${footerElH}px);`"
    >
      <template v-if="tabs.length > 1">
        <TableTabs :tabs="tabs" :set-tab="setTab" :active-tab="activeTab" />
        <CTabContent class="h-100">
          <CTabPane :visible="true">
            <HotelPlacesTable
              v-for="(data, booker) in activeTabData"
              :key="booker"
              :month-sorted="monthDataSortedArr"
              :dates-header="datesHeader"
              :categories="hotelCategories"
              :booker="booker"
              :data="data"
              @select-row="selectRow"
            />
          </CTabPane>
        </CTabContent>
      </template>
      <template v-else>
        <HotelPlacesTable
          v-for="(data, booker) in result"
          :key="booker"
          :month-sorted="monthDataSortedArr"
          :dates-header="datesHeader"
          :categories="hotelCategories"
          :booker="booker"
          :data="data"
          @select-row="selectRow"
        />
      </template>
    </div>
  </template>

  <template v-if="ready && modalWh">
    <teleport v-if="tabs.length > 1" :to="`#id-after-body-${modalWh}`">
      <small class="text-disabled opacity-50 pe-2">{{
        tabs[activeTab].booker
      }}</small>
    </teleport>
    <Teleport :to="`#id-title-${modalWh}`">
      <span
        >{{ dataReq.hotelTitle }} наличие мест на период с
        {{ dateService.formatDate(dataReq?.date_fr) }} по
        {{ dateService.formatDate(shiftedDateTo) }}</span
      >
    </Teleport>
    <Teleport
      v-if="showCheckboxForHideEmptyValues"
      :to="`#id-footer-${modalWh}`"
    >
      <CFormCheck
        id="hideEmptyValues"
        v-model="hideEmptyValues"
        label="Скрывать номера без мест"
      />
    </Teleport>
  </template>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { addDays } from '@/helpers'
import { useRequest } from '@/composables/request'
import HotelPlacesTable from '@/components/_shared/hotel-places/HotelPlacesTable.vue'
import TableTabs from '@/components/_shared/TableWithTabsWrap/TableTabs.vue'
import { addExceptionIgnore } from '@/helpers/errorIgnoreList'

const WH = '73cb7a72-eef7-40e8-92c7-da83672bf0bf'
const { coreApi, categoryApi, storage, dateService, notification } =
  inject('services')

const props = defineProps({
  modalWh: {
    type: String,
    required: false,
    default: () => null,
  },
  /**
   * @type {{
   *   hotel: number
   *   hotelTitle?: string
   *   category?: number
   *   date_fr: string
   *   date_to: string
   *   days_to?: string
   * }}
   */
  dataReq: {
    type: Object,
    required: true,
  },
  // Передаём фн т.к. эмит close работатет c addException колбэком только при первом открытии окна, непонятно почему
  close: {
    type: Function,
    required: true,
  },
})

const { wrapRequest } = useRequest({ lockTarget: `#id-${props.modalWh}` })

const ready = ref(false)
const hotelCategories = ref([])
const result = ref(null)
const fullRange = ref(null)
const monthDataSortedArr = ref([])
const datesHeader = ref([])
const hideEmptyValues = ref(false)
const shiftedDateTo = ref(props.dataReq?.date_to)
const showCheckboxForHideEmptyValues = ref(false) // решено пока не показывать чекбох
const headerElH = ref(0)
const footerElH = ref(0)
const mediaQListLG = window.matchMedia('(min-width: 1400px)')
const isLG = ref(mediaQListLG.matches)
const selectedRowEl = ref(null)
const activeTab = ref()

const tabs = computed(() =>
  Object.keys(result.value).map((name, i) => ({
    name: `Вариант ${i + 1}`,
    booker: name,
  })),
)
const activeTabData = computed(() => {
  const booker = tabs.value[activeTab.value].booker
  return { [booker]: result.value[booker] }
})

function setTab(val) {
  activeTab.value = val
}
const shiftDateTo = (to, daysStr) => {
  if (!daysStr) return to
  const dateTo = new Date(to)
  const days = Number(daysStr)
  const shiftedDateTo = addDays(dateTo, days - 1) // - 1 потому что прибавляем дни к date_to, но date_to уже включено в кол-во дней (days)
  return dateService.toIso(shiftedDateTo)
}
const genFullRange = (from, to) => {
  const r = {}
  let i = 0 // ограничить генерацию диапазона (365), на всякий случай чтобы не было тормозов
  let date = from
  while (date !== to && i < 365) {
    i++
    r[date] = 0
    date = dateService.toIso(addDays(new Date(date), 1))
  }
  // добавить последнюю дату
  r[date] = 0
  return r
}
const getModalHeadFootHeight = () => {
  nextTick(() => {
    headerElH.value = document.querySelector('.modal-header')?.offsetHeight || 0
    footerElH.value = document.querySelector('.modal-footer')?.offsetHeight || 0
  })
}
const mediaQListLGHandler = (ev) => {
  isLG.value = ev.matches
}
const selectRow = (ev) => {
  if (selectedRowEl.value) {
    selectedRowEl.value.classList.remove('bg-info', 'bg-opacity-15')
  }
  if (ev.currentTarget !== selectedRowEl.value) {
    ev.currentTarget.classList.add('bg-info', 'bg-opacity-15')
    selectedRowEl.value = ev.currentTarget
  } else {
    selectedRowEl.value = null
  }
}
const prepReqParams = (dateToShifted) => {
  const reqParams = props.dataReq
  reqParams.date_to = dateToShifted
  reqParams.hotelTitle = undefined
  return reqParams
}
const prepResultForBooker = (res, key) => {
  for (const [cat, val] of Object.entries(res[key])) {
    const catDataMapped = Object.entries(fullRange.value).map(([key]) => [
      key,
      val[key] || null,
    ])
    res[key][cat] = Object.fromEntries(catDataMapped)
  }
  return res[key]
}
const prepResultData = (res = {}) => {
  const hotelId = props.dataReq.hotel
  return Object.keys(res).reduce((acc, key) => {
    if (key.includes(hotelId)) {
      const _key = key.includes(':') ? key : `NONE:${key}`
      const [booker] = _key.split(':')
      acc[booker] = { requested: {}, alternatives: {} }
      acc[booker].requested = prepResultForBooker(res, key)
    }
    // else потом могут быть другие отели как альтернатива их добавим в отдельный объект alternatives
    // можно выводить названия отелей в отдельной секции или вкладке с кнопками показать наличие и при нажаитии уже подгружать список категорий этого отеля
    // и показывать таблцу
    return acc
  }, {})
}
const prepMonthData = () => {
  const monthYearData = {}
  for (const date of datesHeader.value) {
    const month = date.split('-')[1]
    const year = date.split('-')[0]
    const key = `${month}-${year}`
    monthYearData[key] = monthYearData[key] || []
    monthYearData[key].push(date)
  }
  return Object.entries(monthYearData).sort((a, b) => {
    const keyA = a[0].split('-').map((d) => Number(d))
    const keyB = b[0].split('-').map((d) => Number(d))
    const [monthA, yearA] = keyA
    const [monthB, yearB] = keyB
    const res = yearA < yearB ? -1 : yearA === yearB ? 0 : 1
    if (res === 1) return res
    return monthA < monthB ? -1 : monthA === monthB ? 0 : 1
  })
}
const addException = () => {
  // Специально обрабатываем 422 "Не найден букер..." https://tracker.yandex.ru/BACK-4481
  const cb = () => {
    notification.warning('Операция не доступна для данного отеля')
    props.close({ disableBtn: true })
  }
  const params = {
    url: 'api/core/available_space/?',
    status: 422,
    method: 'PUT',
    cbList: [{ cb, id: WH }],
  }
  addExceptionIgnore(params)
}
const createFnGetInitialData = (reqParams) => {
  return async () => {
    return Promise.all([
      coreApi.availableSpacePut(WH, reqParams),
      categoryApi.hotelListCategoriesList(WH, props.dataReq.hotel, {
        limit: 50,
        filters: { is_active: true },
      }),
    ])
  }
}

onMounted(async () => {
  addException()

  getModalHeadFootHeight()
  mediaQListLG.addEventListener('change', mediaQListLGHandler)

  setTab(0)

  if (!props.dataReq) return

  // если срок проживания больше выбранного дипазона дат - сдвигаем дату, прибавляем макс. срок проживания
  shiftedDateTo.value = shiftDateTo(
    props.dataReq.date_to,
    props.dataReq.days_to,
  )
  // формируем параметры для запроса
  const reqParams = prepReqParams(shiftedDateTo.value)
  const { result: reqResult } = await wrapRequest(
    createFnGetInitialData(reqParams),
  )
  if (!reqResult) return
  const [res, _categories] = reqResult
  hotelCategories.value = _categories.data

  // генерируется весь диапазон с 0ми (на случай отстутсвия даты в result)
  fullRange.value = genFullRange(reqParams.date_fr, reqParams.date_to)
  datesHeader.value = Object.keys(fullRange.value)
  monthDataSortedArr.value = prepMonthData()
  result.value = prepResultData(res)

  ready.value = true
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
