<template>
  <div>
    <v-client-table
      v-if="data.length"
      :data="data"
      :columns="columns"
      :options="options"
    >
      <template #beforeLimit>
         <div class="mt-4 nobr">
           <div>
             <input
               id="deleteUnaccepted"
               type="checkbox"
               class="form-check-input mt-1 me-2"
               :checked="deleteUnaccepted"
               @change="deleteUnaccepted = !deleteUnaccepted"
             />
             <CFormLabel for="deleteUnaccepted" class="nobr">
               Удалять непринятые платежи
             </CFormLabel>
           </div>
           <div>
             <input
               id="autoSplit"
               type="checkbox"
               class="form-check-input mt-1 me-2"
               :checked="autoSplit"
               @change="autoSplit = !autoSplit"
             />
             <CFormLabel for="autoSplit" class="nobr">
               Автоматически проводить принимаемые платежи
             </CFormLabel>
           </div>
         </div>
      </template>

      <template #check="{row}">
        <div
          class="w-100 h-100 flex justify-content-center align-content-center flex-wrap"
          @click.self="checkRow(row.id)"
        >
          <input
            type="checkbox"
            class="form-check-input mt-0"
            :checked="checked.includes(row.id)"
            @change="checkRow(row.id)"
          />
        </div>
      </template>

      <template #docNumber="{row}">
        <a href="" @click.prevent="idPayment=row.id">{{ row.doc_number }}</a>
      </template>

      <template #docDate="{row}">
        {{ dateService.formatDate(row.doc_date) }}
      </template>

      <template #amount="{row}">
        <div class="text-end ">{{ formatCents(row.amount) }}</div>
      </template>

      <template #type="{row}">
        {{ C.DOCUMENT_STATE_TITLES[row.type] }}
      </template>

      <template #info="{row}">
        <strong>{{ contractors[row.contractor_entity]?.title }}</strong><br>
        {{ row.info }}
      </template>

      <template #paymentNumber="{row}">
        {{ row.payment_doc_number }}
      </template>

      <template #paymentDate="{row}">
        {{ dateService.formatDate(row.payment_doc_date) }}
      </template>

    </v-client-table>

    <Teleport v-if="active && ready" to=".modal-footer>div">
      <CButton
          color="primary"
          :disabled="!checked.length"
          @click="accept()"
        >
          Принять {{ checked.length ? `(${checked.length})` : '' }}
        </CButton>
    </Teleport>
    <PopupPaymentInfo v-if="idPayment && (!paymentType || paymentType === C.IMPORT_FILE_TYPE_IMPORT_PAYMENTS)" :id="idPayment" @close="idPayment=null" />
    <PopupPaymentInfoBuyer v-if="idPayment && paymentType === C.IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS" :id="idPayment" @close="idPayment=null" />
  </div>
</template>

<script setup>
import {ref, computed, h, inject, onUnmounted, onMounted, watch} from 'vue'
import {formatCents} from '@/helpers/textFormatter.js'
import C from "@/config/back_const"
import tableSettings from '@/config/table-config.js'
import PopupPaymentInfo from "@/components/_payment/PopupPaymentInfo.vue"
import PopupPaymentInfoBuyer from "@/components/_payment/PopupPaymentInfoBuyer.vue";

const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  contractors: {
    type: Object,
    required: false,
    default: () => ({})
  },
  active: {
    type: Boolean,
    required: true
  },
  paymentType: {
    type: Number,
    required: false,
    default: C.IMPORT_FILE_TYPE_IMPORT_PAYMENTS,
  },
})
const emit = defineEmits('accept')

const WH = '9f20df7c-97c2-4d9f-b67a-32138a405ac8'
const { storage, dateService, persistentService, accountApi, auth: authService } = inject('services')

const ready = ref(false)
const idPayment = ref(null)

const checked = ref([])
const allChecked = ref(false)
const checkRow = (id) => {
  if (checked.value.includes(id)) {
    checked.value = checked.value.filter(_id => _id !== id)
  } else {
    checked.value.push(id)
  }
}
const checkAll = () => {
  if (allChecked.value) {
    checked.value = []
  } else {
    checked.value = props.data.map(item => item.id)
  }
}
watch(checked, () => {
  allChecked.value = props.data.every(item => checked.value.includes(item.id))
}, { deep: true })

const headings = {
  check: '',
  docNumber: '№ выписки',
  docDate: 'Дата выписки',
  amount: 'Сумма',
  type: 'Статус',
  info: 'Инфо',
  paymentNumber: 'Номер п/п',
  paymentDate: 'Дата п/п'
}
const columns = Object.keys(headings)
const columnsClasses = {
  check: 'w-5',
  paymentNumber: 'w-5 nobr',
  paymentDate: 'w-5 nobr z-index-5',
  type: 'w-5 nobr',
  info: 'w-50',
  docNumber: 'w-5 nobr',
  docDate: 'w-5 nobr z-index-5'
}
const options = computed(() => {
  return {
    ...tableSettings,
    filterable: false,
    texts: { count: '', limit: '' },
    headings: {
      ...headings,
      check: h('div', { style: 'display: inline-flex; width: 100%; justify-content: center; align-items: center;' }, [
          h('input', {
            title: allChecked.value ? 'очистить' : 'выбрать все',
            checked: allChecked.value,
            type: 'checkbox',
            class: 'form-check-input',
            onChange: checkAll
          })
        ]
      )
    },
    columnsClasses,
    sortable: []
  }
})

const deleteUnaccepted = ref(true)
const autoSplit = ref(true)

watch(deleteUnaccepted, (val) => {
  persistentService.set('delete_unaccepted', val)
})

const accept = () => {
  emit('accept', {
    ids: checked.value,
    options: {
      delete_unaccepted: deleteUnaccepted.value,
      auto_split: autoSplit.value
    }
  })
  checked.value = []
}

onMounted(async () => {

  const company = await authService.getCompany()
  const companySettings = await accountApi.companySettingRetrieve(WH, company.id)
  autoSplit.value = companySettings.common?.split_payments_after_import.value ?? companySettings.common?.split_payments_after_import.default

  persistentService.get('delete_unaccepted').then((value) => {
    deleteUnaccepted.value = value || false
  })

  setTimeout(() => {
    ready.value = true
  }, 600)
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>

<style lang="scss" scoped>

</style>
