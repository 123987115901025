<template>
  <div class="mb-5">
    <div>
      <Markdown :source="props.hotel.full_description" />
    </div>
    <div v-if="checkTime && checkTime.length">
      <ul class="list-unstyled flex gap-3">
        <li v-for="item in checkTime" :key="item.title">
          <span class="fw-medium">{{ item.title }}</span>
          <br />
          {{ item.time }}
        </li>
      </ul>
    </div>
  </div>
  <div v-if="dataContact" class="mb-5">
    <h4>Контакты:</h4>
    <div class="contacts">
      <div class="contact" v-for="contact in dataContact" :key="contact.title">
        {{ contact.type }}: {{ contact.title }}
      </div>
    </div>
  </div>
  <div v-if="hotel?.servicesList?.length">
    <h4>Информация для агентств</h4>
    <div>
      <h5 class="mb-4">Услуги/Сервисы</h5>
      <CollapsePreviewContent rem-height="25" :has-gradient="false">
        <ul class="list-unstyled" style="list-style-position: inside">
          <li
            v-for="service in hotel?.servicesList"
            :key="service.id"
            class="mb-3"
          >
            <h6 class="d-inline-block mb-2">
              {{ service.title }}
            </h6>
            <p>
              {{ service.description }}
            </p>
          </li>
        </ul>
      </CollapsePreviewContent>
    </div>
  </div>
</template>

<script setup>
import Markdown from 'vue3-markdown-it'
import { inject, computed, toRef, ref, watch } from 'vue'
import C from '@/config/back_const'
import CollapsePreviewContent from '@/components/custom/CollapsePreviewContent.vue'

const WH = '2092d844-d565-11ee-a506-0242ac120002'

const { contactApi } = inject('services')

const haveId = ref(false)
const dataContact = ref(null)

const props = defineProps({
  hotel: {
    type: Object,
    required: true,
  },
})

const hotel = toRef(props, 'hotel')

const checkTime = computed(() => {
  const local = []

  if (hotel.value?.check_time?.check_in) {
    local.push({
      title: 'Заезд',
      time: hotel.value.check_time.check_in,
    })
  }

  if (hotel.value?.check_time?.check_out) {
    local.push({
      title: 'Выезд',
      time: hotel.value.check_time.check_out,
    })
  }

  if (hotel.value?.check_time?.check_payment) {
    local.push({
      title: 'Расчетный час',
      time: hotel.value.check_time.check_payment,
    })
  }

  return local
})

async function getData(id) {
  haveId.value = true
  try {
    const data = await contactApi.contactList(WH, id, 'refbook__hotel')
    dataContact.value = data?.data?.length
      ? data?.data.map((contact) => {
          return {
            type: C.CONTACT_TYPE_TITLES[contact.type],
            title: contact.number,
          }
        })
      : null
  } catch (err) {
    console.error(err, 'errr')
  }
}

watch(
  hotel,
  (val) => {
    if (val.id && !haveId.value) {
      getData(val.id)
    }
  },
  { deep: true },
)
</script>
