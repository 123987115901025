<template>
  <div>
    <v-client-table
      v-if="data"
      :data="Object.values(data)"
      :columns="columns"
      :options="options"
    >
      <template #docNumber="{row, index}">
        <a href="" @click.prevent="idPayment=getPaymentData(index, 'id')">{{ getPaymentData(index, 'doc_number') }}</a>
      </template>

      <template #docDate="{row, index}">
        {{ dateService.formatDate(getPaymentData(index, 'doc_date')) }}
      </template>

      <template #amount="{row, index}">
        {{ formatCents(getPaymentData(index, 'amount')) }}
      </template>

      <template #error="{row}">
      {{ row }}
      </template>

    </v-client-table>

    <Teleport v-if="active && ready" to=".modal-footer>div">
      <CButton color="primary" class="mx-2" @click="toCSV">CSV</CButton>
    </Teleport>
    <PopupPaymentInfo v-if="idPayment && (!paymentType || paymentType === C.IMPORT_FILE_TYPE_IMPORT_PAYMENTS)" :id="idPayment" @close="idPayment=null" />
    <PopupPaymentInfoBuyer v-if="idPayment && paymentType === C.IMPORT_FILE_TYPE_IMPORT_BUYER_PAYMENTS" :id="idPayment" @close="idPayment=null" />
  </div>
</template>

<script setup>
import {computed, inject, onMounted, onUnmounted, ref} from 'vue'
import tableSettings from '@/config/table-config.js'
import {formatCents} from "@/helpers/textFormatter"
import PopupPaymentInfo from "@/components/_payment/PopupPaymentInfo.vue"
import C from "@/config/back_const";
import PopupPaymentInfoBuyer from "@/components/_payment/PopupPaymentInfoBuyer.vue";

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  payments: {
    type: Array,
    required: true
  },
  active: {
    type: Boolean,
    required: true
  },
  paymentType: {
    type: Number,
    required: false,
    default: C.IMPORT_FILE_TYPE_IMPORT_PAYMENTS,
  },
})
const emit = defineEmits('to-csv')

const WH = 'c460ab76-7081-4bcc-aa0d-29c3683312f1'
const { storage, dateService } = inject('services')
const headings = {
  docNumber: '№ выписки',
  docDate: 'Дата выписки',
  amount: 'Сумма',
  error: 'Ошибка'
}
const columns = Object.keys(headings)
const columnsClasses = {
  uuid: 'w-5 nobr',
  amount: 'w-5',
  error: 'w-50',
  source: 'w-5',
}
const options = {
  ...tableSettings,
  filterable: false,
  texts: { count: '', limit: '' },
  columnsClasses,
  headings,
  sortable: []
}
const ready = ref(false)
const dataKeys = computed(() => Object.keys(props.data))
const idPayment = ref(null)

const getPaymentData = (index, prop) => {
  const payment = props.payments.find(p => p.id === Number(dataKeys.value[index]))
  return payment ? payment[prop] : null
}

const toCSV = () => {
  emit('to-csv', {
    headings: ['id', 'Ошибка', '№ выписки', 'Дата выписки', 'Сумма'],
    errors: Object.entries(props.data).map(([key, value], index) => {
      const errors = {}
      errors[key] = value
      return {
        id: key,
        doc_number: getPaymentData(index, 'doc_number'),
        doc_date: dateService.formatDate(getPaymentData(index, 'doc_date')),
        amount: formatCents(getPaymentData(index, 'amount')),
        errors
      }
    })
  })
}

onMounted(() => {
  setTimeout(() => {
    ready.value = true
  }, 600)
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>

<style lang="scss" scoped>

</style>
