// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CommentApiMethodsCls {
    async commentCreate(wh, data, extra_data = null) {
      // data: Comment
      // Создание комментария
      console.log('commentCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commentUpdate(wh, id, data, extra_data = null) {
      // data: Comment
      // Изменение комментария
      console.log('commentUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commentDelete(wh, id) {
      // Удаление комментария
      console.log('commentDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.comment.delete(id)})
      ctx.$services.storage.del('core__comment', id, wh)
      return null
    }
    async commentActivatePartial(wh, id, data, extra_data = null) {
      // data: CommentActivate
      // Изменение поля is_active для комментария
      console.log('commentActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async commentListForObjectList(wh, id, object_type, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка комментариев для объекта
      // filters: owner_identity
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('commentListForObjectList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.listForObject.list(id, object_type, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.commentApi = new CommentApiMethodsCls()
}

