<template>
    <CModal
      ref="modal"
      :visible="visible"
      size="sm"
      :fullscreen="false"
      backdrop="static"
      alignment="center"
      @close="onClose('cancel')"
    >
      <CModalHeader dismiss>
        <CModalTitle>Вебфон закрыт!</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div class="flex flex-column gap-2 text-center">
          <span>Окно Вебфона закрыто, вы не будете получать звонки!</span>
          <span class="fw-semibold text-primary">Перевести статус в офлайн?</span>
        </div>
      </CModalBody>
      <CModalFooter>
        <div class="flex flex-grow-1 justify-content-center gap-3">
          <CButton color="primary" @click="onComplete">Да</CButton>
          <CButton color="secondary" @click="onClose('cancel')">Нет</CButton>
        </div>
      </CModalFooter>
    </CModal>
</template>
<script setup>
import {inject, onMounted, onUnmounted, ref} from 'vue'
import {webphoneBCName, webphoneMessageTypes} from '@/helpers/broadcast'

const emit = defineEmits(['close'])

const {storage, accountApi} = inject('services')
const WH = '52b2be0e-c50b-4d9d-a71a-f7b0c258f08e'

const bc = new BroadcastChannel(webphoneBCName)
const visible = ref(false)
const closeOnMessage = ref(false)

const onClose = (closeArg) => {
  if (!closeOnMessage.value) {
    bc.postMessage({type: webphoneMessageTypes.closePopup, arg: closeArg})
  }
  visible.value = false
  emit('close', closeArg)
}
const onComplete = async () => {
  onClose()
  try {
    accountApi.offlineCreate(WH)
  } catch (err) {
    console.error(err)
  }
}

bc.onmessage = (ev) => {
  if (ev.data?.type === webphoneMessageTypes.closePopup) {
    closeOnMessage.value = true
    onClose(ev.data?.arg)
  }
}


onMounted(() => {
  visible.value = true
})
onUnmounted(() => {
  storage.clr(WH)
  bc.close()
})
</script>
