// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgeApiMethodsCls {
    async ageCreate(wh, data, extra_data = null) {
      // data: Age
      // Создание Age
      console.log('ageCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.age.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async ageRetrieve(wh, id, extra_data = null) {
      // Получение Age
      console.log('ageRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__age', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__age', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.age.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async ageUpdate(wh, id, data, extra_data = null) {
      // data: Age
      // Обновление Age
      console.log('ageUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.age.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async ageDelete(wh, id) {
      // Удаление Age
      console.log('ageDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.refbook.age.delete(id)})
      ctx.$services.storage.del('refbook__age', id, wh)
      return null
    }
    async hotelAgesRetrieve(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка Age для отеля
      // filters: created_at, updated_at, fr, to
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('hotelAgesRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('refbook__age', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'refbook__age', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.ages.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.ageApi = new AgeApiMethodsCls()
}

