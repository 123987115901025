// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class RefbookApiMethodsCls {
    async bedTypeList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка BedType
      // filters: name, description
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('bedTypeList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.bedType.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async socialGroupList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка SocialGroups
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('socialGroupList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.socialGroup.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, false, extra_data), count:response.data.count}
    }
    async viewTypeList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка ViewType
      // filters: name, description
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('viewTypeList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.viewType.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.refbookApi = new RefbookApiMethodsCls()
}

