// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class InviteApiMethodsCls {
    async inviteCreate(wh, extra_data = null) {
      // Создать инвайт
      console.log('inviteCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.invite.create()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async invitesList(wh, extra_data = null) {
      // Список инвайтов
      console.log('invitesList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.invites.list()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.inviteApi = new InviteApiMethodsCls()
}

