// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class MessageApiMethodsCls {
    async chatList(wh, {limit, offset, page, filters} = {}, extra_data = null) {
      // Получение списка чата
      // filters: lead, tour
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('chatList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async chatCreate(wh, data, extra_data = null) {
      // data: Chat
      // Создание чата
      console.log('chatCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async chatRetrieve(wh, id, extra_data = null) {
      // Получение чата
      console.log('chatRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('messaging__message', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'messaging__message', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async chatMessagesList(wh, id, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка сообщений для чата
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('chatMessagesList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.messages.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async messageList(wh, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка Message
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('messageList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async messageCreate(wh, data, extra_data = null) {
      // data: MessageCreate
      // Создание Message
      console.log('messageCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async messageRetrieve(wh, id, extra_data = null) {
      // Создание Message
      console.log('messageRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('messaging__message', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'messaging__message', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async messageUpdate(wh, id, data, extra_data = null) {
      // data: MessageUpdate
      // Обновление Message
      console.log('messageUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async messageDelete(wh, id) {
      // Удаление Message
      console.log('messageDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.message.message.delete(id)})
      ctx.$services.storage.del('messaging__message', id, wh)
      return null
    }
    async messagePartial(wh, id, data, extra_data = null) {
      // data: Message
      console.log('messagePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async newchatCreate(wh, data, extra_data = null) {
      // data: NewChat
      // Новый чат с лидом
      console.log('newchatCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.newchat.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async transportsList(wh, extra_data = null) {
      // Получение списка транспортов
      console.log('transportsList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.message.transports.list()})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.messageApi = new MessageApiMethodsCls()
}

