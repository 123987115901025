/* Можно использовать  */

import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  formatISO,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks
} from 'date-fns';

const MONTHES = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
const MONTHES_GENITIVE = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']

function _2digit(num) {
  if (num < 10) {
    return '0' + num
  }
  return String(num)
}

export default ctx => {
  class DateService {
    splitDate(date) {
      if (typeof (date) === 'string' || date instanceof String) {
        date = new Date(date)
      }
      if (!(date instanceof Date)) {
        return {
          empty: true,
          year: '0000',
          month: '01',
          day: '01',
          hours: '00',
          minutes: '00',
        }
      }
      return {
        empty: false,
        year: date.getYear() + 1900,
        month: _2digit(date.getMonth() + 1),
        day: _2digit(date.getDate()),
        hours: _2digit(date.getHours()),
        minutes: _2digit(date.getMinutes())
      }
    }

    // для строковых ISO дат. Для '2023-12-31' возвратит '31.12.2023'
    // Не формирует Date. Используется для дат с учётом местного времени, например заезд в отель.
    formatDateAsString (dateString) {
      if (!dateString) return null
      return dateString.split('-').reverse().join('.')
    }

    formatDateMonthYear(date) {
      date = this.splitDate(date)
      return `${date.month}.${date.year}`
    }

    formatDate(date, def = '') {
      date = this.splitDate(date)
      if (date.empty) {
        return def
      }
      return `${date.day}.${date.month}.${date.year}`
    }

    formatTime(date, def = '') {
      date = this.splitDate(date)
      if (date.empty) {
        return def
      }
      return `${date.hours}:${date.minutes}`
    }

    formatDateTime(date, def = '') {
      date = this.splitDate(date)
      if (date.empty) {
        return def
      }
      return `${date.day}.${date.month}.${date.year}<sup>${date.hours}:${date.minutes}</sup>`
    }

    transformSecondsIntoTime(seconds) {
      const m = seconds >= 1 ? Math.floor(seconds / 60) : 0
      const s = seconds >= 1 ? Math.floor(seconds % 60) : 0
      if (m > 0 && s > 0) {
        return `${m} мин, ${s} сек`
      }
      if (m > 0) {
        return `${m} мин`
      }
      if (s > 0) {
        return `${s} сек`
      }
      return ''
    }

    getPresetRanges() {
      return [
        {
          label: 'Эта\u00a0неделя',
          range: [
            startOfWeek(new Date(), {weekStartsOn: 1}),
            endOfWeek(new Date(), {weekStartsOn: 1})
          ]
        },
        {
          label: 'Прошлая\u00a0неделя',
          range: [
            startOfWeek(subWeeks(new Date(), 1), {weekStartsOn: 1}),
            endOfWeek(subWeeks(new Date(), 1), {weekStartsOn: 1})
          ]
        },
        {
          label: 'Этот\u00a0месяц',
          range: [
            startOfMonth(new Date()),
            endOfMonth(new Date())]
        },
        {
          label: 'Прошлый\u00a0месяц',
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1))
          ],
        },
        {
          label: 'Этот\u00a0квартал',
          range: [
            startOfQuarter(new Date()),
            endOfQuarter(new Date())
          ]
        },
        {
          label: 'Прошлый\u00a0квартал',
          range: [
            startOfQuarter(subMonths(new Date(), 3)),
            endOfQuarter(subMonths(new Date(), 3))
          ]
        },
        {
          label: 'Этот\u00a0год',
          range: [
            startOfYear(new Date()),
            endOfYear(new Date())
          ]
        },
        {
          label: 'Прошлый\u00a0год',
          range: [
            startOfYear(subMonths(new Date(), 12)),
            endOfYear(subMonths(new Date(), 12))
          ]
        },
        {
          label: 'Этот\u00a0год',
          range: [startOfYear(new Date()), endOfYear(new Date())],
          slot: 'yearly',
        },
      ]
    }

    toIso(date) {
      try {
        if (typeof date === 'string') {
          date = parseISO(date)
        }
        return formatISO(date, {representation: 'date'})
      }
      catch (e) {
        console.error(e)
        return ''
      }
    }

    monthAndYearLiteral(date) {
      if (typeof date === 'string') {
        date = parseISO(date)
      }
      return `${MONTHES[date.getMonth()]} ${date.getYear()+1900}`
    }

    dateLiteral(date) {
      if (typeof date === 'string') {
        date = parseISO(date)
      }
      return `"${date.getDate()}" ${MONTHES_GENITIVE[date.getMonth()]} ${date.getYear()+1900}`
    }

    dayTimeLiteral(date) {
      if (typeof date === 'string') {
        date = parseISO(date)
      }
      return `${date.getDate()} ${MONTHES_GENITIVE[date.getMonth()]} в ${this.formatTime(date)}`
    }

    monthRangeIso(date) {
      if (typeof date === 'string') {
        date = parseISO(date)
      }
      return {
        start: this.toIso(startOfMonth(date)),
        end: this.toIso(endOfMonth(date))
      }
    }

    getAgeByBirthday (date = new Date()) {
      const month_diff = Date.now() - date.getTime()
      const age_dt = new Date(month_diff)
      const year = age_dt.getUTCFullYear()
      const age = Math.abs(year - 1970)
      return age || 0;
    }
  }

  ctx.$services.dateService = new DateService()
}
