// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class EntityApiMethodsCls {
    async entityList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Получение списка юр лиц
      // filters: id, is_active, company, title, full_title, address, postal_address, email,
      //          director, accountant, allow_custom_invoice, last_invoice_id, invoice_prefix,
      //          invoice_postfix, ogrn, inn, kpp, okpo
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('entityList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.entity.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async entityCreate(wh, data, extra_data = null) {
      // data: Entity
      // Создание юр лица
      console.log('entityCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.entity.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async entityRetrieve(wh, id, extra_data = null) {
      // Получение юр лица
      console.log('entityRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__entity', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__entity', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.entity.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async entityUpdate(wh, id, data, extra_data = null) {
      // data: Entity
      // Обновление модели юр лица
      console.log('entityUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.entity.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async entityDelete(wh, id) {
      // Удаление юр лица
      console.log('entityDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.legal.entity.delete(id)})
      ctx.$services.storage.del('legal__entity', id, wh)
      return null
    }
    async entityActivatePartial(wh, id, data, extra_data = null) {
      // data: EntityActivate
      // Изменение поля is_active для юрлица (Entity)
      console.log('entityActivatePartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.entity.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async entityPhonesRetrieve(wh, id, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // Все внутренние телефоны юр лица
      // filters: number, info
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('entityPhonesRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('legal__entity', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'legal__entity', id:id}, wh, true)
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.legal.entity.phones.retrieve(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.entityApi = new EntityApiMethodsCls()
}

