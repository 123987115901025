<template>
  <div class="mobile-title">
    <slot name="mobile-column-title" />
  </div>
  <!-- desktop -->
  <div v-if="!isSm" class="desktop-cell">
    <slot name="content" />
  </div>
  <!-- mobile -->
  <div v-else class="p-2">
    <slot name="content" />
  </div>
</template>

<script setup>
//компонент для ячейки адаптивной таблицы с заголовком для мобил
import { useMediaSize } from '@/composables/mediaSize'
const { isW2: isSm } = useMediaSize()
</script>
