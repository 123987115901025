<template>
  <div>
    <CTable v-if="hasData" striped bordered>
      <CTableHead class="position-sticky top-0 bg-body">
        <CTableRow>
          <CTableHeaderCell></CTableHeaderCell>
          <CTableHeaderCell
            class="text-center"
            v-for="(mDates, i) in monthSorted"
            :colspan="mDates[1]?.length"
            :key="i"
          >
            <template v-if="mDates.length && mDates[1]?.length">
              {{ getDayMonthYear(mDates[1][0]).slice(1).join(' ') }}
            </template>
          </CTableHeaderCell>
        </CTableRow>
        <CTableRow>
          <CTableHeaderCell> Номер </CTableHeaderCell>
          <CTableHeaderCell v-for="dateH in datesHeader" :key="dateH">
            <div class="text-center">
              {{ getDayMonthYear(dateH)[0] }}
            </div>
          </CTableHeaderCell>
        </CTableRow>
      </CTableHead>

      <CTableBody>
        <CTableRow
          v-for="(item, catId) in data.requested"
          :key="catId"
          @click="onSelectRow"
        >
          <CTableDataCell>
            {{ getCatName(catId) }}
          </CTableDataCell>

          <CTableDataCell v-for="(place, date) in item" :key="date">
            <div class="text-center" role="none">
              <span v-if="place == null" class="fw-medium text-secondary"
                >н/д</span
              >
              <span
                v-else
                class="fw-medium"
                :class="place > 0 ? 'text-body' : 'text-danger'"
                >{{ place }}</span
              >
            </div>
          </CTableDataCell>
        </CTableRow>
      </CTableBody>
    </CTable>
    <template v-else>Нет данных</template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  monthSorted: {
    type: Array,
    required: true,
  },
  datesHeader: {
    type: Array,
    required: true,
  },
  categories: {
    type: Array,
    required: true,
  },
  booker: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['select-row'])

const hasData = computed(
  () =>
    props.data?.requested != null &&
    Object.keys(props.data.requested).length > 0,
)

const getCatName = (catId) => {
  return props.categories.find((cat) => cat?.id === Number(catId))?.name
}

const getDayMonthYear = (dateStr) => {
  const date = new Date(dateStr)
  return [
    date.toLocaleDateString('ru-RU', { day: '2-digit' }),
    date.toLocaleDateString('ru-RU', { month: 'long' }),
    date.toLocaleDateString('ru-RU', { year: 'numeric' }),
  ]
}

const onSelectRow = (ev) => {
  emit('select-row', ev)
}
</script>
