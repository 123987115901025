// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BookingApiMethodsCls {
    async bookingList(wh, {limit, offset, page, sort, filters} = {}, extra_data = null) {
      // filters: created_at, updated_at, status, status_changed_at, place_request_sent_at,
      //          price_request_sent_at, supplier_entity, tour, booker, booking_handle, date_fr,
      //          date_to, day_booking, check_in_time, check_out_time, category, book_a_bed,
      //          ignore_places_limit, invoice, expected_amount, manager, is_quota, company, agent,
      //          payment_to_object, commission_amount, swap_status, swap_target, wait_invoice_date,
      //          id, hotel, location, xstatus, hotel_title, city, tour_agency, invoice_doc_date,
      //          invoice_amount, invoice_due_date, fio_tourist, tour_status
      const params = { ...filters }
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      if (sort) params.o = sort
      console.log('bookingList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.list(params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
    async bookingCreate(wh, data, extra_data = null) {
      // data: BookingCreate
      // Создание брони (Booking)
      console.log('bookingCreate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.create(), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookingRetrieve(wh, id, extra_data = null) {
      console.log('bookingRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__booking', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__booking', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookingPartial(wh, id, data, extra_data = null) {
      // data: BookingUpdate
      // Изменение брони
      console.log('bookingPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookingAddProductPost(wh, id, data, extra_data = null) {
      // data: ProductCreate
      // Создание Product для Booking
      console.log('bookingAddProductPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.addProduct.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookingBookerPartial(wh, id, data, extra_data = null) {
      // data: BookingSetApi
      // Установка API для брони
      console.log('bookingBookerPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.booker.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async bookingCostPartial(wh, id, data, extra_data = null) {
      // data: BookingCost
      // Расчет стоимости брони
      console.log('bookingCostPartial')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.cost.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async productRetrieve(wh, id, extra_data = null) {
      // Получение Product по id
      console.log('productRetrieve')
      // ----- 1 -----
      const stored_obj = ctx.$services.storage.get('core__product', id, wh)
      if (stored_obj) return stored_obj
      ctx.$services.storage.put({data_type:'core__product', id:id}, wh, true)
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.product.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async productUpdate(wh, id, data, extra_data = null) {
      // data: Product
      // Обновление Product
      console.log('productUpdate')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.product.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async productDelete(wh, id) {
      // Удаление Product
      console.log('productDelete')
      // ----- 4 -----
      await ctx.$services.fetch.request({config: endpoints.core.product.delete(id)})
      ctx.$services.storage.del('core__product', id, wh)
      return null
    }
    async tourBookingList(wh, id, {limit, offset, page} = {}, extra_data = null) {
      // Получение списка booking по id тура
      const params = {}
      if (limit) params.limit = limit
      else params.limit = 20
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      else params.offset = 0
      console.log('tourBookingList')
      // ----- 2 -----
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.booking.list(id, params)})
      return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data, extra_data:response.extra_data}, wh, true, extra_data), count:response.data.count}
    }
  }

  ctx.$services.bookingApi = new BookingApiMethodsCls()
}

