<template>
  <div>
    <v-client-table
      v-if="data.length"
      :data="data"
      :columns="columns"
      :options="options"
    >
      <template #uuid="{row}">
        <PoppupXml :data="row.source" :link-text="row.uuid1c" />
      </template>

      <template #warn="{row}">
        <div class="flex" v-for="(warn, index) in row.warnings" :key="index">
          <div class="me-3">
            <AppIcon icon="warning" color="warning" />
          </div>
        <span>{{ warn }}</span>
        </div>
      </template>

      <template #docNumber="{row}">
        {{ row.doc_number }}
      </template>

      <template #docDate="{row}">
        {{ dateService.formatDate(row.doc_date) }}
      </template>

    </v-client-table>

    <Teleport v-if="active && ready" to=".modal-footer>div">
      <CButton color="primary" class="mx-2" @click="toCSV">CSV</CButton>
    </Teleport>
  </div>
</template>

<script setup>
import {inject, onMounted, onUnmounted, ref} from 'vue'
import tableSettings from '@/config/table-config.js'
import PoppupXml from '@/components/popups/PoppupXml.vue'

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  active: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits('to-csv')

const WH = '477783de-f337-4ec8-b44f-3d892fcd6dba'
const { storage, dateService } = inject('services')
const headings = {
  uuid: 'uuid',
  warn: 'Предупреждение',
  docNumber: 'Номер',
  docDate: 'Дата'
}
const columns = Object.keys(headings)
const columnsClasses = {
  uuid: 'w-5 nobr',
  amount: 'w-5',
  error: 'w-50',
  source: 'w-5',
}
const options = {
  ...tableSettings,
  filterable: false,
  texts: { count: '', limit: '' },
  columnsClasses,
  headings,
  sortable: []
}
const ready = ref(false)

const toCSV = () => {
  emit('to-csv', { headings: Object.values(headings), errors: props.data })
}

onMounted(() => {
  setTimeout(() => {
    ready.value = true
  }, 600)
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>

<style lang="scss" scoped>

</style>
