// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CalendarApiMethodsCls {
    async companyCalendarYearList(wh, id, year, extra_data = null) {
      // Получение календаря компании на год
      console.log('companyCalendarYearList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.calendar.year.list(id, year)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyCalendarMonthList(wh, id, month, year, extra_data = null) {
      // Получение календаря компании на месяц
      console.log('companyCalendarMonthList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.calendar.month.list(id, month, year)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyCalendarFillPost(wh, id, data, extra_data = null) {
      // data: CalendarFill
      // Заполнение календаря компании на год
      console.log('companyCalendarFillPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.calendarFill.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async companyCalendarSetPost(wh, id, data, extra_data = null) {
      // data: CalendarSet
      // Установка (изменения) в календаре компании
      console.log('companyCalendarSetPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.calendarSet.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityCalendarYearList(wh, id, year, extra_data = null) {
      // Получение календаря сотрудника на год
      console.log('identityCalendarYearList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.calendar.year.list(id, year)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityCalendarMonthList(wh, id, month, year, extra_data = null) {
      // Получение календаря сотрудника на месяц
      console.log('identityCalendarMonthList')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.calendar.month.list(id, month, year)})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityCalendarFillPost(wh, id, data, extra_data = null) {
      // data: CalendarFill
      // Заполнение календаря сотрудника на год
      console.log('identityCalendarFillPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.calendarFill.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
    async identityCalendarSetPost(wh, id, data, extra_data = null) {
      // data: CalendarSet
      // Установка (изменения) в календаре сотрудника
      console.log('identityCalendarSetPost')
      // ----- 3 -----
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.calendarSet.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh, true, extra_data)
    }
  }

  ctx.$services.calendarApi = new CalendarApiMethodsCls()
}

