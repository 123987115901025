<template>
  <div>
    <template v-if="tabs.length">
      <CNav variant="tabs" role="tablist">
        <CNavItem v-for="(tab, index) in tabs" :key="tab.name">
          <CNavLink
            :active="activeTab === index"
            href="javascript:void(0)"
            @click="activeTab = index"
          >
            {{
              tab.name === 'common'
                ? 'Основные'
                : C.ROLES_TITLES[tab.name] || tab.name
            }}
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        <CTabPane
          v-for="(tab, index) in tabs"
          :key="tab.name"
          role="tabpanel"
          :visible="activeTab === index"
        >
          <div class="py-3 flex flex-column gap-3">
            <div v-for="(option, optionName) in tab.options" :key="optionName">
              <div v-if="optionTypes.boolean.includes(option.type)">
                <CFormCheck
                  :id="optionName"
                  v-model="settings[tab.name][optionName].value"
                  :model-value="valOrDefault(tab.name, optionName)"
                  :label="option.title"
                  class="form-check-m-0"
                  @update:model-value="
                    (val) => {
                      onChange(tab.name, optionName, val)
                    }
                  "
                />
              </div>
              <div v-if="optionTypes.string.includes(option.type)">
                <CFormLabel>{{ option.title }}</CFormLabel>
                <CFormInput
                  v-model.trim="settings[tab.name][optionName].value"
                  :model-value="valOrDefault(tab.name, optionName)"
                  size="sm"
                  @update:model-value="
                    (val) => {
                      onChange(tab.name, optionName, val)
                    }
                  "
                />
              </div>
              <div v-if="optionTypes.text.includes(option.type)">
                <CFormLabel>{{ option.title }}</CFormLabel>
                <CFormTextarea
                  v-model.trim="settings[tab.name][optionName].value"
                  :model-value="valOrDefault(tab.name, optionName)"
                  rows="2"
                  style="resize: both"
                  @update:model-value="
                    (val) => {
                      onChange(tab.name, optionName, val)
                    }
                  "
                />
              </div>
              <div v-if="optionTypes.integer.includes(option.type)">
                <CFormLabel>{{ option.title }}</CFormLabel>
                <CFormInput
                  type="number"
                  v-model.number="settings[tab.name][optionName].value"
                  :model-value="valOrDefault(tab.name, optionName)"
                  :max="settings[tab.name][optionName]?.max"
                  :min="settings[tab.name][optionName]?.min"
                  size="sm"
                  class="w-auto"
                  @input="
                    (ev) => {
                      controlIntMinMaxInput(ev, tab.name, optionName)
                    }
                  "
                  @update:model-value="
                    (val) => {
                      onChange(tab.name, optionName, val)
                    }
                  "
                />
              </div>
              <div v-if="optionTypes.money.includes(option.type)">
                <CFormLabel>{{ option.title }}</CFormLabel>
                <CurrencyInput
                  v-model="settings[tab.name][optionName].value"
                  :model-value="valOrDefault(tab.name, optionName)"
                  class="form-control-sm w-auto"
                  @update:model-value="
                    (val) => {
                      onChange(tab.name, optionName, val)
                    }
                  "
                />
              </div>
            </div>
          </div>
        </CTabPane>
      </CTabContent>
    </template>
    <template v-else-if="ready">
      Для данного пользователя настроек нет
    </template>
  </div>
  <Teleport v-if="ready" to="#user-settings-save">
    <CButton color="success" @click="save" :disabled="hasUnsaved"
      >Сохранить</CButton
    >
  </Teleport>
</template>

<script setup>
import {
  inject,
  onUnmounted,
  onMounted,
  ref,
  computed,
  nextTick,
  watch,
} from 'vue'
import CurrencyInput from '@/components/_custom/currency-input/CurrencyInput.vue'
import C from '@/config/back_const'

const { storage, auth, accountApi } = inject('services')
const WH = 'a1f4b3f6-3697-4ed5-b258-9068cb85cbbc'

const props = defineProps({
  doSave: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  unsaved: {
    type: Boolean,
    required: false,
    default: () => false,
  },
})
const emit = defineEmits('data', 'close')
const settings = ref({})
const data = ref({})
const tabs = computed(() => {
  return Object.entries(settings.value).map(([name, options]) => {
    return { name, options }
  })
})
const activeTab = ref(0)
const myIdentity = ref({})
const myUser = ref({})
const ready = ref(false)
const hasUnsaved = computed(() => props.unsaved)
const optionTypes = {
  string: ['str'],
  boolean: ['bool'],
  integer: ['int'],
  money: ['money'],
  text: ['bin', 'json', 'text'],
}

const checkForRole = (name) => {
  if (Object.keys(C.ROLES_TITLES).includes(name)) {
    return myIdentity.value.role === name
  } else {
    return true
  }
}
const getSettings = async () => {
  const res = await accountApi.userSettingRetrieve(WH, myUser.value.id)
  // filter empty objects and roles
  for (const [key, val] of Object.entries(res)) {
    const valKeys = Object.keys(val)
    if (valKeys.length && checkForRole(key)) {
      settings.value[key] = val
    }
  }
}
const controlIntMinMaxInput = (ev, group, option) => {
  const val = parseFloat(ev.target.value)
  const min = settings.value[group][option].min
  const max = settings.value[group][option].max
  const newVal = isNaN(val) || val <= min ? min : val > max ? max : val
  ev.target.value = newVal
}
const valOrDefault = (group, option) => {
  return settings.value[group][option].value === null
    ? settings.value[group][option].default
    : settings.value[group][option].value
}
const onChange = async (group, option, value) => {
  data.value[option] = value
  emit('data', data.value)
}
const save = async () => {
  await accountApi.userSettingPutUpdate(WH, myUser.value.id, {
    data: data.value,
  })
  data.value = {}
  emit('data', data.value)
  emit('close')
}

watch(
  () => props.doSave,
  (val) => {
    if (val) {
      save()
    }
  },
)

onMounted(async () => {
  try {
    myIdentity.value = await auth.getIdentity()
    myUser.value = await auth.getUser()
    await getSettings()
    nextTick(() => {
      ready.value = true
    })
  } catch (err) {
    console.error(err)
  }
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
