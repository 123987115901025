export default {
  icons: {
    eye: 'fa fa-eye',
    comment: 'fa fa-comment',
    edit: 'fa fa-edit',
    trash: 'fa fa-trash',
    exit: 'fa fa-sign-out',
    save: 'fa fa-save',
    plus: 'fa fa-plus',
    minus: 'fa fa-minus',
    chevronDown: 'fa fa-chevron-down',
    chevronUp: 'fa fa-chevron-up',
    chat: 'fa fa-comments',
    cross: 'fa fa-times',
    filter: 'fa fa-filter',
    reply: 'fa fa-reply',
    briefcase: 'fa fa-briefcase',
    lamp: 'fa fa-lightbulb',
    user: 'fa fa-user',
    factory: 'fa fa-industry',
    sendInvite: 'cil',
    // save: 'fa fa-save',
  },
  person: {
    adultAge: 18,
  },
  bookingSettings: {
    maxCountDays: 30,
    minAge: 5,
  },
  chessSettings: {
    maxNumberOfDays: 45,
  },
  contextMenu: {
    room: [
      {
        name: 'Функция комнаты 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция комнаты 3' },
      { name: 'Функция комнаты 3' },
    ],
    category: [
      {
        name: 'Функция категории 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция категории 3' },
    ],
    total: [
      {
        name: 'Функция итоговое 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция итоговое 3' },
    ],
    status: [
      {
        name: 'Функция статуса 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция статуса 3' },
    ],
    date: [
      {
        name: 'Функция даты 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция даты 3' },
    ],
  },
  cutTextLength: {
    comments: 100,
  },
  defaultButtonsColors: {
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    danger: 'danger',
    warning: 'warning',
    info: 'info',
    dark: 'dark',
    light: 'light',
  },
  defaultButtonsVariants: {
    default: 'default',
    outline: 'outline',
    ghost: 'ghost',
  },
  TABLE_LIMIT: 1000, // "Костыль" Используется для server-table с добавлением строк без пагинации в
  // PopupCommissionerReportEdit.vue

  NOTIFY: {
    SUCCESS: 'success',
    OK: 'success',
    INFO: 'info',
    WARN: 'warning',
    WARNING: 'warning',
    ERROR: 'danger',
    DANGER: 'danger',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
  },

  LOCAL_STORAGE_LATEST_TOKEN_KEY: 'LATEST_TOKEN',
  LOCAL_STORAGE_SERVER_KEY: 'SERVER',
  LOCAL_STORAGE_LOGIN_KEY: 'LOGIN',
  LOCAL_STORAGE_PASSWORD_KEY: 'PASSWORD', // TODO not for production

  DEBOUNCE_TIMEOUT: 500,
  AUTOCOMPLETE_SEARCH_MAX_LEN: 50,

  postMessages: {
    CLIENT_ACTIVATE_WIN: 'CLIENT_ACTIVATE_WIN',
    WEBPHONE_ACTIVATE_WIN: 'WEBPHONE_ACTIVATE_WIN',
    WEBPHONE_SET_DIAL: 'WEBPHONE_SET_DIAL',
  },

  h24Ms: 24 * 60 * 60 * 1000,
  minuteMs: 60 * 1000,

  dataTypeOptions: [
    { label: 'Юр. лицо', value: 'legal__entity' },
    { label: 'Отель', value: 'refbook__hotel' },
    { label: 'Личность', value: 'account__identity' },
    { label: 'Бронь', value: 'core__booking' },
    { label: 'Тур', value: 'core__tour' },
    { label: 'Другое', value: 'unknown' },
  ],
}
