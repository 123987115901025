<template>
  <footer class="podval">
    <div id="inner">
      <div class="flex align-items-center">
        <span class="me-2">Tracker: </span>
        <a
          class="podval-text-link podval-title"
          target="_blank"
          :href="getTrackerSearchLink(title)"
          >{{ props.title }}</a
        >
      </div>
      <div v-if="wiki.length" class="flex flex-wrap align-items-center">
        <span class="me-2">Вики:</span>
        <template v-for="(link, index) in wiki" :key="link">
          <a :href="link" class="podval-text-link px-1" target="_blank">
            {{ getLinkName(link) }}{{ index < wiki.length - 1 ? ', ' : '' }}</a
          >
        </template>
      </div>
      <div v-else>
        <span class="me-2">Вики:</span>
        <a
          :href="getWikiSearchLink(title)"
          class="podval-text-link"
          target="_blank"
          >{{ title }}</a
        >
      </div>
      <div class="flex align-items-center">
        <span class="me-2">UUID: </span>
        <a
          :href="getTrackerSearchLink(uuid)"
          class="podval-text-link"
          target="_blank"
          >{{ uuid }}</a
        >
      </div>
      <div v-if="tasks.length" class="flex flex-wrap align-items-center">
        <span class="me-2">Задачи:</span>
        <template v-for="(link, index) in tasks" :key="link">
          <a :href="link" class="podval-task-link" target="_blank">
            {{ getLinkName(link) }}{{ index < tasks.length - 1 ? ', ' : '' }}</a
          >
        </template>
      </div>
      <div class="mt-2">
        <a
          class="podval-task-create-link"
          target="_blank"
          :href="getTaskCreateLink(title)"
          >Создать задачу</a
        >
      </div>
    </div>
  </footer>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  uuid: {
    type: String,
    required: true,
  },
  tasks: {
    type: Array,
    default: () => [],
  },
  wiki: {
    type: Array,
    default: () => [],
  },
})

const getLinkName = (link) => {
  const split = link.split('/')
  const name = split.pop()
  return name || split.pop()
}
const getTrackerSearchLink = (searchText) => {
  return `https://tracker.yandex.ru/issues/?_s=${searchText}`
}
const getTaskCreateLink = (title) => {
  return `https://tracker.yandex.ru/createTicket?queue=BACK&_form=false&summary=${title} - ${props.uuid}`
}
const getWikiSearchLink = (searchText) => {
  return `https://wiki.yandex.ru/_search?text=${searchText}`
}
</script>
