<template>
  <div>
    <CModal
      ref="modal"
      :visible="isVisible"
      backdrop="static"
      alignment="center"
      fullscreen="md"
      @close="onClose"
    >
      <CModalHeader dismiss>
        <CModalTitle>Настройки компании</CModalTitle>
      </CModalHeader>
      <CModalBody class="popup-min-h position-relative">
        <CompanySettingsView
          @data="onChangeData"
          :do-save="doSave"
          @save="doSave = false"
          @close="onClose"
          :unsaved="unsaved"
        />
        <template v-if="unsaved">
          <div class="position-absolute start-0 top-0 flex w-100 h-100">
            <div
              class="flex flex-column justify-content-center align-items-center flex-grow-1 bg-light bg-opacity-85"
            >
              <h5>Несохранённые изменения!</h5>
              <span>Выберите дальнейшее действие</span>
              <div class="flex justify-content-center gap-3 mt-3">
                <CButton color="danger" @click="closeNoSave"
                  >Закрыть без сохранения</CButton
                >
                <CButton color="primary" @click="unsaved = false"
                  >Редактировать настройки</CButton
                >
                <CButton color="success" @click="saveAndClose"
                  >Сохранить и закрыть</CButton
                >
              </div>
            </div>
          </div>
        </template>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onClose" :disabled="unsaved"
          >Закрыть</CButton
        >
        <!-- для teleport -->
        <div id="company-settings-save"></div>
      </CModalFooter>
      <ThePodval
        title="Настройки компании"
        :tasks="[
          'https://tracker.yandex.ru/BACK-4451',
          'https://tracker.yandex.ru/BACK-4283',
        ]"
        :wiki="[]"
        :uuid="WH"
      />
    </CModal>
  </div>
</template>

<script setup>
import { inject, onUnmounted, onMounted, ref, nextTick } from 'vue'
import CompanySettingsView from '@/popups/common/_account_settings/_company_settings/CompanySettingsView.vue'
import ThePodval from '@/components/_shared/ThePodval.vue'

const { storage } = inject('services')
const WH = 'e988e202-a0b7-46d7-b202-34daa8d535d8'
const emit = defineEmits(['close'])

const isVisible = ref(false)
const changedSettings = ref({})
const unsaved = ref(false)
const doSave = ref(false)

const onClose = () => {
  if (Object.keys(changedSettings.value).length) {
    unsaved.value = true
    return
  }
  unsaved.value = false
  isVisible.value = false
  emit('close')
}
const onChangeData = (data) => {
  changedSettings.value = data
}
const closeNoSave = () => {
  changedSettings.value = {}
  unsaved.value = false
  onClose()
}
const saveAndClose = () => {
  doSave.value = true
}

onMounted(() => {
  isVisible.value = true
  nextTick(() => {
    const headerEl = document.querySelector('.modal-header')
    if (!headerEl) return
    headerEl.addEventListener(
      'click',
      (ev) => {
        ev.stopPropagation()
        if (ev.target.classList.contains('btn-close')) {
          onClose()
        }
      },
      true,
    )
  })
})
onUnmounted(() => {
  storage.clr(WH)
})
</script>
